import { FormParameters } from "app/hooks/v2/measurementUrlSetting/useEditPageForm";
import {
  CreateMeasurementUrlRequest,
  MeasurementUrlLocationParameterComparePattern,
  MeasurementUrlQueryParameterComparePattern,
  MeasurementUrlSetting,
  MeasurementUrlType,
} from "types/measurement_urls";

// FIXME: この実装はAPI側に持たせたい。form情報をAPIに送る形に変更する
export class MeasurementUrlSettingTranslator {
  static encode(
    urlType: MeasurementUrlType,
    formParams: FormParameters
  ): CreateMeasurementUrlRequest {
    return {
      url_type: urlType,
      name: formParams.name,
      url: {
        locations: formParams.location.value
          ? [
              {
                pattern: formParams.location
                  .pattern as MeasurementUrlLocationParameterComparePattern,
                value: formParams.location.value,
              },
            ]
          : [],
        parameters: formParams.parameters
          .filter((p) => p.keyValue.length !== 0)
          .map((item) => ({
            pattern: item.pattern as MeasurementUrlQueryParameterComparePattern,
            key: item.keyValue.split("=")[0],
            value: item.keyValue.split("=")[1],
          })),
      },
    };
  }

  static decode(setting: MeasurementUrlSetting): FormParameters {
    return {
      name: setting.name,
      location:
        setting.url.locations.length > 0
          ? {
              pattern: setting.url.locations[0].pattern,
              value: setting.url.locations[0].value,
            }
          : {
              pattern: "include",
              value: "",
            },
      parameters:
        setting.url.parameters.length > 0
          ? setting.url.parameters.map((item) => {
              const key = item.key ? `${item.key}=` : "";

              return {
                pattern: item.pattern,
                keyValue: item.value ? `${key}${item.value}` : key,
              };
            })
          : [
              {
                pattern: "include",
                keyValue: "",
              },
            ],
    };
  }
}
