import styles from "./PresetFormatSelectButton.module.scss";

type PresetFormatSelectButtonProps = {
  text: JSX.Element;
  onClick: () => void;
  img?: JSX.Element;
};

const PresetFormatSelectButton = ({
  text,
  onClick,
  img,
}: PresetFormatSelectButtonProps) => (
  <div className={styles.presetFormatSelectButton} onClick={onClick}>
    <div>
      <div className={styles.image}>{img}</div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
);

export default PresetFormatSelectButton;
