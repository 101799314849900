import React, { useMemo } from "react";

import {
  DeliveryReportPageCallbacks,
  DeliveryReportPageParameters,
} from "app/hooks/v2/deliveryReport/useDeliveryReportPage";

import fontStyles from "fontStyles.module.scss";

import ErrorMessages from "molecules/v2/ErrorMessages";
import PanelListLayout from "templates/ezPush/PanelListLayout";
import DeliveryReportAggregateRequestForm from "./DeliveryReportPageForm";
import DeliveryReportStatusTable from "./DeliveryReportStatusTable";
import {
  DeliveryReportRequestConfirmModal,
  DeliveryReportRequestErrorModal,
  DeliveryReportRequestRetryConfirmModal,
} from "./DeliveryReportPageModals";
import { DeliveryReportCondition } from "types/delivery_report";

const DeliveryReportPageMain: React.VFC<{
  parameters: DeliveryReportPageParameters;
  callbacks: DeliveryReportPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const elements: JSX.Element[] = [];
  if (parameters.event.errorMessages.length > 0) {
    elements.push(<ErrorMessages errors={parameters.event.errorMessages} />);
  }

  const requestPanelElement = useMemo(
    () => (
      <DeliveryReportAggregateRequestForm
        formParameters={parameters.form}
        formCallbacks={callbacks.form}
        currentDate={parameters.event.currentDate}
        submit={() => {
          if (callbacks.event.canSend()) {
            callbacks.updateModal(
              <DeliveryReportRequestConfirmModal
                formParameters={parameters.form}
                submit={() => {
                  callbacks.event.submit();
                  callbacks.updateModal(undefined);
                }}
                closeModal={() => callbacks.updateModal(undefined)}
              />
            );
          }
        }}
      />
    ),
    [parameters.form, parameters.event.currentDate, callbacks]
  );

  elements.push(requestPanelElement);

  const listElement = (
    <DeliveryReportStatusTable
      statusList={parameters.data}
      update={callbacks.event.update}
      download={callbacks.event.download}
      retry={(requestUuid: string, condition: DeliveryReportCondition) =>
        callbacks.updateModal(
          <DeliveryReportRequestErrorModal
            retry={() => {
              callbacks.updateModal(
                <DeliveryReportRequestRetryConfirmModal
                  condition={condition}
                  submit={() => {
                    callbacks.event.retry(requestUuid);
                    callbacks.updateModal(undefined);
                  }}
                  closeModal={() => callbacks.updateModal(undefined)}
                />
              );
            }}
            closeModal={() => callbacks.updateModal(undefined)}
          />
        )
      }
    />
  );

  elements.push(listElement);

  return (
    <PanelListLayout
      titleElement={
        <div className={fontStyles.title}>レポートダウンロード</div>
      }
      panelElements={elements}
    />
  );
};

export default DeliveryReportPageMain;
