import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";

export const PreviewModal: React.VFC<{
  previewer: JSX.Element;
  close: () => void;
}> = ({ previewer, close }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="プレビュー"
        description={previewer}
        buttons={[<TransparentButton text="閉じる" onClick={close} />]}
      />
    </ModalBackground>
  );
};
