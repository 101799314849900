import styles from "./SettingsEditPhaseFormMainBodyAreaView.module.scss";

const SettingsEditPhaseFormMainBodyAreaView = ({
  elements,
}: {
  elements: JSX.Element[];
}) => {
  return (
    <div className={styles.settingsEditPhaseFormMainBodyArea}>
      {elements.map((e, i) => (
        <div key={i}>{e}</div>
      ))}
    </div>
  );
};

export default SettingsEditPhaseFormMainBodyAreaView;
