import {
  V2_ROUTING_PATH_PARAMETERS,
  V2_ROUTING_TABLE,
  MEASUREMENT_URL_SETTING_EDITOR_MODE,
  MeasurementUrlSettingType,
} from "app/system_defaults/v2_routing";

export class MeasurementUrlSettingEditRouter {
  create(type: MeasurementUrlSettingType) {
    const queries = [
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION.MEASUREMENT_URL_SETTINGS.EDIT.MODE}=${MEASUREMENT_URL_SETTING_EDITOR_MODE.CREATE}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION.MEASUREMENT_URL_SETTINGS.EDIT.TYPE}=${type}`,
    ];
    return `${
      V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.EDIT
    }?${queries.join("&")}`;
  }

  edit(type: MeasurementUrlSettingType, urlUuid: string) {
    const queries = [
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION.MEASUREMENT_URL_SETTINGS.EDIT.MODE}=${MEASUREMENT_URL_SETTING_EDITOR_MODE.EDIT}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION.MEASUREMENT_URL_SETTINGS.EDIT.TYPE}=${type}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION.MEASUREMENT_URL_SETTINGS.EDIT.URL_UUID}=${urlUuid}`,
    ];
    return `${
      V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.EDIT
    }?${queries.join("&")}`;
  }
}
