import { Route, Switch } from "react-router-dom";

import SegmentationRulePage from "pages/account/SegmentationRulePage";
import SegmentationRuleCreationPage from "pages/account/SegmentationRuleCreationPage";

import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import { AccountPageContext } from "interfaces/view/accout";
import CustomerAttributeHeaderRepository from "utils/CustomerAttributeHeaderRepository";

interface PagesRoutingProps {
  context: AccountPageContext;
  segmentationRuleRepository: SegmentationRuleRepository;
  customerAttributeHeaderRepository: CustomerAttributeHeaderRepository;
}

const SegmentationRulePagesRouting = (props: PagesRoutingProps) => {
  return (
    <Switch>
      <Route exact path="/segmentation_rules">
        <SegmentationRulePage
          context={props.context}
          segmentationRuleRepository={props.segmentationRuleRepository}
        />
      </Route>
      <Route exact path="/segmentation_rules/creation">
        <SegmentationRuleCreationPage
          context={props.context}
          segmentationRuleRepository={props.segmentationRuleRepository}
          customerAttributeHeaderRepository={
            props.customerAttributeHeaderRepository
          }
        />
      </Route>
    </Switch>
  );
};

export default SegmentationRulePagesRouting;
