const V2_ROOT: string = "target_push";

export const BASIC_CONFIGURATION_TYPE = {
  DOMAIN: "domain",
  FILE: "file",
  URL: "url",
  OPTION: "option",
} as const;

export type BasicConfigurationType =
  typeof BASIC_CONFIGURATION_TYPE[keyof typeof BASIC_CONFIGURATION_TYPE];

export const V2_ROUTING_TABLE = {
  ROOT: `/${V2_ROOT}`,
  AUTH: {
    LOGIN: `/${V2_ROOT}/login`,
    NEW_PASSWORD: `/${V2_ROOT}/login/new_password`,
    SIGNED_OUT: `/${V2_ROOT}/login/signed_out`,
    SESSION_EXPIRED: `/${V2_ROOT}/login/session_expired`,
    SYSTEM_ERROR: `/${V2_ROOT}/login/system_error`,
  },
  ACCOUNT: {
    HOME: `/${V2_ROOT}/home`,
    INITIALIZE: {
      SUBSCRIBE_DOMAIN: `/${V2_ROOT}/initialize/subscribe_domain`,
    },
    GRANT_MESSAGE: {
      HOME: `/${V2_ROOT}/grant_message`,
      EDIT: `/${V2_ROOT}/grant_message/edit`,
    },
    PUSH_NOTIFICATION: {
      HOME: `/${V2_ROOT}/push_notification`,
      EDIT: `/${V2_ROOT}/push_notification/edit`,
    },
    REPORT: {
      HOME: `/${V2_ROOT}/delivery_report`,
    },
    INITIAL_SETTINGS: {
      HOME: `/${V2_ROOT}/initial_settings`,
    },
    SEGMENTATION_RULE: {
      HOME: `/${V2_ROOT}/segmentation_rule`,
      EDIT: `/${V2_ROOT}/segmentation_rule/edit`,
    },
    ADMIN_DELIVERY_REPORT: {
      HOME: `/${V2_ROOT}/admin_delivery_report`,
    },
    BASIC_CONFIGURATION: {
      HOME: `/${V2_ROOT}/basic_configuration/${BASIC_CONFIGURATION_TYPE.DOMAIN}`,
      ROOT: `/${V2_ROOT}/basic_configuration`,
      DOMAIN: `/${V2_ROOT}/basic_configuration/${BASIC_CONFIGURATION_TYPE.DOMAIN}`,
      FILE: `/${V2_ROOT}/basic_configuration/${BASIC_CONFIGURATION_TYPE.FILE}`,
      URL: {
        HOME: `/${V2_ROOT}/basic_configuration/${BASIC_CONFIGURATION_TYPE.URL}`,
        EDIT: `/${V2_ROOT}/basic_configuration/${BASIC_CONFIGURATION_TYPE.URL}/edit`,
      },
      OPTION: `/${V2_ROOT}/basic_configuration/${BASIC_CONFIGURATION_TYPE.OPTION}`,
    },
  },
} as const;

export const V2_ROUTING_PATH_PARAMETERS = {
  ACCOUNT: {
    PUSH_NOTIFICATION: {
      HOME: {
        GENRE: "genre",
      },
      EDIT: {
        GENRE: "genre",
        MODE: "mode",
        CAMPAIGN_UUID: "campaign_uuid",
        JOB_ID: "job_id",
      },
    },
    SEGMENTATION_RULE: {
      EDIT: {
        MODE: "mode",
        DESTINATION_COLLECT_RULE_UUID: "destination_collect_rule_uuid",
      },
    },
    BASIC_CONFIGURATION: {
      MEASUREMENT_URL_SETTINGS: {
        HOME: {
          TYPE: "type",
        },
        EDIT: {
          MODE: "mode",
          TYPE: "type",
          URL_UUID: "url_uuid",
        },
      },
    },
  },
} as const;

export const PUSH_NOTIFICATION_EDITOR_MODE = {
  CREATE: "create",
  EDIT: "edit",
  COPY: "copy",
} as const;

export type PushNotificationEditorMode =
  typeof PUSH_NOTIFICATION_EDITOR_MODE[keyof typeof PUSH_NOTIFICATION_EDITOR_MODE];

export const PUSH_NOTIFICATION_GENRE_TYPE = {
  SCHEDULED: "scheduled",
  DRAFT: "draft",
  DELIVERED: "delivered",
} as const;

// FIXME: PushNotificationGenreTypeに変える。listって情報いらない
export type PushNotificationGenreListType =
  typeof PUSH_NOTIFICATION_GENRE_TYPE[keyof typeof PUSH_NOTIFICATION_GENRE_TYPE];

export const SEGMENTATION_RULE_EDITOR_MODE = {
  CREATE: "create",
  EDIT: "edit",
  COPY: "copy",
} as const;

export type SegmentationRuleEditorMode =
  typeof SEGMENTATION_RULE_EDITOR_MODE[keyof typeof SEGMENTATION_RULE_EDITOR_MODE];

export const MEASUREMENT_URL_SETTING_EDITOR_MODE = {
  CREATE: "create",
  EDIT: "edit",
} as const;

export type MeasurementUrlSettingEditorMode =
  typeof MEASUREMENT_URL_SETTING_EDITOR_MODE[keyof typeof MEASUREMENT_URL_SETTING_EDITOR_MODE];

export const MEASUREMENT_URL_SETTING_TYPE = {
  SUBSCRIPTION: "subscription",
  CONVERSION: "conversion",
  SEGMENTATION: "segmentation",
} as const;

export type MeasurementUrlSettingType =
  typeof MEASUREMENT_URL_SETTING_TYPE[keyof typeof MEASUREMENT_URL_SETTING_TYPE];
