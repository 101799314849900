import { useCallback, useEffect, useState } from "react";
import { SubscriptionStatistics } from "types/subscription_statistics";
import RestApi from "utils/RestApi";
import SubscriptionStatisticsRepository from "utils/repositories/SubscriptionStatisticsRepository";

export const SUBSCRIPTION_STATISTICS_LOADING_STATUS = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  NO_DATA: "NO_DATA",
} as const;

export type SubscriptionStatisticsProcessStatus =
  typeof SUBSCRIPTION_STATISTICS_LOADING_STATUS[keyof typeof SUBSCRIPTION_STATISTICS_LOADING_STATUS];

export type SubscriptionStatisticsParameters = {
  subscriptionStatistics: SubscriptionStatistics | null;
  loadingStatus: SubscriptionStatisticsProcessStatus;
};

export const useSubscriptionStatistics = (
  managementApi: RestApi
): SubscriptionStatisticsParameters => {
  const [subscriptionStatistics, setSubscriptionStatistics] =
    useState<SubscriptionStatistics | null>(null);

  const [loadingStatus, setLoadingStatus] =
    useState<SubscriptionStatisticsProcessStatus>(
      SUBSCRIPTION_STATISTICS_LOADING_STATUS.LOADING
    );

  const getStats = useCallback(async () => {
    const repository = new SubscriptionStatisticsRepository(managementApi);
    const data = await repository.get();
    setSubscriptionStatistics(data);

    setLoadingStatus(
      data === null
        ? SUBSCRIPTION_STATISTICS_LOADING_STATUS.NO_DATA
        : SUBSCRIPTION_STATISTICS_LOADING_STATUS.LOADED
    );
  }, [managementApi, setSubscriptionStatistics]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  return { subscriptionStatistics, loadingStatus };
};
