type SearchParameters = { [key: string]: string };

const parseSearchParameters = (payload: string): SearchParameters => {
  const trimed = payload.split("?").slice(-1)[0];

  return trimed
    .split("&")
    .map((s) => s.split("="))
    .filter((words) => words.length === 2)
    .reduce((buffer, words) => ({ ...buffer, [words[0]]: words[1] }), {});
};

const encodeSearchParameters = (param: {
  [key: string]: string | undefined;
}) => {
  const keys = Object.keys(param);
  if (keys.length) {
    return (
      "?" +
      keys
        .filter((k) => param[k])
        .map((k) => `${k}=${param[k]}`)
        .join("&")
    );
  } else {
    return "";
  }
};

const stringToEnumValue = <T extends string, D>(
  value: string,
  selection: T[],
  defaultValue: D
): T | D => {
  return (selection as ReadonlyArray<string>).includes(value)
    ? (value as T)
    : defaultValue;
};

export { parseSearchParameters, encodeSearchParameters, stringToEnumValue };

export default SearchParameters;
