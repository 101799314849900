import { useCallback, useEffect, useState } from "react";
import AccountRepository from "utils/AccountRepository";
import { Account } from "interfaces/models";
import RestApi from "utils/RestApi";
import { RecorderRepository } from "utils/RecorderRepositoy";

const useAccount = (
  managementApi: RestApi,
  onSystemError: () => void
): Account | undefined => {
  const [account, setAccount] = useState<Account | undefined>(undefined);

  const loadAccount = useCallback(async () => {
    try {
      const accountRepository = new AccountRepository(managementApi);
      setAccount(await accountRepository.get());
    } catch (error) {
      const recorderRepository = new RecorderRepository(managementApi);
      recorderRepository.record_error(error);
      console.log("アカウント設定読み込みに失敗しました");
      onSystemError();
    }
  }, [managementApi, onSystemError]);

  useEffect(() => {
    loadAccount();
  }, [loadAccount]);

  return account;
};

export default useAccount;
