import { AccountPageContext } from "interfaces/view/accout";
import { Route, Switch } from "react-router-dom";

// Components
import ReportPage from "pages/account/ReportPage";
import ReportMetaDataRepository from "utils/ReportMetaDataRepository";
import ReportRepository from "utils/ReportRepository";

const ReportPagesRouting = ({ context }: { context: AccountPageContext }) => {
  const reportMetaDataRepository = new ReportMetaDataRepository(
    context.managementApi
  );
  const reportRepository = new ReportRepository(context.managementApi);

  return (
    <Switch>
      <Route path="/reports" exact>
        <ReportPage
          context={context}
          reportMetaDataRepository={reportMetaDataRepository}
          reportRepository={reportRepository}
        />
      </Route>
    </Switch>
  );
};

export default ReportPagesRouting;
