import { Account } from "interfaces/models";
import RestApi from "./RestApi";

class AccountRepository {
  managementApi: RestApi;
  endpointUrl: string;
  jwtToken: string;

  RETRY_COUNT = 5;
  TIMEOUT_MS = 30000;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
    this.endpointUrl = managementApi.endpointUrl;
    this.jwtToken = managementApi.jwtToken;
  }

  private async getRecursive(leastRetryCount: number): Promise<Account> {
    if (leastRetryCount <= 0) {
      throw new Error("max retry");
    }
    try {
      return await this.managementApi.get("account");
    } catch {
      return this.getRecursive(leastRetryCount - 1);
    }
  }

  async get(): Promise<Account> {
    return new Promise<Account>(async (resolve, reject) => {
      const timerId: NodeJS.Timeout = setTimeout(
        () => reject(new Error("timeout")),
        this.TIMEOUT_MS
      );

      try {
        const account = await this.getRecursive(this.RETRY_COUNT);
        resolve(account);
      } catch {
        reject(new Error("response error"));
      } finally {
        clearTimeout(timerId);
      }
    });
  }
}

export default AccountRepository;
