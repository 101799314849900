import { ColorChangeHandler } from "react-color";
import EditableInput, {
  EditableInputStyles,
} from "react-color/lib/components/common/EditableInput";

type EZCXEditableInputProps = {
  hex?: string;
  onChange?: ColorChangeHandler;
};

const EZCXEditableInput = ({ hex, onChange }: EZCXEditableInputProps) => {
  const inputStyle: EditableInputStyles = {
    input: {
      width: 125,
      height: 30,
      boxSizing: "border-box",
      outline: "none",
    },
  };

  return <EditableInput style={inputStyle} value={hex} onChange={onChange} />;
};

export default EZCXEditableInput;
