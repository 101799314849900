import styles from "./ActionHeader.module.scss";

interface ActionHeaderProps {
  headlineElement: JSX.Element;
  createButtonElement: JSX.Element | null;
  dataLoadingElement: JSX.Element | undefined;
  dateRangeElement: JSX.Element;
}

const ActionHeader = ({
  headlineElement,
  createButtonElement,
  dataLoadingElement,
  dateRangeElement,
}: ActionHeaderProps) => (
  <div className={styles.header}>
    <div className={styles.headerRight}>
      <div className={styles.title}>{headlineElement}</div>
      {createButtonElement ? (
        <div className={styles.createButton}>{createButtonElement}</div>
      ) : null}
    </div>
    <div className={styles.headerLeft}>
      <div className={styles.loadingIcon}>{dataLoadingElement}</div>
      <div>{dateRangeElement}</div>
    </div>
  </div>
);

export default ActionHeader;
