import { MEASUREMENT_URL_SETTING_TYPE } from "./v2_routing";

export const PUSH_NOTIFICATION_FORM_VALIDATE_RULE = {
  NAME: {
    MAX_LENGTH: 50,
    INVALID_FIRST_CHARACTER: /[+\-=＋－＝]/,
    EMOJI_LIST: [
      "\ud83c[\udf00-\udfff]",
      "\ud83d[\udc00-\ude4f]",
      "\ud83d[\ude80-\udeff]",
      "\ud7c9[\ude00-\udeff]",
      "[\u2600-\u27BF]",
    ],
  },
  CONTENTS: {
    TITLE: {
      MAX_LENGTH: 50,
    },
    BODY: {
      MAX_LENGTH: 120,
    },
    REDIRECT_URL: {
      MAX_LENGTH: 1024,
    },
    IMAGE: {
      MAX_IMAGE_BYTE: 200000,
      ACCEPT_FILE_TYPE: ["image/png", "image/jpeg", "image/gif"],
    },
  },
  DELIVERY_SCHEDULE: {
    MINIMUM_SETTING_TIME_MS: 3600000,
  },
};

export const SEGMENTATION_RULE_FORM_VALIDATE_RULE = {
  NAME: {
    MAX_LENGTH: 50,
    INVALID_FIRST_CHARACTER: /[+\-=＋－＝]/,
    EMOJI_LIST: [
      "\ud83c[\udf00-\udfff]",
      "\ud83d[\udc00-\ude4f]",
      "\ud83d[\ude80-\udeff]",
      "\ud7c9[\ude00-\udeff]",
      "[\u2600-\u27BF]",
    ],
  },
  CONDITIONS: {
    MINIMUM_CONDITION: 1,
  },
};

export const MEASUREMENT_URL_SETTING_FORM_VALIDATE_RULE = {
  TITLE: {
    MAX_LENGTH: 50,
  },
  LOCATION: {
    MAX_LENGTH: 1024,
  },
  QUERY_PARAMETERS: {
    MAX_LENGTH: 1024,
  },
};

export const MEASUREMENT_URL_SETTING_TYPE_RULE = {
  [MEASUREMENT_URL_SETTING_TYPE.SUBSCRIPTION]: {
    ITEM_LIMIT: 30,
  },
  [MEASUREMENT_URL_SETTING_TYPE.CONVERSION]: {
    ITEM_LIMIT: 100,
  },
  [MEASUREMENT_URL_SETTING_TYPE.SEGMENTATION]: {
    ITEM_LIMIT: 100,
  },
};
