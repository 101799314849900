import {
  PushNotificationContentsParameters,
  PushNotificationContentsCallbacks,
} from "app/hooks/v2/pushNotification/usePushNotificationContents";

import styles from "./PushNotificationContentsEditor.module.scss";
import fontStyles from "fontStyles.module.scss";
import animeStyles from "animation.module.scss";

import TextInput from "atoms/TextInput";
import ImageUploader from "molecules/v2/ImageUploader";
import TitleForm from "molecules/v2/TitleForm";
import React, { useMemo } from "react";
import PushNotificationPreviewer from "../PushNotificationPreviewer";
import ToggleButton from "atoms/ezPushShared/accountPageShared/ToggleButton";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import OrangeText from "atoms/ezPushShared/accountPageShared/OrangeText";
import OrangeBorderedButton from "atoms/ezPushShared/accountPageShared/OrangeBorderedButton";
import {
  PushNotificationSendingConfirmModal,
  PushNotificationTestSettingModal,
} from "./PushNotificationEditorModals";
import { TestNotificationParameters } from "app/hooks/v2/pushNotification/useTestNotification";
import GrayOutButton from "atoms/ezPushShared/accountPageShared/GrayOutButton";
import LinkText from "atoms/LinkText";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";

const PushNotificationDemonstration: React.VFC<{
  contentsParameters: PushNotificationContentsParameters;
  testNotificationParameters: TestNotificationParameters;
  executeTestNotification: () => void;
  updateModalContents: (element: JSX.Element | undefined) => void;
}> = ({
  contentsParameters,
  testNotificationParameters,
  executeTestNotification,
  updateModalContents,
}) => {
  const descriptionButton = useMemo(
    () => (
      <OrangeText
        text="テスト配信の設定"
        onChange={() => {
          updateModalContents(
            <PushNotificationTestSettingModal
              subscribeUrl={testNotificationParameters.subscribeUrl}
              testRegisterUrl={testNotificationParameters.testUserRegisterUrl}
              grantMessageType={testNotificationParameters.grantMessageType}
              closeModal={() => updateModalContents(undefined)}
            />
          );
        }}
      />
    ),
    [
      testNotificationParameters.subscribeUrl,
      testNotificationParameters.testUserRegisterUrl,
      testNotificationParameters.grantMessageType,
      updateModalContents,
    ]
  );

  const testButton = useMemo(
    () =>
      testNotificationParameters.isRunning ? (
        <GrayOutButton text="テスト配信中" />
      ) : (
        <OrangeBorderedButton
          text="下書き保存してテスト配信"
          onClick={() =>
            updateModalContents(
              <PushNotificationSendingConfirmModal
                closeModal={() => updateModalContents(undefined)}
                execute={() => {
                  executeTestNotification();
                  updateModalContents(undefined);
                }}
              />
            )
          }
        />
      ),
    [testNotificationParameters, executeTestNotification, updateModalContents]
  );

  return (
    <div className={styles.pushNotificationContentsDemonstration}>
      <div className={styles.preview}>
        <div className={styles.title}>
          <span className={fontStyles.boldText}>プレビュー</span>
        </div>
        <div className={styles.previewer}>
          <PushNotificationPreviewer
            title={contentsParameters.title}
            body={contentsParameters.body}
            imageUrl={contentsParameters.imageUrl}
            isDummy={contentsParameters.isNeedImage}
          />
        </div>
        <div className={styles.note}>
          <span className={fontStyles.note}>
            ※各デバイスでの見え方は、テスト配信でご確認ください。
          </span>
        </div>
      </div>
      <div className={styles.test}>
        <div className={styles.item}>{descriptionButton}</div>
        <div className={styles.item}>{testButton}</div>
      </div>
    </div>
  );
};

type formParameter = {
  label: string;
  form: JSX.Element;
  notification?: string | JSX.Element;
  isRequired?: boolean;
  tooltip?: string | JSX.Element;
  tooltipId?: string;
};

const PushNotificationForms: React.VFC<{
  parameters: PushNotificationContentsParameters;
  callbacks: PushNotificationContentsCallbacks;
}> = ({ parameters, callbacks }) => {
  const imageUploadForm = useMemo(() => {
    return (
      <HorizontalLayout
        elements={[
          <ToggleButton
            checked={parameters.isNeedImage}
            onChange={callbacks.isNeedImage}
          />,
          parameters.isNeedImage ? (
            <div className={animeStyles.fadeIn}>
              <ImageUploader
                image={parameters.image}
                onChange={callbacks.updateImage}
              />
            </div>
          ) : (
            <></>
          ),
        ]}
      />
    );
  }, [
    callbacks.isNeedImage,
    callbacks.updateImage,
    parameters.image,
    parameters.isNeedImage,
  ]);

  const titleForm = useMemo(
    () => (
      <TextInput
        value={parameters.title}
        onChange={(e) => callbacks.updateTitle(e.target.value)}
      />
    ),
    [parameters.title, callbacks]
  );

  const bodyForm = useMemo(
    () => (
      <TextInput
        value={parameters.body}
        onChange={(e) => callbacks.updateBody(e.target.value)}
      />
    ),
    [parameters.body, callbacks]
  );

  const redirectUrlForm = useMemo(
    () => (
      <TextInput
        value={parameters.redirectUrl}
        onChange={(e) => callbacks.updateRedirectUrl(e.target.value)}
      />
    ),
    [parameters.redirectUrl, callbacks]
  );

  const formParameters: formParameter[] = useMemo(
    () => [
      {
        label: "画像",
        form: imageUploadForm,
        notification:
          "形式：PNG,JPG,GIF。サイズ：200KB以下。推奨アスペクト比：1:1",
        tooltipId: "imageRule",
        isRequired: parameters.isNeedImage,
      },
      {
        label: "見出しと本文をAI生成する機能(α版)",
        form: (
          <LinkText
            text={"試してみる"}
            link={
              "https://test-web-001.stg-ez-cx.sushi-impact.net/writer_gpt4.html"
            }
            external={true}
          />
        ),
        notification: "通知の遷移先URLからAIがテキストを5パターン生成します。",
      },
      {
        label: "見出し",
        form: titleForm,
        notification: "文字数上限：50文字（推奨15文字程度）",
        isRequired: true,
      },
      {
        label: "本文",
        form: bodyForm,
        notification: "文字数上限：120文字（推奨20文字程度）",
      },
      {
        label: "遷移先URL",
        form: redirectUrlForm,
        notification: (
          <MultipleLineText
            texts={[
              "半角1024文字以内",
              "遷移先のページにも「サービス専用タグ」をご設置ください",
              "設置が漏れている場合、クリックが計測されません",
            ]}
          />
        ),
        isRequired: true,
      },
    ],
    [
      imageUploadForm,
      titleForm,
      bodyForm,
      redirectUrlForm,
      parameters.isNeedImage,
    ]
  );

  return (
    <div className={styles.pushNotificationForms}>
      {formParameters.map((elem, i) => (
        <div key={i} className={styles.item}>
          <TitleForm
            title={elem.label}
            mainElement={elem.form}
            notification={elem.notification}
            isRequired={elem.isRequired}
            toolTip={elem.tooltip}
          />
        </div>
      ))}
    </div>
  );
};

const PushNotificationContentsEditor: React.VFC<{
  contents: PushNotificationContentsParameters;
  contentsCallbacks: PushNotificationContentsCallbacks;
  testNotificationParameters: TestNotificationParameters;
  executeTestNotification: () => void;
  updateModalContents: (element: JSX.Element | undefined) => void;
}> = ({
  contents,
  contentsCallbacks,
  testNotificationParameters,
  executeTestNotification,
  updateModalContents,
}) => {
  return (
    <div className={styles.pushNotificationContentsEditor}>
      <div className={styles.form}>
        <PushNotificationForms
          parameters={contents}
          callbacks={contentsCallbacks}
        />
      </div>
      <div className={styles.demonstration}>
        <PushNotificationDemonstration
          contentsParameters={contents}
          testNotificationParameters={testNotificationParameters}
          executeTestNotification={executeTestNotification}
          updateModalContents={updateModalContents}
        />
      </div>
    </div>
  );
};

export default PushNotificationContentsEditor;
