import styles from "./ImageTile.module.scss";

type ImageTileProps = {
  img: JSX.Element;
  isActive?: boolean;
  onClick: () => void;
};

const ImageTile = ({ img, isActive = false, onClick }: ImageTileProps) => (
  <div
    className={isActive ? styles.imageTile__active : styles.imageTile}
    onClick={onClick}
  >
    {img}
  </div>
);

export default ImageTile;
