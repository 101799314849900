import { ActionTypes, ACTION_TYPES } from "interfaces/models";
import { CSS_TEXT } from "./ContentsStyle";

export type ModalClassNames = {
  modalId: string;
  modalType: string;
  contentsType: string;
};

export class PreviewNotSupportedError extends Error {}

const tagFullModalClassNames: ModalClassNames = {
  modalId: "-modal",
  modalType: "-full-modal",
  contentsType: "-modal-contents",
};

const tagBottomModalClassNames: ModalClassNames = {
  modalId: "-modal",
  modalType: "-bottom-modal",
  contentsType: "-modal-contents-bottom",
};

const getModalClassNames = (
  actionType: ActionTypes,
  tagClassNamePrefix: string
): ModalClassNames => {
  const tagClassNameSuffixes =
    actionType === ACTION_TYPES.FULL_MODAL
      ? tagFullModalClassNames
      : tagBottomModalClassNames;

  return {
    modalId: tagClassNamePrefix + tagClassNameSuffixes.modalId,
    modalType: tagClassNamePrefix + tagClassNameSuffixes.modalType,
    contentsType: tagClassNamePrefix + tagClassNameSuffixes.contentsType,
  };
};

export const formatContents = (
  actionType: ActionTypes,
  version: number,
  modalContents: string
): string => {
  if (actionType === ACTION_TYPES.TOGGLE_MODAL) {
    throw new PreviewNotSupportedError("トグルモーダル");
  }
  const tagClassNamePrefix = version >= 1 ? "ezcx" : "tamate";
  const classNames = getModalClassNames(actionType, tagClassNamePrefix);

  return `
  <div id="${classNames.modalId}">
    <style>
      ${CSS_TEXT}
    </style>
    <div class="${classNames.modalType}">
      <div class="${classNames.contentsType}">
        ${modalContents}
      </div>
    </div>
  </div>`;
};
