import styles from "./ActionView.module.scss";

interface ActionViewProps {
  errorMessageElements: JSX.Element[];
  headerElement: JSX.Element;
  actionTableElement: JSX.Element;
}

/**
 * FIXME: Rename to ListView
 */
const ActionView = ({
  errorMessageElements,
  headerElement,
  actionTableElement,
}: ActionViewProps) => {
  return (
    <>
      <div className={styles.header}>
        {errorMessageElements.map((e) => (
          <div>{e}</div>
        ))}
      </div>
      <div className={styles.actionView}>
        <div className={styles.header}>{headerElement}</div>
        <div className={styles.contents}>{actionTableElement}</div>
      </div>
    </>
  );
};

export default ActionView;
