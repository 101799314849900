import LoadingIcon from "atoms/LoadingIcon";
import ServiceFullLogo from "atoms/ServiceFullLogo";
import LoadingPageView from "organisms/account/LoadingPageView";

const LoadingPage: React.VFC<{
  loginAccountId: string;
}> = ({ loginAccountId }): JSX.Element => {
  return (
    <LoadingPageView
      logoElement={<ServiceFullLogo />}
      greetingElement={
        <div>
          <div>
            {loginAccountId.length > 0 ? `${loginAccountId}様 ` : ""}ようこそ
          </div>
          <div>ログイン中です。少々お待ちください。</div>
        </div>
      }
      progressElement={<LoadingIcon />}
    />
  );
};

export default LoadingPage;
