import { ActionCreationPhase } from "interfaces/view/actionCreation";

import style from "./PhaseNaviBar.module.scss";

type PhaseNaviBarItemParameters = {
  phase: ActionCreationPhase;
  index: number;
  text: string;
};

type TextBoxParameters = {
  param: PhaseNaviBarItemParameters;
};

const PhaseText = ({ param }: TextBoxParameters) => {
  return (
    <div className={style.textbox}>
      <div className={style.description}>
        <span className={style.index}>{param.index}</span>
        {param.text}
      </div>
    </div>
  );
};

type PhaseNaviItemParameters = {
  currentPhase: ActionCreationPhase;
  param: PhaseNaviBarItemParameters;
};

const PhaseNaviItem = ({ currentPhase, param }: PhaseNaviItemParameters) => {
  return (
    <div
      className={
        param.phase === currentPhase ? style.list_item_active : style.list_item
      }
    >
      <PhaseText param={param} />
    </div>
  );
};

const PhaseNaviBar = ({
  currentPhase,
  items,
}: {
  currentPhase: ActionCreationPhase;
  items: (PhaseNaviBarItemParameters | PhaseNaviBarItemParameters[])[];
}) => (
  <div className={style.list}>
    {items.map((param) =>
      Array.isArray(param) ? (
        param.map((subParam) => (
          <PhaseNaviItem
            key={subParam.index}
            currentPhase={currentPhase}
            param={subParam}
          />
        ))
      ) : (
        <PhaseNaviItem
          key={param.index}
          currentPhase={currentPhase}
          param={param}
        />
      )
    )}
  </div>
);

export default PhaseNaviBar;
