import ContentsPreviewArea from "molecules/ContentsPreviewArea";
import ContentsPreviewUrl from "molecules/ContentsPreviewUrl";
import ContentsPreviewView from "organisms/account/action_creation/ContentsPreviewView";
import parse from "html-react-parser";
import { formatContents } from "utils/FormatContents";
import {
  ActionTypes,
  DecorationEditDeviceType,
  DECORATION_EDIT_DEVICE_TYPE,
  DECORATION_EDIT_DEVICE_TYPE_LABELS,
  DEVICE_DETAILS,
} from "interfaces/models";
import TypeSelector from "atoms/accountPagesShared/TypeSelector";

export type ActionContentsPreviewParameters = {
  url: string;
  html: string;
  actionType: ActionTypes;
  decorationEditDeviceType?: DecorationEditDeviceType;
  onChangeUrl: (value: string) => void;
  onChangeDecorationEditDeviceType?: (value: string) => void;
};

const CreateContentsPreviewArea = ({
  url,
  html,
  actionType,
  decorationEditDeviceType,
  onChangeUrl: onChange,
  onChangeDecorationEditDeviceType,
}: ActionContentsPreviewParameters) => {
  const deviceChangeElement =
    decorationEditDeviceType && onChangeDecorationEditDeviceType ? (
      <TypeSelector
        currentValue={decorationEditDeviceType}
        options={DECORATION_EDIT_DEVICE_TYPE_LABELS}
        onClick={(e) => onChangeDecorationEditDeviceType(e)}
      />
    ) : undefined;

  return (
    <ContentsPreviewView
      urlInputElement={
        <ContentsPreviewUrl url={url} onChange={(e) => onChange(e)} />
      }
      modeSelectElement={deviceChangeElement}
      previewAreaElement={
        <ContentsPreviewArea
          url={url}
          html={parse(formatContents(actionType, 1, html)) as JSX.Element}
        />
      }
      style={{
        minWidth: decorationEditDeviceType
          ? DEVICE_DETAILS[decorationEditDeviceType].WIDTH
          : DEVICE_DETAILS[DECORATION_EDIT_DEVICE_TYPE.SP].WIDTH,
      }}
    />
  );
};

export default CreateContentsPreviewArea;
