import styles from "./SubscribeDomainPanel.module.scss";
import fontStyles from "fontStyles.module.scss";

import TextInput from "atoms/TextInput";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";

import Panel from "templates/ezPush/Panel";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import ErrorMessage from "utils/ErrorMessage";
import ErrorMessages from "molecules/v2/ErrorMessages";
import SubscribeDomainRegisterModal from "./SubscribeDomainConfirmModal";
import React, { useMemo } from "react";
import { SUBSCRIBE_DOMAIN_DESCRIPTION } from "app/system_defaults/descriptionTexts";

const SubscribeDomainPanelTitleElement: React.VFC = () => (
  <VerticalSpreadLayout
    align="center"
    items={[
      <div className={fontStyles.title}>ようこそ</div>,
      <MultipleLineText
        texts={SUBSCRIBE_DOMAIN_DESCRIPTION.introduction}
        align="center"
      />,
    ]}
  />
);

const SubscribeDomainPanel: React.VFC<{
  domain: string;
  errors: ErrorMessage[];
  updateDomain: (domain: string) => void;
  canRequestCreateDomain: () => boolean;
  requestCreateDomain: () => void;
  setModalContents: (modalContents: JSX.Element | undefined) => void;
}> = ({
  domain,
  errors,
  updateDomain,
  requestCreateDomain,
  canRequestCreateDomain,
  setModalContents,
}) => {
  const domainInputElement = useMemo(
    () => (
      <VerticalSpreadLayout
        margin={5}
        items={[
          errors.length > 0 ? <ErrorMessages errors={errors} /> : <></>,
          <TextInput
            value={domain}
            onChange={(e) => updateDomain(e.target.value)}
            placeholder={"example.com"}
          />,
          <div className={fontStyles.note}>半角1024文字以内</div>,
          <MultipleLineText
            texts={SUBSCRIBE_DOMAIN_DESCRIPTION.note.map((d) => (
              <span className={fontStyles.note}>{d}</span>
            ))}
          />,
        ]}
      />
    ),
    [updateDomain, domain, errors]
  );
  const domainCreateButton = useMemo(
    () => (
      <OrangeButton
        text={"登録して基本設定へ"}
        onClick={() => {
          if (canRequestCreateDomain()) {
            setModalContents(
              <SubscribeDomainRegisterModal
                domain={domain}
                clearModal={() => setModalContents(undefined)}
                registerDomain={requestCreateDomain}
              />
            );
          }
        }}
      />
    ),
    [canRequestCreateDomain, requestCreateDomain, setModalContents, domain]
  );
  return (
    <div className={styles.subscribeDomainPanel}>
      <Panel
        itemElements={[
          <div className={styles.body}>
            <VerticalSpreadLayout
              margin={20}
              align="center"
              items={[
                <SubscribeDomainPanelTitleElement />,
                domainInputElement,
                domainCreateButton,
              ]}
            />
          </div>,
        ]}
      />
    </div>
  );
};

export default SubscribeDomainPanel;
