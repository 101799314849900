import styles from "./SegmentationRuleNameForm.module.scss";

type SegmentationRuleNameFormProps = {
  titleElement: JSX.Element;
  formElement: JSX.Element;
};

const SegmentationRuleNameForm = ({
  titleElement,
  formElement,
}: SegmentationRuleNameFormProps) => {
  return (
    <div className={styles.layout}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.form}>{formElement}</div>
    </div>
  );
};

export default SegmentationRuleNameForm;
