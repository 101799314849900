import HeadlineText from "atoms/HeadlineText";
import { ActionCreationPhase } from "interfaces/view/actionCreation";
import ErrorMessage from "molecules/ErrorMessage";
import TopicPath from "molecules/TopicPath";
import ActionCreationHeader from "organisms/account/action_creation/ActionCreationHeader";
import CreatePhaseNaviBar from "./CreatePhaseNaviBar";

type CreateActionCreationHeaderProps = {
  isEditMode: boolean;
  phase: ActionCreationPhase;
  errorMessage?: Object;
  additionalElement?: JSX.Element;
};

const CreateActionCreationHeader = ({
  isEditMode,
  phase,
  errorMessage,
  additionalElement,
}: CreateActionCreationHeaderProps) => {
  const pageTitle = isEditMode
    ? "おもてなしアクション編集"
    : "おもてなしアクション新規作成";
  return (
    <ActionCreationHeader
      topicPathElement={
        <TopicPath
          items={[
            { text: "おもてなしアクション", link: "/actions" },
            { text: pageTitle },
          ]}
        />
      }
      headerTextElement={<HeadlineText text={pageTitle} />}
      phaseNaviElement={<CreatePhaseNaviBar phase={phase} />}
      errorMessageElement={
        errorMessage ? (
          <>
            {Object.values(errorMessage).map((e: string, i: number) => (
              <div key={i}>
                <ErrorMessage message={e} />
              </div>
            ))}
          </>
        ) : (
          <></>
        )
      }
      settingEditElement={additionalElement}
    />
  );
};

export default CreateActionCreationHeader;
