import {
  ActionCreationPhase,
  ACTION_CREATION_PHASE,
} from "interfaces/view/actionCreation";
import PhaseNaviBar from "molecules/PhaseNaviBar";

type CreatePhaseNaviBarProps = {
  phase: ActionCreationPhase;
};

const CreatePhaseNaviBar = ({ phase }: CreatePhaseNaviBarProps) => {
  return (
    <PhaseNaviBar
      currentPhase={phase}
      items={[
        {
          phase: ACTION_CREATION_PHASE.MODE_SELECT,
          index: 1,
          text: "作成方法選択",
        },
        {
          phase: ACTION_CREATION_PHASE.FORMAT_SELECT,
          index: 2,
          text: "アクション選択",
        },
        {
          phase: ACTION_CREATION_PHASE.SETTINGS_EDIT,
          index: 3,
          text: "設定",
        },
        {
          phase: ACTION_CREATION_PHASE.COMPLETE,
          index: 4,
          text: "完了",
        },
      ]}
    />
  );
};

export default CreatePhaseNaviBar;
