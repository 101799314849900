import { useCallback, useEffect } from "react";
import { PushNotificationCallbacks } from "./usePushNotificationParameters";
import { useHistory } from "react-router-dom";
import CampaignRepository from "utils/repositories/CampaignRepository";
import RestApi from "utils/RestApi";
import { PushNotificationEditRouter } from "app/process/pushNotification/edit/pushNotificationEditRouter";

export type PushNotificationEventCallbacks = {
  initialLoad: () => void;
  goPushMessageEditor: () => void;
  deleteCampaign: (campaignUuid: string) => void;
  updateCampaignStatus: (campaignUuid: string, isActive: boolean) => void;
  editCampaign: (campaignUuid: string) => void;
  copyCampaign: (campaignUuid: string) => void;
  copyJobHistory: (campaignUuid: string, jobId: number) => void;
};

const usePushNotificationEvents = (
  managementApi: RestApi,
  callbacks: PushNotificationCallbacks
): [PushNotificationEventCallbacks] => {
  const history = useHistory();

  const initialLoad = useCallback(() => {
    callbacks.loadScheduledCampaigns();
    callbacks.loadDraftCampaigns();
    callbacks.loadJobHistories();
  }, [callbacks]);

  const goPushMessageEditor = useCallback(() => {
    history.push(new PushNotificationEditRouter().createCampaign());
  }, [history]);

  const deleteCampaign = useCallback(
    (campaignUuid: string) => {
      new CampaignRepository(managementApi)
        .delete({
          campaign_uuid: campaignUuid,
        })
        .then(() => {
          callbacks.loadDraftCampaigns();
        });
    },
    [managementApi, callbacks]
  );

  const updateCampaignStatus = useCallback(
    (campaignUuid: string, isActive: boolean) => {
      new CampaignRepository(managementApi)
        .changeStatus({
          campaign_uuid: campaignUuid,
          is_active: isActive,
        })
        .then(() => {
          callbacks.loadScheduledCampaigns();
          callbacks.loadDraftCampaigns();
        });
    },
    [managementApi, callbacks]
  );

  const editCampaign = useCallback(
    (campaignUuid: string) => {
      history.push(new PushNotificationEditRouter().editCampaign(campaignUuid));
    },
    [history]
  );

  const copyCampaign = useCallback(
    (campaignUuid: string) => {
      history.push(new PushNotificationEditRouter().copyCampaign(campaignUuid));
    },
    [history]
  );

  const copyJobHistory = useCallback(
    (campaignUuid: string, jobId: number) => {
      history.push(
        new PushNotificationEditRouter().copyJobHistory(
          campaignUuid,
          String(jobId)
        )
      );
    },
    [history]
  );

  // NOTE: 初期化
  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return [
    {
      initialLoad,
      goPushMessageEditor,
      deleteCampaign,
      updateCampaignStatus,
      editCampaign,
      copyCampaign,
      copyJobHistory,
    },
  ];
};

export default usePushNotificationEvents;
