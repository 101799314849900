import styles from "./SourceEditView.module.scss";

const SourceEditView = ({
  resetElement,
  editAreaElement,
}: {
  resetElement: JSX.Element;
  editAreaElement: JSX.Element;
}) => {
  return (
    <div className={styles.sourceEditView}>
      <div className={styles.header}>
        <div className={styles.title}>HTMLソース</div>
        <div>{resetElement}</div>
      </div>
      {editAreaElement}
    </div>
  );
};

export default SourceEditView;
