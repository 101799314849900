import styles from "./OrangeWideButton.module.scss";

type OrangeWideButtonProps = {
  text: string;
  onClick: () => void;
};

const OrangeWideButton = (props: OrangeWideButtonProps): JSX.Element => {
  return (
    <button className={styles.orangeWideButton} onClick={props.onClick}>
      {props.text}
    </button>
  );
};

export default OrangeWideButton;
