import { useCallback } from "react";
import { useLocation } from "react-router-dom";

const SCROLL_DELAY = 100;

export const useAnchor = (): { scrollToAnchor: () => void } => {
  const location = useLocation();

  const scrollToAnchor = useCallback<() => void>(() => {
    const hash = location.hash.slice(1);
    const target = document.getElementById(hash);

    if (target) {
      setTimeout(() => {
        target.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, SCROLL_DELAY);
    }
  }, [location]);

  return { scrollToAnchor };
};
