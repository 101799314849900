import styles from "./ButtonsView.module.scss";

type ButtonsViewProps = {
  buttons: JSX.Element[];
};

const ButtonsView = ({ buttons }: ButtonsViewProps) => {
  return (
    <div className={styles.buttonsView}>
      {buttons.map((e, i) => (
        <div key={i} className={styles.button}>
          {e}
        </div>
      ))}
    </div>
  );
};

export default ButtonsView;
