export const getCurrentDate = (): Date => {
  const currentDateTime = new Date();

  return new Date(
    currentDateTime.getFullYear(),
    currentDateTime.getMonth(),
    currentDateTime.getDate(),
    0,
    0,
    0,
    0
  );
};
