import RestApi from "utils/RestApi";
import usePushNotificationParameters, {
  PushNotificationParameters,
} from "./usePushNotificationParameters";
import usePushNotificationEvents, {
  PushNotificationEventCallbacks,
} from "./usePushNotificationEvents";
import { useState } from "react";
import usePushNotificationSearchParameters, {
  PushNotificationEditSearchParameters,
} from "./usePushNotificationPageSearchParameters";

export type PushNotificationPageParameters = {
  data: PushNotificationParameters;
  modalContents: JSX.Element | undefined;
  searchParameters: PushNotificationEditSearchParameters;
};

export type PushNotificationPageCallbacks = {
  events: PushNotificationEventCallbacks;
  updateModalContents: (value: JSX.Element) => void;
  closeModalContents: () => void;
};

const usePushNotificationPage = (
  managementApi: RestApi
): [PushNotificationPageParameters, PushNotificationPageCallbacks] => {
  const searchParameters = usePushNotificationSearchParameters();
  const [data, callbacks] = usePushNotificationParameters(managementApi);
  const [events] = usePushNotificationEvents(managementApi, callbacks);
  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  return [
    {
      data,
      modalContents,
      searchParameters,
    },
    {
      events,
      updateModalContents: (value: JSX.Element | undefined) =>
        setModalContents(value),
      closeModalContents: () => setModalContents(undefined),
    },
  ];
};

export default usePushNotificationPage;
