import { useMemo, useState } from "react";
import { useLocation } from "react-router";
import { parseSearchParameters } from "utils/SearchParameters";
import useLoginInput from "app/hooks/v2/useLoginInput";
import { isAdminLogin } from "app/process/admin";

import ErrorMessage from "atoms/authorizePagesShared/ErrorMessage";
import InputForm from "atoms/authorizePagesShared/InputForm";

import OrangeWideButton from "atoms/ezPushShared/authPageShared/OrangeWideButton";
import AuthFormV2 from "templates/ezPush/auth/AuthFormV2";
import AuthPageBaseV2 from "./AuthPageBase";

interface SignInPageProp {
  onSignIn: (
    accountId: string,
    userId: string,
    password: string,
    isAdmin: boolean,
    onError: (err: any) => void
  ) => void;
}

const SignInPageV2 = ({ onSignIn }: SignInPageProp) => {
  const location = useLocation();
  const searchParameters = parseSearchParameters(location.search);
  const [loginInput, loginInputCallback] = useLoginInput(searchParameters);
  const isAdmin: boolean = useMemo(() => {
    return isAdminLogin(searchParameters.admin);
  }, [searchParameters]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const onError = (err: any) => {
    setErrorMessage(err.message);
  };

  return (
    <AuthPageBaseV2
      formElement={
        <AuthFormV2
          inputElements={[
            <InputForm
              item={{
                name: "clientId",
                type: "text",
                value: loginInput.clientId,
                onChange: loginInputCallback.setClientId,
                placeholder: "アカウントID",
              }}
            />,
            <InputForm
              item={{
                name: "username",
                type: "text",
                value: loginInput.username,
                onChange: loginInputCallback.setUsername,
                placeholder: "ユーザーID",
              }}
            />,
            <InputForm
              item={{
                name: "password",
                type: "password",
                value: loginInput.password,
                onChange: loginInputCallback.setPassword,
                placeholder: "パスワード",
              }}
            />,
          ]}
          errorMessageElement={
            <ErrorMessage
              message={errorMessage ? `認証エラーが発生しました` : ""}
            />
          }
          submitButtonElement={
            <OrangeWideButton
              text={isAdmin ? "管理者ログイン" : "ログイン"}
              onClick={() =>
                onSignIn(
                  loginInput.clientId,
                  loginInput.username,
                  loginInput.password,
                  isAdmin,
                  onError
                )
              }
            />
          }
        />
      }
    />
  );
};

export default SignInPageV2;
