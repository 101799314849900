import RestApi from "./RestApi";

export class RecorderRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async record_error(error: unknown): Promise<void> {
    const message = {
      error: error instanceof Error ? error.message : error,
    };
    this.managementApi.post("system_error", message);
  }
}
