import styles from "./ActionCreationForm.module.scss";

const ActionCreationForm = ({
  stepBarElement,
  mainElement,
  previousPhaseElement,
  nextPhaseElement,
  buttonElements,
}: {
  stepBarElement?: JSX.Element;
  mainElement: JSX.Element;
  previousPhaseElement?: JSX.Element;
  nextPhaseElement?: JSX.Element;
  buttonElements: JSX.Element[];
}) => (
  <div className={styles.actionCreationForm}>
    <form className={styles.form}>
      <div className={styles.stepBar}>{stepBarElement}</div>
      <div className={styles.main}>{mainElement}</div>
      {previousPhaseElement || nextPhaseElement ? (
        <div className={styles.footer}>
          <div className={styles.previous}>{previousPhaseElement}</div>
          <div className={styles.next}>{nextPhaseElement}</div>
        </div>
      ) : undefined}
    </form>
    <div className={styles.commonButtons}>
      {buttonElements.map((e, i) => (
        <div className={styles.button} key={i}>
          {e}
        </div>
      ))}
    </div>
  </div>
);

export default ActionCreationForm;
