import { useCallback, useEffect, useState } from "react";
import { Permission, PermissionType } from "interfaces/models";
import SessionRepository from "utils/SessionRepository";
import RestApi from "utils/RestApi";

const usePermission = (managementApi: RestApi): PermissionType => {
  const [permission, setPermission] = useState<PermissionType>(
    Permission.GENERAL
  );

  const load = useCallback(async () => {
    try {
      const sessionRepository = new SessionRepository(managementApi);
      setPermission(await sessionRepository.get());
    } catch (error) {
      console.error(error);
    }
  }, [managementApi]);

  useEffect(() => {
    load();

    return () => {
      managementApi.abort();
    };
  }, [load, managementApi]);

  return permission;
};

export default usePermission;
