import React from "react";

import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import AccountPageBase from "./AccountPageBase";
import SegmentationRuleEditor from "organisms/ezPush/segmentationRule/editor/SegmentationRuleEditor";
import useSegmentationRuleEditPage from "app/hooks/v2/segmentationRule/editor/useSegmentationRuleEditPage";

const SegmentationRuleEditPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = useSegmentationRuleEditPage(
    accountContext.managementApi
  );
  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <SegmentationRuleEditor parameters={parameters} callbacks={callbacks} />
      }
      modalContents={parameters.modalContents}
    />
  );
};

export default SegmentationRuleEditPage;
