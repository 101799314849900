import React from "react";

import styles from "./CenteringLayout.module.scss";

const CenteringLayout: React.VFC<{
  element: JSX.Element;
}> = ({ element }) => {
  return <div className={styles.centeringLayout}>{element}</div>;
};

export default CenteringLayout;
