import TextInput from "atoms/TextInput";
import styles from "./ActionNameForm.module.scss";

type ActionNameFormProps = {
  name: string;
  setName: (value: string) => void;
};

const ActionNameForm = ({ name, setName }: ActionNameFormProps) => {
  return (
    <div className={styles.actionNameForm}>
      <TextInput
        value={name}
        onChange={(e) => {
          if (e) {
            setName(e.target.value);
          }
        }}
      />
    </div>
  );
};

export default ActionNameForm;
