import {
  PUSH_NOTIFICATION_EDITOR_MODE,
  PUSH_NOTIFICATION_GENRE_TYPE,
  V2_ROUTING_PATH_PARAMETERS,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";

export class PushNotificationEditRouter {
  createCampaign() {
    const queries: string[] = [
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.MODE}=${PUSH_NOTIFICATION_EDITOR_MODE.CREATE}`,
    ];

    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.EDIT}?${queries.join("&")}`;
  }

  editCampaign(campaignUuid: string) {
    const queries: string[] = [
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.MODE}=${PUSH_NOTIFICATION_EDITOR_MODE.EDIT}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.GENRE}=${PUSH_NOTIFICATION_GENRE_TYPE.DRAFT}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.CAMPAIGN_UUID}=${campaignUuid}`,
    ];

    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.EDIT}?${queries.join("&")}`;
  }

  copyCampaign(campaignUuid: string) {
    const queries: string[] = [
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.MODE}=${PUSH_NOTIFICATION_EDITOR_MODE.COPY}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.GENRE}=${PUSH_NOTIFICATION_GENRE_TYPE.DRAFT}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.CAMPAIGN_UUID}=${campaignUuid}`,
    ];
    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.EDIT}?${queries.join("&")}`;
  }

  copyJobHistory(campaignUuid: string, jobId: string) {
    const queries: string[] = [
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.MODE}=${PUSH_NOTIFICATION_EDITOR_MODE.COPY}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.GENRE}=${PUSH_NOTIFICATION_GENRE_TYPE.DELIVERED}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.CAMPAIGN_UUID}=${campaignUuid}`,
      `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.JOB_ID}=${jobId}`,
    ];
    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.EDIT}?${queries.join("&")}`;
  }
}
