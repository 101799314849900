import React from "react";
import styles from "./GrantMessageControlPanelLayout.module.scss";

const GrantMessageControlPanelLayout: React.VFC<{
  titleElement: JSX.Element;
  testParemeterElement: JSX.Element;
  editButtonElement?: JSX.Element;
}> = ({ titleElement, testParemeterElement, editButtonElement }) => (
  <div className={styles.grantMessageControlPanelLayout}>
    <div className={styles.title}>{titleElement}</div>
    <div>{testParemeterElement}</div>
    {editButtonElement ? (
      <div className={styles.editButtonElement}>{editButtonElement}</div>
    ) : undefined}
  </div>
);

export default GrantMessageControlPanelLayout;
