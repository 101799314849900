import { CLOSE_BUTTON_STYLE } from "app/system_defaults/action_creation_defaults";
import {
  ActionCreationContentsStyleParameters,
  PositionType,
  POSITION_TYPE,
  ContentsType,
  CONTENTS_TYPE,
  CustomContentsFormat,
  CUSTOM_CONTENTS_FORMAT,
  CreativeParts,
  CREATIVE_PARTS,
  ActionCreationContentsPCStyleParameters,
} from "interfaces/model/actionContentsParameters";
import { CloseButtonSizeParameters } from "interfaces/models";
import {
  ColorType,
  COLOR_TYPE,
} from "molecules/GradationColorPicker/ColorTypeSelector";
import { Node } from "node-html-parser";
import { COMMON_STYLE_PARAMETERS, CREATIVE_PARTS_CLASS_NAME } from "./creative";

const createStyleParameters = (
  styles: ActionCreationContentsStyleParameters,
  closeButtonSizeParameters: CloseButtonSizeParameters,
  isEnablePCStyle?: boolean,
  pcStyles?: ActionCreationContentsPCStyleParameters,
  pcCloseButtonSizeParameters?: CloseButtonSizeParameters
) => {
  const height = styles.contentsBaseHeightAdjust
    ? "auto"
    : styles.contentsBaseHeight
    ? `${styles.contentsBaseHeight}px`
    : "auto";

  const pcHeight = pcStyles?.contentsBase.background.heightAdjust
    ? "auto"
    : pcStyles?.contentsBase.background.height
    ? `${pcStyles?.contentsBase.background.height}px`
    : "auto";

  const buttonBackgroundColor =
    styles.buttonColorType === COLOR_TYPE.SIMPLE
      ? styles.buttonBackgroundGradationColor[0]
      : `linear-gradient(${styles.buttonBackgroundGradationColor.join(",")})`;

  const couponButtonBackgroundColor =
    styles.couponButtonColorType === COLOR_TYPE.SIMPLE
      ? styles.couponButtonColor[0]
      : `linear-gradient(${styles.couponButtonColor.join(",")})`;

  const smallImageSize = 30 * styles.imageMagnification;

  const pcStyleParameters =
    isEnablePCStyle && pcStyles
      ? `
    /* pc */
    --pc-modal-width: ${pcStyles.contentsBase.background.width}px;
    --pc-base-border-radius: ${pcStyles.contentsBase.border.radius}px;
    --pc-base-max-height: ${pcStyles.contentsBase.background.maxHeight};
    --pc-modal-height: ${pcHeight};
    --pc-base-padding: ${pcStyles.contentsBase.background.padding.top}px ${
          pcStyles.contentsBase.background.padding.right
        }px ${pcStyles.contentsBase.background.padding.bottom}px ${
          pcStyles.contentsBase.background.padding.left
        }px;

    --pc-article-font-size: ${pcStyles.article.size}px;
    --pc-headline-font-size: ${pcStyles.headline.text.size}px;
    --pc-sub-headline-font-size: ${pcStyles.subHeadline.text.size}px;
    --pc-sub-headline-background-radius: ${
      pcStyles.subHeadline.background.radius
    }px;
    --pc-sub-headline-background-padding: ${
      pcStyles.subHeadline.background.padding.top + 1
    }px ${pcStyles.subHeadline.background.padding.right}px ${
          pcStyles.subHeadline.background.padding.bottom
        }px ${pcStyles.subHeadline.background.padding.left}px;
    
    --pc-button-font-size: ${pcStyles.button.text.size}px;
    --pc-button-border-radius: ${pcStyles.button.background.radius}px;
    --pc-button-padding: ${pcStyles.button.background.padding.top + 1}px ${
          pcStyles.button.background.padding.right
        }px ${pcStyles.button.background.padding.bottom}px ${
          pcStyles.button.background.padding.left
        }px;
    
    ${
      pcCloseButtonSizeParameters
        ? `--pc-close-button-radius: ${pcCloseButtonSizeParameters.radius}px;`
        : ""
    }
    ${
      pcCloseButtonSizeParameters
        ? `--pc-close-button-padding: ${pcCloseButtonSizeParameters.padding}px;`
        : ""
    }
    ${
      pcCloseButtonSizeParameters
        ? `--pc-close-button-size: ${pcCloseButtonSizeParameters.size}px;`
        : ""
    }
    ${
      pcCloseButtonSizeParameters
        ? `--close-button-position-padding: ${pcCloseButtonSizeParameters.positionPadding}px;`
        : ""
    }

    --pc-coupon-code-font-size: ${pcStyles.couponCode.text.size}px;
    --pc-coupon-copy-button-font-size: ${pcStyles.couponCopyButton.text.size}px;
    --pc-coupon-copy-button-padding: ${
      pcStyles.couponCopyButton.background.padding.top + 1
    }px ${pcStyles.couponCopyButton.background.padding.right}px ${
          pcStyles.couponCopyButton.background.padding.bottom
        }px ${pcStyles.couponCopyButton.background.padding.left}px;
    --pc-coupon-copy-button-radius: ${
      pcStyles.couponCopyButton.background.radius
    }px;
    
    --pc-small-image-size: ${30 * pcStyles.image.magnification}%;
    --pc-small-image-text-area-size: ${
      100 - 30 * pcStyles.image.magnification
    }%;`
      : "";

  return `:root {
    /* base */
    --modal-width: ${styles.contentsBaseWidth}px;
    --border-color: ${styles.borderColor};
    --background-color: ${styles.backgroundColor};
    --overlay-background-color: rgba(102, 102, 102, .5);
    --base-border-radius: ${styles.contentsBaseRadius}px;
    --base-max-height: ${styles.contentsBaseMaxHeight};
    --modal-height: ${height};
    --modal-border-size: ${styles.contentsBaseBorderWidth}px;

    /* article */
    --article-color: ${styles.textColor};
    --article-font-size: ${styles.textSize}px;

    /* headline */
    --headline-font-size: ${styles.headlineTextSize}px;
    --headline-font-color: ${styles.headlineTextColor};

    /* sub headline */
    --sub-headline-font-size: ${styles.subHeadlineTextSize}px;
    --sub-headline-font-color: ${styles.subHeadlineTextColor};
    --sub-headline-background-color: ${styles.subHeadlineBackgroundColor};
    --sub-headline-background-radius: ${styles.subHeadlineBackgroundRadius}px;
    --sub-headline-background-padding: ${
      styles.subHeadlineBackgroundTopPadding + 1
    }px ${styles.subHeadlineBackgroundRightPadding}px ${
    styles.subHeadlineBackgroundBottomPadding
  }px ${styles.subHeadlineBackgroundLeftPadding}px;

    /* button */
    --button-background-color: ${buttonBackgroundColor};
    --button-font-color: ${styles.buttonTextColor};
    --button-font-size: ${styles.buttonTextSize}px;
    --button-border-radius: ${styles.buttonBorderRadius}px;
    --button-padding: ${styles.buttonTopPadding + 1}px ${
    styles.buttonRightPadding
  }px ${styles.buttonBottomPadding}px ${styles.buttonLeftPadding}px;
    --button-shadow: ${
      styles.isButtonShadow
        ? "0 0 10px rgba(0, 0, 0, 0.16)"
        : "0 0 0 transparent"
    };

    /* close button */
    --close-button-color: ${styles.closeButtonCrossColor};
    --close-button-background-color: ${styles.closeButtonBackgroundColor};
    --close-button-radius: ${closeButtonSizeParameters.radius}px;
    --close-button-padding: ${closeButtonSizeParameters.padding}px;
    --close-button-size: ${closeButtonSizeParameters.size}px;
    --close-button-position-padding: ${
      closeButtonSizeParameters.positionPadding
    }px;

    /* coupon code */
    --coupon-code-background-color: ${styles.couponCodeBackgroundColor};
    --coupon-code-color: ${styles.couponCodeTextColor};
    --coupon-code-font-size: ${styles.couponCodeTextSize}px;

    /* coupon copy button */
    --coupon-copy-button-background-color: ${couponButtonBackgroundColor};
    --coupon-copy-button-font-color: ${styles.couponButtonTextColor};
    --coupon-copy-button-font-size: ${styles.couponButtonTextSize}px;
    --coupon-copy-button-padding: ${styles.couponButtonTopPadding + 1}px ${
    styles.couponButtonRightPadding
  }px ${styles.couponButtonBottomPadding}px ${styles.couponButtonLeftPadding}px;
    --coupon-copy-button-radius: ${styles.couponButtonRadius}px;
    --coupon-button-shadow: ${
      styles.couponButtonShadow
        ? "0 0 10px rgba(0, 0, 0, 0.16)"
        : "0 0 0 transparent"
    };

    /* image */
    --small-image-size: ${smallImageSize}%;
    --small-image-text-area-size: ${100 - smallImageSize}%;
    ${pcStyleParameters}
  }`;
};

export const generatePopUpCSSPositionParameters = (
  positionType: PositionType
): string => {
  switch (positionType) {
    case POSITION_TYPE.CENTER_BOTTOM:
      return `top: auto; bottom: 5px; left: 50%; right: auto; transform: translateX(-50%);`;
    case POSITION_TYPE.CENTER_TOP:
      return `top: 5px; bottom: auto; left: 50%; right: auto; transform: translateX(-50%);`;
    case POSITION_TYPE.LEFT_BOTTOM:
      return `top: auto; bottom: 5px; left: 5px; right: auto;`;
    case POSITION_TYPE.LEFT_TOP:
      return `top: 5px; bottom: auto; left: 5px; right: auto;`;
    case POSITION_TYPE.RIGHT_BOTTOM:
      return `top: auto; bottom: 5px; left: auto; right: 5px;`;
    case POSITION_TYPE.RIGHT_TOP:
      return `top: 5px; bottom: auto; left: auto; right: 5px;`;
    default:
      return "";
  }
};

export const createPositionParameters = (
  contentsType: ContentsType,
  positionType: PositionType
): string => {
  switch (contentsType) {
    case CONTENTS_TYPE.PARTIAL:
      return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
        CREATIVE_PARTS_CLASS_NAME.BOTTOM_MODAL
      } {
    position: fixed;
    overflow: hidden;
    z-index: 99999;
    padding-top: var(--close-button-position-padding);
    padding-right: var(--close-button-position-padding);
    padding-left: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    ${generatePopUpCSSPositionParameters(positionType)}
  }`;

    case CONTENTS_TYPE.FULL:
      return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.FULL_MODAL} {
    position: fixed;
    overflow: hidden;
    background: var(--overlay-background-color);
    z-index: 99999;
    box-sizing: border-box;
    overflow-wrap: break-word;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }`;
    default:
      console.error(`Invalid contents type ${contentsType}`);
      return "";
  }
};

const createContentsBaseStyle = (
  contentsType: ContentsType,
  format: CustomContentsFormat,
  topPadding: number,
  bottomPadding: number,
  rightPadding: number,
  leftPadding: number
) => {
  if (contentsType === CONTENTS_TYPE.FULL) {
    const padding = `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`;

    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: var(--modal-width);
    height: var(--modal-height);
    background: var(--background-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, .16);
    border-radius: var(--base-border-radius);
    padding: ${padding};
    font-size: 0px;
    text-align: center;
    border: var(--modal-border-size) solid var(--border-color);
    max-height: var(--base-max-height);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar {
    width: 12px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }`;
  } else if (contentsType === CONTENTS_TYPE.PARTIAL) {
    const padding = `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`;

    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE} {
    box-sizing: border-box;
    width: var(--modal-width);
    height: var(--modal-height);
    background: var(--background-color);
    margin: 0 auto;
    padding: ${padding};
    border-radius: var(--base-border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    font-size: 0px;
    text-align: center;
    border: var(--modal-border-size) solid var(--border-color);
    max-height: var(--base-max-height);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar {
    width: 12px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }`;
  }
};

const createArticleStyle = (format: CustomContentsFormat) => {
  return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE
  } + .${CREATIVE_PARTS_CLASS_NAME.ARTICLE},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME
  } + .${CREATIVE_PARTS_CLASS_NAME.ARTICLE},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.HEADLINE
  } + .${CREATIVE_PARTS_CLASS_NAME.ARTICLE}{
    margin-top:8px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.ARTICLE} {
    color: var(--article-color);
    font-size: var(--article-font-size);
    margin: 0;
    text-align: ${
      format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE
        ? "left"
        : "center"
    };
    line-height: 1.2;
  }`;
};

const createHeadlineStyle = (
  format: CustomContentsFormat,
  parts: CreativeParts[]
) => {
  if (
    format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE &&
    parts.length > 1 &&
    parts[1] !== CREATIVE_PARTS.HEADLINE
  ) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.HEADLINE},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE} + .${CREATIVE_PARTS_CLASS_NAME.HEADLINE}{
    margin-top:8px;
  }

  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} {
    color: var(--headline-font-color);
    font-size: var(--headline-font-size);
    font-weight: 700;
    margin: 0;
    text-align: left;
    line-height: 1.2;
  }`;
  } else {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.HEADLINE},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME} + .${CREATIVE_PARTS_CLASS_NAME.HEADLINE}{
    margin-top:8px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} {
    color: var(--headline-font-color);
    font-size: var(--headline-font-size);
    font-weight: 700;
    margin: 0;
    text-align: center;
    line-height: 1.2;
  }`;
  }
};

const createSubHeadlineStyle = () => {
  return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE} {
    box-sizing: border-box;
    color: var(--sub-headline-font-color);
    background-color: var(--sub-headline-background-color);
    border-radius: var(--sub-headline-background-radius);
    font-size: var(--sub-headline-font-size);
    font-weight: 700;
    display: inline-block;
    margin: 0;
    padding: var(--sub-headline-background-padding);
    max-width: 100%;
    line-height: 1.2;
  }`;
};

const createButtonStyle = (type: ColorType) => {
  return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.BUTTON} {
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    font-size: var(--button-font-size);
    color: var(--button-font-color);
    ${
      type === COLOR_TYPE.SIMPLE ? "background-color" : "background"
    }: var(--button-background-color);
    font-weight: 700;
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    border: none;
    cursor: pointer;
    transition: opacity .3s ease;
    margin: 8px 0 0 0;
    max-width: 100%;
    box-shadow: var(--button-shadow);
    line-height: 1.2;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.BUTTON
  }:hover {
    opacity: 0.7;
  }`;
};

const createCouponCodeStyle = () => {
  return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE} +  .${CREATIVE_PARTS_CLASS_NAME.COUPON_AREA},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME} + .${CREATIVE_PARTS_CLASS_NAME.COUPON_AREA},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.ARTICLE} + .${CREATIVE_PARTS_CLASS_NAME.COUPON_AREA},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.COUPON_AREA}{
    margin-top:8px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.COUPON_CODE} {
    box-sizing: border-box;
    margin-bottom: 0;
    border: none;
    box-shadow: none;
    background: var(--coupon-code-background-color);
    padding: 8px;
    color: var(--coupon-code-color);
    font-size: var(--coupon-code-font-size);
    max-width: 100%;
  }

  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.COUPON_CODE} + .${CREATIVE_PARTS_CLASS_NAME.COUPON_COPY_BUTTON}{
    margin-top:8px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.COUPON_COPY_BUTTON} {
    box-sizing: border-box;
    text-decoration: none;
    font-size: var(--coupon-copy-button-font-size);
    color: var(--coupon-copy-button-font-color);
    background: var(--coupon-copy-button-background-color);
    font-weight: bold;
    padding: var(--coupon-copy-button-padding);
    border-radius: var(--coupon-copy-button-radius);
    border: none;
    cursor: pointer;
    transition: opacity .3s ease;
    max-width: 100%;
    box-shadow: var(--coupon-button-shadow);
    line-height: 1.2;
  }`;
};

const createImageStyle = (format: CustomContentsFormat) => {
  if (format === CUSTOM_CONTENTS_FORMAT.FULL_IMAGE) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE} {
    width: 100%;
  }`;
  } else if (format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE} +  .${CREATIVE_PARTS_CLASS_NAME.CONTAINER},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.ARTICLE} + .${CREATIVE_PARTS_CLASS_NAME.CONTAINER},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.CONTAINER}{
    margin-top:8px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME} {
    width: var(--small-image-size);
    margin-right: 10px;
  }

  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE} {
    width: 100%;
  }`;
  } else if (format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_LARGE_IMAGE) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE} +  .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.ARTICLE} + .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME},
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME}{
    margin-top:8px;
  }
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE} {
    width: 100%;
  }`;
  }
};

const createIconStyle = () => {
  return ``;
};

const createFixedAreaStyle = (contentsType: ContentsType) => {
  if (contentsType === CONTENTS_TYPE.FULL) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.FIXED_AREA} {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(50%, -50%);
  }`;
  } else if (contentsType === CONTENTS_TYPE.PARTIAL) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.FIXED_AREA} {
    position: absolute;
    top: 0px;
    right: 0px;
  }`;
  }
};

const createCloseStyle = () => {
  return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CLOSE} {
    display: block;
    line-height: 0;
    width: var(--close-button-size);
    height: var(--close-button-size);
    padding: var(--close-button-padding);
    border-radius: var(--close-button-radius);
    background-color: var(--close-button-background-color);
    cursor: pointer;
    transition: opacity .3s ease;
    box-sizing: content-box;
  }

  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CLOSE} path {
    fill: var(--close-button-color);
  }

  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CLOSE}:hover {
    opacity: 0.7;
  }`;
};

const createContainerStyle = (format: CustomContentsFormat) => {
  if (format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE) {
    return `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTAINER} {
    display: flex;
    align-items: center;
  }

  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTAINER_TEXT} {
    width: var(--small-image-text-area-size);
  }`;
  }

  return "";
};

const createPCStyle = (format: CustomContentsFormat) => {
  return `
  @media screen and (min-width: 600px) {
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE
  } {
      width: var(--pc-modal-width);
      height: var(--pc-modal-height);
      border-radius: var(--pc-base-border-radius);
      padding: var(--pc-base-padding);
      max-height: var(--pc-base-max-height);
    }

    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.ARTICLE
  } {
      font-size: var(--pc-article-font-size);
    }

    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.HEADLINE
  } {
      font-size: var(--pc-headline-font-size);
    }

    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE
  } {
      border-radius: var(--pc-sub-headline-background-radius);
      font-size: var(--pc-sub-headline-font-size);
      padding: var(--pc-sub-headline-background-padding);
    }

    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.BUTTON
  } {
      font-size: var(--pc-button-font-size);
      padding: var(--pc-button-padding);
      border-radius: var(--pc-button-border-radius);
    }
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.COUPON_CODE
  } {
      font-size: var(--pc-coupon-code-font-size);
    }
  
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
    CREATIVE_PARTS_CLASS_NAME.COUPON_COPY_BUTTON
  } {
      font-size: var(--pc-coupon-copy-button-font-size);
      padding: var(--pc-coupon-copy-button-padding);
      border-radius: var(--pc-coupon-copy-button-radius);
    }

    ${
      format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE
        ? `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME} {
      width: var(--pc-small-image-size);
    }
    
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTAINER_TEXT} {
      width: var(--pc-small-image-text-area-size);
    }`
        : ""
    }

    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CLOSE} {
      width: var(--pc-close-button-size);
      height: var(--pc-close-button-size);
      padding: var(--pc-close-button-padding);
      border-radius: var(--pc-close-button-radius);
    }
  }`;
};

export class StyleHtmlCodec {
  encode = (
    parts: CreativeParts[],
    contents: {
      contentsType: ContentsType;
      positionType: PositionType;
      format: CustomContentsFormat;
      isEnablePCStyle?: boolean;
    },
    styles: ActionCreationContentsStyleParameters,
    pcStyles?: ActionCreationContentsPCStyleParameters
  ) => {
    let items = [];

    const closeButtonSizeParams: CloseButtonSizeParameters =
      CLOSE_BUTTON_STYLE.filter((e) => e.key === styles.closeButtonSize)[0];

    const pcCloseButtonSizeParams: CloseButtonSizeParameters =
      CLOSE_BUTTON_STYLE.filter((e) => e.key === pcStyles?.closeButton.size)[0];

    items.push(
      createStyleParameters(
        styles,
        closeButtonSizeParams,
        contents.isEnablePCStyle,
        pcStyles,
        pcCloseButtonSizeParams
      )
    );

    items.push(
      createContentsBaseStyle(
        contents.contentsType,
        contents.format,
        styles.contentsBaseTopPadding,
        styles.contentsBaseBottomPadding,
        styles.contentsBaseRightPadding,
        styles.contentsBaseLeftPadding
      )
    );

    items.push(createContainerStyle(contents.format));

    if (contents.format)
      items.push(
        createPositionParameters(contents.contentsType, contents.positionType)
      );

    if (parts.includes(CREATIVE_PARTS.HEADLINE))
      items.push(createHeadlineStyle(contents.format, parts));

    if (parts.includes(CREATIVE_PARTS.SUB_HEADLINE))
      items.push(createSubHeadlineStyle());

    if (parts.includes(CREATIVE_PARTS.ARTICLE))
      items.push(createArticleStyle(contents.format));

    if (parts.includes(CREATIVE_PARTS.BUTTON))
      items.push(createButtonStyle(styles.buttonColorType));

    if (parts.includes(CREATIVE_PARTS.COUPON_CODE))
      items.push(createCouponCodeStyle());

    if (
      parts.includes(CREATIVE_PARTS.IMAGE) ||
      parts.includes(CREATIVE_PARTS.MAGNIFICATION_IMAGE)
    )
      items.push(createImageStyle(contents.format));

    if (parts.includes(CREATIVE_PARTS.LINK_IMAGE))
      items.push(createImageStyle(contents.format));

    if (parts.includes(CREATIVE_PARTS.ICON)) items.push(createIconStyle());

    items.push(createFixedAreaStyle(contents.contentsType));

    items.push(createCloseStyle());

    if (contents.isEnablePCStyle) {
      items.push(createPCStyle(contents.format));
    }

    return `<style>
    ${items.map((e) => e).join("\n")}
</style>`;
  };
  decode = (styleNode: Node) => {
    console.log(styleNode.innerText);
  };
}
