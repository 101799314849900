import { useCallback, useState } from "react";
import { DestinationCollectRule } from "types/destination_collect_rule";
import RestApi from "utils/RestApi";
import DestinationCollectRuleRepository from "utils/repositories/DestinationCollectRuleRepository";

const useDestinationCollectRule = (
  managementApi: RestApi
): [DestinationCollectRule | null, (uuid: string) => void] => {
  const [destinationCollectRule, setDestinationCollectRule] =
    useState<DestinationCollectRule | null>(null);

  const get = useCallback(
    (destinationCollectRuleUuid: string) => {
      setDestinationCollectRule(null);
      new DestinationCollectRuleRepository(managementApi)
        .get({ destination_collect_rule_uuid: destinationCollectRuleUuid })
        .then((response) => {
          if (response) {
            setDestinationCollectRule(response.item);
          }
        });
    },
    [managementApi]
  );

  return [destinationCollectRule, get];
};

export default useDestinationCollectRule;
