import { useCallback, useEffect, useState } from "react";

import { ActionRepository } from "utils/ActionsRepository";
import { Action } from "interfaces/models";

const useActions = (
  actionRepository: ActionRepository
): [Action[] | undefined, () => Promise<void>, string] => {
  const [actions, setActions] = useState<Action[]>();

  const [error, setError] = useState<string>("");

  const loadAction = useCallback<() => Promise<void>>(async () => {
    try {
      setActions(undefined);
      setActions(await actionRepository.load());
      setError("");
    } catch (error) {
      if (error instanceof Error) {
        setError("アクションの読み込みに失敗しました");
        console.error(error);
      } else {
        console.error(error);
      }
    }
  }, [actionRepository, setError]);

  useEffect(() => {
    loadAction();
  }, [loadAction]);

  return [actions, async () => loadAction(), error];
};

export default useActions;
