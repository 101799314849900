import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import ImageTextTile from "atoms/accountPagesShared/ImageTextTile";
import ImageTile from "atoms/accountPagesShared/ImageTile";
import ItemName from "molecules/ItemName";
import ContentsLayoutFormView from "organisms/account/action_creation/contents_edit/ContentsLayoutFormView";
import TileLayoutView from "organisms/account/action_creation/contents_edit/TileLayoutView";
import ModalIcon from "images/icons/action_creation/format_select/modal.svg";
import PopUpIcon from "images/icons/action_creation/format_select/pop_up.svg";
import { loadCustomFormatPatterns } from "utils/action_creation/CustomContentsPatterns";
import EventText from "atoms/accountPagesShared/EventText";
import FormTitleView from "organisms/account/FormTitleView";
import UnavailableBoard from "molecules/formParts/UnavailableBoard";
import UnavailableContentsView from "organisms/account/action_creation/contents_edit/UnavailableContentsView";

import {
  CONTENTS_TYPE,
  POSITION_TYPE,
  PositionType,
  ContentsType,
  CustomContentsFormat,
} from "interfaces/model/actionContentsParameters";
import { ContentsSettingPhaseCategory } from "interfaces/view/actionCreation";

type CreateContentsLayoutFormProps = {
  params: {
    isEditRawHTML: boolean;
    contentsType: ContentsType;
    positionType: PositionType;
    format: CustomContentsFormat;
    pattern: number;
  };
  services: {
    onSelectContentsSettingPhaseCategory(
      category: ContentsSettingPhaseCategory
    ): void;
    onChangeContentsAttribute(
      contentsType: ContentsType,
      positionType: PositionType
    ): void;
    onSelectContentsType(contentsType: ContentsType): void;
    onSelectPositionType(positionType: PositionType): void;
    onSelectContentsPattern(pattern: number): void;
    onClickFormatReselect(): void;
  };
};

const CreateContentsLayoutForm = ({
  params,
  services,
}: CreateContentsLayoutFormProps) => {
  if (params.isEditRawHTML) {
    return (
      <UnavailableContentsView
        boardElement={
          <UnavailableContentsView
            boardElement={
              <UnavailableBoard
                text={
                  <>
                    HTMLソースが変更されているため
                    <br />
                    「レイアウト」は利用できません。
                  </>
                }
              />
            }
          />
        }
      />
    );
  }
  // TODO: position list decide logic move to application layer
  const positions = {
    [CONTENTS_TYPE.PARTIAL]: [
      { value: POSITION_TYPE.LEFT_TOP, label: "左上" },
      { value: POSITION_TYPE.CENTER_TOP, label: "中央上" },
      { value: POSITION_TYPE.RIGHT_TOP, label: "右上" },
      { value: POSITION_TYPE.RIGHT_BOTTOM, label: "右下" },
      { value: POSITION_TYPE.CENTER_BOTTOM, label: "中央下" },
      { value: POSITION_TYPE.LEFT_BOTTOM, label: "左下" },
    ],
    [CONTENTS_TYPE.FULL]: [{ value: POSITION_TYPE.CENTER, label: "中央" }],
  };

  const contentsTypes = [
    { value: CONTENTS_TYPE.FULL, label: "モーダル", img: ModalIcon },
    { value: CONTENTS_TYPE.PARTIAL, label: "ポップアップ", img: PopUpIcon },
  ];

  const patterns = loadCustomFormatPatterns(params.format);

  return (
    <ContentsLayoutFormView
      contentsTypeElement={
        <TileLayoutView
          titleElement={<ItemName text="タイプ" />}
          tileElements={contentsTypes.map((e) => (
            <ImageTextTile
              text={e.label}
              img={<img src={e.img} alt="" />}
              isActive={params.contentsType === e.value}
              onClick={() => {
                services.onSelectContentsType(e.value);
              }}
            />
          ))}
        />
      }
      contentsPlaceElement={
        <TileLayoutView
          titleElement={<ItemName text="配置" />}
          tileElements={[
            <BorderedSelectBox
              options={positions[params.contentsType]}
              value={params.positionType}
              onChange={(e) => {
                services.onSelectPositionType(e as PositionType);
              }}
            />,
          ]}
        />
      }
      formatPatternElement={
        <TileLayoutView
          titleElement={
            <FormTitleView
              titleElement={<ItemName text="パターン" />}
              subElement={
                <EventText
                  text="フォーマットを選び直す"
                  onClick={() => services.onClickFormatReselect()}
                />
              }
            />
          }
          tileElements={patterns.map((e) => {
            return (
              <ImageTile
                onClick={() => {
                  services.onSelectContentsPattern(e.name);
                }}
                img={<img src={e.icon} alt={`${e.name}`} />}
                isActive={params.pattern === e.name}
              />
            );
          })}
        />
      }
    />
  );
};

export default CreateContentsLayoutForm;
