import { useMemo, useState } from "react";

export type GrantMessageContentsTitleDesignParameters = {
  text: string;
  fontSize: number;
  fontColor: string;
};

export type GrantMessageContentsTitleDesignCallbacks = {
  updateText: (value: string) => void;
  updateFontSize: (value: number) => void;
  updateFontColor: (value: string) => void;
};

export const useGrantMessageContentsTitleDesign = (
  currentParameters: GrantMessageContentsTitleDesignParameters
): [
  GrantMessageContentsTitleDesignParameters,
  GrantMessageContentsTitleDesignCallbacks
] => {
  const [text, setText] = useState<string>(currentParameters.text);
  const [fontSize, setFontSize] = useState<number>(currentParameters.fontSize);
  const [fontColor, setFontColor] = useState<string>(
    currentParameters.fontColor
  );

  const parameters = useMemo(() => {
    return {
      text,
      fontSize,
      fontColor,
    };
  }, [text, fontSize, fontColor]);

  const callbacks = useMemo(() => {
    return {
      updateText: (value: string) => {
        setText(value);
      },
      updateFontSize: (value: number) => {
        setFontSize(value);
      },
      updateFontColor: (value: string) => {
        setFontColor(value);
      },
    };
  }, [setText, setFontSize, setFontColor]);

  return [parameters, callbacks];
};
