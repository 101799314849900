import React from "react";
import styles from "./SpreadLayout.module.scss";

const SpreadLayout: React.VFC<{
  items: JSX.Element[];
}> = ({ items }) => (
  <div className={styles.spreadLayout}>
    {items.map((item, i) => (
      <div key={i}>{item}</div>
    ))}
  </div>
);

export default SpreadLayout;
