import styles from "./ContentsLayoutFormView.module.scss";

type ContentsLayoutFormViewProps = {
  contentsTypeElement: JSX.Element;
  contentsPlaceElement: JSX.Element;
  formatPatternElement: JSX.Element;
};

const ContentsLayoutFormView = ({
  contentsTypeElement,
  contentsPlaceElement,
  formatPatternElement,
}: ContentsLayoutFormViewProps) => {
  return (
    <div className={styles.ContentsLayoutFormView}>
      <div className={styles.contentsType}>{contentsTypeElement}</div>
      <div className={styles.contentsPlace}>{contentsPlaceElement}</div>
      <div className={styles.formatPattern}>{formatPatternElement}</div>
    </div>
  );
};

export default ContentsLayoutFormView;
