import styles from "./NewPasswordForm.module.scss";

type NewPasswordFromProps = {
  headlineElement: JSX.Element;
  passwordFormElement: JSX.Element;
  passwordConfirmFormElement: JSX.Element;
  submitButtomElement: JSX.Element;
  errorMessageElement: JSX.Element;
};

const NewPasswordFrom = ({
  headlineElement,
  passwordFormElement,
  passwordConfirmFormElement,
  submitButtomElement,
  errorMessageElement,
}: NewPasswordFromProps) => (
  <div className={styles.newPasswordForm}>
    <div className={styles.headline}>{headlineElement}</div>
    <form onSubmit={(event) => event.preventDefault()}>
      <div className={styles.newPassword}>{passwordFormElement}</div>
      <div className={styles.newPasswordConfirm}>
        {passwordConfirmFormElement}
      </div>
      <div className={styles.notice}>
        <p className={styles.sentence}>
          {"※半角英字、半角数字、記号（! # $ % & - / ? @ _）"}
        </p>
        <p className={styles.sentence}>
          {"それぞれ1文字以上使用し、8～20文字以内"}
        </p>
      </div>
      <div>{errorMessageElement}</div>
      <div>{submitButtomElement}</div>
    </form>
  </div>
);

export default NewPasswordFrom;
