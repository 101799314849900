import SettingsEditPhaseFormMain from "organisms/account/action_creation/settings_edit/SettingsEditPhaseFormMain";
import SettingsEditPhaseFormMainSideArea from "organisms/account/action_creation/settings_edit/SettingsEditPhaseFormMainSideArea";
import SideAreaTab from "molecules/SideAreaTab";
import {
  ContentsSettingPhaseCategory,
  CONTENTS_SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";
import CreateSettingsEditPhaseFormMainBodyArea from "app/creators/action_creation/custom/settings_edit/CreateSettingsEditPhaseFormMainBodyArea";
import {
  ActionExecutionConditions,
  ActionFrequency,
  ActionTerminateReaction,
  ExecuteTiming,
  ExpirationPeriod,
  SegmentationRule,
} from "interfaces/models";
import { CustomContentsFormParameters } from "interfaces/model/actionContentsParameters";
import { ActionCreationSettingsExecutionConditions } from "app/hooks/actionCreationPage";

type CreateSettingsEditPhaseFormMainProps = {
  contentsSettingPhaseCategory: ContentsSettingPhaseCategory;
  initValue?: {
    executionConditions: ActionExecutionConditions;
  };
  params: {
    errorMessage?: Object;
    segmentationRuleList: SegmentationRule[];
    isCompleteSettings: boolean;
    name: string;
    expirationPeriod: ExpirationPeriod | null;
    segmentationRuleIds: string[];
    frequency: ActionFrequency;
    terminateReaction: ActionTerminateReaction;
    abRate: number;
    formPattern: CustomContentsFormParameters;
    executionConditions: ActionCreationSettingsExecutionConditions;
    cvUrl: string;
  };
  callbacks: {
    onSelectContentsSettingPhaseCategory(
      category: ContentsSettingPhaseCategory
    ): void;
    setName: (value: string) => void;
    setExpirationPeriod: (value: ExpirationPeriod | null) => void;
    setFrequency: (value: ActionFrequency) => void;
    setTerminateReaction: (value: ActionTerminateReaction) => void;
    setABRate: (value: number) => void;
    setExpirationPeriodEnabled: (e: boolean) => void;
    setCvUrl: (value: string) => void;
    onChangeIncludes(p: ManagementApiData.UrlMatchPattern[]): void;
    onChangeExcludes(p: ManagementApiData.UrlMatchPattern[]): void;
    onChangeSegmentationRules(index: number, ruleId: string): void;
    onResetIncludes(): void;
    onResetExcludes(): void;
    onChangeExecuteTiming(
      isExecuteTimingsActive: boolean,
      executeTiming: ExecuteTiming[] | undefined
    ): void;
    onClickDeleteExecuteTiming(index: number): void;
    onClickInsertExecuteTiming(): void;
    onChangeSetExecuteTimingFromHourValue(index: number, value: number): void;
    onChangeSetExecuteTimingFromTimeValue(index: number, value: number): void;
    onChangeSetExecuteTimingToHourValue(index: number, value: number): void;
    onChangeSetExecuteTimingToTimeValue(index: number, value: number): void;
    onChangeSetExecuteTimingDayOfWeek(index: number, label: string): void;
    onChangeSetExecuteTimingHoliday(index: number): void;
    getExecuteTimingHolidayState(index: number): boolean;
    getExecuteTimingDayOfWeekState(index: number, value: string): boolean;
  };
  form: ActionCreation.FormState &
    ActionCreation.ExpirationPeriodFormState & {
      onClickSegmentationRuleAddButton(): void;
      onClickSegmentationRuleDeleteButton(): void;
    };
};

const CreateSettingsEditPhaseFormMain: React.VFC<
  CreateSettingsEditPhaseFormMainProps
> = ({ contentsSettingPhaseCategory, initValue, params, callbacks, form }) => {
  const buttonParams = [
    { name: "表示", category: CONTENTS_SETTING_PHASE_CATEGORY.CONDITION },
  ];
  return (
    <SettingsEditPhaseFormMain
      sideAreaElement={
        <SettingsEditPhaseFormMainSideArea
          buttons={buttonParams.map((e) => (
            <SideAreaTab
              key={e.category}
              name={e.name}
              isActive={contentsSettingPhaseCategory === e.category}
              isComplete={params.isCompleteSettings}
              onClick={() =>
                callbacks.onSelectContentsSettingPhaseCategory(e.category)
              }
            />
          ))}
        />
      }
      bodyAreaElement={
        <CreateSettingsEditPhaseFormMainBodyArea
          category={contentsSettingPhaseCategory}
          initValue={initValue}
          params={params}
          callbacks={callbacks}
          form={form}
          errors={params.errorMessage}
        />
      }
    />
  );
};

export default CreateSettingsEditPhaseFormMain;
