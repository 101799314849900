import React from "react";
import { DestinationCollectRule } from "types/destination_collect_rule";
import { unixTimeToDateTimeSecond } from "utils/format/unixtime";

import styles from "./SegmentationRuleList.module.scss";

import CopyButton from "atoms/accountPagesShared/CopyButton";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import EditButton from "atoms/accountPagesShared/EditButton";
import OrangeHeaderTable, {
  OrangeHeaderTableRecord,
} from "atoms/ezPushShared/accountPageShared/OrangeHeaderTable";
import TooltipIcon from "atoms/ezPushShared/accountPageShared/TooltipIcon";
import {
  AskControlSegmentationRuleModal,
  RejectSegmentationRuleControlModal,
  CopySegmentationRuleModal,
  AskDeleteSegmentationRuleModal,
  SEGMENTATION_RULE_CONTROL_TYPE,
  SegmentationRuleDetailModal,
  DeleteSegmentationRuleModal,
  ControlSegmentationRuleModal,
} from "./SegmentationRuleListModals";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import { TOOL_TIP_TEXT } from "app/system_defaults/tool_tip_texts";
import CenteringLayout from "templates/ezPush/CenteringLayout";
import DisabledIcon from "atoms/accountPagesShared/DisabledIcon";
import ClickableAreaText from "atoms/ezPushShared/accountPageShared/ClickableAreaText";

const HEADER_NAMES = [
  "セグメント名",
  <HorizontalIconLayout
    elements={[
      <span>想定対象者数</span>,
      <ToolTip
        text={
          TOOL_TIP_TEXT.ACCOUNT.SEGMENTATION_RULE.HOME.DESTINATION_COUNT.LABEL
        }
      />,
    ]}
  />,
  "最終更新日時",
  "操作",
];

const handleEditEvent = (
  destination_collect_rule_uuid: string,
  getConnectedScheduledCampaigns: (
    destinationCollectRuleUuid: string
  ) => string[],
  getConnectedDraftCampaigns: (destinationCollectRuleUuid: string) => string[],
  updateModal: (modal: JSX.Element) => void,
  clearModal: () => void,
  editRule: (destinationCollectRuleUuid: string) => void
) => {
  const schedules = getConnectedScheduledCampaigns(
    destination_collect_rule_uuid
  );
  if (schedules.length > 0) {
    updateModal(
      <RejectSegmentationRuleControlModal
        controlType={SEGMENTATION_RULE_CONTROL_TYPE.EDIT}
        campaignNames={schedules}
        clearModalElement={clearModal}
      />
    );
  } else {
    const drafts = getConnectedDraftCampaigns(destination_collect_rule_uuid);
    if (drafts.length > 0) {
      updateModal(
        <AskControlSegmentationRuleModal
          controlType={SEGMENTATION_RULE_CONTROL_TYPE.EDIT}
          campaignNames={drafts}
          submit={() => editRule(destination_collect_rule_uuid)}
          clear={clearModal}
        />
      );
    } else {
      editRule(destination_collect_rule_uuid);
    }
  }
};

const handleDeleteEvent = (
  destination_collect_rule_uuid: string,
  getConnectedScheduledCampaigns: (
    destinationCollectRuleUuid: string
  ) => string[],
  getConnectedDraftCampaigns: (destinationCollectRuleUuid: string) => string[],
  updateModal: (modal: JSX.Element) => void,
  clearModal: () => void,
  deleteRule: (destinationCollectRuleUuid: string) => Promise<void>
) => {
  const schedules = getConnectedScheduledCampaigns(
    destination_collect_rule_uuid
  );
  if (schedules.length > 0) {
    updateModal(
      <RejectSegmentationRuleControlModal
        controlType={SEGMENTATION_RULE_CONTROL_TYPE.DELETE}
        campaignNames={schedules}
        clearModalElement={clearModal}
      />
    );
  } else {
    const drafts = getConnectedDraftCampaigns(destination_collect_rule_uuid);
    if (drafts.length > 0) {
      updateModal(
        <AskControlSegmentationRuleModal
          controlType={SEGMENTATION_RULE_CONTROL_TYPE.DELETE}
          campaignNames={drafts}
          submit={() => {
            updateModal(
              <ControlSegmentationRuleModal
                controlType={SEGMENTATION_RULE_CONTROL_TYPE.DELETE}
                campaignNames={drafts}
              />
            );
            deleteRule(destination_collect_rule_uuid)
              .then(clearModal)
              .catch(clearModal);
          }}
          clear={clearModal}
        />
      );
    } else {
      updateModal(
        <AskDeleteSegmentationRuleModal
          submit={() => {
            updateModal(<DeleteSegmentationRuleModal />);
            deleteRule(destination_collect_rule_uuid)
              .then(clearModal)
              .catch(clearModal);
          }}
          clearModalElement={clearModal}
        />
      );
    }
  }
};

const ControlButtons: React.VFC<{
  destination_collect_rule_uuid: string;
  deleteRule: (destinationCollectRuleUuid: string) => Promise<void>;
  copyRule: (destinationCollectRuleUuid: string) => void;
  editRule: (destinationCollectRuleUuid: string) => void;
  getConnectedScheduledCampaigns: (
    destinationCollectRuleUuid: string
  ) => string[];
  getConnectedDraftCampaigns: (destinationCollectRuleUuid: string) => string[];
  updateModal: (modal: JSX.Element) => void;
  clearModal: () => void;
}> = ({
  destination_collect_rule_uuid,
  deleteRule,
  copyRule,
  editRule,
  getConnectedScheduledCampaigns,
  getConnectedDraftCampaigns,
  updateModal,
  clearModal,
}) => {
  const buttons = [
    <TooltipIcon
      iconElement={
        <EditButton
          onClick={() =>
            handleEditEvent(
              destination_collect_rule_uuid,
              getConnectedScheduledCampaigns,
              getConnectedDraftCampaigns,
              updateModal,
              clearModal,
              editRule
            )
          }
        />
      }
      comment="編集"
    />,
    <TooltipIcon
      iconElement={
        <DeleteButton
          onClick={() =>
            handleDeleteEvent(
              destination_collect_rule_uuid,
              getConnectedScheduledCampaigns,
              getConnectedDraftCampaigns,
              updateModal,
              clearModal,
              deleteRule
            )
          }
        />
      }
      comment="削除"
    />,
    <TooltipIcon
      iconElement={
        <CopyButton
          onClick={() =>
            updateModal(
              <CopySegmentationRuleModal
                clearModalElement={clearModal}
                submit={() => copyRule(destination_collect_rule_uuid)}
              />
            )
          }
        />
      }
      comment="複製"
    />,
  ];

  return (
    <div className={styles.controllers}>
      {buttons.map((elem, i) => (
        <div key={i} className={styles.item}>
          {elem}
        </div>
      ))}
    </div>
  );
};

const SegmentationRuleList: React.VFC<{
  rules: DestinationCollectRule[] | null;
  urls: MeasurementUrlSettingWithType[] | null;
  deleteRule: (destinationCollectRuleUuid: string) => Promise<void>;
  copyRule: (destinationCollectRuleUuid: string) => void;
  editRule: (destinationCollectRuleUuid: string) => void;
  getConnectedScheduledCampaigns: (
    destinationCollectRuleUuid: string
  ) => string[];
  getConnectedDraftCampaigns: (destinationCollectRuleUuid: string) => string[];
  updateModal: (modal: JSX.Element) => void;
  clearModal: () => void;
}> = ({
  rules,
  urls,
  deleteRule,
  copyRule,
  editRule,
  getConnectedScheduledCampaigns,
  getConnectedDraftCampaigns,
  updateModal,
  clearModal,
}) => {
  const records: OrangeHeaderTableRecord[] | null = rules
    ? rules.map((elem) => {
        return {
          cells: [
            {
              item: (
                <div style={{ marginLeft: "15px" }}>
                  <ClickableAreaText
                    text={elem.name}
                    onClick={() =>
                      updateModal(
                        <SegmentationRuleDetailModal
                          destinationCollectRule={elem}
                          urls={urls}
                          clear={clearModal}
                        />
                      )
                    }
                  />
                </div>
              ),
              isNoPadding: true,
            },
            {
              item:
                elem.destination_count === null
                  ? "集計中"
                  : elem.destination_count,
              width: 114,
              isAlignRight: true,
            },
            { item: unixTimeToDateTimeSecond(elem.updated_at), width: 108 },
            {
              item: elem.is_editable ? (
                <ControlButtons
                  destination_collect_rule_uuid={
                    elem.destination_collect_rule_uuid
                  }
                  deleteRule={deleteRule}
                  copyRule={copyRule}
                  editRule={editRule}
                  getConnectedScheduledCampaigns={
                    getConnectedScheduledCampaigns
                  }
                  getConnectedDraftCampaigns={getConnectedDraftCampaigns}
                  updateModal={updateModal}
                  clearModal={clearModal}
                />
              ) : (
                <CenteringLayout
                  element={
                    <TooltipIcon
                      iconElement={<DisabledIcon />}
                      comment="このセグメントは編集・削除・複製することができません"
                    />
                  }
                />
              ),
              width: 98,
            },
          ],
        };
      })
    : null;

  return (
    <div className={styles.segmentationRuleList}>
      <OrangeHeaderTable
        headers={HEADER_NAMES}
        records={records}
        blankMessage={"セグメント設定はありません"}
      />
    </div>
  );
};

export default SegmentationRuleList;
