import { DateRange } from "interfaces/view/accout";
import CalenderIcon from "images/icons/icon_calendar.svg";
import SelectBoxArrowIcon from "images/icons/icon_select_box_arrow.svg";
import SelectBoxActiveArrowIcon from "images/icons/icon_select_box_active_arrow.svg";
import styles from "./DateRangePickerOpener.module.scss";

const formatDate = (date: Date) => {
  const _month = ("0" + (date.getMonth() + 1)).slice(-2);
  const _date = ("0" + date.getDate()).slice(-2);
  return `${date.getFullYear()}/${_month}/${_date}`;
};

interface DateRangePickerOpenerProps {
  dateRange: DateRange;
  isOpen: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const DateRangePickerOpener = ({
  dateRange,
  isOpen,
  onClick,
}: DateRangePickerOpenerProps) => {
  return (
    <button className={styles.dateRangePickerOpener} onClick={onClick}>
      <div className={styles.buttonLeft}>
        <div className={styles.icon}>
          <img src={CalenderIcon} alt="" />
        </div>
        <div className={styles.dateRange}>
          {formatDate(dateRange.start)} - {formatDate(dateRange.end)}
        </div>
      </div>
      <div className={styles.buttonRight}>
        <img
          src={isOpen ? SelectBoxActiveArrowIcon : SelectBoxArrowIcon}
          alt=""
        />
      </div>
    </button>
  );
};

export default DateRangePickerOpener;
