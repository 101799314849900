import {
  ActionCreationCommonCallbacks,
  ACTION_CREATION_PHASE,
  DesignSettingPhaseCategory,
  SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";
import CreateCompletePhaseFormMain from "./complete/CreateCompletePhaseFormMain";
import CreateContentsEditPhaseFormMain from "./contents_edit/CreateContentsEditPhaseFormMain";
import CreateFormatSelectPhaseFormMain from "./format_select/CreateFormatSelectPhaseFormMain";
import CreateSettingsEditPhaseFormMain from "./settings_edit/CreateSettingsEditPhaseFormMain";
import {
  ActionCreationPageState,
  ActionCreationPageViewCallbacks,
} from "app/hooks/useActionCreationService";
import { ACTION_TYPES } from "interfaces/models";
import {
  ACTION_CREATION_MODE,
  CONTENTS_TYPE,
  ContentsType,
  PositionType,
} from "interfaces/model/actionContentsParameters";
import { assertNever } from "utils/assertions";

const CreateCustomActionCreationFormMain: React.VFC<{
  appState: ActionCreationPageState;
  appCallbacks: ActionCreationCommonCallbacks;
  viewCallbacks: ActionCreationPageViewCallbacks;
  formState: ActionCreation.FormState;
}> = ({ appState, appCallbacks, viewCallbacks, formState }) => {
  const preview = {
    url: appState.actionCreationSettings.contents.details.url || "",
    html: appState.previewHtml,
    actionType:
      appState.actionCreationSettings.contents.contentsType ===
      CONTENTS_TYPE.FULL
        ? ACTION_TYPES.FULL_MODAL
        : ACTION_TYPES.BOTTOM_MODAL,
    decorationEditDeviceType: appState.decorationEditDeviceType,
    onChangeUrl: viewCallbacks.contents.setUrl,
    onChangeDecorationEditDeviceType:
      appCallbacks.changeDecorationEditDeviceType,
  };

  switch (appState.phase) {
    case ACTION_CREATION_PHASE.MODE_SELECT:
      return null;
    case ACTION_CREATION_PHASE.FORMAT_SELECT:
      return (
        <CreateFormatSelectPhaseFormMain
          format={appState.actionCreationSettings.contents.details.customFormat}
          onSelectCustomFormat={appCallbacks.selectCustomFormat}
        />
      );
    case ACTION_CREATION_PHASE.SETTINGS_EDIT:
      const settingsPhaseCommon = {
        onSelectContentsSettingPhaseCategory:
          appCallbacks.changeContentsSettingPhaseCategory,
      };

      switch (appState.settingPhaseCategory) {
        case SETTING_PHASE_CATEGORY.VIEW:
          const viewSettings = {
            /////////////////////
            // settings events //
            /////////////////////
            onChangeContentsAttribute(
              contentsType: ContentsType,
              positionType: PositionType
            ): void {
              appCallbacks.onChangeContentsAttribute(
                contentsType,
                positionType
              );
            },
            onSelectContentsType(contentsType: ContentsType) {
              appCallbacks.setContentsType(contentsType);
            },
            onSelectPositionType(positionType: PositionType) {
              appCallbacks.setPositionType(positionType);
            },
            onSelectContentsPattern(pattern: number): void {
              appCallbacks.setContentsPattern(pattern);
            },
            onClickFormatReselect() {
              appCallbacks.changePhase(ACTION_CREATION_PHASE.FORMAT_SELECT);
            },

            ///////////////////
            // design events //
            ///////////////////
            onSelectDesignPhaseCategory(
              category: DesignSettingPhaseCategory
            ): void {
              appCallbacks.changeCategory(category);
            },
            onChangeHtmlSource(source: string): void {
              appCallbacks.updateRawHtml(source);
            },
            onClickResetHtml: () => appCallbacks.requestResettingHtml(),
            onChangeContentsImage(image: File | undefined): void {
              appCallbacks.changeContentsImage(image);
            },
            setErrorMessage: viewCallbacks.viewStateCallbacks.setErrorMessage,
            onChangeDecorationEditDeviceType:
              appCallbacks.changeDecorationEditDeviceType,
            onChangeIsEnablePCStyle: () => {
              viewCallbacks.contents.setIsEnablePCStyle(
                !appState.actionCreationSettings.contents.details
                  .isEnablePCStyle
              );
              if (
                !appState.actionCreationSettings.contents.details
                  .isEnablePCStyle
              ) {
                appCallbacks.onActivePCStyle();
              }
            },
          };

          return (
            <CreateContentsEditPhaseFormMain
              params={{
                mode: ACTION_CREATION_MODE.CUSTOM,
                designPhaseCategory:
                  appState.modeParams[ACTION_CREATION_MODE.CUSTOM]
                    .designPhaseCategory,
                isEditRawHTML:
                  appState.modeParams[ACTION_CREATION_MODE.CUSTOM]
                    .isEditRawHTML,
                html: appState.actionCreationSettings.contents.htmlTemplate,
                contentsType:
                  appState.actionCreationSettings.contents.details.contentsType,
                positionType:
                  appState.actionCreationSettings.contents.details.positionType,
                format:
                  appState.actionCreationSettings.contents.details.customFormat,
                pattern:
                  appState.actionCreationSettings.contents.details.patternIndex,
                image: appState.actionCreationSettings.contents.image,
                version:
                  appState.actionCreationSettings.contents.details.version,
                decorationEditDeviceType: appState.decorationEditDeviceType,
                isEnablePCStyle:
                  appState.actionCreationSettings.contents.details
                    .isEnablePCStyle,
              }}
              callbacks={{
                ...settingsPhaseCommon,
                ...viewSettings,
              }}
              styles={{
                params: appState.actionCreationSettings.contents.details.styles,
                callbacks: viewCallbacks.styles,
              }}
              pcStyles={{
                params:
                  appState.actionCreationSettings.contents.details.pcStyles,
                callbacks: viewCallbacks.pcStyles,
              }}
              preview={preview}
            />
          );
        case SETTING_PHASE_CATEGORY.CONDITION: {
          const periodFormState: ActionCreation.ExpirationPeriodFormState = {
            isExpirationPeriodEnabled:
              !!appState.actionCreationSettings.executionConditions
                .expirationPeriod,
            setExpirationPeriodVisibility: (e: boolean) => {
              if (e) {
                appCallbacks.setExpirationPeriod({});
              } else {
                appCallbacks.setExpirationPeriod(undefined);
              }
            },
          };

          const s =
            appState.actionCreationSettings.executionConditions
              .segmentationRules;
          const segmentationRuleIds = s.create ? [] : s.segmentationRuleIds;
          return (
            <CreateSettingsEditPhaseFormMain
              contentsSettingPhaseCategory={
                appState.modeParams[ACTION_CREATION_MODE.CUSTOM]
                  .contentsSettingPhaseCategory
              }
              initValue={appState.sourceAction || undefined}
              // NOTE: deprecated
              //   the appState.actionCreationSettings access from form should be write only.
              //   use instead sourceAction values.
              params={{
                name: appState.actionCreationSettings.name,
                expirationPeriod:
                  appState.actionCreationSettings.executionConditions
                    .expirationPeriod,
                segmentationRuleIds:
                  segmentationRuleIds.length > 0 ? segmentationRuleIds : [],
                frequency:
                  appState.actionCreationSettings.executionConditions.frequency,
                terminateReaction:
                  appState.actionCreationSettings.executionConditions
                    .terminateReaction,
                abRate: appState.actionCreationSettings.contents.abTestARate,
                segmentationRuleList: appState.segmentationRuleList || [],
                errorMessage: appState.errorMessage,
                isCompleteSettings:
                  appState.modeParams[ACTION_CREATION_MODE.CUSTOM]
                    .isCompleteSettings,
                formPattern:
                  appState.actionCreationSettings.contents.details
                    .formatPatterns[
                    appState.actionCreationSettings.contents.details
                      .patternIndex
                  ],
                executionConditions:
                  appState.actionCreationSettings.executionConditions,
                cvUrl: appState.actionCreationSettings.cvUrl,
              }}
              callbacks={{
                ...settingsPhaseCommon,
                onChangeIncludes: appCallbacks.setIncludes,
                onChangeExcludes: appCallbacks.setExcludes,
                onChangeSegmentationRules: (
                  index: number,
                  ruleId: string
                ): void => {
                  const s =
                    appState.actionCreationSettings.executionConditions
                      .segmentationRules;
                  const segmentationRuleIds = s.create
                    ? []
                    : s.segmentationRuleIds;
                  let e = [...segmentationRuleIds];

                  e[index] = ruleId;
                  appCallbacks.setSegmentationRules(e);
                },
                onResetIncludes: appCallbacks.resetUrlConditionIncludes,
                onResetExcludes: appCallbacks.resetUrlConditionExcludes,
                ...viewCallbacks.fromEventHandlers,
                onChangeExecuteTiming: appCallbacks.setExecuteTiming,
                onClickDeleteExecuteTiming: appCallbacks.deleteExecuteTimings,
                onClickInsertExecuteTiming: appCallbacks.insertExecuteTimings,
                onChangeSetExecuteTimingFromHourValue:
                  appCallbacks.setExecuteTimingFromHourValue,
                onChangeSetExecuteTimingFromTimeValue:
                  appCallbacks.setExecuteTimingFromTimeValue,
                onChangeSetExecuteTimingToHourValue:
                  appCallbacks.setExecuteTimingToHourValue,
                onChangeSetExecuteTimingToTimeValue:
                  appCallbacks.setExecuteTimingToTimeValue,
                onChangeSetExecuteTimingDayOfWeek:
                  appCallbacks.setExecuteTimingDayOfWeek,
                onChangeSetExecuteTimingHoliday:
                  appCallbacks.setExecuteTimingHoliday,
                getExecuteTimingHolidayState:
                  appCallbacks.getExecuteTimingHolidayState,
                getExecuteTimingDayOfWeekState:
                  appCallbacks.getExecuteTimingDayOfWeekState,
              }}
              form={{
                ...formState,
                ...periodFormState,
                onClickSegmentationRuleAddButton: () => {
                  appCallbacks.addSegumentationRule();
                },
                onClickSegmentationRuleDeleteButton: () => {
                  appCallbacks.deleteSegumentationRule();
                },
              }}
            />
          );
        }
        default:
          return null;
      }
    case ACTION_CREATION_PHASE.COMPLETE:
      return (
        <CreateCompletePhaseFormMain
          status={appState.status}
          createdAction={appState.createdAction || undefined}
          name={appState.actionCreationSettings.name}
          preview={preview}
        />
      );
    default:
      assertNever(appState.phase);
      return null;
  }
};

export default CreateCustomActionCreationFormMain;
