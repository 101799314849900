import React, { useMemo } from "react";

import fontStyles from "fontStyles.module.scss";

import {
  PushNotificationPageCallbacks,
  PushNotificationPageParameters,
} from "app/hooks/v2/pushNotification/usePushNotificationPage";

import OrangeButton from "atoms/ezPushShared/OrangeButton";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import PushNotificationLists from "./PushNotificationLists";
import TitleMainLayout from "templates/ezPush/TitleMainLayout";

const PushNotificationPanel: React.VFC<{
  parameters: PushNotificationPageParameters;
  callbacks: PushNotificationPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const titleElement = useMemo(() => {
    return (
      <HorizontalLayout
        elements={[
          <span className={fontStyles.title}>プッシュ通知一覧</span>,
          <OrangeButton
            text="新規作成"
            onClick={callbacks.events.goPushMessageEditor}
          />,
        ]}
      />
    );
  }, [callbacks.events.goPushMessageEditor]);

  return (
    <TitleMainLayout
      titleElement={titleElement}
      mainElement={
        <PushNotificationLists parameters={parameters} callbacks={callbacks} />
      }
    />
  );
};

export default PushNotificationPanel;
