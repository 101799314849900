import React from "react";
import styles from "./HorizontalIconLayout.module.scss";

const HorizontalIconLayout: React.VFC<{
  elements: JSX.Element[];
  margin?: number;
}> = ({ elements, margin }) => {
  return (
    <div className={styles.horizontalIconLayout}>
      {elements.map((elem, i) => (
        <div
          key={i}
          className={styles.item}
          style={{
            marginRight: i === elements.length - 1 ? 0 : `${margin}px`,
          }}
        >
          {elem}
        </div>
      ))}
    </div>
  );
};

export default HorizontalIconLayout;
