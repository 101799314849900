import { useCallback, useState } from "react";
import { DestinationCollectRule } from "types/destination_collect_rule";
import RestApi from "utils/RestApi";
import DestinationCollectRuleRepository from "utils/repositories/DestinationCollectRuleRepository";

const useDestinationCollectRules = (
  managementApi: RestApi
): [DestinationCollectRule[] | null, () => void] => {
  const [destinationCollectRules, setDestinationCollectRules] = useState<
    DestinationCollectRule[] | null
  >(null);

  const load = useCallback(() => {
    setDestinationCollectRules(null);
    new DestinationCollectRuleRepository(managementApi)
      .load()
      .then((response) => {
        if (response) {
          setDestinationCollectRules(response.items);
        }
      });
  }, [managementApi]);

  return [destinationCollectRules, load];
};

export default useDestinationCollectRules;
