import { useEffect, useState } from "react";

import CreateFooter from "app/creators/CreateFooter";
import CreateAccountPageSharedElements from "app/creators/CreateAccountPageSharedElements";

import { ClientPageTemplate } from "templates/ClientPageTemplate";

import { AccountPageContext } from "interfaces/view/accout";
import LoadingPage from "pages/account/LoadingPage";

/**
 * Build a page whitch in accout page group.
 * This component creates organisms and integrate with template.
 * areaContentsElement is given by callbacks.
 */
const BuildAccountPage = ({
  context,
  renderAreaContentsElement,
  renderDefaultModalElement,
}: {
  context: AccountPageContext;
  renderAreaContentsElement: (params: {
    setModalElement: (e: JSX.Element | undefined) => void;
  }) => JSX.Element;
  renderDefaultModalElement?: (params: {
    setModalElement: (e: JSX.Element | undefined) => void;
  }) => JSX.Element;
}) => {
  const [modalElement, setModalElement] = useState<JSX.Element>();

  useEffect(() => {
    if (renderDefaultModalElement) {
      setModalElement(renderDefaultModalElement({ setModalElement }));
    }
  }, [renderDefaultModalElement]);

  if (context.account) {
    return (
      <CreateAccountPageSharedElements
        context={context}
        setModalElement={setModalElement}
        render={(headerElement, sidebarElement) => (
          <ClientPageTemplate
            headerElement={headerElement}
            sideBarElement={sidebarElement}
            footerElement={<CreateFooter />}
            areaContents={renderAreaContentsElement({ setModalElement })}
            modalElement={modalElement}
          />
        )}
      />
    );
  } else {
    return <LoadingPage loginAccountId={context.loginAccountId} />;
  }
};

export default BuildAccountPage;
