import {
  ActionCreationPageState,
  ActionCreationPageViewCallbacks,
} from "app/hooks/useActionCreationService";
import {
  ACTION_CREATION_MODE,
  CONTENTS_TYPE,
  PresetContentsFormat,
} from "interfaces/model/actionContentsParameters";
import {
  ACTION_TYPES,
  LocationPattern,
  PathParameterPattern,
} from "interfaces/models";
import {
  ACTION_CREATION_PHASE,
  ActionCreationPresetFormCallbacks,
  SETTING_PHASE_CATEGORY,
  ActionCreationCommonCallbacks,
} from "interfaces/view/actionCreation";
import { ActionCreationPresetServiceCallbacks } from "utils/action_creation/ActionCreationPresetServiceCallbacks";
import CreatePresetCompletePhaseFormMain from "./complete/CreatePresetCompletePhaseFormMain";
import CreatePresetContentsEditPhaseFormEditAreaForm from "./contents_edit/CreatePresetContentsEditPhaseFormEditAreaForm";
import CreatePresetFormatSelectPhaseFormMain from "./format_select/CreatePresetFormatSelectPhaseFormMain";
import CreatePresetSettingsEditPhaseForm from "./settings_edit/CreatePresetSettingsEditPhaseForm";

type CreatePresetActionCreationFormMainProps = {
  appState: ActionCreationPageState;
  appCallbacks: ActionCreationCommonCallbacks;
  viewCallbacks: ActionCreationPageViewCallbacks;
};

const CreatePresetActionCreationFormMain = ({
  appState,
  appCallbacks,
  viewCallbacks,
}: CreatePresetActionCreationFormMainProps) => {
  const s =
    appState.actionCreationSettings.executionConditions.segmentationRules;

  const callbacks: ActionCreationPresetFormCallbacks = {
    commons: appCallbacks, // FIXME: do not give part of appCallback
    mode: viewCallbacks.modeView[ACTION_CREATION_MODE.PRESET],
    systems: viewCallbacks.viewStateCallbacks,
    settings: viewCallbacks.fromEventHandlers,
    contents: viewCallbacks.contents,
    styles: viewCallbacks.styles,
  };

  const services: ActionCreationPresetServiceCallbacks = {
    //////////////////////////
    // format select events //
    //////////////////////////
    onSelectPresetFormat(format: PresetContentsFormat) {
      appCallbacks.selectPresetFormat(format);
    },
    /////////////////////
    // settings events //
    /////////////////////
    onClickExpirationPeriodToggle(e: boolean) {
      if (e) {
        appCallbacks.setExpirationPeriod({});
      } else {
        appCallbacks.setExpirationPeriod(undefined);
      }
    },
    onClickExcludeLocationAddButton() {
      throw new Error("not implemented error");
    },
    onClickExcludeLocationDeleteButton(index: number) {
      throw new Error("not implemented error");
    },
    onChangeExcludeLocation(index: number, location: LocationPattern) {
      appCallbacks.updateExcludeLocation(index, location);
    },
    onChangeLoginErrorCount(count: string) {
      const errorCount = Number(count);
      if (!isNaN(errorCount)) {
        appCallbacks.updateLoginErrorCountSettings(errorCount);
      }
    },
    onChangeLoginErrorPageCondition() {
      appCallbacks.updateLoginErrorCountSettings(undefined);
    },
    onChangeLoginUrlLocation(location: LocationPattern) {
      appCallbacks.updateLoginUrlLocation(location);
    },
    onChangeLoginUrlParameter(
      index: number,
      queryParameter: PathParameterPattern
    ) {
      appCallbacks.updateLoginUrlParameter(index, queryParameter);
    },
    onClickLoginUrlParameterAddButton() {
      appCallbacks.addLoginUrlFrom();
    },
    onClickLoginUrlParameterDeleteButton() {
      appCallbacks.removeLoginUrlFrom();
    },

    ///////////////////
    // design events //
    ///////////////////

    onChangeContentsImage(image: File | undefined): void {
      appCallbacks.changeContentsImage(image);
    },
  };

  const params = {
    phase: appState.phase,
    mode: ACTION_CREATION_MODE.PRESET,
    modalType: appState.modalType,
    createdAction: appState.createdAction || undefined,
    systems: {
      errorMessage: appState.errorMessage,
      status: appState.status,
      modalType: appState.modalType,
      settingPhaseCategory: appState.settingPhaseCategory,
    },
    presets: appState.modeParams[ACTION_CREATION_MODE.PRESET],
    settings: {
      name: appState.actionCreationSettings.name,
      status: appState.status,
      locations:
        appState.actionCreationSettings.executionConditions.urlCondition.includes.map(
          (c) => c.locations[0].value
        ),
      expirationPeriod:
        appState.actionCreationSettings.executionConditions.expirationPeriod,
      segmentationRuleIds: s.create ? [] : s.segmentationRuleIds,
      excludeLocations:
        appState.actionCreationSettings.executionConditions.urlCondition.excludes.map(
          (c) => c.locations[0]
        ),
      frequency: appState.actionCreationSettings.executionConditions.frequency,
      terminateReaction:
        appState.actionCreationSettings.executionConditions.terminateReaction,
      cvUrl: appState.actionCreationSettings.cvUrl,
      abRate: appState.actionCreationSettings.contents.abTestARate,
      html: appState.actionCreationSettings.contents.htmlTemplate,
      previewHtml: appState.previewHtml,
      actionType:
        appState.actionCreationSettings.contents.contentsType ===
        CONTENTS_TYPE.FULL
          ? ACTION_TYPES.FULL_MODAL
          : ACTION_TYPES.BOTTOM_MODAL,
      isExpirationPeriodEnabled:
        !!appState.actionCreationSettings.executionConditions.expirationPeriod,
      executeTimings:
        appState.actionCreationSettings.executionConditions.executeTimings,
    },
    contents: {
      contentsType:
        appState.actionCreationSettings.contents.details.contentsType,
      positionType:
        appState.actionCreationSettings.contents.details.positionType,
      presetFormat:
        appState.actionCreationSettings.contents.details.presetFormat,
      url: appState.actionCreationSettings.contents.details.url,
      template: appState.actionCreationSettings.contents.details.template,
      format: appState.actionCreationSettings.contents.details.customFormat,
      pattern: appState.actionCreationSettings.contents.details.patternIndex,
      formatPatterns:
        appState.actionCreationSettings.contents.details.formatPatterns,
      image: appState.actionCreationSettings.contents.image,
    },
    styles: appState.actionCreationSettings.contents.details.styles,
  };

  const preview = {
    url: params.contents.url ? params.contents.url : "",
    html: params.settings.previewHtml,
    actionType: params.settings.actionType,
    onChangeUrl: callbacks.contents.setUrl,
  };

  switch (params.phase) {
    case ACTION_CREATION_PHASE.MODE_SELECT:
      return null;
    case ACTION_CREATION_PHASE.FORMAT_SELECT:
      return <CreatePresetFormatSelectPhaseFormMain services={services} />;
    case ACTION_CREATION_PHASE.SETTINGS_EDIT:
      switch (params.systems.settingPhaseCategory) {
        case SETTING_PHASE_CATEGORY.VIEW:
          return (
            <CreatePresetContentsEditPhaseFormEditAreaForm
              params={params}
              callbacks={callbacks}
              services={services}
            />
          );
        case SETTING_PHASE_CATEGORY.CONDITION:
          return (
            <CreatePresetSettingsEditPhaseForm
              params={params}
              callbacks={callbacks}
              services={services}
            />
          );
        default:
          return null;
      }
    case ACTION_CREATION_PHASE.COMPLETE:
      return (
        <CreatePresetCompletePhaseFormMain params={params} preview={preview} />
      );
    default:
      ((p: never) => {})(params.phase);
      return null;
  }
};

export default CreatePresetActionCreationFormMain;
