// NOTE: old codec(2022/09).
import {
  Condition,
  SegmentationRule,
  UrlPattern,
  CustomerTypeCondition,
  CustomerTypes,
  TotalPvCountCondition,
  SessionStayingTimeCondition,
  ConditionTypes,
  PageStayingTimeCondition,
  PageScrollRateCondition,
  AccessBlankCondition,
  SessionPvCountCondition,
  SessionScenarioPvCondition,
  CustomerAttributeCondition,
  LOCATION_COMPARE_PATTERN,
  IntegerCompareTypes,
  ComparePatterns,
  WordCompareTypes,
  WordCompareCondition,
  IntegerCompareCondition,
  PathParameterPattern,
  PATH_PARAMETER_COMPARE_PATTERN,
} from "interfaces/models";
import { SegmentationRuleCreationRequest } from "interfaces/requests";
import {
  ExternalData,
  ExternalDataKeyPattern,
  PageView,
  PvPattern,
  SegmentationRuleCreationAppState,
  StayingTime,
  TargetUser,
  UserAction,
  UserActionPattern,
  LastPVTiming,
  PageScroll,
  TotalCVCount,
  ExternalDataComparePattern,
  TargetUserType,
} from "interfaces/view/segmentationCreation";
import { assertNever } from "utils/assertions";
import CvUrlCodec, { CvUrl } from "utils/CvUrlCodec";

const ALL_PAGE = "*";

const TWO_YEARS_DAYS = 365 * 2;

const daysToHours = (days: number) => {
  return days * 24;
};

const hoursToDays = (hours: number) => {
  return Math.floor(hours / 24);
};

class SegmentationRuleApiCodec {
  cvUrl: CvUrl;

  constructor(cvUrl: string) {
    this.cvUrl = CvUrlCodec.decode(cvUrl);
  }

  encodeUserActionPageView(
    targetPage: UrlPattern,
    userAction: PageView
  ): Condition | undefined {
    switch (userAction.pvPattern) {
      case PvPattern.SESSION: {
        const c: SessionPvCountCondition = {
          condition_type: ConditionTypes.SESSION_PV_COUNT,
          parameters: {
            url: targetPage,
            count: userAction.count,
            operation: {
              compare_type: userAction.comparePattern,
              value: userAction.count,
            },
          },
        };
        return c;
      }
      case PvPattern.CONTINUOUS: {
        const c: SessionScenarioPvCondition = {
          condition_type: ConditionTypes.SESSION_SCENARIO_PV,
          parameters: {
            pattern: Array.from(Array(Number(userAction.count)).keys()).map(
              () => {
                return targetPage;
              }
            ),
          },
        };
        return c;
      }
      case PvPattern.INCLUDE_PAST: {
        const c: TotalPvCountCondition = userAction.range_days
          ? {
              condition_type: ConditionTypes.TOTAL_PV_COUNT,
              parameters: {
                url: targetPage,
                count: userAction.count,
                range_hours: daysToHours(
                  userAction.range_days <= TWO_YEARS_DAYS
                    ? userAction.range_days
                    : TWO_YEARS_DAYS
                ),
                operation: {
                  operation_type: ComparePatterns.INTEGER,
                  compare_type: userAction.comparePattern,
                  value: userAction.count,
                },
              },
            }
          : {
              condition_type: ConditionTypes.TOTAL_PV_COUNT,
              parameters: {
                url: targetPage,
                count: userAction.count,
                range_hours: daysToHours(TWO_YEARS_DAYS),
                operation: {
                  operation_type: ComparePatterns.INTEGER,
                  compare_type: userAction.comparePattern,
                  value: userAction.count,
                },
              },
            };
        return c;
      }
      default:
        return undefined;
    }
  }

  encodeUserActionStayingTime(
    targetPage: UrlPattern,
    userAction: StayingTime
  ): Condition | undefined {
    const location = targetPage.location[0].value;
    if (location === ALL_PAGE) {
      const c: SessionStayingTimeCondition = {
        condition_type: ConditionTypes.SESSION_STAYING_TIME,
        parameters: {
          url: targetPage,
          stay_seconds: userAction.seconds,
        },
      };
      return c;
    } else {
      const c: PageStayingTimeCondition = {
        condition_type: ConditionTypes.PAGE_STAYING_TIME,
        parameters: {
          url: targetPage,
          stay_seconds: userAction.seconds,
        },
      };
      return c;
    }
  }

  encodeUserAction(
    targetPage: UrlPattern,
    userAction: UserAction
  ): Condition | undefined {
    switch (userAction.type) {
      case UserActionPattern.NO_ACTION:
        return undefined;

      case UserActionPattern.PAGE_VIEW:
        return this.encodeUserActionPageView(targetPage, userAction);

      case UserActionPattern.STAYING_TIME:
        return this.encodeUserActionStayingTime(targetPage, userAction);

      case UserActionPattern.PAGE_SCROLL: {
        const c: PageScrollRateCondition = {
          condition_type: ConditionTypes.PAGE_SCROLL_RATE,
          parameters: {
            url: targetPage,
            scroll_rate: userAction.rate / 100,
          },
        };
        return c;
      }

      case UserActionPattern.TOTAL_CV_COUNT: {
        const params: PathParameterPattern[] = [];
        this.cvUrl.searchParams.forEach((e) => {
          params.push({
            key: e.key,
            value: e.value,
            pattern: PATH_PARAMETER_COMPARE_PATTERN.INCLUDE,
          });
        });

        const c: TotalPvCountCondition = {
          condition_type: ConditionTypes.TOTAL_PV_COUNT,
          parameters: {
            url: {
              location: [
                {
                  value: this.cvUrl.location,
                  pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
                },
              ],
              parameters: params,
            },
            count: Math.round(userAction.count),
            operation: {
              operation_type: ComparePatterns.INTEGER,
              compare_type: userAction.comparePattern,
              value: userAction.count,
            },
          },
        };
        return c;
      }

      case UserActionPattern.LAST_PV_TIMING: {
        const c: AccessBlankCondition = {
          condition_type: ConditionTypes.ACCESS_BLANK,
          parameters: {
            min_range_hours: daysToHours(userAction.days),
            max_range_hours: daysToHours(TWO_YEARS_DAYS),
          },
        };
        return c;
      }

      default:
        assertNever(userAction);
    }
  }

  encodeExternalData(externalData: ExternalData): Condition | undefined {
    if (externalData.key === ExternalDataKeyPattern.UNSELECTED) {
      return undefined;
    } else {
      switch (externalData.compareType) {
        case ExternalDataComparePattern.EQUAL: {
          const c: CustomerAttributeCondition = {
            condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
            parameters: {
              key: externalData.key,
              operation: {
                value: externalData.value,
                operation_type: ComparePatterns.WORD,
                compare_type: WordCompareTypes.INCLUDE,
              },
            },
          };
          return c;
        }
        case ExternalDataComparePattern.INCLUDE: {
          const c: CustomerAttributeCondition = {
            condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
            parameters: {
              key: externalData.key,
              operation: {
                value: externalData.value,
                operation_type: ComparePatterns.WORD,
                compare_type: WordCompareTypes.MATCH_ANY,
              },
            },
          };
          return c;
        }

        case ExternalDataComparePattern.GRATER_THAN: {
          const c: CustomerAttributeCondition = {
            condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
            parameters: {
              key: externalData.key,
              operation: {
                value: isNaN(parseInt(externalData.value))
                  ? 0
                  : parseInt(externalData.value),
                operation_type: ComparePatterns.INTEGER,
                compare_type: IntegerCompareTypes.GreaterThanOrEqualTo,
              },
            },
          };
          return c;
        }

        case ExternalDataComparePattern.LESS_THAN: {
          const c: CustomerAttributeCondition = {
            condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
            parameters: {
              key: externalData.key,
              operation: {
                value: isNaN(parseInt(externalData.value))
                  ? 0
                  : parseInt(externalData.value),
                operation_type: ComparePatterns.INTEGER,
                compare_type: IntegerCompareTypes.LessThanOrEqualTo,
              },
            },
          };
          return c;
        }

        default: {
          assertNever(externalData.compareType);
        }
      }
    }
  }

  encodeTargetUser(targetUser: TargetUserType): Condition | undefined {
    switch (targetUser) {
      case TargetUser.ALL:
        return undefined;
      case TargetUser.EXISTING_USER_ONLY: {
        const c: CustomerTypeCondition = {
          condition_type: "customer_type",
          parameters: {
            key: "",
            customer_type: CustomerTypes.SIGNED_UP,
          },
        };
        return c;
      }
      case TargetUser.NO_MEMBER_USER_ONLY: {
        const c: CustomerTypeCondition = {
          condition_type: "customer_type",
          parameters: {
            key: "",
            customer_type: CustomerTypes.SIGNED_OUT,
          },
        };
        return c;
      }
      default:
        assertNever(targetUser);
    }
  }

  encodeConditions(appState: SegmentationRuleCreationAppState): Condition[] {
    let result: Condition[] = [];

    // encode target user condition
    const targetUserCondition = this.encodeTargetUser(appState.targetUser);

    if (targetUserCondition) {
      result.push(targetUserCondition);
    }

    // encode user action conditions
    appState.userActions.forEach((v) => {
      const c = this.encodeUserAction(appState.targetPage, v);
      if (c) {
        result.push(c);
      }
    });

    // encode external data conditions
    const external_data_conditions: (Condition | undefined)[] =
      appState.externalData
        .filter((v) => v.value !== "")
        .map((v) => this.encodeExternalData(v));

    external_data_conditions.forEach((v) => {
      if (v) {
        result.push(v);
      }
    });

    return result;
  }

  /**
   * ユーザーアクション「購入」か判定
   * condition
   *  - URL
   *    - location = {cvPageUrl}
   *  - range_hours: undefined
   *
   * @param {TotalPvCountCondition} condition - 期間PV数条件
   * @returns {boolean} - 判定結果
   *
   */
  isTotalCvUserAction(condition: TotalPvCountCondition): boolean {
    if (condition.parameters.range_hours !== undefined) {
      return false;
    }

    const location = this.cvUrl.location;

    if (
      !condition.parameters.url.location
        .map((e) => location.includes(e.value))
        .every((e) => e)
    ) {
      return false;
    }

    const searchParamLength = Array.from(this.cvUrl.searchParams).length;

    if (searchParamLength !== condition.parameters.url.parameters.length) {
      return false;
    }

    const is_matches = condition.parameters.url.parameters.map((e) => {
      // NOTE: 条件をもとにサーチパラメータを取得
      const params = this.cvUrl.searchParams.filter(
        (param) => param.key === e.key
      );

      // NOTE: キーの存在チェック
      if (params.length > 0) {
        // NOTE: conditionにサーチパラメータのvalue設定があれば一致チェック、なければ素通り
        if (e.value) {
          return params.some((p) => p.value === e.value);
        } else {
          return true;
        }
      } else {
        return false;
      }
    });

    return is_matches.every((e) => e);
  }

  __decodeTargetUser(condition: Condition): TargetUserType | undefined {
    switch (condition.condition_type) {
      case ConditionTypes.CUSTOMER_TYPE: {
        const c = condition as CustomerTypeCondition;
        switch (c.parameters.customer_type) {
          case CustomerTypes.SIGNED_UP: {
            return TargetUser.EXISTING_USER_ONLY;
          }
          case CustomerTypes.SIGNED_OUT: {
            return TargetUser.NO_MEMBER_USER_ONLY;
          }
        }
        break;
      }
      default: {
        return undefined;
      }
    }
  }

  decodeTargetUser(conditions: Condition[]): TargetUserType {
    const targetUsers = conditions
      .map((c) => this.__decodeTargetUser(c))
      .filter((c): c is Exclude<typeof c, undefined> => c !== undefined);

    return targetUsers[0] || TargetUser.ALL;
  }

  decodeUserAction(condition: Condition): UserAction | undefined {
    const conditionType = condition.condition_type;
    switch (conditionType) {
      case ConditionTypes.SESSION_PV_COUNT: {
        const cond = condition as SessionPvCountCondition;
        const ua: PageView = {
          type: UserActionPattern.PAGE_VIEW,
          count: cond.parameters.operation
            ? cond.parameters.operation.value
            : cond.parameters.count,
          comparePattern: cond.parameters.operation
            ? cond.parameters.operation.compare_type
            : IntegerCompareTypes.GreaterThanOrEqualTo,
          pvPattern: PvPattern.SESSION,
        };

        return ua;
      }
      case ConditionTypes.SESSION_SCENARIO_PV: {
        const cond = condition as SessionScenarioPvCondition;
        const ua: PageView = {
          type: UserActionPattern.PAGE_VIEW,
          count: cond.parameters.pattern.length,
          comparePattern: IntegerCompareTypes.EqualTo,
          pvPattern: PvPattern.CONTINUOUS,
        };

        return ua;
      }
      case ConditionTypes.SESSION_STAYING_TIME: {
        const cond = condition as SessionStayingTimeCondition;
        const ua: StayingTime = {
          type: UserActionPattern.STAYING_TIME,
          seconds: cond.parameters.stay_seconds,
        };

        return ua;
      }
      case ConditionTypes.PAGE_SCROLL_RATE: {
        const cond = condition as PageScrollRateCondition;
        const ua: PageScroll = {
          type: UserActionPattern.PAGE_SCROLL,
          rate: Math.round(cond.parameters.scroll_rate * 100),
        };

        return ua;
      }
      case ConditionTypes.PAGE_STAYING_TIME: {
        const cond = condition as PageStayingTimeCondition;
        const ua: StayingTime = {
          type: UserActionPattern.STAYING_TIME,
          seconds: cond.parameters.stay_seconds,
        };

        return ua;
      }
      case ConditionTypes.TOTAL_PV_COUNT: {
        const cond = condition as TotalPvCountCondition;

        if (this.isTotalCvUserAction(cond)) {
          const ua: TotalCVCount = {
            type: UserActionPattern.TOTAL_CV_COUNT,
            count: cond.parameters.count,
            comparePattern: cond.parameters.operation
              ? cond.parameters.operation.compare_type
              : IntegerCompareTypes.GreaterThanOrEqualTo,
          };
          return ua;
        } else {
          const ua: PageView = {
            type: UserActionPattern.PAGE_VIEW,
            count: cond.parameters.count,
            comparePattern: cond.parameters.operation
              ? cond.parameters.operation.compare_type
              : IntegerCompareTypes.GreaterThanOrEqualTo,
            pvPattern: PvPattern.INCLUDE_PAST,
            range_days: cond.parameters.range_hours
              ? hoursToDays(cond.parameters.range_hours)
              : TWO_YEARS_DAYS,
          };
          return ua;
        }
      }
      case ConditionTypes.ACCESS_BLANK: {
        const cond = condition as AccessBlankCondition;
        const ua: LastPVTiming = {
          type: UserActionPattern.LAST_PV_TIMING,
          days: hoursToDays(cond.parameters.min_range_hours),
        };

        return ua;
      }
      default: {
        return undefined;
      }
    }
  }

  decodeConditionUrl(condition: Condition): UrlPattern | undefined {
    switch (condition.condition_type) {
      case ConditionTypes.SESSION_PV_COUNT: {
        const cond = condition as SessionPvCountCondition;
        return cond.parameters.url;
      }
      case ConditionTypes.SESSION_SCENARIO_PV: {
        const cond = condition as SessionScenarioPvCondition;
        return cond.parameters.pattern[0];
      }
      case ConditionTypes.SESSION_STAYING_TIME: {
        const cond = condition as SessionStayingTimeCondition;
        return cond.parameters.url;
      }
      case ConditionTypes.PAGE_SCROLL_RATE: {
        const cond = condition as PageScrollRateCondition;
        return cond.parameters.url;
      }
      case ConditionTypes.PAGE_STAYING_TIME: {
        const cond = condition as PageStayingTimeCondition;
        return cond.parameters.url;
      }
      case ConditionTypes.TOTAL_PV_COUNT: {
        const cond = condition as TotalPvCountCondition;

        if (this.isTotalCvUserAction(cond)) {
          // NOTE: Exclude TOTAL_CV_COUNT
          return undefined;
        } else {
          return cond.parameters.url;
        }
      }
      default: {
        return undefined;
      }
    }
  }

  decodeTargetPage(conditions: Condition[]): UrlPattern {
    const urls: UrlPattern[] = conditions
      .map((c) => this.decodeConditionUrl(c))
      .filter((v): v is Exclude<typeof v, undefined> => v !== undefined);

    if (urls.length > 0) {
      return urls[0];
    } else {
      return {
        location: [
          {
            value: "*",
            pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
          },
        ],
        parameters: [],
      };
    }
  }

  decodeUserActions(conditions: Condition[]): UserAction[] {
    return conditions
      .map((e) => this.decodeUserAction(e))
      .filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
  }

  decodeExternalData(condition: Condition): ExternalData | undefined {
    switch (condition.condition_type) {
      case ConditionTypes.CUSTOMER_ATTRIBUTE: {
        const customer_attribute = condition as CustomerAttributeCondition;
        switch (customer_attribute.parameters.operation.operation_type) {
          case ComparePatterns.WORD: {
            const operation_word = customer_attribute.parameters
              .operation as WordCompareCondition;
            switch (operation_word.compare_type) {
              case WordCompareTypes.INCLUDE: {
                return {
                  key: customer_attribute.parameters.key,
                  value: operation_word.value,
                  compareType: ExternalDataComparePattern.EQUAL,
                };
              }
              case WordCompareTypes.MATCH_ANY: {
                return {
                  key: customer_attribute.parameters.key,
                  value: operation_word.value,
                  compareType: ExternalDataComparePattern.INCLUDE,
                };
              }
              // Unimplemented WordCompareTypes.EQUAL
              default: {
                return undefined;
              }
            }
          }
          case ComparePatterns.INTEGER: {
            const operation_integer = customer_attribute.parameters
              .operation as IntegerCompareCondition;
            switch (operation_integer.compare_type) {
              case IntegerCompareTypes.GreaterThanOrEqualTo: {
                return {
                  key: customer_attribute.parameters.key,
                  value: String(operation_integer.value),
                  compareType: ExternalDataComparePattern.GRATER_THAN,
                };
              }
              case IntegerCompareTypes.LessThanOrEqualTo: {
                return {
                  key: customer_attribute.parameters.key,
                  value: String(operation_integer.value),
                  compareType: ExternalDataComparePattern.LESS_THAN,
                };
              }
              // Unimplemented GreaterThan, LessThan, EqualTo
              default: {
                return undefined;
              }
            }
          }
          // Unimplemented DateCompareTypes, ExistanceCompareCondition
          default: {
            return undefined;
          }
        }
      }
      // Not ConditionTypes.CUSTOMER_ATTRIBUTE
      default: {
        return undefined;
      }
    }
  }

  decodeExternalDataList(conditions: Condition[]): ExternalData[] {
    return conditions
      .map((e) => this.decodeExternalData(e))
      .filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
  }

  encode(
    appState: SegmentationRuleCreationAppState
  ): SegmentationRuleCreationRequest {
    return {
      name: appState.name,
      conditions: this.encodeConditions(appState),
    };
  }

  decode(data: SegmentationRule): SegmentationRuleCreationAppState {
    return {
      name: data.name,
      targetUser: this.decodeTargetUser(data.conditions),
      targetPage: this.decodeTargetPage(data.conditions),
      userActions: this.decodeUserActions(data.conditions),
      externalData: this.decodeExternalDataList(data.conditions),
    };
  }
}

export default SegmentationRuleApiCodec;
