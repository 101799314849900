import ActiveCheckBox from "./ActiveCheckBox";
import DisableCheckBox from "./InactiveCheckBox";
import styles from "./CheckBox.module.scss";

type CheckBoxProps = {
  text: string | JSX.Element;
  value: boolean;
  onChange: () => void;
  customStyle?: React.CSSProperties;
};

const CheckBox = ({ text, value, onChange, customStyle }: CheckBoxProps) => {
  const box = value ? <ActiveCheckBox /> : <DisableCheckBox />;
  return (
    <label className={styles.checkBox} onClick={() => onChange()}>
      <div className={styles.box}>{box}</div>
      <div style={customStyle}>{text}</div>
    </label>
  );
};

export default CheckBox;
