import { GrantMessageHtmlPreviewPreprocessor } from "app/process/grantMessageHtmlPreviewPreprocessor";
import HtmlPreviewer from "atoms/ezPushShared/accountPageShared/HtmlPreviewer";
import { GrantMessageLayoutType } from "interfaces/v2/grantMessageContents";
import React from "react";

const ActionHtmlPreviewer: React.VFC<{
  layoutType: GrantMessageLayoutType;
  html: string;
}> = ({ layoutType, html }) => {
  const preprocessor = new GrantMessageHtmlPreviewPreprocessor();
  return <HtmlPreviewer html={preprocessor.execute(layoutType, html)} />;
};

export default ActionHtmlPreviewer;
