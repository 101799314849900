import useAccount from "app/hooks/v2/useAccount";
import usePermission from "app/hooks/v2/usePermission";
import RestApi from "utils/RestApi";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

const useAccountPageContext = (
  managementApi: RestApi,
  loginAccountId: string,
  onSignOut: () => void,
  onSystemError: () => void
): AccountPageContextV2 => {
  const permission = usePermission(managementApi);
  const account = useAccount(managementApi, onSystemError);

  return {
    managementApi,
    account,
    permission,
    loginAccountId,
    onSignOut,
  };
};

export default useAccountPageContext;
