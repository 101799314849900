import styles from "./CompletePhaseDetailTableView.module.scss";

type CompletePhaseDetailTableViewProps = {
  headlineElement: JSX.Element;
  detailTableElement: JSX.Element;
};

const CompletePhaseDetailTableView = ({
  headlineElement,
  detailTableElement,
}: CompletePhaseDetailTableViewProps) => {
  return (
    <div className={styles.completePhaseDetailTableView}>
      <div className={styles.headline}>{headlineElement}</div>
      <div className={styles.table}>{detailTableElement}</div>
    </div>
  );
};

export default CompletePhaseDetailTableView;
