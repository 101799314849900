import styles from "./IconWrapper.module.scss";

const IconWrapper: React.VFC<{
  icon: string;
  isHidden: boolean;
  onClick: () => void;
  width?: number;
}> = ({ icon, isHidden, onClick, width }) => {
  return (
    <button
      type="button"
      className={styles.iconWrapper}
      onClick={onClick}
      hidden={isHidden}
      style={
        width
          ? {
              width: width,
            }
          : undefined
      }
    >
      <img
        src={icon}
        alt=""
        hidden={isHidden}
        style={
          width
            ? {
                width: width,
              }
            : undefined
        }
      />
    </button>
  );
};

export default IconWrapper;
