export const COMMON_STYLE_PARAMETERS = {
  CREATIVE_ID: "#ezcx-modal",
  HEADLINE_FONT_SIZE: "20px",
  SUB_HEADLINE_FONT_SIZE: "14px",
  ARTICLE_FONT_SIZE: "12px",
  BUTTON_FONT_SIZE: "14px",
  COUPON_CODE_FONT_SIZE: "12px",
  COUPON_COPY_BUTTON_FONT_SIZE: "14px",
};

const CLASS_PREFIX = "ezcx-";

export const CREATIVE_PARTS_CLASS_NAME = {
  MODAL_CONTENTS: CLASS_PREFIX + "modal-contents",
  MODAL_CONTENTS_BOTTOM: CLASS_PREFIX + "modal-contents-bottom",
  FULL_MODAL: CLASS_PREFIX + "full-modal",
  BOTTOM_MODAL: CLASS_PREFIX + "bottom-modal",
  MODAL: CLASS_PREFIX + "modal-contents",
  POP_UP: CLASS_PREFIX + "modal-contents-bottom",
  CLOSE: CLASS_PREFIX + "close-button",
  HEADLINE: CLASS_PREFIX + "headline",
  SUB_HEADLINE: CLASS_PREFIX + "sub-headline",
  ARTICLE: CLASS_PREFIX + "article",
  BUTTON: CLASS_PREFIX + "button",
  IMAGE_FRAME: CLASS_PREFIX + "image-frame",
  IMAGE: CLASS_PREFIX + "image",
  ICON: CLASS_PREFIX + "icon",
  COUPON_CODE: CLASS_PREFIX + "coupon-code",
  COUPON_COPY_BUTTON: CLASS_PREFIX + "copy-button",
  CONTAINER: CLASS_PREFIX + "container",
  CONTAINER_TEXT: CLASS_PREFIX + "container-text",
  CONTENTS_BASE: CLASS_PREFIX + "contents-base",
  MAIN_AREA: CLASS_PREFIX + "main-area",
  FIXED_AREA: CLASS_PREFIX + "fixed-area",
  COUPON_AREA: CLASS_PREFIX + "codecopy",
};

export const CREATIVE_ACTION_CLASS_NAME = {
  REACTION: CLASS_PREFIX + "reaction-click",
  CLOSE: CLASS_PREFIX + "close",
  REGISTER: CLASS_PREFIX + "register-button",
};
