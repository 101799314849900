import { ImageLoader } from "app/process/imageLoader";
import { useCallback, useEffect, useMemo, useState } from "react";

export type GrantMessageContentsImageDesignParameters = {
  isNewFile: boolean;
  file: File | null;
  path: string | undefined;
  magnification: number;
};

export type GrantMessageContentsImageDesignCallbacks = {
  updateFile: (value: File | null) => void;
  updatePath: (value: string | undefined) => void;
  updateMagnification: (value: number) => void;
};

export const useGrantMessageContentsImageDesign = (
  currentParameters: GrantMessageContentsImageDesignParameters
): [
  GrantMessageContentsImageDesignParameters,
  GrantMessageContentsImageDesignCallbacks
] => {
  const [file, setFile] = useState<File | null>(null);
  const [path, setPath] = useState<string | undefined>(currentParameters.path);
  const [isNewFile, setIsNewFile] = useState<boolean>(false);
  const [magnification, setMagnification] = useState<number>(
    currentParameters.path && currentParameters.magnification
      ? currentParameters.magnification
      : 1
  );

  const parameters = useMemo(() => {
    return {
      isNewFile,
      file,
      path,
      magnification,
    };
  }, [isNewFile, file, path, magnification]);

  const loadCurrentImageFile = useCallback(async () => {
    if (currentParameters.path) {
      const imageLoader = new ImageLoader();
      const currentFile = await imageLoader.get(currentParameters.path);
      setFile(currentFile);
    }
  }, [currentParameters.path, setFile]);

  useEffect(() => {
    loadCurrentImageFile();
  }, [loadCurrentImageFile]);

  const callbacks = useMemo(() => {
    return {
      updateFile: (value: File | null) => {
        setFile(value);
        setPath(value ? URL.createObjectURL(value) : undefined);
        setIsNewFile(true);
        setMagnification(1);
      },
      updatePath: (value: string | undefined) => {
        setPath(value);
      },
      updateMagnification: (value: number) => {
        setMagnification(value);
      },
    };
  }, [setFile, setPath, setMagnification]);

  return [parameters, callbacks];
};
