import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { MeasurementUrlSettingEditRouter } from "app/process/measurementUrlSetting/measurementUrlSettingEditRouter";
import { BasicConfigurationType } from "app/system_defaults/v2_routing";

import { MeasurementUrlType } from "types/measurement_urls";

import { BasicConfigurationRouter } from "app/process/basicConfiguration/basicConfigurationRouter";
import { UrlParameters } from "./useUrlParameters";

export type BasicConfigurationPageEvents = {
  goToCreateUrlSetting: (measurementUrlType: MeasurementUrlType) => void;
  editUrlSetting: (
    measurementUrlType: MeasurementUrlType,
    urlUuid: string
  ) => void;
  updateHistory: (children: BasicConfigurationType) => void;
};

export const useBasicConfigurationPageEvents = (
  urlParameters: UrlParameters
): BasicConfigurationPageEvents => {
  const history = useHistory();

  const goToCreateUrlSetting = useCallback(
    (measurementUrlType: MeasurementUrlType) => {
      history.push(
        new MeasurementUrlSettingEditRouter().create(measurementUrlType)
      );
    },
    [history]
  );

  const editUrlSetting = useCallback(
    (measurementUrlType: MeasurementUrlType, urlUuid: string) => {
      history.push(
        new MeasurementUrlSettingEditRouter().edit(measurementUrlType, urlUuid)
      );
    },
    [history]
  );

  const updateHistory = useCallback(
    (children: BasicConfigurationType) => {
      history.push(
        new BasicConfigurationRouter().run(children, urlParameters.type)
      );
    },
    [history, urlParameters.type]
  );

  return {
    goToCreateUrlSetting,
    editUrlSetting,
    updateHistory,
  };
};
