import {
  SegmentationRuleConditionInterface,
  SegmentationRulePageConditionParameters,
  SegmentationRuleSubscribeTimingConditionParameters,
  SegmentationRuleTreeParameter,
} from "interfaces/v2/segmentationRuleForm";
import {
  MeasurementUrlSetting,
  MeasurementUrlSettingWithType,
} from "types/measurement_urls";

// NOTE: URL
export const URL_LOCATION_PATTERN_TYPE = {
  INCLUDE: "INCLUDE",
  EXCLUDE: "EXCLUDE",
  COMPLETE: "COMPLETE",
};

type UrlLocationPatternType =
  typeof URL_LOCATION_PATTERN_TYPE[keyof typeof URL_LOCATION_PATTERN_TYPE];

type UrlLocationRule = {
  pattern: UrlLocationPatternType;
  value: string;
};

export const URL_QUERY_PARAMETER_PATTERN_TYPE = {
  INCLUDE: "INCLUDE",
  EXCLUDE: "EXCLUDE",
};

type UrlQueryParameterPatternType =
  typeof URL_QUERY_PARAMETER_PATTERN_TYPE[keyof typeof URL_QUERY_PARAMETER_PATTERN_TYPE];

type UrlQueryParameterRule = {
  pattern: UrlQueryParameterPatternType;
  key: string;
  value: string | null;
};

type UrlRule = {
  locations: UrlLocationRule[];
  parameters: UrlQueryParameterRule[];
};

// NOTE: Operation
export const INTEGER_COMPARE_OPERATION_TYPE = {
  GREATER_THAN_OR_EQUAL_TO: "GREATER_THAN_OR_EQUAL_TO",
  LESS_THAN_OR_EQUAL_TO: "LESS_THAN_OR_EQUAL_TO",
  EQUAL_TO: "EQUAL_TO",
} as const;

type IntegerCompareOperationType =
  typeof INTEGER_COMPARE_OPERATION_TYPE[keyof typeof INTEGER_COMPARE_OPERATION_TYPE];

type IntegerCompareOperation = {
  type: "integer";
  value: number;
  compare_type: IntegerCompareOperationType;
};

// NOTE: Condition
export enum CONDITION_TYPE {
  ALL_TRACKING_ID = "all_tracking_id",
  TOTAL_PV_COUNT = "total_pv_count",
  SUBSCRIBE_TIMING = "subscribe_timing",
}

type AllTrackingIdCondition = {
  type: CONDITION_TYPE.ALL_TRACKING_ID;
  parameters: {};
};

type TotalPvCountCondition = {
  type: CONDITION_TYPE.TOTAL_PV_COUNT;
  parameters: {
    url: UrlRule;
    operation: IntegerCompareOperation;
    range_hours: number;
  };
};

type SubscribeTimingCondition = {
  type: CONDITION_TYPE.SUBSCRIBE_TIMING;
  parameters: {
    days_ago: number;
  };
};

// NOTE: Node
export enum NODE_TYPE {
  OPERATOR = "OPERATOR",
  CONDITION = "CONDITION",
}

export const OPERATOR_TYPE = {
  AND: "AND",
  OR: "OR",
} as const;

type OperatorType = typeof OPERATOR_TYPE[keyof typeof OPERATOR_TYPE];

export type ConditionNode = {
  node_type: NODE_TYPE.CONDITION;
  condition_uuid: string;
  condition:
    | AllTrackingIdCondition
    | TotalPvCountCondition
    | SubscribeTimingCondition;
};

export type OperatorNode = {
  node_type: NODE_TYPE.OPERATOR;
  operator_type: OperatorType;
  nodes: (OperatorNode | ConditionNode)[];
};

// NOTE: nodesのエイリアス
export type SegmentationRuleTreeNode = OperatorNode | ConditionNode;

export class SegmentationRuleTree {
  tree: OperatorNode;

  constructor(data: OperatorNode) {
    this.tree = data;
  }

  toJsonString() {
    return JSON.stringify(this.tree);
  }

  // NOTE: CLASS GENERATORS
  // NOTE: Generate from json string saved in Database
  static loadDBData(data: string): SegmentationRuleTree | null {
    const tmp = JSON.parse(data);

    if (tmp["node_type"] !== NODE_TYPE.OPERATOR) {
      throw new Error(`INVALID_FORMAT_SEGMENTATION_RULE_TREE: ${data}`);
    }

    if (!Array.isArray(tmp["nodes"])) {
      throw new Error(`INVALID_FORMAT_SEGMENTATION_RULE_TREE: ${data}`);
    }

    if (tmp["nodes"].length === 0) {
      throw new Error(`INVALID_FORMAT_SEGMENTATION_RULE_TREE: ${data}`);
    }

    return new SegmentationRuleTree(tmp);
  }

  // NOTE: Generate from edit form inputs
  private static getTargetUrlSetting(
    targetUrlUuid: string,
    settings: MeasurementUrlSettingWithType[]
  ): MeasurementUrlSetting {
    const targetUrlSettings = settings.filter(
      (setting) => setting.url.url_uuid === targetUrlUuid
    );

    return targetUrlSettings[0].url;
  }

  // NOTE: Decision apiとmeasurement url settingのデータ
  private static translateLocationComparePattern(
    locationPattern: string
  ): UrlLocationPatternType {
    if (locationPattern === "include") {
      return URL_LOCATION_PATTERN_TYPE.INCLUDE;
    } else if (locationPattern === "exclude") {
      return URL_LOCATION_PATTERN_TYPE.EXCLUDE;
    } else if (locationPattern === "complete") {
      return URL_LOCATION_PATTERN_TYPE.COMPLETE;
    }
    throw new Error(
      `INVALID_LOCATION_COMPARE_PATTERN_DETECTED: ${locationPattern}`
    );
  }

  private static translateParameterComparePattern(
    parameterPattern: string
  ): UrlLocationPatternType {
    if (parameterPattern === "include") {
      return URL_LOCATION_PATTERN_TYPE.INCLUDE;
    } else if (parameterPattern === "exclude") {
      return URL_LOCATION_PATTERN_TYPE.EXCLUDE;
    }
    throw new Error(
      `INVALID_PARAMETER_COMPARE_PATTERN_DETECTED: ${parameterPattern}`
    );
  }

  private static durationToRangeHours(duration: number): number {
    return duration * 24;
  }

  private static generatePageConditionNode(
    duration: number,
    page: SegmentationRulePageConditionParameters,
    urlSettings: MeasurementUrlSettingWithType[]
  ): ConditionNode {
    // FIXME: 対象設定が削除されて選択肢がない場合は…？
    const targetUrlSetting = this.getTargetUrlSetting(
      page.urlSetting.value,
      urlSettings
    );

    return {
      node_type: NODE_TYPE.CONDITION,
      condition_uuid: page.conditionUuid,
      condition: {
        type: CONDITION_TYPE.TOTAL_PV_COUNT,
        parameters: {
          url: {
            locations: targetUrlSetting.url.locations.map((l) => ({
              pattern: this.translateLocationComparePattern(l.pattern),
              value: l.value,
            })),
            parameters: targetUrlSetting.url.parameters.map((e) => {
              return {
                pattern: this.translateParameterComparePattern(e.pattern),
                key: e.key,
                value: e.value,
              };
            }),
          },
          operation: {
            type: "integer",
            compare_type: page.compareType as IntegerCompareOperationType,
            value: page.value,
          },
          range_hours: this.durationToRangeHours(duration),
        },
      },
    };
  }

  private static generateSubscribeTimingConditionNode(
    subscribeTiming: SegmentationRuleSubscribeTimingConditionParameters
  ): ConditionNode {
    return {
      node_type: NODE_TYPE.CONDITION,
      condition_uuid: subscribeTiming.conditionUuid,
      condition: {
        type: CONDITION_TYPE.SUBSCRIBE_TIMING,
        parameters: {
          days_ago: subscribeTiming.days_ago,
        },
      },
    };
  }

  private static generateConditionNode(
    duration: number,
    condition: SegmentationRuleConditionInterface,
    urls: MeasurementUrlSettingWithType[]
  ): ConditionNode[] {
    if (condition.type === CONDITION_TYPE.TOTAL_PV_COUNT) {
      return condition.pages.map((page) => {
        return this.generatePageConditionNode(duration, page, urls);
      });
    } else if (condition.type === CONDITION_TYPE.SUBSCRIBE_TIMING) {
      return [this.generateSubscribeTimingConditionNode(condition.parameters)];
    } else {
      throw new Error(`INVALID_CONDITION_TYPE_DETECTED: ${condition}`);
    }
  }

  static generateNewTree(
    duration: number,
    params: SegmentationRuleTreeParameter,
    urls: MeasurementUrlSettingWithType[]
  ) {
    const nodes: OperatorNode[] = params.map((p) => {
      return {
        node_type: NODE_TYPE.OPERATOR,
        operator_type: OPERATOR_TYPE.AND,
        nodes: SegmentationRuleTree.generateConditionNode(duration, p, urls),
      };
    });

    const baseNode: OperatorNode = {
      node_type: NODE_TYPE.OPERATOR,
      operator_type: OPERATOR_TYPE.OR,
      nodes: nodes,
    };

    return new SegmentationRuleTree(baseNode);
  }
}
