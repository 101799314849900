/**
 * Component Category: Molcule
 */
import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import CopyToClipboard from "react-copy-to-clipboard";

import styles from "./TagModalCopyArea.module.scss";

const TagModalCopyArea = ({ tag }: { tag: string }) => (
  <div className={styles.tagArea}>
    <div className={styles.form}>
      <input
        className={styles.formInput}
        type="text"
        value={tag}
        onFocus={(e) => {
          e.target.select();
        }}
        readOnly
      />
    </div>
    <CopyToClipboard text={tag}>
      <PositiveButton
        text={"タグをコピー"}
        onClick={() => alert("コピーしました")}
      />
    </CopyToClipboard>
  </div>
);

export default TagModalCopyArea;
