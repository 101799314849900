import styles from "./SegmentationRuleSettingBoard.module.scss";

type SegmentationRuleSettingBoardProps = {
  titleElement: JSX.Element;
  formElements: JSX.Element[];
  separatorElement: JSX.Element;
  addElementButton: JSX.Element;
};

const SegmentationRuleSettingBoard = ({
  titleElement,
  formElements,
  separatorElement,
  addElementButton,
}: SegmentationRuleSettingBoardProps) => (
  <div className={styles.segmentationRuleSettingBoard}>
    <div className={styles.title}>{titleElement}</div>
    <div className={styles.form}>
      {formElements.map((e, i) => (
        <div key={i}>
          {i === 0 ? undefined : (
            <div className={styles.separator}>{separatorElement}</div>
          )}
          <div className={styles.item}>{e}</div>
        </div>
      ))}
    </div>
    <div className={styles.footer}>{addElementButton}</div>
  </div>
);

export default SegmentationRuleSettingBoard;
