import React from "react";
import styles from "./HorizontalLayout.module.scss";

const HorizontalLayout: React.VFC<{
  elements: JSX.Element[];
  alignment?: "left" | "right";
  vertical?: "flex-start" | "center" | "flex-end";
  itemMargin?: number;
}> = ({
  elements,
  alignment = "left",
  vertical = "center",
  itemMargin = 10,
}) => {
  return (
    <div
      className={styles.horizontalLayout}
      style={{
        alignItems: vertical,
        justifyContent: alignment,
      }}
    >
      {elements.map((elem, i) => (
        <div
          key={i}
          className={styles.item}
          style={
            elements.length - 1 === i ? undefined : { marginRight: itemMargin }
          }
        >
          {elem}
        </div>
      ))}
    </div>
  );
};

export default HorizontalLayout;
