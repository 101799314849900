import React from "react";
import styles from "./OrangeBorderedButton.module.scss";

const OrangeBorderedButton: React.VFC<{
  text: string | JSX.Element;
  onClick: () => void;
}> = ({ text, onClick }) => {
  return (
    <div className={styles.orangeBorderedButton} onClick={onClick}>
      {text}
    </div>
  );
};

export default OrangeBorderedButton;
