import copyIcon from "images/icons/icon_copy.svg";
import styles from "./IconButton.module.scss";

const CopyButton: React.VFC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <button type="button" className={styles.iconButton} onClick={onClick}>
      <img src={copyIcon} alt="copy" />
    </button>
  );
};

export default CopyButton;
