import RangeSlider from "molecules/RangeSlider/RangeSlider";
import styles from "./RangeSliderForm.module.scss";

type RangeSliderFormProps = {
  name: JSX.Element;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: any) => void;
  valueTextEditCallback: (value: any) => string;
};

const RangeSliderForm = ({
  name,
  value,
  min,
  max,
  step,
  onChange,
  valueTextEditCallback,
}: RangeSliderFormProps) => {
  return (
    <div className={styles.rangeSliderForm}>
      <div className={styles.name}>{name}</div>
      <div>
        <RangeSlider
          value={value}
          maxValue={max}
          minValue={min}
          stepValue={step}
          onChange={(e) => onChange(e)}
          valueTextEditCallback={valueTextEditCallback}
        />
      </div>
    </div>
  );
};

export default RangeSliderForm;
