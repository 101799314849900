import RestApi from "utils/RestApi";
import { useEffect, useState } from "react";
import { DestinationCollectRule } from "types/destination_collect_rule";
import useDestinationCollectRules from "./useDestinationCollectRules";
import usePushNotificationEditForm, {
  PushNotificationEditFormParameters,
  PushNotificationEditFormCallbacks,
} from "./usePushNotificationEditForm";
import usePushNotificationEditPageEvents, {
  PushNotificationEditPageEventParameters,
  PushNotificationEditPageEvents,
} from "./usePushNotificationEditPageEvents";
import usePushNotificationEditSearchParameters from "./usePushNotificationEditPageSearchParameters";
import useEditResource from "./useEditResource";

export type PushNotificationEditPageParameters = {
  destinationCollectRules: DestinationCollectRule[] | null;
  data: PushNotificationEditFormParameters;
  events: PushNotificationEditPageEventParameters;
  modalContents: JSX.Element | undefined;
};

export type PushNotificationEditPageCallbacks = {
  data: PushNotificationEditFormCallbacks;
  events: PushNotificationEditPageEvents;
  updateModalContents: (element: JSX.Element | undefined) => void;
};

const usePushNotificationEditPage = (
  managementApi: RestApi
): [PushNotificationEditPageParameters, PushNotificationEditPageCallbacks] => {
  const searchParameters = usePushNotificationEditSearchParameters();

  const [destinationCollectRules, loadDestinationCollectRules] =
    useDestinationCollectRules(managementApi);

  const [editResource, updateEditResource] = useEditResource(
    managementApi,
    searchParameters
  );

  const [formParams, formCallbacks] = usePushNotificationEditForm(
    editResource,
    destinationCollectRules
  );

  const [eventParameters, events] = usePushNotificationEditPageEvents(
    managementApi,
    searchParameters,
    formParams,
    updateEditResource
  );

  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  useEffect(() => {
    loadDestinationCollectRules();
  }, [loadDestinationCollectRules]);

  return [
    {
      destinationCollectRules: destinationCollectRules,
      data: formParams,
      events: eventParameters,
      modalContents: modalContents,
    },
    {
      data: formCallbacks,
      events: events,
      updateModalContents: (value) => setModalContents(value),
    },
  ];
};

export default usePushNotificationEditPage;
