export const PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST = {
  NAME: {
    MUST_BE_FILLED: "「管理名」を入力してください",
    CHARACTER_LIMIT: "「管理名」は50文字以内で入力してください",
    FIRST_CHARACTER_IS_NOT_FIGURE:
      "「管理名」の先頭に記号を入れて登録することはできません。先頭の記号文字を削除してください。",
    EMOJI_LIMIT:
      "「管理名」に絵文字を登録することはできません。削除してください",
  },
  CONTENTS: {
    TITLE: {
      MUST_BE_FILLED: "「見出し」を入力してください",
      CHARACTER_LIMIT: "「見出し」は50文字以内で入力してください",
    },
    BODY: {
      CHARACTER_LIMIT: "「本文」は120文字以内で入力してください",
    },
    IMAGE: {
      SIZE_LIMIT: "「画像」はファイルサイズを200KB以下にしてください",
      CONTENTS_TYPE_RESTRICT:
        "「画像」はファイル形式をPNG, JPG, GIFのいずれかにしてください",
      MUST_BE_FILLED: "「画像」をアップロードしてください",
    },
    REDIRECT_URL: {
      MUST_BE_FILLED: "「遷移先URL」を入力してください",
      CHARACTER_LIMIT: "「遷移先URL」は半角1024文字以内で入力してください",
      INVALID_URL_FORMAT: "「遷移先URL」は正しいURL形式で入力してください",
    },
  },
  SCHEDULE: {
    DELIVERY_TIME_OUT_OF_RANGE:
      "配信日時は現時刻より60分後以降の時刻となるよう修正してください",
    WEEKDAYS: {
      MUST_BE_SELECTED: "曜日に１つ以上チェックを入れてください",
    },
    DAYS: {
      MUST_BE_SELECTED: "日付に１つ以上チェックをいれてください",
    },
    NO_EXIST_DELIVERY_SCHEDULE:
      "プッシュ通知が一度も配信されない設定になっています。くりかえし配信の条件と配信期間をご確認ください。",
  },
  CAMPAIGN: {
    DUPLICATE:
      "同セグメント・同配信日時のプッシュ通知は登録できません。配信日時を変更してください。",
  },
} as const;

export const DELIVERY_REPORT_ERROR_MESSAGE_LIST = {
  FROM_DATE: {
    MUST_BEFORE_TODAY:
      "「レポート対象期間」の開始日時には明日以降は設定できません。",
  },
  TO_DATE: {
    MUST_AFTER_FROM_DATE:
      "「レポート対象期間」の終了日時は開始日時以降に設定してください。",
  },
  TRACKING_DATE_RANGE: {
    MUST_BE_FILLED: "「トラッキング期間」を入力してください",
  },
} as const;

export const SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST = {
  NAME: {
    MUST_BE_FILLED: "「セグメント名」を入力してください",
    CHARACTER_LIMIT: "「セグメント名」は50文字以内で入力してください",
    FIRST_CHARACTER_IS_NOT_FIGURE:
      "「セグメント名」の先頭に記号を入れて登録することはできません。先頭の記号文字を削除してください。",
    EMOJI_LIMIT:
      "「セグメント名」に絵文字を登録することはできません。削除してください",
    DUPLICATE: "すでに設定されているセグメント名と重複しています",
  },
  CONDITIONS: {
    MUST_BE_LEAST_ONE_CONDITION: "1つ以上ページを指定する必要があります",
    MUST_BE_ABOVE_ZERO_DAY:
      "「許諾からの経過日数」は0以上の値を入力してください",
  },
} as const;

export const MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST = {
  TITLE: {
    MUST_BE_FILLED: "「タイトル」を入力してください",
    CHARACTER_LIMIT: "「タイトル」は50文字以内で入力してください",
  },
  LOCATION: {
    CHARACTER_LIMIT: "「URL」は1024文字以内で入力してください",
  },
  QUERY_PARAMETER: {
    CHARACTER_LIMIT: "「パラメータ」は1024文字以内で入力してください",
  },
  COMPOSITE: {
    MUST_BE_FILLED: "「URL」または「パラメータ」を入力してください",
  },
  SAVE: {
    FAILED: "保存に失敗しました",
  },
};

export const DOMAIN_ERROR_MESSAGE_LIST = {
  MUST_BE_FILLED: "ドメインを入力してください",
  INVALID_URL_FORMAT: "正しいドメイン形式を入力してください",
  UNEXPECTED_ERROR: "登録エラーが発生しました、再度お試しください。",
};
