import React, { useMemo } from "react";

import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import useGrantMessageEditPage from "app/hooks/v2/grantMessage/useGrantMessageEditPage";

import fontStyles from "fontStyles.module.scss";

import PublicStatus from "atoms/ezPushShared/accountPageShared/PublicStatus";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import GrantMessageEditPageLayout from "templates/ezPush/grantMessage/GrantMessageEditPageLayout";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import GrantMessageEditForm from "organisms/ezPush/grantMessageEdit/GrantMessageEditForm";
import ActionHtmlPreviewer from "molecules/v2/ActionHtmlPreviewer";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import { GrantMessage } from "types/grant_message";
import ErrorMessages from "molecules/v2/ErrorMessages";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";

const SaveModal: React.VFC<{
  isPublic: boolean;
  clearModalElement: () => void;
  save: () => void;
}> = ({ isPublic, clearModalElement, save }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="許諾メッセージを保存します。よろしいですか？"
        description={
          isPublic ? "ステータスが公開中のため、即時反映されます。" : undefined
        }
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={save} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

const CancelModal: React.VFC<{
  clearModalElement: () => void;
  backToHome: () => void;
}> = ({ clearModalElement, backToHome }) => (
  <ModalBackground>
    <NotificationModal
      title="許諾メッセージの作成をキャンセルします。よろしいですか？"
      description="入力内容は保持されませんので、ご注意ください。"
      buttons={[
        <TransparentButton text="いいえ" onClick={clearModalElement} />,
        <OrangeButton onClick={backToHome} text="はい" />,
      ]}
    />
  </ModalBackground>
);

const GrantMessageEditor: React.VFC<{
  accountContext: AccountPageContextV2;
  grantMessage: GrantMessage;
  loadGrantMessages: () => void;
  setModalElement: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
}> = ({ accountContext, grantMessage, loadGrantMessages, setModalElement }) => {
  const [grantMessageEditParameters, grantMessageEditCallbacks] =
    useGrantMessageEditPage(
      accountContext.managementApi,
      grantMessage,
      loadGrantMessages
    );

  // NOTE: コンポーネント生成
  const titleElement: JSX.Element = useMemo(
    () => (
      <HorizontalLayout
        elements={[
          <span className={fontStyles.title}>許諾メッセージ設定</span>,
          <PublicStatus isActive={grantMessageEditParameters.view.isPublic} />,
        ]}
      />
    ),
    [grantMessageEditParameters.view.isPublic]
  );

  return (
    <GrantMessageEditPageLayout
      titleElement={titleElement}
      errorElement={
        grantMessageEditParameters.view.errorMessages.length > 0 ? (
          <ErrorMessages
            errors={grantMessageEditParameters.view.errorMessages}
          />
        ) : (
          <></>
        )
      }
      previewElement={
        grantMessageEditParameters.view.previewHtml === "" ? (
          <LoadingView iconElement={<LoadingIcon />} />
        ) : (
          <ActionHtmlPreviewer
            layoutType={grantMessageEditParameters.data.layout.layoutType}
            html={grantMessageEditParameters.view.previewHtml}
          />
        )
      }
      editElement={
        <GrantMessageEditForm
          parameters={grantMessageEditParameters}
          callbacks={grantMessageEditCallbacks}
        />
      }
      buttonElement={
        <HorizontalLayout
          elements={[
            <TransparentButton
              onClick={() =>
                setModalElement(
                  <CancelModal
                    backToHome={grantMessageEditCallbacks.event.backToHome}
                    clearModalElement={() => setModalElement(undefined)}
                  />
                )
              }
              text="キャンセル"
            />,
            <OrangeButton
              onClick={() =>
                setModalElement(
                  <SaveModal
                    isPublic={grantMessageEditParameters.view.isPublic}
                    clearModalElement={() => setModalElement(undefined)}
                    save={() => {
                      grantMessageEditCallbacks.event.save(() =>
                        setModalElement(undefined)
                      );
                    }}
                  />
                )
              }
              text="保存"
            />,
          ]}
        />
      }
    />
  );
};

export default GrantMessageEditor;
