import styles from "./ReportArea.module.scss";

const ReportArea: React.VFC<{
  titleElement: JSX.Element;
  downloadButtonElement: JSX.Element;
  tableElement: JSX.Element;
}> = ({ titleElement, downloadButtonElement, tableElement }) => (
  <div className={styles.reportArea}>
    <div className={styles.reportAreaHeader}>
      <div className={styles.title}>{titleElement}</div>
      <div>{downloadButtonElement}</div>
    </div>
    <div>
      <div>{tableElement}</div>
    </div>
  </div>
);

export default ReportArea;
