import Switch from "react-switch";
import styles from "./ToggleButton.module.scss";

type ToggleButtonProps = {
  checked: boolean;
  onChange: (value: boolean) => void;
};

const ToggleButton = ({ checked, onChange }: ToggleButtonProps) => {
  return (
    <Switch
      checked={checked}
      onChange={(e) => onChange(e)}
      className={styles.toggleButton}
      width={40}
      height={20}
      handleDiameter={14}
      checkedIcon={false}
      uncheckedIcon={false}
      onColor="#FF7B2C"
      offColor="#CCCCCC"
    />
  );
};

export default ToggleButton;
