import { DeliveryReportFormParameters } from "app/hooks/v2/deliveryReport/useDeliveryReportForm";
import { DELIVERY_REPORT_DEFAULTS } from "app/system_defaults/delivery_report_defaults";
import { DELIVERY_REPORT_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import ErrorMessage from "utils/ErrorMessage";

class DeliveryReportRequestValidator {
  currentDate: Date;
  constructor(currentDate: Date) {
    this.currentDate = currentDate;
  }
  validate(params: DeliveryReportFormParameters): ErrorMessage[] {
    let errors: ErrorMessage[] = [];
    if (params.fromDate > this.currentDate) {
      errors.push(
        new ErrorMessage(
          DELIVERY_REPORT_ERROR_MESSAGE_LIST.FROM_DATE.MUST_BEFORE_TODAY
        )
      );
    }

    if (params.fromDate > params.toDate) {
      errors.push(
        new ErrorMessage(
          DELIVERY_REPORT_ERROR_MESSAGE_LIST.TO_DATE.MUST_AFTER_FROM_DATE
        )
      );
    }

    if (
      params.trackingDateRange <
      DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MIN ||
      params.trackingDateRange >
      DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MAX
    ) {
      errors.push(
        new ErrorMessage(
          DELIVERY_REPORT_ERROR_MESSAGE_LIST.TRACKING_DATE_RANGE.MUST_BE_FILLED
        )
      );
    }

    return errors;
  }
}

export default DeliveryReportRequestValidator;
