import { useCallback, useLayoutEffect, useMemo, useState } from "react";

import * as useEditPage from "app/hooks/v2/measurementUrlSetting/useEditPage";

import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import { MEASUREMENT_URL_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";

import fontStyles from "fontStyles.module.scss";

import AccountPageBase from "./AccountPageBase";
import TitleMainLayout from "templates/ezPush/TitleMainLayout";
import BreadCrumbs from "atoms/ezPushShared/accountPageShared/BreadCrumbs";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import MeasurementUrlSettingEditForm from "organisms/ezPush/measurementUrlSetting/editor/MeasurementUrlSettingEditForm";
import MeasurementUrlSettingExceedLimitModal from "organisms/ezPush/measurementUrlSetting/editor/MeasurementUrlSettingExceedLimitModal";

const MeasurementUrlSettingEditPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [modalContents, setModalContents] = useState<JSX.Element>();
  const [pageParameters, pageCallbacks] = useEditPage.useEditPage(
    accountContext.managementApi
  );

  const titleName = useMemo(
    () =>
      `${
        MEASUREMENT_URL_TYPE_DISPLAY_WORD[pageParameters.mode.type]
      }登録・編集`,
    [pageParameters.mode.type]
  );

  const crumbPaths = useMemo(() => {
    return [
      {
        name: "基本設定",
        url: V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.DOMAIN,
      },
      {
        name: titleName,
        url: V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.EDIT,
      },
    ];
  }, [titleName]);

  const isDisplayExceedLimitModal = useCallback(() => {
    if (!modalContents && pageParameters.isExceededLimit) {
      setModalContents(
        <MeasurementUrlSettingExceedLimitModal
          gobackListPage={pageCallbacks.event.onCancel}
        />
      );
    }
  }, [
    modalContents,
    pageParameters.isExceededLimit,
    pageCallbacks.event.onCancel,
    setModalContents,
  ]);

  useLayoutEffect(() => {
    // NOTE: 画面に移動したとき、ページ先頭にスクロールさせたい。
    window.scroll({ top: 0 });

    isDisplayExceedLimitModal();
  }, [isDisplayExceedLimitModal]);

  return (
    <AccountPageBase
      context={accountContext}
      modalContents={modalContents}
      areaContents={
        <TitleMainLayout
          headerElement={<BreadCrumbs paths={crumbPaths} />}
          titleElement={<span className={fontStyles.title}>{titleName}</span>}
          mainElement={
            <MeasurementUrlSettingEditForm
              pageParameters={pageParameters}
              pageCallbacks={pageCallbacks}
            />
          }
        />
      }
    />
  );
};

export default MeasurementUrlSettingEditPage;
