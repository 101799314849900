import styles from "./Header.module.scss";

export interface HeaderProps {
  logo: JSX.Element;
  accountNameElement: JSX.Element;
  signOutButtonElement: JSX.Element;
  accessStatisticsButton: JSX.Element;
  displayTagButton: JSX.Element;
}

/**
 * Component Category: Organism
 */
const Header = ({
  logo,
  accountNameElement,
  signOutButtonElement,
  accessStatisticsButton,
  displayTagButton,
}: HeaderProps) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.serviceLogo}>{logo}</div>
        <div className={styles.loginUser}>{accountNameElement}</div>
        <div className={styles.logout}>{signOutButtonElement}</div>
      </div>
      <div className={styles.accessStatisticsButton}>
        {accessStatisticsButton}
      </div>
      <div className={styles.headerRight}>{displayTagButton}</div>
    </header>
  );
};

export default Header;
