import React from "react";
import { SubscriptionStatistics } from "types/subscription_statistics";

import styles from "./SubscribeCountPanel.module.scss";
import animeStyles from "animation.module.scss";

import CheckMark from "atoms/ezPushShared/CheckMark";
import NumberStats from "atoms/ezPushShared/accountPageShared/NumberStats";

const SubscribeCountPanel: React.VFC<{
  stats: SubscriptionStatistics;
}> = ({ stats }) => {
  return (
    <div className={animeStyles.fadeIn}>
      <div className={styles.subscribeCountPanel}>
        <div className={styles.mark}>
          <CheckMark />
        </div>
        <div>
          <NumberStats number={stats.count.toLocaleString()} unit="人" />
        </div>
      </div>
    </div>
  );
};

export default SubscribeCountPanel;
