import styles from "./ReportHeader.module.scss";
import sortIcon from "images/icons/icon_arrow_bottom_small.svg";
import {
  sortColumnType,
  sortStateType,
  SORT_DIRECTION,
} from "interfaces/models";

export type reportLabel = {
  label: string;
  isSortable: boolean;
  columnId?: string;
  nest?: reportLabel[];
};

export const reportHeaderItems = (
  labels: reportLabel[],
  sortColumnId: sortColumnType,
  setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>,
  sortStatus: sortStateType,
  setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>
): JSX.Element[] => {
  const onClickHandler = (id: string) => {
    let columnStatus: sortStateType = sortStatus;
    let columnIndex = 0;
    columnStatus.forEach((c, index) => {
      if (c.columnId === id) {
        columnIndex = index;
        return;
      }
    });
    columnStatus[columnIndex].columnId = id;
    const direction =
      columnStatus[columnIndex].sortDirection === SORT_DIRECTION.ASC
        ? SORT_DIRECTION.DESC
        : SORT_DIRECTION.ASC;
    columnStatus[columnIndex].sortDirection = direction;
    setSortStatus(columnStatus);
    setSortColumnId({ columnId: id, sortDirection: direction });
  };

  return labels.map((e, i) => (
    <th key={i} className={styles.items}>
      <div className={styles.item}>
        <div className={styles.label}>{e.label}</div>
        <div className={styles.icon}>
          {e.isSortable ? (
            <img
              className={styles.sortIcon}
              src={sortIcon}
              alt=""
              onClick={() => onClickHandler(e.columnId ? e.columnId : "")}
            />
          ) : undefined}
        </div>
      </div>
      {e.nest ? (
        <div className={styles.nestedItem}>
          {e.nest.map((n, j) => (
            <>
              <div key={j} className={styles.nestedItemChild}>
                <div className={styles.label}>{n.label}</div>
                <div className={styles.iconRotated}>
                  {n.isSortable ? (
                    <img
                      className={styles.icon}
                      src={sortIcon}
                      alt=""
                      onClick={() =>
                        onClickHandler(n.columnId ? n.columnId : "")
                      }
                    />
                  ) : undefined}
                </div>
              </div>
            </>
          ))}
        </div>
      ) : undefined}
    </th>
  ));
};
