import RestApi from "utils/RestApi";
import {
  CreateMeasurementUrlRequest,
  CreateMeasurementUrlResponse,
  DeleteMeasurementUrlRequest,
  GetMeasurementUrlRequest,
  GetMeasurementUrlResponse,
  LoadMeasurementUrlWithTypeResponse,
  UpdateMeasurementUrlRequest,
  UpdateMeasurementUrlResponse,
} from "types/measurement_urls";

class MeasurementUrlRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async load(): Promise<LoadMeasurementUrlWithTypeResponse> {
    const data: LoadMeasurementUrlWithTypeResponse =
      await this.managementApi.get(`measurement-urls`);
    return data;
  }

  async get(
    request: GetMeasurementUrlRequest
  ): Promise<GetMeasurementUrlResponse> {
    const data: GetMeasurementUrlResponse = await this.managementApi.get(
      `measurement-urls/${request.url_uuid}`
    );
    return data;
  }

  async create(
    request: CreateMeasurementUrlRequest
  ): Promise<CreateMeasurementUrlResponse> {
    const data = await this.managementApi.post(`measurement-urls`, request);
    return data;
  }

  async update(
    request: UpdateMeasurementUrlRequest
  ): Promise<UpdateMeasurementUrlResponse> {
    const data = await this.managementApi.put(
      `measurement-urls/${request.url_uuid}`,
      request
    );
    return data;
  }

  async delete(request: DeleteMeasurementUrlRequest): Promise<void> {
    await this.managementApi.delete(`measurement-urls/${request.url_uuid}`);
  }
}

export default MeasurementUrlRepository;
