import { RawReport } from "interfaces/models";
import { Data } from "react-csv/components/CommonPropTypes";

const WHOLE_HEADER_CSV_LABELS: string[] = [
  "対象UU",
  "アクション実施_UU",
  "アクション実施_CVUU",
  "アクション実施_CVR",
  "アクション未実施_UU",
  "アクション未実施_CVUU",
  "アクション未実施_CVR",
  "CVR改善率",
  "アクション実施割合",
  "アクション表示回数",
  "リアクション数",
  "リアクション率",
  "アクション実施(新規のみ)_UU",
  "アクション実施(新規のみ)_CVUU",
  "アクション実施(新規のみ)_CVR",
  "アクション未実施(新規のみ)_UU",
  "アクション未実施(新規のみ)_CVUU",
  "アクション未実施(新規のみ)_CVR",
  "CVR改善率(新規のみ)",
];

const ACTION_DETAIL_HEADER_CSV_LABELS: string[] = [
  "No.",
  "アクション名",
  "対象UU",
  "アクション実施_UU",
  "アクション実施_CVUU",
  "アクション実施_CVR",
  "アクション未実施_UU",
  "アクション未実施_CVUU",
  "アクション未実施_CVR",
  "CVR改善率",
  "アクション実施割合",
  "アクション表示回数",
  "リアクション数",
  "リアクション率",
  "アクション実施(新規のみ)_UU",
  "アクション実施(新規のみ)_CVUU",
  "アクション実施(新規のみ)_CVR",
  "アクション未実施(新規のみ)_UU",
  "アクション未実施(新規のみ)_CVUU",
  "アクション未実施(新規のみ)_CVR",
  "CVR改善率(新規のみ)",
];

export const formatResponseWholeCsvData = (data: RawReport): Data => {
  return data.map((e, i) =>
    i === 0 ? WHOLE_HEADER_CSV_LABELS : e.map((e) => e)
  );
};

export const formatResponseActionDetailCsvData = (data: RawReport): Data => {
  const tmp = data.map((e) => e.filter((f, j) => j !== 0));
  return tmp.map((e, i) => (i === 0 ? ACTION_DETAIL_HEADER_CSV_LABELS : e));
};
