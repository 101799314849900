import HeadlineText from "atoms/HeadlineText";
import LoadingIcon from "atoms/LoadingIcon";
import {
  ReportMetaDataList,
  ReportPeriodType,
  ReportMetaData,
  Report,
  Score,
  ActionDetailScore,
  REPORT_DETAIL_ROW_COUNT_PER_PAGE,
  SORT_DIRECTION,
  SORT_TARGET_KEY_ID,
  sortColumnType,
  sortStateType,
  ReportType,
  REPORT_TYPE,
} from "interfaces/models";
import ReportArea from "organisms/account/reports/ReportArea";
import ReportHeader from "organisms/account/reports/ReportHeader";
import ReportTable, { ReportTableRow } from "molecules/ReportTable/ReportTable";
import { reportLabel } from "molecules/ReportTable/ReportHeader";
import ActionDetailReportTable from "organisms/account/reports/ActionDetailReportTable";
import WholeReportTable from "organisms/account/reports/WholeReportTable";
import DownloadButton from "atoms/accountPagesShared/DownloadButton";
import { Data } from "react-csv/components/CommonPropTypes";
import NestedLists from "atoms/accountPagesShared/NestedLists";
import ReportPageNation from "organisms/account/actions/molecules/ReportPageNation";
import { FORMAT_TYPE } from "molecules/ReportTable/ReportItem";
import { FixedReportLabel } from "molecules/FixedReportTable/FixedReportHeader";
import FixedReportTable from "molecules/FixedReportTable/FixedReportTable";

const LOCALE = "ja-JP";
const TIMEZONE = { timeZone: "Asia/Tokyo" };
const formatDate = (date: Date) => date.toLocaleDateString(LOCALE, TIMEZONE);
const createActionDetailReportElement = (
  reportDetail: Report | undefined,
  csvData: Data | undefined,
  csvFileName: string | undefined,
  filterReportDetailRowNumber: {
    sliceFromIndex: number;
    sliceToRowIndex: number;
  },
  sortColumnId: sortColumnType,
  setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>,
  sortStatus: sortStateType,
  setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>,
  selectedPageNationNumber: number,
  setSelectedPageNationNumber: React.Dispatch<React.SetStateAction<number>>
) => {
  const reportHeaderLabels: FixedReportLabel[] = [
    {
      label: "No.",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.no,
    },
    {
      label: "アクション名",
      isSortable: false,
      columnId: SORT_TARGET_KEY_ID.action_name,
    },
    {
      label: "対象UU",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.target_uu,
    },
    {
      label: "アクション実施",
      isSortable: false,
      columnId: SORT_TARGET_KEY_ID.triggered,
      nest: [
        {
          label: "UU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.triggered_uu,
        },
        {
          label: "CVUU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.triggered_cvuu,
        },
        {
          label: "CVR",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.triggered_cvr,
        },
      ],
    },
    {
      label: "アクション未実施",
      isSortable: false,
      columnId: SORT_TARGET_KEY_ID.not_triggered,
      nest: [
        {
          label: "UU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.not_triggered_uu,
        },
        {
          label: "CVUU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.not_triggered_cvuu,
        },
        {
          label: "CVR",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.not_triggered_cvr,
        },
      ],
    },
    {
      label: "CVR改善率",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.cvr_improve_rate,
    },
    {
      label: "アクション実施割合",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.action_done_rate,
    },
    {
      label: "アクション表示回数",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.action_display_count,
    },
    {
      label: "リアクション数",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.reaction_count,
    },
    {
      label: "リアクション率",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.reaction_rate,
    },
    {
      label: "アクション実施(新規のみ)",
      isSortable: false,
      columnId: SORT_TARGET_KEY_ID.triggered_new,
      nest: [
        {
          label: "UU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.triggered_new_uu,
        },
        {
          label: "CVUU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.triggered_new_cvuu,
        },
        {
          label: "CVR",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.triggered_new_cvr,
        },
      ],
    },
    {
      label: "アクション未実施(新規のみ)",
      isSortable: false,
      columnId: SORT_TARGET_KEY_ID.not_triggered_new,
      nest: [
        {
          label: "UU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.not_triggered_new_uu,
        },
        {
          label: "CVUU",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.not_triggered_new_cvuu,
        },
        {
          label: "CVR",
          isSortable: true,
          columnId: SORT_TARGET_KEY_ID.not_triggered_new_cvr,
        },
      ],
    },
    {
      label: "CVR改善率(新規のみ)",
      isSortable: true,
      columnId: SORT_TARGET_KEY_ID.cvr_improve_rate_new,
    },
  ];

  const getSortTargetKeys = (actionDetailScore: ActionDetailScore): any => {
    const keys: string[] = Object.keys(actionDetailScore);
    // FIXME: パターン網羅に漏れがある。action_nameなど
    //No
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.no) {
      return keys.map((k) => [k, actionDetailScore[k].action_detail.no]);
    }
    //Name
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.action_name) {
      return keys.map((k) => [k, actionDetailScore[k].action_detail.name]);
    }
    //対象UU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.target_uu) {
      return keys.map((k) => [k, actionDetailScore[k].score.matched_uu]);
    }
    //アクション実施>UU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.triggered_uu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.triggered.uu,
      ]);
    }
    //アクション実施>CVUU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.triggered_cvuu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.triggered.cv_uu,
      ]);
    }
    //アクション実施>CVR
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.triggered_cvr) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.triggered.cvr,
      ]);
    }
    //アクション未実施>UU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.not_triggered_uu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.not_triggered.uu,
      ]);
    }
    //アクション未実施>CVUU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.not_triggered_cvuu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.not_triggered.cv_uu,
      ]);
    }
    //アクション未実施>CVR
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.not_triggered_cvr) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.not_triggered.cvr,
      ]);
    }
    //CVR改善率
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.cvr_improve_rate) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.all_user.cvr_improve_rate,
      ]);
    }
    //アクション実施割合
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.action_done_rate) {
      return keys.map((k) => [k, actionDetailScore[k].score.triggered_uu_rate]);
    }
    //アクション表示回数
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.action_display_count) {
      return keys.map((k) => [k, actionDetailScore[k].score.triggered]);
    }
    //リアクション数
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.reaction_count) {
      return keys.map((k) => [k, actionDetailScore[k].score.reaction]);
    }
    //リアクション率
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.reaction_rate) {
      return keys.map((k) => [k, actionDetailScore[k].score.reaction_rate]);
    }
    //アクション実施(新規のみ) > UU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.triggered_new_uu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.triggered.uu,
      ]);
    }
    //アクション実施(新規のみ) > CVUU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.triggered_new_cvuu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.triggered.cv_uu,
      ]);
    }
    //アクション実施(新規のみ) > CVR
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.triggered_new_cvr) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.triggered.cvr,
      ]);
    }
    //アクション未実施(新規のみ) > UU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.not_triggered_new_uu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.not_triggered.uu,
      ]);
    }
    //アクション未実施(新規のみ) > CVUU
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.not_triggered_new_cvuu) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.not_triggered.cv_uu,
      ]);
    }
    //アクション未実施(新規のみ) > CVR
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.not_triggered_new_cvr) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.not_triggered.cvr,
      ]);
    }
    //CVR改善率(新規のみ)
    if (sortColumnId.columnId === SORT_TARGET_KEY_ID.cvr_improve_rate_new) {
      return keys.map((k) => [
        k,
        actionDetailScore[k].score.new_user.cvr_improve_rate,
      ]);
    }
    return [];
  };

  //数値列に混ざった"-"を変換し、数値としてソート可能にする
  const convertHyphen = (value: string | number) =>
    value === "-" ? Number.NEGATIVE_INFINITY : value;

  const convertTargetSortKeys = (
    targetSortKeys: any[],
    columnIndex: number
  ) => {
    return targetSortKeys.map((k) =>
      sortStatus[columnIndex].isString
        ? [k[0], String(k[1])]
        : [k[0], Number(convertHyphen(k[1]))]
    );
  };

  // FIXME: anyを多用しているので、厳密に型をつける。
  const sortActionDetailScore = (
    actionDetailScore: ActionDetailScore
  ): string[] => {
    const targetSortKeys: any[] = getSortTargetKeys(actionDetailScore);
    let columnIndex = 0;
    sortStatus.forEach((c, index) => {
      if (c.columnId === sortColumnId.columnId) {
        columnIndex = index;
        return;
      }
    });
    const convertedTargetSortKeys = convertTargetSortKeys(
      targetSortKeys,
      columnIndex
    );
    // NOTE: [[uuid_1, 1], [uuid_2, 2],...]二次元配列
    if (sortStatus[columnIndex].sortDirection === SORT_DIRECTION.ASC) {
      sortStatus[columnIndex].isString
        ? convertedTargetSortKeys.sort().reverse()
        : convertedTargetSortKeys.sort((a, b) => a[1] - b[1]);
    } else {
      sortStatus[columnIndex].isString
        ? convertedTargetSortKeys.sort()
        : convertedTargetSortKeys.sort((a, b) => b[1] - a[1]);
    }
    // NOTE: uuidのいち次元配列
    return convertedTargetSortKeys.map((k) => k[0]);
  };

  const reportScoreCells = (
    actionDetailScore: ActionDetailScore
  ): ReportTableRow[] => {
    //actionDetailScoreをソート
    const sortedUuid: string[] = sortActionDetailScore(actionDetailScore);
    //1ページに表示する件数を切り出し
    const keys = sortedUuid.slice(
      filterReportDetailRowNumber.sliceFromIndex,
      filterReportDetailRowNumber.sliceToRowIndex
    );

    return keys.map((k) => {
      const meta = actionDetailScore[k].action_detail;
      const score = actionDetailScore[k].score;
      return {
        items: [
          {
            value: meta.no,
            columnId: SORT_TARGET_KEY_ID.no,
            formatType: FORMAT_TYPE.INDEX,
          },
          {
            value: meta.name,
            columnId: SORT_TARGET_KEY_ID.action_name,
            formatType: FORMAT_TYPE.TEXT,
          },
          {
            columnId: SORT_TARGET_KEY_ID.target_uu,
            value: score.matched_uu,
            formatType: FORMAT_TYPE.NUMBER,
          },
          {
            nestedValues: [
              {
                columnId: SORT_TARGET_KEY_ID.triggered_uu,
                value: score.all_user.triggered.uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.triggered_cvuu,
                value: score.all_user.triggered.cv_uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.triggered_cvr,
                value: score.all_user.triggered.cvr,
                formatType: FORMAT_TYPE.RATE,
              },
            ],
          },
          {
            nestedValues: [
              {
                columnId: SORT_TARGET_KEY_ID.not_triggered_uu,
                value: score.all_user.not_triggered.uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.not_triggered_cvuu,
                value: score.all_user.not_triggered.cv_uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.not_triggered_cvr,
                value: score.all_user.not_triggered.cvr,
                formatType: FORMAT_TYPE.RATE,
              },
            ],
          },
          {
            columnId: SORT_TARGET_KEY_ID.cvr_improve_rate,
            value: score.all_user.cvr_improve_rate,
            formatType: FORMAT_TYPE.IMPROVED_RATE,
          },
          {
            columnId: SORT_TARGET_KEY_ID.action_done_rate,
            value: score.triggered_uu_rate,
            formatType: FORMAT_TYPE.RATE,
          },
          {
            columnId: SORT_TARGET_KEY_ID.action_display_count,
            value: score.triggered,
            formatType: FORMAT_TYPE.NUMBER,
          },
          {
            columnId: SORT_TARGET_KEY_ID.reaction_count,
            value: score.reaction,
            formatType: FORMAT_TYPE.NUMBER,
          },
          {
            columnId: SORT_TARGET_KEY_ID.reaction_rate,
            value: score.reaction_rate,
            formatType: FORMAT_TYPE.RATE,
          },
          {
            nestedValues: [
              {
                columnId: SORT_TARGET_KEY_ID.triggered_new_uu,
                value: score.new_user.triggered.uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.triggered_new_cvuu,
                value: score.new_user.triggered.cv_uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.triggered_new_cvr,
                value: score.new_user.triggered.cvr,
                formatType: FORMAT_TYPE.RATE,
              },
            ],
          },
          {
            nestedValues: [
              {
                columnId: SORT_TARGET_KEY_ID.not_triggered_new_uu,
                value: score.new_user.not_triggered.uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.not_triggered_new_cvuu,
                value: score.new_user.not_triggered.cv_uu,
                formatType: FORMAT_TYPE.NUMBER,
              },
              {
                columnId: SORT_TARGET_KEY_ID.not_triggered_new_cvr,
                value: score.new_user.not_triggered.cvr,
                formatType: FORMAT_TYPE.RATE,
              },
            ],
          },
          {
            columnId: SORT_TARGET_KEY_ID.cvr_improve_rate_new,
            value: score.new_user.cvr_improve_rate,
            formatType: FORMAT_TYPE.IMPROVED_RATE,
          },
        ],
      };
    });
  };

  return (
    <ReportArea
      titleElement={<HeadlineText text="個別成果レポート" />}
      downloadButtonElement={
        csvData && csvFileName ? (
          <DownloadButton
            text="CSVファイル"
            data={csvData}
            fileName={csvFileName}
          />
        ) : (
          <LoadingIcon />
        )
      }
      tableElement={
        reportDetail ? (
          <ActionDetailReportTable
            fixedTable={
              <FixedReportTable
                headerLabels={reportHeaderLabels}
                rows={reportScoreCells(reportDetail.each)}
                sortColumnId={sortColumnId}
                setSortColumnId={setSortColumnId}
                sortStatus={sortStatus}
                setSortStatus={setSortStatus}
                selectedPageNationNumber={selectedPageNationNumber}
                setSelectedPageNationNumber={setSelectedPageNationNumber}
              />
            }
          />
        ) : (
          <LoadingIcon />
        )
      }
    />
  );
};

const createWholeReportElement = (
  reportDetail: Report | undefined,
  csvData: Data | undefined,
  csvFileName: string | undefined,
  sortColumnId: sortColumnType,
  setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>,
  sortStatus: sortStateType,
  setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>
) => {
  const wholeHeaderLabels: reportLabel[] = [
    { label: "対象UU", isSortable: false, columnId: "" },
    {
      label: "アクション実施",
      isSortable: false,
      nest: [
        { label: "UU", isSortable: false },
        { label: "CVUU", isSortable: false },
        { label: "CVR", isSortable: false },
      ],
    },
    {
      label: "アクション未実施",
      isSortable: false,
      nest: [
        { label: "UU", isSortable: false },
        { label: "CVUU", isSortable: false },
        { label: "CVR", isSortable: false },
      ],
    },
    { label: "CVR改善率", isSortable: false },
    { label: "アクション実施割合", isSortable: false },
    { label: "アクション表示回数", isSortable: false },
    { label: "リアクション数", isSortable: false },
    { label: "リアクション率", isSortable: false },
    {
      label: "アクション実施(新規のみ)",
      isSortable: false,
      nest: [
        { label: "UU", isSortable: false },
        { label: "CVUU", isSortable: false },
        { label: "CVR", isSortable: false },
      ],
    },
    {
      label: "アクション未実施(新規のみ)",
      isSortable: false,
      nest: [
        { label: "UU", isSortable: false },
        { label: "CVUU", isSortable: false },
        { label: "CVR", isSortable: false },
      ],
    },
    { label: "CVR改善率(新規のみ)", isSortable: false },
  ];

  const wholeReportItems = (score: Score): ReportTableRow => {
    return {
      items: [
        {
          columnId: SORT_TARGET_KEY_ID.target_uu,
          value: score.matched_uu,
          formatType: FORMAT_TYPE.NUMBER,
        },
        {
          nestedValues: [
            {
              columnId: SORT_TARGET_KEY_ID.triggered_uu,
              value: score.all_user.triggered.uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.triggered_cvuu,
              value: score.all_user.triggered.cv_uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.triggered_cvr,
              value: score.all_user.triggered.cvr,
              formatType: FORMAT_TYPE.RATE,
            },
          ],
        },
        {
          nestedValues: [
            {
              columnId: SORT_TARGET_KEY_ID.not_triggered_uu,
              value: score.all_user.not_triggered.uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.not_triggered_cvuu,
              value: score.all_user.not_triggered.cv_uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.not_triggered_cvr,
              value: score.all_user.not_triggered.cvr,
              formatType: FORMAT_TYPE.RATE,
            },
          ],
        },
        {
          columnId: SORT_TARGET_KEY_ID.cvr_improve_rate,
          value: score.all_user.cvr_improve_rate,
          formatType: FORMAT_TYPE.IMPROVED_RATE,
        },
        {
          columnId: SORT_TARGET_KEY_ID.action_done_rate,
          value: score.triggered_uu_rate,
          formatType: FORMAT_TYPE.RATE,
        },
        {
          columnId: SORT_TARGET_KEY_ID.action_display_count,
          value: score.triggered,
          formatType: FORMAT_TYPE.NUMBER,
        },
        {
          columnId: SORT_TARGET_KEY_ID.reaction_count,
          value: score.reaction,
          formatType: FORMAT_TYPE.NUMBER,
        },
        {
          columnId: SORT_TARGET_KEY_ID.reaction_rate,
          value: score.reaction_rate,
          formatType: FORMAT_TYPE.RATE,
        },
        {
          nestedValues: [
            {
              columnId: SORT_TARGET_KEY_ID.triggered_new_uu,
              value: score.new_user.triggered.uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.triggered_new_cvuu,
              value: score.new_user.triggered.cv_uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.triggered_new_cvr,
              value: score.new_user.triggered.cvr,
              formatType: FORMAT_TYPE.RATE,
            },
          ],
        },
        {
          nestedValues: [
            {
              columnId: SORT_TARGET_KEY_ID.not_triggered_new_uu,
              value: score.new_user.not_triggered.uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.not_triggered_new_cvuu,
              value: score.new_user.not_triggered.cv_uu,
              formatType: FORMAT_TYPE.NUMBER,
            },
            {
              columnId: SORT_TARGET_KEY_ID.not_triggered_new_cvr,
              value: score.new_user.not_triggered.cvr,
              formatType: FORMAT_TYPE.RATE,
            },
          ],
        },
        {
          columnId: SORT_TARGET_KEY_ID.cvr_improve_rate_new,
          value: score.new_user.cvr_improve_rate,
          formatType: FORMAT_TYPE.IMPROVED_RATE,
        },
      ],
    };
  };

  return (
    <ReportArea
      titleElement={<HeadlineText text="全体成果レポート" />}
      downloadButtonElement={
        csvData && csvFileName ? (
          <DownloadButton
            text="CSVファイル"
            data={csvData}
            fileName={csvFileName}
          />
        ) : (
          <LoadingIcon />
        )
      }
      tableElement={
        reportDetail ? (
          <WholeReportTable
            tableElement={
              <ReportTable
                headerLabels={wholeHeaderLabels}
                rows={[wholeReportItems(reportDetail.total)]}
                sortColumnId={sortColumnId}
                setSortColumnId={setSortColumnId}
                sortStatus={sortStatus}
                setSortStatus={setSortStatus}
              />
            }
          />
        ) : (
          <LoadingIcon />
        )
      }
    />
  );
};

const createPageNationElements = (
  selectedPageNationNumber: number,
  reportDetail: Report | undefined,
  setSelectedPageNationNumber: React.Dispatch<React.SetStateAction<number>>
): JSX.Element => {
  return reportDetail ? (
    <ReportPageNation
      selectedPageNationNumber={selectedPageNationNumber}
      setSelectedPageNationNumber={setSelectedPageNationNumber}
      lowCountPerPage={REPORT_DETAIL_ROW_COUNT_PER_PAGE}
      wholeLowCount={Object.keys(reportDetail.each).length}
    />
  ) : (
    <LoadingIcon />
  );
};

export const createReportContents = (
  parameters: {
    reportMetaDataList: ReportMetaDataList | undefined;
    reportPeriodType: string;
    reportDetail: Report | undefined;
    wholeReportCsv: Data | undefined;
    actionDetailReportCsv: Data | undefined;
    wholeCsvFileName: string | undefined;
    actionDetailCsvFileName: string | undefined;
    filterReportDetailRowNumber: {
      sliceFromIndex: number;
      sliceToRowIndex: number;
    };
    sortColumnId: sortColumnType;
    sortStatus: sortStateType;
    reportYearMonth: string | undefined;
    selectedPageNationNumber: number;
  },
  callbacks: {
    changeReportPeriodType: (reportPeriodType: ReportPeriodType) => void;
    changeReportYearMonth: (reportYearMonth: string) => void;
    setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>;
    setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>;
    setReportPeriodType: React.Dispatch<React.SetStateAction<string>>;
    filterReportMetaDataList: (
      reportMetaDataList: ReportMetaDataList | undefined,
      reportPeriodType: ReportPeriodType,
      reportYearMonth: string | undefined,
      reportType: ReportType
    ) => ReportMetaData | null;
    setSelectedPageNationNumber: React.Dispatch<React.SetStateAction<number>>;
  }
): JSX.Element[] => {
  const LastUpdateTimeElement = (): JSX.Element => {
    if (parameters.reportMetaDataList) {
      const targetMetaData = callbacks.filterReportMetaDataList(
        parameters.reportMetaDataList,
        parameters.reportPeriodType,
        parameters.reportYearMonth,
        REPORT_TYPE.WHOLE
      );
      if (targetMetaData) {
        const tmpMetaDataCreatedAt = new Date(targetMetaData.created_at);
        tmpMetaDataCreatedAt.setDate(tmpMetaDataCreatedAt.getDate() - 1);
        return <>{formatDate(tmpMetaDataCreatedAt)}までのデータを反映</>;
      } else {
        return <>データが見つかりませんでした。</>;
      }
    } else {
      return <LoadingIcon />;
    }
  };

  const headerElement = (
    <ReportHeader
      titleElement={<HeadlineText text="成果レポート" />}
      updateTimeElement={<LastUpdateTimeElement />}
      reportSelectorElement={
        parameters.reportMetaDataList ? (
          <NestedLists
            reportMetaDataList={parameters.reportMetaDataList}
            changeReportPeriodType={callbacks.changeReportPeriodType}
            changeReportYearMonth={callbacks.changeReportYearMonth}
          />
        ) : (
          <LoadingIcon />
        )
      }
    />
  );

  const wholeReportElement = createWholeReportElement(
    parameters.reportDetail,
    parameters.wholeReportCsv,
    parameters.wholeCsvFileName,
    parameters.sortColumnId,
    callbacks.setSortColumnId,
    parameters.sortStatus,
    callbacks.setSortStatus
  );

  const actionDetailElement = createActionDetailReportElement(
    parameters.reportDetail,
    parameters.actionDetailReportCsv,
    parameters.actionDetailCsvFileName,
    parameters.filterReportDetailRowNumber,
    parameters.sortColumnId,
    callbacks.setSortColumnId,
    parameters.sortStatus,
    callbacks.setSortStatus,
    parameters.selectedPageNationNumber,
    callbacks.setSelectedPageNationNumber
  );

  const pageNationElements = createPageNationElements(
    parameters.selectedPageNationNumber,
    parameters.reportDetail,
    callbacks.setSelectedPageNationNumber
  );

  return [
    headerElement,
    wholeReportElement,
    actionDetailElement,
    pageNationElements,
  ];
};
