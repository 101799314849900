import {
  ActionTerminateReaction,
  ACTION_TERMINATE_REACTION,
} from "interfaces/models";
import CheckBox from "molecules/CheckBox/CheckBox";

type TerminateReactionFormProps = {
  terminateReaction: ActionTerminateReaction;
  setTerminateReaction: (value: ActionTerminateReaction) => void;
};

const TerminateReactionForm = ({
  terminateReaction,
  setTerminateReaction,
}: TerminateReactionFormProps) => {
  return (
    <CheckBox
      text="ポップアップ内のボタンがクリックされたら、以降は表示しない"
      value={terminateReaction !== ACTION_TERMINATE_REACTION.NONE}
      onChange={() => {
        if (terminateReaction === ACTION_TERMINATE_REACTION.CLICKED) {
          setTerminateReaction(ACTION_TERMINATE_REACTION.NONE);
        } else if (terminateReaction === ACTION_TERMINATE_REACTION.NONE) {
          setTerminateReaction(ACTION_TERMINATE_REACTION.CLICKED);
        }
      }}
    />
  );
};

export default TerminateReactionForm;
