import React from "react";
import styles from "./GrayBackPanel.module.scss";
import fontStyles from "fontStyles.module.scss";

const GrayBackPanel: React.VFC<{
  text: string | JSX.Element;
  notice?: string | JSX.Element;
}> = ({ text, notice }) => {
  return (
    <div className={styles.grayBackPanel}>
      <div className={styles.panel}>
        <div className={fontStyles.section}>{text}</div>
      </div>
      {notice ? (
        <div className={styles.notice}>
          <div className={fontStyles.note}>{notice}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GrayBackPanel;
