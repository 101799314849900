import HeadlineText from "atoms/HeadlineText";
import Sentence from "atoms/authorizePagesShared/Sentence";
import OrangeWideButton from "atoms/ezPushShared/authPageShared/OrangeWideButton";
import AuthFormLayoutV2 from "templates/ezPush/auth/AuthFormLayoutV2";
import AuthPageBaseV2 from "./AuthPageBase";

const SessionExpiredPageV2 = ({
  onClickLoginButton,
}: {
  onClickLoginButton: () => void;
}) => {
  return (
    <AuthPageBaseV2
      formElement={
        <AuthFormLayoutV2
          headline={
            <HeadlineText text="セッション切れによりログアウトしました" />
          }
          sentence={<Sentence text="以下より再度ログインください" />}
          button={
            <OrangeWideButton text="ログイン" onClick={onClickLoginButton} />
          }
        />
      }
    />
  );
};

export default SessionExpiredPageV2;
