import { V2_SERVICE_NAME } from "app/system_defaults/v2_service";
import V2LogoSvg from "images/logos/target_push_logo.svg";
import styles from "./V2Logo.module.scss";

const V2Logo: React.VFC<{ width?: number; height?: number }> = ({
  width,
  height,
}) => {
  return (
    <img
      src={V2LogoSvg}
      alt={V2_SERVICE_NAME}
      width={width}
      height={height}
      className={styles.v2logo}
    />
  );
};

export default V2Logo;
