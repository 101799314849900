export const getNumberLocale = (num: number): string | null => {
  if (num >= 0) {
    return num.toLocaleString();
  }

  return null;
};

export const getRateLocale = (num: number): string | null => {
  if (num >= 0) {
    return `${(num * 100).toFixed(2)}%`;
  }

  return null;
};
