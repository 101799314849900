import { useState } from "react";
import styles from "./SegmentationRuleName.module.scss";

type SegmentationRuleNameProps = {
  name: string;
  operationElement?: JSX.Element;
  onClick: () => void;
};

const SegmentationRuleName = ({
  name,
  operationElement,
  onClick,
}: SegmentationRuleNameProps) => {
  const [isHover, setHover] = useState<boolean>(false);

  return (
    <div
      className={styles.actionName}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <button className={styles.name} onClick={onClick}>
        {name}
      </button>
      <div className={styles.operation}>
        {isHover ? operationElement : undefined}
      </div>
    </div>
  );
};

export default SegmentationRuleName;
