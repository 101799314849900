import React from "react";
import styles from "./InitializePageTemplate.module.scss";

const InitializePageTemplate: React.VFC<{
  headerElement: JSX.Element;
  areaContents: JSX.Element;
  footerElement: JSX.Element;
  modalElement?: JSX.Element;
}> = ({ headerElement, areaContents, footerElement, modalElement }) => {
  return (
    <div className={styles.initializePageTemplate}>
      <div className={styles.header}>{headerElement}</div>
      <div className={styles.main}>
        <div className={styles.contents}>{areaContents}</div>
        <div className={styles.footer}>{footerElement}</div>
      </div>
      {modalElement}
    </div>
  );
};

export default InitializePageTemplate;
