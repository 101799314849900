import styles from "./TitleMainLayout.module.scss";

const TitleMainLayout: React.VFC<{
  titleElement: JSX.Element;
  mainElement: JSX.Element;
  headerElement?: JSX.Element;
}> = ({ titleElement, mainElement, headerElement }) => {
  return (
    <div className={styles.titleMainLayout}>
      {headerElement ? (
        <div className={styles.header}>{headerElement}</div>
      ) : (
        <></>
      )}
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.main}>{mainElement}</div>
    </div>
  );
};

export default TitleMainLayout;
