import React, { useRef } from "react";
import styles from "./NumberInput.module.scss";
import fontStyles from "fontStyles.module.scss";

const NumberInput: React.VFC<{
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
}> = ({ value, onChange, min, max }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={fontStyles.note}>
      <input
        ref={inputRef}
        className={styles.input}
        type="number"
        min={min}
        max={max}
        step={1}
        value={value}
        onChange={(e) => {
          const num = parseInt(e.target.value);
          if (onChange) {
            if (isNaN(num)) {
              onChange(0);
            }

            if (Number.isInteger(num)) {
              if (max !== undefined && num > max) {
                onChange(max);
              } else if (min !== undefined && num < min) {
                onChange(min);
              } else {
                onChange(num);
              }
            }
          }
        }}
        onFocus={() => {
          inputRef.current?.select();
        }}
      />
    </div>
  );
};

export default NumberInput;
