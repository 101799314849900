import RestApi from "./RestApi";
import { PermissionType } from "interfaces/models";

class SessionRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(): Promise<PermissionType> {
    const data = await this.managementApi.get("session");

    return data["permission"];
  }
}

export default SessionRepository;
