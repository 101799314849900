import { PLEASE_CHOICE } from "app/system_defaults/WordDefaults";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import NumberInput from "atoms/NumberInput";
import {
  CURRENT_PAGE_BEHAVIOR_TYPE,
  SegmentationRuleCreation,
} from "interfaces/model/segmentationRuleCreation";
import { UrlPattern } from "interfaces/models";
import CurrentPageForm from "molecules/SegmentationRuleCreation/CurrentPageForm";
import UrlMatchPatternForm from "molecules/SegmentationRuleCreation/UrlMatchPatternForm";
import SegmentationRuleCreationCurrentPageForm from "organisms/account/segmentation_rule_creation/SegmentationRuleCreationCurrentPageForm";
import TransitionForm from "organisms/account/segmentation_rule_creation/TransitionForm";
import { useMemo } from "react";
import { SegmentationRuleCreationCallbacks } from "utils/segmentation_rule_creation/SegmentationRuleCreationCallbacks";
import { UrlPatternCodec } from "utils/segmentation_rule_creation/UrlPatternCodec";

const IS_USING_URL_BEHAVIOR: SegmentationRuleCreation.CurrentPageBehaviorType[] =
  [
    CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE,
    CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME,
  ];

type CreateBehaviorFormProps = {
  state: SegmentationRuleCreation.CurrentPageBehavior;
  onSelectBehaviorType: (
    value: SegmentationRuleCreation.CurrentPageBehaviorType
  ) => void;
  onChangeInputValue: (value: number) => void;
};

const CreateBehaviorForm = ({
  state,
  onSelectBehaviorType,
  onChangeInputValue,
}: CreateBehaviorFormProps) => {
  const behaviorTypeOptions: OptionType[] = [
    {
      label: PLEASE_CHOICE,
      value: CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED,
    },
    { label: "スクロール量", value: CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE },
    { label: "滞在時間", value: CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME },
    {
      label: "前回のページビューから",
      value: CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK,
    },
  ];

  const behaviorSelectorElement = (
    <BorderedSelectBox
      options={behaviorTypeOptions}
      value={state.behaviorType}
      onChange={(e) => onSelectBehaviorType(e)}
    />
  );

  const inputElement = (
    <NumberInput value={state.value} onChange={(e) => onChangeInputValue(e)} />
  );

  const getSuffix = (
    type: SegmentationRuleCreation.CurrentPageBehaviorType
  ) => {
    if (type === CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE) {
      return "% 以上";
    } else if (type === CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME) {
      return "秒 以上";
    } else if (type === CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK) {
      return "日 以上 経過";
    } else {
      return "";
    }
  };

  return (
    <CurrentPageForm
      behaviorSelectorElement={behaviorSelectorElement}
      inputElement={
        state.behaviorType !== CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED
          ? inputElement
          : undefined
      }
      suffixString={getSuffix(state.behaviorType)}
    />
  );
};

const getInitialUrl = (
  behaviors: SegmentationRuleCreation.CurrentPageBehavior[]
): UrlPattern | undefined => {
  const urlUsingBehaviors: SegmentationRuleCreation.CurrentPageBehaviorType[] =
    [
      CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE,
      CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME,
    ];
  const targets = behaviors.filter((e) =>
    urlUsingBehaviors.includes(e.behaviorType)
  );

  if (targets.length > 0 && targets[0].url) {
    return targets[0].url;
  } else {
    return undefined;
  }
};

type CreateCurrentPageFormProps = {
  state: SegmentationRuleCreation.CurrentPageBehavior[];
  initState: SegmentationRuleCreation.CurrentPageBehavior[] | undefined;
  formCount: number;
  callbacks: SegmentationRuleCreationCallbacks.CurrentPageBehaviorSettingCallbacks;
};

const CreateCurrentPageForm = ({
  state,
  initState,
  formCount,
  callbacks,
}: CreateCurrentPageFormProps): JSX.Element => {
  const currentPageElements = Array.from(Array(formCount))
    .map((_, i) =>
      state[i] ? (
        <TransitionForm
          key={i}
          formElement={
            <CreateBehaviorForm
              state={state[i]}
              onSelectBehaviorType={(v) => callbacks.onChangeType(i, v)}
              onChangeInputValue={(v) => callbacks.onChangeValue(i, v)}
            />
          }
          deleteButtonElement={
            formCount > 1 ? (
              <DeleteButton onClick={() => callbacks.delete(i)} />
            ) : undefined
          }
        />
      ) : undefined
    )
    .filter((e): e is Exclude<typeof e, undefined> => e !== undefined);

  const initApiData: ManagementApiData.UrlMatchPattern | undefined =
    useMemo(() => {
      const initUrl = initState ? getInitialUrl(initState) : undefined;
      return initUrl ? UrlPatternCodec.decode(initUrl) : undefined;
    }, [initState]);

  const currentPageUrlFormElement = (
    <UrlMatchPatternForm
      initValue={initApiData}
      onChange={(e) => callbacks.onChangeUrl(UrlPatternCodec.encode(e))}
      isActive={state.some((e) =>
        IS_USING_URL_BEHAVIOR.includes(e.behaviorType)
      )}
    />
  );

  const descriptionElements: JSX.Element[] = [
    <NoticeText text="※アクション設定と同じURLを指定してください。" />,
    <NoticeText text="※「スクロール量」または「滞在時間」の設定で適用されます。" />,
  ];

  return (
    <SegmentationRuleCreationCurrentPageForm
      urlElement={currentPageUrlFormElement}
      descriptionElements={descriptionElements}
      formElements={currentPageElements}
      separatorElement={<NoticeText text="and" />}
    />
  );
};

export default CreateCurrentPageForm;
