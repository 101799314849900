import {
  LOCATION_COMPARE_TYPE_DISPLAY_WORD,
  QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD,
} from "app/system_defaults/WordDefaults";
import {
  MeasurementUrl,
  MeasurementUrlLocation,
  MeasurementUrlQueryParameters,
} from "types/measurement_urls";

export class MeasurementUrlSettingInterpleter {
  static getLocationDescription(location: MeasurementUrlLocation): string {
    const compareTypeWord =
      LOCATION_COMPARE_TYPE_DISPLAY_WORD[location.pattern];
    return `「${location.value}」${compareTypeWord}`;
  }
  static getParameterDescription(
    parameters: MeasurementUrlQueryParameters
  ): string {
    const key = parameters.key ? `${parameters.key}=` : "";
    const condition = `${key}${parameters.value ? parameters.value : ""}`;
    const compareTypeWord =
      QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD[parameters.pattern];
    return `「${condition}」${compareTypeWord}`;
  }

  static getFullDescription(url: MeasurementUrl): string {
    const locationDescriptions: string[] = url.locations.map((loc) =>
      MeasurementUrlSettingInterpleter.getLocationDescription(loc)
    );
    const parameterDescriptions: string[] = url.parameters.map((p) =>
      MeasurementUrlSettingInterpleter.getParameterDescription(p)
    );

    const location = `URLに${locationDescriptions.join("かつ（and）")}`;
    const parameters = `パラメータに${parameterDescriptions.join(
      "かつ（and）"
    )}`;
    if (locationDescriptions.length > 0 && parameterDescriptions.length > 0) {
      return `${location}、${parameters}`;
    } else if (
      locationDescriptions.length > 0 &&
      parameterDescriptions.length === 0
    ) {
      return location;
    } else if (
      parameterDescriptions.length > 0 &&
      locationDescriptions.length === 0
    ) {
      return parameters;
    } else {
      return "";
    }
  }
}
