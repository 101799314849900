import { useMemo } from "react";
import useDraftCampaign from "./useDraftCampaign";
import useJobHistories from "./useJobHistories";
import RestApi from "utils/RestApi";
import { DraftCampaign, ScheduledCampaign } from "types/campaign";
import { JobHistory } from "types/job_history";
import useScheduledCampaign from "./useScheduledCampaign";

export type PushNotificationParameters = {
  scheduledCampaigns: ScheduledCampaign[] | null;
  draftCampaigns: DraftCampaign[] | null;
  jobHistories: JobHistory[] | null;
};

export type PushNotificationCallbacks = {
  loadScheduledCampaigns: () => void;
  loadDraftCampaigns: () => void;
  loadJobHistories: () => void;
};

const usePushNotificationParameters = (
  managementApi: RestApi
): [PushNotificationParameters, PushNotificationCallbacks] => {
  const [scheduledCampaigns, loadScheduledCampaigns] =
    useScheduledCampaign(managementApi);

  const [draftCampaigns, loadDraftCampaigns] = useDraftCampaign(managementApi);

  const [jobHistories, loadJobHistories] = useJobHistories(managementApi);

  const callbacks: PushNotificationCallbacks = useMemo(() => {
    return {
      loadScheduledCampaigns: loadScheduledCampaigns,
      loadDraftCampaigns: loadDraftCampaigns,
      loadJobHistories: loadJobHistories,
    };
  }, [loadScheduledCampaigns, loadDraftCampaigns, loadJobHistories]);

  return [{ scheduledCampaigns, draftCampaigns, jobHistories }, callbacks];
};

export default usePushNotificationParameters;
