import styles from "./UnavailableBoard.module.scss";
import WarningIcon from "images/icons/icon_warning.svg";

type UnavailableBoardProps = {
  text: JSX.Element;
};

const UnavailableBoard = ({ text }: UnavailableBoardProps) => {
  return (
    <div className={styles.unavailableBoard}>
      <div className={styles.icon}>
        <img src={WarningIcon} alt="" />
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default UnavailableBoard;
