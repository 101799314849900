import styles from "./GrantMessageListLayout.module.scss";

const GrantMessageSelectorLayout: React.VFC<{
  panelElements: JSX.Element[];
  buttonElement: JSX.Element;
}> = ({ panelElements, buttonElement }) => (
  <div className={styles.grantMessageListLayout}>
    <div className={styles.panels}>
      {panelElements.map((panelElement, index) => (
        <div key={index} className={styles.panelItem}>
          {panelElement}
        </div>
      ))}
    </div>
    <div>{buttonElement}</div>
  </div>
);

export default GrantMessageSelectorLayout;
