import styles from "./AuthPageTemplate.module.scss";

type AuthTemplateProps = {
  logoElement: JSX.Element;
  formElement: JSX.Element;
  footerElement: JSX.Element;
};

const AuthTemplate = ({
  logoElement,
  formElement,
  footerElement,
}: AuthTemplateProps) => {
  return (
    <div className={styles.authTemplate}>
      <div className={styles.main}>
        <div className={styles.logo}>{logoElement}</div>
        <div className={styles.form}>{formElement}</div>
      </div>
      <div className={styles.footer}>{footerElement}</div>
    </div>
  );
};

export default AuthTemplate;
