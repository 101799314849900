import { useState } from "react";
import { ColorResult } from "react-color";
import { hexToColor } from "utils/color";
import ColorTypeSelector, { ColorType, COLOR_TYPE } from "./ColorTypeSelector";
import styles from "./GradationColorPicker.module.scss";
import GradationPresetColorPicker from "./GradationPresetColorPicker";
import GradationSliderColorPicker from "./GradationSliderColorPicker";
import SimplePresetColorPicker from "./SimplePresetColorPicker";
import SimpleSliderColorPicker from "./SimpleSliderColorPicker";

export const PICKER_TYPE = {
  PRESET: "PRESET",
  SLIDER: "SLIDER",
};

export type PickerType = typeof PICKER_TYPE[keyof typeof PICKER_TYPE];

type CreatePickerElementProps = {
  pickerType: PickerType;
  colorType: ColorType;
  currentColorNumber: number;
  colors: ColorResult[];
  onChange: (color: ColorResult[]) => void;
  onChangeColorNumber: (value: number) => void;
  onChangePicker: (p: PickerType) => void;
};

const CreatePickerElement = ({
  pickerType,
  colorType,
  currentColorNumber,
  colors,
  onChange,
  onChangeColorNumber,
  onChangePicker,
}: CreatePickerElementProps): JSX.Element => {
  switch (pickerType) {
    case PICKER_TYPE.PRESET:
      switch (colorType) {
        case COLOR_TYPE.SIMPLE:
          return (
            <SimplePresetColorPicker
              color={colors[0]}
              onChange={(c) =>
                onChange(colors.map((e, i) => (i === 0 ? c : e)))
              }
              onChangePicker={() => onChangePicker(PICKER_TYPE.SLIDER)}
            />
          );

        case COLOR_TYPE.GRADATION:
          return (
            <GradationPresetColorPicker
              colors={colors}
              onChange={(c) => onChange(c)}
              onChangePicker={() => onChangePicker(PICKER_TYPE.SLIDER)}
            />
          );

        default:
          return <></>;
      }
    case PICKER_TYPE.SLIDER:
      switch (colorType) {
        case COLOR_TYPE.SIMPLE:
          return (
            <SimpleSliderColorPicker
              color={colors[0].hex}
              colorResult={colors[0]}
              onChange={(c) =>
                onChange(colors.map((e, i) => (i === 0 ? c : e)))
              }
              onChangePicker={() => onChangePicker(PICKER_TYPE.PRESET)}
            />
          );

        case COLOR_TYPE.GRADATION:
          return (
            <GradationSliderColorPicker
              color={colors[currentColorNumber].hex}
              colors={colors}
              currentColorNumber={currentColorNumber}
              onChange={(c) =>
                onChange(
                  colors.map((e, i) => (currentColorNumber === i ? c : e))
                )
              }
              onChangePicker={() => onChangePicker(PICKER_TYPE.PRESET)}
              onClickColorCircle={(e) => onChangeColorNumber(e)}
            />
          );
        default:
          return <></>;
      }
    default:
      return <></>;
  }
};

const getColorOpenerStyle = (
  colorType: ColorType,
  values: string[]
): React.CSSProperties => {
  if (colorType === COLOR_TYPE.GRADATION) {
    return {
      background: `linear-gradient(${values[0]},${values[1]})`,
    };
  }
  return {
    backgroundColor: values[0],
  };
};

type EZCXGradationColorPickerProps = {
  values: string[];
  colorType: ColorType;
  onChange: (values: string[]) => void;
  onChangeColorType: (values: ColorType) => void;
};

const EZCXGradationColorPicker = ({
  values,
  colorType,
  onChange,
  onChangeColorType,
}: EZCXGradationColorPickerProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [pickerType, setPickerType] = useState<PickerType>(PICKER_TYPE.PRESET);
  const [currentColorNumber, setCurrentColorNumber] = useState<number>(0);

  const openerStyle: React.CSSProperties = getColorOpenerStyle(
    colorType,
    values
  );

  return (
    <div className={styles.gradationColorPicker}>
      <div
        style={openerStyle}
        className={styles.opener}
        onClick={() => setIsActive(!isActive)}
      ></div>
      {isActive ? (
        <>
          <div className={styles.cover} onClick={() => setIsActive(false)} />
          <div className={styles.popover}>
            <div className={styles.selector}>
              <ColorTypeSelector
                colorType={colorType}
                setColorType={(e) => onChangeColorType(e)}
              />
            </div>
            <CreatePickerElement
              pickerType={pickerType}
              colorType={colorType}
              colors={values
                .map((e) => hexToColor(e))
                .filter((e): e is ColorResult => e !== undefined)}
              currentColorNumber={currentColorNumber}
              onChange={(c) => onChange(c.map((e) => e.hex))}
              onChangeColorNumber={(e) => setCurrentColorNumber(e)}
              onChangePicker={(p) => setPickerType(p)}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EZCXGradationColorPicker;
