import RestApi from "../RestApi";
import { InitialSettingDownloadRequest, InitialSettingDownloadResponse } from "types/initial_settings";

class InitialSettingRepository {
    managementApi: RestApi;

    constructor(managementApi: RestApi) {
        this.managementApi = managementApi;
    }

    async get(request: InitialSettingDownloadRequest): Promise<InitialSettingDownloadResponse> {
        const data = await this.managementApi.get(`initial-settings/${request.resourceType}`);
        return data;
    }
}

export default InitialSettingRepository;
