import SettingsEditPhaseFormMainBodyAreaView from "organisms/account/action_creation/settings_edit/SettingsEditPhaseFormMainBodyAreaView";

import {
  ContentsSettingPhaseCategory,
  CONTENTS_SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";
import {
  CreateABTestForm,
  CreateActionNameForm,
  CreateExecuteTimingForm,
  CreateFrequencyForm,
  CreateSegmentationRuleForm,
  CreateTerminateReactionForm,
  CreateToggledExpirationPeriodForm,
} from "../../CreateDefinedFormParts";
import { ActionCreationSettingsError } from "utils/action_creation/ActionCreationValidations";
import { CustomContentsFormParameters } from "interfaces/model/actionContentsParameters";
import {
  ActionExecutionConditions,
  ActionFrequency,
  ActionTerminateReaction,
  ExecuteTiming,
  ExpirationPeriod,
  SegmentationRule,
} from "interfaces/models";
import { ActionCreationSettingsExecutionConditions } from "app/hooks/actionCreationPage";
import NamedFormView from "organisms/account/action_creation/settings_edit/NamedFormView";
import ItemName from "molecules/ItemName";
import UrlMatchPatternForms from "molecules/SettingForm/UrlMatchPatternForms";
import ToggleButton from "atoms/accountPagesShared/ToggleButton";
import TextInput from "atoms/TextInput";

type CreateSettingsEditPhaseFormMainBodyAreaProps = {
  category: ContentsSettingPhaseCategory;
  initValue?: {
    executionConditions: ActionExecutionConditions;
  };
  params: {
    formPattern: CustomContentsFormParameters;
    name: string;
    expirationPeriod: ExpirationPeriod | null;
    segmentationRuleIds: string[];
    frequency: ActionFrequency;
    terminateReaction: ActionTerminateReaction;
    abRate: number;
    segmentationRuleList: SegmentationRule[];
    executionConditions: ActionCreationSettingsExecutionConditions;
    cvUrl: string;
  };
  callbacks: {
    setName: (value: string) => void;
    setExpirationPeriod: (value: ExpirationPeriod | null) => void;
    setFrequency: (value: ActionFrequency) => void;
    setTerminateReaction: (value: ActionTerminateReaction) => void;
    setABRate: (value: number) => void;
    setExpirationPeriodEnabled: (e: boolean) => void;
    setCvUrl: (value: string) => void;
    onChangeIncludes(p: ManagementApiData.UrlMatchPattern[]): void;
    onChangeExcludes(p: ManagementApiData.UrlMatchPattern[]): void;
    onChangeSegmentationRules(index: number, ruleId: string): void;
    onResetIncludes(): void;
    onResetExcludes(): void;
    onChangeExecuteTiming(
      isExecuteTimingsActive: boolean,
      executeTiming: ExecuteTiming[]
    ): void;
    onClickDeleteExecuteTiming(index: number): void;
    onClickInsertExecuteTiming(): void;
    onChangeSetExecuteTimingFromHourValue(index: number, value: number): void;
    onChangeSetExecuteTimingFromTimeValue(index: number, value: number): void;
    onChangeSetExecuteTimingToHourValue(index: number, value: number): void;
    onChangeSetExecuteTimingToTimeValue(index: number, value: number): void;
    onChangeSetExecuteTimingDayOfWeek(index: number, label: string): void;
    onChangeSetExecuteTimingHoliday(index: number): void;
    getExecuteTimingHolidayState(index: number): boolean;
    getExecuteTimingDayOfWeekState(index: number, value: string): boolean;
  };
  form: ActionCreation.FormState &
    ActionCreation.ExpirationPeriodFormState & {
      // FIXME: form state and change state callbacks should be managed in molecules
      onClickSegmentationRuleAddButton(): void;
      onClickSegmentationRuleDeleteButton(): void;
    };
  errors?: ActionCreationSettingsError;
};

const CreateSettingsEditPhaseFormMainBodyArea = ({
  category,
  initValue,
  params,
  callbacks,
  form,
}: CreateSettingsEditPhaseFormMainBodyAreaProps) => {
  const elements = [
    <CreateActionNameForm
      name={params.name}
      setName={(e) => callbacks.setName(e)}
    />,
    <CreateSegmentationRuleForm
      ruleIds={params.segmentationRuleIds}
      rules={params.segmentationRuleList}
      onClickAddButton={() => form.onClickSegmentationRuleAddButton()}
      onClickDeleteButton={() => form.onClickSegmentationRuleDeleteButton()}
      onChange={(index, ruleId) =>
        callbacks.onChangeSegmentationRules(index, ruleId)
      }
    />,
    <NamedFormView
      titleElement={
        <ItemName text="コンバージョンURL(部分一致で判定)" isRequired={true} />
      }
      formElement={
        <TextInput
          value={params.cvUrl}
          onChange={(e) => {
            if (e) {
              callbacks.setCvUrl(e.target.value.trim().replace(" ", ""));
            }
          }}
          placeholder="https://example.com"
        />
      }
    />,
    <CreateABTestForm
      abRate={params.abRate}
      setABRate={(e) => callbacks.setABRate(e)}
    />,
    <NamedFormView
      titleElement={
        <ItemName
          text="表示対象URL"
          toolTipId="include_url_condition"
          toolTipText="設定されていないとき、全ページが対象となります。"
        />
      }
      formElement={
        form.isIncludeUrlVisible ? (
          <UrlMatchPatternForms
            initValue={initValue?.executionConditions.url_condition.includes}
            onChange={callbacks.onChangeIncludes}
          />
        ) : undefined
      }
      toggleElement={
        <ToggleButton
          checked={form.isIncludeUrlVisible}
          onChange={(b) => {
            form.setIncludeUrlVisibility(b);
            if (!b) {
              callbacks.onResetIncludes();
            }
          }}
        />
      }
    />,
    <NamedFormView
      titleElement={
        <ItemName
          text="除外対象URL"
          toolTipId="exclude_url_condition"
          toolTipText="表示対象URLより、除外対象URLの設定が優先されます。"
        />
      }
      formElement={
        form.isExcludeUrlVisible ? (
          <UrlMatchPatternForms
            initValue={initValue?.executionConditions.url_condition.excludes}
            onChange={callbacks.onChangeExcludes}
          />
        ) : undefined
      }
      toggleElement={
        <ToggleButton
          checked={form.isExcludeUrlVisible}
          onChange={(b) => {
            form.setExcludeUrlVisibility(b);
            if (!b) {
              callbacks.onResetExcludes();
            }
          }}
        />
      }
    />,
    <CreateFrequencyForm
      frequency={params.frequency}
      setFrequency={callbacks.setFrequency}
    />,
    <CreateTerminateReactionForm
      terminateReaction={params.terminateReaction}
      setTerminateReaction={(e) => callbacks.setTerminateReaction(e)}
    />,
    <CreateExecuteTimingForm
      initValue={initValue?.executionConditions.execute_timing}
      executeTimings={params.executionConditions.executeTimings}
      onChangeExecuteTimings={callbacks.onChangeExecuteTiming}
      isExecuteTimingsActive={form.isExecuteTimingActive}
      setIsExecuteTimingsActive={form.setIsExecuteTimingActive}
      onClickInsertExecuteTiming={callbacks.onClickInsertExecuteTiming}
      onClickDeleteExecuteTiming={callbacks.onClickDeleteExecuteTiming}
      onChangeSetExecuteTimingFromHourValue={
        callbacks.onChangeSetExecuteTimingFromHourValue
      }
      onChangeSetExecuteTimingFromTimeValue={
        callbacks.onChangeSetExecuteTimingFromTimeValue
      }
      onChangeSetExecuteTimingToHourValue={
        callbacks.onChangeSetExecuteTimingToHourValue
      }
      onChangeSetExecuteTimingToTimeValue={
        callbacks.onChangeSetExecuteTimingToTimeValue
      }
      onChangeSetExecuteTimingDayOfWeek={
        callbacks.onChangeSetExecuteTimingDayOfWeek
      }
      onChangeSetExecuteTimingHoliday={
        callbacks.onChangeSetExecuteTimingHoliday
      }
      getExecuteTimingHolidayState={callbacks.getExecuteTimingHolidayState}
      getExecuteTimingDayOfWeekState={callbacks.getExecuteTimingDayOfWeekState}
    />,
    <CreateToggledExpirationPeriodForm
      isEnabled={form.isExpirationPeriodEnabled}
      expirationPeriod={params.expirationPeriod}
      setExpirationPeriod={(value) => callbacks.setExpirationPeriod(value)}
      onSetEnabled={(e) => {
        callbacks.setExpirationPeriodEnabled(e);
        form.setExpirationPeriodVisibility(e);
      }}
    />,
  ];

  switch (category) {
    case CONTENTS_SETTING_PHASE_CATEGORY.CONDITION:
      return <SettingsEditPhaseFormMainBodyAreaView elements={elements} />;
    default:
      // TODO: Move to mode select page
      return <>ERROR</>;
  }
};

export default CreateSettingsEditPhaseFormMainBodyArea;
