import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./ImageUploader.module.scss";
import imageIcon from "images/icons/icon_image.svg";

const ImageViewer = ({ image }: { image: File | undefined }) => {
  const imageUrl = image ? URL.createObjectURL(image) : "";

  return (
    <div className={styles.imageViewer}>
      <img className={styles.image} src={imageUrl} alt="" />
      <div className={styles.reuploadButton}>
        <div className={styles.text}>画像を差し替える</div>
      </div>
    </div>
  );
};

const InitialViewer = () => {
  return (
    <div className={styles.initialViewer}>
      <div className={styles.container}>
        <div className={styles.image}>
          <img className={styles.icon} src={imageIcon} alt="icon" />
        </div>
        <div className={styles.text}>ドラッグ＆ドロップで画像を挿入</div>
      </div>
    </div>
  );
};

type ImageUploaderProps = {
  image: File | undefined;
  onChange: (value: File | undefined) => void;
};

const ImageUploader = ({ image, onChange }: ImageUploaderProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles[0]);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: "image/*",
  });

  return (
    <div {...getRootProps()} className={styles.imageUploader}>
      <input {...getInputProps()} />
      {image ? <ImageViewer image={image} /> : <InitialViewer />}
    </div>
  );
};

export default ImageUploader;
