import { useCallback, useState } from "react";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

import styles from "./CopyableLink.module.scss";

import CopyNoticeButton from "atoms/ezPushShared/accountPageShared/CopyNoticeButton";
import { generateRandomId } from "utils/randomId/RandomId";

const CopyableLink = ({ link }: { link: string }) => {
  const [id] = useState<string>(generateRandomId());
  const onClick = useCallback(() => {
    navigator.clipboard.writeText(link);
  }, [link]);

  const formatter = new DescriptionFormatter();

  return (
    <div className={styles.copyableLink}>
      <a
        className={styles.text}
        target="_blank"
        rel="noopener noreferrer"
        href={link}
      >
        {formatter.substr(link)}
      </a>
      <div>
        <CopyNoticeButton id={id} onClick={onClick} />
      </div>
    </div>
  );
};

export default CopyableLink;
