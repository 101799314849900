import React, { useEffect, useMemo, useState } from "react";
import fontStyles from "fontStyles.module.scss";

import {
  MeasurementUrlType,
  MeasurementUrlSettingWithType,
} from "types/measurement_urls";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";
import { MeasurementUrlSettingInterpleter } from "utils/measurement_url_setting/MeasurementUrlSettingInterpleter";

import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import EditButton from "atoms/accountPagesShared/EditButton";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import ClickableAreaText from "atoms/ezPushShared/accountPageShared/ClickableAreaText";
import OrangeHeaderTable from "atoms/ezPushShared/accountPageShared/OrangeHeaderTable";
import Label from "molecules/v2/Label";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import LoadingIcon from "atoms/LoadingIcon";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import MeasurementUrlSettingTable from "../segmentationRule/editor/MeasurementUrlSettingTable";
import { MEASUREMENT_URL_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";
import { MEASUREMENT_URL_SETTING_TYPE } from "app/system_defaults/v2_routing";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import GrayBackDescriptionList from "molecules/v2/GrayBackDescriptionList";
import { MEASUREMENT_URL_SETTING_TYPE_RULE } from "app/system_defaults/validateRule";
import TooltipIcon from "atoms/ezPushShared/accountPageShared/TooltipIcon";
import GrayOutButton from "atoms/ezPushShared/accountPageShared/GrayOutButton";
import { useAnchor } from "app/hooks/v2/useAnchor";
import { MEASUREMENT_URL_SETTING_DESCRIPTION } from "app/system_defaults/descriptionTexts";

const MeaurementUrlSettingDetailModal: React.VFC<{
  urlSetting: MeasurementUrlSettingWithType;
  clear: () => void;
}> = ({ urlSetting, clear }) => (
  <ModalBackground>
    <ElementModal
      title="ページ詳細"
      element={<MeasurementUrlSettingTable setting={urlSetting} />}
      buttons={[<TransparentButton text="閉じる" onClick={clear} />]}
    />
  </ModalBackground>
);

const MeaurementUrlSettingDeleteModal: React.VFC<{
  clear: () => void;
  deleteSetting: () => void;
}> = ({ clear, deleteSetting }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const buttons = useMemo(() => {
    if (isProcessing) {
      return [<LoadingIcon />];
    }
    return [
      <TransparentButton text="いいえ" onClick={() => clear()} />,
      <OrangeButton
        text={"はい"}
        onClick={() => {
          setIsProcessing(true);
          deleteSetting();
        }}
      />,
    ];
  }, [clear, deleteSetting, isProcessing, setIsProcessing]);

  return (
    <ModalBackground>
      <NotificationModal
        title="このページを削除します。よろしいですか"
        buttons={buttons}
      />
    </ModalBackground>
  );
};

const MeaurementUrlSettingDeleteDenyModal: React.VFC<{
  names: string[];
  clear: () => void;
}> = ({ names, clear }) => (
  <ModalBackground>
    <NotificationModal
      title="このページ設定はセグメント設定に紐づいているため削除できません。"
      buttons={[<TransparentButton text="閉じる" onClick={clear} />]}
      description={
        <GrayBackDescriptionList
          title={"対象のセグメント"}
          description={""}
          items={names}
        />
      }
    />
  </ModalBackground>
);

const MeaurementUrlSettingTableTitle: React.VFC<{
  setting: MeasurementUrlSettingWithType;
  setModalContents: (contents: JSX.Element | undefined) => void;
}> = ({ setting, setModalContents }) => {
  return (
    <ClickableAreaText
      text={setting.url.name}
      onClick={() =>
        setModalContents(
          <MeaurementUrlSettingDetailModal
            urlSetting={setting}
            clear={() => setModalContents(undefined)}
          />
        )
      }
    />
  );
};

const MeaurementUrlSettingTableOperation: React.VFC<{
  setting: MeasurementUrlSettingWithType;
  setModalContents: (contents: JSX.Element | undefined) => void;
  updateSetting: (urlUuid: string) => void;
  deleteSetting: (urlUuid: string) => Promise<void>;
}> = ({ setting, setModalContents, updateSetting, deleteSetting }) => (
  <HorizontalIconLayout
    margin={15}
    elements={[
      <EditButton onClick={() => updateSetting(setting.url.url_uuid)} />,
      <DeleteButton
        onClick={() => {
          setModalContents(
            setting.related_rules.length > 0 ? (
              <MeaurementUrlSettingDeleteDenyModal
                names={setting.related_rules}
                clear={() => setModalContents(undefined)}
              />
            ) : (
              <MeaurementUrlSettingDeleteModal
                clear={() => setModalContents(undefined)}
                deleteSetting={() =>
                  deleteSetting(setting.url.url_uuid).then(() => {
                    setModalContents(undefined);
                  })
                }
              />
            )
          );
        }}
      />,
    ]}
  />
);

const UrlSettingConfigurationTable: React.VFC<{
  settings: MeasurementUrlSettingWithType[] | null;
  updateSetting: (urlUuid: string) => void;
  deleteSetting: (urlUuid: string) => Promise<void>;
  setModalContents: (contents: JSX.Element | undefined) => void;
}> = ({ settings, updateSetting, deleteSetting, setModalContents }) => {
  const formatter = new DescriptionFormatter();

  const records = settings
    ? settings.map((s) => {
        return {
          cells: [
            {
              item: (
                <MeaurementUrlSettingTableTitle
                  setting={s}
                  setModalContents={setModalContents}
                />
              ),
              width: 400,
            },
            {
              item: formatter.substr(
                MeasurementUrlSettingInterpleter.getFullDescription(s.url.url)
              ),
            },
            {
              item: (
                <MeaurementUrlSettingTableOperation
                  setting={s}
                  setModalContents={setModalContents}
                  updateSetting={updateSetting}
                  deleteSetting={deleteSetting}
                />
              ),
              width: 70,
            },
          ],
        };
      })
    : null;
  return (
    <OrangeHeaderTable
      headers={["管理名", "設定内容", "操作"]}
      records={records}
      blankMessage={"ページが登録されていません"}
    />
  );
};

const MeasurementUrlSettingTitleElement: React.VFC<{
  settings: MeasurementUrlSettingWithType[] | null;
  measurementUrlType: MeasurementUrlType;
  isRequired?: boolean;
  createNewSetting: (measurementUrlType: MeasurementUrlType) => void;
}> = ({
  settings,
  measurementUrlType,
  isRequired,
  createNewSetting,
}): JSX.Element => {
  return (
    <div id={measurementUrlType}>
      <VerticalSpreadLayout
        items={[
          <HorizontalLayout
            elements={[
              <Label
                label={
                  <span className={fontStyles.section}>
                    {MEASUREMENT_URL_TYPE_DISPLAY_WORD[measurementUrlType]}
                    一覧
                  </span>
                }
                isRequired={isRequired}
              />,
              settings &&
              settings.length <
                MEASUREMENT_URL_SETTING_TYPE_RULE[measurementUrlType]
                  .ITEM_LIMIT ? (
                <OrangeButton
                  text="新規追加"
                  onClick={() => createNewSetting(measurementUrlType)}
                />
              ) : (
                <TooltipIcon
                  iconElement={
                    <GrayOutButton text={"新規追加"} isActive={false} />
                  }
                  comment={"登録上限に達しているため新規登録できません。"}
                />
              ),
              <div className={fontStyles.boldText}>
                {settings ? (
                  `(${settings.length}/${MEASUREMENT_URL_SETTING_TYPE_RULE[measurementUrlType].ITEM_LIMIT})`
                ) : (
                  <LoadingIcon />
                )}
              </div>,
            ]}
          />,
          <MultipleLineText
            texts={MEASUREMENT_URL_SETTING_DESCRIPTION[measurementUrlType].map(
              (description) => (
                <span
                  className={fontStyles.note}
                  style={{
                    color: "#666666",
                  }}
                >
                  {description}
                </span>
              )
            )}
          />,
        ]}
      />
    </div>
  );
};

const UrlSettingConfiguration: React.VFC<{
  subscriptionUrlSetting: MeasurementUrlSettingWithType[] | null;
  segmentationUrlSetting: MeasurementUrlSettingWithType[] | null;
  conversionUrlSetting: MeasurementUrlSettingWithType[] | null;
  isLoaded: boolean;
  createNewSetting: (measurementUrlType: MeasurementUrlType) => void;
  updateSetting: (
    measurementUrlType: MeasurementUrlType,
    urlUuid: string
  ) => void;
  deleteSetting: (urlUuid: string) => Promise<void>;
  setModalContents: (contents: JSX.Element | undefined) => void;
}> = ({
  subscriptionUrlSetting,
  segmentationUrlSetting,
  conversionUrlSetting,
  isLoaded,
  createNewSetting,
  updateSetting,
  deleteSetting,
  setModalContents,
}) => {
  const { scrollToAnchor } = useAnchor();
  const params = useMemo(
    () => [
      {
        settings: subscriptionUrlSetting,
        type: MEASUREMENT_URL_SETTING_TYPE.SUBSCRIPTION,
        isRequired: true,
      },
      {
        settings: conversionUrlSetting,
        type: MEASUREMENT_URL_SETTING_TYPE.CONVERSION,
        isRequired: false,
      },
      {
        settings: segmentationUrlSetting,
        type: MEASUREMENT_URL_SETTING_TYPE.SEGMENTATION,
        isRequired: false,
      },
    ],
    [subscriptionUrlSetting, segmentationUrlSetting, conversionUrlSetting]
  );

  useEffect(() => {
    if (isLoaded) {
      scrollToAnchor();
    }
  }, [isLoaded, scrollToAnchor]);

  return (
    <VerticalSpreadLayout
      items={params.map((param) => (
        <VerticalSpreadLayout
          margin={5}
          items={[
            <MeasurementUrlSettingTitleElement
              settings={param.settings}
              measurementUrlType={param.type}
              isRequired={param.isRequired}
              createNewSetting={createNewSetting}
            />,
            <UrlSettingConfigurationTable
              settings={param.settings}
              updateSetting={(urlUuid) => updateSetting(param.type, urlUuid)}
              deleteSetting={deleteSetting}
              setModalContents={setModalContents}
            />,
          ]}
        />
      ))}
      margin={20}
    />
  );
};

export default UrlSettingConfiguration;
