import styles from "./HTMLEditTextarea.module.scss";

type HTMLEditTextareaProps = {
  html: string;
  onChange: (html: string) => void;
};

const HTMLEditTextarea = ({ html, onChange }: HTMLEditTextareaProps) => {
  return (
    <textarea
      className={styles.htmlEditTextarea}
      value={html}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default HTMLEditTextarea;
