import React from "react";
import styles from "./OrangeButton.module.scss";
import fontStyles from "fontStyles.module.scss";

const OrangeButton: React.VFC<{
  text: string | JSX.Element;
  onClick: () => void;
}> = ({ text, onClick }): JSX.Element => {
  return (
    <button type="button" className={styles.orangeButton} onClick={onClick}>
      <div className={fontStyles.text}>{text}</div>
    </button>
  );
};

export default OrangeButton;
