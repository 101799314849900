import { EZCX_NAME } from "app/system_defaults/ezcx";
import { V2_SERVICE_NAME } from "app/system_defaults/v2_service";

export class EzcxHeadInitializer {
  updateFavicon(document: Document) {
    const favicon: HTMLLinkElement | null = document.getElementById(
      "favicon"
    ) as HTMLLinkElement | null;

    if (favicon) {
      favicon.href = "/favicon.ico";
    }
  }

  setServiceName(document: Document) {
    document.title = EZCX_NAME;
  }

  run() {
    const doc: Document = document;
    if (doc) {
      this.updateFavicon(doc);
      this.setServiceName(doc);
    }
  }
}

export class V2HeadInitializer {
  updateFavicon(document: Document) {
    const favicon: HTMLLinkElement | null = document.getElementById(
      "favicon"
    ) as HTMLLinkElement | null;

    if (favicon) {
      favicon.href = "/favicon-v2.ico";
    }
  }

  setServiceName(document: Document) {
    document.title = V2_SERVICE_NAME;
  }

  run() {
    const doc: Document = document;
    if (doc) {
      this.updateFavicon(doc);
      this.setServiceName(doc);
    }
  }
}
