import { useHistory, useLocation } from "react-router-dom";
import styles from "./SideBarV2.module.scss";

export type LinkParameters = {
  label: string;
  link: string;
};

const SideBarTab = ({
  label,
  isActive,
  onClick,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={isActive ? styles.sideBarTab__active : styles.sideBarTab}
      onClick={onClick}
    >
      <div className={styles.text}>{label}</div>
    </div>
  );
};

const SideBarV2: React.VFC<{ params: LinkParameters[] }> = ({ params }) => {
  const activePage = useLocation().pathname;
  const history = useHistory();

  return (
    <div className={styles.SideBar}>
      {params.map((item, index) => (
        <div key={index} className={styles.tab}>
          <SideBarTab
            label={item.label}
            onClick={() => history.push(item.link)}
            isActive={activePage.includes(item.link)}
          />
        </div>
      ))}
    </div>
  );
};

export default SideBarV2;
