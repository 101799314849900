import { Condition } from "interfaces/models";

import {
  getDescription,
  getSegmentationRuleLocations,
  getSegmentationRuleUrlParameters,
} from "utils/SegmentationRuleSentence";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

interface DescriptionsProps {
  conditions: Condition[];
}

const Descriptions = ({ conditions }: DescriptionsProps): JSX.Element => {
  let urlDescriptions = [];
  let preview_byte = 80;
  let preview_descriptions = [];

  if (conditions.length > 0) {
    const locations: string[] | null = getSegmentationRuleLocations(conditions);
    const parameters: string[] | null =
      getSegmentationRuleUrlParameters(conditions);

    if (locations) {
      urlDescriptions.push(`対象ページ：${locations.join("、")}ページ`);
    }

    if (parameters) {
      urlDescriptions.push(`URLパラメータに${parameters.join("、")}`);
    }
  }

  let descriptions: string[] = [];

  try {
    descriptions = conditions.map((c) => getDescription(c));
  } catch (error) {
    console.error(error);
    descriptions = ["読み込みエラー"];
  }

  if (descriptions.length) {
    preview_descriptions = urlDescriptions.concat(descriptions);
  } else {
    preview_descriptions = ["全ユーザー"];
  }

  let preview_description = preview_descriptions.join();

  return (
    <div>
      {new DescriptionFormatter().substr(preview_description, preview_byte)}
    </div>
  );
};

export default Descriptions;
