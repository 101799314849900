import {
  PUSH_NOTIFICATION_GENRE_TYPE,
  V2_ROUTING_PATH_PARAMETERS,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";

export class PushNotificationRouter {
  backToScheduledCampaign() {
    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME}`;
  }

  backToDraftCampaign() {
    const genreQuery = `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.HOME.GENRE}=${PUSH_NOTIFICATION_GENRE_TYPE.DRAFT}`;
    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME}?${genreQuery}`;
  }

  backToDeliveredCampaign() {
    const genreQuery = `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.HOME.GENRE}=${PUSH_NOTIFICATION_GENRE_TYPE.DELIVERED}`;
    return `${V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME}?${genreQuery}`;
  }
}
