import {
  GrantMessageContentsButtonDesignCallbacks,
  GrantMessageContentsButtonDesignParameters,
  useGrantMessageContentsButtonDesign,
} from "./design/useGrantMessageContentsButtonDesign";
import {
  GrantMessageContentsCloseButtonDesignCallbacks,
  GrantMessageContentsCloseButtonDesignParameters,
  useGrantMessageContentsCloseButtonDesign,
} from "./design/useGrantMessageContentsCloseButtonDesign";
import {
  GrantMessageContentsDescriptionDesignCallbacks,
  GrantMessageContentsDescriptionDesignParameters,
  useGrantMessageContentsDescriptionDesign,
} from "./design/useGrantMessageContentsDescriptionDesign";
import {
  GrantMessageContentsFrameDesignParameters,
  GrantMessageContentsFrameDesignCallbacks,
  useGrantMessageContentsFrameDesign,
} from "./design/useGrantMessageContentsFrameDesign";

import {
  GrantMessageContentsImageDesignCallbacks,
  GrantMessageContentsImageDesignParameters,
  useGrantMessageContentsImageDesign,
} from "./design/useGrantMessageContentsImageDesign";

import {
  GrantMessageContentsTitleDesignCallbacks,
  GrantMessageContentsTitleDesignParameters,
  useGrantMessageContentsTitleDesign,
} from "./design/useGrantMessageContentsTitleDesign";

export type GrantMessageContentsDesignParameters = {
  frame: GrantMessageContentsFrameDesignParameters;
  image: GrantMessageContentsImageDesignParameters;
  title: GrantMessageContentsTitleDesignParameters;
  description: GrantMessageContentsDescriptionDesignParameters;
  button: GrantMessageContentsButtonDesignParameters;
  closeButton: GrantMessageContentsCloseButtonDesignParameters;
};

export type GrantMessageContentsDesignCallbacks = {
  frame: GrantMessageContentsFrameDesignCallbacks;
  image: GrantMessageContentsImageDesignCallbacks;
  title: GrantMessageContentsTitleDesignCallbacks;
  description: GrantMessageContentsDescriptionDesignCallbacks;
  button: GrantMessageContentsButtonDesignCallbacks;
  closeButton: GrantMessageContentsCloseButtonDesignCallbacks;
};

export const useGrantMessageContentsDesign = (
  currentParameters: GrantMessageContentsDesignParameters
): [
  GrantMessageContentsDesignParameters,
  GrantMessageContentsDesignCallbacks
] => {
  const [frame, frameCallbacks] = useGrantMessageContentsFrameDesign(
    currentParameters.frame
  );

  const [image, imageCallbacks] = useGrantMessageContentsImageDesign(
    currentParameters.image
  );

  const [title, titleCallbacks] = useGrantMessageContentsTitleDesign(
    currentParameters.title
  );

  const [description, descriptionCallbacks] =
    useGrantMessageContentsDescriptionDesign(currentParameters.description);

  const [button, buttonCallbacks] = useGrantMessageContentsButtonDesign(
    currentParameters.button
  );

  const [closeButton, closeButtonCallbacks] =
    useGrantMessageContentsCloseButtonDesign(currentParameters.closeButton);

  return [
    {
      frame: frame,
      image: image,
      title: title,
      description: description,
      button: button,
      closeButton: closeButton,
    },
    {
      frame: frameCallbacks,
      image: imageCallbacks,
      title: titleCallbacks,
      description: descriptionCallbacks,
      button: buttonCallbacks,
      closeButton: closeButtonCallbacks,
    },
  ];
};
