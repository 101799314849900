import { UrlPattern } from "interfaces/models";

export const UrlPatternCodec = {
  decode: (urlPattern: UrlPattern): ManagementApiData.UrlMatchPattern => {
    return {
      locations: urlPattern.location,
      parameters: urlPattern.parameters,
    };
  },
  encode: (value: ManagementApiData.UrlMatchPattern): UrlPattern => {
    return {
      location: value.locations,
      parameters: value.parameters,
    };
  },
};
