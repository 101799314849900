import { useHistory } from "react-router-dom";
import { PushNotificationEditFormParameters } from "./usePushNotificationEditForm";
import CampaignRepository from "utils/repositories/CampaignRepository";
import RestApi from "utils/RestApi";
import { useCallback, useMemo, useState } from "react";
import { ApiRequestBuilder } from "app/process/pushNotification/edit/apiRequestBuilder";
import {
  PUSH_NOTIFICATION_EDITOR_MODE,
  PushNotificationEditorMode,
} from "app/system_defaults/v2_routing";
import { PushNotificationFormValidator } from "app/process/pushNotification/edit/pushNotificationFormValidator";
import ErrorMessage from "utils/ErrorMessage";
import { PushNotificationEditSearchParameters } from "./usePushNotificationEditPageSearchParameters";
import { PushNotificationRouter } from "app/process/pushNotification/home/pushNotificationRouter";
import useTestNotification, {
  TestNotificationParameters,
} from "./useTestNotification";
import { DraftCampaign } from "types/campaign";
import { DeliveryScheduleRule } from "types/delivery_schedule";

export type PushNotificationEditPageEventParameters = {
  editMode: PushNotificationEditorMode;
  errorMessages: ErrorMessage[];
  testNotificationParameters: TestNotificationParameters;
};

export type PushNotificationEditPageEvents = {
  cancel: () => void;
  getRequestDeliveryScheduleRule: () => DeliveryScheduleRule | null;
  getCampaignNextAt(): Promise<number | null>;
  canSave: (isActive: boolean, nextAt: number | null) => Promise<boolean>;
  save: (isActive: boolean) => void;
  testNotification: () => void;
};

const usePushNotificationEditPageEvents = (
  managementApi: RestApi,
  searchParameters: PushNotificationEditSearchParameters,
  parameters: PushNotificationEditFormParameters,
  updateEditResource: (campaign: DraftCampaign) => void
): [
  PushNotificationEditPageEventParameters,
  PushNotificationEditPageEvents
] => {
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [testNotificationParameters, testNotificationCallbacks] =
    useTestNotification(managementApi);
  // NOTE: テスト配信をすることによって、新規作成→編集状態へ状態遷移する。
  // なので、search parametersを直接参照せず、mode変数を立てる
  const [editMode, setEditMode] = useState<PushNotificationEditorMode>(
    searchParameters.mode
  );
  const [campaignUuid, setCampaignUuid] = useState<string | null>(
    searchParameters.campaignUuid
  );

  const getCampaignNextAt = useCallback(async () => {
    const apiRequestBuilder = new ApiRequestBuilder();
    const result = apiRequestBuilder.parseDeliverySchedule(
      parameters.deliverySchedule
    );

    const campaignRepository = new CampaignRepository(managementApi);

    const nextAt = await campaignRepository.getNextAt({ rule: result });

    return nextAt;
  }, [managementApi, parameters.deliverySchedule]);

  const canSaveCampaign = useCallback(
    async (isActive: boolean, nextAt: number | null) => {
      const validator = new PushNotificationFormValidator();
      const campaignRepository = new CampaignRepository(managementApi);

      const scheduledCampaigns = (await campaignRepository.loadScheduled())
        .items;

      const errors: ErrorMessage[] = isActive
        ? validator.validateToBeDelivered(
            parameters,
            nextAt,
            scheduledCampaigns
          )
        : validator.validateToBeDraft(parameters);

      if (errors.length === 0) {
        setErrorMessages([]);
        return true;
      } else {
        setErrorMessages(errors);
        window.scroll({ top: 0, behavior: "smooth" });
        return false;
      }
    },
    [parameters, managementApi]
  );

  const canTestSend = useCallback(async () => {
    const validator = new PushNotificationFormValidator();

    const errors: ErrorMessage[] = validator.validateToBeTest(parameters);

    if (errors.length === 0) {
      setErrorMessages([]);
      return true;
    } else {
      setErrorMessages(errors);
      window.scroll({ top: 0, behavior: "smooth" });
      return false;
    }
  }, [parameters]);

  const saveCampaign = useCallback(
    async (isActive: boolean, callback: (campaign: DraftCampaign) => void) => {
      const apiRequestBuilder = new ApiRequestBuilder();
      if (editMode === PUSH_NOTIFICATION_EDITOR_MODE.EDIT && campaignUuid) {
        apiRequestBuilder
          .getUpdateCampaignRequest(campaignUuid, parameters, isActive)
          .then((request) => {
            new CampaignRepository(managementApi)
              .update(request)
              .then((response) => {
                callback(response.result);
              });
          });
      } else {
        apiRequestBuilder
          .getCreateCampaignRequest(parameters, isActive)
          .then((request) => {
            new CampaignRepository(managementApi)
              .createV2(request)
              .then((response) => {
                callback(response.result);
              });
          });
      }
    },
    [managementApi, parameters, campaignUuid, editMode]
  );

  const getRequestDeliveryScheduleRule = useCallback(() => {
    const apiRequestBuilder = new ApiRequestBuilder();
    return apiRequestBuilder.parseDeliverySchedule(parameters.deliverySchedule);
  }, [parameters]);

  const events: PushNotificationEditPageEvents = useMemo(() => {
    return {
      cancel: () => {
        history.push(new PushNotificationRouter().backToScheduledCampaign());
      },
      getRequestDeliveryScheduleRule: getRequestDeliveryScheduleRule,
      getCampaignNextAt: getCampaignNextAt,
      canSave: (isActive: boolean, nextAt: number | null) =>
        canSaveCampaign(isActive, nextAt),
      save: (isActive: boolean) => {
        saveCampaign(isActive, () => {
          setErrorMessages([]);

          const router = new PushNotificationRouter();
          history.push(
            isActive
              ? router.backToScheduledCampaign()
              : router.backToDraftCampaign()
          );
        });
      },
      testNotification: () =>
        canTestSend().then((canSave) => {
          if (canSave) {
            saveCampaign(false, (campaign: DraftCampaign) => {
              setEditMode(PUSH_NOTIFICATION_EDITOR_MODE.EDIT);
              setCampaignUuid(campaign.campaign_uuid);
              updateEditResource(campaign);
              testNotificationCallbacks.execute(campaign.campaign_uuid);
            });
          }
        }),
    };
  }, [
    history,
    testNotificationCallbacks,
    getCampaignNextAt,
    saveCampaign,
    updateEditResource,
    canSaveCampaign,
    getRequestDeliveryScheduleRule,
    canTestSend,
  ]);

  return [
    {
      editMode,
      errorMessages,
      testNotificationParameters,
    },
    events,
  ];
};

export default usePushNotificationEditPageEvents;
