import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import LinkText from "atoms/LinkText";
import LogOutButton from "atoms/accountPagesShared/LogOutButton";
import Footer from "organisms/account/Footer";
import { ClientPageTemplateV2 } from "templates/ezPush/ClientPageTemplateV2";
import AppHeaderV2 from "templates/ezPush/AppHeaderV2";
import V2Logo from "atoms/ezPushShared/V2Logo";
import LoadingPageV2 from "./LoadingPageV2";
import SideBarV2, { LinkParameters } from "organisms/ezPush/SideBarV2";
import { Permission } from "interfaces/models";
import InitializePageTemplate from "templates/ezPush/InitializePageTemplate";
import { useMemo } from "react";

const SIDEBAR_LINKS: LinkParameters[] = [
  { label: "HOME", link: V2_ROUTING_TABLE.ACCOUNT.HOME },
  {
    label: "レポートダウンロード",
    link: V2_ROUTING_TABLE.ACCOUNT.REPORT.HOME,
  },
  {
    label: "プッシュ通知",
    link: V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME,
  },
  {
    label: "セグメント設定",
    link: V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.HOME,
  },
  {
    label: "基本設定",
    link: V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.ROOT,
  },
];

const ADMIN_SIDEBAR_LINKS: LinkParameters[] = [
  ...SIDEBAR_LINKS,
  {
    label: "管理者レポートダウンロード",
    link: V2_ROUTING_TABLE.ACCOUNT.ADMIN_DELIVERY_REPORT.HOME,
  },
];

const FOOTER_LINKS: JSX.Element[] = [
  <div>Copyright © YRGLM Inc. All Rights Reserved.</div>,
  <LinkText
    text="プライバシーポリシー"
    link="https://www.yrglm.co.jp/policy/"
    external={true}
  />,
  <LinkText
    text="利用契約約款"
    link="https://www.ez-cx.com/tp/terms/"
    external={true}
  />,
];

const isRenderAccountPage = (context: AccountPageContextV2): boolean => {
  if (context.account) {
    return true;
  }

  return false;
};

const AccountPageBase: React.VFC<{
  context: AccountPageContextV2;
  areaContents: JSX.Element;
  modalContents?: JSX.Element | undefined;
  isSideBar?: boolean;
}> = ({ context, areaContents, modalContents, isSideBar = true }) => {
  const headerElement = useMemo(
    () => (
      <AppHeaderV2
        logo={<V2Logo width={88} />}
        accountNameElement={
          <div>
            {context.account
              ? `${context.account.account_name} 様`
              : "Loading ..."}
          </div>
        }
        signOutButtonElement={<LogOutButton onSignOut={context.onSignOut} />}
      />
    ),
    [context.account, context.onSignOut]
  );
  const sideBarElement = useMemo(
    () => (
      <SideBarV2
        params={
          context.permission === Permission.SYSTEM
            ? ADMIN_SIDEBAR_LINKS
            : SIDEBAR_LINKS
        }
      />
    ),
    [context.permission]
  );

  const footerElement = useMemo(() => <Footer elements={FOOTER_LINKS} />, []);

  if (isRenderAccountPage(context)) {
    if (isSideBar) {
      return (
        <ClientPageTemplateV2
          headerElement={headerElement}
          sideBarElement={sideBarElement}
          areaContents={areaContents}
          footerElement={footerElement}
          modalElement={modalContents}
        />
      );
    } else {
      return (
        <InitializePageTemplate
          headerElement={headerElement}
          areaContents={areaContents}
          footerElement={footerElement}
          modalElement={modalContents}
        />
      );
    }
  } else {
    return <LoadingPageV2 loginAccountId={context.loginAccountId} />;
  }
};

export default AccountPageBase;
