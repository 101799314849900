import { useCallback, useEffect, useState } from "react";
import {
  GrantMessageV2,
  GrantMessageUpdateRequestV2,
} from "types/grant_message";
import GrantMessageV2Repository from "utils/repositories/GrantMessageV2Repository";
import RestApi from "utils/RestApi";

export type useGrantMessageV2Callbacks = {
  load: () => void;
  update: (request: GrantMessageUpdateRequestV2) => Promise<void>;
};

const useGrantMessageV2 = (
  managementApi: RestApi
): [GrantMessageV2[] | undefined, useGrantMessageV2Callbacks] => {
  const [grantMessage, setGrantMessage] = useState<
    GrantMessageV2[] | undefined
  >(undefined);

  const loadGrantMessage = useCallback(async () => {
    setGrantMessage(undefined);
    const grantMessageRepository = new GrantMessageV2Repository(managementApi);
    const response = await grantMessageRepository.load();
    setGrantMessage(response.messages);
  }, [managementApi]);

  const updateGrantMessage = useCallback(
    async (request: GrantMessageUpdateRequestV2) => {
      setGrantMessage(undefined);
      const grantMessageRepository = new GrantMessageV2Repository(
        managementApi
      );
      grantMessageRepository.update(request);
      loadGrantMessage();
    },
    [managementApi, setGrantMessage, loadGrantMessage]
  );

  useEffect(() => {
    loadGrantMessage();
  }, [loadGrantMessage]);

  return [
    grantMessage,
    {
      load: loadGrantMessage,
      update: updateGrantMessage,
    },
  ];
};

export default useGrantMessageV2;
