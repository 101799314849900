import { LocationPatternLabels } from "interfaces/models";
import { notifyNever } from "utils/assertions";

const wrapMutablePhrase = (v: string) => `[${v}]`;

const encodeLocationMatchMode = (
  pattern: ManagementApiData.LocationMatchMode
): string => {
  const matchLabel = LocationPatternLabels[pattern];
  return matchLabel;
};

const encodeParameterMatchMode = (
  pattern: ManagementApiData.ParameterMatchMode
): string => {
  switch (pattern) {
    case "include": {
      return `を含む`;
    }
    case "exclude": {
      return `を除く`;
    }
    default: {
      notifyNever(pattern);
      return "？？？";
    }
  }
};

const encodeLocationMatchPattern = (
  l: ManagementApiData.LocationMatchPattern
) =>
  wrapMutablePhrase(l.value) +
  wrapMutablePhrase(encodeLocationMatchMode(l.pattern));

const encodeLocationMatchPatterns = (
  location: ManagementApiData.LocationMatchPattern[]
) => {
  if (!location.length) {
    return null;
  }

  return `URLが${location.map(encodeLocationMatchPattern).join("かつ")}値`;
};

const encodeParameter = (key: string, value?: string) =>
  value ? `${key}=${value}` : key;

const encodeParameterMatchPattern = (
  p: ManagementApiData.ParameterMatchPattern
) =>
  wrapMutablePhrase(encodeParameter(p.key, p.value)) +
  wrapMutablePhrase(encodeParameterMatchMode(p.pattern));

const encodeParameterMatchPatterns = (
  parameters: ManagementApiData.ParameterMatchPattern[]
) => {
  if (!parameters.length) {
    return null;
  }

  return `パラメータが${parameters
    .map(encodeParameterMatchPattern)
    .join("かつ")}`;
};

const UrlParameterMatchPatternText: React.VFC<{
  patterns: ManagementApiData.UrlMatchPattern[];
  noSpecifiedText: string;
}> = ({ patterns, noSpecifiedText }) => {
  const encodeUrl = (url: ManagementApiData.UrlMatchPattern): string | null => {
    const urlText = encodeLocationMatchPatterns(
      url.locations.filter((l) => l.value !== "*")
    );

    const parameterText = encodeParameterMatchPatterns(url.parameters);

    const activeWords = [urlText, parameterText].filter((v) => v);

    return activeWords.length ? activeWords.join("で") : null;
  };

  const activeWords = patterns.map((url) => encodeUrl(url)).filter((v) => v);

  return (
    <div>{activeWords.length ? activeWords.join(", ") : noSpecifiedText}</div>
  );
};

export default UrlParameterMatchPatternText;
