import {
  ChangeCampaignStatusRequest,
  ChangeCampaignStatusResponse,
  CreateCampaignRequestV2,
  CreateCampaignResponse,
  DeleteCampaignRequest,
  DeleteCampaignResponse,
  GetCampaignNextAtRequest,
  GetCampaignNextAtResponse,
  GetCampaignRequest,
  GetCampaignResponse,
  LoadDraftCampaignResponse,
  LoadScheduledCampaignResponse,
  UpdateCampaignRequest,
  UpdateCampaignResponse,
} from "types/campaign";
import RestApi from "utils/RestApi";

class CampaignRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async loadDraft(): Promise<LoadDraftCampaignResponse> {
    const data: LoadDraftCampaignResponse = await this.managementApi.get(
      "campaigns/draft"
    );
    return data;
  }

  async loadScheduled(): Promise<LoadScheduledCampaignResponse> {
    const data: LoadScheduledCampaignResponse = await this.managementApi.get(
      "campaigns/scheduled"
    );
    return data;
  }

  async createV2(
    request: CreateCampaignRequestV2
  ): Promise<CreateCampaignResponse> {
    const data: CreateCampaignResponse = await this.managementApi.post(
      "campaigns/v2",
      request
    );
    return data;
  }

  async delete(
    request: DeleteCampaignRequest
  ): Promise<DeleteCampaignResponse> {
    const data: DeleteCampaignResponse = await this.managementApi.delete(
      `campaigns/${request.campaign_uuid}`
    );
    return data;
  }

  async changeStatus(
    request: ChangeCampaignStatusRequest
  ): Promise<ChangeCampaignStatusResponse> {
    const data: ChangeCampaignStatusResponse = await this.managementApi.patch(
      `campaigns/status`,
      request
    );
    return data;
  }

  async get(request: GetCampaignRequest): Promise<GetCampaignResponse> {
    const data: GetCampaignResponse = await this.managementApi.get(
      `campaigns/${request.campaign_uuid}`
    );
    return data;
  }

  async update(
    request: UpdateCampaignRequest
  ): Promise<UpdateCampaignResponse> {
    const data: UpdateCampaignResponse = await this.managementApi.put(
      `campaigns/v2/${request.campaign_uuid}`,
      request
    );
    return data;
  }

  async getNextAt(request: GetCampaignNextAtRequest) {
    const data: GetCampaignNextAtResponse = await this.managementApi.post(
      `delivery-schedule-rule/next-at`,
      request
    );
    return data.next_at;
  }
}

export default CampaignRepository;
