import TimingTable from "atoms/accountPagesShared/TimingTable";
import DetailTableLabel from "atoms/DetailTableLabel";
import DetailTableValue from "atoms/DetailTableValue";
import UrlParameterMatchPatternText from "atoms/UrlParameterMatchPatternText";
import {
  ActionTerminateReactionLabels,
  ACTION_FREQUENCY_LABELS,
  ExecuteTiming,
  PeriodTime,
  PROCESS_HOLIDAY_PATTERN,
} from "interfaces/models";
import ItemName from "molecules/ItemName";
import { getActionExpirationPeriod } from "organisms/account/action_detail/ActionDetailTableFormat";
import PreviewIFrame from "organisms/account/action_detail/PreviewIFrame";
import DetailTable from "organisms/account/DetailTable";
import createSegmentationRuleDescriptionBox from "organisms/account/SegmentationRuleDescriptionBox";
import SegmentationRuleDescriptionList from "organisms/account/SegmentationRuleDescriptionView";
import { ActionDetail2 } from "utils/ActionsRepository";
import { formatContents, PreviewNotSupportedError } from "utils/FormatContents";
import { SegmentationRuleDescriptionV2Generator } from "utils/SegmentationRuleSentence";
import SegmentationRuleCreationCodec from "utils/segmentation_rule_creation/segmentationRuleCreationCodec";

const CreateTimingTableElement = (execute_timing: ExecuteTiming[]) => {
  const formatNum = (num: number) => String(num).padStart(2, "0");
  const formatTime = (periodTime: PeriodTime) =>
    `${formatNum(periodTime.hour)}:${formatNum(periodTime.minute)}`;
  const ACTIVITY_MARK = { active: "○", inActive: "✕" };
  const convertIsActive = (is_active: boolean) =>
    is_active ? ACTIVITY_MARK.active : ACTIVITY_MARK.inActive;
  const headers = [
    "配信時間",
    "月",
    "火",
    "水",
    "木",
    "金",
    "土",
    "日",
    "祝日",
  ];
  const timings: string[][] = execute_timing.map((e) => {
    return [
      `${formatTime(e.period.from_time)} ～ ${formatTime(e.period.to_time)}`,
      convertIsActive(e.days.monday.is_active),
      convertIsActive(e.days.tuesday.is_active),
      convertIsActive(e.days.wednesday.is_active),
      convertIsActive(e.days.thursday.is_active),
      convertIsActive(e.days.friday.is_active),
      convertIsActive(e.days.saturday.is_active),
      convertIsActive(e.days.sunday.is_active),
      e.process_holiday === PROCESS_HOLIDAY_PATTERN.INCLUDE
        ? ACTIVITY_MARK.active
        : ACTIVITY_MARK.inActive,
    ];
  });

  return timings.length > 0 ? (
    <TimingTable headers={headers} columns={timings} />
  ) : (
    <div>全時間帯</div>
  );
};

interface ActionDetailTableElementProps {
  actionDetail2: ActionDetail2;
  isDisplayPopUp: boolean;
  render: (actionDetailTableElement: JSX.Element) => JSX.Element;
}

const CreateActionDetailTableElement = ({
  actionDetail2,
  isDisplayPopUp,
  render,
}: ActionDetailTableElementProps): JSX.Element => {
  const codec = new SegmentationRuleCreationCodec();

  const frequency =
    ACTION_FREQUENCY_LABELS[
      actionDetail2.action.execution_conditions.frequency
    ];

  const a_rate =
    actionDetail2.action.contents.balancing === "RANDOM"
      ? actionDetail2.action.contents.balancing_option.details.a_rate
      : 0.0;

  const expirationPeriod = getActionExpirationPeriod(
    actionDetail2.action.execution_conditions.expiration_period
  );

  const items: {
    label: string | JSX.Element;
    value: JSX.Element;
  }[] = [
    {
      label: "No.",
      value: <DetailTableValue text={actionDetail2.actionNumber.number} />,
    },
    {
      label: "アクション名",
      value: (
        <DetailTableValue
          text={actionDetail2.actionNumber.action_detail.name}
        />
      ),
    },
    {
      label: "セグメント条件",
      value: (
        <SegmentationRuleDescriptionList
          list={actionDetail2.segmentationRules.map((sr) => {
            return {
              title: `${sr.name}（ID:${sr.segmentation_rule_number}）`,
              descriptionBox: createSegmentationRuleDescriptionBox(
                SegmentationRuleDescriptionV2Generator.run(codec.decode(sr))
              ),
            };
          })}
        />
      ),
    },
    {
      label: "CVページ",
      value: (
        <DetailTableValue
          text={actionDetail2.actionNumber.action_detail.cv_url}
        />
      ),
    },
    {
      label: "表示対象URL",
      value: (
        <UrlParameterMatchPatternText
          patterns={
            actionDetail2.action.execution_conditions.url_condition.includes
          }
          noSpecifiedText="全ページ"
        />
      ),
    },
    {
      label: "除外対象URL",
      value: (
        <UrlParameterMatchPatternText
          patterns={
            actionDetail2.action.execution_conditions.url_condition.excludes
          }
          noSpecifiedText="なし"
        />
      ),
    },
    {
      label: "効果検証テスト",
      value: (
        <DetailTableValue
          text={
            actionDetail2.action.contents.balancing_option.is_ab_test
              ? "有効:(" + String(a_rate * 100) + "%表示)"
              : "無効"
          }
        />
      ),
    },
    { label: "表示頻度", value: <DetailTableValue text={frequency} /> },
    {
      label: "配信スケジュール",
      value: <DetailTableValue text={expirationPeriod} />,
    },
    {
      label: "配信時間帯",
      value: CreateTimingTableElement(
        actionDetail2.action.execution_conditions.execute_timing
      ),
    },
    {
      label: "配信停止条件",
      value: (
        <DetailTableValue
          text={
            ActionTerminateReactionLabels[
              actionDetail2.action.execution_conditions.terminate_reaction
            ]
          }
        />
      ),
    },
    {
      label: (
        <ItemName
          text="表示確認用パラメータ"
          toolTipId="test_url_parameter"
          toolTipText="eZCXタグ設置ページに表示確認用パラメータを入力すると、このアクションが表示されます。<br /> パラメータを使ったアクションの表示は計測されません。"
        />
      ),
      value: (
        <DetailTableValue
          text={"?ezcx_action=" + actionDetail2.action.action_id}
        />
      ),
    },
  ];

  if (isDisplayPopUp) {
    items.push({
      label: "表示デザイン",
      value: (
        <div>
          {actionDetail2.action.contents.patterns
            .filter((p) => p.html)
            .map((p, idx) => {
              try {
                return (
                  <div key={idx}>
                    <PreviewIFrame
                      contents={formatContents(
                        p.display_type,
                        p.version,
                        p.html
                      )}
                    />
                    <p>
                      全体が表示されない場合は、表示確認用パラメータを用いてご確認ください。
                    </p>
                  </div>
                );
              } catch (e) {
                if (e instanceof PreviewNotSupportedError) {
                  return <p>プレビューできません（{e.message}）</p>;
                }
                throw e;
              }
            })}
        </div>
      ),
    });
  }

  return render(
    <DetailTable
      records={items.map((elem, idx) => ({
        label: <DetailTableLabel key={idx} text={elem.label} />,
        value: elem.value,
      }))}
    />
  );
};

export default CreateActionDetailTableElement;
