import disabledIcon from "images/icons/icons_disabled.svg";
import styles from "./IconButton.module.scss";

const DisabledIcon = () => {
  return (
    <button type="button" className={styles.iconButton__disabled}>
      <img src={disabledIcon} alt="" />
    </button>
  );
};

export default DisabledIcon;
