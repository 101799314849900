import React, { useCallback, useMemo } from "react";
import { GrantMessageV2 } from "types/grant_message";
import RestApi from "utils/RestApi";
import useGrantMessagePanel from "app/hooks/v2/grantMessage/useGrantMessagePanel";

import styles from "./GrantMessageControlPanel.module.scss";
import fontStyles from "fontStyles.module.scss";

import OrangeButton from "atoms/ezPushShared/OrangeButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import GrantMessagePublishStatus from "molecules/v2/GrantMessagePublishStatus";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import GrantMessagePanel from "../grantMessage/GrantMessagePanel";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";
import OrangeText from "atoms/ezPushShared/accountPageShared/OrangeText";
import { useHistory } from "react-router-dom";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import { useMeasurementUrlSettingParameters } from "app/hooks/v2/basicConfiguration/useMeasurementUrlSetting";
import { BasicConfigurationRouter } from "app/process/basicConfiguration/basicConfigurationRouter";

const IsPublishModal: React.VFC<{
  isPublish: boolean;
  closeModal: () => void;
  clickPublishStatus: () => void;
}> = ({ isPublish, closeModal, clickPublishStatus }) => {
  const status = isPublish ? "非公開" : "公開中";
  return (
    <ModalBackground>
      <NotificationModal
        title={`ステータスを${status}に設定します。よろしいですか？`}
        buttons={[
          <TransparentButton onClick={closeModal} text="いいえ" />,
          <OrangeButton onClick={clickPublishStatus} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

const GuideToPageSettingModal: React.VFC<{
  clickMoveButton: () => void;
}> = ({ clickMoveButton }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title={
          "許諾メッセージを公開するには「基本設定 STEP3 ページ設定＞許諾メッセージの表示ページ」の設定まで行う必要があります。"
        }
        buttons={[
          <OrangeButton
            onClick={clickMoveButton}
            text="「許諾メッセージの表示ページ設定」へ移動"
          />,
        ]}
      />
    </ModalBackground>
  );
};

const GrantMessageControlPanel: React.VFC<{
  managementApi: RestApi;
  grantMessage: GrantMessageV2 | undefined;
  setModalContents: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  loadGrantMessage: () => void;
}> = ({ managementApi, grantMessage, setModalContents, loadGrantMessage }) => {
  const history = useHistory();
  const [parameters, callbacks] = useGrantMessagePanel(
    managementApi,
    grantMessage,
    loadGrantMessage
  );

  const [measurementUrlSettingParameters] =
    useMeasurementUrlSettingParameters(managementApi);

  const closeModal = useCallback(() => {
    setModalContents(undefined);
  }, [setModalContents]);

  const clickPublishStatus = useCallback(() => {
    callbacks.updatePublishStatus();
    closeModal();
  }, [callbacks, closeModal]);

  const moveToPageSetting = useCallback(() => {
    history.push(new BasicConfigurationRouter().toMeasurementUrlSettings());
  }, [history]);

  const openPublishModal = useCallback(() => {
    // NOTE: 公開前、許諾メッセージ表示ページ設定がない場合は、設定画面へ誘導する
    setModalContents(
      !parameters.isPublish &&
        measurementUrlSettingParameters.subscriptionUrlSetting?.length === 0 ? (
        <GuideToPageSettingModal clickMoveButton={moveToPageSetting} />
      ) : (
        <IsPublishModal
          isPublish={parameters.isPublish}
          closeModal={closeModal}
          clickPublishStatus={clickPublishStatus}
        />
      )
    );
  }, [
    parameters.isPublish,
    measurementUrlSettingParameters.subscriptionUrlSetting,
    moveToPageSetting,
    clickPublishStatus,
    closeModal,
    setModalContents,
  ]);

  // NOTE: コンポーネント生成
  const publishStatusComponent = useMemo(
    () => (
      <HorizontalLayout
        elements={[
          <HorizontalLayout
            elements={[
              <span className={fontStyles.boldText}>公開設定</span>,
              <GrantMessagePublishStatus
                isPublish={parameters.isPublish}
                onToggle={openPublishModal}
              />,
            ]}
          />,
        ]}
      />
    ),
    [openPublishModal, parameters.isPublish]
  );

  return (
    <div className={styles.grantMessageControlPanel}>
      <div className={styles.header}>{publishStatusComponent}</div>
      <div className={styles.main}>
        {grantMessage ? (
          <GrantMessagePanel grantMessage={grantMessage} />
        ) : (
          <LoadingView iconElement={<LoadingIcon />} />
        )}
      </div>
      <div>
        <OrangeText
          text={"デザイン切り替え"}
          onChange={() => {
            history.push(V2_ROUTING_TABLE.ACCOUNT.GRANT_MESSAGE.HOME);
          }}
        />
      </div>
    </div>
  );
};

export default GrantMessageControlPanel;
