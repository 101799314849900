import styles from "./AndSeparator.module.scss";

const AndSeparator = (): JSX.Element => {
  return (
    <div className={styles.andSeparator}>
      <div className={styles.bar} />
      <div className={styles.text}>and</div>
      <div className={styles.bar} />
    </div>
  );
};

export default AndSeparator;
