import styles from "./SideAreaTab.module.scss";
import CompleteCheck from "images/icons/icon_complete_check.svg";
import UncompleteCheck from "images/icons/icon_uncomplete_check.svg";

type SideAreaTabProps = {
  name: string;
  isActive: boolean;
  isComplete: boolean;
  onClick?: () => void;
};

const SideAreaTab = ({
  name,
  isActive,
  isComplete,
  onClick,
}: SideAreaTabProps) => {
  return (
    <div
      className={isActive ? styles.sideAreaTab__active : styles.sideAreaTab}
      onClick={onClick}
    >
      <div className={styles.name}>{name}</div>
      {isComplete ? (
        <img src={CompleteCheck} alt="complete" />
      ) : (
        <img src={UncompleteCheck} alt="uncomplete" />
      )}
    </div>
  );
};

export default SideAreaTab;
