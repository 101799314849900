import styles from "./GrayBackTitle.module.scss";
import fontStyles from "fontStyles.module.scss";

const GrayBackTitle = ({ title }: { title: string }) => (
  <div className={styles.grayBackTitle}>
    <div className={fontStyles.section}>{title}</div>
  </div>
);

export default GrayBackTitle;
