export const V2_SERVICE_NAME = "Target Push";

export const V2_PARAMETERS = {
  SUBSCRIBE: {
    FORCE_DISPLAY: {
      QUERY_PARAMETER: {
        KEY: "ezcx_action",
      },
    },
    TEST: {
      QUERY_PARAMETER: {
        KEY: "ezcx_notification_test",
        VALUE: "true",
      },
    },
  },
} as const;

export const V2_DOWNLOAD_FILE_NAMES = {
  INITIAL_SETTINGS: {
    SW_LOADER: "ezcx_sw_loader.js",
    TAG_LOADER: "targetpush_tag.txt",
    ALL: "TargetPushSetting.zip",
  }
} as const;
