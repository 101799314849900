import EventText from "atoms/accountPagesShared/EventText";
import { ColorResult } from "react-color";
import ColorCircle from "./ColorCircle";
import EZCXEditableInput from "./EditableInput";
import styles from "./PresetColorPicker.module.scss";

type CreateCirclesProps = {
  colors: string[][];
  currentColor: string;
  onClickCircle: (color: string) => void;
};

const CreateCircles = ({
  colors,
  currentColor,
  onClickCircle,
}: CreateCirclesProps): JSX.Element => {
  return (
    <div className={styles.circleArea}>
      {colors.map((raw, raw_count) => (
        <div key={raw_count} className={styles.circleRow}>
          {raw.map((elem, elem_count) => (
            <div key={elem_count} className={styles.circleElem}>
              <ColorCircle
                hex={elem}
                isActive={
                  currentColor
                    ? elem.toUpperCase() === currentColor.toUpperCase()
                    : false
                }
                onChange={() => onClickCircle(elem)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

type PresetColorPickerProps = {
  color: ColorResult;
  onChange: (e: ColorResult | string) => void;
  onChangePicker: () => void;
};

const PresetColorPicker = (props: PresetColorPickerProps) => {
  const colors: string[][] = [
    [
      "#FF6900",
      "#FCB900",
      "#FFEB3B",
      "#7BDCB5",
      "#00D084",
      "#4CAF50",
      "#8ED1FC",
    ],
    [
      "#0693E3",
      "#3F51B5",
      "#EA134B",
      "#F68CA6",
      "#F44336",
      "#FFFFFF",
      "#000000",
    ],
  ];

  return (
    <div className={styles.presetColorPicker}>
      <div>
        <CreateCircles
          colors={colors}
          currentColor={props.color.hex}
          onClickCircle={(c) => props.onChange(c)}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.editableInput}>
          <EZCXEditableInput
            hex={props.color.hex}
            onChange={(c, e) => props.onChange(c)}
          />
        </div>
        <div>
          <EventText text="スライダーで選ぶ" onClick={props.onChangePicker} />
        </div>
      </div>
    </div>
  );
};

export default PresetColorPicker;
