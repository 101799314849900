import styles from "./ModeSelectPhaseFormMain.module.scss";

const ModeSelectPhaseFormMain = ({ buttons }: { buttons: JSX.Element[] }) => (
  <div className={styles.modeSelectPhaseFormMain}>
    <div className={styles.headline}>どちらの方法で作成しますか？</div>
    <div className={styles.buttons}>
      {buttons.map((e, i) => (
        <div key={i} className={styles.button}>
          {e}
        </div>
      ))}
    </div>
  </div>
);

export default ModeSelectPhaseFormMain;
