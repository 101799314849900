import React from "react";

import styles from "./UnorderedList.module.scss";

const UnorderedList: React.VFC<{ items: JSX.Element[] }> = ({ items }) => (
  <ul className={styles.unorderedList}>
    {items.map((item, index) => (
      <li key={index} className={styles.item}>
        {item}
      </li>
    ))}
  </ul>
);

export default UnorderedList;
