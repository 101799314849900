import styles from "./LoadingPageView.module.scss";

type LoadingPageViewProps = {
  logoElement: JSX.Element;
  greetingElement: JSX.Element;
  progressElement: JSX.Element;
};

const LoadingPageView: React.VFC<LoadingPageViewProps> = ({
  logoElement,
  greetingElement,
  progressElement,
}) => {
  return (
    <div className={styles.loadingPageView}>
      <div className={styles.elementsArea}>
        <div className={styles.logo}>{logoElement}</div>
        <div className={styles.greeting}>{greetingElement}</div>
        <div>{progressElement}</div>
      </div>
    </div>
  );
};

export default LoadingPageView;
