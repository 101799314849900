import { useEffect } from "react";

import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import { ActionRepository } from "utils/ActionsRepository";
import { AccountPageContext } from "interfaces/view/accout";

import BuildAccountPage from "app/builders/BuildAccountPage";
import CreateModal from "app/creators/action_creation/CreateModal";
import useActionCreationService from "app/hooks/useActionCreationService";
import ActionCreationView from "organisms/account/action_creation/ActionCreationView";
import { createAccountCreationViewParts } from "app/creators/action_creation/CreateAccountCreationPageAreaContents";
import { ACTION_OPERATION_MODE } from "interfaces/view/actionCreation";

const ActionCreationPage = ({
  context,
  actionRepository,
  segmentationRuleRepository,
}: {
  context: AccountPageContext;
  actionRepository: ActionRepository;
  segmentationRuleRepository: SegmentationRuleRepository;
}) => {
  useEffect(() => {
    // FIXME: illegal updating
    console.log(
      `actionRepository is updated twice, do not update this obj ${actionRepository}`
    );
  }, [actionRepository]);

  const [appState, appCallbacks, viewCallbacks, formState] =
    useActionCreationService(
      actionRepository,
      segmentationRuleRepository,
      context.account?.cv_url
    );

  return (
    <BuildAccountPage
      context={context}
      renderDefaultModalElement={({ setModalElement }) =>
        appState.modalType ? (
          <CreateModal
            actionStatus={appState.status}
            modalType={appState.modalType}
            appCallbacks={appCallbacks}
            setModalElement={setModalElement}
          />
        ) : (
          <></>
        )
      }
      renderAreaContentsElement={() => (
        <ActionCreationView
          {...createAccountCreationViewParts({
            isEditMode: appState.operation === ACTION_OPERATION_MODE.EDIT,
            mode: appState.mode,
            phase: appState.phase,
            appState,
            appCallbacks,
            viewCallbacks,
            formState,
          })}
        />
      )}
    />
  );
};

export default ActionCreationPage;
