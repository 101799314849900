import styles from "./ElementModal.module.scss";
import fontStyles from "fontStyles.module.scss";

import React from "react";

const ElementModal: React.VFC<{
  title: string;
  buttons: JSX.Element[];
  element?: string | JSX.Element;
  introduction?: string | JSX.Element;
  note?: string | JSX.Element;
}> = ({ title, buttons, element, introduction, note }) => {
  return (
    <div className={styles.elementModal}>
      <div className={styles.title}>
        <div className={fontStyles.section}>{title}</div>
      </div>
      {introduction ? (
        <div className={styles.introduction}>{introduction}</div>
      ) : undefined}
      {element ? <div className={styles.element}>{element}</div> : undefined}
      {note ? (
        <div className={styles.note}>
          <span className={fontStyles.note}>{note}</span>
        </div>
      ) : undefined}
      <div className={styles.buttons}>
        {buttons.map((elem, i) => (
          <div key={i} className={styles.button}>
            {elem}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ElementModal;
