import { PLEASE_CHOICE } from "app/system_defaults/WordDefaults";
import {
  IntegerCompareType,
  IntegerCompareTypes,
  PathParameterComparePattern,
  UrlPattern,
} from "interfaces/models";

export const TargetUser = {
  ALL: "all",
  EXISTING_USER_ONLY: "existing_user_only",
  NO_MEMBER_USER_ONLY: "no_member_user_only",
} as const;

export type TargetUserType = typeof TargetUser[keyof typeof TargetUser];

export const TargetUserLabels: { [key in TargetUserType]: string } = {
  [TargetUser.ALL]: "全ユーザー",
  [TargetUser.EXISTING_USER_ONLY]: "既存会員ユーザーのみ",
  [TargetUser.NO_MEMBER_USER_ONLY]: "非会員ユーザーのみ",
} as const;

export const Phase = { INPUT: "input", COMPLETE: "complete" } as const;

export type PhaseType = typeof Phase[keyof typeof Phase];

export interface Parameters {
  name: string;
  targetUser: TargetUserType;
}

export const SegmentationRuleCreationMode = {
  CREATE: "create",
  EDIT: "edit",
} as const;

export type SegmentationRuleCreationModeType =
  typeof SegmentationRuleCreationMode[keyof typeof SegmentationRuleCreationMode];

export const TargetPage = { ALL: "all", SPECIFIC: "specific" } as const;

export type TargetPageType = typeof TargetPage[keyof typeof TargetPage];

export const TargetPageLabels: { [key in TargetPageType]: string } = {
  [TargetPage.ALL]: "サイト全体",
  [TargetPage.SPECIFIC]: "指定のページ",
};

export const ExternalDataComparePattern = {
  EQUAL: "equal",
  INCLUDE: "include",
  GRATER_THAN: "greater_than",
  LESS_THAN: "less_than",
} as const;

export type ExternalDataComparePatternType =
  typeof ExternalDataComparePattern[keyof typeof ExternalDataComparePattern];

export const ExternalDataKeyPattern = {
  UNSELECTED: PLEASE_CHOICE,
};

export type ExternalData = {
  key: string;
  value: string;
  compareType: ExternalDataComparePatternType;
};

export const ExternalDataComparePatternLabels: {
  [key in ExternalDataComparePatternType]: string;
} = {
  [ExternalDataComparePattern.EQUAL]: "に一致する",
  [ExternalDataComparePattern.INCLUDE]: "を含む",
  [ExternalDataComparePattern.GRATER_THAN]: "以上",
  [ExternalDataComparePattern.LESS_THAN]: "以下",
};

export type SegmentationRuleCreationAppState = {
  name: string;
  targetUser: TargetUserType;
  targetPage: UrlPattern;
  userActions: UserAction[];
  externalData: ExternalData[];
};

export const PvPattern = {
  SESSION: "session",
  CONTINUOUS: "continuous",
  INCLUDE_PAST: "include_past",
};

export type PvPatternType = typeof PvPattern[keyof typeof PvPattern];

export const PvPatternLabels: { [key in PvPatternType]: string } = {
  [PvPattern.SESSION]: "セッション内",
  [PvPattern.CONTINUOUS]: "連続で閲覧",
  [PvPattern.INCLUDE_PAST]: "過去の行動を含める",
};

export const UserActionPattern = {
  NO_ACTION: "noAction",
  PAGE_VIEW: "pageView",
  STAYING_TIME: "stayingTime",
  PAGE_SCROLL: "pageScroll",
  LAST_PV_TIMING: "lastPVTiming",
  TOTAL_CV_COUNT: "totalCVCount",
} as const;

export type UserActionPatternType =
  typeof UserActionPattern[keyof typeof UserActionPattern];

export const UserActionPatternLabels: {
  [key in UserActionPatternType]: string;
} = {
  [UserActionPattern.NO_ACTION]: PLEASE_CHOICE,
  [UserActionPattern.PAGE_VIEW]: "ページの閲覧",
  [UserActionPattern.STAYING_TIME]: "滞在時間",
  [UserActionPattern.PAGE_SCROLL]: "ページのスクロール",
  [UserActionPattern.LAST_PV_TIMING]: "最後の来訪から",
  [UserActionPattern.TOTAL_CV_COUNT]: "購入",
};

export type NoAction = {
  type: "noAction";
};

export type PageView = {
  type: "pageView";
  count: number;
  comparePattern: IntegerCompareType;
  pvPattern: PvPatternType;
  range_days?: number;
};

export type StayingTime = {
  type: "stayingTime";
  seconds: number;
};

export type PageScroll = {
  type: "pageScroll";
  rate: number;
};

export type LastPVTiming = {
  type: "lastPVTiming";
  days: number;
};

export type TotalCVCount = {
  type: "totalCVCount";
  count: number;
  comparePattern: IntegerCompareType;
};

export type UserAction =
  | PageView
  | StayingTime
  | PageScroll
  | LastPVTiming
  | TotalCVCount
  | NoAction;

const NoActionDefault: NoAction = {
  type: UserActionPattern.NO_ACTION,
};

const PageViewDefault: PageView = {
  type: UserActionPattern.PAGE_VIEW,
  count: 1,
  comparePattern: IntegerCompareTypes.GreaterThanOrEqualTo,
  pvPattern: PvPattern.SESSION,
};

const PageScrollDefault: PageScroll = {
  type: UserActionPattern.PAGE_SCROLL,
  rate: 10,
};

const StayingTimeDefault: StayingTime = {
  type: UserActionPattern.STAYING_TIME,
  seconds: 60,
};

const LastPVTimingDefault: LastPVTiming = {
  type: UserActionPattern.LAST_PV_TIMING,
  days: 1,
};

const TotalCVCountDefault: TotalCVCount = {
  type: UserActionPattern.TOTAL_CV_COUNT,
  count: 1,
  comparePattern: IntegerCompareTypes.GreaterThanOrEqualTo,
};

export const UserActionDefaults = {
  [UserActionPattern.NO_ACTION]: NoActionDefault,
  [UserActionPattern.PAGE_VIEW]: PageViewDefault,
  [UserActionPattern.PAGE_SCROLL]: PageScrollDefault,
  [UserActionPattern.STAYING_TIME]: StayingTimeDefault,
  [UserActionPattern.LAST_PV_TIMING]: LastPVTimingDefault,
  [UserActionPattern.TOTAL_CV_COUNT]: TotalCVCountDefault,
};

export type FormStateQueryParameter = {
  keyValue: string;
  pattern: PathParameterComparePattern;
};

export type SegmentationRuleDescription = {
  name: string;
  targetPage: {
    locations: string[];
    parameters: string[];
  };
  targetUser: string;
  userAction: string[];
  externalData: string[];
};
