import styles from "./StepBar.module.scss";
import { SettingPhaseCategory } from "interfaces/view/actionCreation";

type StepBarItemProps = {
  index: number;
  text: string;
  isActive: boolean;
};

const StepBarItem = ({ index, text, isActive }: StepBarItemProps) => {
  return (
    <div className={isActive ? styles.item__active : styles.item}>
      <div className={styles.step}>STEP{index}</div>
      <div>{text}</div>
    </div>
  );
};

type StepBarProps = {
  currentStep: SettingPhaseCategory;
  items: { text: string; step: SettingPhaseCategory }[];
};

const StepBar = ({ currentStep, items }: StepBarProps) => {
  return (
    <div className={styles.stepBar}>
      {items.map((e, i) => (
        <StepBarItem
          key={i}
          index={i + 1}
          text={e.text}
          isActive={currentStep === e.step}
        />
      ))}
    </div>
  );
};

export default StepBar;
