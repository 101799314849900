import {
  GRANT_MESSAGE_MODAL_DISPLAY_POSITION,
  GRANT_MESSAGE_LAYOUT_TYPE,
  GrantMessageLayoutType,
  GRANT_MESSAGE_POP_UP_DISPLAY_POSITION,
  GrantMessageDisplayPosition,
} from "interfaces/v2/grantMessageContents";
import { useEffect, useMemo, useState } from "react";

const isInMarginRange = (value: number) => {
  const MAX_ADJUST_MARGIN = 100;
  const MIN_ADJUST_MARGIN = 0;

  return MIN_ADJUST_MARGIN <= value && value <= MAX_ADJUST_MARGIN;
};

export type GrantMessageContentsLayoutParameters = {
  layoutType: GrantMessageLayoutType;
  displayPosition: GrantMessageDisplayPosition;
  topMargin: number;
  bottomMargin: number;
};

export type GrantMessageContentsLayoutCallbacks = {
  updateType: (type: GrantMessageLayoutType) => void;
  updateDisplayPosition: (dp: GrantMessageDisplayPosition) => void;
  updateTopMargin: (value: number) => void;
  updateBottomMargin: (value: number) => void;
};

export const useGrantMessageContentsLayout = (
  currentParameters: GrantMessageContentsLayoutParameters
): [
  GrantMessageContentsLayoutParameters,
  GrantMessageContentsLayoutCallbacks
] => {
  const [layoutType, setLayoutType] = useState<GrantMessageLayoutType>(
    currentParameters.layoutType
  );
  const [displayPosition, setDisplayPosition] =
    useState<GrantMessageDisplayPosition>(currentParameters.displayPosition);

  const [topMargin, setTopMargin] = useState<number>(
    currentParameters.topMargin ?? 0
  );

  const [bottomMargin, setBottomMargin] = useState<number>(
    currentParameters.bottomMargin ?? 0
  );

  useEffect(() => {
    if (layoutType === GRANT_MESSAGE_LAYOUT_TYPE.MODAL) {
      setDisplayPosition(GRANT_MESSAGE_MODAL_DISPLAY_POSITION.CENTER);
    } else if (layoutType === GRANT_MESSAGE_LAYOUT_TYPE.POP_UP) {
      if (currentParameters.layoutType === GRANT_MESSAGE_LAYOUT_TYPE.POP_UP) {
        setDisplayPosition(currentParameters.displayPosition);
      } else {
        setDisplayPosition(GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_BOTTOM);
      }
    }
  }, [
    layoutType,
    currentParameters.layoutType,
    currentParameters.displayPosition,
  ]);

  const parameters: GrantMessageContentsLayoutParameters = useMemo(() => {
    return {
      layoutType: layoutType,
      displayPosition: displayPosition,
      topMargin: topMargin,
      bottomMargin: bottomMargin,
    };
  }, [layoutType, displayPosition, topMargin, bottomMargin]);

  const callbacks: GrantMessageContentsLayoutCallbacks = useMemo(() => {
    return {
      updateType: (type: GrantMessageLayoutType) => {
        setLayoutType(type);
      },
      updateDisplayPosition: (dp: GrantMessageDisplayPosition) => {
        setDisplayPosition(dp);
      },
      updateTopMargin: (value: number) => {
        if (isInMarginRange(value)) {
          setTopMargin(value);
        }
      },
      updateBottomMargin: (value: number) => {
        if (isInMarginRange(value)) {
          setBottomMargin(value);
        }
      },
    };
  }, []);

  return [parameters, callbacks];
};
