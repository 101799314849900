import PullDown from "molecules/pullDown/PullDown";
import styles from "./PullDownForm.module.scss";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";

type PullDownFormProps = {
  name: JSX.Element;
  options: OptionType[];
  value: any;
  onChange: (value: any) => void;
};

const PullDownForm = ({
  name,
  options,
  value,
  onChange,
}: PullDownFormProps) => {
  return (
    <div className={styles.pullDownForm}>
      <div className={styles.name}>{name}</div>
      <div>
        <PullDown
          options={options}
          value={value}
          onChange={(e) => onChange(e)}
        />
      </div>
    </div>
  );
};

export default PullDownForm;
