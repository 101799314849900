import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import TextInput from "atoms/TextInput";
import {
  LocationPattern,
  LocationPatternLabels,
  LOCATION_COMPARE_PATTERN,
} from "interfaces/models";
import styles from "./UrlLocationForm.module.scss";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";

type UrlLocationFormProps = {
  location: LocationPattern | null;
  onChange: (e: LocationPattern) => void;
  isActive?: boolean;
};

const UrlLocationForm = ({
  location,
  onChange,
  isActive = true,
}: UrlLocationFormProps) => {
  const options: OptionType[] = [
    {
      value: LOCATION_COMPARE_PATTERN.INCLUDE,
      label: LocationPatternLabels[LOCATION_COMPARE_PATTERN.INCLUDE],
    },
    {
      value: LOCATION_COMPARE_PATTERN.EXCLUDE,
      label: LocationPatternLabels[LOCATION_COMPARE_PATTERN.EXCLUDE],
    },
    {
      value: LOCATION_COMPARE_PATTERN.COMPLETE,
      label: LocationPatternLabels[LOCATION_COMPARE_PATTERN.COMPLETE],
    },
  ];

  return (
    <div className={styles.urlLocationForm}>
      <div className={styles.main}>
        <div className={styles.location}>
          <TextInput
            value={location ? location.value : ""}
            onChange={(e) => {
              if (e) {
                onChange({
                  value: e.target.value,
                  pattern: location
                    ? location.pattern
                    : LOCATION_COMPARE_PATTERN.INCLUDE,
                });
              }
            }}
            placeholder="https://example.com"
            isActive={isActive}
          />
        </div>
        <div className={styles.compareType}>
          <BorderedSelectBox
            options={options}
            value={
              location ? location.pattern : LOCATION_COMPARE_PATTERN.INCLUDE
            }
            onChange={(e) => {
              onChange({
                value: location ? location.value : "",
                pattern: e,
              });
            }}
            isDisabled={!isActive}
          />
        </div>
      </div>
    </div>
  );
};

export default UrlLocationForm;
