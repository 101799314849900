import styles from "./SegmentationRuleDetailView.module.scss";

const SegmentationRuleDetailView = ({
  titleElement,
  SegmentationRuleDetailTableElement,
  closeButtonElement,
}: {
  titleElement: JSX.Element;
  SegmentationRuleDetailTableElement: JSX.Element;
  closeButtonElement: JSX.Element;
}) => {
  return (
    <div className={styles.segmentationRuleDetail}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.table}>{SegmentationRuleDetailTableElement}</div>
      <div className={styles.closeButton}>{closeButtonElement}</div>
    </div>
  );
};

export default SegmentationRuleDetailView;
