import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import RestApi from "utils/RestApi";
import MeasurementUrlRepository from "utils/repositories/MeasurementUrlRepository";
import ErrorMessage from "utils/ErrorMessage";
import * as useEditModeParameters from "app/hooks/v2/measurementUrlSetting/useEditModeParameters";
import { MeasurementUrlSettingTranslator } from "app/process/measurementUrlSetting/measurementUrlSettingTranslator";
import {
  MEASUREMENT_URL_SETTING_EDITOR_MODE,
  MeasurementUrlSettingEditorMode,
} from "app/system_defaults/v2_routing";

import {
  FormCallbacks,
  FormParameters,
  useEditPageForm,
} from "./useEditPageForm";
import { MeasurementUrlSettingValidator } from "app/process/measurementUrlSetting/measurementUrlSettingValidator";
import { MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import {
  MeasurementUrlSetting,
  MeasurementUrlSettingWithType,
  MeasurementUrlType,
} from "types/measurement_urls";
import { MEASUREMENT_URL_SETTING_TYPE_RULE } from "app/system_defaults/validateRule";
import { BasicConfigurationRouter } from "app/process/basicConfiguration/basicConfigurationRouter";

export type EditPageEventParameters = {
  errors: ErrorMessage[];
};

export type EditPageEventCallbacks = {
  onSave: () => void;
  onCancel: () => void;
};

const useEditPageEvents = (
  managementApi: RestApi,
  editModeParameters: useEditModeParameters.EditModeParameters,
  formParameters: FormParameters
): [EditPageEventParameters, EditPageEventCallbacks] => {
  const history = useHistory();

  const [errors, setErrors] = useState<ErrorMessage[]>([]);

  const onSave = useCallback(() => {
    const repository = new MeasurementUrlRepository(managementApi);

    const validator = new MeasurementUrlSettingValidator();

    const errors = validator.validate(
      formParameters.name,
      formParameters.location,
      formParameters.parameters
    );

    if (errors.length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors([]);
    }

    if (
      editModeParameters.mode === MEASUREMENT_URL_SETTING_EDITOR_MODE.EDIT &&
      editModeParameters.urlUuid
    ) {
      const updateRequest = MeasurementUrlSettingTranslator.encode(
        editModeParameters.type,
        formParameters
      );
      repository
        .update({
          url_uuid: editModeParameters.urlUuid,
          name: updateRequest.name,
          url: updateRequest.url,
          type: updateRequest.url_type,
        })
        .then(() => {
          history.push(
            new BasicConfigurationRouter().toMeasurementUrlSettings(
              editModeParameters.type
            )
          );
        });
    } else {
      repository
        .create(
          MeasurementUrlSettingTranslator.encode(
            editModeParameters.type,
            formParameters
          )
        )
        .then(() => {
          history.push(
            new BasicConfigurationRouter().toMeasurementUrlSettings(
              editModeParameters.type
            )
          );
        })
        .catch(() => {
          setErrors([
            new ErrorMessage(
              MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST.SAVE.FAILED
            ),
          ]);
        });
    }

    return;
  }, [editModeParameters, formParameters, managementApi, history, setErrors]);

  const onCancel = useCallback(
    () =>
      history.push(
        new BasicConfigurationRouter().toMeasurementUrlSettings(
          editModeParameters.type
        )
      ),
    [history, editModeParameters.type]
  );

  return [
    {
      errors,
    },
    {
      onSave: () => onSave(),
      onCancel: () => onCancel(),
    },
  ];
};

export type EditPageParameters = {
  mode: useEditModeParameters.EditModeParameters;
  form: FormParameters;
  event: EditPageEventParameters;
  isLoadTargetMeasurementUrlSetting: boolean;
  isExceededLimit: boolean;
};

export type EditPageCallbacks = {
  form: FormCallbacks;
  event: EditPageEventCallbacks;
};

export const useMeasurementUrlSetting = (
  managementApi: RestApi,
  editModeParameters: useEditModeParameters.EditModeParameters
): MeasurementUrlSetting | null => {
  const [measurementUrlSetting, setMeasurementUrlSetting] =
    useState<MeasurementUrlSetting | null>(null);

  const getMeasurementUrlSetting = useCallback(async () => {
    if (
      editModeParameters.mode === MEASUREMENT_URL_SETTING_EDITOR_MODE.EDIT &&
      editModeParameters.urlUuid
    ) {
      const repository = new MeasurementUrlRepository(managementApi);

      const response = await repository.get({
        url_uuid: editModeParameters.urlUuid,
      });

      if (response) {
        setMeasurementUrlSetting(response.url);
      }
    }
  }, [editModeParameters, managementApi]);

  useEffect(() => {
    if (measurementUrlSetting === null) {
      getMeasurementUrlSetting();
    }
  }, [measurementUrlSetting, getMeasurementUrlSetting]);

  return measurementUrlSetting;
};

export const useExistedMeasurementUrlSettings = (
  managementApi: RestApi,
  type: MeasurementUrlType
) => {
  const [existedMeasurementUrlSettings, setExistedMeasurementUrlSettings] =
    useState<MeasurementUrlSettingWithType[]>([]);

  const load = useCallback(async () => {
    const repository = new MeasurementUrlRepository(managementApi);
    const response = await repository.load();
    setExistedMeasurementUrlSettings(
      response.items.filter((item) => item.type === type)
    );
  }, [managementApi, type]);

  useEffect(() => {
    load();
  }, [load]);

  return [existedMeasurementUrlSettings];
};

const isExceededLimit = (
  existedMeasurementUrlSetting: MeasurementUrlSettingWithType[],
  mode: MeasurementUrlSettingEditorMode,
  urlType: MeasurementUrlType
): boolean => {
  if (mode === MEASUREMENT_URL_SETTING_EDITOR_MODE.CREATE) {
    return (
      existedMeasurementUrlSetting.length >=
      MEASUREMENT_URL_SETTING_TYPE_RULE[urlType].ITEM_LIMIT
    );
  } else if (mode === MEASUREMENT_URL_SETTING_EDITOR_MODE.EDIT) {
    return (
      existedMeasurementUrlSetting.length >
      MEASUREMENT_URL_SETTING_TYPE_RULE[urlType].ITEM_LIMIT
    );
  } else {
    return true;
  }
};

export const useEditPage = (
  managementApi: RestApi
): [EditPageParameters, EditPageCallbacks] => {
  const editModeParameters = useEditModeParameters.useEditModeParameters();

  const [existedMeasurementUrlSetting] = useExistedMeasurementUrlSettings(
    managementApi,
    editModeParameters.type
  );

  const [formParameters, formCallbacks] = useEditPageForm(
    existedMeasurementUrlSetting.find(
      (value) => value.url.url_uuid === editModeParameters.urlUuid
    )?.url || null
  );

  const [eventParameters, eventCallbacks] = useEditPageEvents(
    managementApi,
    editModeParameters,
    formParameters
  );

  return [
    {
      mode: editModeParameters,
      form: formParameters,
      event: eventParameters,
      // NOTE: 編集モードの場合には、対象のデータが読み込まれているかどうかを判定している
      isLoadTargetMeasurementUrlSetting:
        existedMeasurementUrlSetting.length > 0,
      // NOTE: アイテム数が上限を超えている場合には、新規登録を制限する
      isExceededLimit: isExceededLimit(
        existedMeasurementUrlSetting,
        editModeParameters.mode,
        editModeParameters.type
      ),
    },
    {
      form: formCallbacks,
      event: eventCallbacks,
    },
  ];
};
