import SegmentationRuleTable from "organisms/account/segmentation_rules/SegmentationRuleTable";
import SegmentationRuleTableItem from "organisms/account/segmentation_rules/SegmentationRuleTableItem";
import Descriptions from "organisms/account/segmentation_rules/Descriptions";
import SegmentationRuleHeader from "organisms/account/segmentation_rules/molecules/SegmentationRuleHeader";
import SegmentationRuleView from "organisms/account/segmentation_rules/SegmentationRuleView";
import SegmentationRuleName from "organisms/account/segmentation_rules/SegmentationRuleName";
import SegmentationRuleOperation from "organisms/account/segmentation_rules/molecules/SegmentationRuleOperation";

import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import ErrorMessage from "atoms/accountPagesShared/ErrorMessage";
import HeadlineText from "atoms/HeadlineText";
import EditButton from "atoms/accountPagesShared/EditButton";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import DatetimeView from "atoms/accountPagesShared/DatetimeView";

import { SegmentationRule } from "interfaces/models";
import CopyButton from "atoms/accountPagesShared/CopyButton";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";
import ToolTipDeleteButton from "atoms/accountPagesShared/ToolTipDeleteButton";

export interface CreateAreaContentsCallbacks {
  onCreate: (sourceSegmentationRuleId?: string) => void;
  onDetail: (segmentationRuleId: string) => void;
  onEdit: (segmentationRuleId: string) => void;
  onDelete: (segmentationRuleId: string) => void;
}

const CreateSegmentationRuleTable = ({
  segmentationRules,
  callbacks,
}: {
  segmentationRules: SegmentationRule[];
  callbacks: CreateAreaContentsCallbacks;
}) => {
  const items = segmentationRules.map((segmentationRule) => (
    <SegmentationRuleTableItem
      key={segmentationRule.segmentation_rule_id}
      segmentationRuleNumber={segmentationRule.segmentation_rule_number}
      nameElement={
        <SegmentationRuleName
          name={segmentationRule.name}
          operationElement={
            <SegmentationRuleOperation
              items={[
                <EditButton
                  onClick={() =>
                    callbacks.onEdit(segmentationRule.segmentation_rule_id)
                  }
                />,
                <CopyButton
                  onClick={() =>
                    callbacks.onCreate(segmentationRule.segmentation_rule_id)
                  }
                />,
                segmentationRule.linked_actions.length > 0 ? (
                  <ToolTipDeleteButton
                    toolTipText="アクションに設定中のため削除できません。"
                    onClick={() => undefined}
                  />
                ) : (
                  <DeleteButton
                    onClick={() =>
                      callbacks.onDelete(segmentationRule.segmentation_rule_id)
                    }
                  />
                ),
              ]}
            />
          }
          onClick={() =>
            callbacks.onDetail(segmentationRule.segmentation_rule_id)
          }
        />
      }
      detailElement={<Descriptions conditions={segmentationRule.conditions} />}
      createdAtElement={<DatetimeView value={segmentationRule.created_at} />}
      updatedAtElement={<DatetimeView value={segmentationRule.updated_at} />}
    />
  ));

  return (
    <SegmentationRuleTable
      headerColumnElements={[
        <th key={1}>No.</th>,
        <th key={2}>セグメント名</th>,
        <th key={3}>条件</th>,
        <th key={4}>作成日時</th>,
        <th key={5}>更新日時</th>,
      ]}
      items={items}
    />
  );
};

const CreateAreaContents = ({
  errorMessage,
  segmentationRules,
  callbacks,
  render,
}: {
  errorMessage: string;
  segmentationRules: SegmentationRule[] | null;
  callbacks: CreateAreaContentsCallbacks;
  render: (areaContents: JSX.Element) => JSX.Element;
}) => {
  const areaContents = (
    <SegmentationRuleView
      errorMessageElement={<ErrorMessage message={errorMessage} />}
      headerElement={
        <SegmentationRuleHeader
          headlineElement={<HeadlineText text="カスタマーセグメント" />}
          createButtonElement={
            <PositiveButton text="作成" onClick={() => callbacks.onCreate()} />
          }
        />
      }
      actionTableElement={
        segmentationRules === null ? (
          <LoadingView
            iconElement={<LoadingIcon />}
            textElement={<div>カスタマーセグメント一覧を取得しています。</div>}
          />
        ) : (
          <CreateSegmentationRuleTable
            segmentationRules={segmentationRules}
            callbacks={callbacks}
          />
        )
      }
    />
  );

  return render(areaContents);
};

export default CreateAreaContents;
