import React from "react";
import styles from "templates/ezPush/MiniPanel.module.scss";

const MiniPanel: React.VFC<{
  titleElement?: JSX.Element;
  mainElement: JSX.Element;
}> = ({ titleElement, mainElement }) => (
  <div className={styles.miniPanel}>
    {titleElement ? (
      <div className={styles.title}>{titleElement}</div>
    ) : undefined}
    <div className={styles.main}>{mainElement}</div>
  </div>
);

export default MiniPanel;
