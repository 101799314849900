import styles from "./HtmlPreviewer.module.scss";
import Frame from "react-frame-component";
import parse from "html-react-parser";

type HtmlPreviewerProps = {
  html: string;
};

const HtmlPreviewer = ({ html }: HtmlPreviewerProps) => {
  return (
    <div className={styles.htmlPreviewer}>
      <div className={styles.contents}>
        <Frame className={styles.frame}>
          <div>{parse(html)}</div>
        </Frame>
      </div>
    </div>
  );
};

export default HtmlPreviewer;
