import EZCXGradationColorPicker from "molecules/GradationColorPicker/GradationColorPicker";
import styles from "./GradationColorPickForm.module.scss";

type GradationColorPickFormProps = {
  name: string;
  values: string[];
  colorType: string;
  onChange: (value: string[]) => void;
  onChangeColorType: (type: string) => void;
};

const GradationColorPickForm = ({
  name,
  values,
  colorType,
  onChange,
  onChangeColorType,
}: GradationColorPickFormProps) => {
  return (
    <div className={styles.gradationColorPickForm}>
      <div className={styles.name}>{name}</div>
      <div className={styles.form}>
        <EZCXGradationColorPicker
          values={values}
          colorType={colorType}
          onChange={(e) => onChange(e)}
          onChangeColorType={(e) => onChangeColorType(e)}
        />
      </div>
    </div>
  );
};

export default GradationColorPickForm;
