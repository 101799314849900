import styles from "./SegmentationRuleCreationCurrentPageForm.module.scss";

type SegmentationRuleCreationCurrentPageFormProps = {
  urlElement: JSX.Element;
  descriptionElements: JSX.Element[];
  formElements: JSX.Element[];
  separatorElement: JSX.Element;
};

const SegmentationRuleCreationCurrentPageForm = ({
  urlElement,
  descriptionElements,
  formElements,
  separatorElement,
}: SegmentationRuleCreationCurrentPageFormProps) => {
  return (
    <div className={styles.segmentationRuleCreationCurrentPageForm}>
      <div className={styles.url}>{urlElement}</div>
      <div className={styles.descriptions}>
        {descriptionElements.map((e, i) => (
          <div key={i}>{e}</div>
        ))}
      </div>
      <div className={styles.forms}>
        {formElements.map((e, i) => (
          <div key={i}>
            {i === 0 ? undefined : (
              <div className={styles.separator}>{separatorElement}</div>
            )}
            <div>{e}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentationRuleCreationCurrentPageForm;
