import { useMemo, useState } from "react";

export type GrantMessageContentsFrameDesignParameters = {
  lineWidth: number;
  lineColor: string;
  backgroundColor: string;
};

export type GrantMessageContentsFrameDesignCallbacks = {
  updateLineWidth: (value: number) => void;
  updateLineColor: (value: string) => void;
  updateBackgroundColor: (value: string) => void;
};

export const useGrantMessageContentsFrameDesign = (
  currentParameters: GrantMessageContentsFrameDesignParameters
): [
  GrantMessageContentsFrameDesignParameters,
  GrantMessageContentsFrameDesignCallbacks
] => {
  const [lineWidth, setLineWidth] = useState<number>(
    currentParameters.lineWidth
  );
  const [lineColor, setLineColor] = useState<string>(
    currentParameters.lineColor
  );
  const [backgroundColor, setBackgroundColor] = useState(
    currentParameters.backgroundColor
  );

  const parameters = useMemo(() => {
    return {
      lineWidth,
      lineColor,
      backgroundColor,
    };
  }, [lineWidth, lineColor, backgroundColor]);

  const callbacks = useMemo(() => {
    return {
      updateLineWidth: (value: number) => {
        setLineWidth(value);
      },
      updateLineColor: (value: string) => {
        setLineColor(value);
      },
      updateBackgroundColor: (value: string) => {
        setBackgroundColor(value);
      },
    };
  }, [setLineWidth, setLineColor, setBackgroundColor]);

  return [parameters, callbacks];
};
