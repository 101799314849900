import { DOMAIN_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import ErrorMessage from "utils/ErrorMessage";

const DOMAIN_PATTERN = /^[a-z0-9.-]+\.[a-z]{2,}$/i;

class DomainValidator {
  static isValidDomain(domain: string): boolean {
    // FIXME: 正しいドメイン形式かチェックする処理を書く
    if (domain.length <= 0) {
      return false;
    }
    return DOMAIN_PATTERN.test(domain);
  }
}

// FIXME: モデル側の責務がここにあるのはおかしい、けどここじゃないとエラー時の通知レンダリングができない。
// TODO: とりあえずバリデーションクラスは外だしする。
export class DomainFormValidator {
  run(domain: string): ErrorMessage[] {
    const errors: ErrorMessage[] = [];
    if (domain.length <= 0) {
      errors.push(new ErrorMessage(DOMAIN_ERROR_MESSAGE_LIST.MUST_BE_FILLED));
    }

    if (!DomainValidator.isValidDomain(domain)) {
      errors.push(
        new ErrorMessage(DOMAIN_ERROR_MESSAGE_LIST.INVALID_URL_FORMAT)
      );
    }
    return errors;
  }
}
