import { MeasurementUrl } from "types/measurement_urls";

export class MeasurementUrlTranslator {
  QUERY_PARAMETER_BLANK_VALUE_FILLER = "target_push_test";

  toUrl(measurementUrl: MeasurementUrl): URL {
    let url = new URL(measurementUrl.locations[0].value);

    measurementUrl.parameters.forEach((parameter) => {
      url.searchParams.set(
        parameter.key,
        parameter.value ?? this.QUERY_PARAMETER_BLANK_VALUE_FILLER
      );
    });

    return url;
  }
}
