import CreateActionDetailTableElement from "app/creators/actions/CreateActionDetailTable";
import HeadlineText from "atoms/HeadlineText";
import LoadingIcon from "atoms/LoadingIcon";
import { ACTION_STATUS } from "interfaces/models";
import { ActionCreationPresetFormParameters } from "interfaces/view/actionCreation";
import CompletePhaseDetailTableView from "organisms/account/action_creation/complete/CompletePhaseDetailTableView";
import CompletePhaseFormMainView from "organisms/account/action_creation/complete/CompletePhaseFormMainView";
import LoadingView from "organisms/account/LoadingView";
import CreateContentsPreviewArea, {
  ActionContentsPreviewParameters,
} from "../../CreateContentsPreviewArea";

type CreateCompletePhaseFormMainProps = {
  params: ActionCreationPresetFormParameters;
  preview: ActionContentsPreviewParameters;
};

const CreatePresetCompletePhaseFormMain = ({
  params,
  preview,
}: CreateCompletePhaseFormMainProps) => {
  if (!params.createdAction) {
    return (
      <LoadingView
        iconElement={<LoadingIcon />}
        textElement={
          <div>
            おもてなしアクション「{params.settings.name}」を
            {ACTION_STATUS.ACTIVE ? "有効" : "一時停止"}で保存しています
          </div>
        }
      />
    );
  }

  const activeTitle = "おもてなしアクションが有効で保存されました";
  const testTitle = "おもてなしアクションが一時停止で保存されました";

  const headlineText =
    params.settings.status === ACTION_STATUS.ACTIVE ? activeTitle : testTitle;

  return (
    <CreateActionDetailTableElement
      actionDetail2={params.createdAction}
      isDisplayPopUp={false}
      render={(actionDetailTableElement) => {
        return (
          <CompletePhaseFormMainView
            previewAreaElement={<CreateContentsPreviewArea {...preview} />}
            settingDetailsElement={
              <CompletePhaseDetailTableView
                headlineElement={<HeadlineText text={headlineText} />}
                detailTableElement={actionDetailTableElement}
              />
            }
          />
        );
      }}
    />
  );
};

export default CreatePresetCompletePhaseFormMain;
