import styles from "./SegmentationRuleView.module.scss";

interface SegmentationRuleViewProps {
  errorMessageElement: JSX.Element;
  headerElement: JSX.Element;
  actionTableElement: JSX.Element;
}

/**
 * FIXME: Rename to ListView
 */
const SegmentationRuleView = ({
  errorMessageElement,
  headerElement,
  actionTableElement,
}: SegmentationRuleViewProps) => {
  return (
    <>
      {errorMessageElement}
      <div className={styles.segmentationRuleView}>
        <div className={styles.header}>{headerElement}</div>
        <div className={styles.contents}>{actionTableElement}</div>
      </div>
    </>
  );
};

export default SegmentationRuleView;
