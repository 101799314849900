import { ActionStatus } from "interfaces/models";
import ReactTooltip from "react-tooltip";

const ToolTipActionStatusText = ({
  value,
  toolTipText,
}: {
  value: ActionStatus;
  toolTipText: string;
}) => {
  const options = [
    { value: "ACTIVE", label: "有効" },
    { value: "TESTING", label: "一時停止" },
  ];

  const currentStatus = options.filter((o) => o.value === value);
  return (
    <div>
      <div data-tip data-for="tooltip">
        {currentStatus[0].label}
      </div>
      <ReactTooltip id="tooltip" place="right" effect="float">
        <p>{toolTipText}</p>
      </ReactTooltip>
    </div>
  );
};

export default ToolTipActionStatusText;
