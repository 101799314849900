import { useLocation } from "react-router-dom";
import {
  SEGMENTATION_RULE_EDITOR_MODE,
  SegmentationRuleEditorMode,
  V2_ROUTING_PATH_PARAMETERS,
} from "app/system_defaults/v2_routing";
import { useMemo } from "react";

export type SegmentationRuleEditPageModeParameters = {
  mode: SegmentationRuleEditorMode;
  destinationCollectRuleUuid: string | null;
};

const useSegmentationRuleEditPageModeParameters =
  (): SegmentationRuleEditPageModeParameters => {
    const location = useLocation();

    const searchParams = useMemo(
      () => new URLSearchParams(location.search),
      [location.search]
    );

    return {
      mode:
        (searchParams.get(
          V2_ROUTING_PATH_PARAMETERS.ACCOUNT.SEGMENTATION_RULE.EDIT.MODE
        ) as SegmentationRuleEditorMode) ??
        SEGMENTATION_RULE_EDITOR_MODE.CREATE,
      destinationCollectRuleUuid: searchParams.get(
        V2_ROUTING_PATH_PARAMETERS.ACCOUNT.SEGMENTATION_RULE.EDIT
          .DESTINATION_COLLECT_RULE_UUID
      ),
    };
  };

export default useSegmentationRuleEditPageModeParameters;
