const isFilledUrlCondition = (url: ManagementApiData.UrlMatchPattern) => {
  return url.locations.length > 0 || url.parameters.length > 0;
};

export const filterUrlCondition = (
  condition: ActionCreation.UrlCondition
): ActionCreation.UrlCondition => {
  return {
    includes: condition.includes.filter((e) => isFilledUrlCondition(e)),
    excludes: condition.excludes.filter((e) => isFilledUrlCondition(e)),
  };
};
