import {
  Action,
  ActionNumber,
  ActionNumberResponse,
  ActionSummary,
  SegmentationRule,
  SegmentationRuleDetail,
  SegmentationRuleDetailResponse,
  ActionStatus,
} from "interfaces/models";
import {
  ActionCreationRequest,
  ActionUpdateRequest,
} from "interfaces/requests";
import RestApi, { AsyncProcessStatus, ASYNC_PROCESS_STATUS } from "./RestApi";
import sleep from "./Sleep";

export type ActionDetail2 = {
  action: Action;
  actionNumber: ActionNumber;
  segmentationRules: SegmentationRule[];
  segmentationRuleDetails: SegmentationRuleDetail[];
};

export class ActionRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(actionId: string): Promise<ActionDetail2 | undefined> {
    // FIXME: call api 'actions/{action_uuid}' once

    const data: { actions: Action[] } = await this.managementApi.get("actions");
    const actions: Action[] = data.actions.filter(
      (action) => action.action_id === actionId
    );
    const action = actions.length ? actions[0] : null;
    if (!action) {
      return;
    }
    const actionNumbers: ActionNumberResponse = await this.managementApi.get(
      "action_numbers"
    );
    const segmentationRuleIds =
      action.execution_conditions.segmentation_rule_ids;
    const { segmentation_rules }: { segmentation_rules: SegmentationRule[] } =
      await this.managementApi.get("segmentation_rules");
    const segmentationRules: SegmentationRule[] = segmentation_rules.filter(
      (r) => segmentationRuleIds.includes(r.segmentation_rule_id)
    );
    const segmentationRuleDetailResponse: SegmentationRuleDetailResponse =
      await this.managementApi.get("segmentation_rule_details");
    const tmp: SegmentationRuleDetail[] = segmentationRuleIds.map(
      (id) => segmentationRuleDetailResponse[id]
    );

    return {
      action: action,
      actionNumber: actionNumbers[actionId],
      segmentationRules: segmentationRules,
      segmentationRuleDetails: tmp || [],
    };
  }

  async load(): Promise<Action[]> {
    try {
      const data: { actions: Action[] } = await this.managementApi.get(
        "actions"
      );

      return data.actions;
    } catch (error) {
      throw new Error(`Failed to load account numbers: ${error.message}`);
    }
  }

  async add(actionSettings: ActionCreationRequest): Promise<any> {
    return this.managementApi.post("actions", actionSettings);
  }

  async delete(actionId: string): Promise<void> {
    await this.managementApi.delete(`actions/${actionId}`);
  }

  async update(actionId: string, request: ActionUpdateRequest): Promise<any> {
    return await this.managementApi.put(`actions/${actionId}`, request);
  }

  async updateStatus(actionId: string, newStatus: ActionStatus): Promise<void> {
    await this.managementApi.patch(`actions/${actionId}`, {
      status: newStatus,
    });
  }

  async loadSummaries(
    startDatetime: Date,
    endDatetime: Date
  ): Promise<{ [actionId: string]: ActionSummary }> {
    const isActionSummaryRequestResponse = (
      obj: any
    ): obj is ActionSummaryRequestResponse => {
      return obj.queue_id !== undefined;
    };

    type ActionSummaryLoadResponse = {
      status: AsyncProcessStatus;
      results: { [actionId: string]: ActionSummary };
    };

    type ActionSummaryRequestResponse = {
      queue_id: string;
    };

    const params: { [key: string]: string } = {
      start_datetime: startDatetime.getTime().toString(),
      end_datetime: endDatetime.getTime().toString(),
    };

    const queue: ActionSummaryRequestResponse | ActionSummaryLoadResponse =
      await this.managementApi.post("action_summaries/request", params);
    if (isActionSummaryRequestResponse(queue)) {
      const startTimestamp = +new Date();
      while (+new Date() - startTimestamp < 60 * 60 * 1000) {
        const results: ActionSummaryLoadResponse = await this.managementApi.get(
          `action_summaries/request/${queue.queue_id}`
        );
        switch (results.status) {
          case ASYNC_PROCESS_STATUS.QUEUED:
          case ASYNC_PROCESS_STATUS.RUNNING:
            await sleep(1000);
            break;
          case ASYNC_PROCESS_STATUS.CANCELLED:
          case ASYNC_PROCESS_STATUS.SUCCEEDED:
            return results.results;
          default:
            ((_s: never) => {})(results.status);
            throw new Error(`unexpected status ${results.status}`);
        }
      }
      throw new Error(`Timeout`);
    } else {
      return queue.results;
    }
  }

  async sendImage(image: File): Promise<string> {
    return new Promise<string>((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = () => {
        this.managementApi
          .post("actions/contents_image", {
            base64: reader.result,
            name: image.name,
            content_type: image.type,
          })
          .then((response) => {
            resolve(response["image_url"]);
          });
      };
    });
  }
}
