import { SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import {
  SEGMENTATION_RULE_EDITOR_MODE,
  SegmentationRuleEditorMode,
} from "app/system_defaults/v2_routing";
import { SEGMENTATION_RULE_FORM_VALIDATE_RULE } from "app/system_defaults/validateRule";
import {
  SegmentationRuleFormParameters,
  SegmentationRuleTreeParameter,
} from "interfaces/v2/segmentationRuleForm";
import ErrorMessage from "utils/ErrorMessage";
import { CONDITION_TYPE } from "./segmentationRuleTree";

class SegmentationRuleEditFormValidator {
  params: SegmentationRuleFormParameters;
  existNames: string[];
  mode: SegmentationRuleEditorMode;

  constructor(
    params: SegmentationRuleFormParameters,
    existNames: string[],
    mode: SegmentationRuleEditorMode
  ) {
    this.params = params;
    this.existNames = existNames;
    this.mode = mode;
  }

  isNameDuplication(name: string): boolean {
    if (this.mode === SEGMENTATION_RULE_EDITOR_MODE.EDIT) {
      // NOTE: 単純に既存リストと一致を見ると、編集前後で名前が一致している場合エラーになる
      // なので一度既存リストから対象を抜いて一致をみる
      if (this.existNames.filter((n) => n !== name).includes(name)) {
        return true;
      }
    } else {
      // NOTE: コピーと新規作成は既存リストと単純に一致をみる
      if (this.existNames.includes(name)) {
        return true;
      }
    }
    return false;
  }

  validateName(name: string): ErrorMessage[] {
    let errorMessages: ErrorMessage[] = [];

    if (name === "") {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.NAME.MUST_BE_FILLED
        )
      );
    }

    if (name.length > SEGMENTATION_RULE_FORM_VALIDATE_RULE.NAME.MAX_LENGTH) {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.NAME.CHARACTER_LIMIT
        )
      );
    }

    if (this.isNameDuplication(name)) {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.NAME.DUPLICATE
        )
      );
    }

    if (
      SEGMENTATION_RULE_FORM_VALIDATE_RULE.NAME.INVALID_FIRST_CHARACTER.test(
        name.charAt(0)
      )
    ) {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.NAME.FIRST_CHARACTER_IS_NOT_FIGURE
        )
      );
    }

    const reg = new RegExp(
      SEGMENTATION_RULE_FORM_VALIDATE_RULE.NAME.EMOJI_LIST.join("|"),
      "g"
    );

    if (reg.test(name)) {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.NAME.EMOJI_LIMIT
        )
      );
    }

    return errorMessages;
  }

  validateTree(tree: SegmentationRuleTreeParameter) {
    let errorMessages: ErrorMessage[] = [];
    if (
      tree.find((condition) => {
        if (condition.type === CONDITION_TYPE.TOTAL_PV_COUNT) {
          return condition.pages.find((page) => page.urlSetting.value !== "");
        } else {
          return false;
        }
      }) === undefined
    ) {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.CONDITIONS.MUST_BE_LEAST_ONE_CONDITION
        )
      );
    }

    if (
      tree.find((condition) => {
        if (condition.type === CONDITION_TYPE.SUBSCRIBE_TIMING) {
          return condition.parameters.days_ago === 0;
        } else {
          return false;
        }
      })
    ) {
      errorMessages.push(
        new ErrorMessage(
          SEGMENTATION_RULE_EDIT_ERROR_MESSAGE_LIST.CONDITIONS.MUST_BE_ABOVE_ZERO_DAY
        )
      );
    }

    return errorMessages;
  }

  validate(): ErrorMessage[] {
    return this.validateName(this.params.name).concat(
      this.validateTree(this.params.tree)
    );
  }
}

export default SegmentationRuleEditFormValidator;
