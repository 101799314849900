import {
  GrantMessageV2,
  GrantMessageUpdateRequestV2,
  GrantMessageGetRequestV2,
  GrantMessageLoadResponse,
  GrantMessageTypeUpdateRequestV2,
  GrantMessageUpdateRequestV3,
  GrantMessageDesignGenerateResponse,
} from "types/grant_message";
import RestApi from "../RestApi";

class GrantMessageV2Repository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async load(): Promise<GrantMessageLoadResponse> {
    const data = await this.managementApi.get("grant_message");
    return data;
  }

  async get(request: GrantMessageGetRequestV2): Promise<GrantMessageV2> {
    const data = await this.managementApi.get(
      `grant_message/${request.grant_message_type}`
    );
    return data;
  }

  async update(request: GrantMessageUpdateRequestV2): Promise<GrantMessageV2> {
    const data = await this.managementApi.put(
      `grant_message/${request.grant_message_type}`,
      request
    );
    return data;
  }

  async updateV3(
    request: GrantMessageUpdateRequestV3
  ): Promise<GrantMessageV2> {
    const data = await this.managementApi.put(
      `grant_message/v3/${request.grant_message_type}`,
      request
    );
    return data;
  }

  async updateType(
    request: GrantMessageTypeUpdateRequestV2
  ): Promise<GrantMessageV2> {
    const data = await this.managementApi.patch(
      `grant_message/${request.grant_message_type}`,
      request
    );
    return data;
  }

  async getDesign(request: GrantMessageUpdateRequestV3): Promise<string> {
    const data: GrantMessageDesignGenerateResponse =
      await this.managementApi.post(
        `grant_message/design/${request.grant_message_type}`,
        request
      );
    return data.design;
  }
}

export default GrantMessageV2Repository;
