import { useState, useCallback, useEffect } from "react";
import { Domain } from "types/domain";
import RestApi from "utils/RestApi";
import DomainRepository from "utils/repositories/DomainRepository";

export type DomainConfigurationParameters = {
  domains: Domain[] | null;
};

export type DomainConfigurationCallbacks = {
  load: () => void;
  create: (domain: string) => Promise<boolean>;
  delete: (domainUuid: string) => Promise<void>;
};

export const useDomainConfigurationParameters = (
  managementApi: RestApi
): [DomainConfigurationParameters, DomainConfigurationCallbacks] => {
  const [domains, setDomains] = useState<Domain[] | null>(null);

  const loadDomains = useCallback(async () => {
    const repository = new DomainRepository(managementApi);
    const response = await repository.get();
    setDomains(response.items.sort((a, b) => a.created_at - b.created_at));
  }, [managementApi, setDomains]);

  const createDomain = useCallback(
    async (domain: string): Promise<boolean> => {
      const repository = new DomainRepository(managementApi);

      return repository
        .create({
          domain,
          is_subscribe: false,
        })
        .then(() => {
          loadDomains();
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [managementApi, loadDomains]
  );

  const deleteDomain = useCallback(
    async (domainUuid: string) => {
      const repository = new DomainRepository(managementApi);
      await repository.delete(domainUuid);
      loadDomains();
    },
    [managementApi, loadDomains]
  );

  useEffect(() => {
    loadDomains();
  }, [loadDomains]);

  return [
    { domains },
    {
      load: loadDomains,
      create: createDomain,
      delete: deleteDomain,
    },
  ];
};
