type ActionCreationViewProps = {
  headerElement?: JSX.Element;
  formElement: JSX.Element;
};

const ActionCreationView = ({
  headerElement,
  formElement,
}: ActionCreationViewProps) => (
  <div>
    {headerElement ? <div>{headerElement}</div> : null}
    <div>{formElement}</div>
  </div>
);

export default ActionCreationView;
