import styles from "./TileLayoutView.module.scss";

type TileLayoutViewProps = {
  titleElement: JSX.Element;
  tileElements: JSX.Element[];
};

const TileLayoutView = ({
  titleElement,
  tileElements,
}: TileLayoutViewProps) => {
  return (
    <div className={styles.tileLayoutView}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.tiles}>
        {tileElements.map((e, i) => (
          <div key={i} className={styles.tile}>
            {e}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TileLayoutView;
