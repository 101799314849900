import UrlLocationForm from "molecules/UrlForm/UrlLocationForm";
import UrlParameterForms from "molecules/SettingForm/UrlParameterForms";

import { useCallback, useEffect, useState } from "react";

import styles from "./UrlMatchPatternForm.module.scss";

//=============================================================================
// Type Definitions

type ParameterMatchMode = ManagementApiData.ParameterMatchMode;

type ParameterMatchPattern = {
  mode: ParameterMatchMode;
  keyValue: string;
};

type UrlMatchPattern = {
  locations: ManagementApiData.LocationMatchPattern[];
  parameters: ParameterMatchPattern[];
};

//=============================================================================
// constants

const INIT_PARAMETER_FORM_VALUE: ParameterMatchPattern = {
  mode: "include",
  keyValue: "",
};

const INIT_URL_FORM_VALUE: UrlMatchPattern = {
  locations: [],
  parameters: [INIT_PARAMETER_FORM_VALUE],
};

//=============================================================================
// utility functions

const ParameterCodec = {
  encode: (
    v: ParameterMatchPattern
  ): ManagementApiData.ParameterMatchPattern | null => {
    const buf = v.keyValue.split("=");

    if (buf.length < 2) {
      return null;
    }

    return {
      key: buf[0],
      value: buf[1],
      pattern: v.mode,
    };
  },

  decode: (p: ManagementApiData.ParameterMatchPattern) => ({
    mode: p.pattern,
    keyValue: `${p.key}=${p.value || ""}`,
  }),
};

const UrlMatchPatternCodec = {
  encode: (urlPattern: UrlMatchPattern): ManagementApiData.UrlMatchPattern => {
    return {
      locations: urlPattern.locations.filter((l) => !!l.value),
      parameters: urlPattern.parameters
        .map(ParameterCodec.encode)
        .filter((p): p is ManagementApiData.ParameterMatchPattern => !!p),
    };
  },
  decode: (initValue: ManagementApiData.UrlMatchPattern): UrlMatchPattern => {
    return {
      locations: initValue.locations,
      parameters: initValue.parameters.length
        ? initValue.parameters.map(ParameterCodec.decode)
        : [INIT_PARAMETER_FORM_VALUE],
    };
  },
};

//=============================================================================
// Rendering Components

const UrlMatchPatternForm: React.VFC<{
  initValue?: ManagementApiData.UrlMatchPattern;
  onChange: (p: ManagementApiData.UrlMatchPattern) => void;
  isActive?: boolean;
}> = ({ initValue, onChange, isActive = true }) => {
  const [urlPattern, setUrlPattern] = useState<UrlMatchPattern>(
    initValue ? UrlMatchPatternCodec.decode(initValue) : INIT_URL_FORM_VALUE
  );

  const updateInitValue = useCallback(() => {
    if (initValue) {
      setUrlPattern(UrlMatchPatternCodec.decode(initValue));
    }
  }, [initValue]);

  useEffect(() => {
    updateInitValue();
  }, [updateInitValue]);

  const update = useCallback(
    (value: UrlMatchPattern) => {
      setUrlPattern(value);
      onChange(UrlMatchPatternCodec.encode(value));
    },
    [onChange, setUrlPattern]
  );

  // --------------------------------------------------------
  // Rendering

  return (
    <div
      className={
        isActive ? styles.locationsFormView : styles.locationsFormView__disabled
      }
    >
      <div className={styles.urlInputArea}>
        <div className={styles.urlInput}>
          <div className={styles.location}>
            <UrlLocationForm
              location={urlPattern.locations[0] || null}
              onChange={(l) => update({ ...urlPattern, locations: [l] })}
              isActive={isActive}
            />
          </div>
          <UrlParameterForms
            state={{
              deleteForm: (parameterIndex: number) =>
                update({
                  ...urlPattern,
                  parameters: urlPattern.parameters.filter(
                    (_, i) => i !== parameterIndex
                  ),
                }),
              addForm: () => {
                update({
                  ...urlPattern,
                  parameters: urlPattern.parameters.concat([
                    INIT_PARAMETER_FORM_VALUE,
                  ]),
                });
              },
              items: urlPattern.parameters.map((p, pIdx) => ({
                keyValue: p.keyValue,
                option: p.mode,
                onChangeKeyValue: (value) => {
                  update({
                    ...urlPattern,
                    parameters: urlPattern.parameters.map((e, i) =>
                      i === pIdx
                        ? {
                            keyValue: value,
                            mode: e.mode,
                          }
                        : e
                    ),
                  });
                },
                onChangeOption: (value) => {
                  update({
                    ...urlPattern,
                    parameters: urlPattern.parameters.map((e, i) =>
                      i === pIdx
                        ? {
                            keyValue: e.keyValue,
                            mode: value,
                          }
                        : e
                    ),
                  });
                },
              })),
              isActive: isActive,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UrlMatchPatternForm;
