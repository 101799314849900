type SearchParameter = {
  key: string;
  value: string;
};

export type CvUrl = {
  location: string;
  searchParams: SearchParameter[];
};

class CvUrlCodec {
  static decode(url: string) {
    const splitted_url = url.split("?");
    if (splitted_url.length === 2) {
      const location = splitted_url[0];
      const params = splitted_url[1].split("&");

      return {
        location: location,
        searchParams: params.map((e) => {
          const keyValue = e.split("=");
          return {
            key: keyValue[0],
            value: keyValue[1],
          };
        }),
      };
    } else {
      if (url.includes("=")) {
        const params = url.split("&");
        return {
          location: "",
          searchParams: params.map((e) => {
            const keyValue = e.split("=");
            return {
              key: keyValue[0],
              value: keyValue[1],
            };
          }),
        };
      } else {
        return {
          location: url,
          searchParams: [],
        };
      }
    }
  }
}

export default CvUrlCodec;
