import React from "react";
import styles from "./PanelListLayout.module.scss";

const PanelListLayout: React.VFC<{
  titleElement: JSX.Element;
  panelElements: JSX.Element[];
}> = ({ titleElement, panelElements }) => {
  return (
    <div className={styles.panelListLayout}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.panels}>
        {panelElements.map((e, i) => (
          <div key={i} className={styles.panel}>
            {e}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PanelListLayout;
