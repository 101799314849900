import Footer from "organisms/account/Footer";
import LinkText from "atoms/LinkText";

const BuildFooter = () => {
  const elements = [
    <div>Copyright © YRGLM Inc. All Rights Reserved.</div>,
    <LinkText
      text="プライバシーポリシー"
      link="https://www.yrglm.co.jp/policy/"
      external={true}
    />,
    <LinkText
      text="利用規約"
      link="https://www.ez-cx.com/terms/"
      external={true}
    />,
  ];

  return <Footer elements={elements} />;
};

export default BuildFooter;
