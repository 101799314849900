import styles from "./NumberInput.module.scss";

const getStyle = (
  isDisabled: boolean | undefined,
  isError: boolean | undefined
) => {
  if (isDisabled) {
    return styles.input__disabled;
  } else {
    if (isError) {
      return styles.input__error;
    } else {
      return styles.input;
    }
  }
};

type NumberInputProps = {
  value?: number;
  placeholder?: string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  isError?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
};

const NumberInput = ({
  value,
  placeholder,
  onChange,
  onBlur,
  isError,
  isDisabled,
  maxLength,
}: NumberInputProps) => {
  return (
    <input
      className={getStyle(isDisabled, isError)}
      type="text"
      maxLength={maxLength}
      value={value}
      onChange={(e) => {
        const num = Number(e.target.value);
        if (onChange && !isNaN(num)) {
          onChange(num);
        }
      }}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={isDisabled}
    />
  );
};

export default NumberInput;
