import React, { useMemo } from "react";
import {
  SegmentationRuleEditPageCallbacks,
  SegmentationRuleEditPageParameters,
} from "app/hooks/v2/segmentationRule/editor/useSegmentationRuleEditPage";

import fontStyles from "fontStyles.module.scss";

import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import TitleMainLayout from "templates/ezPush/TitleMainLayout";
import SegmentationRuleEditPanel from "./SegmentationRuleEditPanel";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";
import { SegmentationRuleEditorStatus } from "interfaces/v2/segmentationRuleForm";

const SegmentationRuleEditor: React.VFC<{
  parameters: SegmentationRuleEditPageParameters;
  callbacks: SegmentationRuleEditPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const titleElement = useMemo(
    () => (
      <HorizontalIconLayout
        elements={[
          <span className={fontStyles.title}>セグメント作成・編集</span>,
        ]}
      />
    ),
    []
  );

  const panelElement = useMemo(
    () =>
      parameters.editorStatus === SegmentationRuleEditorStatus.EDITABLE ? (
        <SegmentationRuleEditPanel
          parameters={parameters}
          callbacks={callbacks}
        />
      ) : (
        <LoadingView
          iconElement={<LoadingIcon />}
          textElement={<div>データを取得しています…</div>}
        />
      ),
    [parameters, callbacks]
  );

  return (
    <TitleMainLayout titleElement={titleElement} mainElement={panelElement} />
  );
};

export default SegmentationRuleEditor;
