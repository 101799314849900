import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import { randomBalancingARateOption } from "interfaces/models";

type ABRateFormProps = {
  abRate: number;
  setABRate: (value: number) => void;
};

const ABRateForm = ({ abRate, setABRate }: ABRateFormProps) => {
  const isDisabled =
    randomBalancingARateOption.filter((o) => o.value === abRate).length === 1
      ? false //management webで扱える値が保持されている時 選択可能
      : true; //management webで扱えない値が保持されている時 選択不可

  return (
    <div style={{ width: 200 }}>
      <BorderedSelectBox
        options={randomBalancingARateOption}
        value={abRate}
        onChange={(e) => setABRate(e)}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ABRateForm;
