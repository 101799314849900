import Select, { components, StylesConfig } from "react-select";
import dropdownIcon from "images/icons/icon_select_box_arrow.svg";
import activeDropdownIcon from "images/icons/icon_select_box_active_arrow.svg";
import styles from "./SelectWrap.module.scss";

export type OptionType = {
  value: string | number;
  label: string;
};

const DropdownIndicator = (props: any) => {
  const icon = props.selectProps.menuIsOpen ? activeDropdownIcon : dropdownIcon;

  return (
    <components.DropdownIndicator {...props}>
      <img src={icon} alt="" className={styles.dropdownIndicator} />
    </components.DropdownIndicator>
  );
};

const SelectWrap = (props: {
  options: readonly OptionType[];
  value?: OptionType[];
  onChange?: (event: any) => void;
  customStyles: StylesConfig;
  noOptionsMessage?: string;
  isDisabled?: boolean;
  placeholder?: string;
}) => {
  return (
    <Select
      styles={props.customStyles}
      components={{ DropdownIndicator }}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      isSearchable={false}
      maxMenuHeight={200}
      noOptionsMessage={() => {
        return props.noOptionsMessage ? props.noOptionsMessage : "No options";
      }}
      isDisabled={props.isDisabled ? props.isDisabled : false}
      placeholder={props.placeholder}
    />
  );
};

export default SelectWrap;
