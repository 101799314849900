import styles from "./GradationColorCircle.module.scss";
import WhiteCheckIcon from "images/icons/icon_white_check.svg";
import { ColorResult } from "react-color";

export const SPECIFIC_COLORS = ["#FFFFFF"];

type GradationColorCircleProps = {
  colors: ColorResult[];
  isActive: boolean;
  onChange: () => void;
};

const WhiteCheckElement = (
  <img className={styles.check} src={WhiteCheckIcon} alt="" />
);

const GradationColorCircle = ({
  colors,
  isActive,
  onChange,
}: GradationColorCircleProps) => {
  const circleColor: React.CSSProperties = {
    background: `linear-gradient(${colors.map((c) => c.hex).join(",")})`,
  };

  return (
    <div className={styles.gradationColorCircle} onClick={() => onChange()}>
      <div style={circleColor} className={styles.circle}>
        {isActive ? WhiteCheckElement : null}
      </div>
    </div>
  );
};

export default GradationColorCircle;
