import CalenderIcon from "images/icons/icon_calendar.svg";
import SelectBoxArrowIcon from "images/icons/icon_select_box_arrow.svg";
import SelectBoxActiveArrowIcon from "images/icons/icon_select_box_active_arrow.svg";
import styles from "./DatePickerOpener.module.scss";

const formatDate = (date: Date) => {
  const _month = ("0" + (date.getMonth() + 1)).slice(-2);
  const _date = ("0" + date.getDate()).slice(-2);
  return `${date.getFullYear()}/${_month}/${_date}`;
};

interface DatePickerOpenerProps {
  date: Date;
  isOpen: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const DatePickerOpener = ({ date, isOpen, onClick }: DatePickerOpenerProps) => {
  return (
    <button className={styles.dateRangePickerOpener} onClick={onClick}>
      <div className={styles.buttonLeft}>
        <div className={styles.icon}>
          <img src={CalenderIcon} alt="" />
        </div>
        <div className={styles.dateRange}>{formatDate(date)}</div>
      </div>
      <div className={styles.buttonRight}>
        <img
          src={isOpen ? SelectBoxActiveArrowIcon : SelectBoxArrowIcon}
          alt=""
        />
      </div>
    </button>
  );
};

export default DatePickerOpener;
