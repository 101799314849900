import EventText from "atoms/accountPagesShared/EventText";
import { ColorResult, CustomPicker, InjectedColorProps } from "react-color";
import Hue from "react-color/lib/components/common/Hue";
import Saturation from "react-color/lib/components/common/Saturation";
import styles from "./SimpleSliderColorPicker.module.scss";
import EZCXEditableInput from "./EditableInput";
import ColorCircle from "./ColorCircle";

type SimpleSliderColorPickerProps = {
  colorResult: ColorResult;
  onChangePicker: () => void;
};

const SimpleSliderColorPicker = (
  props: InjectedColorProps & SimpleSliderColorPickerProps
) => {
  return (
    <div className={styles.simpleSliderColorPicker}>
      <div className={styles.main}>
        <div className={styles.colorCircle}>
          <ColorCircle
            color={props.colorResult}
            isActive={false}
            onChange={() => {}}
          />
        </div>
        <div className={styles.sliders}>
          <div className={styles.hue}>
            <Hue
              {...props}
              onChange={(c, e) => (props.onChange ? props.onChange(c) : null)}
              pointer={() => {
                return <div className={styles.slider} />;
              }}
            />
          </div>
          <div className={styles.saturation}>
            <Saturation
              {...props}
              onChange={(c, e) => (props.onChange ? props.onChange(c) : null)}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.editableInput}>
          <EZCXEditableInput
            hex={props.hex}
            onChange={(c) => (props.onChange ? props.onChange(c) : null)}
          />
        </div>
        <div>
          <EventText text="標準色から選ぶ" onClick={props.onChangePicker} />
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(SimpleSliderColorPicker);
