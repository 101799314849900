import {
  ActionCreationCommonCallbacks,
  ActionCreationPhase,
  ACTION_CREATION_PHASE,
} from "interfaces/view/actionCreation";
import CreateActionCreationSettingEditStepBar from "app/creators/action_creation/CreateActionCreationSettingEditStepBar";
import ActionCreationForm from "organisms/account/action_creation/ActionCreationForm";
import CreateActionCreationHeader from "app/creators/action_creation/CreateActionCreationHeader";
import { createFooterButtons } from "app/creators/action_creation/CreateFooterButtons";
import ModeSelectButton from "atoms/accountPagesShared/ModeSelectButton";
import ModeSelectPhaseFormMain from "organisms/account/action_creation/mode_select/ModeSelectPhaseFormMain";
// import PresetIcon from "images/icons/action_creation/mode_select/preset_mode.svg";
import CustomIcon from "images/icons/action_creation/mode_select/custom_mode.svg";
import {
  ActionCreationPageState,
  ActionCreationPageViewCallbacks,
  PAGE_STATE,
} from "app/hooks/useActionCreationService";
import {
  ACTION_CREATION_MODE,
  ActionCreationMode,
} from "interfaces/model/actionContentsParameters";
import CreatePresetActionCreationFormMain from "app/creators/action_creation/preset/CreatePresetActionCreationFormMain";
import CreateCustomActionCreationFormMain from "app/creators/action_creation/custom/CreateCustomActionCreationFormMain";
import { createPhaseTransitionButtons } from "./CreatePhaseTransitionButtons";
import ErrorPhaseFormMain from "organisms/account/action_creation/error/ErrorPhaseFormMain";
import { notifyNever } from "utils/assertions";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";

type Creator = (params: {
  isEditMode: boolean;
  mode?: ActionCreationMode;
  phase: ActionCreationPhase;
  appState: ActionCreationPageState;
  appCallbacks: ActionCreationCommonCallbacks;
  // FIXME: this include business logic, remove this callbacks
  viewCallbacks: ActionCreationPageViewCallbacks;
  formState: ActionCreation.FormState;
}) => {
  formElement: JSX.Element;
  headerElement?: JSX.Element;
};

export const createAccountCreationViewParts: Creator = ({
  isEditMode,
  mode,
  phase,
  appState,
  appCallbacks,
  viewCallbacks,
  formState,
}) => {
  const footerButtons = createFooterButtons({
    pageState: appState.pageState,
    phase,
    callbacks: {
      onCanceled: appCallbacks.cancel,
      onClickReleaseButton: () => appCallbacks.submit(true),
      onClickUnreleaseButton: () => appCallbacks.submit(false),
      onClickBackToActionList: appCallbacks.moveToActionIndexPage,
    },
  });

  switch (appState.pageState) {
    case PAGE_STATE.INIT:
    case PAGE_STATE.LOADING:
      return {
        formElement: (
          <ActionCreationForm
            mainElement={
              <LoadingView
                iconElement={<LoadingIcon />}
                textElement={<div>データ読み込み中．．．</div>}
              />
            }
            buttonElements={footerButtons}
          />
        ),
      };
    case PAGE_STATE.LOADING_FAILED:
      return {
        formElement: (
          <ActionCreationForm
            mainElement={
              <ErrorPhaseFormMain text="このアクションは編集でサポートされていません" />
            }
            buttonElements={footerButtons}
          />
        ),
      };
    case PAGE_STATE.EDITABLE:
      if (phase === ACTION_CREATION_PHASE.MODE_SELECT || mode === undefined) {
        return {
          formElement: (
            <ActionCreationForm
              mainElement={
                <ModeSelectPhaseFormMain
                  buttons={[
                    // FIXME: プリセット画面を実装したらコメントアウトを外す
                    // <ModeSelectButton
                    //   onClick={() => appCallbacks.selectMode(ACTION_CREATION_MODE.PRESET)}
                    //   text="目的別テンプレートから作成"
                    //   img={<img src={PresetIcon} alt="" />}
                    // />,
                    <ModeSelectButton
                      onClick={() =>
                        appCallbacks.selectMode(ACTION_CREATION_MODE.CUSTOM)
                      }
                      text="カスタムで自由に作成"
                      img={<img src={CustomIcon} alt="" />}
                    />,
                  ]}
                />
              }
              buttonElements={footerButtons}
            />
          ),
        };
      } else {
        const headerElement = (
          <CreateActionCreationHeader
            isEditMode={isEditMode}
            phase={phase}
            errorMessage={appState.errorMessage}
          />
        );

        const { previousButton, nextButton } = createPhaseTransitionButtons({
          mode,
          phase,
          settingPhaseCategory: appState.settingPhaseCategory,
          onClickBackButtonAtFormatSelect: appCallbacks.resetMode,
          changePhase: appCallbacks.changePhase,
          changeSettingPhaseCategory:
            viewCallbacks.viewStateCallbacks.setSettingPhaseCategory,
        });

        return {
          headerElement,
          formElement: (
            <ActionCreationForm
              stepBarElement={
                phase === ACTION_CREATION_PHASE.SETTINGS_EDIT ? (
                  <CreateActionCreationSettingEditStepBar
                    mode={mode}
                    settingPhaseCategory={appState.settingPhaseCategory}
                  />
                ) : undefined
              }
              mainElement={
                mode === ACTION_CREATION_MODE.CUSTOM ? (
                  <CreateCustomActionCreationFormMain
                    appState={appState}
                    appCallbacks={appCallbacks}
                    viewCallbacks={viewCallbacks}
                    formState={formState}
                  />
                ) : (
                  <CreatePresetActionCreationFormMain
                    appState={appState}
                    appCallbacks={appCallbacks}
                    viewCallbacks={viewCallbacks}
                  />
                )
              }
              previousPhaseElement={previousButton}
              nextPhaseElement={nextButton}
              buttonElements={footerButtons}
            />
          ),
        };
      }
    default:
      notifyNever(appState.pageState);

      return {
        formElement: <></>,
      };
  }
};
