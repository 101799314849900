import { GrantMessage } from "types/grant_message";
import {
  GrantMessageFormCallbacks,
  GrantMessageFormParameters,
  useGrantMessageContents,
} from "./useGrantMessageContents";
import { useMemo, useState } from "react";
import { ACTION_STATUS } from "interfaces/models";
import { useHistory } from "react-router-dom";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import RestApi from "utils/RestApi";
import useGrantMessageEditEvents from "./useGrantMessageEditEvents";
import ErrorMessage from "utils/ErrorMessage";

export type GrantMessageEditPageViewParameters = {
  previewHtml: string;
  errorMessages: ErrorMessage[];
  isPublic: boolean;
};

export type GrantMessageEditPageEventCallbacks = {
  backToHome: () => void;
  save: (errorCallback: () => void) => void;
};

export type GrantMessageEditPageParameters = {
  data: GrantMessageFormParameters;
  view: GrantMessageEditPageViewParameters;
};

export type GrantMessageEditPageCallbacks = {
  data: GrantMessageFormCallbacks;
  event: GrantMessageEditPageEventCallbacks;
};

const useGrantMessageEditPage = (
  managementApi: RestApi,
  currentGrantMessage: GrantMessage,
  loadGrantMessages: () => void
): [GrantMessageEditPageParameters, GrantMessageEditPageCallbacks] => {
  const history = useHistory();

  const [isPublic] = useState<boolean>(
    currentGrantMessage.action.status === ACTION_STATUS.ACTIVE
  );

  const [grantMessageContents, grantMessageContentsCallbacks] =
    useGrantMessageContents(
      JSON.parse(currentGrantMessage.grantMessage.contents)
    );

  const [editEventParameters, editEventCallbacks] = useGrantMessageEditEvents(
    managementApi,
    grantMessageContents
  );

  const parameters: GrantMessageEditPageParameters = useMemo(() => {
    return {
      data: grantMessageContents,
      view: {
        previewHtml: editEventParameters.previewHtml,
        errorMessages: editEventParameters.errorMessages,
        isPublic: isPublic,
      },
    };
  }, [grantMessageContents, isPublic, editEventParameters]);

  const callbacks: GrantMessageEditPageCallbacks = {
    data: grantMessageContentsCallbacks,
    event: {
      backToHome: () => history.push(V2_ROUTING_TABLE.ACCOUNT.HOME),
      save: (errorCallback: () => void) => {
        if (editEventCallbacks.isValidGrantMessage(grantMessageContents)) {
          editEventCallbacks.saveGrantMessage(grantMessageContents).then(() => {
            loadGrantMessages();
            history.push(V2_ROUTING_TABLE.ACCOUNT.HOME);
          });
        } else {
          errorCallback();
        }
      },
    },
  };

  return [parameters, callbacks];
};

export default useGrantMessageEditPage;
