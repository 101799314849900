import styles from "./TextInput.module.scss";

const getStyle = (isError: boolean | undefined, isActive: boolean) => {
  if (isActive) {
    if (isError) {
      return styles.input__error;
    } else {
      return styles.input;
    }
  } else {
    return styles.input__unavailable;
  }
};

type InputProps = {
  value: string | number;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: any) => void;
  isError?: boolean;
  isActive?: boolean;
};

const TextInput = ({
  value,
  placeholder,
  onChange,
  onBlur,
  isError = false,
  isActive = true,
}: InputProps) => (
  <input
    className={getStyle(isError, isActive)}
    type="text"
    value={value}
    onChange={isActive ? onChange : () => {}}
    onBlur={isActive ? onBlur : () => {}}
    placeholder={placeholder}
  />
);

export default TextInput;
