import ToggleButton from "atoms/ezPushShared/accountPageShared/ToggleButton";
import styles from "./GrantMessagePublishStatus.module.scss";
import fontStyles from "fontStyles.module.scss";
import React from "react";

const GrantMessagePublishStatus: React.VFC<{
  isPublish: boolean;
  onToggle: () => void;
}> = ({ isPublish, onToggle }) => {
  return (
    <div className={styles.grantMessagePublishStatus}>
      <div className={styles.item}>
        <div className={fontStyles.text}>非公開</div>
      </div>
      <div className={styles.item}>
        <ToggleButton checked={isPublish} onChange={onToggle} />
      </div>
      <div className={styles.item}>
        <div className={fontStyles.text}>公開</div>
      </div>
    </div>
  );
};

export default GrantMessagePublishStatus;
