import { DeliveryScheduleRule } from "types/delivery_schedule";
import styles from "./DeliveryScheduleRuleDescription.module.scss";
import { DeliveryScheduleRuleInterpleter } from "utils/deliveryScheduleRule/DeliveryScheduleRuleInterpleter";

const DeliveryScheduleRuleDescription: React.VFC<{
  rule: DeliveryScheduleRule;
}> = ({ rule }): JSX.Element => {
  const interpreter = new DeliveryScheduleRuleInterpleter();

  const displayData = interpreter.interpleteBackendData(rule);

  return (
    <div className={styles.deliveryScheduleRuleDescription}>
      {displayData.map((data, index) => (
        <div key={index}>{data}</div>
      ))}
    </div>
  );
};

export default DeliveryScheduleRuleDescription;
