import React, { useState } from "react";
import styles from "./TooltipIcon.module.scss";
import ReactTooltip from "react-tooltip";
import { generateRandomId } from "utils/randomId/RandomId";

const TooltipIcon: React.VFC<{
  iconElement: JSX.Element;
  comment: string | JSX.Element;
}> = ({ iconElement, comment }) => {
  const [id] = useState<string>(generateRandomId());
  return (
    <div className={styles.tooltipIcon}>
      <ReactTooltip id={id} place="top" effect="solid">
        {comment}
      </ReactTooltip>
      <div data-tip data-for={id} className={styles.button}>
        {iconElement}
      </div>
    </div>
  );
};

export default TooltipIcon;
