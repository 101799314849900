import { useLocation } from "react-router-dom";
import {
  PUSH_NOTIFICATION_EDITOR_MODE,
  PushNotificationEditorMode,
  PushNotificationGenreListType,
  V2_ROUTING_PATH_PARAMETERS,
} from "app/system_defaults/v2_routing";
import { useMemo } from "react";

export type PushNotificationEditSearchParameters = {
  mode: PushNotificationEditorMode;
  genre: PushNotificationGenreListType;
  campaignUuid: string | null;
  jobId: string | null;
};

const usePushNotificationEditSearchParameters =
  (): PushNotificationEditSearchParameters => {
    const location = useLocation();

    const searchParams = useMemo(
      () => new URLSearchParams(location.search),
      [location.search]
    );

    return {
      mode:
        (searchParams.get(
          V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.MODE
        ) as PushNotificationEditorMode) ??
        PUSH_NOTIFICATION_EDITOR_MODE.CREATE,
      genre: searchParams.get(
        V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.GENRE
      ) as PushNotificationGenreListType,
      campaignUuid: searchParams.get(
        V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.CAMPAIGN_UUID
      ),
      jobId: searchParams.get(
        V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.EDIT.JOB_ID
      ),
    };
  };

export default usePushNotificationEditSearchParameters;
