/**
 * return:
 * - success: string
 * - failure: null
 */
class UrlTranslator {
  encode(url: string): string | null {
    try {
      return new URL(url).toString();
    } catch (error) {
      return null;
    }
  }
}

export default UrlTranslator;
