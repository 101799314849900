import { useCallback, useEffect, useState } from "react";
import { GrantMessageSetting } from "types/grant_message";
import GrantMessageSettingRepository from "utils/repositories/GrantMessageSettingRepository";
import RestApi from "utils/RestApi";

const useGrantMessageSetting = (
  managementApi: RestApi
): GrantMessageSetting | undefined => {
  const [grantMessageSetting, setGrantMessageSetting] = useState<
    GrantMessageSetting | undefined
  >(undefined);

  const loadGrantMessageSetting = useCallback(async () => {
    setGrantMessageSetting(undefined);
    const grantMessageSettingRepository = new GrantMessageSettingRepository(
      managementApi
    );
    setGrantMessageSetting(await grantMessageSettingRepository.get());
  }, [managementApi]);

  useEffect(() => {
    loadGrantMessageSetting();
  }, [loadGrantMessageSetting]);

  return grantMessageSetting;
};

export default useGrantMessageSetting;
