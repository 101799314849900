import {
  Condition,
  ConditionTypes,
  CustomerAttributeCondition,
  CustomerTypeCondition,
  CustomerTypes,
  PageScrollRateCondition,
  PageStayingTimeCondition,
  SessionPvCountCondition,
  SessionScenarioPvCondition,
  SessionStayingTimeCondition,
  TotalPvCountCondition,
  AccessBlankCondition,
  UrlPattern,
  LocationPattern,
  TrackingAttributeCondition,
  WordCompareTypes,
  WordCompareTypesTypeLabels,
  PathParameterPattern,
  PathParameterComparePattern,
  PATH_PARAMETER_COMPARE_PATTERN,
  LOCATION_COMPARE_PATTERN,
  LocationComparePattern,
} from "interfaces/models";
import {
  ExternalData,
  ExternalDataComparePatternLabels,
  PvPattern,
  PvPatternLabels,
  SegmentationRuleCreationAppState,
  SegmentationRuleDescription,
  TargetUserLabels,
  UserAction,
  UserActionPattern,
} from "interfaces/view/segmentationCreation";
import {
  SegmentationRuleCreation,
  CURRENT_PAGE_BEHAVIOR_TYPE,
  INTEGER_COMPARE_TYPE,
  CUSTOMER_TYPE,
  SESSION_BEHAVIOR_TYPE,
  PAST_BEHAVIOR_TYPE,
  DATA_TYPE,
  WORD_COMPARE_TYPE,
  DATE_COMPARE_TARGET,
} from "interfaces/model/segmentationRuleCreation";

export type UrlDescription = {
  location: string[];
  parameters: string[];
};

export type SegmentationRuleDescriptionV2 = {
  name: string;
  currentPageBehaviorDescription: {
    urlDescription: UrlDescription | null;
    elements: string[];
  };
  sessionBehaviorDescription: {
    elements: {
      text: string;
      urlDescription: UrlDescription | null;
    }[];
  };
  pastBehaviorDescription: {
    elements: {
      text: string;
      urlDescription: UrlDescription | null;
    }[];
  };
  customerTypeDescription: {
    typeName: string;
  };
  customerAttributeDescription: {
    elements: string[];
  };
};

export class SegmentationRuleDescriptionV2Generator {
  static run(
    segmentationRuleCreation: SegmentationRuleCreation.SegmentationRuleCreation
  ): SegmentationRuleDescriptionV2 {
    return {
      name: segmentationRuleCreation.name,
      currentPageBehaviorDescription: {
        urlDescription: this.generateCurrentPageUrlDescription(
          segmentationRuleCreation.behavior.currentPageBehaviors
        ),
        elements: segmentationRuleCreation.behavior.currentPageBehaviors.map(
          (x) => this.generateCurrentPageBehaviorDescription(x)
        ),
      },
      sessionBehaviorDescription: {
        elements: segmentationRuleCreation.behavior.sessionBehaviors.map((x) =>
          this.generateSessionBehaviorDescription(x)
        ),
      },
      pastBehaviorDescription: {
        elements: segmentationRuleCreation.behavior.pastBehaviors.map((x) =>
          this.generatePastBehaviorDescription(x)
        ),
      },
      customerTypeDescription: {
        typeName: this.generateCustomerTypeLabel(
          segmentationRuleCreation.customer.customerType
        ),
      },
      customerAttributeDescription: {
        elements: segmentationRuleCreation.customer.customerAttributes.map(
          (x) => this.generateCustomerAttributeDescription(x)
        ),
      },
    };
  }

  static generateIntegerCompareTypeLabel(
    type: SegmentationRuleCreation.IntegerCompareType
  ): string {
    if (type === INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO) {
      return "以上";
    } else if (type === INTEGER_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO) {
      return "以下";
    } else if (type === INTEGER_COMPARE_TYPE.EQUAL_TO) {
      return "と等しい";
    }
    return "undefined";
  }

  static generateWordCompareTypeLabel(
    type: SegmentationRuleCreation.WordCompareType
  ): string {
    if (type === WORD_COMPARE_TYPE.EQUAL) {
      return "と一致する";
    } else if (type === WORD_COMPARE_TYPE.INCLUDE) {
      return "を含む";
    } else if (type === WORD_COMPARE_TYPE.DEEP_EQUAL) {
      return "と完全一致する";
    }
    return "undefined";
  }

  static generateDateTargetTypeLabel(
    target: SegmentationRuleCreation.DateCompareTarget,
    value: number
  ): string {
    if (value === 0) {
      if (target === DATE_COMPARE_TARGET.DAY) {
        return "今日";
      } else if (target === DATE_COMPARE_TARGET.MONTH) {
        return "今月";
      } else if (target === DATE_COMPARE_TARGET.YEAR) {
        return "今年";
      }
    }
    return "undefined";
  }

  static generateLocationPatternLabel(type: LocationComparePattern): string {
    if (type === LOCATION_COMPARE_PATTERN.INCLUDE) {
      return "を含む";
    } else if (type === LOCATION_COMPARE_PATTERN.EXCLUDE) {
      return "を除く";
    } else if (type === LOCATION_COMPARE_PATTERN.COMPLETE) {
      return "と一致する";
    }
    return "undefined";
  }

  static generatePathParameterComparePatternLabel(
    type: PathParameterComparePattern
  ): string {
    if (type === PATH_PARAMETER_COMPARE_PATTERN.INCLUDE) {
      return "を含む";
    } else if (type === PATH_PARAMETER_COMPARE_PATTERN.EXCLUDE) {
      return "を除く";
    }
    return "undefined";
  }

  static generateLocationDescription(locationPattern: LocationPattern): string {
    return `「${locationPattern.value}」${this.generateLocationPatternLabel(
      locationPattern.pattern
    )}`;
  }

  static generatePathParameterPatternDescription(
    pathParameterPattern: PathParameterPattern
  ): string {
    return `「${pathParameterPattern.key}=${
      pathParameterPattern.value
    }」${this.generatePathParameterComparePatternLabel(
      pathParameterPattern.pattern
    )}`;
  }

  static generateUrlDescription(urlPattern: UrlPattern): UrlDescription {
    return {
      location: urlPattern.location.map((x) =>
        this.generateLocationDescription(x)
      ),
      parameters: urlPattern.parameters.map((x) =>
        this.generatePathParameterPatternDescription(x)
      ),
    };
  }

  static generateCurrentPageUrlDescription(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): UrlDescription | null {
    for (let c of currentPageBehaviors) {
      if (c.url !== undefined) {
        return this.generateUrlDescription(c.url);
      }
    }
    return null;
  }

  static generateCurrentPageBehaviorDescription(
    currentPageBehavior: SegmentationRuleCreation.CurrentPageBehavior
  ): string {
    if (
      currentPageBehavior.behaviorType ===
        CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE &&
      currentPageBehavior.compareType != null
    ) {
      return `「スクロール量」が「${
        currentPageBehavior.value
      }%」${this.generateIntegerCompareTypeLabel(
        currentPageBehavior.compareType
      )}`;
    } else if (
      currentPageBehavior.behaviorType ===
        CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME &&
      currentPageBehavior.compareType != null
    ) {
      return `「滞在時間」が「${
        currentPageBehavior.value
      }秒」${this.generateIntegerCompareTypeLabel(
        currentPageBehavior.compareType
      )}`;
    } else if (
      currentPageBehavior.behaviorType ===
        CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK &&
      currentPageBehavior.compareType != null
    ) {
      return `「前回のページビューから」「${
        currentPageBehavior.value
      }日」${this.generateIntegerCompareTypeLabel(
        currentPageBehavior.compareType
      )}`;
    }
    return "";
  }

  static generateSessionBehaviorDescription(
    sessionBehavior: SegmentationRuleCreation.SessionBehavior
  ): {
    text: string;
    urlDescription: UrlDescription | null;
  } {
    if (sessionBehavior.behaviorType === SESSION_BEHAVIOR_TYPE.PV_COUNT) {
      return {
        text: `「ページの閲覧」が「${
          sessionBehavior.value
        }回」${this.generateIntegerCompareTypeLabel(
          sessionBehavior.compareType
        )}`,
        urlDescription: this.generateUrlDescription(sessionBehavior.url),
      };
    } else if (
      sessionBehavior.behaviorType === SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV
    ) {
      return {
        text: `「同一ページの連続閲覧」が「${
          sessionBehavior.value
        }回」${this.generateIntegerCompareTypeLabel(
          sessionBehavior.compareType
        )}`,
        urlDescription: this.generateUrlDescription(sessionBehavior.url),
      };
    } else if (
      sessionBehavior.behaviorType === SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT
    ) {
      return {
        text: `「総閲覧数」が「${
          sessionBehavior.value
        }回」${this.generateIntegerCompareTypeLabel(
          sessionBehavior.compareType
        )}`,
        urlDescription: null,
      };
    } else if (
      sessionBehavior.behaviorType === SESSION_BEHAVIOR_TYPE.STAYING_TIME
    ) {
      return {
        text: `「総滞在時間」が「${
          sessionBehavior.value
        }秒」${this.generateIntegerCompareTypeLabel(
          sessionBehavior.compareType
        )}`,
        urlDescription: null,
      };
    }
    return { text: "", urlDescription: null };
  }

  static generatePastBehaviorDescription(
    pastBehavior: SegmentationRuleCreation.PastBehavior
  ): {
    text: string;
    urlDescription: UrlDescription | null;
  } {
    if (pastBehavior.behaviorType === PAST_BEHAVIOR_TYPE.PV_COUNT) {
      return {
        text: `過去「${pastBehavior.rangeDays}日」以内の「ページの閲覧」が「${
          pastBehavior.value
        }回」${this.generateIntegerCompareTypeLabel(pastBehavior.compareType)}`,
        urlDescription: this.generateUrlDescription(pastBehavior.url),
      };
    } else if (pastBehavior.behaviorType === PAST_BEHAVIOR_TYPE.ALL_PV_COUNT) {
      return {
        text: `過去「${pastBehavior.rangeDays}日」以内の「総閲覧数」が「${
          pastBehavior.value
        }回」${this.generateIntegerCompareTypeLabel(pastBehavior.compareType)}`,
        urlDescription: null,
      };
    }
    return { text: "", urlDescription: null };
  }

  static generateCustomerTypeLabel(
    customerType: SegmentationRuleCreation.CustomerType
  ): string {
    if (customerType === CUSTOMER_TYPE.ALL) {
      return "全ユーザー";
    } else if (customerType === CUSTOMER_TYPE.EXISTING_USER_ONLY) {
      return "既存会員ユーザーのみ";
    } else if (customerType === CUSTOMER_TYPE.NO_MEMBER_USER_ONLY) {
      return "非会員ユーザーのみ";
    }
    return "undefined";
  }

  static generateCustomerAttributeDescription(
    customerAttribute: SegmentationRuleCreation.CustomerAttribute
  ): string {
    if (customerAttribute.operation.type === DATA_TYPE.INTEGER) {
      return `「${customerAttribute.key}」の数値が「${
        customerAttribute.operation.value
      }」${this.generateIntegerCompareTypeLabel(
        customerAttribute.operation.compareType
      )}`;
    } else if (customerAttribute.operation.type === DATA_TYPE.WORD) {
      return `「${customerAttribute.key}」の文字列が「${
        customerAttribute.operation.value
      }」${this.generateWordCompareTypeLabel(
        customerAttribute.operation.compareType
      )}`;
    } else if (
      customerAttribute.operation.type === DATA_TYPE.DATE &&
      customerAttribute.operation.value === 0
    ) {
      return `「${
        customerAttribute.key
      }」の日付が「${this.generateDateTargetTypeLabel(
        customerAttribute.operation.compareTarget,
        customerAttribute.operation.value
      )}」と一致する`;
    }
    return "undefined";
  }
}

const hoursToDays = (range_hours: number): number => {
  const ONE_DAY = 24; //hours
  return Math.floor(range_hours / ONE_DAY);
};

const getPeriodDescription = (range_hours: number): string => {
  if (range_hours) {
    const days = hoursToDays(range_hours);
    if (days >= 1) {
      return `過去${days}日間で`;
    } else {
      return `過去${range_hours}時間で`;
    }
  } else {
    return "全期間で";
  }
};

const getConditionUrl = (c: Condition): UrlPattern | null => {
  try {
    if (c.condition_type === ConditionTypes.SESSION_PV_COUNT) {
      const tmp = c as SessionPvCountCondition;
      return tmp.parameters.url;
    } else if (c.condition_type === ConditionTypes.SESSION_SCENARIO_PV) {
      const tmp = c as SessionScenarioPvCondition;
      return tmp.parameters.pattern[0];
    } else if (c.condition_type === ConditionTypes.SESSION_STAYING_TIME) {
      const tmp = c as SessionStayingTimeCondition;
      return tmp.parameters.url;
    } else if (c.condition_type === ConditionTypes.PAGE_STAYING_TIME) {
      const tmp = c as PageStayingTimeCondition;
      return tmp.parameters.url;
    } else if (c.condition_type === ConditionTypes.PAGE_SCROLL_RATE) {
      const tmp = c as PageScrollRateCondition;
      return tmp.parameters.url;
    } else if (c.condition_type === ConditionTypes.TOTAL_PV_COUNT) {
      const tmp = c as TotalPvCountCondition;
      return tmp.parameters.url;
    } else if (c.condition_type === ConditionTypes.ACCESS_BLANK) {
      return null;
    } else if (c.condition_type === ConditionTypes.CUSTOMER_TYPE) {
      return null;
    } else if (c.condition_type === ConditionTypes.CUSTOMER_ATTRIBUTE) {
      return null;
    } else if (c.condition_type === ConditionTypes.TRACKING_ATTRIBUTE) {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
  return null;
};

const getLocationPatternSuffix = (pattern: string) => {
  if (pattern === "prefix") {
    return "で始まる";
  } else if (pattern === "complete") {
    return "と一致する";
  } else if (pattern === "include") {
    return "を含む";
  } else if (pattern === "exclude") {
    return "を除く";
  }
  return "";
};

const getCompareTypeSuffix = (pattern: string) => {
  if (pattern === "greater_than_or_equal_to") {
    return "以上";
  } else if (pattern === "less_than_or_equal_to") {
    return "以下";
  } else if (pattern === "greater_than") {
    return "より多い";
  } else if (pattern === "less_than") {
    return "より少ない";
  } else if (pattern === "equal_to") {
    return "と等しい";
  }
  return "";
};

export const getLocationPatternDescription = (
  location: LocationPattern[],
  parameters: PathParameterPattern[]
): string[] => {
  const isBlankedParameter = parameters.every((e) => e.key === "");
  return location.map((l: LocationPattern) => {
    if (l.value === "*") {
      return isBlankedParameter ? "サイト全体" : "";
    }

    const suffix = getLocationPatternSuffix(l.pattern);

    if (l.value !== "") {
      return `「${l.value}」${suffix}`;
    } else {
      return "";
    }
  });
};

export const getSegmentationRuleLocations = (
  conditions: Condition[]
): string[] | null => {
  const urlPattern: UrlPattern | undefined = conditions
    .map((e) => getConditionUrl(e))
    .filter((e): e is Exclude<typeof e, null> => e !== null)[0];

  if (!urlPattern) {
    return null;
  }

  if (urlPattern.location) {
    return getLocationPatternDescription(
      urlPattern.location,
      urlPattern.parameters
    );
  }

  console.error("EZCX: Location of segmentation rule is invalid.");
  return null;
};

export const getUrlParameterDescription = (
  parameters: PathParameterPattern[]
): string[] => {
  return parameters.map((p) => {
    const suffix = getLocationPatternSuffix(p.pattern);
    if (p.value) {
      return `「${p.key}=${p.value}」${suffix}`;
    } else {
      return `「${p.key}」${suffix}`;
    }
  });
};

export const getSegmentationRuleUrlParameters = (
  conditions: Condition[]
): string[] | null => {
  const urlPattern: UrlPattern | null = getConditionUrl(conditions[0]);

  if (!urlPattern) {
    return null;
  }

  if (urlPattern.parameters.length > 0) {
    return getUrlParameterDescription(urlPattern.parameters);
  }

  return null;
};

export const getCustomerAttributeDescription = (
  condition: CustomerAttributeCondition
): string => {
  if (condition.parameters.operation.operation_type === "existance") {
    return `外部データの「${condition.parameters.key}」にデータが存在`;
  } else if (condition.parameters.operation.operation_type === "word") {
    const wordComparePattern: { [key: string]: string } = {
      [WordCompareTypes.EQUAL]:
        WordCompareTypesTypeLabels[WordCompareTypes.EQUAL],
      [WordCompareTypes.INCLUDE]:
        WordCompareTypesTypeLabels[WordCompareTypes.INCLUDE],
      [WordCompareTypes.MATCH_ANY]:
        WordCompareTypesTypeLabels[WordCompareTypes.MATCH_ANY],
    };

    const key: string = condition.parameters.key;
    const value: string = condition.parameters.operation.value;
    const compareType: string =
      wordComparePattern[condition.parameters.operation.compare_type];

    return `外部データの「${key}」が「${value}」${compareType}`;
  } else if (condition.parameters.operation.operation_type === "date") {
    const dateCompareTargetPattern: { [key: string]: string } = {
      year: "年",
      month: "月",
      day: "日",
    };

    const key: string = condition.parameters.key;
    const value: number = condition.parameters.operation.value;
    const compareTarget: string =
      dateCompareTargetPattern[condition.parameters.operation.compare_target];

    if (condition.parameters.operation.compare_type === "absolute") {
      return `外部データの「${key}」が${value}${compareTarget}`;
    } else if (condition.parameters.operation.compare_type === "relative") {
      const tmp =
        value === 0
          ? `当${compareTarget}`
          : value > 0
          ? `${value}${compareTarget}後`
          : `${value}${compareTarget}前`;
      return `外部データの「${key}」が${tmp}`;
    }
  } else if (condition.parameters.operation.operation_type === "integer") {
    const IntegerComparePattern: { [key: string]: string } = {
      greater_than_or_equal_to: "以上",
      less_than_or_equal_to: "以下",
      greater_than: "より多い",
      less_than: "より少ない",
      equal_to: "と等しい",
    };

    const key: string = condition.parameters.key;
    const value: number = condition.parameters.operation.value;
    const compareType: string =
      IntegerComparePattern[condition.parameters.operation.compare_type];

    return `外部データの「${key}」が${value}${compareType}`;
  }

  return "";
};

export const getDescription = (condition: Condition): string => {
  const conditionType =
    "condition_type" in condition ? condition["condition_type"] : null;

  // FIXME: asを使わない形に変更する
  if (conditionType === ConditionTypes.SESSION_PV_COUNT) {
    const sessionPvCountCondition = condition as SessionPvCountCondition;

    if (sessionPvCountCondition.parameters.operation) {
      return `同セッション内で${
        sessionPvCountCondition.parameters.operation.value
      }PV${getCompareTypeSuffix(
        sessionPvCountCondition.parameters.operation.compare_type
      )}`;
    } else {
      return `同セッション内で${sessionPvCountCondition.parameters.count}PV以上`;
    }
  } else if (conditionType === ConditionTypes.SESSION_SCENARIO_PV) {
    const sessionScenarioPvCondition = condition as SessionScenarioPvCondition;

    const count: number = sessionScenarioPvCondition.parameters.pattern.length;

    return `対象ページを${count}回連続で経由`;
  } else if (conditionType === ConditionTypes.SESSION_STAYING_TIME) {
    const sessionStayingTimeCondition =
      condition as SessionStayingTimeCondition;

    return `同セッション内で${sessionStayingTimeCondition.parameters.stay_seconds}秒以上滞在`;
  } else if (conditionType === ConditionTypes.PAGE_STAYING_TIME) {
    const pageStayingTimeCondition = condition as PageStayingTimeCondition;

    return `対象ページで${pageStayingTimeCondition.parameters.stay_seconds}秒以上滞在`;
  } else if (conditionType === ConditionTypes.PAGE_SCROLL_RATE) {
    const pageScrollRateCondition = condition as PageScrollRateCondition;

    return `対象ページで${
      pageScrollRateCondition.parameters.scroll_rate * 100
    }％以上スクロール`;
  } else if (conditionType === ConditionTypes.TOTAL_PV_COUNT) {
    const totalPvCountCondition = condition as TotalPvCountCondition;

    const prefix = totalPvCountCondition.parameters.range_hours
      ? getPeriodDescription(totalPvCountCondition.parameters.range_hours)
      : "";

    const suffix = totalPvCountCondition.parameters.operation
      ? getCompareTypeSuffix(
          totalPvCountCondition.parameters.operation.compare_type
        )
      : "と等しい";

    return `${prefix}対象ページを${totalPvCountCondition.parameters.count}PV${suffix}`;
  } else if (conditionType === ConditionTypes.CUSTOMER_TYPE) {
    const customerTypeCondition = condition as CustomerTypeCondition;

    if (
      customerTypeCondition.parameters.customer_type === CustomerTypes.SIGNED_UP
    ) {
      return "外部データとひも付き有り";
    } else if (
      customerTypeCondition.parameters.customer_type ===
      CustomerTypes.SIGNED_OUT
    ) {
      return "外部データとひも付き無し";
    }
  } else if (conditionType === ConditionTypes.CUSTOMER_ATTRIBUTE) {
    const customerAttributeCondition = condition as CustomerAttributeCondition;

    return getCustomerAttributeDescription(customerAttributeCondition);
  } else if (conditionType === ConditionTypes.ACCESS_BLANK) {
    const accessBlankCondition = condition as AccessBlankCondition;

    const days = hoursToDays(accessBlankCondition.parameters.min_range_hours);

    if (days >= 1) {
      return `最終PVから${days}日以上経過`;
    } else {
      return `最終PVから${accessBlankCondition.parameters.min_range_hours}時間以上経過`;
    }
  } else if (conditionType === ConditionTypes.TRACKING_ATTRIBUTE) {
    const trackingAttributeCondition = condition as TrackingAttributeCondition;

    return `外部データの「${trackingAttributeCondition.parameters.key}」が${
      trackingAttributeCondition.parameters.operation.value
    }${getCompareTypeSuffix(
      trackingAttributeCondition.parameters.operation.compare_type
    )}`;
  }

  return "";
};

export const substrDescription = (
  text: string,
  len: number,
  truncation: string
): string => {
  if (truncation === undefined) {
    truncation = "";
  }
  let text_array = text.split("");
  let count = 0;
  let str = "";
  let i = 0;
  for (i = 0; i < text_array.length; i++) {
    var n = escape(text_array[i]);
    if (n.length < 4) count++;
    else count += 2;
    if (count > len) {
      return str + truncation;
    }
    str += text.charAt(i);
  }
  return text;
};

export const getDescriptions = (
  decodedSegmentation: SegmentationRuleCreationAppState
): SegmentationRuleDescription => {
  return {
    name: decodedSegmentation.name,
    targetPage: getUrlPatternDescriptions(decodedSegmentation.targetPage),
    targetUser: TargetUserLabels[decodedSegmentation.targetUser],
    userAction: decodedSegmentation.userActions.map((c) =>
      getUserActionDescription(c)
    ),
    externalData: decodedSegmentation.externalData.map((c) =>
      getExternalDataDescription(c)
    ),
  };
};

//////////////////////////////////////////
// SegmentationRuleApiState description //
//////////////////////////////////////////

export const getUrlPatternDescriptions = (
  url: UrlPattern
): {
  locations: string[];
  parameters: string[];
} => {
  const locations: string[] = getLocationPatternDescription(
    url.location,
    url.parameters
  );
  const parameters: string[] = getUrlParameterDescription(url.parameters);
  return {
    locations: locations,
    parameters: parameters,
  };
};

export const getUserActionDescription = (userAction: UserAction): string => {
  switch (userAction.type) {
    case UserActionPattern.PAGE_VIEW: {
      const optionalDescriptions = [PvPatternLabels[userAction.pvPattern]];
      if (
        userAction.pvPattern === PvPattern.INCLUDE_PAST &&
        userAction.range_days
      )
        optionalDescriptions.push(`過去${userAction.range_days}日以内)`);
      return `ページの閲覧${userAction.count}回${getCompareTypeSuffix(
        userAction.comparePattern
      )}(${optionalDescriptions.join(",")})`;
    }
    case UserActionPattern.STAYING_TIME: {
      return `滞在時間${userAction.seconds}秒以上`;
    }
    case UserActionPattern.PAGE_SCROLL:
      return `ページのスクロール${userAction.rate}%以上`;
    case UserActionPattern.LAST_PV_TIMING:
      return `最後の来訪から${userAction.days}日以上の間隔を空けて来訪した`;
    case UserActionPattern.TOTAL_CV_COUNT:
      return `購入${userAction.count}回${getCompareTypeSuffix(
        userAction.comparePattern
      )}`;
    case UserActionPattern.NO_ACTION:
    default:
      return "";
  }
};

export const getExternalDataDescription = (
  externalData: ExternalData
): string => {
  if (externalData.key.length > 0 && externalData.value.length > 0) {
    return `「${externalData.key}」が「${externalData.value}」${
      ExternalDataComparePatternLabels[externalData.compareType]
    }`;
  } else {
    return "";
  }
};
