import styles from "./ColorCircle.module.scss";
import GrayCheckIcon from "images/icons/icon_gray_check.svg";
import WhiteCheckIcon from "images/icons/icon_white_check.svg";
import { ColorResult } from "react-color";

export const SPECIFIC_COLORS = ["#FFFFFF"];

type ColorCircleProps = {
  color: ColorResult;
  isActive: boolean;
  onChange: () => void;
};

const GrayCheckElement = (
  <img className={styles.check} src={GrayCheckIcon} alt="" />
);
const WhiteCheckElement = (
  <img className={styles.check} src={WhiteCheckIcon} alt="" />
);

const ColorCircle = ({ color, isActive, onChange }: ColorCircleProps) => {
  const circleColor: React.CSSProperties = {
    backgroundColor: color.hex,
  };

  const isSpecific = SPECIFIC_COLORS.includes(color.hex);

  return (
    <div className={styles.colorCircle} onClick={onChange}>
      <div
        style={circleColor}
        className={isSpecific ? styles.circle__specific : styles.circle}
      >
        {isActive ? (isSpecific ? GrayCheckElement : WhiteCheckElement) : null}
      </div>
    </div>
  );
};

export default ColorCircle;
