import HeadlineText from "atoms/HeadlineText";
import Sentence from "atoms/authorizePagesShared/Sentence";
import OrangeWideButton from "atoms/ezPushShared/authPageShared/OrangeWideButton";
import AuthPageBaseV2 from "./AuthPageBase";
import AuthFormLayoutV2 from "templates/ezPush/auth/AuthFormLayoutV2";

const SignedOutPageV2 = ({
  onClickLoginButton,
}: {
  onClickLoginButton: () => void;
}) => {
  return (
    <AuthPageBaseV2
      formElement={
        <AuthFormLayoutV2
          headline={<HeadlineText text="ログアウトしました" />}
          sentence={
            <Sentence text="再度ご利用の場合は、以下よりログインください" />
          }
          button={
            <OrangeWideButton text="ログイン" onClick={onClickLoginButton} />
          }
        />
      }
    />
  );
};

export default SignedOutPageV2;
