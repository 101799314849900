import {
  BASIC_CONFIGURATION_TYPE,
  BasicConfigurationType,
  MeasurementUrlSettingType,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export type UrlParameters = {
  children: BasicConfigurationType;
  type: MeasurementUrlSettingType;
};

const getTabType = (pathname: string): BasicConfigurationType => {
  if (pathname === V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.ROOT) {
    return BASIC_CONFIGURATION_TYPE.DOMAIN;
  } else {
    return pathname.split("/").pop() as BasicConfigurationType;
  }
};

export const useUrlParameters = (): UrlParameters => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  return {
    children: getTabType(location.pathname),
    type: searchParams.get("type") as MeasurementUrlSettingType,
  };
};
