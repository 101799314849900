import React, { useMemo, useState } from "react";
import fontStyles from "fontStyles.module.scss";

import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import TextInput from "atoms/TextInput";
import ErrorMessages from "molecules/v2/ErrorMessages";
import ErrorMessage from "utils/ErrorMessage";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import { DomainFormValidator } from "app/process/basicConfiguration/domainValidator";
import LoadingIcon from "atoms/LoadingIcon";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import GrayBackPanel from "atoms/ezPushShared/accountPageShared/GrayBackPanel";
import { DOMAIN_CREATE_MODAL_DESCRIPTION } from "app/system_defaults/descriptionTexts";

const DomainCreateConfirmModal: React.VFC<{
  domain: string;
  goBack: () => void;
  register: () => void;
}> = ({ domain, goBack, register }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  return (
    <ElementModal
      title={"このドメイン設定を登録します。よろしいですか"}
      element={
        <GrayBackPanel
          text={<div className={fontStyles.boldText}>{domain}</div>}
        />
      }
      buttons={
        isProcessing
          ? [<LoadingIcon />]
          : [
              <TransparentButton text={"いいえ"} onClick={goBack} />,
              <OrangeButton
                text="はい"
                onClick={() => {
                  setIsProcessing(true);
                  register();
                }}
              />,
            ]
      }
    />
  );
};

const DomainCreateModal: React.VFC<{
  createDomain: (domain: string) => void;
  clear: () => void;
}> = ({ createDomain, clear }) => {
  const [isClickCreate, setIsClickCreate] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>("");

  const [errors, setErrors] = useState<ErrorMessage[]>([]);

  const errorMessageElement = useMemo(
    () => <ErrorMessages errors={errors} />,
    [errors]
  );

  const noteElement = useMemo(
    () => (
      <MultipleLineText
        texts={DOMAIN_CREATE_MODAL_DESCRIPTION.note.map((d) => (
          <div className={fontStyles.note}>{d}</div>
        ))}
      />
    ),
    []
  );

  const inputElement = useMemo(
    () => (
      <VerticalSpreadLayout
        margin={5}
        items={[
          <TextInput
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="example.com"
          />,
          noteElement,
        ]}
      />
    ),
    [domain, noteElement, setDomain]
  );

  const confirmModal = (
    <DomainCreateConfirmModal
      domain={domain}
      goBack={() => setIsClickCreate(false)}
      register={() => {
        // FIXME: モデル側の責務がここにあるのはおかしい、けどエラー時のレンダリングの都合でここで呼び出している
        // TODO: モーダルの中でエラーを表示する方法を考える
        const validator = new DomainFormValidator();
        const temp = validator.run(domain);
        if (temp.length > 0) {
          setErrors(temp);
          setIsClickCreate(false);
          return;
        }
        createDomain(domain);
      }}
    />
  );

  const inputModal = (
    <NotificationModal
      title="その他のドメイン登録"
      description={
        <VerticalSpreadLayout
          margin={10}
          items={
            errors.length > 0
              ? [errorMessageElement, inputElement]
              : [inputElement]
          }
        />
      }
      buttons={[
        <TransparentButton text={"キャンセル"} onClick={clear} />,
        <OrangeButton
          text={"登録"}
          onClick={() => {
            const validator = new DomainFormValidator();
            const temp = validator.run(domain);
            if (temp.length > 0) {
              setErrors(temp);
              return;
            } else {
              setIsClickCreate(true);
            }
          }}
        />,
      ]}
    />
  );

  return (
    <ModalBackground>
      {isClickCreate ? confirmModal : inputModal}
    </ModalBackground>
  );
};

export default DomainCreateModal;
