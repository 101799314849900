import React, { useMemo } from "react";

import styles from "./MarginSettingForm.module.scss";
import fontStyles from "fontStyles.module.scss";

import ToolTip from "atoms/accountPagesShared/ToolTip";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import NumberInput from "atoms/ezPushShared/NumberInput";

const MarginSettingForm: React.VFC<{
  topMargin: number;
  bottomMargin: number;
  updateTopMargin: (value: number) => void;
  updateBottomMargin: (value: number) => void;
}> = ({ topMargin, bottomMargin, updateTopMargin, updateBottomMargin }) => {
  const toolTip = useMemo(
    () => (
      <ToolTip
        id="displayPositionAdjustment"
        text={
          <MultipleLineText
            texts={[
              "許諾メッセージのポップアップ",
              "の表示位置を微調整できます。",
              "上下のうち余白を設けたい",
              "箇所へ数値をご入力ください。",
            ]}
          />
        }
      />
    ),
    []
  );

  const topMarginElement = useMemo(
    () => (
      <div className={styles.marginInputElement}>
        <div className={styles.position}>上</div>
        <div className={styles.input}>
          <NumberInput
            value={topMargin}
            onChange={(value) => updateTopMargin(value)}
            min={0}
            max={100}
          />
        </div>
        <div className={styles.unit}>
          <div className={fontStyles.note}>px</div>
        </div>
      </div>
    ),
    [topMargin, updateTopMargin]
  );

  const bottomMarginElement = useMemo(
    () => (
      <div className={styles.marginInputElement}>
        <div className={styles.position}>下</div>
        <div className={styles.input}>
          <NumberInput
            value={bottomMargin}
            onChange={(value) => updateBottomMargin(value)}
            min={0}
            max={100}
          />
        </div>
        <div className={styles.unit}>
          <div className={fontStyles.note}>px</div>
        </div>
      </div>
    ),
    [bottomMargin, updateBottomMargin]
  );

  return (
    <div className={styles.marginSettingForm}>
      <div className={styles.title}>表示位置の調整</div>
      <div className={styles.toolTip}>{toolTip}</div>
      <div className={styles.topInput}>{topMarginElement}</div>
      <div>{bottomMarginElement}</div>
    </div>
  );
};

export default MarginSettingForm;
