import React from "react";
import styles from "./TransparentButton.module.scss";
import fontStyles from "fontStyles.module.scss";

const TransparentButton: React.VFC<{
  text: string;
  onClick: () => void;
}> = ({ text, onClick }) => {
  return (
    <button
      type="button"
      className={styles.transparentButton}
      onClick={onClick}
    >
      <div className={fontStyles.text}>{text}</div>
    </button>
  );
};

export default TransparentButton;
