import ReactTooltip, { Place } from "react-tooltip";
import ToolTipIcon from "images/icons/icon_tooltip.svg";
import styles from "./ToolTip.module.scss";
import { useState } from "react";
import { generateRandomId } from "utils/randomId/RandomId";

type ToolTipProps = {
  text: string | JSX.Element;
  id?: string; // FIXME: Deprecated. Generate itself.
  place?: Place;
};

const ToolTip = ({ id, text, place }: ToolTipProps) => {
  const [toolTipId] = useState<string>(id ? id : generateRandomId());
  return (
    <div className={styles.toolTip}>
      <img
        data-tip
        data-for={toolTipId}
        src={ToolTipIcon}
        alt="(?)"
        className={styles.icon}
      />
      <ReactTooltip id={toolTipId} place={place} effect="float" html={false}>
        {text}
      </ReactTooltip>
    </div>
  );
};

export default ToolTip;
