import styles from "./PasswordForm.module.scss";

interface InputPasswordProps {
  label: string;
  text: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordForm = (props: InputPasswordProps) => {
  return (
    <div className={styles.passwordForm}>
      <label className={styles.label} htmlFor={props.label}>
        {props.text}
      </label>
      <input
        className={styles.form}
        name={props.label}
        type="password"
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
};

export default PasswordForm;
