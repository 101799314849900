import { ColorResult, HSLColor, RGBColor } from "react-color";

export const DEFAULT_BLACK: ColorResult = {
  hex: "#FFFFFF",
  hsl: { h: 0, s: 100, l: 0 },
  rgb: { r: 0, g: 0, b: 0, a: 1 },
};

const hexToRgb = (hex: string | undefined): RGBColor | undefined => {
  if (!hex) {
    return undefined;
  }

  if (hex.length === 4) {
    return {
      r: parseInt(hex.substring(1, 2), 16),
      g: parseInt(hex.substring(2, 3), 16),
      b: parseInt(hex.substring(3, 4), 16),
    };
  } else if (hex.length === 7) {
    return {
      r: parseInt(hex.substring(1, 3), 16),
      g: parseInt(hex.substring(3, 5), 16),
      b: parseInt(hex.substring(5, 7), 16),
    };
  } else {
    return undefined;
  }
};

const hexToHsl = (hex: string | undefined): HSLColor | undefined => {
  const rgbColor: RGBColor | undefined = hexToRgb(hex);

  if (!rgbColor) {
    return undefined;
  }

  const r = rgbColor.r / 255;
  const g = rgbColor.g / 255;
  const b = rgbColor.b / 255;

  let cmin = Math.min(r, g, b);
  let cmax = Math.max(r, g, b);
  let delta = cmax - cmin;

  let h,
    s,
    l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;

  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h: h, s: s, l: l };
};

export const hexToColor = (
  hex: string | undefined
): ColorResult | undefined => {
  if (!hex) {
    return undefined;
  }

  const hsl: HSLColor | undefined = hexToHsl(hex);
  const rgb: RGBColor | undefined = hexToRgb(hex);

  if (hsl && rgb) {
    return {
      hex: hex.toUpperCase(),
      hsl: hsl,
      rgb: rgb,
    };
  } else {
    return undefined;
  }
};
