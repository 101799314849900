import React, { useMemo } from "react";

import styles from "./PushNotificationTestSettingDescription.module.scss";
import fontStyles from "fontStyles.module.scss";

import CopyableLink from "atoms/ezPushShared/accountPageShared/CopyableLink";
import { GrantMessageType } from "types/grant_message";

const NOTICES = [
  "※他のブラウザやデバイスを登録される場合は、再度、手順1～3を操作してください。",
  "※テスト配信の登録を解除したい場合は、別途お問い合わせください。",
] as const;

type SentenceType = {
  messages: string[];
  url?: string;
  notes?: string[];
};

const PushNotificationTestSettingDescription: React.VFC<{
  subscribeUrl: string | null;
  testRegisterUrl: string | null;
  grantMessageType: GrantMessageType | undefined;
}> = ({ subscribeUrl, testRegisterUrl, grantMessageType }) => {
  const getGrantMessageSubscribeDescription = (
    gmt: GrantMessageType | undefined
  ) => {
    if (gmt === "custom") {
      return [
        "テスト配信したいブラウザで以下URLにアクセスします。",
        "許諾メッセージが表示されるので、メッセージ内のボタンをクリックし、",
        "次に表示されるメッセージ内の「許可」ボタンをクリックしてください。",
      ];
    } else if (gmt === "browser_default") {
      return [
        "テスト配信したいブラウザで以下URLにアクセスします。",
        "表示されるメッセージ内の「許可」ボタンをクリックしてください。",
      ];
    } else {
      return [];
    }
  };

  const getGrantMessageSubscribeNote = (gmt: GrantMessageType | undefined) => {
    if (gmt === "custom") {
      return [
        "※1回目のメッセージで表示されない場合は、ブラウザの通知設定で自社サイトからの通知を",
        "デフォルトに設定してください（chromeの場合は「権限をリセット」）。",
        "※2回目のメッセージが表示されない場合は、ブラウザによって自動的にブロックされる場合があります。",
        "ブラウザの通知設定から「許可する」に変更ください。",
      ];
    } else if (gmt === "browser_default") {
      return [
        "※メッセージが表示されない場合は、ブラウザによって自動的にブロックされる場合があります。",
        "ブラウザの通知設定から「許可する」に変更ください。",
      ];
    } else {
      return [];
    }
  };

  const sentences: SentenceType[] = useMemo(() => {
    return [
      {
        messages: getGrantMessageSubscribeDescription(grantMessageType),
        notes: getGrantMessageSubscribeNote(grantMessageType),
        url: subscribeUrl ?? undefined,
      },
      {
        messages: ["1と同じブラウザで以下のURLにアクセスしてください。"],
        url: testRegisterUrl ?? undefined,
      },
      {
        messages: [
          "「Setup Complete」が表示されたら、テスト配信の設定は完了です。",
          "この画面の「閉じる」を押して編集画面に戻り、「下書き保存してテスト配信」ボタンより",
          "テスト配信を実施してください。",
        ],
        notes: [
          "「Setup Complete」が表示された場合は再度設定を行うか、別途お問い合わせください。",
        ],
      },
    ];
  }, [grantMessageType, subscribeUrl, testRegisterUrl]);

  const procedureElements = useMemo(() => {
    return sentences.map((elem, i) => (
      <div key={i} className={styles.item}>
        <div className={styles.number}>{i + 1}.</div>
        <div className={styles.sentence}>
          {elem.messages.map((sentence, j) => (
            <div key={j}>{sentence}</div>
          ))}
          {elem.url ? (
            <div className={styles.url}>
              <CopyableLink link={elem.url} />
            </div>
          ) : (
            <></>
          )}
          {elem.notes ? (
            elem.notes.map((note, k) => (
              <div key={k} className={styles.note}>
                <span className={fontStyles.note}>{note}</span>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    ));
  }, [sentences]);

  return (
    <div className={styles.pushNotificationTestSettingDescription}>
      <div className={styles.introduce}>
        ブラウザ・デバイスごとに一度だけ行ってください。
      </div>
      <div className={styles.procedure}>{procedureElements}</div>
      <div className={styles.notices}>
        {NOTICES.map((notice, i) => (
          <div key={i}>
            <span className={fontStyles.note}>{notice}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PushNotificationTestSettingDescription;
