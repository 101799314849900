import { useCallback, useEffect, useState } from "react";
import { DeliveryReportStatus } from "types/delivery_report";
import RestApi from "utils/RestApi";
import DeliveryReportRepository from "utils/repositories/DeliveryReportRepository";

const useAdminDeliveryReportStatus = (
  managementApi: RestApi
): [DeliveryReportStatus[] | null, () => void] => {
  const [reportStatus, setReportStatus] = useState<
    DeliveryReportStatus[] | null
  >(null);

  const loadDeliveryReportStatus = useCallback(async () => {
    setReportStatus(null);
    const repository = new DeliveryReportRepository(managementApi);
    const response = await repository.getAdmin();
    if (response) {
      setReportStatus(response.items);
    }
  }, [managementApi]);

  useEffect(() => {
    loadDeliveryReportStatus();
  }, [loadDeliveryReportStatus]);

  return [reportStatus, loadDeliveryReportStatus];
};

export default useAdminDeliveryReportStatus;
