import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SegmentationRuleEditRouter } from "app/process/segmentationRule/segmentationRuleEditRouter";
import DestinationCollectRuleRepository from "utils/repositories/DestinationCollectRuleRepository";
import RestApi from "utils/RestApi";
import useDraftCampaign from "../../pushNotification/useDraftCampaign";
import useScheduledCampaign from "../../pushNotification/useScheduledCampaign";

export type SegmentationRulePageEvents = {
  createRule: () => void;
  deleteRule: (destinationCollectRuleUuid: string) => Promise<void>;
  copyRule: (destinationCollectRuleUuid: string) => void;
  editRule: (destinationCollectRuleUuid: string) => void;
  getConnectedScheduledCampaigns: (
    destinationCollectRuleUuid: string
  ) => string[];
  getConnectedDraftCampaigns: (destinationCollectRuleUuid: string) => string[];
};

const useSegmentationRulePageEvents = (
  managementApi: RestApi,
  loadDestinationCollectRules: () => void
): SegmentationRulePageEvents => {
  const history = useHistory();

  const [scheduledCampaigns, loadScheduledCampaigns] =
    useScheduledCampaign(managementApi);
  const [draftCampaigns, loadDraftCampaigns] = useDraftCampaign(managementApi);

  useEffect(() => loadScheduledCampaigns(), [loadScheduledCampaigns]);
  useEffect(() => loadDraftCampaigns(), [loadDraftCampaigns]);

  const createRule = useCallback(() => {
    history.push(new SegmentationRuleEditRouter().create());
  }, [history]);

  const getConnectedScheduledCampaigns = useCallback(
    (destinationCollectRuleUuid: string): string[] => {
      if (scheduledCampaigns) {
        return scheduledCampaigns
          .filter(
            (e) =>
              e.destination_collect_rule.destination_collect_rule_uuid ===
              destinationCollectRuleUuid
          )
          .map((e) => e.name);
      }

      return [];
    },
    [scheduledCampaigns]
  );

  const getConnectedDraftCampaigns = useCallback(
    (destinationCollectRuleUuid: string): string[] => {
      if (draftCampaigns) {
        return draftCampaigns
          .filter(
            (e) =>
              e.destination_collect_rule.destination_collect_rule_uuid ===
              destinationCollectRuleUuid
          )
          .map((e) => e.name);
      }

      return [];
    },
    [draftCampaigns]
  );

  const deleteRule = useCallback(
    async (destinationCollectRuleUuid: string) => {
      return new Promise<void>((resolve, reject) => {
        new DestinationCollectRuleRepository(managementApi)
          .delete({
            destination_collect_rule_uuid: destinationCollectRuleUuid,
          })
          .then(() => {
            loadDestinationCollectRules();
            resolve();
          })
          .catch(reject);
      });
    },
    [managementApi, loadDestinationCollectRules]
  );

  const copyRule = useCallback(
    (destinationCollectRuleUuid: string) => {
      history.push(
        new SegmentationRuleEditRouter().copy(destinationCollectRuleUuid)
      );
    },
    [history]
  );

  const editRule = useCallback(
    (destinationCollectRuleUuid: string) => {
      history.push(
        new SegmentationRuleEditRouter().edit(destinationCollectRuleUuid)
      );
    },
    [history]
  );

  return {
    createRule,
    deleteRule,
    copyRule,
    editRule,
    getConnectedScheduledCampaigns,
    getConnectedDraftCampaigns,
  };
};

export default useSegmentationRulePageEvents;
