import errorIcon from "images/icons/icon_error.svg";
import styles from "./ErrorMessage.module.scss";

const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <div className={styles.errorMessage}>
      <img className={styles.icon} src={errorIcon} alt="ERROR : " />
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default ErrorMessage;
