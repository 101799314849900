import {
  GrantMessageType,
  GrantMessageUpdateRequestV3,
} from "types/grant_message";
import { GrantMessageFormParameters } from "./useGrantMessageContents";
import { useCallback, useEffect, useState } from "react";
import { ActionRepository } from "utils/ActionsRepository";
import RestApi from "utils/RestApi";
import ErrorMessage from "utils/ErrorMessage";
import GrantMessageFormValidator from "app/process/grantMessageFormValidator";
import GrantMessageV2Repository from "utils/repositories/GrantMessageV2Repository";

const GRANT_MESSAGE_UPDATE_REQUEST_INTERVAL = 1000;

const generateGrantMessageUpdateRequest = (
  imagePath: string | null,
  grantMessageContents: GrantMessageFormParameters
): GrantMessageUpdateRequestV3 => {
  const tmp = grantMessageContents;

  if (imagePath) {
    tmp.design.image.path = imagePath;
  }

  return {
    grant_message_type: "custom" as GrantMessageType,
    form: JSON.stringify(grantMessageContents),
  };
};

type GrantMessageEditEventParameters = {
  previewHtml: string;
  errorMessages: ErrorMessage[];
};

type GrantMessageEditEventCallbacks = {
  saveGrantMessage: (
    grantMessageFormParameters: GrantMessageFormParameters
  ) => Promise<void>;
  isValidGrantMessage: (params: GrantMessageFormParameters) => boolean;
};

const useGrantMessageEditEvents = (
  managementApi: RestApi,
  grantMessageContents: GrantMessageFormParameters
): [GrantMessageEditEventParameters, GrantMessageEditEventCallbacks] => {
  const [previewHtml, setPreviewHtml] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [designBuffer, setDesignBuffer] = useState<string | null>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  // NOTE: 許諾メッセージ更新
  const getGrantMessageDesign = useCallback(
    async (design: GrantMessageFormParameters) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      setPreviewHtml("");

      const id = setTimeout(() => {
        const grantMessageRepository = new GrantMessageV2Repository(
          managementApi
        );

        grantMessageRepository
          .getDesign({
            grant_message_type: "custom",
            form: JSON.stringify(design),
          })
          .then((response) => {
            setPreviewHtml(response);
          });
      }, GRANT_MESSAGE_UPDATE_REQUEST_INTERVAL);

      setTimeoutId(id);
    },
    [managementApi, setPreviewHtml, setTimeoutId, timeoutId]
  );

  // NOTE: 許諾メッセージのデザインを更新する
  useEffect(() => {
    if (JSON.stringify(grantMessageContents) !== designBuffer) {
      getGrantMessageDesign(grantMessageContents);
      setDesignBuffer(JSON.stringify(grantMessageContents));
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    grantMessageContents,
    getGrantMessageDesign,
    designBuffer,
    setDesignBuffer,
    timeoutId,
  ]);

  const isValidGrantMessage = useCallback(
    (params: GrantMessageFormParameters): boolean => {
      const validator = new GrantMessageFormValidator();
      const errors = validator.run(params);
      setErrorMessages(errors);
      return errors.length === 0;
    },
    [setErrorMessages]
  );

  const saveGrantMessage = useCallback(
    async (params: GrantMessageFormParameters) => {
      return new Promise<void>((resolve) => {
        const actionRepository = new ActionRepository(managementApi);
        const grantMessageRepository = new GrantMessageV2Repository(
          managementApi
        );
        if (params.design.image.isNewFile && params.design.image.file) {
          actionRepository
            .sendImage(params.design.image.file)
            .then((imagePath: string) => {
              grantMessageRepository
                .updateV3(generateGrantMessageUpdateRequest(imagePath, params))
                .then(() => resolve());
            });
        } else {
          grantMessageRepository
            .updateV3(generateGrantMessageUpdateRequest(null, params))
            .then(() => resolve());
        }
      });
    },
    [managementApi]
  );

  return [
    {
      previewHtml,
      errorMessages,
    },
    { saveGrantMessage, isValidGrantMessage },
  ];
};

export default useGrantMessageEditEvents;
