import React from "react";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import VerticalTable from "atoms/ezPushShared/accountPageShared/VerticalTable";

import styles from "./MeasurementUrlSettingTable.module.scss";
import { PAGE_SETTING_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import { MeasurementUrlSettingInterpleter } from "utils/measurement_url_setting/MeasurementUrlSettingInterpleter";

const MeasurementUrlSettingTable: React.FC<{
  setting: MeasurementUrlSettingWithType;
}> = ({ setting }) => (
  <div className={styles.measurementUrlSettingTable}>
    <VerticalTable
      items={[
        {
          label: <HorizontalIconLayout elements={[<span>管理名</span>]} />,
          value: setting.url.name,
        },
        {
          label: "ページ種別",
          value: PAGE_SETTING_TYPE_DISPLAY_WORD[setting.type],
        },
        {
          label: "URL",
          value: (
            <div className={styles.locationArea}>
              {setting.url.url.locations.length > 0
                ? setting.url.url.locations.map((loc, index) => (
                    <>
                      {index ? (
                        <div className={styles.separator}>かつ（and）</div>
                      ) : undefined}
                      <div className={styles.parameterBox}>
                        {MeasurementUrlSettingInterpleter.getLocationDescription(
                          loc
                        )}
                      </div>
                    </>
                  ))
                : "-"}
            </div>
          ),
        },
        {
          label: "パラメータ",
          value: (
            <div className={styles.parameterArea}>
              {setting.url.url.parameters.length > 0
                ? setting.url.url.parameters.map((p, index) => (
                    <>
                      {index ? (
                        <div className={styles.separator}>かつ（and）</div>
                      ) : undefined}
                      <div className={styles.parameterBox}>
                        {MeasurementUrlSettingInterpleter.getParameterDescription(
                          p
                        )}
                      </div>
                    </>
                  ))
                : "-"}
            </div>
          ),
        },
      ]}
    />
  </div>
);

export default MeasurementUrlSettingTable;
