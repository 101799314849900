import styles from "./AccessStatisticsView.module.scss";

const AccessStatisticsView = ({
  titleElement,
  accessStatisticsTableElement,
  closeButtonElement,
}: {
  titleElement: JSX.Element;
  accessStatisticsTableElement: JSX.Element;
  closeButtonElement: JSX.Element;
}) => {
  return (
    <div className={styles.accessStatisticsDetail}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.table}>{accessStatisticsTableElement}</div>
      <div className={styles.closeButton}>{closeButtonElement}</div>
    </div>
  );
};

export default AccessStatisticsView;
