import styles from "./SegmentationRuleTableItem.module.scss";

type SegmentationRuleTableItemProps = {
  segmentationRuleNumber: number;
  nameElement: JSX.Element;
  detailElement: JSX.Element;
  createdAtElement: JSX.Element;
  updatedAtElement: JSX.Element;
};

const SegmentationRuleTableItem = ({
  nameElement,
  segmentationRuleNumber,
  detailElement,
  createdAtElement,
  updatedAtElement,
}: SegmentationRuleTableItemProps) => {
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>{segmentationRuleNumber}</td>
      <td className={styles.td__segmentation_rule_name}>{nameElement}</td>
      <td className={styles.td}>{detailElement}</td>
      <td className={styles.td}>{createdAtElement}</td>
      <td className={styles.td}>{updatedAtElement}</td>
    </tr>
  );
};

export default SegmentationRuleTableItem;
