import { useEffect, useState } from "react";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationCallback,
} from "amazon-cognito-identity-js";
import useLocalStorage from "react-use-localstorage";
import { useHistory } from "react-router-dom";

export type SignInCallback = (
  accountId: string,
  userId: string,
  password: string,
  isAdmin: boolean,
  onSuccess: () => void,
  onError: (err: any) => void,
  onNewPasswordRequired: () => void
) => void;

export type CognitoAuthOperations = {
  onSignIn: SignInCallback;
  onSignOut: () => void;
  onCompleteNewPasswordChallenge: (
    newPassword: string,
    loginPath: string,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
};

type CognitoAuthProps = {
  userPool: CognitoUserPool;
  authName: string | null;
  saveAuthName: (authName: string | null) => void;
  render: (props: {
    session: CognitoUserSession | null;
    operations: CognitoAuthOperations;
    adminTargetAccountId?: string;
    loginAccountId: string;
  }) => JSX.Element;
};

const CognitoAuth = ({
  userPool,
  authName,
  saveAuthName,
  render,
}: CognitoAuthProps) => {
  const history = useHistory();
  const [adminTargetAccountId, setAdminTargetAccountId] =
    useLocalStorage("TARGET_ACCOUNT_ID");
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(
    authName
      ? new CognitoUser({
          Username: authName,
          Pool: userPool,
        })
      : null
  );

  const [loginAccountId, setLoginAccountId] = useState<string>("");

  const operations: CognitoAuthOperations = {
    onSignIn: (
      accountId,
      userId,
      password,
      isAdmin,
      onSuccess,
      onError,
      onNewPasswordRequired
    ) => {
      const ADMIN_ACCCOUNT_ID = "__ADMIN__";
      const authName = `${userId}@${isAdmin ? ADMIN_ACCCOUNT_ID : accountId}`;
      setLoginAccountId(accountId);

      if (isAdmin) {
        setAdminTargetAccountId(accountId);
      }

      try {
        const cognitoUser = new CognitoUser({
          Username: authName,
          Pool: userPool,
        });

        const authenticationDetails = new AuthenticationDetails({
          Username: authName,
          Password: password,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (session: CognitoUserSession) => {
            setCognitoUser(cognitoUser);
            setSession(session);
            onSuccess();
          },
          onFailure: (err: any) => onError(err),
          newPasswordRequired: (
            authenticationDetails: AuthenticationDetails,
            callbacks: IAuthenticationCallback
          ) => {
            setCognitoUser(cognitoUser);
            onNewPasswordRequired();
          },
        });
      } catch (err) {
        onError(err);
      }
    },
    onSignOut: (): void => {
      if (cognitoUser) {
        cognitoUser.signOut();
      }
      setSession(null);
    },
    onCompleteNewPasswordChallenge: (
      newPassword: string,
      loginPath: string,
      onSuccess: () => void,
      onError: (err: any) => void
    ): void => {
      if (!cognitoUser) {
        return;
      }

      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (session: CognitoUserSession) => {
            setCognitoUser(cognitoUser);
            setSession(session);
            onSuccess();
          },
          onFailure: (err: any) => {
            switch (err.code) {
              case "NotAuthorizedException":
              case "InvalidParameterException":
                history.push(
                  `${loginPath}?error_code=${err.code}&message=${err.message}`
                );
                break;
              case "InvalidPasswordException":
              default:
                console.error(err);
                onError(err.message);
            }
          },
        }
      );
    },
  };

  useEffect(() => {
    saveAuthName(cognitoUser ? cognitoUser.getUsername() : null);
    if (!isLoaded) {
      if (cognitoUser) {
        cognitoUser.getSession((error: any, session: any) => {
          setSession(session);
        });
      }
      setLoaded(true);
    }
  }, [cognitoUser, isLoaded, saveAuthName]);

  return isLoaded
    ? render({ session, operations, adminTargetAccountId, loginAccountId })
    : null;
};

export default CognitoAuth;
