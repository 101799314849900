import EventText from "atoms/accountPagesShared/EventText";
import { ColorResult } from "react-color";
import { hexToColor } from "utils/color";
import ColorCircle from "./ColorCircle";
import EZCXEditableInput from "./EditableInput";
import styles from "./SimplePresetColorPicker.module.scss";

type CreateCirclesProps = {
  colors: ColorResult[][];
  currentColor: ColorResult;
  onClickCircle: (color: ColorResult) => void;
};

const CreateCircles = ({
  colors,
  currentColor,
  onClickCircle,
}: CreateCirclesProps): JSX.Element => {
  return (
    <div className={styles.circleArea}>
      {colors.map((raw, raw_count) => (
        <div key={raw_count} className={styles.circleRow}>
          {raw.map((elem, elem_count) => (
            <div key={elem_count} className={styles.circleElem}>
              <ColorCircle
                color={elem}
                isActive={
                  currentColor
                    ? elem.hex.toUpperCase() === currentColor.hex.toUpperCase()
                    : false
                }
                onChange={() => onClickCircle(elem)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

type SimplePresetColorPickerProps = {
  color: ColorResult;
  onChange: (e: ColorResult) => void;
  onChangePicker: () => void;
};

const SimplePresetColorPicker = (props: SimplePresetColorPickerProps) => {
  const colors: ColorResult[][] = [
    [
      hexToColor("#FF6900"),
      hexToColor("#FCB900"),
      hexToColor("#FFEB3B"),
      hexToColor("#7BDCB5"),
      hexToColor("#00D084"),
      hexToColor("#4CAF50"),
      hexToColor("#8ED1FC"),
    ],
    [
      hexToColor("#0693E3"),
      hexToColor("#3F51B5"),
      hexToColor("#EA134B"),
      hexToColor("#F68CA6"),
      hexToColor("#F44336"),
      hexToColor("#FFFFFF"),
      hexToColor("#000000"),
    ],
  ].filter((row): row is ColorResult[] =>
    row
      .filter((col): col is ColorResult => col !== undefined)
      .every((e) => e !== undefined)
  );

  return (
    <div className={styles.simplePresetColorPicker}>
      <CreateCircles
        colors={colors}
        currentColor={props.color}
        onClickCircle={(c) => props.onChange(c)}
      />
      <div className={styles.footer}>
        <div className={styles.editableInput}>
          <EZCXEditableInput
            hex={props.color.hex}
            onChange={(c) => props.onChange(c)}
          />
        </div>
        <div>
          <EventText text="スライダーで選ぶ" onClick={props.onChangePicker} />
        </div>
      </div>
    </div>
  );
};

export default SimplePresetColorPicker;
