import React, { useMemo } from "react";
import {
  ConditionDispatchType,
  PAGE_CONDITION_COMPARE_TYPE,
  PageConditionDispatchType,
  SegmentationRulePageConditionParameters,
  SegmentationRuleTreeDispatchAction,
  SegmentationRuleTreeParameter,
} from "interfaces/v2/segmentationRuleForm";

import CrossIcon from "images/icons/icon_cross.svg";
import styles from "./SegmentationRuleTreeForm.module.scss";
import fontStyles from "fontStyles.module.scss";

import NumberInput from "atoms/ezPushShared/NumberInput";
import OrangeText from "atoms/ezPushShared/accountPageShared/OrangeText";
import IconWrapper from "atoms/ezPushShared/accountPageShared/IconWrapper";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import GroupedPullDown, {
  GroupedOption,
} from "atoms/ezPushShared/accountPageShared/GroupedPullDown";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import { SegmentationRuleMeasurementUrlModal } from "./SegmentationRuleEditorModals";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import LoadingIcon from "atoms/LoadingIcon";
import MeasurementUrlSettingTable from "./MeasurementUrlSettingTable";
import {
  COMPARE_CONDITION_OPTIONS_DISPLAY_WORD,
  PAGE_SETTING_TYPE_DISPLAY_WORD,
} from "app/system_defaults/WordDefaults";
import Separator from "atoms/ezPushShared/Separator";

const COMPARE_CONDITION_OPTIONS = [
  {
    label:
      COMPARE_CONDITION_OPTIONS_DISPLAY_WORD[
        PAGE_CONDITION_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO
      ],
    value: PAGE_CONDITION_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO,
  },
  {
    label:
      COMPARE_CONDITION_OPTIONS_DISPLAY_WORD[
        PAGE_CONDITION_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO
      ],
    value: PAGE_CONDITION_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
  },
  {
    label:
      COMPARE_CONDITION_OPTIONS_DISPLAY_WORD[
        PAGE_CONDITION_COMPARE_TYPE.EQUAL_TO
      ],
    value: PAGE_CONDITION_COMPARE_TYPE.EQUAL_TO,
  },
];

const UrlSettingDetailTable: React.FC<{
  urlUuid: string;
  urls: MeasurementUrlSettingWithType[];
}> = ({ urlUuid, urls }) => {
  const targetUrl = urls.find((url) => url.url.url_uuid === urlUuid);
  return targetUrl ? (
    <MeasurementUrlSettingTable setting={targetUrl} />
  ) : (
    <LoadingIcon />
  );
};

const SegmentationRuleTreePageForm: React.VFC<{
  conditionNumber: number;
  pageNumber: number;
  page: SegmentationRulePageConditionParameters;
  pageSettingOptions: GroupedOption[];
  compareConditionOptions: OptionType[];
  pageElementDispatch: (action: SegmentationRuleTreeDispatchAction) => void;
  openDetailModal: () => void;
}> = ({
  conditionNumber,
  pageNumber,
  page,
  pageSettingOptions,
  compareConditionOptions,
  pageElementDispatch,
  openDetailModal,
}) => {
  const pageArea = (
    <div className={styles.pageArea}>
      <div className={styles.label}>ページ</div>
      <div className={styles.selector}>
        <GroupedPullDown
          selected={page.urlSetting}
          groups={pageSettingOptions}
          onChange={(e) => {
            pageElementDispatch({
              type: PageConditionDispatchType.UPDATE_PAGE_UUID,
              conditionNumber: conditionNumber,
              pageNumber: pageNumber,
              value: e,
            });
          }}
        />
      </div>
      <OrangeText
        text={"詳細"}
        onChange={openDetailModal}
        clickable={page.urlSetting.value !== ""}
      />
    </div>
  );

  const conditionArea = (
    <div className={styles.conditionArea}>
      <div className={styles.label}>PV数</div>
      <div className={styles.number}>
        <NumberInput
          value={page.value}
          onChange={(value) =>
            pageElementDispatch({
              type: PageConditionDispatchType.UPDATE_VALUE,
              conditionNumber: conditionNumber,
              pageNumber: pageNumber,
              value: value,
            })
          }
          min={0}
          max={99}
        />
      </div>
      <div className={styles.compareTypeSelector}>
        <BorderedSelectBox
          options={compareConditionOptions}
          value={page.compareType}
          onChange={(e) =>
            pageElementDispatch({
              type: PageConditionDispatchType.UPDATE_COMPARE_TYPE,
              conditionNumber: conditionNumber,
              pageNumber: pageNumber,
              value: e,
            })
          }
        />
      </div>
    </div>
  );

  return (
    <div className={styles.segmentationRuleTreePageConditionForm}>
      {pageArea}
      {conditionArea}
    </div>
  );
};

const SegmentationRuleTreePagesConditionForm: React.VFC<{
  conditionNumber: number;
  pageSettings: SegmentationRulePageConditionParameters[];
  pageSettingOptions: GroupedOption[];
  urls: MeasurementUrlSettingWithType[];
  compareConditionOptions: OptionType[];
  updateTree: (action: SegmentationRuleTreeDispatchAction) => void;
  updateModalContents: (value: JSX.Element) => void;
  closeModalContents: () => void;
}> = ({
  conditionNumber,
  pageSettings,
  pageSettingOptions,
  urls,
  compareConditionOptions,
  updateTree,
  updateModalContents,
  closeModalContents,
}): JSX.Element => {
  const addPageElement = useMemo(
    () => (
      <OrangeText
        text={"ページを追加"}
        onChange={() =>
          updateTree({
            type: PageConditionDispatchType.ADD_PAGE,
            conditionNumber: conditionNumber,
          })
        }
      />
    ),
    [conditionNumber, updateTree]
  );

  return (
    <div className={styles.segmentationRuleTreeConditionForm}>
      <div className={styles.pageForms}>
        {pageSettings.map((setting, i) => (
          <div key={i}>
            {i > 0 ? (
              <div className={styles.separator}>
                <Separator text={"かつ（AND）"} />
              </div>
            ) : (
              <></>
            )}
            <div className={styles.pageForm}>
              <SegmentationRuleTreePageForm
                conditionNumber={conditionNumber}
                pageNumber={i}
                page={setting}
                pageElementDispatch={updateTree}
                compareConditionOptions={compareConditionOptions}
                pageSettingOptions={pageSettingOptions}
                openDetailModal={() =>
                  updateModalContents(
                    <SegmentationRuleMeasurementUrlModal
                      clearModalElement={closeModalContents}
                      element={
                        <UrlSettingDetailTable
                          urlUuid={setting.urlSetting.value}
                          urls={urls}
                        />
                      }
                    />
                  )
                }
              />
              <div className={styles.deletePage}>
                <IconWrapper
                  icon={CrossIcon}
                  isHidden={pageSettings.length === 1}
                  onClick={() =>
                    updateTree({
                      type: PageConditionDispatchType.REDUCE_PAGE,
                      conditionNumber: conditionNumber,
                      pageNumber: i,
                    })
                  }
                  width={12}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.addPage}>{addPageElement}</div>
    </div>
  );
};

const SegmentationRuleTreeForm: React.VFC<{
  tree: SegmentationRuleTreeParameter;
  urls: MeasurementUrlSettingWithType[];
  updateTree: (action: SegmentationRuleTreeDispatchAction) => void;
  updateModalContents: (value: JSX.Element | undefined) => void;
  closeModalContents: () => void;
}> = ({
  tree,
  urls,
  updateTree,
  updateModalContents,
  closeModalContents,
}): JSX.Element => {
  const addConditionButton = useMemo(
    () => (
      <OrangeText
        text={"＋ 条件を追加"}
        onChange={() =>
          updateTree({
            type: ConditionDispatchType.ADD_CONDITION,
          })
        }
      />
    ),
    [updateTree]
  );

  const PageSettingOptions: GroupedOption[] = useMemo(
    () => [
      {
        label: PAGE_SETTING_TYPE_DISPLAY_WORD.conversion,
        options: urls
          .filter((url) => url.type === "conversion")
          .sort((a, b) => a.url.created_at - b.url.created_at)
          .map((e) => ({
            value: e.url.url_uuid,
            label: e.url.name,
          })),
      },
      {
        label: PAGE_SETTING_TYPE_DISPLAY_WORD.segmentation,
        options: urls
          .filter((url) => url.type === "segmentation")
          .sort((a, b) => a.url.created_at - b.url.created_at)
          .map((e) => ({
            value: e.url.url_uuid,
            label: e.url.name,
          })),
      },
    ],
    [urls]
  );

  return (
    <div className={styles.segmentationRuleTreeForm}>
      {tree.map((page, i) => (
        <div key={i}>
          {i > 0 ? (
            <div className={styles.separator}>
              <Separator text={"または（OR）"} />
            </div>
          ) : (
            <></>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              <span className={fontStyles.boldText}>条件{i + 1}</span>
            </div>
            <div className={styles.condition}>
              {page.type === "total_pv_count" ? (
                <SegmentationRuleTreePagesConditionForm
                  conditionNumber={i}
                  pageSettings={page.pages}
                  pageSettingOptions={PageSettingOptions}
                  urls={urls}
                  compareConditionOptions={COMPARE_CONDITION_OPTIONS}
                  updateTree={updateTree}
                  updateModalContents={updateModalContents}
                  closeModalContents={closeModalContents}
                />
              ) : undefined}
              <div className={styles.deleteCondition}>
                <IconWrapper
                  icon={CrossIcon}
                  isHidden={tree.length <= 1}
                  onClick={() =>
                    updateTree({
                      type: ConditionDispatchType.REDUCE_CONDITION,
                      conditionNumber: i,
                    })
                  }
                  width={12}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className={styles.addConditionButton}>{addConditionButton}</div>
    </div>
  );
};

export default SegmentationRuleTreeForm;
