import React from "react";
import { SubscriptionStatistics } from "types/subscription_statistics";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

import styles from "./GrantMessageStatsPanel.module.scss";
import animeStyles from "animation.module.scss";
import fontStyles from "fontStyles.module.scss";

import CheckMark from "atoms/ezPushShared/CheckMark";
import NumberStats from "atoms/ezPushShared/accountPageShared/NumberStats";

const SubscribeRatePanel: React.VFC<{
  stats: SubscriptionStatistics;
}> = ({ stats }) => {
  const formatter = new DescriptionFormatter();
  const details = [
    {
      label: "許諾数",
      data: `${formatter.addCommasToNumber(stats.thirty_days_period_count)}回`,
    },
    {
      label: "表示回数",
      data: `${formatter.addCommasToNumber(
        stats.grant_message.displayed_uu
      )}回`,
    },
  ];

  const rateElement: JSX.Element =
    stats.grant_message.displayed_uu > 0 ? (
      <NumberStats
        number={(
          (stats.thirty_days_period_count / stats.grant_message.displayed_uu) *
          100
        )
          .toFixed(2)
          .toLocaleString()}
        unit="%"
      />
    ) : (
      <NumberStats number={"-"} unit="" />
    );

  return (
    <div className={animeStyles.fadeIn}>
      <div className={styles.grantMessageStatsPanel}>
        <div className={styles.rate}>
          <div className={styles.mark}>
            <CheckMark />
          </div>
          <div>{rateElement}</div>
        </div>
        <div className={styles.details}>
          {details.map((e, i) => (
            <div key={i} className={styles.item}>
              <div className={styles.label}>{e.label}</div>
              <div className={styles.data}>
                <div className={fontStyles.boldText}>{e.data}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SubscribeRatePanel;
