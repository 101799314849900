import { CognitoUserSession } from "amazon-cognito-identity-js";

const LoadSession = ({
  session,
  render,
  onExpired,
}: {
  session: CognitoUserSession | null;
  onExpired: () => JSX.Element;
  render: (params: {
    jwtToken: string;
    onSessionExpired: () => void;
  }) => JSX.Element;
}) => {
  return session
    ? render({
        jwtToken: session.getIdToken().getJwtToken(),
        onSessionExpired: () => {
          console.error("session expired callback is not implemented");
        },
      })
    : onExpired();
};

export default LoadSession;
