import TextOnly1Icon from "images/icons/action_creation/pattern_select/text_only/text_only_1.svg";
import TextOnly2Icon from "images/icons/action_creation/pattern_select/text_only/text_only_2.svg";
import TextOnly3Icon from "images/icons/action_creation/pattern_select/text_only/text_only_3.svg";
import TextOnly4Icon from "images/icons/action_creation/pattern_select/text_only/text_only_4.svg";
import TextOnly5Icon from "images/icons/action_creation/pattern_select/text_only/text_only_5.svg";
import TextOnly6Icon from "images/icons/action_creation/pattern_select/text_only/text_only_6.svg";
import TextOnly7Icon from "images/icons/action_creation/pattern_select/text_only/text_only_7.svg";

import IconText1Icon from "images/icons/action_creation/pattern_select/icon_text/icon_text_1.png";
import IconText2Icon from "images/icons/action_creation/pattern_select/icon_text/icon_text_2.svg";
import IconText3Icon from "images/icons/action_creation/pattern_select/icon_text/icon_text_3.svg";
import IconText4Icon from "images/icons/action_creation/pattern_select/icon_text/icon_text_4.svg";

import CouponCode1Icon from "images/icons/action_creation/pattern_select/coupon_code/coupon_code_1.svg";
import CouponCode2Icon from "images/icons/action_creation/pattern_select/coupon_code/coupon_code_2.svg";
import CouponCode3Icon from "images/icons/action_creation/pattern_select/coupon_code/coupon_code_3.svg";
import CouponCode4Icon from "images/icons/action_creation/pattern_select/coupon_code/coupon_code_4.svg";

import TextWithSmallImage1Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_1.svg";
import TextWithSmallImage2Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_2.svg";
import TextWithSmallImage3Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_3.svg";
import TextWithSmallImage4Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_4.svg";
import TextWithSmallImage5Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_5.svg";
import TextWithSmallImage6Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_6.svg";
import TextWithSmallImage7Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_7.svg";
import TextWithSmallImage8Icon from "images/icons/action_creation/pattern_select/text_with_small_image/text_with_small_image_8.svg";

import TextWithLargeImage1Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_1.svg";
import TextWithLargeImage2Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_2.svg";
import TextWithLargeImage3Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_3.svg";
import TextWithLargeImage4Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_4.svg";
import TextWithLargeImage5Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_5.svg";
import TextWithLargeImage6Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_6.svg";
import TextWithLargeImage7Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_7.svg";
import TextWithLargeImage8Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_8.svg";
import TextWithLargeImage9Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_9.svg";
import TextWithLargeImage10Icon from "images/icons/action_creation/pattern_select/text_with_large_image/text_with_large_image_10.svg";

import ImageFull1Icon from "images/icons/action_creation/pattern_select/full_image/full_image.svg";
import ImageFull2Icon from "images/icons/action_creation/pattern_select/full_image/full_image_with_link.svg";

import {
  CustomContentsFormat,
  CustomContentsFormParameters,
  CUSTOM_CONTENTS_FORMAT,
  CREATIVE_PARTS,
  MAIN_LAYOUT,
} from "interfaces/model/actionContentsParameters";

type CustomFormPatterns = {
  [formats in CustomContentsFormat]: CustomContentsFormParameters[];
};

const CUSTOM_FORM_PATTERNS: CustomFormPatterns = {
  [CUSTOM_CONTENTS_FORMAT.TEXT_ONLY]: [
    {
      name: 0,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly1Icon,
    },
    {
      name: 1,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly2Icon,
    },
    {
      name: 2,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly3Icon,
    },
    {
      name: 3,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly4Icon,
    },
    {
      name: 4,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly5Icon,
    },
    {
      name: 5,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly6Icon,
    },
    {
      name: 6,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextOnly7Icon,
    },
  ],
  [CUSTOM_CONTENTS_FORMAT.ICON_TEXT]: [
    {
      name: 0,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.ICON,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: IconText1Icon,
    },
    {
      name: 1,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.ICON,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: IconText2Icon,
    },
    {
      name: 2,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.ICON,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: IconText3Icon,
    },
    {
      name: 3,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.ICON,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: IconText4Icon,
    },
  ],
  [CUSTOM_CONTENTS_FORMAT.COUPON_CODE]: [
    {
      name: 0,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.COUPON_CODE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: CouponCode1Icon,
    },
    {
      name: 1,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.COUPON_CODE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: CouponCode2Icon,
    },
    {
      name: 2,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.COUPON_CODE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: CouponCode3Icon,
    },
    {
      name: 3,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.COUPON_CODE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: CouponCode4Icon,
    },
  ],
  [CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE]: [
    {
      name: 0,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithSmallImage1Icon,
    },
    {
      name: 1,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithSmallImage2Icon,
    },
    {
      name: 2,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithSmallImage3Icon,
    },
    {
      name: 3,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      layout: MAIN_LAYOUT.HEADER_TITLE,
      icon: TextWithSmallImage4Icon,
    },
    {
      name: 4,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithSmallImage5Icon,
    },
    {
      name: 5,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithSmallImage6Icon,
    },
    {
      name: 6,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithSmallImage7Icon,
    },
    {
      name: 7,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.MAGNIFICATION_IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      layout: MAIN_LAYOUT.HEADER_TITLE,
      icon: TextWithSmallImage8Icon,
    },
  ],
  [CUSTOM_CONTENTS_FORMAT.TEXT_WITH_LARGE_IMAGE]: [
    {
      name: 0,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage1Icon,
    },
    {
      name: 1,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage2Icon,
    },
    {
      name: 2,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage3Icon,
    },
    {
      name: 3,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage4Icon,
    },
    {
      name: 4,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage5Icon,
    },
    {
      name: 5,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.HEADLINE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage6Icon,
    },
    {
      name: 6,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.SUB_HEADLINE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage7Icon,
    },
    {
      name: 7,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage8Icon,
    },
    {
      name: 8,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage9Icon,
    },
    {
      name: 9,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.ARTICLE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.BUTTON,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: TextWithLargeImage10Icon,
    },
  ],
  [CUSTOM_CONTENTS_FORMAT.FULL_IMAGE]: [
    {
      name: 0,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.IMAGE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: ImageFull1Icon,
    },
    {
      name: 1,
      parts: [
        CREATIVE_PARTS.BASE,
        CREATIVE_PARTS.LINK_IMAGE,
        CREATIVE_PARTS.CLOSE_BUTTON,
      ],
      icon: ImageFull2Icon,
    },
  ],
};

export const getCustomFormatMaxPattern = (
  format: CustomContentsFormat
): number => {
  try {
    return CUSTOM_FORM_PATTERNS[format].length;
  } catch (err) {
    console.error("Undefined format selected");
  }
  return 0;
};

export const loadCustomFormatPatterns = (
  format: CustomContentsFormat
): CustomContentsFormParameters[] => {
  if (!Object.values(CUSTOM_CONTENTS_FORMAT).includes(format)) {
    throw new Error("unexpected value stored to format");
  }

  return CUSTOM_FORM_PATTERNS[format];
};

export const getCustomFormatPattern = (
  format: CustomContentsFormat,
  pattern: number
): CustomContentsFormParameters | undefined => {
  if (!format || pattern < 0) {
    return undefined;
  }

  try {
    return CUSTOM_FORM_PATTERNS[format][pattern];
  } catch (err) {
    console.error("Undefined format pattern selected");
  }
  return undefined;
};
