import styles from "./SettingsEditPhaseFormMain.module.scss";

const SettingsEditPhaseFormMain = ({
  sideAreaElement,
  bodyAreaElement,
}: {
  sideAreaElement: JSX.Element;
  bodyAreaElement: JSX.Element;
}) => {
  return (
    <div className={styles.settingsEditPhaseFormMain}>
      {sideAreaElement}
      <div>{bodyAreaElement}</div>
    </div>
  );
};

export default SettingsEditPhaseFormMain;
