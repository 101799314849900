import { DAY_OF_WEEK, HOLIDAY } from "interfaces/models";
import CheckBox from "./CheckBox";
import styles from "./DayOfWeekForm.module.scss";

const DAY_OF_WEEK_MODEL: {
  label: string;
  value: string;
  type: string;
}[] = [
  { label: "月", value: DAY_OF_WEEK.MONDAY, type: "checkBox" },
  { label: "火", value: DAY_OF_WEEK.TUESDAY, type: "checkBox" },
  { label: "水", value: DAY_OF_WEEK.WEDNESDAY, type: "checkBox" },
  { label: "木", value: DAY_OF_WEEK.THURSDAY, type: "checkBox" },
  { label: "金", value: DAY_OF_WEEK.FRIDAY, type: "checkBox" },
  { label: "土", value: DAY_OF_WEEK.SATURDAY, type: "checkBox" },
  { label: "日", value: DAY_OF_WEEK.SUNDAY, type: "checkBox" },
  { label: "/", value: "separator", type: "text" },
  { label: "祝日", value: HOLIDAY.HOLIDAY, type: "checkBox" },
];

type DayOfWeekFormType = {
  executeTimingLayerIndex: number;
  onChangeSetExecuteTimingDayOfWeek(index: number, label: string): void;
  onChangeSetExecuteTimingHoliday(index: number): void;
  getExecuteTimingHolidayState(index: number): boolean;
  getExecuteTimingDayOfWeekState(index: number, value: string): boolean;
};

const DayOfWeekForm = ({
  executeTimingLayerIndex,
  onChangeSetExecuteTimingDayOfWeek,
  onChangeSetExecuteTimingHoliday,
  getExecuteTimingHolidayState,
  getExecuteTimingDayOfWeekState,
}: DayOfWeekFormType) => {
  const dayOfWeekElement: (JSX.Element | undefined)[] = DAY_OF_WEEK_MODEL.map(
    (model, elementIndex) => {
      switch (model.type) {
        case "checkBox":
          return (
            <div key={elementIndex} className={styles.checkBox}>
              <CheckBox
                text={model.label}
                value={
                  model.value === HOLIDAY.HOLIDAY
                    ? getExecuteTimingHolidayState(executeTimingLayerIndex)
                    : getExecuteTimingDayOfWeekState(
                        executeTimingLayerIndex,
                        model.value
                      )
                }
                onChange={() =>
                  model.value === HOLIDAY.HOLIDAY
                    ? onChangeSetExecuteTimingHoliday(executeTimingLayerIndex)
                    : onChangeSetExecuteTimingDayOfWeek(
                        executeTimingLayerIndex,
                        model.value
                      )
                }
              />
            </div>
          );
        case "text":
          return (
            <div key={elementIndex} className={styles.text}>
              {model.label}
            </div>
          );
        default:
          return undefined;
      }
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.dayOfWeek}>{dayOfWeekElement}</div>
    </div>
  );
};

export default DayOfWeekForm;
