import { useState } from "react";
import { Direction, Range, getTrackBackground } from "react-range";

import styles from "./RangeSlider.module.scss";

type RangeSliderProps = {
  value: number;
  minValue: number;
  maxValue: number;
  stepValue: number;
  isActive?: boolean;
  onChange: (value: number) => void;
  valueTextEditCallback: (value: any) => string;
};

const RangeSlider = ({
  value,
  minValue,
  maxValue,
  stepValue,
  isActive = true,
  onChange,
  valueTextEditCallback,
}: RangeSliderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const activeDropDownIcon = (
    <svg
      className={isActive ? styles.dropdown : styles.dropdownDisabled}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="5"
      viewBox="0 0 11 5"
    >
      <path d="M5.5,5,11,0H0Z" transform="translate(11 5) rotate(180)" />
    </svg>
  );

  const dropDownIcon = (
    <svg
      className={isActive ? styles.dropdown : styles.dropdownDisabled}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="5"
      viewBox="0 0 11 5"
    >
      <path d="M5.5,0,11,5H0Z" transform="translate(11 5) rotate(180)" />
    </svg>
  );

  const valueElement: JSX.Element = (
    <div
      className={isActive ? styles.box : styles.boxDisabled}
      onClick={() => (isActive ? setIsOpen(!isOpen) : undefined)}
    >
      <div className={isActive ? styles.value : styles.valueDisabled}>
        {valueTextEditCallback(value)}
      </div>
      {isOpen ? activeDropDownIcon : dropDownIcon}
    </div>
  );

  const values = [value];

  const sliderElement: JSX.Element = (
    <Range
      values={values}
      min={minValue}
      max={maxValue}
      step={stepValue}
      direction={Direction.Down}
      onChange={(values) => onChange(values[0])}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 0 10px #00000029",
            backgroundColor: "#fff",
            width: "50px",
            height: "120px",
            position: "absolute",
          }}
        >
          <div
            ref={props.ref}
            style={{
              width: "4px",
              height: "100px",
              background: getTrackBackground({
                values: values,
                colors: ["#ff7b2c", "#ccc"],
                min: minValue,
                max: maxValue,
                direction: Direction.Down,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            width: "14px",
            height: "14px",
            borderRadius: "14px",
            border: "1px solid #ccc",
            outline: "none",
            backgroundColor: "#fff",
          }}
        />
      )}
    />
  );

  return (
    <div className={styles.rangeSlider}>
      {valueElement}
      {isOpen ? (
        <div className={styles.popup}>
          <div className={styles.blurArea} onClick={() => setIsOpen(false)} />
          <div className={styles.slider}>{sliderElement}</div>
        </div>
      ) : undefined}
    </div>
  );
};

export default RangeSlider;
