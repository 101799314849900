import styles from "./RadioButton.module.scss";
import RadioButtonBase from "atoms/RadioButtonBase";

type RadioButtonProps = {
  isActive: boolean;
  onClick: () => void;
  label: string | JSX.Element;
};

const RadioButton = ({ isActive, onClick, label }: RadioButtonProps) => {
  return (
    <label className={styles.radioButton} onClick={() => onClick()}>
      <div className={styles.button}>
        <RadioButtonBase isActive={isActive} onClick={() => {}} />
      </div>
      <div>{label}</div>
    </label>
  );
};

export default RadioButton;
