import { PLEASE_CHOICE } from "app/system_defaults/WordDefaults";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import NumberInput from "atoms/NumberInput";
import TextInput from "atoms/TextInput";
import {
  CUSTOMER_TYPE,
  DATA_TYPE,
  DATE_COMPARE_TARGET,
  INTEGER_COMPARE_TYPE,
  SegmentationRuleCreation,
  WORD_COMPARE_TYPE,
} from "interfaces/model/segmentationRuleCreation";
import CustomerAttributeForm from "molecules/SegmentationRuleCreation/CustomerAttributeForm";
import SegmentationRuleCustomerAttributeForm from "organisms/account/segmentation_rule_creation/SegmentationRuleCustomerAttributeForm";
import TransitionForm from "organisms/account/segmentation_rule_creation/TransitionForm";
import { SegmentationRuleCreationCallbacks } from "utils/segmentation_rule_creation/SegmentationRuleCreationCallbacks";

const createIntegerCompareTypeElement = (
  compareType: SegmentationRuleCreation.IntegerCompareType,
  onChangeCompareType: (
    value: SegmentationRuleCreation.IntegerCompareType
  ) => void
): JSX.Element => {
  const options: OptionType[] = [
    { label: "以上", value: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO },
    {
      label: "以下",
      value: INTEGER_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO,
    },
    {
      label: "と等しい",
      value: INTEGER_COMPARE_TYPE.EQUAL_TO,
    },
  ];

  return (
    <BorderedSelectBox
      options={options}
      value={compareType}
      onChange={(e) => onChangeCompareType(e)}
    />
  );
};

const createWordCompareTypeElement = (
  compareType: SegmentationRuleCreation.WordCompareType,
  onChangeCompareType: (
    value: SegmentationRuleCreation.IntegerCompareType
  ) => void
): JSX.Element => {
  const options: OptionType[] = [
    { label: "と等しい", value: WORD_COMPARE_TYPE.EQUAL },
    {
      label: "を含む",
      value: WORD_COMPARE_TYPE.INCLUDE,
    },
    { label: "と完全一致する", value: WORD_COMPARE_TYPE.DEEP_EQUAL },
  ];

  return (
    <BorderedSelectBox
      options={options}
      value={compareType}
      onChange={(e) => onChangeCompareType(e)}
    />
  );
};

const createCompareTargetElement = (
  compareTarget: SegmentationRuleCreation.DateCompareTarget,
  onChangeCompareType: (
    value: SegmentationRuleCreation.DateCompareTarget
  ) => void
): JSX.Element => {
  const options: OptionType[] = [
    { label: "今年", value: DATE_COMPARE_TARGET.YEAR },
    {
      label: "今月",
      value: DATE_COMPARE_TARGET.MONTH,
    },
    {
      label: "今日",
      value: DATE_COMPARE_TARGET.DAY,
    },
  ];

  return (
    <BorderedSelectBox
      options={options}
      value={compareTarget}
      onChange={(e) => onChangeCompareType(e)}
    />
  );
};

const createCompareForm = (
  state: SegmentationRuleCreation.CustomerAttribute,
  onChangeCompareType: (value: SegmentationRuleCreation.CompareType) => void,
  onChangeCompareTarget: (
    value: SegmentationRuleCreation.DateCompareTarget
  ) => void
): JSX.Element => {
  if (state.operation.type === DATA_TYPE.INTEGER) {
    const operation =
      state.operation as SegmentationRuleCreation.IntegerCompareOperation;
    return createIntegerCompareTypeElement(
      operation.compareType,
      onChangeCompareType
    );
  } else if (state.operation.type === DATA_TYPE.WORD) {
    const operation =
      state.operation as SegmentationRuleCreation.WordCompareOperation;
    return createWordCompareTypeElement(
      operation.compareType,
      onChangeCompareType
    );
  } else if (state.operation.type === DATA_TYPE.DATE) {
    const operation =
      state.operation as SegmentationRuleCreation.DateCompareOperation;
    return createCompareTargetElement(
      operation.compareTarget,
      onChangeCompareTarget
    );
  } else {
    // NOTE: 存在し得ないdataTypeが入った場合の処理。仕様が無いので、一旦非表示にする
    return <></>;
  }
};

const createInputElement = (
  state: SegmentationRuleCreation.CustomerAttribute,
  onChangeValue: (value: string) => void
): JSX.Element | undefined => {
  if (state.operation.type === DATA_TYPE.INTEGER) {
    return (
      <NumberInput
        value={Number(state.operation.value)}
        onChange={(e) => onChangeValue(e)}
      />
    );
  } else if (state.operation.type === DATA_TYPE.WORD) {
    return (
      <TextInput
        value={state.operation.value}
        onChange={(e) => onChangeValue(e.target.value)}
      />
    );
  }
  return undefined;
};

type CreateCustomerAttributeBehaviorFormProps = {
  state: SegmentationRuleCreation.CustomerAttribute;
  customerAttributeKeys: string[];
  onChangeKey: (value: string) => void;
  onChangeDataType: (value: SegmentationRuleCreation.DataType) => void;
  onChangeValue: (value: string) => void;
  onChangeCompareType: (value: SegmentationRuleCreation.CompareType) => void;
  onChangeCompareTarget: (
    value: SegmentationRuleCreation.DateCompareTarget
  ) => void;
};

const CreateCustomerAttributeBehaviorForm = ({
  state,
  customerAttributeKeys,
  onChangeKey,
  onChangeDataType,
  onChangeValue,
  onChangeCompareType,
  onChangeCompareTarget,
}: CreateCustomerAttributeBehaviorFormProps) => {
  // key selector
  const defaultKey: OptionType[] = [
    {
      label: PLEASE_CHOICE,
      value: "",
    },
  ];
  const keyOptions: OptionType[] = customerAttributeKeys.map((e) => {
    return { label: e, value: e };
  });

  const keySelectorElement = (
    <BorderedSelectBox
      options={defaultKey.concat(keyOptions)}
      value={state.key}
      onChange={(e) => onChangeKey(e)}
    />
  );

  const dataTypeOptions: OptionType[] = [
    {
      label: "数値",
      value: DATA_TYPE.INTEGER,
    },
    { label: "文字", value: DATA_TYPE.WORD },
    {
      label: "日付",
      value: DATA_TYPE.DATE,
    },
  ];

  const dataTypeSelector = (
    <BorderedSelectBox
      options={dataTypeOptions}
      value={state.operation.type}
      onChange={(e) => onChangeDataType(e)}
    />
  );

  return (
    <CustomerAttributeForm
      keySelectorElement={keySelectorElement}
      dataSelectorElement={dataTypeSelector}
      inputElement={createInputElement(state, onChangeValue)}
      compareTypeElement={createCompareForm(
        state,
        onChangeCompareType,
        onChangeCompareTarget
      )}
    />
  );
};

const createCustomerAttributeForms = (
  formCount: number,
  customerAttributeKeys: string[],
  state: SegmentationRuleCreation.CustomerAttributeInfo,
  callbacks: SegmentationRuleCreationCallbacks.CustomerAttributeInfoCallbacks
): [
  typeSelectorElement: JSX.Element,
  customerAttributeElements: JSX.Element[]
] => {
  const options: OptionType[] = [
    {
      label: "全ユーザー",
      value: CUSTOMER_TYPE.ALL,
    },
    { label: "既存会員ユーザーのみ", value: CUSTOMER_TYPE.EXISTING_USER_ONLY },
    { label: "非会員ユーザーのみ", value: CUSTOMER_TYPE.NO_MEMBER_USER_ONLY },
  ];

  const typeSelector: JSX.Element = (
    <BorderedSelectBox
      options={options}
      value={state.customerType}
      onChange={(v) => callbacks.customerType.onChangeType(v)}
    />
  );

  const transitionForms: JSX.Element[] = Array.from(Array(formCount))
    .map((_, i) =>
      state.customerAttributes[i] ? (
        <TransitionForm
          formElement={
            <CreateCustomerAttributeBehaviorForm
              state={state.customerAttributes[i]}
              customerAttributeKeys={customerAttributeKeys}
              onChangeKey={(v) =>
                callbacks.customerAttributes.onChangeKey(i, v)
              }
              onChangeDataType={(v) =>
                callbacks.customerAttributes.onChangeDataType(i, v)
              }
              onChangeValue={(v) => {
                callbacks.customerAttributes.onChangeValue(i, v);
              }}
              onChangeCompareType={(v) =>
                callbacks.customerAttributes.onChangeCompareType(i, v)
              }
              onChangeCompareTarget={(v) =>
                callbacks.customerAttributes.onChangeCompareTarget(i, v)
              }
            />
          }
          deleteButtonElement={
            formCount > 1 ? (
              <DeleteButton
                onClick={() => callbacks.customerAttributes.delete(i)}
              />
            ) : undefined
          }
        />
      ) : undefined
    )
    .filter((e): e is Exclude<typeof e, undefined> => e !== undefined);

  return [typeSelector, transitionForms];
};

type CreateCustomerAttributeFormProps = {
  formCount: number;
  customerAttributeKeys: string[];
  state: SegmentationRuleCreation.CustomerAttributeInfo;
  callbacks: SegmentationRuleCreationCallbacks.CustomerAttributeInfoCallbacks;
};

const CreateCustomerAttributeForm = ({
  formCount,
  customerAttributeKeys,
  state,
  callbacks,
}: CreateCustomerAttributeFormProps) => {
  const [typeSelectorElement, customerAttributeElements] =
    createCustomerAttributeForms(
      formCount,
      customerAttributeKeys,
      state,
      callbacks
    );

  return (
    <SegmentationRuleCustomerAttributeForm
      typeElement={typeSelectorElement}
      customerAttributeElements={customerAttributeElements}
      separatorElement={<NoticeText text="and" />}
    />
  );
};

export default CreateCustomerAttributeForm;
