import { PLEASE_CHOICE } from "app/system_defaults/WordDefaults";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import NumberInput from "atoms/NumberInput";
import {
  INTEGER_COMPARE_TYPE,
  PAST_BEHAVIOR_TYPE,
  SegmentationRuleCreation,
} from "interfaces/model/segmentationRuleCreation";
import { UrlPattern } from "interfaces/models";
import NumberInputForm from "molecules/SegmentationRuleCreation/NumberInputForm";
import PastForm from "molecules/SegmentationRuleCreation/PastForm";
import UrlMatchPatternForm from "molecules/SegmentationRuleCreation/UrlMatchPatternForm";
import { useMemo } from "react";
import { SegmentationRuleCreationCallbacks } from "utils/segmentation_rule_creation/SegmentationRuleCreationCallbacks";
import { UrlPatternCodec } from "utils/segmentation_rule_creation/UrlPatternCodec";

type CreatePastBehaviorFormProps = {
  state: SegmentationRuleCreation.PastBehavior;
  initState: SegmentationRuleCreation.PastBehavior | undefined;
  onSelectBehaviorType: (
    value: SegmentationRuleCreation.PastBehaviorType
  ) => void;
  onChangeInputValue: (value: number) => void;
  onChangeCompareType: (
    value: SegmentationRuleCreation.IntegerCompareType
  ) => void;
  onChangeUrl: (url: UrlPattern) => void;
  onChangePeriods: (value: number) => void;
};

const CreatePastBehaviorForm = ({
  state,
  initState,
  onSelectBehaviorType,
  onChangeInputValue,
  onChangeCompareType,
  onChangeUrl,
  onChangePeriods,
}: CreatePastBehaviorFormProps) => {
  const rangeInputElement = (
    <NumberInputForm
      leftElement={<div>過去</div>}
      centerElement={
        <NumberInput
          value={state.rangeDays}
          onChange={(e) => onChangePeriods(e)}
          isDisabled={state.behaviorType === PAST_BEHAVIOR_TYPE.NOT_SELECTED}
        />
      }
      rightElement={<div>日間以内(1~730日以内で入力ください)</div>}
      isActive={state.behaviorType !== PAST_BEHAVIOR_TYPE.NOT_SELECTED}
    />
  );

  const behaviorTypeOptions: OptionType[] = [
    {
      label: PLEASE_CHOICE,
      value: PAST_BEHAVIOR_TYPE.NOT_SELECTED,
    },
    { label: "指定ページの閲覧数", value: PAST_BEHAVIOR_TYPE.PV_COUNT },
    {
      label: "総閲覧数",
      value: PAST_BEHAVIOR_TYPE.ALL_PV_COUNT,
    },
  ];

  const behaviorSelectorElement = (
    <BorderedSelectBox
      options={behaviorTypeOptions}
      value={state.behaviorType}
      onChange={(e) => onSelectBehaviorType(e)}
    />
  );

  const inputElement = (
    <NumberInput value={state.value} onChange={(e) => onChangeInputValue(e)} />
  );

  const compareTypeOptions: OptionType[] = [
    { label: "以上", value: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO },
    {
      label: "以下",
      value: INTEGER_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO,
    },
    {
      label: "と等しい",
      value: INTEGER_COMPARE_TYPE.EQUAL_TO,
    },
  ];

  const compareTypeElement = (
    <BorderedSelectBox
      options={compareTypeOptions}
      value={state.compareType}
      onChange={(e) => onChangeCompareType(e)}
    />
  );

  const urlUsingBehaviors: SegmentationRuleCreation.PastBehaviorType[] = [
    PAST_BEHAVIOR_TYPE.PV_COUNT,
  ];

  const initStateUrl: ManagementApiData.UrlMatchPattern | undefined =
    useMemo(() => {
      const initUrl = initState ? initState.url : undefined;
      return initUrl ? UrlPatternCodec.decode(initUrl) : undefined;
    }, [initState]);

  const urlElement = urlUsingBehaviors.includes(state.behaviorType) ? (
    <UrlMatchPatternForm
      initValue={initStateUrl}
      onChange={(e) => onChangeUrl(UrlPatternCodec.encode(e))}
    />
  ) : undefined;

  return state.behaviorType === PAST_BEHAVIOR_TYPE.NOT_SELECTED ? (
    <PastForm
      rangeInputElement={rangeInputElement}
      selectorElement={behaviorSelectorElement}
    />
  ) : (
    <PastForm
      rangeInputElement={rangeInputElement}
      selectorElement={behaviorSelectorElement}
      inputElement={inputElement}
      unitElement={<>回</>}
      compareTypeElement={compareTypeElement}
      urlElement={urlElement}
    />
  );
};

const createPastForm = (
  state: SegmentationRuleCreation.PastBehavior[],
  initState: SegmentationRuleCreation.PastBehavior[] | undefined,
  formCount: number,
  callbacks: SegmentationRuleCreationCallbacks.PastBehaviorSettingCallbacks
) => {
  return Array.from(Array(formCount))
    .map((_, i) =>
      state[i] ? (
        <CreatePastBehaviorForm
          key={i}
          state={state[i]}
          initState={initState ? initState[i] : undefined}
          onSelectBehaviorType={(v) => callbacks.onChangeType(i, v)}
          onChangeInputValue={(v) => callbacks.onChangeValue(i, v)}
          onChangeCompareType={(v) => callbacks.onChangeCompareType(i, v)}
          onChangeUrl={(v) => callbacks.onChangeUrl(i, v)}
          onChangePeriods={(v) => callbacks.onChangePeriods(i, v)}
        />
      ) : undefined
    )
    .filter((e): e is Exclude<typeof e, undefined> => e !== undefined);
};

export default createPastForm;
