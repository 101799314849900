import { StepFunctionsStatusResponse } from "app/system_defaults/step_functions_status";
import RestApi from "utils/RestApi";
import { TestNotificationRepository } from "utils/repositories/TestNotificationRepository";

export class TestNotificationExecuter {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  request = (campaignUuid: string): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
      const response = await new TestNotificationRepository(
        this.managementApi
      ).request({
        campaign_uuid: campaignUuid,
      });

      if (response) {
        resolve(response.request_uuid);
      } else {
        reject();
      }
    });
  };

  getStatus = (requestUuid: string): Promise<StepFunctionsStatusResponse> => {
    return new Promise<StepFunctionsStatusResponse>(async (resolve, reject) => {
      const response = await new TestNotificationRepository(
        this.managementApi
      ).getStatus({
        request_uuid: requestUuid,
      });

      if (response) {
        resolve(response.result);
      } else {
        reject();
      }
    });
  };
}
