import styles from "./AuthFormV2.module.scss";

type AuthFormProps = {
  inputElements: JSX.Element[];
  errorMessageElement: JSX.Element;
  submitButtonElement: JSX.Element;
};

const AuthFormV2 = ({
  inputElements,
  errorMessageElement,
  submitButtonElement,
}: AuthFormProps) => (
  <form
    className={styles.authFormV2}
    onSubmit={(event) => {
      event.preventDefault();
    }}
  >
    <div>
      {inputElements.map((item: JSX.Element, index) => (
        <div key={index} className={styles.input}>
          {item}
        </div>
      ))}
    </div>
    <div className={styles.error}>{errorMessageElement}</div>
    <div className={styles.submit}>{submitButtonElement}</div>
  </form>
);

export default AuthFormV2;
