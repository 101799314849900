import styles from "./NeutralButton.module.scss";

interface NeutralButtonProps {
  text: string;
  onClick: () => void;
}

const NeutralButton = (props: NeutralButtonProps) => {
  return (
    <button
      type="button"
      className={styles.neutralButton}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default NeutralButton;
