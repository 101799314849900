import React, { useMemo } from "react";

import styles from "./MeasurementUrlSettingEditForm.module.scss";

import {
  MeasurementUrlLocationParameterComparePattern,
  MeasurementUrlQueryParameterComparePattern,
} from "types/measurement_urls";

import * as useEditPage from "app/hooks/v2/measurementUrlSetting/useEditPage";

import {
  LOCATION_COMPARE_PATTERN,
  LocationComparePattern,
  PATH_PARAMETER_COMPARE_PATTERN,
} from "interfaces/models";
import {
  LOCATION_COMPARE_TYPE_DISPLAY_WORD,
  QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD,
} from "app/system_defaults/WordDefaults";

import TextInput from "atoms/TextInput";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import OrangeText from "atoms/ezPushShared/accountPageShared/OrangeText";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import Separator from "atoms/ezPushShared/Separator";
import TitleForm from "molecules/v2/TitleForm";

import Panel from "templates/ezPush/Panel";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import {
  MEASUREMENT_URL_SETTING_EDITOR_MODE,
  MEASUREMENT_URL_SETTING_TYPE,
  MeasurementUrlSettingType,
} from "app/system_defaults/v2_routing";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ErrorMessages from "molecules/v2/ErrorMessages";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";

const MeasurementUrlSettingTitleForm: React.VFC<{
  title: string;
  updateTitle: (title: string) => void;
}> = ({ title, updateTitle }) => (
  <div className={styles.titleForm}>
    <TitleForm
      title="管理名"
      mainElement={
        <TextInput
          value={title}
          onChange={(e) => updateTitle(e.target.value)}
          placeholder="TOPページ"
        />
      }
      isRequired
      notification="文字数上限：50文字"
    />
  </div>
);

const LOCATION_PATTERN_OPTIONS: OptionType[] = [
  LOCATION_COMPARE_PATTERN.INCLUDE,
  LOCATION_COMPARE_PATTERN.EXCLUDE,
  LOCATION_COMPARE_PATTERN.COMPLETE,
].map((pattern) => ({
  value: pattern,
  label: LOCATION_COMPARE_TYPE_DISPLAY_WORD[pattern],
}));

const MeasurementUrlSettingLocationForm: React.VFC<{
  modeType: MeasurementUrlSettingType;
  value: string;
  pattern: string;
  updateLocationValue: (value: string) => void;
  updateLocationPattern: (pattern: LocationComparePattern) => void;
}> = ({
  modeType,
  value,
  pattern,
  updateLocationValue,
  updateLocationPattern,
}) => {
  const descriptionTexts: string[] = useMemo(() => {
    const descriptions = [
      "半角1024文字以内",
      "完全一致、部分一致どちらも指定可",
    ];
    if (modeType === MEASUREMENT_URL_SETTING_TYPE.SUBSCRIPTION) {
      descriptions.concat([
        "表示対象が複数の場合、同一ドメインでのみご指定いただけます。",
        "（サブドメインは別のドメインとなるため、設定できません）",
      ]);
    }
    return descriptions;
  }, [modeType]);

  return (
    <div className={styles.locationForm}>
      <TitleForm
        title="URL"
        mainElement={
          <HorizontalLayout
            elements={[
              <div className={styles.text}>
                <TextInput
                  value={value}
                  onChange={(e) => updateLocationValue(e.target.value)}
                  placeholder="https://example.com"
                />
              </div>,
              <div className={styles.patternSelect}>
                <BorderedSelectBox
                  options={LOCATION_PATTERN_OPTIONS}
                  value={pattern}
                  onChange={(value) =>
                    updateLocationPattern(
                      value as MeasurementUrlLocationParameterComparePattern
                    )
                  }
                />
              </div>,
            ]}
          />
        }
        notification={<MultipleLineText texts={descriptionTexts} />}
      />
    </div>
  );
};

const QUERY_PARAMETER_PATTERN_OPTIONS: OptionType[] = [
  PATH_PARAMETER_COMPARE_PATTERN.INCLUDE,
  PATH_PARAMETER_COMPARE_PATTERN.EXCLUDE,
].map((pattern) => ({
  value: pattern,
  label: QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD[pattern],
}));

const MeasurementUrlSettingQueryParameterForm: React.VFC<{
  queryParameters: {
    keyValue: string;
    pattern: string;
  }[];
  updateParameterKeyValue: (index: number, keyValue: string) => void;
  updateParameterPattern: (
    index: number,
    pattern: MeasurementUrlQueryParameterComparePattern
  ) => void;
  removeParameter: (index: number) => void;
}> = ({
  queryParameters,
  updateParameterKeyValue,
  updateParameterPattern,
  removeParameter,
}) => {
  const formElements: JSX.Element[] = useMemo(() => {
    const temp: JSX.Element[] = [];
    queryParameters.forEach((parameter, index) => {
      if (index > 0) {
        temp.push(
          <div style={{ margin: "20px 0" }}>
            <Separator text={"かつ（AND）"} width={520} />
          </div>
        );
      }
      temp.push(
        <HorizontalLayout
          elements={[
            <div className={styles.text}>
              <TextInput
                value={parameter.keyValue}
                onChange={(e) => updateParameterKeyValue(index, e.target.value)}
                placeholder="product_no="
              />
            </div>,
            <div className={styles.patternSelect}>
              <BorderedSelectBox
                options={QUERY_PARAMETER_PATTERN_OPTIONS}
                value={parameter.pattern}
                onChange={(value) =>
                  updateParameterPattern(
                    index,
                    value as MeasurementUrlQueryParameterComparePattern
                  )
                }
              />
            </div>,
            queryParameters.length > 1 ? (
              <DeleteButton onClick={() => removeParameter(index)} />
            ) : (
              <></>
            ),
          ]}
        />
      );
    });
    return temp;
  }, [
    queryParameters,
    updateParameterKeyValue,
    updateParameterPattern,
    removeParameter,
  ]);

  return (
    <div className={styles.queryParameterForm}>
      <TitleForm
        title="パラメータ"
        mainElement={
          <>
            {formElements.map((element, index) => (
              <div key={index}>{element}</div>
            ))}
          </>
        }
        notification={"半角1024文字以内"}
      />
    </div>
  );
};

const getEditPanelElements = (
  pageParameters: useEditPage.EditPageParameters,
  pageCallbacks: useEditPage.EditPageCallbacks
): JSX.Element[] => {
  return [
    <MeasurementUrlSettingTitleForm
      title={pageParameters.form.name}
      updateTitle={pageCallbacks.form.title.updateTitle}
    />,
    <MeasurementUrlSettingLocationForm
      modeType={pageParameters.mode.type}
      value={pageParameters.form.location.value}
      pattern={pageParameters.form.location.pattern}
      updateLocationValue={pageCallbacks.form.location.updateLocationValue}
      updateLocationPattern={pageCallbacks.form.location.updateLocationPattern}
    />,
    <MeasurementUrlSettingQueryParameterForm
      queryParameters={pageParameters.form.parameters.map((item) => ({
        keyValue: item.keyValue,
        pattern: item.pattern,
      }))}
      updateParameterKeyValue={
        pageCallbacks.form.queryParameters.updateParameterKeyValue
      }
      updateParameterPattern={
        pageCallbacks.form.queryParameters.updateParameterPattern
      }
      removeParameter={pageCallbacks.form.queryParameters.removeParameter}
    />,
    <OrangeText
      text="パラメータを追加"
      onChange={pageCallbacks.form.queryParameters.addParameter}
    />,
  ];
};

const MeasurementUrlSettingEditForm: React.VFC<{
  pageParameters: useEditPage.EditPageParameters;
  pageCallbacks: useEditPage.EditPageCallbacks;
}> = ({ pageParameters, pageCallbacks }) => (
  <div className={styles.measurementUrlSettingEditForm}>
    {pageParameters.event.errors.length > 0 ? (
      <div className={styles.errors}>
        <ErrorMessages errors={pageParameters.event.errors} />
      </div>
    ) : undefined}
    <div className={styles.panel}>
      <Panel
        itemElements={
          pageParameters.mode.mode === MEASUREMENT_URL_SETTING_EDITOR_MODE.EDIT
            ? pageParameters.isLoadTargetMeasurementUrlSetting
              ? getEditPanelElements(pageParameters, pageCallbacks)
              : [
                  <LoadingView
                    iconElement={<LoadingIcon />}
                    textElement={<div>データ読み込み中．．．</div>}
                  />,
                ]
            : getEditPanelElements(pageParameters, pageCallbacks)
        }
      />
    </div>
    <div>
      <HorizontalLayout
        elements={[
          <TransparentButton
            text={"キャンセル"}
            onClick={() => pageCallbacks.event.onCancel()}
          />,
          <OrangeButton
            text={"保存"}
            onClick={() => pageCallbacks.event.onSave()}
          />,
        ]}
        alignment="right"
      />
    </div>
  </div>
);

export default MeasurementUrlSettingEditForm;
