import styles from "./TextArea.module.scss";

type TextAreaProps = {
  value: string,
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void,
  isError?: boolean,
}

const TextArea = ({
  value,
  onChange,
  isError,
}: TextAreaProps) => {
  return <textarea
    className={isError ? styles.textArea__error : styles.textArea}
    value={value}
    onChange={(e) => onChange(e)}
  />;
}

export default TextArea;
