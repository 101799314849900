import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import {
  ActionCreationRequest,
  SegmentationRuleCreationRequest,
} from "interfaces/requests";
import {
  ActionStatus,
  ActionTypes,
  ACTION_STATUS,
  ACTION_TYPES,
  DAY_OF_WEEK,
  DecorationEditDeviceType,
  DECORATION_EDIT_DEVICE_TYPE,
  DEFAULT_EXECUTE_TIMING,
  ExecuteTiming,
  LocationPattern,
  PathParameterPattern,
  PATH_PARAMETER_COMPARE_PATTERN,
  PROCESS_HOLIDAY_PATTERN,
  SegmentationRule,
} from "interfaces/models";
import {
  ActionCreationCommonCallbacks,
  ActionCreationContentsCallbacks,
  ActionCreationContentsPCStyleCallbacks,
  ActionCreationContentsStyleCallbacks,
  ActionCreationCustomCallbacks,
  ActionCreationCustomFormParameters,
  ActionCreationCustomParameters,
  ActionCreationModalType,
  ActionCreationPhase,
  ActionCreationPresetCallbacks,
  ActionCreationPresetFormParameters,
  ActionCreationPresetParameters,
  ActionCreationSettingsCallbacks,
  ActionCreationSettingsParameters,
  ActionOperationMode,
  ACTION_CREATION_MODAL_TYPE,
  ACTION_CREATION_PHASE,
  ACTION_OPERATION_MODE,
  ContentsSettingPhaseCategory,
  CONTENTS_SETTING_PHASE_CATEGORY,
  DesignSettingPhaseCategory,
  DESIGN_SETTING_PHASE_CATEGORY,
  SettingPhaseCategory,
  SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";

import { ActionDetail2, ActionRepository } from "utils/ActionsRepository";
import SearchParameters, { stringToEnumValue } from "utils/SearchParameters";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import { ActionCreationValidations } from "utils/action_creation/ActionCreationValidations";
import { initPresetActionContents } from "utils/action_creation/ContentsDefaultStyle";

import {
  Action,
  ActionContents,
  ActionCreationSettings,
  useActionCreationPagePresetState,
  useActionCreationSettingsState,
} from "app/hooks/actionCreationPage";
import { PresetActionSegmentationRuleGenerator } from "utils/action_creation/PresetActionSegmentationRuleGenerator";
import { ContentsParameterCodec } from "./ContentsParameterCodec";
import {
  ActionCreationMode,
  ACTION_CREATION_MODE,
  ActionContentsParameters,
  CONTENTS_TYPE,
  ContentsType,
  PositionType,
  POSITION_TYPE,
  ActionCreationContentsStyleParameters,
} from "interfaces/model/actionContentsParameters";
import { assertNever } from "utils/assertions";
import { filterUrlCondition } from "utils/UrlMatchPattern";
import useSearchParameters from "./useSearchParameters";

// ============================================================================
// Enum Parameters

export const PAGE_STATE = {
  INIT: "INIT", // initial state
  LOADING: "LOADING",
  LOADING_FAILED: "LOADING_FAILED",
  EDITABLE: "EDITABLE",
  // FIXME: Integrate with submit logic
  // CREATING: "CREATING",
  // COMPLETE: "COMPLETE", // terminal state
} as const;

// ============================================================================
// Type Definitions

export type PageState = typeof PAGE_STATE[keyof typeof PAGE_STATE];

export type ActionCreationPageViewState = {
  custom: ActionCreationCustomFormParameters;
  preset: ActionCreationPresetFormParameters;
};

type ActionCreationSearchParameters = {
  operation: ActionOperationMode;
  phase: ActionCreationPhase;
  mode?: ActionCreationMode;
  editActionId?: string;
};

// ============================================================================
// Functions

/**
 * operation = create:
 * - source_id: phase = mode_select
 * - exist source_id: phase = setting_edit
 * operation = edit:
 * - always: phase = setting_edit
 * @param searchParameters
 * @returns {ActionOperationMode, ActionCreationPhase, ActionCreationMode, string}
 */
const decodeSearchParameters = (
  searchParameters: SearchParameters
): ActionCreationSearchParameters => {
  const editActionId = searchParameters.edit_id ?? searchParameters.source_id;
  const defaultPhase =
    searchParameters.edit_id ?? searchParameters.source_id
      ? ACTION_CREATION_PHASE.SETTINGS_EDIT
      : ACTION_CREATION_PHASE.MODE_SELECT;
  const defaultMode = editActionId ? ACTION_CREATION_MODE.CUSTOM : undefined;

  return {
    operation: stringToEnumValue(
      searchParameters.operation,
      Object.values(ACTION_OPERATION_MODE),
      ACTION_OPERATION_MODE.CREATE
    ),
    phase: stringToEnumValue(
      searchParameters.phase,
      Object.values(ACTION_CREATION_PHASE),
      defaultPhase
    ),
    mode: stringToEnumValue(
      searchParameters.mode,
      Object.values(ACTION_CREATION_MODE),
      defaultMode
    ),
    editActionId: editActionId,
  };
};

/**
 * ActionDetail2 is illegal format
 * convert to new model
 */
class ActionCodec {
  decode(a: ActionDetail2): Action {
    const abTestARate =
      a.action.contents.balancing === "RANDOM"
        ? a.action.contents.balancing_option.details.a_rate
        : 1.0;

    const pattern = a.action.contents.patterns[0];

    switch (pattern.display_type) {
      case ACTION_TYPES.FULL_MODAL:
      case ACTION_TYPES.BOTTOM_MODAL:
        break;
      case ACTION_TYPES.TOGGLE_MODAL:
      default:
        throw new Error(`unsupported display_type ${pattern.display_type}`);
    }

    const contents: ActionContents = {
      abTestARate,
      actionType: pattern.display_type,
      html: pattern.html,
      details: a.action.contents.details,
    };

    return {
      uuid: a.action.action_id,
      name: a.actionNumber.action_detail.name,
      number: parseInt(a.actionNumber.number) || undefined,
      executionConditions: a.action.execution_conditions,
      contents: contents,
      status: a.action.status,
      cvUrl: a.actionNumber.action_detail.cv_url,
    };
  }
}

// ============================================================
// appState.edit

/**
 * action: null means cannot loaded
 */
type ActionCreationPageStateEdit =
  | {
      isEditMode: false;
    }
  | {
      isEditMode: true;
      isLoaded: boolean;
      action: Action | undefined;
    };

// ============================================================
// appState

export type ActionCreationPageState = {
  pageState: PageState;
  operation: ActionOperationMode;
  edit: ActionCreationPageStateEdit;
  mode: ActionCreationMode | undefined;
  phase: ActionCreationPhase;
  sourceAction: Action | null;
  createdAction: ActionDetail2 | null | undefined;
  segmentationRuleList?: SegmentationRule[];
  errorMessage?: Object;
  settingPhaseCategory: SettingPhaseCategory;
  status: ActionStatus;
  modalType: ActionCreationModalType | undefined;
  previewHtml: string;
  decorationEditDeviceType: DecorationEditDeviceType;
  actionCreationSettings: ActionCreationSettings;
  modeParams: {
    [ACTION_CREATION_MODE.CUSTOM]: ActionCreationCustomParameters;
    [ACTION_CREATION_MODE.PRESET]: ActionCreationPresetParameters;
  };
};

export type CreateAccountCreationPageAreaContentsCallbackModeForm = {
  customCallbacks: ActionCreationCustomCallbacks;
  presetCallbacks: ActionCreationPresetCallbacks;
  commonFormCallbacks: {
    commons: ActionCreationCommonCallbacks; // FIXME: do not give part of appCallback
    systems: {
      setErrorMessage: React.Dispatch<React.SetStateAction<{}>>;
      setStatus: React.Dispatch<React.SetStateAction<ActionStatus>>;
      setModalType: React.Dispatch<
        React.SetStateAction<ActionCreationModalType>
      >;
      setSettingPhaseCategory: React.Dispatch<
        React.SetStateAction<SettingPhaseCategory>
      >;
    };
    settings: ActionCreationSettingsCallbacks;
    contents: ActionCreationContentsCallbacks;
    styles: ActionCreationContentsStyleCallbacks;
  };
};

export type ActionCreationPageViewCallbacks = {
  viewStateCallbacks: {
    setErrorMessage: React.Dispatch<React.SetStateAction<{}>>;
    setStatus: React.Dispatch<React.SetStateAction<ActionStatus>>;
    setModalType: React.Dispatch<
      React.SetStateAction<ActionCreationModalType | undefined>
    >;
    setSettingPhaseCategory: React.Dispatch<
      React.SetStateAction<SettingPhaseCategory>
    >;
  };
  fromEventHandlers: ActionCreationSettingsCallbacks;
  contents: ActionCreationContentsCallbacks;
  styles: ActionCreationContentsStyleCallbacks;
  pcStyles: ActionCreationContentsPCStyleCallbacks;
  modeView: {
    [ACTION_CREATION_MODE.CUSTOM]: ActionCreationCustomCallbacks;
    [ACTION_CREATION_MODE.PRESET]: ActionCreationPresetCallbacks;
  };
};

export const HTML_PLACE_HOLDERS = {
  IMAGE_URL: "__EZCX_IMAGE_URL__",
} as const;

const useActionCreationFormState = (): [
  ActionCreation.FormState,
  React.Dispatch<Action | null>
] => {
  const [isIncludeUrlVisible, setIncludeUrlVisibility] =
    useState<boolean>(false);
  const [isExcludeUrlVisible, setExcludeUrlVisibility] =
    useState<boolean>(false);
  const [isExecuteTimingActive, setIsExecuteTimingActive] =
    useState<boolean>(false);
  return [
    useMemo(
      () => ({
        isIncludeUrlVisible,
        isExcludeUrlVisible,
        isExecuteTimingActive,
        setIncludeUrlVisibility,
        setExcludeUrlVisibility,
        setIsExecuteTimingActive,
      }),
      [isIncludeUrlVisible, isExcludeUrlVisible, isExecuteTimingActive]
    ),
    // initialize
    useCallback((sourceAction) => {
      const url_condition = sourceAction?.executionConditions.url_condition;
      setIncludeUrlVisibility(!!url_condition?.includes?.length);
      setExcludeUrlVisibility(!!url_condition?.excludes?.length);
      const executeTiming = sourceAction?.executionConditions.execute_timing;
      setIsExecuteTimingActive(
        executeTiming && executeTiming.length > 0 ? true : false
      );
    }, []),
  ];
};

const useActionCreationService = (
  actionRepository: ActionRepository,
  segmentationRuleRepository: SegmentationRuleRepository,
  defaultCvUrl: string | undefined
): [
  ActionCreationPageState,
  ActionCreationCommonCallbacks,
  ActionCreationPageViewCallbacks,
  ActionCreation.FormState
] => {
  // ==========================================================================
  // Load Global Parameters

  const history = useHistory();
  const [searchParameters, updateSearchParameters] = useSearchParameters();

  // ==========================================================================
  // Page const parameters

  const { operation, phase, mode, editActionId } = useMemo(
    () => decodeSearchParameters(searchParameters),
    [searchParameters]
  );

  // ==========================================================================
  // Page Status (part 1)

  const [pageState, setPageState] = useState<PageState>(PAGE_STATE.INIT);
  const [editTargetAction, setEditTargetAction] = useState<Action>();

  const [segmentationRuleList, setSegmentationRuleList] =
    useState<SegmentationRule[]>();

  const [actionNameList, setActionNameList] = useState<string[]>([]);

  const [createdAction, setCreatedAction] = useState<ActionDetail2 | null>();

  // custom only parameters
  const [contentsSettingPhaseCategory, setContentsSettingPhaseCategory] =
    useState<ContentsSettingPhaseCategory>(
      CONTENTS_SETTING_PHASE_CATEGORY.CONDITION
    );
  const [designPhaseCategory, setDesignPhaseCategory] =
    useState<DesignSettingPhaseCategory>(DESIGN_SETTING_PHASE_CATEGORY.LAYOUT);
  const [isCompleteSettings, setIsCompleteSettings] = useState<boolean>(false);

  const [presetParams, presetCallbacks] = useActionCreationPagePresetState();

  // System
  const [errorMessage, setErrorMessage] = useState<{}>({});
  const [status, setStatus] = useState<ActionStatus>(ACTION_STATUS.TESTING);
  const [modalType, setModalType] = useState<ActionCreationModalType>();
  const [settingPhaseCategory, setSettingPhaseCategory] =
    useState<SettingPhaseCategory>(SETTING_PHASE_CATEGORY.CONDITION);
  const [previewHtml, setPreviewHtml] = useState<string>("");
  const [decorationEditDeviceType, setDecorationEditDeviceType] =
    useState<DecorationEditDeviceType>(DECORATION_EDIT_DEVICE_TYPE.SP);

  const [actionCreationSettings, actionCreationSettingsSetters] =
    useActionCreationSettingsState(
      operation,
      mode,
      editTargetAction,
      defaultCvUrl
    );

  // ==========================================================================
  // Temp variables: Remove Me

  const customParams: ActionCreationCustomParameters = useMemo(
    () => ({
      contentsSettingPhaseCategory,
      designPhaseCategory,
      isCompleteSettings,
      isEditRawHTML: actionCreationSettings.contents.details.isEditingRawHtml,
    }),
    [
      contentsSettingPhaseCategory,
      designPhaseCategory,
      isCompleteSettings,
      actionCreationSettings.contents.details.isEditingRawHtml,
    ]
  );

  // ==========================================================================
  // Biz Logic (part 1)

  useEffect(() => {
    actionRepository.load().then((actions) => {
      setActionNameList(
        actions
          .filter((a) => a.details)
          .map((a) => a.details.action_detail.name)
      );
    });
  }, [actionRepository]);

  useEffect(() => {
    segmentationRuleRepository
      .load()
      .then((l) => setSegmentationRuleList(l))
      .catch((error) => {
        console.error(error);
      });
  }, [segmentationRuleRepository]);

  // ==========================================================================
  // Temp variables: Remove Me

  const customCallbacks: ActionCreationCustomCallbacks = useMemo(
    () => ({
      setContentsSettingPhaseCategory,
      setDesignPhaseCategory,
      setIsCompleteSettings,
      setIsEditRawHTML:
        actionCreationSettingsSetters.contents.details
          .actionCreationContentsCallbacks.setEditingRawHtml,
    }),
    [
      actionCreationSettingsSetters.contents.details
        .actionCreationContentsCallbacks,
    ]
  );

  /**
   * Integrate to HTML Editor
   */
  const resetContentsHtml = useCallback(
    (actionContentsParameters: ActionContentsParameters): void => {
      const generatedHtml = new ContentsParameterCodec().encodeToHtml(
        actionContentsParameters
      );
      if (generatedHtml !== null) {
        actionCreationSettingsSetters.contents.setHtmlTemplate(generatedHtml);
      }
    },
    [actionCreationSettingsSetters.contents]
  );

  // ==========================================================================
  // Biz Logic (part 2)

  // Spec: When error message updated, the message is focused.
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [errorMessage]);

  useEffect(() => {
    setPageState(PAGE_STATE.INIT);
  }, [editActionId]);

  useEffect(() => {
    switch (pageState) {
      case PAGE_STATE.INIT:
        if (editActionId) {
          setPageState(PAGE_STATE.LOADING);
          actionRepository
            .get(editActionId)
            .then((a) => {
              try {
                if (a) {
                  setEditTargetAction(new ActionCodec().decode(a));
                }
                setPageState(PAGE_STATE.EDITABLE);
              } catch (e) {
                if (e instanceof Error) {
                  setErrorMessage([e.message]);
                }
                setPageState(PAGE_STATE.LOADING_FAILED);
              }
            })
            .catch((e) => {
              if (e instanceof Error) {
                setErrorMessage([e.message]);
              }
              setPageState(PAGE_STATE.LOADING_FAILED);
            });
        } else {
          setPageState(PAGE_STATE.EDITABLE);
        }
        break;
      case PAGE_STATE.LOADING:
      case PAGE_STATE.LOADING_FAILED:
      case PAGE_STATE.EDITABLE:
        break;
      default:
        assertNever(pageState);
    }
  }, [actionRepository, pageState, editActionId]);

  useEffect(() => {
    const template = actionCreationSettings.contents.htmlTemplate;
    if (actionCreationSettings.contents.image) {
      setPreviewHtml(
        template.replaceAll(
          HTML_PLACE_HOLDERS.IMAGE_URL,
          URL.createObjectURL(actionCreationSettings.contents.image)
        )
      );
    } else if (actionCreationSettings.contents.details.styles.imageUrl) {
      setPreviewHtml(
        template.replaceAll(
          HTML_PLACE_HOLDERS.IMAGE_URL,
          actionCreationSettings.contents.details.styles.imageUrl
        )
      );
    } else {
      // FIXME: use dummy image
      setPreviewHtml(template);
    }
  }, [
    actionCreationSettings.contents.htmlTemplate,
    actionCreationSettings.contents.image,
    actionCreationSettings.contents.details.styles.imageUrl,
  ]);

  /**
   * onChange form value, update template html
   */
  useEffect(() => {
    const details = actionCreationSettings.contents.details;
    if (!details.isEditingRawHtml) {
      resetContentsHtml(details);
    }
  }, [resetContentsHtml, actionCreationSettings.contents.details]);

  useEffect(() => {
    if (mode === ACTION_CREATION_MODE.PRESET) {
      actionCreationSettingsSetters.executeConditions.setSegmentationRules({
        create: true,
        segmentationRuleCreationSettings:
          new PresetActionSegmentationRuleGenerator().execute(
            actionCreationSettings.contents.details.presetFormat,
            presetParams
          ),
      });
    }
  }, [
    mode,
    actionCreationSettings.contents.details.presetFormat,
    presetParams,
    actionCreationSettingsSetters.executeConditions,
  ]);

  // ========================================================
  // Temp variables: Remove Me

  const modeErrorCheckers = useMemo(() => {
    const actionCreationSettingsParameters: ActionCreationSettingsParameters = {
      name: actionCreationSettings.name,
      status: status,
      expirationPeriod:
        actionCreationSettings.executionConditions.expirationPeriod,
      segmentationRuleIds: actionCreationSettings.executionConditions
        .segmentationRules.create
        ? []
        : actionCreationSettings.executionConditions.segmentationRules
            .segmentationRuleIds,
      frequency: actionCreationSettings.executionConditions.frequency,
      terminateReaction:
        actionCreationSettings.executionConditions.terminateReaction,
      cvUrl: actionCreationSettings.cvUrl,
      abRate: actionCreationSettings.contents.abTestARate,
      html: actionCreationSettings.contents.htmlTemplate,
      previewHtml: previewHtml,
      actionType:
        actionCreationSettings.contents.contentsType === CONTENTS_TYPE.FULL
          ? ACTION_TYPES.FULL_MODAL
          : ACTION_TYPES.BOTTOM_MODAL,
      isExpirationPeriodEnabled:
        !!actionCreationSettings.executionConditions.expirationPeriod,
      executeTimings: actionCreationSettings.executionConditions.executeTimings,
    };

    return {
      [ACTION_CREATION_MODE.CUSTOM]: () =>
        ActionCreationValidations.check(
          actionCreationSettingsParameters,
          actionNameList,
          operation,
          editTargetAction?.name
        ),
      [ACTION_CREATION_MODE.PRESET]: () =>
        ActionCreationValidations.checkPreset(
          actionCreationSettingsParameters,
          presetParams
        ),
    };
  }, [
    actionCreationSettings,
    presetParams,
    previewHtml,
    status,
    actionNameList,
    editTargetAction,
    operation,
  ]);

  const processing = useMemo(() => {
    const sendNewSegmentation = async (
      request: SegmentationRuleCreationRequest
    ): Promise<SegmentationRule> => {
      return await segmentationRuleRepository.create(request);
    };

    const getErrors = (mode: ActionCreationMode) => {
      return modeErrorCheckers[mode]();
    };

    /**
     * @param mode FIXME: remove this dependency, this caused by html logic and error checker interface mismatch
     */
    const createAction = async (
      status: ActionStatus,
      actionCreationSettings: ActionCreationSettings,
      operation: ActionOperationMode,
      targetActionId?: string
    ): Promise<ActionDetail2> => {
      try {
        // ========================================================
        // STEP1: Upload Image

        const imageUrl = actionCreationSettings.contents.image
          ? await actionRepository.sendImage(
              actionCreationSettings.contents.image
            )
          : undefined;

        // ========================================================
        // STEP2: Set Image URL

        const html = actionCreationSettings.contents.htmlTemplate.replaceAll(
          HTML_PLACE_HOLDERS.IMAGE_URL,
          imageUrl || actionCreationSettings.contents.details.styles.imageUrl
        );

        // ========================================================
        // STEP3: Create Action and SegmentationRule (if needed)

        let segmentationRuleIds: string[];
        if (
          actionCreationSettings.executionConditions.segmentationRules.create
        ) {
          const newSegmentationRule: SegmentationRule =
            await sendNewSegmentation(
              actionCreationSettings.executionConditions.segmentationRules
                .segmentationRuleCreationSettings
            );

          segmentationRuleIds = [newSegmentationRule.segmentation_rule_id];
        } else {
          segmentationRuleIds =
            actionCreationSettings.executionConditions.segmentationRules
              .segmentationRuleIds;
        }
        // locations: ["*"], // XXX: this value never used
        // excludeLocations: [], // XXX: this value never used
        const request: ActionCreationRequest = {
          name: actionCreationSettings.name,
          status: status,
          action_type:
            actionCreationSettings.contents.contentsType === CONTENTS_TYPE.FULL
              ? ACTION_TYPES.FULL_MODAL
              : ACTION_TYPES.BOTTOM_MODAL,
          segmentation_rule_ids: Array.from(
            new Set(segmentationRuleIds)
          ).filter((e) => e !== ""),
          contents: {
            contents_patterns: [
              {
                html: html,
              },
              {
                html: "",
              },
            ],
            balancing_option: {
              balancing_type: "RANDOM",
              a_rate: actionCreationSettings.contents.abTestARate,
              pattern_a: 0,
              pattern_b: -1,
            },
            details: {
              ...actionCreationSettings.contents.details,
              styles: {
                ...actionCreationSettings.contents.details.styles,
                imageUrl:
                  imageUrl ||
                  actionCreationSettings.contents.details.styles.imageUrl,
              },
              pcStyles: {
                ...actionCreationSettings.contents.details.pcStyles,
              },
            },
          },
          expiration_period:
            actionCreationSettings.executionConditions.expirationPeriod,
          cv_url: actionCreationSettings.cvUrl,
          frequency: actionCreationSettings.executionConditions.frequency,
          terminate_reaction:
            actionCreationSettings.executionConditions.terminateReaction,
          execution_conditions: {
            url_condition: filterUrlCondition(
              actionCreationSettings.executionConditions.urlCondition
            ),
            execute_timing: actionCreationSettings.executionConditions
              .executeTimings
              ? actionCreationSettings.executionConditions.executeTimings
              : [],
          },

          // deprecated
          locations: ["*"],
          exclude_locations: [],
        };

        const createResponse =
          operation === ACTION_OPERATION_MODE.EDIT && targetActionId
            ? await actionRepository.update(targetActionId, request)
            : await actionRepository.add(request);

        const createdAction = await actionRepository.get(
          createResponse["action_id"]
        );
        if (!createdAction) {
          throw new Error("failed to get action");
        }

        return createdAction;
      } catch (error) {
        // FIXME: handle error
        console.error(error);

        throw error;
      }
    };

    return {
      getErrors,
      createAction,
    };
  }, [
    //
    // Objects: api accessors
    //
    actionRepository,
    segmentationRuleRepository,
    //
    // Interfaces: Output to app status
    //
    modeErrorCheckers,
  ]);

  // ========================================================
  // Return variables (part 1): Application Callbacks

  const appCallbacks: ActionCreationCommonCallbacks = useMemo(() => {
    const onChangeContentsAttribute = (
      contentsType: ContentsType,
      positionType: PositionType
    ): void => {
      if (
        contentsType === CONTENTS_TYPE.FULL &&
        positionType === POSITION_TYPE.CENTER
      ) {
        actionCreationSettingsSetters.contents.setContentsType(
          CONTENTS_TYPE.FULL
        );
      } else {
        actionCreationSettingsSetters.contents.setContentsType(
          CONTENTS_TYPE.PARTIAL
        );
      }
    };

    return {
      moveToActionIndexPage: () => {
        history.push("/actions");
      },
      closeModal: () => setModalType(undefined),
      cancel: () => setModalType(ACTION_CREATION_MODAL_TYPE.IS_CANCEL),
      submit: (active) => {
        setStatus(active ? ACTION_STATUS.ACTIVE : ACTION_STATUS.TESTING);
        setModalType(ACTION_CREATION_MODAL_TYPE.IS_SUBMIT);
      },
      selectMode: (mode) => {
        updateSearchParameters({
          phase: ACTION_CREATION_PHASE.FORMAT_SELECT,
          mode: mode,
        });
      },
      resetMode: () => {
        updateSearchParameters({
          phase: "",
          mode: "",
        });
      },
      changePhase: (phase) => {
        updateSearchParameters({
          phase: phase,
        });
      },
      changeDecorationEditDeviceType: (type) => {
        setDecorationEditDeviceType(type);
      },
      onSubmit: async () => {
        if (mode === undefined) {
          return;
        }

        const errors = processing.getErrors(mode);

        // FIXME: integrate with ActionCreationSettings
        setErrorMessage(errors);
        if (Object.keys(errors).length > 0) {
          return;
        }

        processing
          .createAction(
            status,
            actionCreationSettings,
            operation,
            editTargetAction?.uuid
          )
          .then((created) => {
            setCreatedAction(created);
          })
          .catch((error) => {
            setErrorMessage(["アクションの生成に失敗しました"]);
            setCreatedAction(null);
          });

        updateSearchParameters({
          phase: ACTION_CREATION_PHASE.COMPLETE,
        });
      },
      onResetHTML: () => {
        switch (mode) {
          case ACTION_CREATION_MODE.CUSTOM:
            actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setEditingRawHtml(
              false
            );
            return;
          case ACTION_CREATION_MODE.PRESET:
          case undefined:
            return;
          default:
            ((v: never) => {})(mode);
        }
      },
      selectPresetFormat: (format) => {
        if (mode === ACTION_CREATION_MODE.CUSTOM) {
          actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPresetFormat(
            format
          );
          setSettingPhaseCategory(SETTING_PHASE_CATEGORY.CONDITION);

          initPresetActionContents(
            format,
            actionCreationSettingsSetters.contents.details
              .actionCreationContentsStyleCallbacks
          );

          if (!ACTION_CREATION_MODE.CUSTOM) {
            updateSearchParameters({
              phase: ACTION_CREATION_PHASE.MODE_SELECT,
            });
          } else {
            updateSearchParameters({
              phase: ACTION_CREATION_PHASE.SETTINGS_EDIT,
              mode: ACTION_CREATION_MODE.CUSTOM,
            });
          }
        } else {
          actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPresetFormat(
            format
          );
          setSettingPhaseCategory(SETTING_PHASE_CATEGORY.CONDITION);

          initPresetActionContents(
            format,
            actionCreationSettingsSetters.contents.details
              .actionCreationContentsStyleCallbacks
          );

          // TODO: initialize action settings related with format type
          updateSearchParameters({
            phase: ACTION_CREATION_PHASE.SETTINGS_EDIT,
            mode: ACTION_CREATION_MODE.PRESET,
          });
        }
      },
      selectCustomFormat: (format) => {
        actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setEditingRawHtml(
          false
        );
        actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setFormat(
          format
        );
        // NOTE: reset contents pattern
        actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPattern(
          0
        );

        setSettingPhaseCategory(SETTING_PHASE_CATEGORY.VIEW);
        updateSearchParameters({
          phase: ACTION_CREATION_PHASE.SETTINGS_EDIT,
          mode: ACTION_CREATION_MODE.CUSTOM,
        });
      },
      addSegumentationRule: () => {
        const s = actionCreationSettings.executionConditions.segmentationRules;
        const NO_SELECTED_SEGMENTATION_RULE = "";
        if (!s.create) {
          const tmpIds = s.segmentationRuleIds;
          actionCreationSettingsSetters.executeConditions.setSegmentationRules({
            create: false,
            segmentationRuleIds: tmpIds.concat([NO_SELECTED_SEGMENTATION_RULE]),
          });
        }
      },
      deleteSegumentationRule: () => {
        const s = actionCreationSettings.executionConditions.segmentationRules;
        if (!s.create) {
          const e = s.segmentationRuleIds;

          if (e.length > 1) {
            e.pop();
            actionCreationSettingsSetters.executeConditions.setSegmentationRules(
              {
                create: false,
                segmentationRuleIds: e,
              }
            );
          }
        }
      },
      onChangeContentsAttribute,
      requestResettingHtml: () => {
        // NOTE: custom page only
        setModalType(ACTION_CREATION_MODAL_TYPE.IS_RESET);
      },
      changeContentsImage(image: File | undefined): void {
        actionCreationSettingsSetters.contents.setImage(image);
      },
      updateRawHtml: (source) => {
        // NOTE: custom page only
        actionCreationSettingsSetters.contents.setHtmlTemplate(source);
        actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setEditingRawHtml(
          true
        );
      },
      changeCategory: (category) => {
        // NOTE: custom page only
        customCallbacks.setDesignPhaseCategory(category);
      },
      changeContentsSettingPhaseCategory: (category) => {
        customCallbacks.setContentsSettingPhaseCategory(category);
      },
      addLoginUrlFrom: () => {
        // preset only
        let params = presetParams.loginUrl.parameters;

        params.push({
          key: "",
          value: "",
          pattern: PATH_PARAMETER_COMPARE_PATTERN.INCLUDE,
        });

        presetCallbacks.setLoginUrl({
          location: presetParams.loginUrl.location,
          parameters: params,
        });

        presetCallbacks.setNumberOfLoginUrlParameter(
          presetParams.numberOfLoginUrlParameter + 1
        );
      },
      removeLoginUrlFrom: () => {
        // preset only
        if (presetParams.numberOfLoginUrlParameter > 1) {
          let params = presetParams.loginUrl.parameters;

          params.pop();

          presetCallbacks.setLoginUrl({
            location: presetParams.loginUrl.location,
            parameters: params,
          });

          presetCallbacks.setNumberOfLoginUrlParameter(
            presetParams.numberOfLoginUrlParameter - 1
          );
        }
      },
      updateLoginUrlLocation: (location: LocationPattern) => {
        let url = presetParams.loginUrl;
        url.location[0] = location;
        presetCallbacks.setLoginUrl({ ...url });
      },
      updateLoginUrlParameter: (
        index: number,
        queryParameter: PathParameterPattern
      ) => {
        // preset only
        let url = presetParams.loginUrl;

        url.parameters[index] = queryParameter;

        presetCallbacks.setLoginUrl({ ...url });
      },
      updateLoginErrorCountSettings: (errorCount) => {
        // preset only
        if (errorCount === undefined) {
          presetCallbacks.setContinuousLoginErrorCondition(
            !presetParams.continuousLoginErrorCondition
          );
        } else {
          presetCallbacks.setLoginErrorCount(errorCount);
        }
      },
      setContentsType: (contentsType) => {
        // custom only
        actionCreationSettingsSetters.contents.setContentsType(contentsType);
        if (contentsType === CONTENTS_TYPE.FULL) {
          actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPositionType(
            POSITION_TYPE.CENTER
          );
          appCallbacks.onChangeContentsAttribute(
            contentsType,
            POSITION_TYPE.CENTER
          );
        } else if (contentsType === CONTENTS_TYPE.PARTIAL) {
          actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPositionType(
            POSITION_TYPE.CENTER_BOTTOM
          );
          appCallbacks.onChangeContentsAttribute(
            contentsType,
            POSITION_TYPE.CENTER_BOTTOM
          );
        }
      },
      setPositionType: (positionType) => {
        // custom only
        if (
          actionCreationSettings.contents.details.positionType !== positionType
        ) {
          actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPositionType(
            positionType
          );
          appCallbacks.onChangeContentsAttribute(
            actionCreationSettings.contents.details.contentsType,
            positionType
          );
        }
      },
      setContentsPattern: (pattern) => {
        // custom only
        actionCreationSettingsSetters.contents.details.actionCreationContentsCallbacks.setPattern(
          pattern
        );
      },
      updateIncludeLocation: (index, location) => {
        // FIXME: excludes items should be nullable
        const buffer = [
          ...actionCreationSettings.executionConditions.urlCondition.includes,
        ];

        buffer[index] = { locations: [location], parameters: [] };
        actionCreationSettingsSetters.executeConditions.urlConditionSetters.setIncludes(
          buffer
        );
      },
      updateExcludeLocation: (index, location) => {
        // FIXME: excludes items should be nullable
        const buffer = [
          ...actionCreationSettings.executionConditions.urlCondition.excludes,
        ];

        buffer[index] = { locations: [location], parameters: [] };
        actionCreationSettingsSetters.executeConditions.urlConditionSetters.setExcludes(
          buffer
        );
      },
      setSegmentationRules: (rules) => {
        actionCreationSettingsSetters.executeConditions.setSegmentationRules({
          create: false,
          segmentationRuleIds: rules,
        });
      },
      setExpirationPeriod: (period) => {
        if (period === undefined) {
          actionCreationSettingsSetters.executeConditions.setExpirationPeriod(
            null
          );
        } else {
          actionCreationSettingsSetters.executeConditions.setExpirationPeriod({
            from_date: period.fromDate || Math.floor(Date.now() / 1000),
            to_date: period.toDate || Math.floor(Date.now() / 1000),
          });
        }
      },
      removeUrlConditionIncludes: (index: number, formCount: number): void => {
        actionCreationSettingsSetters.executeConditions.urlConditionSetters.setIncludes(
          actionCreationSettings.executionConditions.urlCondition.includes
            .filter((e, i) => i !== index)
            .slice(0, formCount)
        );
      },
      removeUrlConditionExcludes: (index: number, formCount: number): void => {
        actionCreationSettingsSetters.executeConditions.urlConditionSetters.setExcludes(
          actionCreationSettings.executionConditions.urlCondition.excludes
            .filter((e, i) => i !== index)
            .slice(0, formCount)
        );
      },
      setIncludes:
        actionCreationSettingsSetters.executeConditions.urlConditionSetters
          .setIncludes,
      setExcludes:
        actionCreationSettingsSetters.executeConditions.urlConditionSetters
          .setExcludes,
      resetUrlConditionIncludes: (): void => {
        actionCreationSettingsSetters.executeConditions.urlConditionSetters.setIncludes(
          []
        );
      },
      resetUrlConditionExcludes: (): void => {
        actionCreationSettingsSetters.executeConditions.urlConditionSetters.setExcludes(
          []
        );
      },
      setExecuteTiming: (
        isExecuteTimingsActive: boolean,
        initialExecuteTimings: ExecuteTiming[] | undefined
      ): void => {
        let tmpExecuteTiming = [];
        if (!isExecuteTimingsActive) {
          tmpExecuteTiming = [];
        } else {
          if (initialExecuteTimings && initialExecuteTimings.length > 0) {
            tmpExecuteTiming = JSON.parse(
              JSON.stringify(initialExecuteTimings)
            );
          } else {
            tmpExecuteTiming = [DEFAULT_EXECUTE_TIMING];
          }
        }
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      deleteExecuteTimings: (index: number): void => {
        const tmpExecuteTimings =
          actionCreationSettings.executionConditions.executeTimings.filter(
            (e, i) => i !== index
          );
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTimings,
        ]);
      },
      insertExecuteTimings: (): void => {
        const tmpExecuteTiming = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
          DEFAULT_EXECUTE_TIMING,
        ]);
      },
      setExecuteTimingFromHourValue: (index: number, value: number): void => {
        const tmpExecuteTiming = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        tmpExecuteTiming[index].period.from_time.hour = value;
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      setExecuteTimingFromTimeValue: (index: number, value: number): void => {
        const tmpExecuteTiming = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        tmpExecuteTiming[index].period.from_time.minute = value;
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      setExecuteTimingToHourValue: (index: number, value: number): void => {
        const tmpExecuteTiming = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        tmpExecuteTiming[index].period.to_time.hour = value;
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      setExecuteTimingToTimeValue: (index: number, value: number): void => {
        const tmpExecuteTiming = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        tmpExecuteTiming[index].period.to_time.minute = value;
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      setExecuteTimingHoliday: (index: number): void => {
        let tmpExecuteTiming: ExecuteTiming[] = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        if (!tmpExecuteTiming[index].process_holiday) {
          tmpExecuteTiming[index].process_holiday = JSON.parse(
            JSON.stringify(DEFAULT_EXECUTE_TIMING.process_holiday)
          );
        }
        tmpExecuteTiming[index].process_holiday =
          tmpExecuteTiming[index].process_holiday ===
          PROCESS_HOLIDAY_PATTERN.INCLUDE
            ? PROCESS_HOLIDAY_PATTERN.EXCLUDE
            : PROCESS_HOLIDAY_PATTERN.INCLUDE;
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      setExecuteTimingDayOfWeek: (index: number, label: string): void => {
        let tmpExecuteTiming: ExecuteTiming[] = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        if (!tmpExecuteTiming[index].days) {
          tmpExecuteTiming[index].days = JSON.parse(
            JSON.stringify(DEFAULT_EXECUTE_TIMING.days)
          );
        }
        switch (label) {
          case DAY_OF_WEEK.MONDAY:
            tmpExecuteTiming[index].days.monday.is_active =
              !tmpExecuteTiming[index].days.monday.is_active;
            break;
          case DAY_OF_WEEK.TUESDAY:
            tmpExecuteTiming[index].days.tuesday.is_active =
              !tmpExecuteTiming[index].days.tuesday.is_active;
            break;
          case DAY_OF_WEEK.WEDNESDAY:
            tmpExecuteTiming[index].days.wednesday.is_active =
              !tmpExecuteTiming[index].days.wednesday.is_active;
            break;
          case DAY_OF_WEEK.THURSDAY:
            tmpExecuteTiming[index].days.thursday.is_active =
              !tmpExecuteTiming[index].days.thursday.is_active;
            break;
          case DAY_OF_WEEK.FRIDAY:
            tmpExecuteTiming[index].days.friday.is_active =
              !tmpExecuteTiming[index].days.friday.is_active;
            break;
          case DAY_OF_WEEK.SATURDAY:
            tmpExecuteTiming[index].days.saturday.is_active =
              !tmpExecuteTiming[index].days.saturday.is_active;
            break;
          case DAY_OF_WEEK.SUNDAY:
            tmpExecuteTiming[index].days.sunday.is_active =
              !tmpExecuteTiming[index].days.sunday.is_active;
            break;
          default:
            console.error("想定外の曜日値が設定されています。");
            break;
        }
        actionCreationSettingsSetters.executeConditions.setExecuteTimings([
          ...tmpExecuteTiming,
        ]);
      },
      getExecuteTimingHolidayState: (index: number): boolean => {
        const holidayState: boolean =
          actionCreationSettings.executionConditions.executeTimings[index]
            .process_holiday === PROCESS_HOLIDAY_PATTERN.INCLUDE;
        return holidayState;
      },
      getExecuteTimingDayOfWeekState: (
        index: number,
        value: string
      ): boolean => {
        let tmpExecuteTiming: ExecuteTiming[] = JSON.parse(
          JSON.stringify(
            actionCreationSettings.executionConditions.executeTimings
          )
        );
        if (!tmpExecuteTiming[index].days) {
          tmpExecuteTiming[index].days = JSON.parse(
            JSON.stringify(DEFAULT_EXECUTE_TIMING.days)
          );
        }
        const days = tmpExecuteTiming[index].days;
        switch (value) {
          case DAY_OF_WEEK.MONDAY:
            return days.monday.is_active;
          case DAY_OF_WEEK.TUESDAY:
            return days.tuesday.is_active;
          case DAY_OF_WEEK.WEDNESDAY:
            return days.wednesday.is_active;
          case DAY_OF_WEEK.THURSDAY:
            return days.thursday.is_active;
          case DAY_OF_WEEK.FRIDAY:
            return days.friday.is_active;
          case DAY_OF_WEEK.SATURDAY:
            return days.saturday.is_active;
          case DAY_OF_WEEK.SUNDAY:
            return days.sunday.is_active;
          default:
            return false;
        }
      },
      onActivePCStyle: () => {
        const pcCallbacks: ActionCreationContentsPCStyleCallbacks =
          actionCreationSettingsSetters.contents.details
            .actionCreationContentsPCStyleCallbacks;
        const spParams: ActionCreationContentsStyleParameters =
          actionCreationSettings.contents.details.styles;
        pcCallbacks.contentsBase.background.setWidth(
          spParams.contentsBaseWidth
        );
        pcCallbacks.contentsBase.background.setHeight(
          spParams.contentsBaseHeight
        );
        pcCallbacks.contentsBase.background.setMaxHeight(
          spParams.contentsBaseMaxHeight
        );
        pcCallbacks.contentsBase.background.setHeightAdjust(
          spParams.contentsBaseHeightAdjust
        );
        pcCallbacks.contentsBase.background.padding.setTop(
          spParams.contentsBaseTopPadding
        );
        pcCallbacks.contentsBase.background.padding.setBottom(
          spParams.contentsBaseBottomPadding
        );
        pcCallbacks.contentsBase.background.padding.setRight(
          spParams.contentsBaseRightPadding
        );
        pcCallbacks.contentsBase.background.padding.setLeft(
          spParams.contentsBaseLeftPadding
        );
        pcCallbacks.contentsBase.border.setRadius(spParams.contentsBaseRadius);
        pcCallbacks.headline.text.setSize(spParams.headlineTextSize);
        pcCallbacks.subHeadline.background.setRadius(
          spParams.subHeadlineBackgroundRadius
        );
        pcCallbacks.subHeadline.background.padding.setTop(
          spParams.subHeadlineBackgroundTopPadding
        );
        pcCallbacks.subHeadline.background.padding.setBottom(
          spParams.subHeadlineBackgroundBottomPadding
        );
        pcCallbacks.subHeadline.background.padding.setRight(
          spParams.subHeadlineBackgroundRightPadding
        );
        pcCallbacks.subHeadline.background.padding.setLeft(
          spParams.subHeadlineBackgroundLeftPadding
        );
        pcCallbacks.subHeadline.text.setSize(spParams.subHeadlineTextSize);
        pcCallbacks.article.setSize(spParams.textSize);
        pcCallbacks.button.background.setRadius(spParams.buttonBorderRadius);
        pcCallbacks.button.background.padding.setTop(spParams.buttonTopPadding);
        pcCallbacks.button.background.padding.setBottom(
          spParams.buttonBottomPadding
        );
        pcCallbacks.button.background.padding.setRight(
          spParams.buttonRightPadding
        );
        pcCallbacks.button.background.padding.setLeft(
          spParams.buttonLeftPadding
        );
        pcCallbacks.button.text.setSize(spParams.buttonTextSize);
        pcCallbacks.image.setMagnification(spParams.imageMagnification);
        pcCallbacks.couponCode.text.setSize(spParams.couponCodeTextSize);
        pcCallbacks.couponCopyButton.background.setRadius(
          spParams.couponButtonRadius
        );
        pcCallbacks.couponCopyButton.background.padding.setTop(
          spParams.couponButtonTopPadding
        );
        pcCallbacks.couponCopyButton.background.padding.setBottom(
          spParams.couponButtonBottomPadding
        );
        pcCallbacks.couponCopyButton.background.padding.setRight(
          spParams.couponButtonRightPadding
        );
        pcCallbacks.couponCopyButton.background.padding.setLeft(
          spParams.couponButtonLeftPadding
        );
        pcCallbacks.couponCopyButton.text.setSize(
          spParams.couponButtonTextSize
        );
        pcCallbacks.closeButton.setSize(spParams.closeButtonSize);
      },
    };
  }, [
    // external parameters
    history,
    updateSearchParameters,
    // model parameters
    mode,
    editTargetAction,
    presetParams,
    operation,
    //
    // Callbacks
    //
    // model callbacks
    processing,
    actionCreationSettings,
    actionCreationSettingsSetters,
    customCallbacks,
    presetCallbacks,
    status,
  ]);

  // ========================================================
  // Biz Logic (part 3): Merge to other biz logic code blocks

  // Spec: When form changed, run validation
  useEffect(() => {
    customCallbacks.setIsCompleteSettings(
      !Object.keys(modeErrorCheckers[ACTION_CREATION_MODE.CUSTOM]()).length
    );
    presetCallbacks.setIsCompleteSettings(
      !Object.keys(modeErrorCheckers[ACTION_CREATION_MODE.PRESET]()).length
    );
  }, [customCallbacks, presetCallbacks, modeErrorCheckers]);

  // ========================================================
  // Return variables (part 2):
  //   Merge to other return variables code blocks

  const viewCallbacks: ActionCreationPageViewCallbacks = useMemo(
    () => ({
      viewStateCallbacks: {
        setErrorMessage,
        setStatus,
        setModalType,
        setSettingPhaseCategory,
      },
      fromEventHandlers: {
        setName: actionCreationSettingsSetters.setName,
        setStatus: setStatus,
        setLocations: (l: string[]) =>
          actionCreationSettingsSetters.executeConditions.urlConditionSetters.setIncludes(
            l.map((v) => ({
              locations: [{ pattern: "include", value: v }],
              parameters: [],
            }))
          ),
        setExpirationPeriod:
          actionCreationSettingsSetters.executeConditions.setExpirationPeriod,
        setSegmentationRuleIds: (ids) =>
          actionCreationSettingsSetters.executeConditions.setSegmentationRules({
            create: false,
            segmentationRuleIds: ids,
          }),
        setExcludeLocations: (l: LocationPattern[]) => {
          actionCreationSettingsSetters.executeConditions.urlConditionSetters.setExcludes(
            l.map((p) => ({ locations: [p], parameters: [] }))
          );
        },
        setFrequency:
          actionCreationSettingsSetters.executeConditions.setFrequency,
        setTerminateReaction:
          actionCreationSettingsSetters.executeConditions.setTerminateReaction,
        setCvUrl: actionCreationSettingsSetters.setCvUrl,
        setABRate: actionCreationSettingsSetters.contents.setAbTestARate,
        setHtml: actionCreationSettingsSetters.contents.setHtmlTemplate,
        setActionType: (value: ActionTypes) =>
          actionCreationSettingsSetters.contents.setContentsType(
            value === ACTION_TYPES.FULL_MODAL
              ? CONTENTS_TYPE.FULL
              : CONTENTS_TYPE.PARTIAL
          ),
        setExpirationPeriodEnabled: () => {
          console.log(
            "this callback is deprecated, should be controlled by expirationPeriod"
          );
        },
      },
      contents:
        actionCreationSettingsSetters.contents.details
          .actionCreationContentsCallbacks,
      styles:
        actionCreationSettingsSetters.contents.details
          .actionCreationContentsStyleCallbacks,
      pcStyles:
        actionCreationSettingsSetters.contents.details
          .actionCreationContentsPCStyleCallbacks,
      modeView: {
        [ACTION_CREATION_MODE.CUSTOM]: customCallbacks,
        [ACTION_CREATION_MODE.PRESET]: presetCallbacks,
      },
    }),
    [
      actionCreationSettingsSetters,
      // other callbacks
      presetCallbacks,
      customCallbacks,
    ]
  );

  // FIXME: refactor structure
  const appState: ActionCreationPageState = useMemo(
    () => ({
      pageState,
      operation,
      edit:
        operation === ACTION_OPERATION_MODE.EDIT
          ? ({
              isEditMode: true,
              isLoaded:
                pageState !== PAGE_STATE.INIT &&
                pageState !== PAGE_STATE.LOADING,
              action: editTargetAction,
            } as const)
          : ({
              isEditMode: false,
            } as const),
      mode,
      phase,
      sourceAction: editTargetAction || null,
      createdAction,
      segmentationRuleList,
      errorMessage,
      status,
      modalType,
      previewHtml,
      decorationEditDeviceType,
      settingPhaseCategory,
      actionCreationSettings,
      modeParams: {
        [ACTION_CREATION_MODE.CUSTOM]: customParams,
        [ACTION_CREATION_MODE.PRESET]: presetParams,
      },
    }),
    [
      actionCreationSettings,
      createdAction,
      customParams,
      errorMessage,
      modalType,
      mode,
      phase,
      presetParams,
      segmentationRuleList,
      settingPhaseCategory,
      status,
      previewHtml,
      decorationEditDeviceType,
      editTargetAction,
      operation,
      pageState,
    ]
  );

  // ========================================================
  // Page Status (part 2):
  //   Merge to other page status code blocks

  const [formState, initFormState] = useActionCreationFormState();

  // ========================================================
  // Biz Logic (part 4): Merge to other biz logic code blocks

  // Spec: When page loaded with source action, init form state
  useEffect(() => {
    initFormState(appState.sourceAction);
  }, [appState.sourceAction, initFormState]);

  // ========================================================
  // Return: use [status, callbacks] instead

  return [
    appState,
    appCallbacks,
    viewCallbacks,
    {
      ...formState,
    },
  ];
};

export default useActionCreationService;
