import styles from "./DetailTable.module.scss";

export interface DetailTableProps {
  records: {
    label: JSX.Element;
    value: JSX.Element;
  }[];
}

// FIXME: label, valueはJSX.Elementとstringのどちらも受け付けられるようにすると利便性高い。
export const DetailTable = ({ records }: DetailTableProps) => {
  return (
    <div className={styles.detailTable}>
      <table className={styles.table}>
        <tbody>
          {records.map((r, idx) => (
            <tr key={idx} className={styles.tr}>
              <th className={styles.th}>{r.label}</th>
              <td className={styles.td}>{r.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailTable;
