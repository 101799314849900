import grassIcon from "images/icons/icon_grass.svg";
import styles from "./PreviewerButton.module.scss";
import fontStyles from "fontStyles.module.scss";
import React from "react";

const PreviewButton: React.VFC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <div className={styles.previewButton} onClick={onClick}>
      <img src={grassIcon} alt="" className={styles.icon} />
      <span className={fontStyles.note}>プレビュー</span>
    </div>
  );
};

export default PreviewButton;
