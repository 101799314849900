import styles from "./TransitionForm.module.scss";

type TransitionFormProps = {
  formElement: JSX.Element;
  deleteButtonElement?: JSX.Element;
};

const TransitionForm = ({
  formElement,
  deleteButtonElement,
}: TransitionFormProps) => {
  return (
    <div className={styles.transitionForm}>
      <div className={styles.form}>{formElement}</div>
      {deleteButtonElement ? (
        <div className={styles.delete}>{deleteButtonElement}</div>
      ) : undefined}
    </div>
  );
};

export default TransitionForm;
