import React, { useMemo } from "react";

import LoopIcon from "images/icons/icon_loop.svg";
import CopyButton from "atoms/accountPagesShared/CopyButton";
import OrangeHeaderTable, {
  OrangeHeaderTableRecord,
} from "atoms/ezPushShared/accountPageShared/OrangeHeaderTable";
import { JobHistory } from "types/job_history";
import { unixTimeToDateTime } from "utils/format/unixtime";
import { PreviewModal } from "./PushNotificationListModals";
import PushNotificationPreviewer from "./PushNotificationPreviewer";
import LoadingIcon from "atoms/LoadingIcon";
import CenteringLayout from "templates/ezPush/CenteringLayout";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

import TooltipIcon from "atoms/ezPushShared/accountPageShared/TooltipIcon";
import LinkText from "atoms/LinkText";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import VerticalTable from "atoms/ezPushShared/accountPageShared/VerticalTable";
import DeliveryScheduleRuleDescription from "molecules/v2/DeliveryScheduleRuleDescription";
import NotificationTitle from "molecules/v2/NotificationTitle";

const HEADER_NAMES = [
  "配信日時",
  "管理名",
  "セグメント指定",
  "配信数",
  "配信成功数",
  "操作",
];

const JobHistoryPreviewModal: React.VFC<{
  elem: JobHistory;
  hideContents: () => void;
}> = ({ elem, hideContents }) => {
  return (
    <PreviewModal
      previewer={
        elem.job.setting.contents ? (
          <VerticalSpreadLayout
            align="center"
            items={[
              <div style={{ margin: "30px" }}>
                <PushNotificationPreviewer
                  title={elem.job.setting.contents.title}
                  body={elem.job.setting.contents.body}
                  imageUrl={elem.job.setting.contents.image_url}
                />
              </div>,
              <VerticalTable
                items={[
                  {
                    label: "遷移先URL",
                    value: (
                      <LinkText
                        text={elem.job.setting.contents.redirect_url}
                        link={elem.job.setting.contents.redirect_url}
                        external={true}
                      />
                    ),
                  },
                  {
                    label: "配信サイクル",
                    value: elem.job.setting.schedule ? (
                      <DeliveryScheduleRuleDescription
                        rule={elem.job.setting.schedule}
                      />
                    ) : (
                      "-"
                    ),
                  },
                ]}
              />,
            ]}
          />
        ) : (
          <LoadingIcon />
        )
      }
      close={hideContents}
    />
  );
};

const JobHistoryList: React.VFC<{
  histories: JobHistory[] | null;
  copyHistory: (campaignUuid: string, jobId: number) => void;
  showContents: (contents: JSX.Element) => void;
  hideContents: () => void;
}> = ({ histories, copyHistory, showContents, hideContents }) => {
  const jobHistoryList: OrangeHeaderTableRecord[] | null = useMemo(() => {
    if (!histories) {
      return null;
    }

    // NOTE: 送信数が0のものは表示しない。急場での対応なので、0件でも表示するボタンなど作るかも。
    const filteredHistories = histories.filter(
      (hist) => hist.summary.total_count !== 0
    );

    const formatter = new DescriptionFormatter();

    return filteredHistories.map((elem) => {
      return {
        cells: [
          { item: unixTimeToDateTime(elem.job.created_at), width: 108 },
          {
            item: (
              <NotificationTitle
                mark={
                  elem.job.setting.schedule &&
                  elem.job.setting.schedule.frequency.frequency_type !== "ONCE"
                    ? LoopIcon
                    : undefined
                }
                title={formatter.substr(elem.job.campaign_name, 100)}
                onClickPreviewButton={() =>
                  showContents(
                    <JobHistoryPreviewModal
                      elem={elem}
                      hideContents={hideContents}
                    />
                  )
                }
              />
            ),
          },
          {
            item:
              elem.job.setting.destination && elem.job.setting.destination.name
                ? formatter.substr(elem.job.setting.destination.name)
                : "-",
          },
          {
            item: formatter.addCommasToNumber(elem.summary.total_count),
            isAlignRight: true,
          },
          {
            item: formatter.addCommasToNumber(elem.summary.success_count),
            isAlignRight: true,
          },
          {
            item: (
              <CenteringLayout
                element={
                  <TooltipIcon
                    iconElement={
                      <CopyButton
                        onClick={() =>
                          copyHistory(elem.job.campaign_uuid, elem.job.job_id)
                        }
                      />
                    }
                    comment="複製"
                  />
                }
              />
            ),
          },
        ],
      };
    });
  }, [histories, copyHistory, showContents, hideContents]);

  return (
    <OrangeHeaderTable
      headers={HEADER_NAMES}
      records={jobHistoryList}
      blankMessage="配信済みのプッシュ通知設定はありません"
    />
  );
};

export default JobHistoryList;
