import {
  COLOR_TYPE,
  ColorType,
} from "molecules/GradationColorPicker/ColorTypeSelector";
import { useMemo, useState } from "react";

export type GrantMessageContentsButtonDesignParameters = {
  colorType: ColorType;
  text: string;
  fontSize: number;
  fontColor: string;
  backgroundColor: string[];
  borderRadius: number;
  widthMargin: number;
  heightMargin: number;
};

export type GrantMessageContentsButtonDesignCallbacks = {
  updateColorType: (value: ColorType) => void;
  updateText: (value: string) => void;
  updateFontSize: (value: number) => void;
  updateFontColor: (value: string) => void;
  updateBackgroundColor: (value: string[]) => void;
  updateBorderRadius: (value: number) => void;
  updateWidthMargin: (value: number) => void;
  updateHeightMargin: (value: number) => void;
};

export const useGrantMessageContentsButtonDesign = (
  currentParameters: GrantMessageContentsButtonDesignParameters
): [
  GrantMessageContentsButtonDesignParameters,
  GrantMessageContentsButtonDesignCallbacks
] => {
  const [colorType, setColorType] = useState<ColorType>(
    currentParameters.colorType
      ? currentParameters.colorType
      : COLOR_TYPE.SIMPLE
  );
  const [text, setText] = useState<string>(currentParameters.text);
  const [fontSize, setFontSize] = useState<number>(currentParameters.fontSize);
  const [fontColor, setFontColor] = useState<string>(
    currentParameters.fontColor
  );
  const [backgroundColor, setBackgroundColor] = useState<string[]>(
    currentParameters.backgroundColor
  );

  const [borderRadius, setBorderRadius] = useState<number>(
    currentParameters.borderRadius
  );

  const [widthMargin, setWidthMargin] = useState<number>(
    currentParameters.widthMargin
  );

  const [heightMargin, setHeightMargin] = useState<number>(
    currentParameters.heightMargin
  );

  const parameters = useMemo(() => {
    return {
      colorType,
      text,
      fontSize,
      fontColor,
      backgroundColor,
      borderRadius,
      widthMargin,
      heightMargin,
    };
  }, [
    colorType,
    text,
    fontSize,
    fontColor,
    backgroundColor,
    borderRadius,
    widthMargin,
    heightMargin,
  ]);

  const callbacks = useMemo(() => {
    return {
      updateColorType: (value: ColorType) => {
        setColorType(value);
      },
      updateText: (value: string) => {
        setText(value);
      },
      updateFontSize: (value: number) => {
        setFontSize(value);
      },
      updateFontColor: (value: string) => {
        setFontColor(value);
      },
      updateBackgroundColor: (value: string[]) => {
        setBackgroundColor(value);
      },
      updateBorderRadius: (value: number) => {
        setBorderRadius(value);
      },
      updateWidthMargin: (value: number) => {
        setWidthMargin(value);
      },
      updateHeightMargin: (value: number) => {
        setHeightMargin(value);
      },
    };
  }, [
    setColorType,
    setText,
    setFontSize,
    setFontColor,
    setBackgroundColor,
    setBorderRadius,
    setWidthMargin,
    setHeightMargin,
  ]);

  return [parameters, callbacks];
};
