import styles from "./AccessStaticsTable.module.scss";
import AccessStatisticsTableColumn, {
  AccessStatisticsTableSettings,
} from "./AccessStatisticsTableColumn";
import {
  Accesses,
  itemType,
  DailyAccessesType,
  MonthlyAccessesType,
  ACCESSES_PERIOD_TYPE,
} from "interfaces/models";

const DAILY_ACCESS_REPORT_SLICE_COUNT = 30;
const MONTHLY_ACCESS_REPORT_SLICE_COUNT = 24;
const formatDate = (dateString: string, selectedTerm: string) => {
  const date: Date = new Date(
    selectedTerm === ACCESSES_PERIOD_TYPE.DAILY
      ? dateString
      : dateString + "-01"
  );
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return selectedTerm === ACCESSES_PERIOD_TYPE.DAILY
    ? year + "年" + month + "月" + day + "日"
    : year + "年" + month + "月";
};

const formatItems = (accesses: Accesses | undefined, selectedTerm: string) => {
  let items: itemType[] = [];
  if (accesses !== undefined) {
    const targetTerm: DailyAccessesType | MonthlyAccessesType =
      selectedTerm === ACCESSES_PERIOD_TYPE.DAILY
        ? accesses.daily
        : accesses?.monthly;
    const keysResponseAll = Object.keys(targetTerm.reports);
    const keysResponseSliced = keysResponseAll
      .reverse()
      .slice(
        0,
        selectedTerm === ACCESSES_PERIOD_TYPE.DAILY
          ? DAILY_ACCESS_REPORT_SLICE_COUNT
          : MONTHLY_ACCESS_REPORT_SLICE_COUNT
      );
    keysResponseSliced.forEach((e) => {
      items.push({
        date: formatDate(e, selectedTerm),
        pv: targetTerm.reports[e].value.pv.toLocaleString(),
        uu: targetTerm.reports[e].value.uu.toLocaleString(),
        cvuu: targetTerm.reports[e].value.cvuu.toLocaleString(),
        cvr: (targetTerm.reports[e].value.cvr * 100).toFixed(2) + "%",
      });
    });
  }
  return items;
};

type AccessStatisticsTableProps = {
  selectedTerm: string;
  accesses: Accesses | undefined;
};

const AccessStatisticsTable = ({
  selectedTerm,
  accesses,
}: AccessStatisticsTableProps) => {
  const columns: AccessStatisticsTableSettings[] = [
    selectedTerm === ACCESSES_PERIOD_TYPE.DAILY
      ? { key: ACCESSES_PERIOD_TYPE.DAILY, header: "日" }
      : { key: ACCESSES_PERIOD_TYPE.MONTHLY, header: "月" },
    { key: "pv", header: "PV" },
    { key: "uu", header: "UU" },
    { key: "cvuu", header: "CVUU" },
    { key: "cvr", header: "CVR" },
  ];

  return (
    <div className={styles.accessStatisticsTable}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr key="header">
            {columns.map((e: AccessStatisticsTableSettings) => (
              <AccessStatisticsTableColumn {...e} />
            ))}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {formatItems(accesses, selectedTerm).map((items) => (
            <tr className={styles.tr}>
              <td className={styles.td}>{items.date}</td>
              <td className={styles.td_right}>{items.pv}</td>
              <td className={styles.td_right}>{items.uu}</td>
              <td className={styles.td_right}>{items.cvuu}</td>
              <td className={styles.td_right}>{items.cvr}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccessStatisticsTable;
