import styles from "./ClientPageTemplateV2.module.scss";

export interface ClientPageTemplateV2Props {
  headerElement: JSX.Element;
  sideBarElement: JSX.Element;
  areaContents: JSX.Element;
  footerElement: JSX.Element;
  modalElement?: JSX.Element;
}

const ClientPageTemplateV2 = ({
  headerElement,
  sideBarElement,
  areaContents,
  footerElement,
  modalElement,
}: ClientPageTemplateV2Props) => {
  return (
    <div className={styles.ClientPageTemplateV2}>
      <div className={styles.header}>{headerElement}</div>
      <div className={styles.body}>
        <div className={styles.sideBar}>{sideBarElement}</div>
        <div className={styles.main}>
          <div className={styles.contents}>{areaContents}</div>
          <div className={styles.footer}>{footerElement}</div>
        </div>
      </div>
      {modalElement}
    </div>
  );
};

export { ClientPageTemplateV2 };
