import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { CognitoAuthOperations } from "../loaders/CognitoAuth";

import SignInRouteV2 from "./SignInRouteV2";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import useSession from "app/hooks/v2/useSession";
import AccountPageGroupRoutingV2 from "./AccountPageGroupRoutingV2";
import RestApi from "utils/RestApi";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import { useEffect } from "react";
import { V2HeadInitializer } from "app/process/headInitializer";

interface V2RouteProps {
  apiEndpointUrl: string;
  session: CognitoUserSession | null;
  operations: CognitoAuthOperations;
  adminTargetAccountId?: string;
  loginAccountId: string;
}

const V2Route = ({
  apiEndpointUrl,
  session,
  operations,
  adminTargetAccountId,
  loginAccountId,
}: V2RouteProps) => {
  const history = useHistory();
  const [jwtToken, onSessionExpired] = useSession(session, () =>
    history.push(V2_ROUTING_TABLE.AUTH.SESSION_EXPIRED)
  );
  const onSignOut = () => {
    operations.onSignOut();
    history.push(V2_ROUTING_TABLE.AUTH.SIGNED_OUT);
  };
  const onSystemError = () => {
    history.push(V2_ROUTING_TABLE.AUTH.SYSTEM_ERROR);
  };

  const signInRoute = (
    <SignInRouteV2
      onSignIn={operations.onSignIn}
      onCompleteNewPasswordChallenge={operations.onCompleteNewPasswordChallenge}
    />
  );

  useEffect(() => {
    new V2HeadInitializer().run();
  }, []);

  if (session && jwtToken) {
    const managementApi = new RestApi(
      apiEndpointUrl,
      jwtToken,
      adminTargetAccountId,
      onSessionExpired,
      onSystemError
    );

    return (
      <Switch>
        <Route path={V2_ROUTING_TABLE.AUTH.LOGIN}>{signInRoute}</Route>
        <Route>
          <AccountPageGroupRoutingV2
            managementApi={managementApi}
            loginAccountId={loginAccountId}
            onSignOut={onSignOut}
            onSystemError={onSystemError}
          />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path={V2_ROUTING_TABLE.AUTH.LOGIN}>{signInRoute}</Route>
        <Route>
          <Redirect to={V2_ROUTING_TABLE.AUTH.SESSION_EXPIRED} />
        </Route>
      </Switch>
    );
  }
};

export default V2Route;
