import styles from "./LoadingView.module.scss";

type LoadingViewProps = { iconElement: JSX.Element; textElement?: JSX.Element };

const LoadingView: React.VFC<LoadingViewProps> = ({
  iconElement,
  textElement,
}) => (
  <div className={styles.loadingView}>
    <div className={styles.elements}>
      <div className={styles.icon}>{iconElement}</div>
      <div>{textElement}</div>
    </div>
  </div>
);

export default LoadingView;
