// NOTE: new codec (2022/09).
import {
  CURRENT_PAGE_BEHAVIOR_TYPE,
  CUSTOMER_TYPE,
  DATA_TYPE,
  DATE_COMPARE_TARGET,
  DATE_COMPARE_TYPE,
  INTEGER_COMPARE_TYPE,
  PAST_BEHAVIOR_TYPE,
  SegmentationRuleCreation,
  SESSION_BEHAVIOR_TYPE,
  WORD_COMPARE_TYPE,
} from "interfaces/model/segmentationRuleCreation";
import {
  Condition,
  SegmentationRule,
  CustomerTypeCondition,
  CustomerTypes,
  TotalPvCountCondition,
  SessionStayingTimeCondition,
  ConditionTypes,
  PageStayingTimeCondition,
  PageScrollRateCondition,
  AccessBlankCondition,
  SessionPvCountCondition,
  SessionScenarioPvCondition,
  CustomerAttributeCondition,
  LOCATION_COMPARE_PATTERN,
  ComparePatterns,
  CompareOperation,
  IntegerCompareType,
  IntegerCompareTypes,
  IntegerCompareCondition,
  WordCompareCondition,
  DateCompareCondition,
  WordCompareTypesType,
  WordCompareTypes,
  DateCompareTypesType,
  DateCompareTypes,
  DateCompareTargetsType,
  DateCompareTargets,
  UrlPattern,
} from "interfaces/models";
import { SegmentationRuleCreationRequest } from "interfaces/requests";
import { assertNever } from "utils/assertions";

const ALL_PAGE = "*";

const TWO_YEARS_DAYS = 365 * 2;

export class SegmentationRuleCreationEncoder {
  encodeUrl(url: UrlPattern): UrlPattern {
    return {
      location:
        url.location.length > 0
          ? url.location
          : [
              {
                pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
                value: ALL_PAGE,
              },
            ],
      parameters: url.parameters,
    };
  }
  encodeDays(days: number): number {
    return days * 24;
  }

  encodeCurrentPage(
    currentPage: SegmentationRuleCreation.CurrentPageBehavior
  ): Condition | undefined {
    switch (currentPage.behaviorType) {
      case CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE: {
        if (currentPage.url) {
          const c: PageScrollRateCondition = {
            condition_type: ConditionTypes.PAGE_SCROLL_RATE,
            parameters: {
              url: this.encodeUrl(currentPage.url),
              scroll_rate: currentPage.value / 100,
            },
          };
          return c;
        } else {
          return undefined;
        }
      }
      case CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME: {
        if (currentPage.url) {
          const c: PageStayingTimeCondition = {
            condition_type: ConditionTypes.PAGE_STAYING_TIME,
            parameters: {
              url: this.encodeUrl(currentPage.url),
              stay_seconds: currentPage.value,
            },
          };
          return c;
        } else {
          return undefined;
        }
      }
      case CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK: {
        const c: AccessBlankCondition = {
          condition_type: ConditionTypes.ACCESS_BLANK,
          parameters: {
            min_range_hours: this.encodeDays(currentPage.value),
            max_range_hours: this.encodeDays(TWO_YEARS_DAYS),
          },
        };
        return c;
      }
      case CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED: {
        return undefined;
      }
      default: {
        assertNever(currentPage.behaviorType);
      }
    }
  }

  encodeSession(
    session: SegmentationRuleCreation.SessionBehavior
  ): Condition | undefined {
    switch (session.behaviorType) {
      case SESSION_BEHAVIOR_TYPE.PV_COUNT: {
        const c: SessionPvCountCondition = {
          condition_type: ConditionTypes.SESSION_PV_COUNT,
          parameters: {
            url: this.encodeUrl(session.url),
            count: session.value,
            operation: {
              compare_type: this.encodeIntegerCompareType(session.compareType),
              value: session.value,
            },
          },
        };
        return c;
      }
      case SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV: {
        const c: SessionScenarioPvCondition = {
          condition_type: ConditionTypes.SESSION_SCENARIO_PV,
          parameters: {
            pattern: Array(session.value).fill(this.encodeUrl(session.url)),
          },
        };
        return c;
      }
      case SESSION_BEHAVIOR_TYPE.STAYING_TIME: {
        const c: SessionStayingTimeCondition = {
          condition_type: ConditionTypes.SESSION_STAYING_TIME,
          parameters: {
            url: this.encodeUrl(session.url),
            stay_seconds: session.value,
          },
        };
        return c;
      }
      case SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT: {
        const c: SessionPvCountCondition = {
          condition_type: ConditionTypes.SESSION_PV_COUNT,
          parameters: {
            url: {
              location: [
                {
                  value: ALL_PAGE,
                  pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
                },
              ],
              parameters: [],
            },
            count: Math.round(session.value),
            operation: {
              compare_type: this.encodeIntegerCompareType(session.compareType),
              value: session.value,
            },
          },
        };
        return c;
      }
      case SESSION_BEHAVIOR_TYPE.NOT_SELECTED: {
        return undefined;
      }
      default: {
        assertNever(session.behaviorType);
      }
    }
  }

  encodePast(
    past: SegmentationRuleCreation.PastBehavior
  ): Condition | undefined {
    switch (past.behaviorType) {
      case PAST_BEHAVIOR_TYPE.PV_COUNT: {
        const c: TotalPvCountCondition = {
          condition_type: ConditionTypes.TOTAL_PV_COUNT,
          parameters: {
            url: this.encodeUrl(past.url),
            count: Math.round(past.value),
            operation: {
              operation_type: ComparePatterns.INTEGER,
              compare_type: this.encodeIntegerCompareType(past.compareType),
              value: past.value,
            },
            range_hours: this.encodeDays(past.rangeDays),
          },
        };
        return c;
      }
      case PAST_BEHAVIOR_TYPE.ALL_PV_COUNT: {
        const c: TotalPvCountCondition = {
          condition_type: ConditionTypes.TOTAL_PV_COUNT,
          parameters: {
            url: {
              location: [
                {
                  value: ALL_PAGE,
                  pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
                },
              ],
              parameters: [],
            },
            count: Math.round(past.value),
            operation: {
              operation_type: ComparePatterns.INTEGER,
              compare_type: this.encodeIntegerCompareType(past.compareType),
              value: past.value,
            },
            range_hours: this.encodeDays(past.rangeDays),
          },
        };
        return c;
      }
      case PAST_BEHAVIOR_TYPE.NOT_SELECTED: {
        return undefined;
      }
      default: {
        assertNever(past.behaviorType);
      }
    }
  }

  encodeBehaviors(
    data: SegmentationRuleCreation.Behavior
  ): (Condition | undefined)[] {
    const currentPageConditions = data.currentPageBehaviors.map((e) =>
      this.encodeCurrentPage(e)
    );

    const sessionConditions = data.sessionBehaviors.map((e) =>
      this.encodeSession(e)
    );

    const pastConditions = data.pastBehaviors.map((e) => this.encodePast(e));

    return currentPageConditions
      .concat(sessionConditions)
      .concat(pastConditions);
  }

  encodeCustomerType(
    customerType: SegmentationRuleCreation.CustomerType
  ): Condition | undefined {
    switch (customerType) {
      case CUSTOMER_TYPE.ALL:
        return undefined;
      case CUSTOMER_TYPE.EXISTING_USER_ONLY: {
        const c: CustomerTypeCondition = {
          condition_type: "customer_type",
          parameters: {
            key: "",
            customer_type: CustomerTypes.SIGNED_UP,
          },
        };
        return c;
      }
      case CUSTOMER_TYPE.NO_MEMBER_USER_ONLY: {
        const c: CustomerTypeCondition = {
          condition_type: "customer_type",
          parameters: {
            key: "",
            customer_type: CustomerTypes.SIGNED_OUT,
          },
        };
        return c;
      }
      default:
        assertNever(customerType);
    }
  }

  encodeIntegerCompareType(
    type: SegmentationRuleCreation.IntegerCompareType
  ): IntegerCompareType {
    if (type === INTEGER_COMPARE_TYPE.EQUAL_TO) {
      return IntegerCompareTypes.EqualTo;
    } else if (type === INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO) {
      return IntegerCompareTypes.GreaterThanOrEqualTo;
    } else if (type === INTEGER_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO) {
      return IntegerCompareTypes.LessThanOrEqualTo;
    }

    throw new Error(`Invalid type in encodeIntegerCompareType: ${type}`);
  }

  encodeWordCompareType(type: SegmentationRuleCreation.WordCompareType) {
    // NOTE: 判定条件と画面での表示名にはズレがある。歴史的経緯の問題。
    // csvで一つのカラムに複数のデータがセパレータで入っていたため、
    // 一致する(EQUAL)->複数データの少なくとも1カラムに完全一致する(INCLUDE)
    // 部分一致する(INCLUDE)->複数データの少なくとも1カラムに部分一致する(MATCH_ANY)
    if (type === WORD_COMPARE_TYPE.DEEP_EQUAL) {
      return WordCompareTypes.EQUAL;
    } else if (type === WORD_COMPARE_TYPE.EQUAL) {
      return WordCompareTypes.INCLUDE;
    } else if (type === WORD_COMPARE_TYPE.INCLUDE) {
      return WordCompareTypes.MATCH_ANY;
    } else {
      assertNever(type);
    }

    throw new Error(`Invalid type in encodeWordCompareType: ${type}`);
  }

  encodeDateType(type: SegmentationRuleCreation.DateCompareType) {
    if (type === DATE_COMPARE_TYPE.RELATIVE) {
      return DateCompareTypes.RELATIVE;
    }

    throw new Error(`Invalid type in encodeDateType: ${type}`);
  }

  encodeDateTarget(target: SegmentationRuleCreation.DateCompareTarget) {
    if (target === DATE_COMPARE_TARGET.DAY) {
      return DateCompareTargets.DAY;
    } else if (target === DATE_COMPARE_TARGET.MONTH) {
      return DateCompareTargets.MONTH;
    } else if (target === DATE_COMPARE_TARGET.YEAR) {
      return DateCompareTargets.YEAR;
    }
    throw new Error(`Invalid type in encodeDateTarget: ${target}`);
  }

  encodeCustomerAttribute(
    customerAttribute: SegmentationRuleCreation.CustomerAttribute
  ): Condition | undefined {
    if (customerAttribute.key.length === 0) {
      return undefined;
    }
    switch (customerAttribute.operation.type) {
      case DATA_TYPE.INTEGER: {
        const operation =
          customerAttribute.operation as SegmentationRuleCreation.IntegerCompareOperation;

        const c: CustomerAttributeCondition = {
          condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
          parameters: {
            key: customerAttribute.key,
            operation: {
              value: operation.value,
              operation_type: ComparePatterns.INTEGER,
              compare_type: this.encodeIntegerCompareType(
                operation.compareType
              ),
            },
          },
        };
        return c;
      }
      case DATA_TYPE.WORD: {
        const operation =
          customerAttribute.operation as SegmentationRuleCreation.WordCompareOperation;

        const c: CustomerAttributeCondition = {
          condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
          parameters: {
            key: customerAttribute.key,
            operation: {
              value: operation.value.toString(),
              operation_type: ComparePatterns.WORD,
              compare_type: this.encodeWordCompareType(operation.compareType),
            },
          },
        };
        return c;
      }
      case DATA_TYPE.DATE: {
        const operation =
          customerAttribute.operation as SegmentationRuleCreation.DateCompareOperation;

        const c: CustomerAttributeCondition = {
          condition_type: ConditionTypes.CUSTOMER_ATTRIBUTE,
          parameters: {
            key: customerAttribute.key,
            operation: {
              value: 0,
              operation_type: ComparePatterns.DATE,
              compare_type: this.encodeDateType(operation.compareType),
              compare_target: this.encodeDateTarget(operation.compareTarget),
            },
          },
        };
        return c;
      }
      default: {
        return undefined;
      }
    }
  }

  encodeCustomerAttributeInfo(
    data: SegmentationRuleCreation.CustomerAttributeInfo
  ): (Condition | undefined)[] {
    const customerTypeCondition: Condition | undefined =
      this.encodeCustomerType(data.customerType);

    const customerAttributeConditions: (Condition | undefined)[] =
      data.customerAttributes.map((e) => this.encodeCustomerAttribute(e));

    return customerAttributeConditions.concat(customerTypeCondition);
  }

  run(data: SegmentationRuleCreation.SegmentationRuleCreation): Condition[] {
    const behaviorConditions: Condition[] = this.encodeBehaviors(
      data.behavior
    ).filter((e): e is Exclude<typeof e, undefined> => e !== undefined);

    const customerAttributeInfo = this.encodeCustomerAttributeInfo(
      data.customer
    ).filter((e): e is Exclude<typeof e, undefined> => e !== undefined);

    return behaviorConditions.concat(customerAttributeInfo);
  }
}

export class SegmentationRuleCreationDecoder {
  decodeHours(hours: number): number {
    return Math.floor(hours / 24);
  }

  decodeCurrentPageBehavior(
    condition: Condition
  ): SegmentationRuleCreation.CurrentPageBehavior {
    if (condition.condition_type === ConditionTypes.PAGE_SCROLL_RATE) {
      const c = condition as PageScrollRateCondition;
      return {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE,
        url: c.parameters.url,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        value: c.parameters.scroll_rate * 100,
      };
    } else if (condition.condition_type === ConditionTypes.PAGE_STAYING_TIME) {
      const c = condition as PageStayingTimeCondition;
      return {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME,
        url: c.parameters.url,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        value: c.parameters.stay_seconds,
      };
    } else if (condition.condition_type === ConditionTypes.ACCESS_BLANK) {
      const c = condition as AccessBlankCondition;
      return {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        value: this.decodeHours(c.parameters.min_range_hours),
      };
    }

    throw new Error(
      `Invalid condition in decodeCurrentPageBehavior: ${condition.condition_type}`
    );
  }

  decodeSessionBehavior(
    condition: Condition
  ): SegmentationRuleCreation.SessionBehavior {
    if (condition.condition_type === ConditionTypes.SESSION_PV_COUNT) {
      const c = condition as SessionPvCountCondition;
      const compareType = c.parameters.operation
        ? (c.parameters.operation.compare_type as IntegerCompareType)
        : IntegerCompareTypes.GreaterThanOrEqualTo;

      const url = c.parameters.url;

      return {
        behaviorType:
          url.location[0].value === ALL_PAGE
            ? SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT
            : SESSION_BEHAVIOR_TYPE.PV_COUNT,
        url: url,
        compareType: this.decodeIntegerCompareType(compareType),
        value: c.parameters.operation
          ? c.parameters.operation.value
          : c.parameters.count,
      };
    } else if (
      condition.condition_type === ConditionTypes.SESSION_SCENARIO_PV
    ) {
      const c = condition as SessionScenarioPvCondition;
      return {
        behaviorType: SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV,
        url: c.parameters.pattern[0],
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        value: c.parameters.pattern.length,
      };
    } else if (
      condition.condition_type === ConditionTypes.SESSION_STAYING_TIME
    ) {
      const c = condition as SessionStayingTimeCondition;
      return {
        behaviorType: SESSION_BEHAVIOR_TYPE.STAYING_TIME,
        url: c.parameters.url,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        value: c.parameters.stay_seconds,
      };
    }

    throw new Error(
      `Invalid condition in decodeSessionBehavior: ${condition.condition_type}`
    );
  }

  decodeIntegerCompareType(
    type: IntegerCompareType
  ): SegmentationRuleCreation.IntegerCompareType {
    if (type === IntegerCompareTypes.GreaterThanOrEqualTo) {
      return INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO;
    } else if (type === IntegerCompareTypes.LessThanOrEqualTo) {
      return INTEGER_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO;
    } else if (type === IntegerCompareTypes.EqualTo) {
      return INTEGER_COMPARE_TYPE.EQUAL_TO;
    }

    throw new Error(`Invalid type in decodeIntegerCompareType: ${type}`);
  }

  decodeWordCompareType(
    type: WordCompareTypesType
  ): SegmentationRuleCreation.WordCompareType {
    if (type === WordCompareTypes.EQUAL) {
      return WORD_COMPARE_TYPE.DEEP_EQUAL;
    } else if (type === WordCompareTypes.INCLUDE) {
      return WORD_COMPARE_TYPE.EQUAL;
    } else if (type === WordCompareTypes.MATCH_ANY) {
      return WORD_COMPARE_TYPE.INCLUDE;
    }

    throw new Error(`Invalid type in decodeWordCompareType: ${type}`);
  }

  decodeDateCompareType(
    type: DateCompareTypesType
  ): SegmentationRuleCreation.DateCompareType {
    if (type === DateCompareTypes.RELATIVE) {
      return DATE_COMPARE_TYPE.RELATIVE;
    }

    throw new Error(`Invalid type in decodeDateCompareType: ${type}`);
  }

  decodeDateCompareTarget(
    type: DateCompareTargetsType
  ): SegmentationRuleCreation.DateCompareTarget {
    if (type === DateCompareTargets.YEAR) {
      return DATE_COMPARE_TARGET.YEAR;
    } else if (type === DateCompareTargets.MONTH) {
      return DATE_COMPARE_TARGET.MONTH;
    } else if (type === DateCompareTargets.DAY) {
      return DATE_COMPARE_TARGET.DAY;
    }

    throw new Error(`Invalid type in decodeDateCompareTarget: ${type}`);
  }

  decodePastBehavior(
    condition: Condition
  ): SegmentationRuleCreation.PastBehavior {
    if (condition.condition_type === ConditionTypes.TOTAL_PV_COUNT) {
      const c = condition as TotalPvCountCondition;
      const compareType = c.parameters.operation
        ? (c.parameters.operation.compare_type as IntegerCompareType)
        : IntegerCompareTypes.GreaterThanOrEqualTo;

      const url = c.parameters.url;

      return {
        behaviorType:
          url.location[0].value === ALL_PAGE
            ? PAST_BEHAVIOR_TYPE.ALL_PV_COUNT
            : PAST_BEHAVIOR_TYPE.PV_COUNT,
        url: url,
        compareType: this.decodeIntegerCompareType(compareType),
        value: c.parameters.operation
          ? c.parameters.operation.value
          : c.parameters.count,
        rangeDays: c.parameters.range_hours
          ? this.decodeHours(c.parameters.range_hours)
          : TWO_YEARS_DAYS,
      };
    }

    throw new Error(
      `Invalid condition in decodePastBehavior: ${condition.condition_type}`
    );
  }

  decodeCustomerType(
    condition: Condition
  ): SegmentationRuleCreation.CustomerType {
    if (condition.condition_type === ConditionTypes.CUSTOMER_TYPE) {
      const c = condition as CustomerTypeCondition;

      if (c.parameters.customer_type === CustomerTypes.SIGNED_UP) {
        return CUSTOMER_TYPE.EXISTING_USER_ONLY;
      } else if (c.parameters.customer_type === CustomerTypes.SIGNED_OUT) {
        return CUSTOMER_TYPE.NO_MEMBER_USER_ONLY;
      }
    }

    return CUSTOMER_TYPE.ALL;
  }

  decodeCompareOperation(
    operation: CompareOperation
  ): SegmentationRuleCreation.CompareOperation {
    if (operation.operation_type === ComparePatterns.INTEGER) {
      const o = operation as IntegerCompareCondition;
      const r: SegmentationRuleCreation.IntegerCompareOperation = {
        type: DATA_TYPE.INTEGER,
        compareType: this.decodeIntegerCompareType(o.compare_type),
        value: o.value,
      };
      return r;
    } else if (operation.operation_type === ComparePatterns.WORD) {
      const o = operation as WordCompareCondition;
      const r: SegmentationRuleCreation.WordCompareOperation = {
        type: DATA_TYPE.WORD,
        compareType: this.decodeWordCompareType(o.compare_type),
        value: o.value,
      };
      return r;
    } else if (operation.operation_type === ComparePatterns.DATE) {
      const o = operation as DateCompareCondition;
      const r: SegmentationRuleCreation.DateCompareOperation = {
        type: DATA_TYPE.DATE,
        compareType: this.decodeDateCompareType(o.compare_type),
        compareTarget: this.decodeDateCompareTarget(o.compare_target),
        value: o.value,
      };
      return r;
    }

    throw new Error(
      `Invalid operation in decodeCompareOperation: ${operation.operation_type}`
    );
  }

  decodeCustomerAttribute(
    condition: Condition
  ): SegmentationRuleCreation.CustomerAttribute {
    if (condition.condition_type === ConditionTypes.CUSTOMER_ATTRIBUTE) {
      const c = condition as CustomerAttributeCondition;
      return {
        key: c.parameters.key,
        operation: this.decodeCompareOperation(c.parameters.operation),
      };
    }

    throw new Error(
      `Invalid condition in decodeCustomerAttribute: ${condition.condition_type}`
    );
  }

  isCurrentPageBehavior(conditionType: string) {
    const types: string[] = [
      ConditionTypes.PAGE_SCROLL_RATE,
      ConditionTypes.PAGE_STAYING_TIME,
      ConditionTypes.ACCESS_BLANK,
    ];
    return types.includes(conditionType);
  }

  isSessionBehavior(conditionType: string) {
    const types: string[] = [
      ConditionTypes.SESSION_PV_COUNT,
      ConditionTypes.SESSION_SCENARIO_PV,
      ConditionTypes.SESSION_STAYING_TIME,
    ];
    return types.includes(conditionType);
  }

  isPastBehavior(conditionType: string) {
    const types: string[] = [ConditionTypes.TOTAL_PV_COUNT];
    return types.includes(conditionType);
  }

  isCustomerType(conditionType: string) {
    const types: string[] = [ConditionTypes.CUSTOMER_TYPE];
    return types.includes(conditionType);
  }

  isCustomerAttribute(conditionType: string) {
    const types: string[] = [
      ConditionTypes.CUSTOMER_ATTRIBUTE,
      ConditionTypes.TRACKING_ATTRIBUTE,
    ];
    return types.includes(conditionType);
  }

  run(
    data: SegmentationRule
  ): SegmentationRuleCreation.SegmentationRuleCreation {
    const currentPageBehaviors: Condition[] = data.conditions.filter((c) =>
      this.isCurrentPageBehavior(c.condition_type)
    );
    const sessionBehaviors: Condition[] = data.conditions.filter((c) =>
      this.isSessionBehavior(c.condition_type)
    );
    const pastBehaviors: Condition[] = data.conditions.filter((c) =>
      this.isPastBehavior(c.condition_type)
    );
    const customerTypes: Condition[] = data.conditions.filter((c) =>
      this.isCustomerType(c.condition_type)
    );
    const customerAttributes: Condition[] = data.conditions.filter((c) =>
      this.isCustomerAttribute(c.condition_type)
    );

    return {
      name: data.name,
      behavior: {
        currentPageBehaviors: currentPageBehaviors.map((c) =>
          this.decodeCurrentPageBehavior(c)
        ),
        sessionBehaviors: sessionBehaviors.map((s) =>
          this.decodeSessionBehavior(s)
        ),
        pastBehaviors: pastBehaviors.map((p) => this.decodePastBehavior(p)),
      },
      customer: {
        customerType:
          customerTypes.length > 0
            ? customerTypes.map((c) => this.decodeCustomerType(c))[0]
            : CUSTOMER_TYPE.ALL,
        customerAttributes: customerAttributes.map((c) =>
          this.decodeCustomerAttribute(c)
        ),
      },
    };
  }
}

class SegmentationRuleCreationCodec {
  encoder: SegmentationRuleCreationEncoder;
  decoder: SegmentationRuleCreationDecoder;

  constructor() {
    this.encoder = new SegmentationRuleCreationEncoder();
    this.decoder = new SegmentationRuleCreationDecoder();
  }
  encode(
    segmentationRuleCreation: SegmentationRuleCreation.SegmentationRuleCreation
  ): SegmentationRuleCreationRequest {
    return {
      name: segmentationRuleCreation.name,
      conditions: this.encoder.run(segmentationRuleCreation),
    };
  }

  decode(
    data: SegmentationRule
  ): SegmentationRuleCreation.SegmentationRuleCreation {
    return this.decoder.run(data);
  }
}

export default SegmentationRuleCreationCodec;
