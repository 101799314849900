import styles from "./TypeSelector.module.scss";

type TypeSelectorProps = {
  currentValue: string;
  options: { label: string; value: string }[];
  onClick: (value: any) => void;
};

const TypeSelector = ({
  currentValue,
  options,
  onClick,
}: TypeSelectorProps) => {
  return (
    <div className={styles.typeSelector}>
      {options.map((e) => (
        <div
          key={e.label}
          className={
            currentValue === e.value ? styles.cell__active : styles.cell
          }
          onClick={() => onClick(e.value)}
        >
          {e.label}
        </div>
      ))}
    </div>
  );
};

export default TypeSelector;
