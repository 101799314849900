import {
  ActionCreationMode,
  ACTION_CREATION_MODE,
} from "interfaces/model/actionContentsParameters";
import {
  SettingPhaseCategory,
  SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";
import StepBar from "molecules/StepBar";

type CreateActionCreationSettingEditStepBarProps = {
  mode?: ActionCreationMode;
  settingPhaseCategory: SettingPhaseCategory;
};

const CreateActionCreationSettingEditStepBar = ({
  mode,
  settingPhaseCategory,
}: CreateActionCreationSettingEditStepBarProps): JSX.Element => {
  const steps = {
    condition: { text: "条件設定", value: SETTING_PHASE_CATEGORY.CONDITION },
    view: { text: "表示設定", value: SETTING_PHASE_CATEGORY.VIEW },
  };

  return (
    <StepBar
      currentStep={settingPhaseCategory}
      items={
        mode && mode === ACTION_CREATION_MODE.CUSTOM
          ? [
              { text: `${steps.view.text}`, step: steps.view.value },
              { text: `${steps.condition.text}`, step: steps.condition.value },
            ]
          : [
              { text: `${steps.condition.text}`, step: steps.condition.value },
              { text: `${steps.view.text}`, step: steps.view.value },
            ]
      }
    />
  );
};

export default CreateActionCreationSettingEditStepBar;
