import React, { ButtonHTMLAttributes, useState } from "react";
// DatePicker
import DatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";

// self
import "./DatePickerWrapper.scss";
import containerStyles from "./DatePickerContainer.module.scss";

import DatePickerOpener from "./DatePickerOpener";

const locale = { ...ja, options: { ...ja.options, weekStartsOn: 1 } } as Locale;

interface DatePickerWrapperProps {
  date: Date;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const DatePickerWrapper = ({
  date,
  onChange,
  minDate,
  maxDate,
}: DatePickerWrapperProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const Opener = React.forwardRef(
    (
      props: React.DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      ref: React.Ref<HTMLDivElement>
    ) => {
      return (
        <div ref={ref}>
          <DatePickerOpener
            date={date}
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      );
    }
  );

  const DatePickerContainer = ({
    className,
    children,
  }: {
    className: string;
    children: React.ReactNode[];
  }): React.ReactNode => {
    return (
      <div className={containerStyles.dateRangePickerContainer}>
        <div className={className}>
          <div className={containerStyles.main}>
            {children[0]}
            {children[1]}
            {children[2]}
          </div>
        </div>
        <div>{children[7]}</div>
      </div>
    );
  };

  return (
    <DatePicker
      // dates
      selected={date}
      startDate={date}
      // designs
      calendarContainer={DatePickerContainer}
      customInput={<Opener />}
      showPopperArrow={false}
      focusSelectedMonth
      // events
      onChange={(e) => {
        if (e && !Array.isArray(e)) {
          onChange(e);
        }
      }}
      shouldCloseOnSelect={false}
      disabledKeyboardNavigation
      open={isOpen}
      onClickOutside={() => setIsOpen(false)}
      // range
      showDisabledMonthNavigation
      // locale
      locale={locale}
      dateFormatCalendar={"yyyy/MM"}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default DatePickerWrapper;
