import { useCallback, useEffect, useState } from "react";
import { PushNotificationEditSearchParameters } from "./usePushNotificationEditPageSearchParameters";
import {
  PUSH_NOTIFICATION_EDITOR_MODE,
  PUSH_NOTIFICATION_GENRE_TYPE,
} from "app/system_defaults/v2_routing";
import RestApi from "utils/RestApi";
import { DraftCampaign } from "types/campaign";
import { JobHistory } from "types/job_history";
import { PushNotificationContentsParameters } from "./usePushNotificationContents";
import CampaignRepository from "utils/repositories/CampaignRepository";
import JobHistoryRepository from "utils/repositories/JobHistoryRepository";

// FIXME: Edit Resourceという名前がイケてないので変えてほしい。いい案が思いつかない
export type PushNotificationEditResource = {
  name: string;
  campaign_uuid: string | null;
  contents: PushNotificationContentsParameters;
  destination_collect_rule: {
    destination_collect_rule_uuid: string;
    name: string;
    segmentation_rule_tree: string;
  };
  delivery_schedule: {
    delivery_schedule_uuid: string;
    name: string;
    schedule_rule: string;
    start_at: number;
    end_at: number;
  } | null;
};

class EditResourceCreator {
  createFromCampaign = (
    campaign: DraftCampaign,
    isEditMode: boolean
  ): PushNotificationEditResource => {
    return {
      name: isEditMode ? campaign.name : `${campaign.name}_copy`,
      campaign_uuid: isEditMode ? campaign.campaign_uuid : null,
      contents: {
        contentsUuid: campaign.contents.contents_uuid,
        title: campaign.contents.title,
        body: campaign.contents.body,
        isNeedImage: !!campaign.contents.image_url,
        image: null,
        imageUrl: campaign.contents.image_url,
        redirectUrl: campaign.contents.redirect_url,
        isNewImage: false,
      },
      destination_collect_rule: campaign.destination_collect_rule,
      delivery_schedule: null,
    };
  };

  createFromJobHistory = (
    jobHistory: JobHistory
  ): PushNotificationEditResource => {
    return {
      name: `${jobHistory.job.campaign_name}_copy`,
      campaign_uuid: null,
      contents: jobHistory.job.setting.contents
        ? {
            contentsUuid: "",
            title: jobHistory.job.setting.contents.title,
            body: jobHistory.job.setting.contents.body,
            isNeedImage: !!jobHistory.job.setting.contents.image_url,
            image: null,
            imageUrl: jobHistory.job.setting.contents.image_url,
            redirectUrl: jobHistory.job.setting.contents.redirect_url,
            isNewImage: false,
          }
        : {
            contentsUuid: "",
            title: "",
            body: "",
            isNeedImage: true,
            image: null,
            imageUrl: "",
            redirectUrl: "",
            isNewImage: false,
          },
      destination_collect_rule: jobHistory.job.setting.destination
        ? {
            destination_collect_rule_uuid:
              jobHistory.job.setting.destination.destination_collect_rule_uuid,
            name: jobHistory.job.setting.destination.name,
            segmentation_rule_tree:
              jobHistory.job.setting.destination.segmentation_rule_tree,
          }
        : {
            destination_collect_rule_uuid: "",
            name: "",
            segmentation_rule_tree: "",
          },
      delivery_schedule: null,
    };
  };
}

const useEditResource = (
  managementApi: RestApi,
  searchParameters: PushNotificationEditSearchParameters
): [PushNotificationEditResource | null, (campaign: DraftCampaign) => void] => {
  const [editResource, setEditResource] =
    useState<PushNotificationEditResource | null>(null);

  const updateEditResource = useCallback((campaign: DraftCampaign) => {
    setEditResource(
      new EditResourceCreator().createFromCampaign(campaign, true)
    );
  }, []);

  const loadEditResourceDraftCampaign = useCallback(
    async (campaignUuid: string) => {
      const response = await new CampaignRepository(managementApi).get({
        campaign_uuid: campaignUuid,
      });
      if (response) {
        setEditResource(
          new EditResourceCreator().createFromCampaign(
            response.item,
            searchParameters.mode === PUSH_NOTIFICATION_EDITOR_MODE.EDIT
          )
        );
      }
    },
    [managementApi, searchParameters.mode]
  );

  const loadJobHistory = useCallback(
    async (campaignUuid: string, jobId: string) => {
      const response = await new JobHistoryRepository(managementApi).get({
        campaignUuid: campaignUuid,
        jobId: jobId,
      });
      if (response) {
        setEditResource(
          new EditResourceCreator().createFromJobHistory(response.item)
        );
      }
    },
    [managementApi]
  );

  const autoLoadEditResource = useCallback(async () => {
    if (editResource === null) {
      if (searchParameters.mode === PUSH_NOTIFICATION_EDITOR_MODE.EDIT) {
        if (
          searchParameters.genre === PUSH_NOTIFICATION_GENRE_TYPE.DRAFT &&
          searchParameters.campaignUuid
        ) {
          loadEditResourceDraftCampaign(searchParameters.campaignUuid);
        }
      } else if (searchParameters.mode === PUSH_NOTIFICATION_EDITOR_MODE.COPY) {
        if (
          searchParameters.genre === PUSH_NOTIFICATION_GENRE_TYPE.DRAFT &&
          searchParameters.campaignUuid
        ) {
          loadEditResourceDraftCampaign(searchParameters.campaignUuid);
        } else if (
          searchParameters.genre === PUSH_NOTIFICATION_GENRE_TYPE.DELIVERED &&
          searchParameters.campaignUuid &&
          searchParameters.jobId
        ) {
          loadJobHistory(searchParameters.campaignUuid, searchParameters.jobId);
        }
      }
    }
  }, [
    searchParameters,
    editResource,
    loadEditResourceDraftCampaign,
    loadJobHistory,
  ]);

  useEffect(() => {
    autoLoadEditResource();
  }, [autoLoadEditResource]);

  return [editResource, updateEditResource];
};

export default useEditResource;
