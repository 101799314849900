import React from "react";
import styles from "./Tabs.module.scss";

import TableTab, { TableTabProps } from "./TableTab";

const Tabs: React.VFC<{ tabProps: TableTabProps[] }> = ({ tabProps }) => (
  <div className={styles.tabs}>
    {tabProps.map((elem, i) => (
      <div className={styles.tab} key={i}>
        <TableTab {...elem} />
      </div>
    ))}
  </div>
);

export default Tabs;
