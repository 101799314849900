import CreateSegmentationRuleCreationContentsInput from "./CreateSegmentationRuleCreationContentsInput";
import CreateSegmentationRuleCreationContentsComplete from "./CreateSegmentationRuleCreationContentsComplete";

import SegmentationRuleCreationView from "organisms/account/SegmentationRuleCreationView";
import SegmentationRuleCreationHeader from "organisms/account/segmentation_rule_creation/SegmentationRuleCreationHeader";
import ErrorMessage from "molecules/ErrorMessage";
import HeadlineText from "atoms/HeadlineText";
import AssertNever from "atoms/AssertNever";

import {
  Phase,
  SegmentationRuleCreationMode,
  SegmentationRuleCreationModeType,
} from "interfaces/view/segmentationCreation";
import {
  SegmentationRuleCreationRenderingState,
  SegmentationRuleCreationServiceCallbacks,
} from "app/hooks/useSegmentationRuleCreationService";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";

const getTitle = (mode: SegmentationRuleCreationModeType | undefined) => {
  switch (mode) {
    case SegmentationRuleCreationMode.CREATE:
      return "カスタマーセグメント作成";
    case SegmentationRuleCreationMode.EDIT:
      return "カスタマーセグメント編集";
    default:
      console.error("Invalid mode");
      return "";
  }
};

export type CreateSegmentationRuleCreationViewProps = {
  params: SegmentationRuleCreationRenderingState;
  callbacks: SegmentationRuleCreationServiceCallbacks;
};

const CreateSegmentationRuleCreationView = (
  props: CreateSegmentationRuleCreationViewProps
) => {
  const header = (
    <SegmentationRuleCreationHeader
      headerTextElement={
        <HeadlineText text={getTitle(props.params.pageState.mode)} />
      }
      errorMessageElements={props.params.message.map((e) => (
        <ErrorMessage message={e} />
      ))}
    />
  );

  const body = () => {
    if (!props.params.isLoaded) {
      return (
        <LoadingView
          iconElement={<LoadingIcon />}
          textElement={<div>データ読み込み中．．．</div>}
        />
      );
    } else if (props.params.pageState.phase === Phase.INPUT) {
      return (
        <CreateSegmentationRuleCreationContentsInput
          params={props.params}
          callbacks={props.callbacks}
        />
      );
    } else if (props.params.pageState.phase === Phase.COMPLETE) {
      return (
        <CreateSegmentationRuleCreationContentsComplete
          segmentationRule={props.params.createdSegmentationRuleCreation}
          onComplete={props.callbacks.event.onComplete}
        />
      );
    } else {
      return <AssertNever never={props.params.pageState.phase} />;
    }
  };

  return (
    <SegmentationRuleCreationView headerElement={header} formElement={body()} />
  );
};

export default CreateSegmentationRuleCreationView;
