import { GrantMessageSetting } from "types/grant_message";
import RestApi from "../RestApi";

class GrantMessageSettingRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(): Promise<GrantMessageSetting> {
    const data = await this.managementApi.get(`grant_message_setting`);
    return data;
  }
}

export default GrantMessageSettingRepository;
