import styles from "./Separator.module.scss";
import fontStyles from "fontStyles.module.scss";

const Separator: React.VFC<{
  text?: string;
  width?: number;
}> = ({ text, width }) => (
  <div
    className={styles.separator}
    style={{
      width: width ? `${width}px` : "100%",
    }}
  >
    <div className={styles.separateLine} />
    {text ? (
      <div className={styles.operator}>
        <span className={fontStyles.boldText}>{text}</span>
      </div>
    ) : undefined}
    <div className={styles.separateLine} />
  </div>
);

export default Separator;
