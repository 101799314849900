import styles from "./CustomFormatSelectButton.module.scss";

type CustomFormatSelectButtonProps = {
  text: string;
  img?: JSX.Element;
  isActive?: boolean;
  onClick: () => void;
};

const CustomFormatSelectButton = ({
  text,
  isActive,
  img,
  onClick,
}: CustomFormatSelectButtonProps) => (
  <div
    className={
      isActive
        ? styles.customFormatSelectButton__active
        : styles.customFormatSelectButton
    }
    onClick={onClick}
  >
    <div>
      <div className={styles.image}>{img}</div>
      <div>{text}</div>
    </div>
  </div>
);

export default CustomFormatSelectButton;
