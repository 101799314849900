import styles from "./FormItemView.module.scss";

type FormItemViewProps = {
  titleElement: JSX.Element;
  titleSideElements?: JSX.Element[];
  mainElements?: JSX.Element[][];
};

const FormItemView = ({
  titleElement,
  titleSideElements,
  mainElements,
}: FormItemViewProps) => {
  return (
    <div className={styles.formItemView}>
      <div className={styles.title}>
        <div className={styles.text}>{titleElement}</div>
        {titleSideElements?.map((e, i) => (
          <div key={i} className={styles.text}>
            {e}
          </div>
        ))}
      </div>
      <div className={styles.divider} />
      {mainElements ? (
        <div className={styles.texts}>
          {mainElements.map((e, i) => (
            <div key={i} className={styles.paragraph}>
              {e.map((f, j) => (
                <div key={j} className={styles.element}>
                  {f}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FormItemView;
