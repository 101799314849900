import styles from "./ContentsPreviewArea.module.scss";
import Iframe from "react-iframe";
import Frame from "react-frame-component";

type ContentsPreviewAreaProps = {
  url: string;
  html: JSX.Element;
};

const ContentsPreviewArea = ({ url, html }: ContentsPreviewAreaProps) => {
  return (
    <div className={styles.contentsPreviewArea}>
      <Iframe url={url} className={styles.iframe} />
      <div className={styles.contents}>
        <Frame className={styles.frame}>
          <div>{html}</div>
        </Frame>
      </div>
    </div>
  );
};

export default ContentsPreviewArea;
