import React from "react";
import cautionIcon from "images/icons/icon_caution.svg";
import styles from "./CautionMessage.module.scss";

const CautionMessage: React.VFC<{
  message: string | JSX.Element;
}> = ({ message }) => (
  <div className={styles.cautionMessage}>
    <div className={styles.icon}>
      <img src={cautionIcon} alt="" className={styles.image} />
    </div>
    <div>{message}</div>
  </div>
);

export default CautionMessage;
