import { CreateDomainRequest, LoadDomainResponse } from "types/domain";
import RestApi from "../RestApi";

class DomainRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(): Promise<LoadDomainResponse> {
    const data = await this.managementApi.get("domain");
    return data;
  }

  async create(domain: CreateDomainRequest): Promise<void> {
    await this.managementApi.post("domain", domain);
  }

  async update(domainUuid: string, domain: string): Promise<void> {
    await this.managementApi.put(`domain/${domainUuid}`, { domain });
  }

  async delete(domainUuid: string): Promise<void> {
    await this.managementApi.delete(`domain/${domainUuid}`);
  }
}

export default DomainRepository;
