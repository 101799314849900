//-----------------------------------------------------------------------------
// Enums

import { ColorType } from "molecules/GradationColorPicker/ColorTypeSelector";

export const MAIN_LAYOUT = {
  DEFAULT: "default",
  HEADER_TITLE: "header_title",
};

export type MainLayout = typeof MAIN_LAYOUT[keyof typeof MAIN_LAYOUT];

export const CREATIVE_PARTS = {
  BASE: "base",
  HEADLINE: "headline",
  SUB_HEADLINE: "sub_headline",
  ARTICLE: "article",
  BUTTON: "button",
  IMAGE: "image",
  MAGNIFICATION_IMAGE: "magnification_image",
  LINK_IMAGE: "link_image",
  ICON: "icon",
  COUPON_CODE: "coupon_code",
  CLOSE_BUTTON: "close_button",
};

export type CreativeParts = typeof CREATIVE_PARTS[keyof typeof CREATIVE_PARTS];

export const PRESET_CONTENTS_FORMAT = {
  LOGIN_FOLLOW: "login_follow",
  PURCHASE_FOLLOW: "purchase_follow",
  PRODUCT_INTEREST: "product_interest",
  REVISITOR: "revisitor",
  FIRST_VISIT: "first_visit",
  CART_NOTIFICATION: "cart_notification",
} as const;

export type PresetContentsFormat =
  typeof PRESET_CONTENTS_FORMAT[keyof typeof PRESET_CONTENTS_FORMAT];

export const POSITION_TYPE = {
  LEFT_TOP: "LEFT_TOP",
  CENTER_TOP: "CENTER_TOP",
  RIGHT_TOP: "RIGHT_TOP",
  CENTER: "CENTER",
  RIGHT_BOTTOM: "RIGHT_BOTTOM",
  CENTER_BOTTOM: "CENTER_BOTTOM",
  LEFT_BOTTOM: "LEFT_BOTTOM",
};

export type PositionType = typeof POSITION_TYPE[keyof typeof POSITION_TYPE];

export const CUSTOM_CONTENTS_FORMAT = {
  TEXT_ONLY: "text_only",
  ICON_TEXT: "icon_text",
  COUPON_CODE: "coupon_code",
  TEXT_WITH_SMALL_IMAGE: "text_with_small_image",
  TEXT_WITH_LARGE_IMAGE: "text_with_large_image",
  FULL_IMAGE: "full_image",
} as const;

export type CustomContentsFormat =
  typeof CUSTOM_CONTENTS_FORMAT[keyof typeof CUSTOM_CONTENTS_FORMAT];

export const CONTENTS_TYPE = {
  FULL: "FULL",
  PARTIAL: "PARTIAL",
} as const;

export type ContentsType = typeof CONTENTS_TYPE[keyof typeof CONTENTS_TYPE];

export const ACTION_CREATION_MODE = {
  PRESET: "preset",
  CUSTOM: "custom",
} as const;

export type ActionCreationMode =
  typeof ACTION_CREATION_MODE[keyof typeof ACTION_CREATION_MODE];

//-----------------------------------------------------------------------------
// Data structures

export type CustomContentsFormParameters = {
  name: number;
  parts: CreativeParts[];
  icon: string;
  layout?: MainLayout;
};

export type ActionCreationContentsStyleParameters = {
  backgroundColor: string;
  borderColor: string;
  subHeadlineBackgroundColor: string;
  subHeadlineTextColor: string;
  subHeadlineText: string;
  subHeadlineTextSize: number;
  subHeadlineBackgroundRadius: number;
  subHeadlineBackgroundTopPadding: number;
  subHeadlineBackgroundBottomPadding: number;
  subHeadlineBackgroundRightPadding: number;
  subHeadlineBackgroundLeftPadding: number;
  headlineTextColor: string;
  headlineText: string;
  headlineTextSize: number;
  textColor: string;
  text: string;
  textSize: number;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonText: string;
  buttonLinkText: string;
  buttonBorderRadius: number;
  isOpenOtherWindow: boolean;
  buttonTopPadding: number;
  buttonBottomPadding: number;
  buttonRightPadding: number;
  buttonLeftPadding: number;
  isButtonShadow: boolean;
  buttonBackgroundGradationColor: string[];
  buttonColorType: ColorType;
  imageUrl: string;
  imageMagnification: number;
  imageLinkText: string;
  couponCodeText: string;
  couponCodeTextSize: number;
  couponCodeTextColor: string;
  couponCodeBackgroundColor: string;
  couponButtonColor: string[];
  couponButtonRadius: number;
  couponButtonTextColor: string;
  couponButtonText: string;
  couponButtonTextSize: number;
  couponButtonTopPadding: number;
  couponButtonBottomPadding: number;
  couponButtonRightPadding: number;
  couponButtonLeftPadding: number;
  couponButtonShadow: boolean;
  couponButtonColorType: ColorType;
  contentsBaseTopPadding: number;
  contentsBaseBottomPadding: number;
  contentsBaseRightPadding: number;
  contentsBaseLeftPadding: number;
  contentsBaseRadius: number;
  contentsBaseWidth: number;
  contentsBaseMaxHeight: string;
  contentsBaseHeight: number;
  contentsBaseBorderWidth: number;
  contentsBaseHeightAdjust: boolean;
  closeButtonBackgroundColor: string;
  closeButtonCrossColor: string;
  closeButtonSize: string;
  buttonTextSize: number;
};

export type ActionCreationContentsPCStyleParameters = {
  contentsBase: {
    background: {
      width: number;
      height: number;
      maxHeight: string;
      heightAdjust: boolean;
      padding: {
        top: number;
        bottom: number;
        right: number;
        left: number;
      };
    };
    border: {
      radius: number;
    };
  };
  headline: {
    text: {
      size: number;
    };
  };
  subHeadline: {
    background: {
      radius: number;
      padding: {
        top: number;
        bottom: number;
        right: number;
        left: number;
      };
    };
    text: {
      size: number;
    };
  };
  article: {
    size: number;
  };
  button: {
    background: {
      radius: number;
      padding: {
        top: number;
        bottom: number;
        right: number;
        left: number;
      };
    };
    text: {
      size: number;
    };
  };
  image: {
    magnification: number;
  };
  couponCode: {
    text: {
      size: number;
    };
  };
  couponCopyButton: {
    background: {
      radius: number;
      padding: {
        top: number;
        bottom: number;
        right: number;
        left: number;
      };
    };
    text: {
      size: number;
    };
  };
  closeButton: {
    size: string;
  };
};

export type ActionContentsParameters = {
  version: number;
  mode: ActionCreationMode | undefined;
  isEditingRawHtml: boolean;
  contentsType: ContentsType; // FIXME: Remove this parameter
  positionType: PositionType;
  presetFormat: PresetContentsFormat;
  customFormat: CustomContentsFormat;
  patternIndex: number;
  formatPatterns: CustomContentsFormParameters[];
  url?: string;
  template: string;
  isEnablePCStyle: boolean;
  styles: ActionCreationContentsStyleParameters;
  pcStyles: ActionCreationContentsPCStyleParameters;
};
