import styles from "./CustomerAttributeForm.module.scss";

type CustomerAttributeFormProps = {
  keySelectorElement: JSX.Element;
  dataSelectorElement: JSX.Element;
  inputElement?: JSX.Element;
  compareTypeElement: JSX.Element;
};

const CustomerAttributeForm = ({
  keySelectorElement,
  dataSelectorElement,
  inputElement,
  compareTypeElement,
}: CustomerAttributeFormProps) => {
  return (
    <div className={styles.customerAttributeForm}>
      <div className={styles.keySelector}>{keySelectorElement}</div>
      <div className={styles.dataSelector}>{dataSelectorElement}</div>
      {inputElement ? (
        <div className={styles.input}>{inputElement}</div>
      ) : undefined}
      <div className={styles.compareType}>{compareTypeElement}</div>
    </div>
  );
};

export default CustomerAttributeForm;
