import styles from "./ReportView.module.scss";

const ReportView: React.VFC<{
  headerElement: JSX.Element;
  reportElements: JSX.Element[];
  pageNationElements?: JSX.Element;
}> = ({ headerElement, reportElements, pageNationElements }) => {
  return (
    <div className={styles.reportView}>
      <div className={styles.header}>{headerElement}</div>
      {reportElements.map((e, i) => (
        <div key={i} className={styles.report}>
          {e}
        </div>
      ))}
      <div className={styles.pageNation}>{pageNationElements}</div>
    </div>
  );
};

export default ReportView;
