import GrayBackTitle from "atoms/ezPushShared/accountPageShared/GrayBackTitle";
import styles from "./GrayBackTitleForm.module.scss";

const GrayBackTitleForm = ({
  title,
  elements,
}: {
  title: string;
  elements: JSX.Element[];
}) => (
  <div className={styles.grayBackTitleForm}>
    <div className={styles.title}>
      <GrayBackTitle title={title} />
    </div>
    <div className={styles.elements}>
      {elements.map((elem, i) => (
        <div key={i} className={styles.element}>
          {elem}
        </div>
      ))}
    </div>
  </div>
);

export default GrayBackTitleForm;
