import styles from "./LogOutButton.module.scss";

interface LogOutProps {
  onSignOut: () => void;
}

const LogOutButton = ({ onSignOut }: LogOutProps) => {
  return (
    <div className={styles.LogOutButton} onClick={onSignOut}>
      ログアウト
    </div>
  );
};

export default LogOutButton;
