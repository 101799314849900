import styles from "./ColorTypeSelector.module.scss";

export const COLOR_TYPE = {
  SIMPLE: "SIMPLE",
  GRADATION: "GRADATION",
};

export type ColorType = typeof COLOR_TYPE[keyof typeof COLOR_TYPE];

type ColorTypeSelectorProps = {
  colorType: ColorType;
  setColorType: (value: ColorType) => void;
};

const ColorTypeSelector = ({
  colorType,
  setColorType,
}: ColorTypeSelectorProps) => {
  const colorTypeLabels = [
    { label: "単色", value: COLOR_TYPE.SIMPLE },
    { label: "グラデーション", value: COLOR_TYPE.GRADATION },
  ];

  return (
    <div className={styles.colorTypeSelector}>
      {colorTypeLabels.map((e) => (
        <div
          key={e.label}
          className={colorType === e.value ? styles.cell__active : styles.cell}
          onClick={() => setColorType(e.value)}
        >
          {e.label}
        </div>
      ))}
    </div>
  );
};

export default ColorTypeSelector;
