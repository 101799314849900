import { ActionCreationCommonCallbacks } from "interfaces/view/actionCreation";
import {
  ActionCreationModalType,
  ACTION_CREATION_MODAL_TYPE,
} from "interfaces/view/actionCreation";

import NotificateModal from "atoms/accountPagesShared/NotificateModal";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";

import CreateNotificateModalContents from "app/creators/CreateNotificateModalContents";
import { ActionCreationMode } from "interfaces/model/actionContentsParameters";
import { ActionStatus, ACTION_STATUS } from "interfaces/models";

export type CreateModalCallback = {
  common: {
    onCloseModal: () => void;
    onCancel: () => void;
  };
  mode: {
    [key in ActionCreationMode]: {
      onSubmit: () => void;
      onResetHtmlButton: () => void;
    };
  };
};

const CreateModal = ({
  actionStatus,
  modalType,
  appCallbacks,
  setModalElement,
}: {
  actionStatus: ActionStatus;
  modalType: ActionCreationModalType;
  appCallbacks: ActionCreationCommonCallbacks;
  setModalElement: (v: undefined) => void;
}) => {
  const onRequestCloseModal = () => {
    setModalElement(undefined);
    appCallbacks.closeModal();
  };

  // NOTE: building this callback logic must be in page or useXXX
  const onSubmitModal = {
    [ACTION_CREATION_MODAL_TYPE.IS_SUBMIT]: appCallbacks.onSubmit,
    [ACTION_CREATION_MODAL_TYPE.IS_CANCEL]: async () =>
      appCallbacks.moveToActionIndexPage(),
    [ACTION_CREATION_MODAL_TYPE.IS_RESET]: async () =>
      appCallbacks.onResetHTML(),
  };

  const LABELS = {
    [ACTION_CREATION_MODAL_TYPE.IS_SUBMIT]: {
      title:
        "おもてなしアクションを" +
        (actionStatus === ACTION_STATUS.ACTIVE ? "有効で" : "一時停止で") +
        "保存します",
      description: "",
    },
    [ACTION_CREATION_MODAL_TYPE.IS_CANCEL]: {
      title: "おもてなしアクションの作成をキャンセルします",
      description: "入力内容は保持されませんので、ご注意ください。",
    },
    [ACTION_CREATION_MODAL_TYPE.IS_RESET]: {
      title: "HTMLソースをリセットします",
      description: "現在の入力内容は破棄され、ソースをリセットします。",
    },
  } as const;

  const label = LABELS[modalType];

  return (
    <NotificateModal
      Contents={
        <CreateNotificateModalContents
          title={label.title}
          description={label.description}
          buttons={[
            <NegativeButton
              text="キャンセル"
              onClick={() => onRequestCloseModal()}
            />,
            <PositiveButton
              text="OK"
              onClick={async () => {
                await onSubmitModal[modalType]();
                onRequestCloseModal();
              }}
            />,
          ]}
        />
      }
      isOpen={true}
      onRequestClose={onRequestCloseModal}
    />
  );
};

export default CreateModal;
