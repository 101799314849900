import {
  ActionFrequency,
  ActionTerminateReaction,
  ExecuteTiming,
  ExpirationPeriod,
  LocationPattern,
  PathParameterPattern,
  SegmentationRule,
} from "interfaces/models";

import ToggledExpirationPeriodForm from "organisms/account/action_creation/ToggledExpirationPeriodForm";
import NamedFormView from "organisms/account/action_creation/settings_edit/NamedFormView";

import ItemName from "molecules/ItemName";
import ABRateForm from "molecules/SettingForm/ABRateForm";
import ActionNameForm from "molecules/SettingForm/ActionNameForm";
import FrequencyForm from "molecules/SettingForm/FrequencyForm";
import LoginErrorCountForm from "molecules/SettingForm/LoginErrorCountForm";
import SegmentationRulesForm from "molecules/SettingForm/SegmentationRulesForm";
import TerminateReactionForm from "molecules/SettingForm/TerminateReactionForm";
import URLForm from "molecules/SettingForm/URLForm";
import ExpirationPeriodForm from "molecules/ExpirationPeriodForm";
import ToggleButton from "atoms/accountPagesShared/ToggleButton";
import ExecuteTimingForm from "molecules/SettingForm/ExecuteTimingForm";

type CreateActionNameFormProps = {
  name: string;
  setName: (value: string) => void;
};

export const CreateActionNameForm = ({
  name,
  setName,
}: CreateActionNameFormProps) => {
  return (
    <NamedFormView
      titleElement={<ItemName text="アクション名" isRequired={true} />}
      formElement={<ActionNameForm name={name} setName={setName} />}
    />
  );
};

type CreateSegmentationRuleFormProps = {
  ruleIds: string[];
  rules: SegmentationRule[];
  onClickAddButton: () => void;
  onClickDeleteButton: () => void;
  onChange: (index: number, ruleId: string) => void;
};

export const CreateSegmentationRuleForm = ({
  ruleIds,
  rules,
  onClickAddButton,
  onClickDeleteButton,
  onChange,
}: CreateSegmentationRuleFormProps) => {
  return (
    <NamedFormView
      titleElement={<ItemName text="セグメント条件" />}
      formElement={
        <SegmentationRulesForm
          selects={ruleIds}
          rules={rules}
          onClickAddButton={onClickAddButton}
          onClickDeleteButton={onClickDeleteButton}
          onChangeSegmentationRule={onChange}
        />
      }
    />
  );
};

type CreateABTestFormProps = {
  abRate: number;
  setABRate: (value: number) => void;
};

export const CreateABTestForm = ({
  abRate,
  setABRate,
}: CreateABTestFormProps) => {
  return (
    <NamedFormView
      titleElement={
        <ItemName
          text="効果検証テスト"
          toolTipId="ab_test"
          toolTipText="条件に合致したユーザーから「表示/非表示」を指定の割合にランダムに分け、アクションの有無による効果の差異をテストできます。"
        />
      }
      formElement={<ABRateForm abRate={abRate} setABRate={setABRate} />}
    />
  );
};

type CreateFrequencyFormProps = {
  frequency: ActionFrequency;
  setFrequency: (value: ActionFrequency) => void;
};

export const CreateFrequencyForm = ({
  frequency,
  setFrequency,
}: CreateFrequencyFormProps) => {
  return (
    <NamedFormView
      titleElement={<ItemName text="ユーザーに表示する頻度" />}
      formElement={
        <FrequencyForm frequency={frequency} setFrequency={setFrequency} />
      }
    />
  );
};

type CreateTerminateReactionFormProps = {
  terminateReaction: ActionTerminateReaction;
  setTerminateReaction: (value: ActionTerminateReaction) => void;
};

export const CreateTerminateReactionForm = ({
  terminateReaction,
  setTerminateReaction,
}: CreateTerminateReactionFormProps) => {
  return (
    <NamedFormView
      titleElement={<ItemName text="表示を停止する条件" />}
      formElement={
        <TerminateReactionForm
          terminateReaction={terminateReaction}
          setTerminateReaction={setTerminateReaction}
        />
      }
    />
  );
};

type CreateExecuteTimingFormProps = {
  initValue: ExecuteTiming[] | undefined;
  executeTimings: ExecuteTiming[];
  isExecuteTimingsActive: boolean;
  onChangeExecuteTimings(
    isExecuteTimingsActive: boolean,
    executeTiming: ExecuteTiming[] | undefined
  ): void;
  setIsExecuteTimingsActive: (b: boolean) => void;
  onClickInsertExecuteTiming(): void;
  onClickDeleteExecuteTiming(index: number): void;
  onChangeSetExecuteTimingFromHourValue(index: number, value: number): void;
  onChangeSetExecuteTimingFromTimeValue(index: number, value: number): void;
  onChangeSetExecuteTimingToHourValue(index: number, value: number): void;
  onChangeSetExecuteTimingToTimeValue(index: number, value: number): void;
  onChangeSetExecuteTimingDayOfWeek(index: number, label: string): void;
  onChangeSetExecuteTimingHoliday(index: number): void;
  getExecuteTimingHolidayState(index: number): boolean;
  getExecuteTimingDayOfWeekState(index: number, value: string): boolean;
};

export const CreateExecuteTimingForm = ({
  initValue,
  executeTimings,
  isExecuteTimingsActive,
  onChangeExecuteTimings,
  setIsExecuteTimingsActive,
  onClickInsertExecuteTiming,
  onClickDeleteExecuteTiming,
  onChangeSetExecuteTimingFromHourValue,
  onChangeSetExecuteTimingFromTimeValue,
  onChangeSetExecuteTimingToHourValue,
  onChangeSetExecuteTimingToTimeValue,
  onChangeSetExecuteTimingDayOfWeek,
  onChangeSetExecuteTimingHoliday,
  getExecuteTimingHolidayState,
  getExecuteTimingDayOfWeekState,
}: CreateExecuteTimingFormProps) => {
  return (
    <NamedFormView
      titleElement={
        <ItemName
          text="配信時間帯"
          toolTipId="execute_timing"
          toolTipText="例）平日の営業時間のみ配信したい場合：営業時間帯を指定して、月～金にチェック"
        />
      }
      formElement={
        <>
          <ToggleButton
            checked={isExecuteTimingsActive}
            onChange={(isExecuteTimingsActive) => {
              setIsExecuteTimingsActive(isExecuteTimingsActive);
              onChangeExecuteTimings(
                isExecuteTimingsActive,
                !isExecuteTimingsActive ? [] : initValue
              );
            }}
          />
          {isExecuteTimingsActive ? (
            <ExecuteTimingForm
              executeTimings={executeTimings}
              setIsExecuteTimingsActive={setIsExecuteTimingsActive}
              onClickInsertExecuteTiming={onClickInsertExecuteTiming}
              onClickDeleteExecuteTiming={onClickDeleteExecuteTiming}
              onChangeSetExecuteTimingFromHourValue={
                onChangeSetExecuteTimingFromHourValue
              }
              onChangeSetExecuteTimingFromTimeValue={
                onChangeSetExecuteTimingFromTimeValue
              }
              onChangeSetExecuteTimingToHourValue={
                onChangeSetExecuteTimingToHourValue
              }
              onChangeSetExecuteTimingToTimeValue={
                onChangeSetExecuteTimingToTimeValue
              }
              onChangeSetExecuteTimingDayOfWeek={
                onChangeSetExecuteTimingDayOfWeek
              }
              onChangeSetExecuteTimingHoliday={onChangeSetExecuteTimingHoliday}
              getExecuteTimingHolidayState={getExecuteTimingHolidayState}
              getExecuteTimingDayOfWeekState={getExecuteTimingDayOfWeekState}
            />
          ) : undefined}
        </>
      }
    />
  );
};

type CreateToggledExpirationPeriodFormProps = {
  isEnabled: boolean;
  expirationPeriod: ExpirationPeriod | null;
  setExpirationPeriod: (value: ExpirationPeriod) => void;
  onSetEnabled: (b: boolean) => void;
};

export const CreateToggledExpirationPeriodForm = ({
  isEnabled,
  expirationPeriod,
  setExpirationPeriod,
  onSetEnabled,
}: CreateToggledExpirationPeriodFormProps) => {
  const formElement =
    isEnabled && expirationPeriod ? (
      <ExpirationPeriodForm
        value={expirationPeriod}
        onChange={(value) => setExpirationPeriod(value)}
      />
    ) : null;

  return (
    <NamedFormView
      titleElement={
        <ItemName
          text="配信スケジュール"
          toolTipId="expiration_period"
          toolTipText="例：正月キャンペーン期間のみ配信：2022/01/01 00:00~2022/01/04 00:00"
        />
      }
      formElement={
        <ToggledExpirationPeriodForm
          buttonElement={
            <ToggleButton checked={isEnabled} onChange={onSetEnabled} />
          }
          formElement={formElement}
        />
      }
    />
  );
};

type LoginErrorCountFormProps = {
  loginErrorCount: number;
  loginErrorCondition: boolean;
  onChange: (value: string) => void;
  onChangeLoginErrorPageCondition: () => void;
};

export const CreateLoginErrorCountForm = ({
  loginErrorCount,
  loginErrorCondition,
  onChange,
  onChangeLoginErrorPageCondition,
}: LoginErrorCountFormProps) => {
  return (
    <NamedFormView
      titleElement={
        <ItemName text="ログインに失敗した回数" isRequired={true} />
      }
      formElement={
        <LoginErrorCountForm
          loginErrorCount={loginErrorCount}
          loginErrorCondition={loginErrorCondition}
          onChange={(e) => onChange(e)}
          onChangeLoginErrorCondition={() => onChangeLoginErrorPageCondition()}
        />
      }
    />
  );
};

type CreateLoginErrorURLFormProps = {
  location: LocationPattern[];
  parameters: PathParameterPattern[];
  numberOfLoginUrlParameter: number;
  setLocation: (value: LocationPattern) => void;
  setParameters: (index: number, value: PathParameterPattern) => void;
  onClickParameterAddButton: () => void;
  onClickParameterDeleteButton: () => void;
};

export const CreateLoginErrorURLForm = ({
  location,
  parameters,
  numberOfLoginUrlParameter,
  setLocation,
  setParameters,
  onClickParameterAddButton,
  onClickParameterDeleteButton,
}: CreateLoginErrorURLFormProps) => {
  return (
    <NamedFormView
      titleElement={<ItemName text="ログインエラーURL" isRequired={true} />}
      formElement={
        <URLForm
          location={location[0]}
          parameters={parameters}
          numberOfLoginUrlParameter={numberOfLoginUrlParameter}
          setLocation={setLocation}
          setParameters={setParameters}
          onClickParameterAddButton={() => onClickParameterAddButton()}
          onClickParameterDeleteButton={() => onClickParameterDeleteButton()}
        />
      }
    />
  );
};
