import { LocationPattern } from "interfaces/models";
import EventText from "atoms/accountPagesShared/EventText";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import UrlLocationForm from "molecules/UrlForm/UrlLocationForm";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import UrlParameterForms from "./UrlParameterForms";

import { cloneElement, useEffect, useReducer } from "react";

import styles from "./UrlMatchPatternForms.module.scss";
import { notifyNever } from "utils/assertions";

//=============================================================================
// Type Definitions

type ParameterMatchMode = ManagementApiData.ParameterMatchMode;

type ParameterMatchPattern = {
  mode: ParameterMatchMode;
  keyValue: string;
};

type UrlMatchPattern = {
  locations: ManagementApiData.LocationMatchPattern[];
  parameters: ParameterMatchPattern[];
};

//=============================================================================
// constants

const INIT_PARAMETER_FORM_VALUE: ParameterMatchPattern = {
  mode: "include",
  keyValue: "",
};

const INIT_URL_FORM_VALUE: UrlMatchPattern = {
  locations: [],
  parameters: [INIT_PARAMETER_FORM_VALUE],
};

//=============================================================================
// state

type ReducerAction =
  | {
      type: "ADD_FORM";
    }
  | {
      type: "DELETE_FORM";
      index: number;
    }
  | {
      type: "CHANGE_LOCATION";
      index: number;
      location: LocationPattern;
    }
  | {
      type: "CHANGE_PARAMETER";
      urlIndex: number;
      parameterIndex: number;
      value: {
        [key in keyof ParameterMatchPattern]?: ParameterMatchPattern[key];
      };
    }
  | {
      type: "ADD_PARAMETER_FORM";
      urlIndex: number;
    }
  | {
      type: "DELETE_PARAMETER_FORM";
      urlIndex: number;
      parameterIndex: number;
    };

const parametersReducer = (
  parameters: ParameterMatchPattern[],
  ra: ReducerAction
): ParameterMatchPattern[] => {
  switch (ra.type) {
    case "ADD_PARAMETER_FORM":
      return parameters.concat([INIT_PARAMETER_FORM_VALUE]);
    case "DELETE_PARAMETER_FORM":
      return parameters.filter((_, pIdx) => pIdx !== ra.parameterIndex);
    case "CHANGE_PARAMETER":
      return parameters.map((pt, pIdx) =>
        pIdx === ra.parameterIndex ? { ...pt, ...ra.value } : pt
      );
    default:
      return parameters;
  }
};

const reducer = (
  urlPatterns: UrlMatchPattern[],
  ra: ReducerAction
): UrlMatchPattern[] => {
  switch (ra.type) {
    case "ADD_FORM":
      return urlPatterns.concat([INIT_URL_FORM_VALUE]);
    case "DELETE_FORM":
      const nextFormCount = Math.max(1, urlPatterns.length - 1);
      return urlPatterns
        .filter((_, idx) => idx !== ra.index)
        .slice(0, nextFormCount);
    case "CHANGE_LOCATION":
      return urlPatterns.map((pattern, idx) =>
        idx === ra.index ? { ...pattern, locations: [ra.location] } : pattern
      );
    case "ADD_PARAMETER_FORM":
    case "DELETE_PARAMETER_FORM":
    case "CHANGE_PARAMETER":
      return urlPatterns.map((pattern, uIdx) =>
        uIdx === ra.urlIndex
          ? {
              ...pattern,
              parameters: parametersReducer(pattern.parameters, ra),
            }
          : pattern
      );
    default:
      notifyNever(ra);
      return urlPatterns;
  }
};

//=============================================================================
// utility functions

const joinElements = (elements: JSX.Element[], separator: JSX.Element) => {
  return elements
    .map((f) => [f, separator])
    .flat()
    .slice(0, -1)
    .map((e, i) => cloneElement(e, { key: i }));
};

const ParameterCodec = {
  encode: (
    v: ParameterMatchPattern
  ): ManagementApiData.ParameterMatchPattern | null => {
    const buf = v.keyValue.split("=");

    if (buf.length < 2) {
      return null;
    }

    return {
      key: buf[0],
      value: buf[1],
      pattern: v.mode,
    };
  },

  decode: (p: ManagementApiData.ParameterMatchPattern) => ({
    mode: p.pattern,
    keyValue: `${p.key}=${p.value || ""}`,
  }),
};

const UrlMatchPatternCodec = {
  encode: (
    urlPatterns: UrlMatchPattern[]
  ): ManagementApiData.UrlMatchPattern[] => {
    return urlPatterns.map((urlPattern) => ({
      locations: urlPattern.locations.filter((l) => !!l.value),
      parameters: urlPattern.parameters
        .map(ParameterCodec.encode)
        .filter((p): p is ManagementApiData.ParameterMatchPattern => !!p),
    }));
  },
  decode: (initValue: ManagementApiData.UrlMatchPattern[]): UrlMatchPattern[] =>
    initValue.map((urlPattern) => ({
      locations: urlPattern.locations,
      parameters: urlPattern.parameters.length
        ? urlPattern.parameters.map(ParameterCodec.decode)
        : [INIT_PARAMETER_FORM_VALUE],
    })),
};

//=============================================================================
// Rendering Components

const UrlMatchPatternForms: React.VFC<{
  initValue?: ManagementApiData.UrlMatchPattern[];
  onChange: (p: ManagementApiData.UrlMatchPattern[]) => void;
}> = ({ initValue, onChange }) => {
  const [urlPatterns, dispatch] = useReducer(
    reducer,
    initValue?.length
      ? UrlMatchPatternCodec.decode(initValue)
      : [INIT_URL_FORM_VALUE]
  );

  // notify change
  useEffect(() => {
    onChange(UrlMatchPatternCodec.encode(urlPatterns));
  }, [urlPatterns, onChange]);

  // --------------------------------------------------------
  // Rendering

  return (
    <div className={styles.locationsFormView}>
      <div className={styles.main}>
        <div className={styles.urlInputList}>
          {joinElements(
            urlPatterns.map((e, fIdx) => (
              <div className={styles.urlInputArea}>
                <div className={styles.urlInput}>
                  <div className={styles.locations}>
                    <div className={styles.location}>
                      <UrlLocationForm
                        location={e.locations[0] || null}
                        onChange={(l) =>
                          dispatch({
                            type: "CHANGE_LOCATION",
                            index: fIdx,
                            location: l,
                          })
                        }
                      />
                    </div>
                    {urlPatterns.length > 1 ? (
                      <div className={styles.delete}>
                        <DeleteButton
                          onClick={() =>
                            dispatch({ type: "DELETE_FORM", index: fIdx })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                  <UrlParameterForms
                    state={{
                      deleteForm: (parameterIndex: number) =>
                        dispatch({
                          type: "DELETE_PARAMETER_FORM",
                          urlIndex: fIdx,
                          parameterIndex,
                        }),
                      addForm: () =>
                        dispatch({
                          type: "ADD_PARAMETER_FORM",
                          urlIndex: fIdx,
                        }),
                      items: e.parameters.map((p, pIdx) => ({
                        keyValue: p.keyValue,
                        option: p.mode,
                        onChangeKeyValue: (value) =>
                          dispatch({
                            type: "CHANGE_PARAMETER",
                            urlIndex: fIdx,
                            parameterIndex: pIdx,
                            value: {
                              keyValue: value,
                            },
                          }),
                        onChangeOption: (value) =>
                          dispatch({
                            type: "CHANGE_PARAMETER",
                            urlIndex: fIdx,
                            parameterIndex: pIdx,
                            value: {
                              mode: value,
                            },
                          }),
                      })),
                    }}
                  />
                </div>
              </div>
            )),
            <div className={styles.separator}>
              <NoticeText text="or" />
            </div>
          )}
        </div>
        <div className={styles.addButton}>
          <EventText
            text="追加"
            onClick={() => dispatch({ type: "ADD_FORM" })}
          />
        </div>
      </div>
    </div>
  );
};

export default UrlMatchPatternForms;
