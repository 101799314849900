import { useMemo } from "react";
import {
  SegmentationRuleEditPageCallbacks,
  SegmentationRuleEditPageParameters,
} from "app/hooks/v2/segmentationRule/editor/useSegmentationRuleEditPage";

import styles from "./SegmentationRuleEditPanel.module.scss";
import fontStyles from "fontStyles.module.scss";

import TextInput from "atoms/TextInput";
import ToggleButton from "atoms/ezPushShared/accountPageShared/ToggleButton";
import NumberInput from "atoms/ezPushShared/NumberInput";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TitleForm from "molecules/v2/TitleForm";
import Panel from "templates/ezPush/Panel";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";

import SegmentationRuleTreeForm from "./SegmentationRuleTreeForm";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import {
  SegmentationRuleEditCancelModal,
  SegmentationRuleSaveModal,
  SegmentationRuleSavingModal,
} from "./SegmentationRuleEditorModals";
import ErrorMessages from "molecules/v2/ErrorMessages";
import { TOOL_TIP_TEXT } from "app/system_defaults/tool_tip_texts";
import Label from "molecules/v2/Label";

const SegmentationRuleEditPanel: React.VFC<{
  parameters: SegmentationRuleEditPageParameters;
  callbacks: SegmentationRuleEditPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const nameForm = useMemo(
    () => (
      <TitleForm
        title="セグメント名"
        mainElement={
          <div className={styles.name}>
            <TextInput
              value={parameters.form.name}
              onChange={(e) => callbacks.form.updateName(e.target.value)}
            />
          </div>
        }
        notification="文字数上限：50文字"
        isRequired
      />
    ),
    [parameters.form.name, callbacks]
  );

  const durationForm = useMemo(() => {
    const label = (
      <Label
        label={<span className={fontStyles.boldText}>期間</span>}
        toolTip={TOOL_TIP_TEXT.ACCOUNT.SEGMENTATION_RULE.EDIT.DURATION.LABEL}
      />
    );
    const form = (
      <div className={styles.form}>
        <div className={styles.toggle}>
          <HorizontalIconLayout
            elements={[
              <span> 指定しない</span>,
              <ToggleButton
                checked={parameters.form.isNeedDuration}
                onChange={() => callbacks.form.flipIsNeedDuration()}
              />,
              <span>指定する</span>,
            ]}
          />
        </div>
        {parameters.form.isNeedDuration ? (
          <HorizontalIconLayout
            elements={[
              <span>過去</span>,
              <NumberInput
                min={1}
                max={730}
                value={parameters.form.duration}
                onChange={callbacks.form.updateDuration}
              />,
              <span>日間</span>,
              <ToolTip
                text={
                  TOOL_TIP_TEXT.ACCOUNT.SEGMENTATION_RULE.EDIT.DURATION.FORM
                }
              />,
            ]}
          />
        ) : undefined}
      </div>
    );
    return (
      <div className={styles.duration}>
        <div className={styles.label}>{label}</div>
        <div>{form}</div>
      </div>
    );
  }, [
    parameters.form.duration,
    parameters.form.isNeedDuration,
    callbacks.form,
  ]);

  const buttonElements = useMemo(
    () => (
      <HorizontalLayout
        elements={[
          <TransparentButton
            text={"キャンセル"}
            onClick={() =>
              callbacks.updateModalContents(
                <SegmentationRuleEditCancelModal
                  backToHome={callbacks.event.cancel}
                  clearModal={callbacks.closeModalContents}
                />
              )
            }
          />,
          <OrangeButton
            text={"保存"}
            onClick={() => {
              if (callbacks.event.canSave(parameters.form)) {
                callbacks.updateModalContents(
                  <SegmentationRuleSaveModal
                    save={() => {
                      callbacks.updateModalContents(
                        <SegmentationRuleSavingModal />
                      );
                      callbacks.event.save(parameters.form);
                    }}
                    clearModal={callbacks.closeModalContents}
                  />
                );
              }
            }}
          />,
        ]}
      />
    ),
    [callbacks, parameters.form]
  );

  return (
    <div className={styles.segmentationRuleEditPanel}>
      {parameters.event.errorMessages.length > 0 ? (
        <div className={styles.errorMessages}>
          <ErrorMessages errors={parameters.event.errorMessages} />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.panel}>
        <Panel
          itemElements={[
            nameForm,
            durationForm,
            <SegmentationRuleTreeForm
              tree={parameters.form.tree}
              urls={parameters.urls}
              updateTree={callbacks.form.updateTree}
              updateModalContents={callbacks.updateModalContents}
              closeModalContents={callbacks.closeModalContents}
            />,
          ]}
        />
      </div>
      <div className={styles.buttons}>{buttonElements}</div>
    </div>
  );
};

export default SegmentationRuleEditPanel;
