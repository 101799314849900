import { useState } from "react";
import RestApi from "utils/RestApi";

import { DeliveryReportStatus } from "types/delivery_report";
import useDeliveryReportForm, {
  DeliveryReportFormCallbacks,
  DeliveryReportFormParameters,
} from "../deliveryReport/useDeliveryReportForm";
import {
  DeliveryReportEventCallbacks,
  DeliveryReportEventParameters,
} from "../deliveryReport/useDeliveryReportEvents";
import useAdminDeliveryReportStatus from "./useAdminDeliveryReportStatus";
import useAdminDeliveryReportEvents from "./useAdminDeliveryReportEvents";

export type DeliveryReportPageParameters = {
  data: DeliveryReportStatus[] | null;
  form: DeliveryReportFormParameters;
  event: DeliveryReportEventParameters;
  modal: JSX.Element | undefined;
};

export type DeliveryReportPageCallbacks = {
  form: DeliveryReportFormCallbacks;
  event: DeliveryReportEventCallbacks;
  updateModal: (value: JSX.Element | undefined) => void;
};

const useAdminDeliveryReportPage = (
  managementApi: RestApi
): [DeliveryReportPageParameters, DeliveryReportPageCallbacks] => {
  const [reportStatus, loadReportStatus] =
    useAdminDeliveryReportStatus(managementApi);
  const [formParams, formCallbacks] = useDeliveryReportForm();
  const [eventParams, eventCallbacks] = useAdminDeliveryReportEvents(
    managementApi,
    formParams,
    loadReportStatus
  );
  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  return [
    {
      data: reportStatus,
      form: formParams,
      event: eventParams,
      modal: modalContents,
    },
    {
      form: formCallbacks,
      event: eventCallbacks,
      updateModal: (value: JSX.Element | undefined) => setModalContents(value),
    },
  ];
};

export default useAdminDeliveryReportPage;
