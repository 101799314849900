import styles from "./GrantMessageEditForm.module.scss";
import {
  GrantMessageEditPageCallbacks,
  GrantMessageEditPageParameters,
} from "app/hooks/v2/grantMessage/useGrantMessageEditPage";
import GrantMessageEditLayoutForm from "./GrantMessageLayoutEditForm";
import GrantMessageEditDesignForm from "./GrantMessageEditDesignForm";

const GrantMessageEditForm = ({
  parameters,
  callbacks,
}: {
  parameters: GrantMessageEditPageParameters;
  callbacks: GrantMessageEditPageCallbacks;
}): JSX.Element => (
  <div className={styles.grantMessageEditForm}>
    <GrantMessageEditLayoutForm
      parameters={parameters.data.layout}
      callbacks={callbacks.data.layout}
    />
    <GrantMessageEditDesignForm
      parameters={parameters.data.design}
      callbacks={callbacks.data.design}
    />
  </div>
);

export default GrantMessageEditForm;
