/**
 * Component Category: Organism
 */
import { useState } from "react";
import Pagination from "./molecules/Pagination";

import styles from "./ActionTable.module.scss";

interface ActionTableProps {
  headerColumnElements: JSX.Element[];
  items: JSX.Element[];
}

const ActionTable = ({ headerColumnElements, items }: ActionTableProps) => {
  const [page, setPage] = useState<number>(0);

  const pageMaxRow = 100;

  const startActionNum = pageMaxRow * page;

  return (
    <div className={styles.actionTable}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr key="header" className={styles.tr}>
            {headerColumnElements}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {items.slice(startActionNum, startActionNum + pageMaxRow)}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <Pagination
          length={items.length}
          pageRowMax={pageMaxRow}
          onChange={(pageNum: number) => {
            setPage(pageNum);
          }}
        />
      </div>
    </div>
  );
};

export default ActionTable;
