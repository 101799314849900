export class ImageLoader {
  RESPONSE_SUCCESS = 200;
  async get(url: string) {
    const response = await fetch(url, {
      mode: "no-cors",
    });

    if (response.status === this.RESPONSE_SUCCESS) {
      throw new Error(
        `Unable to download file. HTTP status: ${response.status}`
      );
    }

    const blob = await response.blob();

    const splittedUrl: string[] = url.split("/");

    // NOTE: file名の重複許容のため先頭に付与しているUUID文字列を取り除く
    const fileName: string = splittedUrl[splittedUrl.length - 1]
      .split("_")
      .slice(1)
      .join("_");

    return new File([blob], fileName, {
      type: blob.type,
    });
  }
}
