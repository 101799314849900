import CreateActionDetailTableElement from "app/creators/actions/CreateActionDetailTable";
import HeadlineText from "atoms/HeadlineText";
import LoadingIcon from "atoms/LoadingIcon";
import { ActionStatus, ACTION_STATUS } from "interfaces/models";
import CompletePhaseDetailTableView from "organisms/account/action_creation/complete/CompletePhaseDetailTableView";
import CompletePhaseFormMainView from "organisms/account/action_creation/complete/CompletePhaseFormMainView";
import LoadingView from "organisms/account/LoadingView";
import { ActionDetail2 } from "utils/ActionsRepository";
import CreateContentsPreviewArea, {
  ActionContentsPreviewParameters,
} from "../../CreateContentsPreviewArea";

const CreateCompletePhaseFormMain = ({
  status,
  createdAction,
  name,
  preview,
}: {
  status: ActionStatus;
  createdAction: ActionDetail2 | undefined;
  name: string;
  preview: ActionContentsPreviewParameters;
}) => {
  if (!createdAction) {
    return (
      <LoadingView
        iconElement={<LoadingIcon />}
        textElement={
          <div>
            おもてなしアクション「{name}」を
            {status === ACTION_STATUS.ACTIVE ? "有効" : "一時停止"}
            で保存しています
          </div>
        }
      />
    );
  }

  const activeTitle = "おもてなしアクションが有効で保存されました";
  const testTitle = "おもてなしアクションが一時停止で保存されました";

  const headlineText =
    status === ACTION_STATUS.ACTIVE ? activeTitle : testTitle;

  return (
    <CreateActionDetailTableElement
      actionDetail2={createdAction}
      isDisplayPopUp={false}
      render={(actionDetailTableElement) => {
        return (
          <CompletePhaseFormMainView
            previewAreaElement={<CreateContentsPreviewArea {...preview} />}
            settingDetailsElement={
              <CompletePhaseDetailTableView
                headlineElement={<HeadlineText text={headlineText} />}
                detailTableElement={actionDetailTableElement}
              />
            }
          />
        );
      }}
    />
  );
};

export default CreateCompletePhaseFormMain;
