import Modal from "react-modal";
import styles from "./DetailModal.module.scss";

const DetailModal = ({
  modalContentsElement,
  onRequestCloseModal,
}: {
  modalContentsElement: JSX.Element;
  onRequestCloseModal: () => void;
}) => {
  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.content}
      isOpen={!!modalContentsElement}
      onRequestClose={onRequestCloseModal}
    >
      {modalContentsElement}
    </Modal>
  );
};

export default DetailModal;
