export class DescriptionFormatter {
  /**
   * 長い文字列を、特定の長さに省略し、文末置き換えを行う
   *
   * text: 入力文字列
   * text_max_length: 文字列長の指定。default=60。
   * omitted_symbol: 文末省略を可視化するシンボル。default="..."。
   */
  substr(
    text: string,
    textMaxLength: number = 60,
    omittedSymbol: string = "..."
  ): string {
    if (text.length <= textMaxLength) {
      return text;
    }
    return (
      text.substring(0, textMaxLength - omittedSymbol.length) + omittedSymbol
    );
  }

  addCommasToNumber(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
