import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import {
  CloseButtonSizeParameters,
  CLOSE_BUTTON_SIZE_PATTERN,
  LOCATION_COMPARE_PATTERN,
  PATH_PARAMETER_COMPARE_PATTERN,
} from "interfaces/models";
import { COLOR_TYPE } from "molecules/GradationColorPicker/ColorTypeSelector";

export const CONTENTS_VERSION = 2;

export const CLOSE_BUTTON_STYLE: CloseButtonSizeParameters[] = [
  {
    key: CLOSE_BUTTON_SIZE_PATTERN.SMALL,
    size: 11,
    radius: 11,
    padding: 4,
    positionPadding: 10,
  },
  {
    key: CLOSE_BUTTON_SIZE_PATTERN.MIDDLE,
    size: 15,
    radius: 15,
    padding: 5,
    positionPadding: 13,
  },
  {
    key: CLOSE_BUTTON_SIZE_PATTERN.LARGE,
    size: 23,
    radius: 23,
    padding: 8,
    positionPadding: 20,
  },
];

export const DEFAULT_CONTENTS_STYLE = {
  SUB_HEADLINE: {
    BACKGROUND: {
      COLOR: "#AAAAAA",
      RADIUS: 100,
      PADDING: {
        TOP: 3,
        BOTTOM: 3,
        RIGHT: 20,
        LEFT: 20,
      },
    },
    TEXT: {
      COLOR: "#FFFFFF",
      TEXT: "サブ見出し",
      SIZE: 14,
    },
  },
  HEADLINE: {
    TEXT_COLOR: "#000000",
    TEXT: "見出し",
    TEXT_SIZE: 20,
  },
  TEXT: {
    TEXT_COLOR: "#000000",
    TEXT: "本文",
    TEXT_SIZE: 12,
  },
  BUTTON: {
    BACKGROUND_COLOR: "#AAAAAA",
    GRADATION_BACKGROUND_COLOR: ["#AAAAAA", "#DDDDDD"],
    COLOR_TYPE: COLOR_TYPE.SIMPLE,
    TEXT: {
      COLOR: "#FFFFFF",
      SIZE: 12,
      TEXT: "ボタン",
    },
    BORDER: {
      RADIUS: 50,
    },
    PADDING: {
      TOP: 5,
      BOTTOM: 5,
      RIGHT: 20,
      LEFT: 20,
    },
    LINK_TEXT: "",
    IS_OPEN_OTHER_WINDOW: true,
    IS_BUTTON_SHADOW: false,
  },
  COUPON: {
    CODE: {
      BACKGROUND: {
        COLOR: "#EEEEEE",
      },
      TEXT: {
        SIZE: 12,
        TEXT: "couponcode",
        COLOR: "#000000",
      },
    },
    BUTTON: {
      BACKGROUND: {
        COLOR: ["#AAAAAA", "#DDDDDD"],
        COLOR_TYPE: COLOR_TYPE.SIMPLE,
        RADIUS: 50,
        PADDING: {
          TOP: 3,
          BOTTOM: 3,
          RIGHT: 20,
          LEFT: 20,
        },
        IS_SHADOW: true,
      },
      TEXT: {
        COLOR: "#FFFFFF",
        TEXT: "クーポンコードをコピー",
        SIZE: 12,
      },
    },
  },
  BASE: {
    BACKGROUND_COLOR: "#FFFFFF",
    PADDING: {
      TOP: 10,
      BOTTOM: 10,
      RIGHT: 10,
      LEFT: 10,
    },
    BORDER: {
      WIDTH: 3,
      COLOR: "#666666",
      RADIUS: 5,
    },
    SIZE: {
      WIDTH: 325,
      HEIGHT: 0,
      MAX_HEIGHT: "80vh",
      IS_HEIGHT_ADJUST: true,
    },
  },
  CLOSE_BUTTON: {
    BACKGROUND_COLOR: "#eee",
    CROSS_COLOR: "#454545",
    SIZE: CLOSE_BUTTON_SIZE_PATTERN.SMALL,
  },
  IMAGE: {
    MAGNIFICATION: 1.0,
  },
};

export const DEFAULT_LOGIN_FOLLOW_SEGMENTATION_RULE = {
  loginUrl: {
    location: [
      {
        value: "",
        pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
      },
    ],
    parameters: [
      {
        key: "",
        value: "",
        pattern: PATH_PARAMETER_COMPARE_PATTERN.INCLUDE,
      },
    ],
  },
  errorCount: 2,
};

export const CONTENTS_BASE_RADIUS_PATTERN: OptionType[] = [
  { label: "角", value: 0 },
  { label: "丸(小)", value: 5 },
  { label: "丸(中)", value: 10 },
  { label: "丸(大)", value: 20 },
];

export const BUTTON_RADIUS_PATTERN: OptionType[] = [
  { label: "角", value: 0 },
  { label: "丸(小)", value: 5 },
  { label: "丸(中)", value: 10 },
  { label: "丸(大)", value: 20 },
];

export const CONTENTS_BASE_BORDER_PATTERN: OptionType[] = [
  { label: "0px", value: 0 },
  { label: "1px", value: 1 },
  { label: "2px", value: 2 },
  { label: "3px", value: 3 },
  { label: "5px", value: 5 },
  { label: "8px", value: 8 },
];

export const SUB_HEADLINE_RADIUS_PATTERN: OptionType[] = [
  { label: "角", value: 0 },
  { label: "丸(小)", value: 5 },
  { label: "丸(中)", value: 50 },
  { label: "丸(大)", value: 100 },
];
