import React from "react";
import styles from "./PanelHorizontalLayout.module.scss";

const PanelHorizontalLayout: React.VFC<{
  elements: JSX.Element[];
}> = ({ elements }) => {
  return (
    <div className={styles.panelHorizontalLayout}>
      {elements.map((elem, i) => (
        <div key={i} className={styles.item}>
          {elem}
        </div>
      ))}
    </div>
  );
};

export default PanelHorizontalLayout;
