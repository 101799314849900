import { useCallback, useState } from "react";
import { JobHistory } from "types/job_history";
import RestApi from "utils/RestApi";
import JobHistoryRepository from "utils/repositories/JobHistoryRepository";

const useJobHistories = (
  managementApi: RestApi
): [JobHistory[] | null, () => void] => {
  const [jobHistories, setJobHistories] = useState<JobHistory[] | null>(null);

  const loadJobHistories = useCallback(() => {
    setJobHistories(null);
    new JobHistoryRepository(managementApi).load().then((response) => {
      if (response) {
        setJobHistories(response.items);
      }
    });
  }, [managementApi]);

  return [jobHistories, loadJobHistories];
};

export default useJobHistories;
