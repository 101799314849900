import { GrantMessageContentsImageDesignParameters } from "app/hooks/v2/grantMessage/design/useGrantMessageContentsImageDesign";
import { GrantMessageFormParameters } from "app/hooks/v2/grantMessage/useGrantMessageContents";
import ErrorMessage from "utils/ErrorMessage";

class GrantMessageFormValidator {
  MAX_IMAGE_SIZE = 200000;

  ACCEPT_FILE_TYPE = ["image/png", "image/jpeg", "image/gif"];

  validateImage(
    params: GrantMessageContentsImageDesignParameters
  ): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (params.file !== null) {
      if (params.file.size > this.MAX_IMAGE_SIZE) {
        errors.push(
          new ErrorMessage("ファイルサイズを200KB以下にしてください")
        );
      }

      const fileType: string = params.file.type;

      if (!this.ACCEPT_FILE_TYPE.some((type) => fileType.includes(type))) {
        errors.push(
          new ErrorMessage(
            "ファイル形式をPNG, JPG, GIFのいずれかにしてください。"
          )
        );
      }
    }

    return errors;
  }

  run(params: GrantMessageFormParameters): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (params.design.title.text === "") {
      errors.push(new ErrorMessage("「見出し」を入力してください"));
    }

    if (params.design.button.text === "") {
      errors.push(new ErrorMessage("「許可ボタン」を入力してください"));
    }

    if (params.design.image.isNewFile) {
      errors = errors.concat(this.validateImage(params.design.image));
    }

    return errors;
  }
}

export default GrantMessageFormValidator;
