import React from "react";
import styles from "./VerticalSpreadLayout.module.scss";

const VerticalSpreadLayout: React.VFC<{
  items: JSX.Element[];
  align?: "center" | "left" | "right";
  margin?: number;
}> = ({ items, align = "left", margin = 0 }) => (
  <div className={styles.verticalSpreadLayout} style={{ alignItems: align }}>
    {items.map((item, index) => (
      <div
        key={index}
        className={styles.item}
        style={{
          display: "flex",
          justifyContent: align,
          marginBottom: index < items.length - 1 ? `${margin}px` : 0,
        }}
      >
        {item}
      </div>
    ))}
  </div>
);

export default VerticalSpreadLayout;
