const DAY_JP = ["日", "月", "火", "水", "木", "金", "土"] as const;

export const getDateStringFromDate = (tmp: Date): string => {
  const month = (tmp.getMonth() + 1).toString().padStart(2, "0");
  const date = tmp.getDate().toString().padStart(2, "0");
  return `${tmp.getFullYear()}/${month}/${date}`;
};

export const getDateString = (unixTime: number): string => {
  const tmp = new Date(unixTime);
  const month = (tmp.getMonth() + 1).toString().padStart(2, "0");
  const date = tmp.getDate().toString().padStart(2, "0");
  return `${tmp.getFullYear()}/${month}/${date}`;
};

export const unixTimeToDateTimeSecond = (unixTime: number): string => {
  const date = new Date(unixTime);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${getDateString(unixTime)} ${hours}:${minutes}:${seconds}`;
};

export const unixTimeToDateTime = (unixTime: number): string => {
  const tmp = new Date(unixTime);
  const hours = tmp.getHours().toString().padStart(2, "0");
  const minutes = tmp.getMinutes().toString().padStart(2, "0");
  return `${getDateString(unixTime)} ${hours}:${minutes}`;
};

// NOTE: JAPAN FORMAT Date

export const DateToDateString = (tmp: Date): string => {
  const month = (tmp.getMonth() + 1).toString().padStart(2, "0");
  const date = tmp.getDate().toString().padStart(2, "0");
  const day = tmp.getDay();
  return `${tmp.getFullYear()}年${month}月${date}日（${DAY_JP[day]}）`;
};

export const DateToDeliveryDateTime = (tmp: Date): string => {
  const hours = tmp.getHours().toString().padStart(2, "0");
  const minutes = tmp.getMinutes().toString().padStart(2, "0");
  return `${DateToDateString(tmp)} ${hours}:${minutes}`;
};

export const unixTimeToDateTimeWeekday = (unixTime: number): string => {
  const tmp = new Date(unixTime);
  const month = (tmp.getMonth() + 1).toString().padStart(2, "0");
  const date = tmp.getDate().toString().padStart(2, "0");
  const day = tmp.getDay();
  const hours = tmp.getHours().toString().padStart(2, "0");
  const minutes = tmp.getMinutes().toString().padStart(2, "0");
  return `${tmp.getFullYear()}年${month}月${date}日（${
    DAY_JP[day]
  }） ${hours}:${minutes}`;
};
