import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { AccountPageContext } from "interfaces/view/accout";
import BuildAccountPage from "app/builders/BuildAccountPage";
import CreateAreaContents from "app/creators/segmentation_rules/CreateAreaContents";
import CreateDeleteConfirmModal from "app/creators/CreateDeleteConfirmModal";
import LoadSegmentationRules from "app/loaders/LoadSegmentationRules";

import { parseSearchParameters } from "utils/SearchParameters";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import { SegmentationRuleCreationMode } from "interfaces/view/segmentationCreation";
import CreateSegmentationRuleModalElement, {
  SegmentationRulePageModalParameters,
  SegmentationRulePageModalParametersType,
} from "app/creators/segmentation_rules/CreateSegmentationRuleModalElement";
import SegmentationRuleApiCodec from "utils/segmentation_rule_creation/segmentationRuleApiCodec";
import { getDescriptions } from "utils/SegmentationRuleSentence";

type SegmentationRulePageProps = {
  context: AccountPageContext;
  segmentationRuleRepository: SegmentationRuleRepository;
};

const SegmentationRuleCreationPage = ({
  context,
  segmentationRuleRepository,
}: SegmentationRulePageProps) => {
  const location = useLocation();
  const history = useHistory();

  const searchParameters = parseSearchParameters(location.search);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [segmentationRuleCodec, setSegmentationRuleCodec] =
    useState<SegmentationRuleApiCodec>(
      new SegmentationRuleApiCodec(
        context.account && context.account.cv_url ? context.account.cv_url : ""
      )
    );

  useEffect(() => {
    setSegmentationRuleCodec(
      new SegmentationRuleApiCodec(
        context.account && context.account.cv_url ? context.account.cv_url : ""
      )
    );
  }, [context.account]);

  const onRequestCloseModal = () => {
    // FIXME: keep search range parameters
    history.push("#");
  };

  const onMoveToCreatePage = (params: { key: string; value?: string }[]) => {
    const payload = params
      .filter((kv): kv is { key: string; value: string } => !!kv.value)
      .map((kv) => `${kv.key}=${kv.value}`)
      .join("&");

    history.push(`/segmentation_rules/creation?${payload}`);
  };

  return (
    <LoadSegmentationRules
      segmentationRuleRepository={segmentationRuleRepository}
      onError={setErrorMessage}
      render={({ segmentationRules, reload }) => {
        return (
          <BuildAccountPage
            context={context}
            renderAreaContentsElement={({ setModalElement }) => {
              const onDelete = async (segmentationRuleId: string) => {
                try {
                  await segmentationRuleRepository.delete(segmentationRuleId);
                  reload();
                } catch (e) {
                  if (e instanceof Error) {
                    setErrorMessage(e.message);
                  }
                } finally {
                  setModalElement(undefined);
                }
              };

              return (
                <CreateAreaContents
                  segmentationRules={
                    segmentationRules
                      ? segmentationRules.sort(
                          (r1, r2) =>
                            r1.segmentation_rule_number -
                            r2.segmentation_rule_number
                        )
                      : null
                  }
                  errorMessage={errorMessage}
                  callbacks={{
                    onCreate: (segmentationRuleId) => {
                      onMoveToCreatePage([
                        {
                          key: "mode",
                          value: SegmentationRuleCreationMode.CREATE,
                        },
                        {
                          key: "source_id",
                          value: segmentationRuleId,
                        },
                      ]);
                    },
                    onEdit: (segmentationRuleId) => {
                      onMoveToCreatePage([
                        {
                          key: "mode",
                          value: SegmentationRuleCreationMode.EDIT,
                        },
                        {
                          key: "edit_id",
                          value: segmentationRuleId,
                        },
                      ]);
                    },
                    onDelete: (segmentationRuleId) => {
                      setModalElement(
                        <CreateDeleteConfirmModal
                          message="このカスタマーセグメントを削除しますか？"
                          onDelete={() => onDelete(segmentationRuleId)}
                          onClose={() => setModalElement(undefined)}
                        />
                      );
                    },
                    onDetail: (segmentationRuleId) => {
                      history.push(`?modal=detail&id=${segmentationRuleId}`);
                    },
                  }}
                  render={(areaContentsElement) => areaContentsElement}
                />
              );
            }}
            renderDefaultModalElement={({ setModalElement }) => {
              if (
                Object.keys(SegmentationRulePageModalParameters).includes(
                  searchParameters.modal
                )
              ) {
                const segmentationRule = segmentationRules
                  ? segmentationRules.filter(
                      (e) => e.segmentation_rule_id === searchParameters.id
                    )
                  : null;
                if (segmentationRule) {
                  return (
                    <CreateSegmentationRuleModalElement
                      modalMode={
                        searchParameters.modal as SegmentationRulePageModalParametersType
                      }
                      segmentationRule={segmentationRule[0]}
                      segmentationRuleDescription={getDescriptions(
                        segmentationRuleCodec.decode(segmentationRule[0])
                      )}
                      onRequestCloseModal={() => {
                        onRequestCloseModal();
                        setModalElement(undefined);
                      }}
                    />
                  );
                } else {
                  return <div>対象のセグメント条件が存在しません。</div>;
                }
              }
              return <></>;
            }}
          />
        );
      }}
    />
  );
};

export default SegmentationRuleCreationPage;
