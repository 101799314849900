import React from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import usePushNotificationEditPage from "app/hooks/v2/pushNotification/usePushNotificationEditPage";

import AccountPageBase from "./AccountPageBase";
import PushNotificationEditor from "organisms/ezPush/pushNotification/edit/PushNotificationEditor";

const PushNotificationEditPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = usePushNotificationEditPage(
    accountContext.managementApi
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <PushNotificationEditor parameters={parameters} callbacks={callbacks} />
      }
      modalContents={parameters.modalContents}
    />
  );
};

export default PushNotificationEditPage;
