import styles from "./TimingTable.module.scss";

const TimingTable: React.VFC<{
  headers: string[];
  columns: string[][];
}> = ({ headers, columns }) => {
  return (
    <table className={styles.timingTable}>
      <thead>
        <tr className={styles.headerRow}>
          {headers.map((h) => (
            <th className={styles.cell}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {columns.map((c) => (
          <tr className={styles.row}>
            {c.map((e) => (
              <td className={styles.cell}>{e}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TimingTable;
