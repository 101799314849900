import { V2_DOWNLOAD_FILE_NAMES } from "app/system_defaults/v2_service";
import { useCallback } from "react";
import { InitialSettingResourceType } from "types/initial_settings";
import RestApi from "utils/RestApi";
import UrlDownloader from "utils/download/downloadUrl";
import InitialSettingRepository from "utils/repositories/InitialSettingRepository";

export type FileConfigurationCallbacks = {
  downloadSWLoader: () => void;
  downloadTagLoader: () => void;
  downloadAll: () => void;
};

export const useFileConfiguration = (
  managementApi: RestApi
): [FileConfigurationCallbacks] => {
  const download = useCallback(
    async (resourceType: InitialSettingResourceType, fileName: string) => {
      const response = await new InitialSettingRepository(managementApi).get({
        resourceType: resourceType,
      });
      if (response) {
        new UrlDownloader().downloadS3FileWithPresignedUrl(
          fileName,
          response.resource_url
        );
      }
    },
    [managementApi]
  );
  return [
    {
      downloadSWLoader: () => {
        download(
          "sw-loader",
          V2_DOWNLOAD_FILE_NAMES.INITIAL_SETTINGS.SW_LOADER
        );
      },
      downloadTagLoader: async () => {
        download(
          "tag-loader",
          V2_DOWNLOAD_FILE_NAMES.INITIAL_SETTINGS.TAG_LOADER
        );
      },
      downloadAll: async () => {
        download("all", V2_DOWNLOAD_FILE_NAMES.INITIAL_SETTINGS.ALL);
      },
    },
  ];
};
