import { SubscriptionStatistics } from "types/subscription_statistics";
import RestApi from "../RestApi";

class SubscriptionStatisticsRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(): Promise<SubscriptionStatistics> {
    const data = await this.managementApi.get("subscription/stats");
    return data;
  }
}

export default SubscriptionStatisticsRepository;
