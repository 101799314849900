class UrlDownloader {
    contentsDisposition(fileName: string, url: string) {
        const a = document.createElement("a");

        a.download = fileName;
        a.href = url;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    downloadS3FileWithPresignedUrl(fileName: string, url: string) {
        const link = document.createElement("a");

        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export default UrlDownloader;
