import { SegmentationRule } from "interfaces/models";
import { useState, useCallback, useEffect } from "react";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";

const useSegmentationRule = (
  segmentationRuleRepository: SegmentationRuleRepository,
  segmentationRuleId: string | undefined
): SegmentationRule | null => {
  const [segmentationRule, setSegmentationRule] =
    useState<SegmentationRule | null>(null);

  const loadSegmentationRule = useCallback<() => void>(async () => {
    if (segmentationRuleId) {
      setSegmentationRule(
        await segmentationRuleRepository.get(segmentationRuleId)
      );
    }
  }, [segmentationRuleRepository, segmentationRuleId]);

  useEffect(() => {
    loadSegmentationRule();
  }, [loadSegmentationRule]);

  return segmentationRule;
};

export default useSegmentationRule;
