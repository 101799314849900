import {
  GRANT_MESSAGE_LAYOUT_TYPE,
  GrantMessageLayoutType,
} from "interfaces/v2/grantMessageContents";
import { CSS_TEXT } from "utils/ContentsStyle";

type ModalClassNames = {
  modalId: string;
  modalType: string;
  contentsType: string;
};

export class GrantMessageHtmlPreviewPreprocessor {
  TAG_CLASS_NAMES = {
    [GRANT_MESSAGE_LAYOUT_TYPE.MODAL]: {
      modalId: "-modal",
      modalType: "-full-modal",
      contentsType: "-modal-contents",
    },
    [GRANT_MESSAGE_LAYOUT_TYPE.POP_UP]: {
      modalId: "-modal",
      modalType: "-bottom-modal",
      contentsType: "-modal-contents-bottom",
    },
  };

  getModalClassNames = (
    layoutType: GrantMessageLayoutType
  ): ModalClassNames => {
    const tagClassNamePrefix = "ezcx";
    const tagClassNameSuffixes = this.TAG_CLASS_NAMES[layoutType];

    return {
      modalId: tagClassNamePrefix + tagClassNameSuffixes.modalId,
      modalType: tagClassNamePrefix + tagClassNameSuffixes.modalType,
      contentsType: tagClassNamePrefix + tagClassNameSuffixes.contentsType,
    };
  };
  execute(layoutType: GrantMessageLayoutType, html: string) {
    const classNames = this.getModalClassNames(layoutType);
    return `
    <div id="${classNames.modalId}">
      <style>
        ${CSS_TEXT}
      </style>
      <div class="${classNames.modalType}">
        <div class="${classNames.contentsType}">
          ${html}
        </div>
      </div>
    </div>`;
  }
}
