import React from "react";
import styles from "./ClickableAreaText.module.scss";

const ClickableAreaText: React.FC<{
  text: string;
  onClick: () => void;
}> = ({ text, onClick }) => (
  <div className={styles.clickableAreaText} onClick={onClick}>
    {text}
  </div>
);

export default ClickableAreaText;
