import { useCallback, useEffect, useState } from "react";
import {
  MeasurementUrlLocationParameterComparePattern,
  MeasurementUrlQueryParameterComparePattern,
  MeasurementUrlSetting,
} from "types/measurement_urls";

import {
  LOCATION_COMPARE_PATTERN,
  PATH_PARAMETER_COMPARE_PATTERN,
} from "interfaces/models";
import { MeasurementUrlSettingTranslator } from "app/process/measurementUrlSetting/measurementUrlSettingTranslator";

export type LocationFormParameter = {
  pattern: string;
  value: string;
};

export type QueryParameterFormParameter = {
  keyValue: string;
  pattern: string;
};

export type FormParameters = {
  name: string;
  location: LocationFormParameter;
  parameters: QueryParameterFormParameter[];
};

export type TitleFormCallbacks = {
  updateTitle: (title: string) => void;
};

export type LocationFormCallbacks = {
  updateLocationValue: (value: string) => void;
  updateLocationPattern: (
    pattern: MeasurementUrlLocationParameterComparePattern
  ) => void;
};

export type QueryParameterFormCallbacks = {
  updateParameterKeyValue: (index: number, keyValue: string) => void;
  updateParameterPattern: (
    index: number,
    pattern: MeasurementUrlQueryParameterComparePattern
  ) => void;
  addParameter: () => void;
  removeParameter: (index: number) => void;
};

export type FormCallbacks = {
  title: TitleFormCallbacks;
  location: LocationFormCallbacks;
  queryParameters: QueryParameterFormCallbacks;
};

const LOCATION_INIT_DATA: LocationFormParameter = {
  value: "",
  pattern: LOCATION_COMPARE_PATTERN.INCLUDE,
} as const;

const QUERY_PARAMETER_INIT_DATA: QueryParameterFormParameter = {
  keyValue: "",
  pattern: PATH_PARAMETER_COMPARE_PATTERN.INCLUDE,
} as const;

export const useEditPageForm = (
  measurementUrlSetting: MeasurementUrlSetting | null
): [FormParameters, FormCallbacks] => {
  const [title, setTitle] = useState<string>("");
  const [locationForm, setLocationForm] =
    useState<LocationFormParameter>(LOCATION_INIT_DATA);
  const [queryParametersForm, setQueryParametersForm] = useState<
    QueryParameterFormParameter[]
  >([QUERY_PARAMETER_INIT_DATA]);

  useEffect(() => {
    if (measurementUrlSetting) {
      const temp = MeasurementUrlSettingTranslator.decode(
        measurementUrlSetting
      );
      setTitle(temp.name);
      setLocationForm(temp.location);
      setQueryParametersForm(temp.parameters);
    }
  }, [measurementUrlSetting]);

  const updateTitle = useCallback(
    (title: string) => setTitle(title),
    [setTitle]
  );
  const updateLocation = useCallback(
    (value: string) =>
      setLocationForm({
        ...locationForm,
        value: value,
      }),
    [locationForm, setLocationForm]
  );
  const updateLocationPattern = useCallback(
    (pattern: MeasurementUrlLocationParameterComparePattern) =>
      setLocationForm({
        ...locationForm,
        pattern: pattern,
      }),
    [locationForm, setLocationForm]
  );

  const updateParameterKeyValue = useCallback(
    (index: number, keyValue: string) =>
      setQueryParametersForm((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, keyValue: keyValue } : item
        )
      ),
    [setQueryParametersForm]
  );
  const updateParameterPattern = useCallback(
    (index: number, pattern: MeasurementUrlQueryParameterComparePattern) =>
      setQueryParametersForm((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, pattern: pattern } : item
        )
      ),
    [setQueryParametersForm]
  );

  const addQueryParameter = useCallback(
    () =>
      setQueryParametersForm((prev) => [
        ...prev,
        {
          keyValue: "",
          pattern: "include",
        },
      ]),
    [setQueryParametersForm]
  );

  const removeQueryParameter = useCallback(
    (index: number) =>
      setQueryParametersForm((prev) => prev.filter((_, i) => i !== index)),
    [setQueryParametersForm]
  );

  return [
    {
      name: title,
      location: locationForm,
      parameters: queryParametersForm,
    },
    {
      title: {
        updateTitle: updateTitle,
      },
      location: {
        updateLocationValue: updateLocation,
        updateLocationPattern: updateLocationPattern,
      },
      queryParameters: {
        updateParameterKeyValue: updateParameterKeyValue,
        updateParameterPattern: updateParameterPattern,
        addParameter: addQueryParameter,
        removeParameter: removeQueryParameter,
      },
    },
  ];
};
