export const TOOL_TIP_TEXT = {
  ACCOUNT: {
    HOME: {},
    SEGMENTATION_RULE: {
      HOME: {
        DESTINATION_COUNT: {
          LABEL:
            "セグメント条件に該当する想定人数です。「セグメント作成時」「セグメント更新時」「配信1時間前」のタイミングで数値が更新されます。",
        },
      },
      EDIT: {
        DESTINATION_COUNT: {
          LABEL:
            "セグメント条件に該当する想定人数です。「セグメント作成時」「セグメント更新時」「配信1時間前」のタイミングで数値が更新されます。",
        },
        DURATION: {
          LABEL:
            "短期間のPV数でユーザーを絞り込みたい場合に指定してください。指定しない場合、過去730日間が対象期間となります。",
          FORM: "プッシュ通知配信日を起点にした日数です。1～730日で指定できます。",
        },
        DETAIL: {
          NAME: "事前に登録したページの管理名称が表示されます。",
        },
      },
    },
  },
} as const;
