import {
  RetryDeliveryReportRequest,
  RetryDeliveryReportResponse,
  CreateDeliveryReportRequest,
  CreateDeliveryReportResponse,
  DownloadDeliveryReportRequest,
  GetDeliveryReportResponse,
  CreateAdminDeliveryReportRequest,
} from "types/delivery_report";
import RestApi from "utils/RestApi";

class DeliveryReportRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(): Promise<GetDeliveryReportResponse> {
    const data: GetDeliveryReportResponse = await this.managementApi.get(
      `delivery-reports`
    );
    return data;
  }

  async getAdmin(): Promise<GetDeliveryReportResponse> {
    const data: GetDeliveryReportResponse = await this.managementApi.get(
      `delivery-reports?is_debug=true`
    );
    return data;
  }

  async post(
    request: CreateDeliveryReportRequest
  ): Promise<CreateDeliveryReportResponse> {
    const data: CreateDeliveryReportResponse = await this.managementApi.post(
      `delivery-reports`,
      request
    );
    return data;
  }

  async postAdmin(
    request: CreateAdminDeliveryReportRequest
  ): Promise<CreateDeliveryReportResponse> {
    const data: CreateDeliveryReportResponse = await this.managementApi.post(
      `delivery-reports`,
      request
    );
    return data;
  }

  // FIXME: anyを、レスポンス内容がわかったら型定義する。（ダウンロードレスポンスの内容がわからない）
  async download(request: DownloadDeliveryReportRequest): Promise<any> {
    const data = await this.managementApi.download(
      `delivery-reports/result/${request.request_uuid}`
    );
    return data;
  }

  async retry(
    request: RetryDeliveryReportRequest
  ): Promise<RetryDeliveryReportResponse> {
    const data: RetryDeliveryReportResponse = await this.managementApi.post(
      `delivery-reports/${request.request_uuid}`
    );
    return data;
  }
}

export default DeliveryReportRepository;
