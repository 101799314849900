import styles from "./PublicStatus.module.scss";
import fontStyles from "fontStyles.module.scss";

const PublicStatus = ({ isActive }: { isActive: boolean }): JSX.Element => {
  return (
    <div className={styles.publicStatus}>
      <div className={styles.text}>
        <div className={fontStyles.note}>ステータス：</div>
      </div>
      <div
        className={isActive ? styles.status__positive : styles.status__negative}
      >
        <div className={fontStyles.note}>{isActive ? "公開中" : "非公開"}</div>
      </div>
    </div>
  );
};

export default PublicStatus;
