import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import AccountPageBase from "./AccountPageBase";
import DeliveryReportPageMain from "organisms/ezPush/deliveryReport/DeliveryReportPageMain";
import useAdminDeliveryReportPage from "app/hooks/v2/adminDeliveryReport/useAdminDeliveryReportPage";

const AdminDeliveryReportPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = useAdminDeliveryReportPage(
    accountContext.managementApi
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <DeliveryReportPageMain parameters={parameters} callbacks={callbacks} />
      }
      modalContents={parameters.modal}
    />
  );
};

export default AdminDeliveryReportPage;
