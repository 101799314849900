import DatePickerWrapper from "./DatePicker/DatePickerWrapper";
import styles from "./ExpirationPeriodDatePicker.module.scss";
import TimePicker from "./TimePicker/TimePicker";

const ExpirationPeriodDatePicker = ({
  title,
  date,
  onChange,
}: {
  title: string;
  date: Date;
  onChange: (e: Date) => void;
}) => {
  return (
    <div className={styles.expirationPeriodDatePicker}>
      <div className={styles.title}>{title}</div>
      <div className={styles.date}>
        <DatePickerWrapper date={date} onChange={(e) => onChange(e)} />
      </div>
      <div>
        <TimePicker date={date} onChange={(e) => onChange(e)} />
      </div>
    </div>
  );
};

export default ExpirationPeriodDatePicker;
