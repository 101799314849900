import { useState } from "react";
import {
  GrantMessageContentsLayoutCallbacks,
  GrantMessageContentsLayoutParameters,
  useGrantMessageContentsLayout,
} from "./useGrantMessageContentsLayout";
import {
  GrantMessageContentsDesignCallbacks,
  GrantMessageContentsDesignParameters,
  useGrantMessageContentsDesign,
} from "./useGrantMessageContentsDesign";

export type GrantMessageFormParameters = {
  layout: GrantMessageContentsLayoutParameters;
  design: GrantMessageContentsDesignParameters;
};
export type GrantMessageFormCallbacks = {
  layout: GrantMessageContentsLayoutCallbacks;
  design: GrantMessageContentsDesignCallbacks;
};

export const useGrantMessageContents = (
  grantMessageContents: GrantMessageFormParameters
): [GrantMessageFormParameters, GrantMessageFormCallbacks] => {
  const [currentSettings] =
    useState<GrantMessageFormParameters>(grantMessageContents);

  const [layoutParameters, layoutCallbacks] = useGrantMessageContentsLayout(
    currentSettings.layout
  );

  const [designParameters, designCallbacks] = useGrantMessageContentsDesign(
    currentSettings.design
  );

  return [
    {
      layout: layoutParameters,
      design: designParameters,
    },
    {
      layout: layoutCallbacks,
      design: designCallbacks,
    },
  ];
};
