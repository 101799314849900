import GrayBackPanel from "atoms/ezPushShared/accountPageShared/GrayBackPanel";

import styles from "./GrayBackDescriptionList.module.scss";
import fontStyles from "fontStyles.module.scss";

const GrayBackDescriptionList: React.VFC<{
  title: string;
  description: string;
  items: string[];
}> = ({ title, description, items }) => (
  <GrayBackPanel
    text={
      <div className={styles.grayBackDescriptionList}>
        <div className={styles.title}>
          <span className={fontStyles.boldText}>{title}</span>
        </div>
        <div className={styles.listArea}>
          <div className={styles.sectionTitle}>
            <span className={fontStyles.boldText}>{description}</span>
          </div>
          {items.map((item, i) => (
            <div key={i} className={fontStyles.text}>
              ・{item}
            </div>
          ))}
        </div>
      </div>
    }
  />
);

export default GrayBackDescriptionList;
