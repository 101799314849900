import CreateFooter from "app/creators/CreateFooter";
import V2Logo from "atoms/ezPushShared/V2Logo";
import AuthTemplate from "templates/AuthPageTemplate";

const AuthPageBaseV2 = ({ formElement }: { formElement: JSX.Element }) => {
  return (
    <AuthTemplate
      logoElement={<V2Logo height={72} />}
      footerElement={<CreateFooter />}
      formElement={formElement}
    />
  );
};

export default AuthPageBaseV2;
