import React from "react";
import styles from "./NotificationTitle.module.scss";
import PreviewButton from "atoms/ezPushShared/accountPageShared/PreviewerButton";
import TooltipIcon from "atoms/ezPushShared/accountPageShared/TooltipIcon";

const NotificationTitle: React.VFC<{
  mark?: string;
  title: string;
  onClickPreviewButton: () => void;
}> = ({ mark, title, onClickPreviewButton }) => {
  return (
    <div className={styles.notificationTitle}>
      <div className={styles.titleArea}>
        {mark ? (
          <TooltipIcon
            iconElement={
              <div className={styles.mark}>
                <img src={mark} alt=" " />
              </div>
            }
            comment="くりかえし配信"
          />
        ) : undefined}
        <div>{title}</div>
      </div>
      <div className={styles.previewArea}>
        <PreviewButton onClick={() => onClickPreviewButton()} />
      </div>
    </div>
  );
};

export default NotificationTitle;
