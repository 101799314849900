import { useCallback, useEffect, useState } from "react";

import { ActionDetail2, ActionRepository } from "utils/ActionsRepository";

const LoadActionDetail = ({
  actionRepository,
  actionId,
  render,
}: {
  actionRepository: ActionRepository;
  actionId: string;
  render: (parameters: {
    actionDetail2?: ActionDetail2;
    errorMessage?: string;
  }) => JSX.Element;
}) => {
  const [actionDetail2, setActionDetail2] = useState<ActionDetail2>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadDetails = useCallback(async () => {
    try {
      const actionDetail = await actionRepository.get(actionId);
      if (!actionDetail) {
        return;
      }
      setActionDetail2(actionDetail);
    } catch (err) {
      setErrorMessage(err.message);
    }
  }, [actionRepository, actionId]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  return render({ actionDetail2, errorMessage });
};

export default LoadActionDetail;
