import styles from "./SegmentationRuleCreationHeader.module.scss";

type SegmentationRuleCreationHeaderProps = {
  headerTextElement: JSX.Element;
  errorMessageElements?: JSX.Element[];
};

const SegmentationRuleCreationHeader = ({
  headerTextElement,
  errorMessageElements,
}: SegmentationRuleCreationHeaderProps) => (
  <div className={styles.layout}>
    <div className={styles.headline}>
      <div className={styles.title}>{headerTextElement}</div>
    </div>
    {errorMessageElements ? (
      <div className={styles.errorMessage}>
        {errorMessageElements.map((e, i) => (
          <div key={i}>{e}</div>
        ))}
      </div>
    ) : null}
  </div>
);

export default SegmentationRuleCreationHeader;
