import styles from "./NotificateModalView.module.scss";

export interface NotificateModalViewProps {
  title: JSX.Element;
  description: JSX.Element;
  buttons: JSX.Element;
}

const NotificateModalView = (props: NotificateModalViewProps): JSX.Element => {
  return (
    <div className={styles.notificateModal}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.description}>{props.description}</div>
      <div className={styles.buttons}>{props.buttons}</div>
    </div>
  );
};

export default NotificateModalView;
