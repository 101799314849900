import { useEffect, useReducer, useState } from "react";
import {
  ConditionDispatchType,
  PAGE_CONDITION_COMPARE_TYPE,
  PageConditionDispatchType,
  SegmentationRulePageConditionParameters,
  SegmentationRuleTreeParameter,
  SegmentationRuleTreeDispatchAction,
  SegmentationRuleFormParameters,
  SegmentationRuleFormCallbacks,
  TreeDispatchType,
  SegmentationRuleConditionInterface,
} from "interfaces/v2/segmentationRuleForm";
import { generateUuid } from "utils/uuid/uuid";
import { DestinationCollectRule } from "types/destination_collect_rule";
import {
  SEGMENTATION_RULE_EDITOR_MODE,
  SegmentationRuleEditorMode,
} from "app/system_defaults/v2_routing";
import { CONDITION_TYPE } from "app/process/segmentationRule/segmentationRuleTree";

const getInitialPageSettingParameter =
  (): SegmentationRulePageConditionParameters => {
    return {
      urlSetting: {
        label: "選択してください",
        value: "",
      },
      compareType: PAGE_CONDITION_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO,
      value: 0,
      conditionUuid: generateUuid(),
    };
  };
const getInitialConditionParameter = (): SegmentationRuleConditionInterface => {
  return {
    type: CONDITION_TYPE.TOTAL_PV_COUNT,
    pages: [getInitialPageSettingParameter()],
  };
};

const getInitialTreeParameter = (): SegmentationRuleTreeParameter => {
  return [getInitialConditionParameter()];
};

function treeDispatcher(
  tree: SegmentationRuleTreeParameter,
  action: SegmentationRuleTreeDispatchAction
): SegmentationRuleTreeParameter {
  switch (action.type) {
    case TreeDispatchType.UPDATE:
      return [...action.tree];
    case PageConditionDispatchType.ADD_PAGE:
      return tree.map((c, i) =>
        i === action.conditionNumber && c.type === CONDITION_TYPE.TOTAL_PV_COUNT
          ? {
              type: CONDITION_TYPE.TOTAL_PV_COUNT,
              pages: [...c.pages, getInitialPageSettingParameter()],
            }
          : c
      );
    case PageConditionDispatchType.REDUCE_PAGE:
      return tree.map((cond, i) => {
        return i === action.conditionNumber &&
          cond.type === CONDITION_TYPE.TOTAL_PV_COUNT
          ? {
              type: CONDITION_TYPE.TOTAL_PV_COUNT,
              pages: cond.pages.filter((_, j) => j !== action.pageNumber),
            }
          : cond;
      });
    case PageConditionDispatchType.UPDATE_PAGE_UUID:
      return tree.map((c, i) =>
        i === action.conditionNumber && c.type === CONDITION_TYPE.TOTAL_PV_COUNT
          ? {
              type: CONDITION_TYPE.TOTAL_PV_COUNT,
              pages: c.pages.map((page, j) =>
                j === action.pageNumber
                  ? {
                      ...page,
                      urlSetting: action.value,
                    }
                  : page
              ),
            }
          : c
      );
    case PageConditionDispatchType.UPDATE_COMPARE_TYPE:
      return tree.map((c, i) =>
        i === action.conditionNumber && c.type === CONDITION_TYPE.TOTAL_PV_COUNT
          ? {
              type: CONDITION_TYPE.TOTAL_PV_COUNT,
              pages: c.pages.map((page, j) =>
                j === action.pageNumber
                  ? {
                      ...page,
                      compareType: action.value,
                    }
                  : page
              ),
            }
          : c
      );
    case PageConditionDispatchType.UPDATE_VALUE:
      return tree.map((c, i) =>
        i === action.conditionNumber && c.type === CONDITION_TYPE.TOTAL_PV_COUNT
          ? {
              type: CONDITION_TYPE.TOTAL_PV_COUNT,
              pages: c.pages.map((page, j) =>
                j === action.pageNumber
                  ? {
                      ...page,
                      value: action.value,
                    }
                  : page
              ),
            }
          : c
      );
    case ConditionDispatchType.ADD_CONDITION:
      return [...tree, getInitialConditionParameter()];
    case ConditionDispatchType.REDUCE_CONDITION:
      return tree.filter((_, i) => i !== action.conditionNumber);
    default:
      return tree;
  }
}

const SEGMENTATION_RULE_FORM_DEFAULTS = {
  NAME: "",
  DURATION: {
    OFF: 730,
    ON: 30,
  },
  IS_NEED_DURATION: false,
};

const useSegmentationRuleForm = (
  mode: SegmentationRuleEditorMode,
  destinationCollectRule: DestinationCollectRule | null
): [SegmentationRuleFormParameters, SegmentationRuleFormCallbacks] => {
  const [name, setName] = useState<string>(
    SEGMENTATION_RULE_FORM_DEFAULTS.NAME
  );
  const [duration, setDuration] = useState<number>(
    SEGMENTATION_RULE_FORM_DEFAULTS.DURATION.OFF
  );
  const [tree, treeDispatch] = useReducer(
    treeDispatcher,
    getInitialTreeParameter()
  );
  const [isNeedDuration, setIsNeedDuration] = useState<boolean>(
    SEGMENTATION_RULE_FORM_DEFAULTS.IS_NEED_DURATION
  );
  const [canEdit, setCanEdit] = useState<boolean>(true);

  useEffect(() => {
    if (destinationCollectRule) {
      try {
        setName(
          mode === SEGMENTATION_RULE_EDITOR_MODE.COPY
            ? `${destinationCollectRule.name}_copy`
            : destinationCollectRule.name
        );
        if (destinationCollectRule.form) {
          const formParams: SegmentationRuleFormParameters = JSON.parse(
            destinationCollectRule.form
          );
          setDuration(formParams.duration);
          treeDispatch({
            type: TreeDispatchType.UPDATE,
            tree: formParams.tree,
          });
          setIsNeedDuration(formParams.isNeedDuration);
        }
      } catch (error) {
        setCanEdit(false);
      }
    }
  }, [mode, destinationCollectRule]);

  return [
    { name, duration, tree, canEdit, isNeedDuration },
    {
      updateName: (value) => setName(value),
      updateDuration: (value) => setDuration(value),
      updateTree: (value) => treeDispatch(value),
      flipIsNeedDuration: () => {
        const nextState = !isNeedDuration;
        setIsNeedDuration(nextState);
        // NOTE: ONなら30日間、OFFなら730日間
        setDuration(
          nextState
            ? SEGMENTATION_RULE_FORM_DEFAULTS.DURATION.ON
            : SEGMENTATION_RULE_FORM_DEFAULTS.DURATION.OFF
        );
      },
    },
  ];
};

export default useSegmentationRuleForm;
