import ToggleButton from "atoms/accountPagesShared/ToggleButton";
import styles from "./ToggleForm.module.scss";

type ToggleFormProps = {
  name: JSX.Element;
  isChecked: boolean;
  onChange: (value: boolean) => void;
};

const ToggleForm = ({
  name,
  isChecked,
  onChange,
}: ToggleFormProps): JSX.Element => {
  return (
    <div className={styles.toggleForm}>
      <div className={styles.name}>{name}</div>
      <ToggleButton checked={isChecked} onChange={() => onChange(!isChecked)} />
    </div>
  );
};

export default ToggleForm;
