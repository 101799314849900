import { useCallback, useState } from "react";
import RestApi from "utils/RestApi";
import DeliveryReportRequestValidator from "app/process/deliveryReport/deliveryReportRequestValidator";

import DeliveryReportRepository from "utils/repositories/DeliveryReportRepository";
import ErrorMessage from "utils/ErrorMessage";

import { DeliveryReportFormParameters } from "./useDeliveryReportForm";

export type DeliveryReportEventParameters = {
  currentDate: Date;
  errorMessages: ErrorMessage[];
};

export type DeliveryReportEventCallbacks = {
  canSend: () => boolean;
  submit: () => void;
  update: () => void;
  download: (requestUuid: string) => void;
  retry: (requestUuid: string) => void;
};

const useDeliveryReportEvents = (
  managementApi: RestApi,
  formParameters: DeliveryReportFormParameters,
  loadReportStatus: () => void
): [DeliveryReportEventParameters, DeliveryReportEventCallbacks] => {
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [currentDate] = useState<Date>(new Date());

  const canSend = useCallback(
    (formParameters: DeliveryReportFormParameters) => {
      setErrorMessages([]);
      const validator = new DeliveryReportRequestValidator(currentDate);

      const errors = validator.validate(formParameters);

      if (errors.length > 0) {
        setErrorMessages(errors);
        return false;
      }

      return true;
    },
    [currentDate]
  );

  return [
    { currentDate, errorMessages: errorMessages },
    {
      canSend: () => canSend(formParameters),
      submit: () => {
        if (canSend(formParameters)) {
          const toDateToSend = new Date(formParameters.toDate);
          toDateToSend.setDate(formParameters.toDate.getDate() + 1);
          toDateToSend.setMilliseconds(toDateToSend.getMilliseconds() - 1);

          new DeliveryReportRepository(managementApi)
            .post({
              range_hours: formParameters.trackingDateRange * 24,
              from_timestamp_at: formParameters.fromDate.getTime(),
              to_timestamp_at: toDateToSend.getTime(),
            })
            .then(() => {
              loadReportStatus();
            });
        }
      },
      update: () => {
        loadReportStatus();
      },
      download: (requestUuid: string) => {
        new DeliveryReportRepository(managementApi).download({
          request_uuid: requestUuid,
        });
      },
      retry: (requestUuid: string) => {
        new DeliveryReportRepository(managementApi)
          .retry({
            request_uuid: requestUuid,
          })
          .then(() => {
            loadReportStatus();
          });
      },
    },
  ];
};

export default useDeliveryReportEvents;
