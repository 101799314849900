import { sortColumnType, sortStateType } from "interfaces/models";
import { fixedReportHeaderItems, FixedReportLabel } from "./FixedReportHeader";
import { Item, fixedReportItems } from "./FixedReportItem";
import styles from "./FixedReportTable.module.scss";

export type FixedReportTableRow = {
  items: Item[];
};
const FixedReportTable: React.VFC<{
  headerLabels: FixedReportLabel[];
  rows: FixedReportTableRow[];
  sortColumnId: sortColumnType;
  setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>;
  sortStatus: sortStateType;
  setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>;
  selectedPageNationNumber: number;
  setSelectedPageNationNumber: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  headerLabels,
  rows,
  sortColumnId,
  setSortColumnId,
  sortStatus,
  setSortStatus,
  selectedPageNationNumber,
  setSelectedPageNationNumber,
}): JSX.Element => {
  return (
    <table className={styles.reportTable}>
      <thead>
        <tr className={styles.header}>
          {fixedReportHeaderItems(
            headerLabels,
            sortColumnId,
            setSortColumnId,
            sortStatus,
            setSortStatus,
            selectedPageNationNumber,
            setSelectedPageNationNumber
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          const rowStyle: React.CSSProperties = {
            backgroundColor: i % 2 === 0 ? "#fff" : "#fff7f1",
          };
          return (
            <tr key={i} className={styles.row}>
              {fixedReportItems(row.items, rowStyle)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default FixedReportTable;
