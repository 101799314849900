import { Redirect, Route, Switch, useHistory } from "react-router-dom";
// FIXME: Move cognito dependency code to CognitoAuth
import { CognitoUserSession } from "amazon-cognito-identity-js";

// Sub-Routing
import ActionPagesRouting from "./ActionPagesRouting";
import SegmentationRulePagesRouting from "./SegmentationRulePagesRouting";
import ReportPagesRouting from "./ReportPageGroupRouting";

import { CognitoAuthOperations } from "app/loaders/CognitoAuth";
import AccountPageGroupPreprocess from "app/processors/AccountPageGroupPreprocess";

import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import CustomerAttributeHeaderRepository from "utils/CustomerAttributeHeaderRepository";
import { useEffect } from "react";
import { EzcxHeadInitializer } from "app/process/headInitializer";
import { Permission } from "interfaces/models";
import NotFoundPage from "pages/auth/NotFoundPage";

const AccountPageGroupRouting = ({
  apiEndpointUrl,
  session,
  adminTargetAccountId,
  operations,
  loginAccountId,
  onSessionExpired,
}: {
  apiEndpointUrl: string;
  session: CognitoUserSession | null;
  operations: CognitoAuthOperations;
  adminTargetAccountId?: string;
  loginAccountId: string;
  onSessionExpired: () => JSX.Element;
}) => {
  const history = useHistory();
  useEffect(() => {
    new EzcxHeadInitializer().run();
  }, []);
  const onSignOut = () => {
    operations.onSignOut();
    history.push("/login/signed_out");
  };

  const onSystemError = () => {
    history.push("/login/system_error");
  };

  return (
    <AccountPageGroupPreprocess
      apiEndpointUrl={apiEndpointUrl}
      session={session}
      adminTargetAccountId={adminTargetAccountId}
      onSessionExpired={onSessionExpired}
      loginAccountId={loginAccountId}
      onSignOut={onSignOut}
      onSystemError={onSystemError}
      render={({ context }) => {
        const segmentationRuleRepository = new SegmentationRuleRepository(
          context.managementApi
        );
        const customerAttributeHeaderRepository =
          new CustomerAttributeHeaderRepository(context.managementApi);

        if (context.permission === Permission.SYSTEM) {
          return (
            <Switch>
              <Route exact path="/">
                <Redirect to="/reports" />
              </Route>
              <Route exact path="/reports">
                <ReportPagesRouting context={context} />
              </Route>
              <Route path="/actions">
                <ActionPagesRouting context={context} />
              </Route>
              <Route path="/segmentation_rules">
                <SegmentationRulePagesRouting
                  context={context}
                  segmentationRuleRepository={segmentationRuleRepository}
                  customerAttributeHeaderRepository={
                    customerAttributeHeaderRepository
                  }
                />
              </Route>
              <Route>
                <Redirect to="/login" />
              </Route>
            </Switch>
          );
        } else {
          return (
            <Switch>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          );
        }
      }}
    />
  );
};

export default AccountPageGroupRouting;
