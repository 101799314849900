import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import { ExecuteTiming } from "interfaces/models";
import DayOfWeekForm from "molecules/CheckBox/DayOfWeekForm";
import PeriodForm from "molecules/pullDown/PeriodForm";
import styles from "./ExecuteTimingLayer.module.scss";

type executeTimingsType = {
  index: number;
  executeTiming: ExecuteTiming;
  executeTimings: ExecuteTiming[];
  onClickDeleteExecuteTiming: (index: number) => void;
  onChangeSetExecuteTimingFromHourValue(index: number, value: number): void;
  onChangeSetExecuteTimingFromTimeValue(index: number, value: number): void;
  onChangeSetExecuteTimingToHourValue(index: number, value: number): void;
  onChangeSetExecuteTimingToTimeValue(index: number, value: number): void;
  onChangeSetExecuteTimingDayOfWeek(index: number, label: string): void;
  onChangeSetExecuteTimingHoliday(index: number): void;
  getExecuteTimingHolidayState(index: number): boolean;
  getExecuteTimingDayOfWeekState(index: number, value: string): boolean;
};

const ExecuteTimingLayer = ({
  index,
  executeTiming,
  executeTimings,
  onClickDeleteExecuteTiming,
  onChangeSetExecuteTimingFromHourValue,
  onChangeSetExecuteTimingFromTimeValue,
  onChangeSetExecuteTimingToHourValue,
  onChangeSetExecuteTimingToTimeValue,
  onChangeSetExecuteTimingDayOfWeek,
  onChangeSetExecuteTimingHoliday,
  getExecuteTimingHolidayState,
  getExecuteTimingDayOfWeekState,
}: executeTimingsType) => {
  return (
    <div key={index} className={styles.executeTimings}>
      <div className={styles.executeTiming}>
        {index > 0 ? (
          <div className={styles.orText}>
            <NoticeText text="or" />
          </div>
        ) : undefined}
        <div className={styles.executeTimingGroup}>
          <div className={styles.period}>
            <PeriodForm
              index={index}
              executeTiming={executeTiming}
              onChangeSetExecuteTimingFromHourValue={
                onChangeSetExecuteTimingFromHourValue
              }
              onChangeSetExecuteTimingFromTimeValue={
                onChangeSetExecuteTimingFromTimeValue
              }
              onChangeSetExecuteTimingToHourValue={
                onChangeSetExecuteTimingToHourValue
              }
              onChangeSetExecuteTimingToTimeValue={
                onChangeSetExecuteTimingToTimeValue
              }
            />
          </div>
          <div className={styles.dayOfWeek}>
            <DayOfWeekForm
              executeTimingLayerIndex={index}
              onChangeSetExecuteTimingDayOfWeek={
                onChangeSetExecuteTimingDayOfWeek
              }
              onChangeSetExecuteTimingHoliday={onChangeSetExecuteTimingHoliday}
              getExecuteTimingHolidayState={getExecuteTimingHolidayState}
              getExecuteTimingDayOfWeekState={getExecuteTimingDayOfWeekState}
            />
          </div>
          {executeTimings.length > 1 ? (
            <div className={styles.delete}>
              <DeleteButton onClick={() => onClickDeleteExecuteTiming(index)} />
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
export default ExecuteTimingLayer;
