import ColorPicker from "molecules/ColorPicker/ColorPicker";
import styles from "./ColorPickForm.module.scss";

type ColorPickFormProps = {
  name: string;
  value: string;
  onChange: (value: any) => void;
};

const ColorPickForm = ({ name, value, onChange }: ColorPickFormProps) => {
  return (
    <div className={styles.colorPickForm}>
      <div className={styles.name}>{name}</div>
      <div className={styles.form}>
        <ColorPicker
          value={value}
          onChange={(color_code: string) => onChange(color_code)}
        />
      </div>
    </div>
  );
};

export default ColorPickForm;
