import styles from "./AsteriskList.module.scss";

const AsteriskList: React.VFC<{ items: JSX.Element[] }> = ({ items }) => (
  <ul className={styles.asteriskList}>
    {items.map((item, index) => (
      <li key={index} className={styles.item}>
        {item}
      </li>
    ))}
  </ul>
);

export default AsteriskList;
