import EventText from "atoms/accountPagesShared/EventText";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import TextInput from "atoms/TextInput";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";

import styles from "./UrlParameterForms.module.scss";

//=============================================================================
// Type Definitions

type ParameterMatchMode = ManagementApiData.ParameterMatchMode;

type ParameterFormState = {
  keyValue: string;
  option: ParameterMatchMode;
  onChangeKeyValue: (value: string) => void;
  onChangeOption: (value: ParameterMatchMode) => void;
};

type ParametersFormState = {
  addForm: () => void;
  deleteForm: (index: number) => void;
  items: ParameterFormState[];
  isActive?: boolean;
};

//=============================================================================
// utility functions

//=============================================================================
// Rendering Components

const UrlParameterForms: React.VFC<{
  state: ParametersFormState;
}> = ({ state }) => {
  const options: { value: ParameterMatchMode; label: string }[] = [
    {
      value: "include",
      label: "を含む",
    },
    {
      value: "exclude",
      label: "を除く",
    },
  ];

  return (
    <div className={styles.parameters}>
      <div className={styles.forms}>
        {state.items.map((p, idx) => (
          <div key={idx}>
            {idx === 0 ? undefined : (
              <div className={styles.separator}>
                <NoticeText text="and" />
              </div>
            )}
            <div key={idx} className={styles.form}>
              {idx === 0 ? (
                <div className={styles.name}>パラメータ</div>
              ) : (
                <div className={styles.empty} />
              )}
              <div className={styles.input}>
                <TextInput
                  value={p.keyValue}
                  onChange={(e) => p.onChangeKeyValue(e.target.value)}
                  placeholder="product_no="
                  isActive={
                    state.isActive !== undefined ? state.isActive : true
                  }
                />
              </div>
              <div className={styles.select}>
                <BorderedSelectBox
                  options={options}
                  value={p.option}
                  onChange={(e) => p.onChangeOption(e as ParameterMatchMode)}
                  isDisabled={
                    state.isActive !== undefined ? !state.isActive : false
                  }
                />
              </div>
              {state.items.length > 1 ? (
                <div className={styles.delete}>
                  <DeleteButton onClick={() => state.deleteForm(idx)} />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.add}>
        <EventText
          text="追加"
          onClick={state.addForm}
          isActive={state.isActive !== undefined ? state.isActive : true}
        />
      </div>
    </div>
  );
};

export default UrlParameterForms;
