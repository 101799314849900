import SelectWrap from "atoms/accountPagesShared/SelectWrap";
import { ActionStatus } from "interfaces/models";

const ActionStatusSelect = ({
  value,
  onChange,
}: {
  value: ActionStatus;
  onChange: (s: ActionStatus) => void;
}) => {
  const options = [
    { value: "ACTIVE", label: "有効" },
    { value: "TESTING", label: "一時停止" },
  ];

  const currentStatus = options.filter((o) => o.value === value);

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      fontSize: "13px",
      lineHeight: "20px",
      textAlign: "left",
      "&:hover": {
        cursor: "pointer",
      },
      backgroundColor: "transparent",
    }),
    valueContainer: (provided: any) => ({
      width: "60px",
      margin: 0,
      padding: 0,
    }),
    indicatorContainer: (provided: any) => ({
      ...provided,
      width: "11px",
      margin: 0,
      padding: 0,
    }),
    control: () => ({
      display: "flex",
      justifyContact: "center",
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#FFF",
      width: "81px",
      margin: 0,
      padding: "0px",
      border: 0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      margin: 0,
      padding: 0,
      border: 0,
    }),
    option: () => ({
      padding: "10px 10px",
      "&:hover": {
        backgroundColor: "#FFF7F1",
        cursor: "pointer",
        "&:first-child": {
          "border-top-right-radius": "5px",
          "border-top-left-radius": "5px",
        },
        "&:last-child": {
          "border-bottom-right-radius": "5px",
          "border-bottom-left-radius": "5px",
        },
      },
      border: "none",
    }),
    indicatorSeparator: (provided: any) => ({
      display: "none",
      backgroundColor: "transparent",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
      height: "20px",
      margin: 0,
      padding: 0,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      margin: 0,
      paddingLeft: 0,
      paddingRight: 1,
      textOverflow: "clip",
    }),
  };

  return (
    <SelectWrap
      options={options}
      value={currentStatus}
      onChange={(e) => {
        if (value !== e.value) {
          onChange(e.value as ActionStatus);
        }
      }}
      customStyles={customStyles}
    />
  );
};

export default ActionStatusSelect;
