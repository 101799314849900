import styles from "./ImageTextTile.module.scss";

type ImageTextTileProps = {
  text?: string;
  img?: JSX.Element;
  isActive?: boolean;
  onClick: () => void;
};

const ImageTextTile = ({
  text,
  img,
  isActive = false,
  onClick,
}: ImageTextTileProps) => (
  <div
    className={isActive ? styles.imageTextTile__active : styles.imageTextTile}
    onClick={onClick}
  >
    <div className={styles.image}>{img}</div>
    <div>{text}</div>
  </div>
);

export default ImageTextTile;
