import React from "react";
import styles from "./VerticalTable.module.scss";

type VerticalTableRow = {
  label: string | JSX.Element;
  value: string | JSX.Element;
};

const VerticalTable: React.FC<{
  items: VerticalTableRow[];
}> = ({ items }) => (
  <table className={styles.verticalTable}>
    <tbody className={styles.body}>
      {items.map((item, i) => (
        <tr key={i} className={styles.row}>
          <td className={styles.header}>{item.label}</td>
          <td className={styles.data}>{item.value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);
export default VerticalTable;
