import styles from "./TextArea.module.scss";

type TextAreaProps = {
  value: string;
  onChange: (value: string) => void;
};

const TextArea = ({ value, onChange }: TextAreaProps) => {
  return (
    <textarea
      className={styles.textArea}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default TextArea;
