import EventText from "atoms/accountPagesShared/EventText";
import { ColorResult } from "react-color";
import { hexToColor } from "utils/color";
import GradationColorCircle from "./GradationColorCircle";
import styles from "./GradationPresetColorPicker.module.scss";

type CreateCirclesProps = {
  colors: ColorResult[][][];
  currentColor: ColorResult[];
  onClickCircle: (colors: ColorResult[]) => void;
};

const CreateCircles = ({
  colors,
  currentColor,
  onClickCircle,
}: CreateCirclesProps): JSX.Element => {
  return (
    <div className={styles.circleArea}>
      {colors.map((row, row_count) => (
        <div key={row_count} className={styles.circleRow}>
          {row.map((elem, elem_count) => (
            <div key={elem_count} className={styles.circleElem}>
              <GradationColorCircle
                colors={elem}
                isActive={elem
                  .map((e, i) =>
                    currentColor[i] ? e.hex === currentColor[i].hex : false
                  )
                  .every((e) => e)}
                onChange={() => onClickCircle(elem)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

type GradationPresetColorPickerProps = {
  colors: ColorResult[];
  onChange: (e: ColorResult[]) => void;
  onChangePicker: () => void;
};

const GradationPresetColorPicker = (props: GradationPresetColorPickerProps) => {
  const colors: ColorResult[][][] = [
    [
      [hexToColor("#FF8B33"), hexToColor("#FF0561")],
      [hexToColor("#739EE3"), hexToColor("#0D337B")],
      [hexToColor("#A3DB62"), hexToColor("#458312")],
      [hexToColor("#FFCF05"), hexToColor("#F4A40C")],
      [hexToColor("#3D0A72"), hexToColor("#FF0561")],
      [hexToColor("#FBFBFD"), hexToColor("#E0EBF5")],
      [hexToColor("#656565"), hexToColor("#000000")],
    ],
  ].filter((row): row is ColorResult[][] =>
    row
      .filter((col): col is ColorResult[] =>
        col
          .filter((elem): elem is ColorResult => elem !== undefined)
          .every((e) => e)
      )
      .every((e) => e)
  );

  return (
    <div className={styles.gradientPresetColorPicker}>
      <CreateCircles
        colors={colors}
        currentColor={props.colors}
        onClickCircle={(c) => props.onChange(c)}
      />
      <div className={styles.footer}>
        <div>
          <EventText text="スライダーで選ぶ" onClick={props.onChangePicker} />
        </div>
      </div>
    </div>
  );
};

export default GradationPresetColorPicker;
