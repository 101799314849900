import { cloneElement } from "react";
import styles from "./Footer.module.scss";

const Footer = ({ elements }: { elements: JSX.Element[] }) => {
  return (
    <div className={styles.footer}>
      {elements
        .map((e) => [e, <div>　|　</div>])
        .flat()
        .slice(0, -1)
        .map((e, i) => cloneElement(e, { key: i }))}
    </div>
  );
};

export default Footer;
