import React, { useCallback, useMemo, useState } from "react";

import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import fontStyles from "fontStyles.module.scss";

import { useBasicConfigrationPageParameters } from "app/hooks/v2/basicConfiguration/usePageParameters";
import {
  BASIC_CONFIGURATION_TYPE,
  BasicConfigurationType,
} from "app/system_defaults/v2_routing";

import Tabs from "molecules/TableTab/Tabs";
import DomainConfiguration from "organisms/ezPush/basicConfiguration/DomainConfiguration";
import UrlSettingConfiguration from "organisms/ezPush/basicConfiguration/MeasurementUrlSettingConfiguration";
import DomainCreateModal from "organisms/ezPush/basicConfiguration/DomainCreateModal";
import Panel from "templates/ezPush/Panel";
import TitleMainLayout from "templates/ezPush/TitleMainLayout";
import AccountPageBase from "./AccountPageBase";
import DomainDeleteModal from "organisms/ezPush/basicConfiguration/DomainDeleteModal";
import FileConfiguration from "organisms/ezPush/basicConfiguration/FileConfiguration";
import OptionConfiguration from "organisms/ezPush/basicConfiguration/OptionConfiguration";

const tabs = [
  BASIC_CONFIGURATION_TYPE.DOMAIN,
  BASIC_CONFIGURATION_TYPE.FILE,
  BASIC_CONFIGURATION_TYPE.URL,
  BASIC_CONFIGURATION_TYPE.OPTION,
];

const ConfigurationTitles = {
  [BASIC_CONFIGURATION_TYPE.DOMAIN]: {
    active: (
      <div>
        <span style={{ color: "#ff7b2c" }}>STEP1</span> ドメイン設定
      </div>
    ),
    inactive: <div style={{ color: "#818181" }}>STEP1 ドメイン設定</div>,
  },
  [BASIC_CONFIGURATION_TYPE.FILE]: {
    active: (
      <div>
        <span style={{ color: "#ff7b2c" }}>STEP2</span>{" "}
        JSファイル・専用タグ設置と確認
      </div>
    ),
    inactive: (
      <div style={{ color: "#818181" }}>
        STEP2 JSファイル・専用タグ設置と確認
      </div>
    ),
  },
  [BASIC_CONFIGURATION_TYPE.URL]: {
    active: (
      <div>
        <span style={{ color: "#ff7b2c" }}>STEP3</span> ページ設定
      </div>
    ),
    inactive: <div style={{ color: "#818181" }}>STEP3 ページ設定</div>,
  },
  [BASIC_CONFIGURATION_TYPE.OPTION]: {
    active: (
      <div>
        <span style={{ color: "#ff7b2c" }}>オプション設定</span>
      </div>
    ),
    inactive: <div style={{ color: "#818181" }}>オプション設定</div>,
  },
};

// NOTE: page component
const BasicConfigurationPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [modalContents, setModalContents] = useState<JSX.Element>();
  const [parameters, callbacks] = useBasicConfigrationPageParameters(
    accountContext.managementApi
  );

  const [selectedTab, setSelectedTab] = useState<BasicConfigurationType>(
    parameters.urlParameters.children || BASIC_CONFIGURATION_TYPE.DOMAIN
  );

  const getConfigurationsTab = useCallback(
    (type: BasicConfigurationType) => {
      return {
        label:
          selectedTab === type
            ? ConfigurationTitles[type].active
            : ConfigurationTitles[type].inactive,
        onClick: () => {
          setSelectedTab(type);
          callbacks.events.updateHistory(type);
        },
        is_active: selectedTab === type,
      };
    },
    [setSelectedTab, selectedTab, callbacks]
  );

  const panelList = useMemo(
    () => ({
      [BASIC_CONFIGURATION_TYPE.DOMAIN]: (
        <DomainConfiguration
          domains={parameters.domain.domains}
          onCreate={() =>
            setModalContents(
              <DomainCreateModal
                createDomain={(domain) => {
                  callbacks.domain.create(domain).then(() => {
                    setModalContents(undefined);
                  });
                }}
                clear={() => setModalContents(undefined)}
              />
            )
          }
          onDelete={(domain, domainUuid) =>
            setModalContents(
              <DomainDeleteModal
                domain={domain}
                deleteDomain={() => {
                  callbacks.domain.delete(domainUuid).then(() => {
                    setModalContents(undefined);
                  });
                }}
                clear={() => setModalContents(undefined)}
              />
            )
          }
        />
      ),
      [BASIC_CONFIGURATION_TYPE.FILE]: (
        <FileConfiguration
          domains={parameters.domain.domains}
          downloadSW={callbacks.file.downloadSWLoader}
          downloadTag={callbacks.file.downloadTagLoader}
          downloadAll={callbacks.file.downloadAll}
        />
      ),
      [BASIC_CONFIGURATION_TYPE.URL]: (
        <UrlSettingConfiguration
          subscriptionUrlSetting={parameters.urlSetting.subscriptionUrlSetting}
          segmentationUrlSetting={parameters.urlSetting.segmentationUrlSetting}
          conversionUrlSetting={parameters.urlSetting.conversionUrlSetting}
          isLoaded={parameters.urlSetting.isLoaded}
          createNewSetting={callbacks.events.goToCreateUrlSetting}
          updateSetting={callbacks.events.editUrlSetting}
          deleteSetting={callbacks.urlSettings.delete}
          setModalContents={setModalContents}
        />
      ),
      [BASIC_CONFIGURATION_TYPE.OPTION]: <OptionConfiguration />,
    }),
    [callbacks, parameters]
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <TitleMainLayout
          titleElement={<span className={fontStyles.title}>基本設定</span>}
          mainElement={
            <>
              <Tabs tabProps={tabs.map((type) => getConfigurationsTab(type))} />
              <Panel itemElements={[panelList[selectedTab]]} />
            </>
          }
        />
      }
      modalContents={modalContents}
    />
  );
};

export default BasicConfigurationPage;
