import EventText from "atoms/accountPagesShared/EventText";
import { ExecuteTiming } from "interfaces/models";
import ExecuteTimingLayer from "organisms/account/ExecuteTimingLayer";
import styles from "./ExecuteTimingForm.module.scss";

type ExecuteTimingFormProps = {
  executeTimings: ExecuteTiming[];
  setIsExecuteTimingsActive: (b: boolean) => void;
  onClickInsertExecuteTiming(): void;
  onClickDeleteExecuteTiming(index: number): void;
  onChangeSetExecuteTimingFromHourValue(index: number, value: number): void;
  onChangeSetExecuteTimingFromTimeValue(index: number, value: number): void;
  onChangeSetExecuteTimingToHourValue(index: number, value: number): void;
  onChangeSetExecuteTimingToTimeValue(index: number, value: number): void;
  onChangeSetExecuteTimingDayOfWeek(index: number, label: string): void;
  onChangeSetExecuteTimingHoliday(index: number): void;
  getExecuteTimingHolidayState(index: number): boolean;
  getExecuteTimingDayOfWeekState(index: number, value: string): boolean;
  getExecuteTimingHolidayState(index: number): boolean;
};

const ExecuteTimingForm = ({
  executeTimings,
  onClickInsertExecuteTiming,
  onClickDeleteExecuteTiming,
  onChangeSetExecuteTimingFromHourValue,
  onChangeSetExecuteTimingFromTimeValue,
  onChangeSetExecuteTimingToHourValue,
  onChangeSetExecuteTimingToTimeValue,
  onChangeSetExecuteTimingDayOfWeek,
  onChangeSetExecuteTimingHoliday,
  getExecuteTimingHolidayState,
  getExecuteTimingDayOfWeekState,
}: ExecuteTimingFormProps) => {
  const executeTimingLayerElement = () => {
    const element = executeTimings.map((executeTiming, index) => {
      return (
        <ExecuteTimingLayer
          index={index}
          executeTiming={executeTiming}
          executeTimings={executeTimings}
          onClickDeleteExecuteTiming={onClickDeleteExecuteTiming}
          onChangeSetExecuteTimingFromHourValue={
            onChangeSetExecuteTimingFromHourValue
          }
          onChangeSetExecuteTimingFromTimeValue={
            onChangeSetExecuteTimingFromTimeValue
          }
          onChangeSetExecuteTimingToHourValue={
            onChangeSetExecuteTimingToHourValue
          }
          onChangeSetExecuteTimingToTimeValue={
            onChangeSetExecuteTimingToTimeValue
          }
          onChangeSetExecuteTimingDayOfWeek={onChangeSetExecuteTimingDayOfWeek}
          onChangeSetExecuteTimingHoliday={onChangeSetExecuteTimingHoliday}
          getExecuteTimingHolidayState={getExecuteTimingHolidayState}
          getExecuteTimingDayOfWeekState={getExecuteTimingDayOfWeekState}
        />
      );
    });
    return element;
  };

  return (
    <div className={styles.container}>
      <div className={styles.executeTiming}>{executeTimingLayerElement()}</div>
      <div className={styles.add}>
        <EventText text="追加" onClick={() => onClickInsertExecuteTiming()} />
      </div>
    </div>
  );
};

export default ExecuteTimingForm;
