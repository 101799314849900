export const formatDate = (date: Date, separator: string = "") => {
  const _month = ("0" + (date.getMonth() + 1)).slice(-2);
  const _date = ("0" + date.getDate()).slice(-2);
  return `${date.getFullYear()}${separator}${_month}${separator}${_date}`;
};

export const subtractDays = (targetDate: Date, days: number): Date => {
  targetDate.setDate(targetDate.getDate() - days);

  return targetDate;
};
