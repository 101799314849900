import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";

export const CancelScheduleModal: React.VFC<{
  clearModalElement: () => void;
  submit: () => void;
}> = ({ clearModalElement, submit }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="この配信予約をキャンセルします。よろしいですか？"
        description={
          <MultipleLineText
            texts={[
              "キャンセル後は下書き一覧から再編集できます。",
              "なお、設定されている配信予定日時は破棄されます。",
            ]}
          />
        }
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={submit} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

export const DeleteCampaignModal: React.VFC<{
  clearModalElement: () => void;
  submit: () => void;
}> = ({ clearModalElement, submit }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="この下書きを削除します。よろしいですか？"
        description="削除すると元には戻せません。"
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={submit} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

export const CopyCampaignModal: React.VFC<{
  clearModalElement: () => void;
  submit: () => void;
}> = ({ clearModalElement, submit }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="作成画面に移動します。よろしいですか？"
        description="この設定内容を元に新たなプッシュ通知を作成します。"
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={submit} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};
