import {
  GetJobHistoryRequest,
  GetJobHistoryResponse,
  JobHistoryResponse,
} from "types/job_history";
import RestApi from "utils/RestApi";

class JobHistoryRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async load(): Promise<JobHistoryResponse> {
    const data = await this.managementApi.get("jobs");
    return data;
  }

  async get(request: GetJobHistoryRequest): Promise<GetJobHistoryResponse> {
    const data = await this.managementApi.get(`jobs/${request.campaignUuid}/${request.jobId}`);
    return data;
  }
}

export default JobHistoryRepository;
