import React from "react";
import styles from "./GrantMessageTileLayout.module.scss";

const GrantMessageTileLayout: React.VFC<{
  describeElement: JSX.Element;
  previewElement: JSX.Element;
  controlElement: JSX.Element;
}> = ({ describeElement, previewElement, controlElement }) => {
  return (
    <div className={styles.grantMessageTileLayout}>
      <div className={styles.left}>{describeElement}</div>
      <div className={styles.right}>
        <div className={styles.preview}>{previewElement}</div>
        <div>{controlElement}</div>
      </div>
    </div>
  );
};

export default GrantMessageTileLayout;
