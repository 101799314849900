import CheckBox from "molecules/CheckBox/CheckBox";
import RangeSlider from "molecules/RangeSlider/RangeSlider";

import styles from "./CheckBoxRangeSliderForm.module.scss";

type CheckBoxRangeSliderFormProps = {
  name: JSX.Element;
  checkBoxText: string;
  isChecked: boolean;
  value: number;
  min: number;
  max: number;
  step: number;
  onChangeCheckBox: (value: boolean) => void;
  onChangeSlider: (value: number) => void;
  valueTextEditCallback: (value: number) => string;
};

const CheckBoxRangeSliderForm = ({
  name,
  checkBoxText,
  isChecked,
  value,
  min,
  max,
  step,
  onChangeCheckBox,
  onChangeSlider,
  valueTextEditCallback,
}: CheckBoxRangeSliderFormProps) => {
  return (
    <div className={styles.checkBoxRangeSliderForm}>
      <div className={styles.name}>{name}</div>
      <div className={styles.slider}>
        <RangeSlider
          value={value}
          minValue={min}
          maxValue={max}
          stepValue={step}
          isActive={!isChecked}
          onChange={(e) => onChangeSlider(e)}
          valueTextEditCallback={valueTextEditCallback}
        />
      </div>
      <div className={styles.checkBox}>
        <CheckBox
          text={checkBoxText}
          value={isChecked}
          onChange={() => onChangeCheckBox(!isChecked)}
          customStyle={{
            fontSize: "10px",
          }}
        />
      </div>
    </div>
  );
};

export default CheckBoxRangeSliderForm;
