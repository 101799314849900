import styles from "./DeliveryReportPageForm.module.scss";
import fontStyles from "fontStyles.module.scss";

import TitleForm from "molecules/v2/TitleForm";
import NumberInput from "atoms/ezPushShared/NumberInput";
import Panel from "templates/ezPush/Panel";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import React, { useMemo } from "react";
import {
  DeliveryReportFormCallbacks,
  DeliveryReportFormParameters,
} from "app/hooks/v2/deliveryReport/useDeliveryReportForm";
import DatePickerWrapper from "molecules/DatePicker/DatePickerWrapper";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import { DELIVERY_REPORT_DEFAULTS } from "app/system_defaults/delivery_report_defaults";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import Label from "molecules/v2/Label";

const DeliveryReportAggregateRequestForm: React.VFC<{
  formParameters: DeliveryReportFormParameters;
  formCallbacks: DeliveryReportFormCallbacks;
  currentDate: Date;
  submit: () => void;
}> = ({ formParameters, formCallbacks, currentDate, submit }) => {
  const titleElement = useMemo(
    () => (
      <Label
        label={<span className={fontStyles.title}>レポート集計設定</span>}
        toolTip={"プッシュ通知の配信結果をCSVファイルでダウンロードできます"}
      />
    ),
    []
  );

  const reportRangeFormElement = useMemo(
    () => (
      <TitleForm
        title="レポート対象期間"
        mainElement={
          <HorizontalLayout
            elements={[
              <DatePickerWrapper
                date={formParameters.fromDate}
                onChange={formCallbacks.updateFromDate}
                maxDate={currentDate}
              />,
              <div className={fontStyles.boldText}>～</div>,
              <DatePickerWrapper
                date={formParameters.toDate}
                onChange={formCallbacks.updateToDate}
                maxDate={currentDate}
              />,
            ]}
          />
        }
        toolTip={
          <MultipleLineText
            texts={[
              "指定した期間に配信されたプッシュ通知のレポートを出力します。",
              "本日分は15分前までのデータが出力できます。",
            ]}
          />
        }
      />
    ),
    [
      formParameters.fromDate,
      formParameters.toDate,
      formCallbacks.updateFromDate,
      formCallbacks.updateToDate,
      currentDate,
    ]
  );

  const trackingRangeFormElement = useMemo(
    () => (
      <TitleForm
        title="トラッキング期間"
        mainElement={
          <div className={styles.trackingRangeForm}>
            <div className={styles.form}>
              <NumberInput
                value={formParameters.trackingDateRange}
                onChange={formCallbacks.updateTrackingDateRange}
                min={DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MIN}
                max={DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MAX}
              />
            </div>
            <div className={fontStyles.boldText}>日</div>
          </div>
        }
        notification="1～60の整数のみ"
        toolTip={
          <MultipleLineText
            texts={[
              "クリックしてから何日分のCV数を集計するかを指定します。",
              "レポート対象期間外のCVは集計されません。",
            ]}
          />
        }
      />
    ),
    [formParameters.trackingDateRange, formCallbacks.updateTrackingDateRange]
  );

  const submitButton = useMemo(
    () => (
      <div className={styles.submit}>
        <OrangeButton text="集計開始" onClick={submit} />
      </div>
    ),
    [submit]
  );

  return (
    <div className={styles.DeliveryReportPageForm}>
      <Panel
        titleElement={titleElement}
        itemElements={[
          reportRangeFormElement,
          trackingRangeFormElement,
          submitButton,
        ]}
      />
    </div>
  );
};

export default DeliveryReportAggregateRequestForm;
