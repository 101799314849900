import { sortColumnType, sortStateType } from "interfaces/models";
import { reportHeaderItems, reportLabel } from "./ReportHeader";
import { Item, reportItems } from "./ReportItem";
import styles from "./ReportTable.module.scss";

export type ReportTableRow = {
  items: Item[];
};
const ReportTable: React.VFC<{
  headerLabels: reportLabel[];
  rows: ReportTableRow[];
  sortColumnId: sortColumnType;
  setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>;
  sortStatus: sortStateType;
  setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>;
}> = ({
  headerLabels,
  rows,
  sortColumnId,
  setSortColumnId,
  sortStatus,
  setSortStatus,
}): JSX.Element => {
  return (
    <table className={styles.reportTable}>
      <thead>
        <tr className={styles.header}>
          {reportHeaderItems(
            headerLabels,
            sortColumnId,
            setSortColumnId,
            sortStatus,
            setSortStatus
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i} className={styles.row}>
            {reportItems(row.items)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReportTable;
