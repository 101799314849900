import { RawReport, Report } from "interfaces/models";
import RestApi from "./RestApi";

class ReportRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get_json(
    whole_report_path: string,
    action_detail_report_path: string
  ): Promise<Report> {
    const data = await this.managementApi.get("action_report_detail", {
      whole_report_path: whole_report_path,
      action_detail_report_path: action_detail_report_path,
    });

    return data["results"];
  }

  async get_raw_csv(report_path: string): Promise<RawReport> {
    const data = await this.managementApi.get("action_raw_report_detail", {
      report_path: report_path,
    });

    return data["data"];
  }
}

export default ReportRepository;
