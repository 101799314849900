import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchParameters, {
  encodeSearchParameters,
  parseSearchParameters,
} from "utils/SearchParameters";

const useSearchParameters = (): [
  SearchParameters,
  (newParameter: SearchParameters) => void
] => {
  const location = useLocation();
  const history = useHistory();
  const [searchParameters, setSearchParameters] = useState<SearchParameters>(
    {}
  );

  useEffect(() => {
    setSearchParameters(parseSearchParameters(location.search));
  }, [location.search]);

  const updateSearchParameters = useCallback(
    (newParameter: SearchParameters) => {
      history.push(
        location.pathname +
          encodeSearchParameters({
            ...searchParameters,
            ...newParameter,
          })
      );
    },
    [history, location.pathname, searchParameters]
  );

  return [searchParameters, updateSearchParameters];
};

export default useSearchParameters;
