import {
  CustomContentsFormParameters,
  MAIN_LAYOUT,
  CREATIVE_PARTS,
  CustomContentsFormat,
  CUSTOM_CONTENTS_FORMAT,
} from "interfaces/model/actionContentsParameters";
import { CREATIVE_PARTS_CLASS_NAME } from "./creative";
import {
  createSubHeadlineParts,
  createHeadlineParts,
  createImageParts,
  createArticleParts,
  createCouponCodeParts,
  createButton,
  createCloseButton,
  createContentsParts,
} from "./Layouts";

const createSmallImageLayoutHtml = (
  params: CustomContentsFormParameters,
  styles: {
    subHeadlineText: string;
    headlineText: string;
    text: string;
    buttonText: string;
    buttonLinkText: string;
    isOpenOtherWindow: boolean;
    couponCodeText: string;
    couponButtonText: string;
    closeButtonSize: string;
  },
  imageUrl: string
) => {
  const parts = params.parts;

  if (params.layout === MAIN_LAYOUT.HEADER_TITLE) {
    return `
<div class="${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}">
  ${
    parts.includes(CREATIVE_PARTS.SUB_HEADLINE)
      ? createSubHeadlineParts(styles.subHeadlineText)
      : ""
  }
  ${
    parts.includes(CREATIVE_PARTS.HEADLINE)
      ? createHeadlineParts(styles.headlineText)
      : ""
  }
  <div class="${CREATIVE_PARTS_CLASS_NAME.CONTAINER}">
    ${
      parts.includes(CREATIVE_PARTS.MAGNIFICATION_IMAGE)
        ? createImageParts(imageUrl)
        : ""
    }
    <div class="${CREATIVE_PARTS_CLASS_NAME.CONTAINER_TEXT}">
      ${
        parts.includes(CREATIVE_PARTS.ARTICLE)
          ? createArticleParts(styles.text)
          : ""
      }
    </div>
  </div>
  ${
    parts.includes(CREATIVE_PARTS.COUPON_CODE)
      ? createCouponCodeParts(styles.couponCodeText, styles.couponButtonText)
      : ""
  }
  ${
    parts.includes(CREATIVE_PARTS.BUTTON)
      ? createButton(
          styles.buttonLinkText,
          styles.buttonText,
          styles.isOpenOtherWindow
        )
      : ""
  }
</div>
<div class="${
      CREATIVE_PARTS_CLASS_NAME.FIXED_AREA
    }">${createCloseButton()}</div>`;
  }

  return `
<div class="${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}">
  ${
    parts.includes(CREATIVE_PARTS.SUB_HEADLINE)
      ? createSubHeadlineParts(styles.subHeadlineText)
      : ""
  }
  <div class="${CREATIVE_PARTS_CLASS_NAME.CONTAINER}">
    ${
      parts.includes(CREATIVE_PARTS.MAGNIFICATION_IMAGE)
        ? createImageParts(imageUrl)
        : ""
    }
    <div class="${CREATIVE_PARTS_CLASS_NAME.CONTAINER_TEXT}">
      ${
        parts.includes(CREATIVE_PARTS.HEADLINE)
          ? createHeadlineParts(styles.headlineText)
          : ""
      }
      ${
        parts.includes(CREATIVE_PARTS.ARTICLE)
          ? createArticleParts(styles.text)
          : ""
      }
    </div>
  </div>
  ${
    parts.includes(CREATIVE_PARTS.COUPON_CODE)
      ? createCouponCodeParts(styles.couponCodeText, styles.couponButtonText)
      : ""
  }
  ${
    parts.includes(CREATIVE_PARTS.BUTTON)
      ? createButton(
          styles.buttonLinkText,
          styles.buttonText,
          styles.isOpenOtherWindow
        )
      : ""
  }
</div>
<div class="${
    CREATIVE_PARTS_CLASS_NAME.FIXED_AREA
  }">${createCloseButton()}</div>`;
};

const createDefaultLayoutHtml = (
  params: CustomContentsFormParameters,
  styles: {
    subHeadlineText: string;
    headlineText: string;
    text: string;
    buttonText: string;
    buttonLinkText: string;
    isOpenOtherWindow: boolean;
    imageUrl: string;
    imageLinkText: string;
    couponCodeText: string;
    couponButtonText: string;
    closeButtonSize: string;
  },
  imageUrl: string
) => {
  let items: string[] = [];

  params.parts.forEach((e) => {
    items.push(createContentsParts(e, styles, imageUrl));
  });

  // NOTE: close button must be set
  return `
  <div class="${
    CREATIVE_PARTS_CLASS_NAME.FIXED_AREA
  }">${createCloseButton()}</div>
  <div class="${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}">
    ${items.map((e) => e).join("")}
  </div>`;
};

export const createLayoutHtml = (
  formatPattern: CustomContentsFormParameters,
  format: CustomContentsFormat,
  styles: {
    subHeadlineText: string;
    headlineText: string;
    text: string;
    buttonText: string;
    buttonLinkText: string;
    isOpenOtherWindow: boolean;
    imageUrl: string;
    imageLinkText: string;
    couponCodeText: string;
    couponButtonText: string;
    closeButtonSize: string;
  },
  imageUrl: string
): string => {
  if (format === CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE) {
    return createSmallImageLayoutHtml(formatPattern, styles, imageUrl);
  } else {
    return createDefaultLayoutHtml(formatPattern, styles, imageUrl);
  }
};
