import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import { SignInCallback } from "../loaders/CognitoAuth";
import NewPasswordPageV2 from "pages/ezPush/auth/NewPasswordPageV2";
import SessionExpiredPageV2 from "pages/ezPush/auth/SessionExpiredPageV2";
import SignedOutPageV2 from "pages/ezPush/auth/SignedOutPageV2";
import SystemErrorPageV2 from "pages/ezPush/auth/SystemErrorPageV2";
import SignInPageV2 from "pages/ezPush/auth/SignInPageV2";

interface SignInRoutingV2Props {
  onSignIn: SignInCallback;
  onCompleteNewPasswordChallenge: (
    newPassword: string,
    loginPath: string,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
}

const SignInRouteV2 = ({
  onSignIn,
  onCompleteNewPasswordChallenge,
}: SignInRoutingV2Props) => {
  const history = useHistory();
  const backToLogin = () => history.push(V2_ROUTING_TABLE.AUTH.LOGIN);

  return (
    <Switch>
      <Route exact path={V2_ROUTING_TABLE.AUTH.LOGIN}>
        <SignInPageV2
          onSignIn={(accountId, userId, password, isAdmin, onError) => {
            onSignIn(
              accountId,
              userId,
              password,
              isAdmin,
              () => history.push(V2_ROUTING_TABLE.ROOT),
              onError,
              () => history.push(V2_ROUTING_TABLE.AUTH.NEW_PASSWORD)
            );
          }}
        />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.AUTH.NEW_PASSWORD}>
        <NewPasswordPageV2
          onComplete={(newPassword, onErrorMessage) =>
            onCompleteNewPasswordChallenge(
              newPassword,
              V2_ROUTING_TABLE.AUTH.LOGIN,
              () =>
                history.push(
                  V2_ROUTING_TABLE.ACCOUNT.INITIALIZE.SUBSCRIBE_DOMAIN
                ),
              (err) => onErrorMessage(err)
            )
          }
        />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.AUTH.SIGNED_OUT}>
        <SignedOutPageV2 onClickLoginButton={backToLogin} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.AUTH.SESSION_EXPIRED}>
        <SessionExpiredPageV2 onClickLoginButton={backToLogin} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.AUTH.SYSTEM_ERROR}>
        <SystemErrorPageV2 onClickLoginButton={backToLogin} />
      </Route>
      <Route exact path="*">
        <Redirect to={V2_ROUTING_TABLE.AUTH.LOGIN} />
      </Route>
    </Switch>
  );
};

export default SignInRouteV2;
