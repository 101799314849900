import ErrorMessage from "utils/ErrorMessage";
import { MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import { MEASUREMENT_URL_SETTING_FORM_VALIDATE_RULE } from "app/system_defaults/validateRule";

export class MeasurementUrlSettingValidator {
  static validateTitle(title: string): ErrorMessage[] {
    let errors: ErrorMessage[] = [];
    if (title.length <= 0) {
      errors.push(
        new ErrorMessage(
          MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST.TITLE.MUST_BE_FILLED
        )
      );
    }

    if (
      title.length > MEASUREMENT_URL_SETTING_FORM_VALIDATE_RULE.TITLE.MAX_LENGTH
    ) {
      errors.push(
        new ErrorMessage(
          MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST.TITLE.CHARACTER_LIMIT
        )
      );
    }
    return errors;
  }

  static validateLocation(location: {
    value: string;
    pattern: string;
  }): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (
      location.value.length >
      MEASUREMENT_URL_SETTING_FORM_VALIDATE_RULE.LOCATION.MAX_LENGTH
    ) {
      errors.push(
        new ErrorMessage(
          MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST.LOCATION.CHARACTER_LIMIT
        )
      );
    }

    return errors;
  }

  static validateQueryParameters(
    queryParameters: {
      keyValue: string;
      pattern: string;
    }[]
  ): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    const LIMIT_OVER_PARAMS = queryParameters.filter(
      (item) =>
        item.keyValue.length >
        MEASUREMENT_URL_SETTING_FORM_VALIDATE_RULE.QUERY_PARAMETERS.MAX_LENGTH
    );

    if (LIMIT_OVER_PARAMS.length > 0) {
      errors.push(
        new ErrorMessage(
          MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST.QUERY_PARAMETER.CHARACTER_LIMIT
        )
      );
    }

    return errors;
  }

  static validateComposite(
    location: {
      value: string;
      pattern: string;
    },
    queryParameters: {
      keyValue: string;
      pattern: string;
    }[]
  ): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    const under_zero_parameters = queryParameters.filter(
      (param) => param.keyValue.length <= 0
    );

    if (location.value.length <= 0 && under_zero_parameters.length > 0) {
      errors.push(
        new ErrorMessage(
          MEASUREMENT_URL_SETTING_ERROR_MESSAGE_LIST.COMPOSITE.MUST_BE_FILLED
        )
      );
    }

    return errors;
  }

  validate(
    title: string,
    location: {
      value: string;
      pattern: string;
    },
    queryParameters: {
      keyValue: string;
      pattern: string;
    }[]
  ) {
    let errors: ErrorMessage[] = [];
    errors.push(...MeasurementUrlSettingValidator.validateTitle(title));
    errors.push(...MeasurementUrlSettingValidator.validateLocation(location));
    errors.push(
      ...MeasurementUrlSettingValidator.validateQueryParameters(queryParameters)
    );
    errors.push(
      ...MeasurementUrlSettingValidator.validateComposite(
        location,
        queryParameters
      )
    );
    return errors;
  }
}
