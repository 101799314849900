import { parse, Node } from "node-html-parser";

import { PresetContentsFormParameters } from "interfaces/view/actionCreation";
import { createLayoutHtml } from "utils/action_creation/CreateLayoutHtml";
import { StyleHtmlCodec } from "utils/action_creation/CreateStyleHtml";
import { getCustomFormatPattern } from "utils/action_creation/CustomContentsPatterns";
import { createPresetLayoutHtml } from "utils/action_creation/CreatePresetLayoutHtml";
import { getPresetFormPattern } from "utils/action_creation/PresetContentsPatterns";
import { HTML_PLACE_HOLDERS } from "./useActionCreationService";
import {
  ACTION_CREATION_MODE,
  CustomContentsFormParameters,
  CustomContentsFormat,
  ActionCreationContentsStyleParameters,
  ActionContentsParameters,
  ContentsType,
  PositionType,
  PresetContentsFormat,
  ActionCreationContentsPCStyleParameters,
} from "interfaces/model/actionContentsParameters";

const encodeHtmlValue = (value: string): string => {
  const replaceList: { [key: string]: string } = {
    "&": "&amp;",
    "'": "&#x27;",
    "`": "&#x60;",
    '"': "&quot;",
    "<": "&lt;",
    ">": "&gt;",
    "\n": "<br/>",
  };

  return value.replace(/[&'`"<>\n]/g, (match) => replaceList[match] || match);
};

class LayoutHtmlCodec {
  encode(
    p:
      | {
          mode: typeof ACTION_CREATION_MODE.CUSTOM;
          customFormatPattern: CustomContentsFormParameters;
          format: CustomContentsFormat;
          styles: ActionCreationContentsStyleParameters;
        }
      | {
          mode: typeof ACTION_CREATION_MODE.PRESET;
          presetFormatPattern: PresetContentsFormParameters;
          styles: ActionCreationContentsStyleParameters;
        }
  ): string {
    const values = {
      subHeadlineText: encodeHtmlValue(p.styles.subHeadlineText),
      headlineText: encodeHtmlValue(p.styles.headlineText),
      text: encodeHtmlValue(p.styles.text),
      buttonText: encodeHtmlValue(p.styles.buttonText),
      buttonLinkText: encodeHtmlValue(p.styles.buttonLinkText),
      isOpenOtherWindow: p.styles.isOpenOtherWindow,
      imageUrl: encodeHtmlValue(p.styles.imageUrl),
      imageLinkText: encodeHtmlValue(p.styles.imageLinkText),
      couponCodeText: encodeHtmlValue(p.styles.couponCodeText),
      couponButtonText: encodeHtmlValue(p.styles.couponButtonText),
      closeButtonSize: p.styles.closeButtonSize,
    };
    const imageUrl = HTML_PLACE_HOLDERS.IMAGE_URL;
    switch (p.mode) {
      case ACTION_CREATION_MODE.CUSTOM:
        return createLayoutHtml(
          p.customFormatPattern,
          p.format,
          values,
          imageUrl
        );
      case ACTION_CREATION_MODE.PRESET:
        return createPresetLayoutHtml(p.presetFormatPattern, values, imageUrl);
    }
  }
  decode(nodes: Node[]) {}
}

class CustomCodec {
  encode(
    mode: typeof ACTION_CREATION_MODE.CUSTOM,
    contents: {
      contentsType: ContentsType;
      positionType: PositionType;
      format: CustomContentsFormat;
      pattern: number;
      isEnablePCStyle: boolean;
    },
    styles: ActionCreationContentsStyleParameters,
    pcStyles: ActionCreationContentsPCStyleParameters
  ) {
    const customFormatPattern = getCustomFormatPattern(
      contents.format,
      contents.pattern
    );
    if (!customFormatPattern) {
      return null;
    }

    const styleHtml = new StyleHtmlCodec().encode(
      customFormatPattern.parts,
      {
        contentsType: contents.contentsType,
        positionType: contents.positionType,
        format: contents.format,
        isEnablePCStyle: contents.isEnablePCStyle,
      },
      styles,
      pcStyles
    );

    const layoutHtml = new LayoutHtmlCodec().encode({
      mode,
      customFormatPattern,
      format: contents.format,
      styles,
    });

    return styleHtml + "\n" + layoutHtml;
  }
  decode(html: string): ActionContentsParameters | null {
    const parsed = parse(html);
    if (!parse) {
      return null;
    }

    // const styleHtml = new StyleHtmlCodec().decode(parsed.childNodes[0]);
    new LayoutHtmlCodec().decode(parsed.childNodes.slice(1));

    return null;
  }
}

class PresetCodec {
  encode(
    mode: typeof ACTION_CREATION_MODE.PRESET,
    contents: {
      contentsType: ContentsType;
      positionType: PositionType;
      format: CustomContentsFormat;
      presetFormat?: PresetContentsFormat;
    },
    styles: ActionCreationContentsStyleParameters,
    pcStyles: ActionCreationContentsPCStyleParameters
  ) {
    const presetFormatPattern = getPresetFormPattern(contents.presetFormat);
    if (!presetFormatPattern) {
      return "";
    }

    const styleHtml = new StyleHtmlCodec().encode(
      presetFormatPattern.parts,
      {
        contentsType: contents.contentsType,
        positionType: contents.positionType,
        format: contents.format,
      },
      styles,
      pcStyles
    );

    const layoutHtml = new LayoutHtmlCodec().encode({
      mode,
      presetFormatPattern,
      styles,
    });

    return styleHtml + "\n" + layoutHtml;
  }
}
export class ContentsParameterCodec {
  encodeToHtml(
    actionContentsParameters: ActionContentsParameters
  ): string | null {
    const mode = actionContentsParameters.mode;
    const styles = actionContentsParameters.styles;
    const pcStyles = actionContentsParameters.pcStyles;

    if (mode === undefined) {
      return null;
    }

    const contents = {
      contentsType: actionContentsParameters.contentsType,
      positionType: actionContentsParameters.positionType,
      presetFormat: actionContentsParameters.presetFormat,
      format: actionContentsParameters.customFormat,
      pattern: actionContentsParameters.patternIndex,
      isEnablePCStyle: actionContentsParameters.isEnablePCStyle,
    };

    switch (mode) {
      case ACTION_CREATION_MODE.CUSTOM: {
        return new CustomCodec().encode(
          mode,
          {
            contentsType: contents.contentsType,
            positionType: contents.positionType,
            format: contents.format,
            pattern: contents.pattern,
            isEnablePCStyle: contents.isEnablePCStyle,
          },
          styles,
          pcStyles
        );
      }
      case ACTION_CREATION_MODE.PRESET: {
        return new PresetCodec().encode(
          mode,
          {
            contentsType: contents.contentsType,
            positionType: contents.positionType,
            format: contents.format,
            presetFormat: contents.presetFormat,
          },
          styles,
          pcStyles
        );
      }
    }
  }
  decodeFromHtml(html: string): ActionContentsParameters | null {
    return new CustomCodec().decode(html);
  }
}
