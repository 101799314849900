import styles from "./UnavailableContentsView.module.scss";

const UnavailableContentsView = ({
  boardElement,
}: {
  boardElement: JSX.Element;
}) => {
  return <div className={styles.unavailableContentsView}>{boardElement}</div>;
};

export default UnavailableContentsView;
