import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import GrayBackPanel from "atoms/ezPushShared/accountPageShared/GrayBackPanel";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";

const SubscribeDomainRegisterModal: React.VFC<{
  domain: string;
  clearModal: () => void;
  registerDomain: () => void;
}> = ({ domain, clearModal, registerDomain }) => (
  <ModalBackground>
    <ElementModal
      title={"このドメイン設定を登録します。よろしいですか？"}
      introduction={
        "登録後、変更するにはお問い合わせが必要になりますので、お間違えのないようご記入ください。"
      }
      element={<GrayBackPanel text={domain} />}
      buttons={[
        <TransparentButton text={"いいえ"} onClick={clearModal} />,
        <OrangeButton text={"はい"} onClick={registerDomain} />,
      ]}
    />
  </ModalBackground>
);

export default SubscribeDomainRegisterModal;
