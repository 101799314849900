import styles from "./AccessStatisticsTableColumn.module.scss";

export interface AccessStatisticsTableSettings {
  key: string;
  header: string;
}

const AccessStatisticsTableColumn = (props: AccessStatisticsTableSettings) => {
  const class_ = styles.th_right;
  return <th className={class_}>{props.header}</th>;
};

export default AccessStatisticsTableColumn;
