import styles from "./FormElementView.module.scss";

type FormElementViewProps = {
  nameElement: JSX.Element;
  formElement: JSX.Element;
};

const FormElementView = ({
  nameElement,
  formElement,
}: FormElementViewProps) => {
  return (
    <div className={styles.formElementView}>
      <div className={styles.name}>{nameElement}</div>
      <div>{formElement}</div>
    </div>
  );
};

export default FormElementView;
