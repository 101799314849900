import EventText from "atoms/accountPagesShared/EventText";
import {
  ActionCreationMode,
  ACTION_CREATION_MODE,
} from "interfaces/model/actionContentsParameters";
import {
  ActionCreationPhase,
  ACTION_CREATION_PHASE,
  SettingPhaseCategory,
  SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";
import { assertNever } from "utils/assertions";

type TransitionButtonParameters = {
  title: string;
  callback: () => void;
};

const getPhaseTransitionButtonParameters = (
  mode: ActionCreationMode,
  phase: ActionCreationPhase,
  settingPhaseCategory: SettingPhaseCategory,
  onClickBackButtonAtFormatSelect: () => void,
  changePhase: (phase: ActionCreationPhase) => void,
  changeSettingPhaseCategory: (category: SettingPhaseCategory) => void
): {
  previousButton: TransitionButtonParameters | undefined;
  nextButton: TransitionButtonParameters | undefined;
} => {
  switch (phase) {
    case ACTION_CREATION_PHASE.FORMAT_SELECT:
      return {
        previousButton: {
          title: "＜  作成方法選択へ",
          callback: () => onClickBackButtonAtFormatSelect(),
        },
        nextButton: undefined,
      };
    case ACTION_CREATION_PHASE.SETTINGS_EDIT:
      switch (settingPhaseCategory) {
        case SETTING_PHASE_CATEGORY.VIEW:
          return mode === ACTION_CREATION_MODE.CUSTOM
            ? {
                previousButton: {
                  title: "＜  アクション選択へ",
                  callback: () =>
                    changePhase(ACTION_CREATION_PHASE.FORMAT_SELECT),
                },
                nextButton: {
                  title: "STEP2 条件設定へ  ＞",
                  callback: () =>
                    changeSettingPhaseCategory(
                      SETTING_PHASE_CATEGORY.CONDITION
                    ),
                },
              }
            : mode === ACTION_CREATION_MODE.PRESET
            ? {
                previousButton: {
                  title: "＜  STEP1 条件設定へ",
                  callback: () =>
                    changeSettingPhaseCategory(
                      SETTING_PHASE_CATEGORY.CONDITION
                    ),
                },
                nextButton: undefined,
              }
            : assertNever(mode);
        case SETTING_PHASE_CATEGORY.CONDITION:
          return mode === ACTION_CREATION_MODE.CUSTOM
            ? {
                previousButton: {
                  title: "＜  STEP1 表示設定へ",
                  callback: () =>
                    changeSettingPhaseCategory(SETTING_PHASE_CATEGORY.VIEW),
                },
                nextButton: undefined,
              }
            : mode === ACTION_CREATION_MODE.PRESET
            ? {
                previousButton: {
                  title: "＜  アクション選択へ",
                  callback: () =>
                    changePhase(ACTION_CREATION_PHASE.FORMAT_SELECT),
                },
                nextButton: {
                  title: "STEP2 表示設定へ  ＞",
                  callback: () =>
                    changeSettingPhaseCategory(SETTING_PHASE_CATEGORY.VIEW),
                },
              }
            : assertNever(mode);
        default:
          assertNever(settingPhaseCategory);
          return { previousButton: undefined, nextButton: undefined };
      }
    case ACTION_CREATION_PHASE.MODE_SELECT:
    case ACTION_CREATION_PHASE.COMPLETE:
      return { previousButton: undefined, nextButton: undefined };
    default:
      assertNever(phase);
      return { previousButton: undefined, nextButton: undefined };
  }
};

export const createPhaseTransitionButtons = ({
  mode,
  phase,
  settingPhaseCategory,
  onClickBackButtonAtFormatSelect,
  changePhase,
  changeSettingPhaseCategory,
}: {
  mode: ActionCreationMode;
  phase: ActionCreationPhase;
  settingPhaseCategory: SettingPhaseCategory;
  onClickBackButtonAtFormatSelect: () => void;
  changePhase: (phase: ActionCreationPhase) => void;
  changeSettingPhaseCategory: (category: SettingPhaseCategory) => void;
}): {
  previousButton: JSX.Element | undefined;
  nextButton: JSX.Element | undefined;
} => {
  const { previousButton, nextButton } = getPhaseTransitionButtonParameters(
    mode,
    phase,
    settingPhaseCategory,
    onClickBackButtonAtFormatSelect,
    changePhase,
    changeSettingPhaseCategory
  );
  return {
    previousButton: previousButton ? (
      <EventText
        text={previousButton.title}
        onClick={previousButton.callback}
      />
    ) : (
      <></>
    ),
    nextButton: nextButton ? (
      <EventText text={nextButton.title} onClick={nextButton.callback} />
    ) : (
      <></>
    ),
  };
};

export type CreatePhaseTransitionButtonsProps = {
  mode: ActionCreationMode;
  phase: ActionCreationPhase;
  settingPhaseCategory: SettingPhaseCategory;
  onClickBackButtonAtFormatSelect: () => void;
  changePhase: (phase: ActionCreationPhase) => void;
  changeSettingPhaseCategory: (category: SettingPhaseCategory) => void;
  render: (params: {
    previousButton: JSX.Element | undefined;
    nextButton: JSX.Element | undefined;
  }) => JSX.Element;
};
