import styles from "./SegmentationRuleCreationForm.module.scss";

const SegmentationRuleCreationForm = ({
  mainElements,
  footerElements,
}: {
  mainElements: JSX.Element[];
  footerElements: JSX.Element[];
}) => (
  <form className={styles.form}>
    <div className={styles.main}>
      <div className={styles.layout}>
        {mainElements.map((section, i) => (
          <div key={i} className={styles.item}>
            {section}
          </div>
        ))}
      </div>
    </div>
    <div className={styles.footer}>
      {footerElements.map((e, i) => (
        <div className={styles.button} key={i}>
          {e}
        </div>
      ))}
    </div>
  </form>
);

export default SegmentationRuleCreationForm;
