import styles from "./SegmentationRuleHeader.module.scss";

interface SegmentationRuleHeaderProps {
  headlineElement: JSX.Element;
  createButtonElement: JSX.Element | null;
}

const SegmentationRuleHeader = ({
  headlineElement,
  createButtonElement,
}: SegmentationRuleHeaderProps) => (
  <div className={styles.header}>
    <div className={styles.headerRight}>
      <div className={styles.title}>{headlineElement}</div>
      {createButtonElement ? (
        <div className={styles.createButton}>{createButtonElement}</div>
      ) : null}
    </div>
  </div>
);

export default SegmentationRuleHeader;
