import React, { ButtonHTMLAttributes, useState } from "react";
import DatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";

import TimePickerOpener from "./TimePickerOpener";
import styles from "./TimePickerContainer.module.scss";
import "./TimePicker.scss";

const locale = { ...ja, options: { ...ja.options } } as Locale;

type TimePickerProps = {
  date: Date;
  onChange: (date: Date) => void;
};

const TimePicker = ({ date, onChange }: TimePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const Opener = React.forwardRef(
    (
      props: React.DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      ref: React.Ref<HTMLDivElement>
    ) => {
      return (
        <div ref={ref}>
          <TimePickerOpener
            {...props}
            time={date}
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      );
    }
  );

  const TimePickerContainer = ({
    className,
    children,
  }: {
    className: string;
    children: React.ReactNode[];
  }): React.ReactNode => {
    return (
      <div className={styles.timePickerContainer}>
        <div className={className}>{children[5]}</div>
      </div>
    );
  };

  return (
    <div id="timePicker">
      <DatePicker
        selected={date}
        // Design
        calendarContainer={TimePickerContainer}
        customInput={<Opener />}
        showTimeSelectOnly
        showTimeSelect
        dateFormat="hh:mm"
        // events
        onChange={(date) => {
          if (date && !Array.isArray(date)) {
            onChange(date);
          }
        }}
        open={isOpen}
        onClickOutside={() => setIsOpen(false)}
        // function
        timeIntervals={30}
        locale={locale}
        timeCaption=""
      />
    </div>
  );
};

export default TimePicker;
