type ExpirationPeriodFormProps = {
  buttonElement: JSX.Element;
  formElement: JSX.Element | null;
};

const ToggledExpirationPeriodForm = ({
  buttonElement,
  formElement,
}: ExpirationPeriodFormProps) => {
  return (
    <div>
      <div>{buttonElement}</div>
      <div>{formElement}</div>
    </div>
  );
};

export default ToggledExpirationPeriodForm;
