import React, { useMemo } from "react";

import fontStyles from "fontStyles.module.scss";

import LoadingIcon from "atoms/LoadingIcon";
import PanelListLayout from "templates/ezPush/PanelListLayout";
import Panel from "templates/ezPush/Panel";
import GrantMessageControlPanel from "organisms/ezPush/home/GrantMessageControlPanel";
import SubscribeCountPanel from "organisms/ezPush/home/SubscribeCountPanel";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import { GrantMessageType, GrantMessageV2 } from "types/grant_message";
import {
  SUBSCRIPTION_STATISTICS_LOADING_STATUS,
  SubscriptionStatisticsParameters,
} from "app/hooks/v2/useSubscriptionStatistics";
import PanelHorizontalLayout from "templates/ezPush/PanelHorizontalLayout";
import SpreadLayout from "templates/ezPush/SpreadLayout";
import { GRANT_MESSAGE_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";
import SubscribeRatePanel from "./SubscribeRatePanel";

const getGrantMessagePanelTitle = (
  grantMessageType: GrantMessageType | undefined
) => {
  if (grantMessageType) {
    return `（${GRANT_MESSAGE_TYPE_DISPLAY_WORD[grantMessageType]}）`;
  } else {
    return "";
  }
};

const HomePanels: React.VFC<{
  accountContext: AccountPageContextV2;
  grantMessage: GrantMessageV2 | undefined;
  stats: SubscriptionStatisticsParameters;
  setModalContents: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  loadGrantMessage: () => void;
}> = ({
  accountContext,
  grantMessage,
  stats,
  setModalContents,
  loadGrantMessage,
}) => {
  const titleElement = useMemo(
    () => <span className={fontStyles.title}>HOME</span>,
    []
  );

  const grantUserLastProcessTimeElement = useMemo(() => {
    return stats ? (
      stats.loadingStatus === SUBSCRIPTION_STATISTICS_LOADING_STATUS.LOADED &&
      stats.subscriptionStatistics ? (
        <span>
          {stats.subscriptionStatistics.date.replaceAll("-", "/")} までの集計
        </span>
      ) : (
        <span>-</span>
      )
    ) : (
      <LoadingIcon />
    );
  }, [stats]);

  const grantUserCountElement = useMemo(() => {
    return stats ? (
      stats.loadingStatus === SUBSCRIPTION_STATISTICS_LOADING_STATUS.LOADED &&
      stats.subscriptionStatistics ? (
        <SubscribeCountPanel stats={stats.subscriptionStatistics} />
      ) : (
        <span>集計待ち</span>
      )
    ) : (
      <LoadingIcon />
    );
  }, [stats]);

  const grantRateElement = useMemo(() => {
    return stats ? (
      stats.loadingStatus === SUBSCRIPTION_STATISTICS_LOADING_STATUS.LOADED &&
      stats.subscriptionStatistics ? (
        <SubscribeRatePanel stats={stats.subscriptionStatistics} />
      ) : (
        <span>集計待ち</span>
      )
    ) : (
      <LoadingIcon />
    );
  }, [stats]);

  const grantMessageTitle =
    "許諾メッセージ" +
    getGrantMessagePanelTitle(
      grantMessage?.grantMessageSetting.selected_message_type
    );

  let panelElements: JSX.Element[] = [
    <PanelHorizontalLayout
      elements={[
        <Panel
          titleElement={
            <SpreadLayout
              items={[
                <div className={fontStyles.title}>
                  許諾ユーザー数（全期間）
                </div>,
                <span className={fontStyles.note}>
                  {grantUserLastProcessTimeElement}
                </span>,
              ]}
            />
          }
          itemElements={[grantUserCountElement]}
        />,
        <Panel
          titleElement={
            <SpreadLayout
              items={[
                <div className={fontStyles.title}>許諾率（直近30日間）</div>,
                <span className={fontStyles.note}>
                  {grantUserLastProcessTimeElement}
                </span>,
              ]}
            />
          }
          itemElements={[grantRateElement]}
        />,
      ]}
    />,
    <Panel
      titleElement={<div className={fontStyles.title}>{grantMessageTitle}</div>}
      itemElements={[
        <GrantMessageControlPanel
          managementApi={accountContext.managementApi}
          grantMessage={grantMessage}
          setModalContents={setModalContents}
          loadGrantMessage={loadGrantMessage}
        />,
      ]}
    />,
  ];
  return (
    <PanelListLayout
      titleElement={titleElement}
      panelElements={panelElements}
    />
  );
};

export default HomePanels;
