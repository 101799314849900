import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import { ACTION_STATUS, ActionStatus } from "interfaces/models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { GrantMessage } from "types/grant_message";
import { ActionRepository } from "utils/ActionsRepository";
import RestApi from "utils/RestApi";

const flipActionStatus = (isPublish: boolean) => {
  return isPublish ? ACTION_STATUS.TESTING : ACTION_STATUS.ACTIVE;
};

const isPublishActive = (status: ActionStatus) => {
  return status === ACTION_STATUS.ACTIVE;
};

type HomePageParameters = {
  isPublish: boolean;
};

type HomePageCallbacks = {
  updatePublishStatus: () => void;
  goGrantMessageEdit: () => void;
};

const useGrantMessagePanel = (
  managementApi: RestApi,
  grantMessage: GrantMessage | undefined,
  loadGrantMessage: () => void
): [HomePageParameters, HomePageCallbacks] => {
  const history = useHistory();
  const [isPublish, setIsPublish] = useState<boolean>(
    grantMessage ? isPublishActive(grantMessage.action.status) : false
  );

  useEffect(() => {
    if (grantMessage) {
      setIsPublish(isPublishActive(grantMessage.action.status));
    }
  }, [grantMessage]);

  const updatePublishStatus = useCallback(() => {
    if (grantMessage) {
      const actionRepository = new ActionRepository(managementApi);
      actionRepository
        .updateStatus(
          grantMessage.grantMessage.action_uuid,
          flipActionStatus(isPublish)
        )
        .then(() => {
          loadGrantMessage();
        });
    }
  }, [managementApi, grantMessage, isPublish, loadGrantMessage]);

  const goGrantMessageEdit = useCallback(
    () => history.push(V2_ROUTING_TABLE.ACCOUNT.GRANT_MESSAGE.EDIT),
    [history]
  );

  const parameters = useMemo(() => {
    return { isPublish };
  }, [isPublish]);

  const callbacks = useMemo(() => {
    return {
      updatePublishStatus,
      goGrantMessageEdit,
    };
  }, [updatePublishStatus, goGrantMessageEdit]);

  return [parameters, callbacks];
};

export default useGrantMessagePanel;
