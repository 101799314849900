import {
  Report,
  ReportMetaData,
  ReportMetaDataList,
  ReportPeriodType,
  ReportType,
  REPORT_TYPE,
} from "interfaces/models";
import { useCallback, useEffect, useState } from "react";
import ReportRepository from "utils/ReportRepository";

const useReportDetail = (
  reportPeriodType: ReportPeriodType,
  reportMetaDataList: ReportMetaDataList | undefined,
  reportRepository: ReportRepository,
  reportYearMonth: string | undefined,
  filterReportMetaDataList: (
    reportMetaDataList: ReportMetaDataList | undefined,
    reportPeriodType: ReportPeriodType,
    reportYearMonth: string | undefined,
    reportType: ReportType
  ) => ReportMetaData | null
): Report | undefined => {
  const [report, setReport] = useState<Report>();
  const getReport = useCallback<() => void>(async () => {
    try {
      const whole_report_path: string | undefined = filterReportMetaDataList(
        reportMetaDataList,
        reportPeriodType,
        reportYearMonth,
        REPORT_TYPE.WHOLE
      )?.report_path;
      const action_detail_report_path = filterReportMetaDataList(
        reportMetaDataList,
        reportPeriodType,
        reportYearMonth,
        REPORT_TYPE.ACTION_DETAIL
      )?.report_path;
      if (whole_report_path && action_detail_report_path)
        setReport(
          await reportRepository.get_json(
            whole_report_path,
            action_detail_report_path
          )
        );
    } catch (err) {
      console.error(err);
    }
  }, [
    reportPeriodType,
    reportMetaDataList,
    reportRepository,
    reportYearMonth,
    filterReportMetaDataList,
  ]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  return report;
};

export default useReportDetail;
