import HeadlineText from "atoms/HeadlineText";
import OrangeWideButton from "atoms/ezPushShared/authPageShared/OrangeWideButton";
import AuthPageBaseV2 from "./AuthPageBase";
import AuthFormLayoutV2 from "templates/ezPush/auth/AuthFormLayoutV2";

const SystemErrorPageV2 = ({
  onClickLoginButton,
}: {
  onClickLoginButton: () => void;
}): JSX.Element => {
  const sentence = (
    <div>
      お手数ですが、サポート（support@ez-cx.com）宛にメールでお問い合わせくださいませ
    </div>
  );

  return (
    <AuthPageBaseV2
      formElement={
        <AuthFormLayoutV2
          headline={<HeadlineText text="システムエラーが発生しました" />}
          sentence={sentence}
          button={
            <OrangeWideButton text="ログイン" onClick={onClickLoginButton} />
          }
        />
      }
    />
  );
};

export default SystemErrorPageV2;
