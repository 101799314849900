import {
  PresetContentsFormat,
  PRESET_CONTENTS_FORMAT,
  CREATIVE_PARTS,
} from "interfaces/model/actionContentsParameters";
import { PresetContentsFormParameters } from "interfaces/view/actionCreation";

///// preset form pattern /////

type PresetFormPatterns = {
  [formats in PresetContentsFormat]: PresetContentsFormParameters;
};

const presetFormPatterns: PresetFormPatterns = {
  [PRESET_CONTENTS_FORMAT.LOGIN_FOLLOW]: {
    parts: [
      CREATIVE_PARTS.BASE,
      CREATIVE_PARTS.HEADLINE,
      CREATIVE_PARTS.ARTICLE,
      CREATIVE_PARTS.BUTTON,
    ],
  },
  [PRESET_CONTENTS_FORMAT.PURCHASE_FOLLOW]: { parts: [] },
  [PRESET_CONTENTS_FORMAT.PRODUCT_INTEREST]: { parts: [] },
  [PRESET_CONTENTS_FORMAT.REVISITOR]: { parts: [] },
  [PRESET_CONTENTS_FORMAT.FIRST_VISIT]: { parts: [] },
  [PRESET_CONTENTS_FORMAT.CART_NOTIFICATION]: { parts: [] },
};

export const getPresetFormPattern = (
  format: PresetContentsFormat | undefined
): PresetContentsFormParameters | undefined => {
  if (format === undefined) {
    return undefined;
  }

  try {
    return presetFormPatterns[format];
  } catch (err) {
    console.error("Undefined format selected");
  }
  return undefined;
};
