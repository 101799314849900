import LoadAccount from "app/loaders/LoadAccount";
import LoadPermission from "app/loaders/LoadPermission";
import LoadSession from "app/loaders/LoadSession";

import RestApi from "utils/RestApi";
import { AccountPageContext } from "interfaces/view/accout";

// FIXME: Move cognito dependency code to CognitoAuth
import { CognitoUserSession } from "amazon-cognito-identity-js";

const AccountPageGroupPreprocess = ({
  apiEndpointUrl,
  session,
  adminTargetAccountId,
  loginAccountId,
  onSessionExpired,
  onSignOut,
  onSystemError,
  render,
}: {
  apiEndpointUrl: string;
  session: CognitoUserSession | null;
  adminTargetAccountId?: string;
  loginAccountId: string;
  onSessionExpired: () => JSX.Element;
  onSignOut: () => void;
  onSystemError: () => void;
  render: (parameters: { context: AccountPageContext }) => JSX.Element;
}) => {
  return (
    <LoadSession
      session={session}
      onExpired={onSessionExpired}
      render={({ jwtToken, onSessionExpired }) => {
        const managementApi = new RestApi(
          apiEndpointUrl,
          jwtToken,
          adminTargetAccountId,
          onSessionExpired,
          onSystemError
        );
        return (
          <LoadPermission
            managementApi={managementApi}
            render={(permission) => (
              <LoadAccount
                managementApi={managementApi}
                onSystemError={onSystemError}
                render={(account, accesses, errorMessage) =>
                  render({
                    context: {
                      managementApi: managementApi,
                      permission: permission,
                      account: account,
                      accesses: accesses,
                      errorMessage: errorMessage,
                      loginAccountId: loginAccountId,
                      onSignOut: onSignOut,
                    },
                  })
                }
              />
            )}
          />
        );
      }}
    />
  );
};

export default AccountPageGroupPreprocess;
