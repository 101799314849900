import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import NeutralButton from "atoms/accountPagesShared/NeutralButton";
import {
  ActionCreationPhase,
  ACTION_CREATION_PHASE,
} from "interfaces/view/actionCreation";
import { PageState, PAGE_STATE } from "app/hooks/useActionCreationService";
import { notifyNever } from "utils/assertions";

export const createFooterButtons = ({
  pageState,
  phase,
  callbacks: commonServices,
}: {
  pageState: PageState;
  phase: ActionCreationPhase;
  callbacks: {
    onCanceled: () => void;
    onClickReleaseButton: () => void;
    onClickUnreleaseButton: () => void;
    onClickBackToActionList: () => void;
  };
}): JSX.Element[] => {
  const backButton = (
    <NegativeButton
      text="一覧に戻る"
      onClick={() => commonServices.onClickBackToActionList()}
    />
  );
  const cancelButton = (
    <NegativeButton
      text="キャンセル"
      onClick={() => commonServices.onCanceled()}
    />
  );

  switch (pageState) {
    case PAGE_STATE.INIT:
    case PAGE_STATE.LOADING:
    case PAGE_STATE.LOADING_FAILED:
      return [backButton];
    case PAGE_STATE.EDITABLE:
      switch (phase) {
        case ACTION_CREATION_PHASE.MODE_SELECT:
          return [cancelButton];
        case ACTION_CREATION_PHASE.FORMAT_SELECT:
          return [cancelButton];
        case ACTION_CREATION_PHASE.SETTINGS_EDIT:
          return [
            cancelButton,
            <NeutralButton
              text="一時停止で保存する"
              onClick={() => commonServices.onClickUnreleaseButton()}
            />,
            <PositiveButton
              text="有効で保存する"
              onClick={() => commonServices.onClickReleaseButton()}
            />,
          ];
        case ACTION_CREATION_PHASE.COMPLETE:
          return [backButton];
        default:
          notifyNever(phase);
          return [];
      }
    default:
      notifyNever(pageState);
      return [];
  }
};
