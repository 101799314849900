import CustomFormatSelectButton from "atoms/accountPagesShared/CustomFormatSelectButton";
import CustomFormatSelectPhaseFormMain from "organisms/account/action_creation/format_select/CustomFormatSelectPhaseFormMain";
// TODO: Implement here after comfirmed specifications
// import IconTextIcon from "images/icons/action_creation/format_select/icon_text.svg";
import TextOnlyIcon from "images/icons/action_creation/format_select/text_only.svg";
import TextWithLargeImageIcon from "images/icons/action_creation/format_select/text_with_large_image.svg";
import TextWithSmallImageIcon from "images/icons/action_creation/format_select/text_with_small_image.svg";
import CouponCodeIcon from "images/icons/action_creation/format_select/coupon_code.svg";
import FullImageIcon from "images/icons/action_creation/format_select/full_image.svg";
import {
  CustomContentsFormat,
  CUSTOM_CONTENTS_FORMAT,
} from "interfaces/model/actionContentsParameters";

type CreateFormatSelectPhaseFormMainProps = {
  format: CustomContentsFormat;
  onSelectCustomFormat: (format: CustomContentsFormat) => void;
};

const CreateFormatSelectPhaseFormMain = ({
  format,
  onSelectCustomFormat,
}: CreateFormatSelectPhaseFormMainProps) => {
  const customPatterns = [
    {
      text: "テキストのみ",
      type: CUSTOM_CONTENTS_FORMAT.TEXT_ONLY,
      img: TextOnlyIcon,
    },
    // TODO: Implement here after comfirmed specifications
    // {text: "アイコン＋テキスト", type: CUSTOM_CONTENTS_FORMAT.ICON_TEXT, img: <img src={IconTextIcon} alt=""/>},
    {
      text: "クーポンコード",
      type: CUSTOM_CONTENTS_FORMAT.COUPON_CODE,
      img: CouponCodeIcon,
    },
    {
      text: "画像（小）＋テキスト",
      type: CUSTOM_CONTENTS_FORMAT.TEXT_WITH_SMALL_IMAGE,
      img: TextWithSmallImageIcon,
    },
    {
      text: "画像（大）＋テキスト",
      type: CUSTOM_CONTENTS_FORMAT.TEXT_WITH_LARGE_IMAGE,
      img: TextWithLargeImageIcon,
    },
    {
      text: "画像のみ",
      type: CUSTOM_CONTENTS_FORMAT.FULL_IMAGE,
      img: FullImageIcon,
    },
  ];

  return (
    <CustomFormatSelectPhaseFormMain
      buttons={customPatterns.map((e) => (
        <CustomFormatSelectButton
          onClick={() => onSelectCustomFormat(e.type)}
          text={e.text}
          img={<img src={e.img} alt="" />}
          isActive={e.type === format}
        />
      ))}
    />
  );
};

export default CreateFormatSelectPhaseFormMain;
