import {
  GrantMessageContentsDesignCallbacks,
  GrantMessageContentsDesignParameters,
} from "app/hooks/v2/grantMessage/useGrantMessageContentsDesign";

import fontStyles from "fontStyles.module.scss";

import PullDownForm from "molecules/formParts/PullDownForm";
import ColorPickForm from "molecules/formParts/ColorPickForm";
import MarginForm from "molecules/formParts/MarginForm";
import RangeSliderForm from "molecules/formParts/RangeSliderForm";
import GrayBackTitleForm from "molecules/v2/GrayBackTitleForm";
import UnderBarTitleForm from "molecules/v2/UnderBarTitleForm";
import TextInput from "atoms/TextInput";
import TextArea from "atoms/ezPushShared/accountPageShared/TextArea";
import GradationColorPickForm from "molecules/formParts/GradationColorPickForm";
import ImageUploaderButton from "molecules/v2/ImageUploader";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import MonitoringSlider from "molecules/MonitoringSlider/MonitoringSlider";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";

const FRAME_LINE_WIDTH_CHOICES: OptionType[] = [
  { label: "0px", value: 0 },
  { label: "1px", value: 1 },
  { label: "2px", value: 2 },
  { label: "3px", value: 3 },
  { label: "5px", value: 5 },
  { label: "8px", value: 8 },
];

const GrantMessageEditDesignForm = ({
  parameters,
  callbacks,
}: {
  parameters: GrantMessageContentsDesignParameters;
  callbacks: GrantMessageContentsDesignCallbacks;
}) => (
  <GrayBackTitleForm
    title="メッセージ設定"
    elements={[
      <UnderBarTitleForm
        title="枠デザイン"
        elements={[
          <PullDownForm
            name={<div>枠線</div>}
            value={parameters.frame.lineWidth}
            options={FRAME_LINE_WIDTH_CHOICES}
            onChange={(e) => callbacks.frame.updateLineWidth(e)}
          />,
          <ColorPickForm
            name="枠線色"
            value={parameters.frame.lineColor}
            onChange={(e) => callbacks.frame.updateLineColor(e)}
          />,
          <ColorPickForm
            name="背景色"
            value={parameters.frame.backgroundColor}
            onChange={(e) => callbacks.frame.updateBackgroundColor(e)}
          />,
        ]}
      />,
      <UnderBarTitleForm
        title={
          <HorizontalIconLayout
            elements={[
              <span>ロゴ画像</span>,
              <div className={fontStyles.text}>
                <ToolTip
                  id="imageRestriction"
                  text={
                    <MultipleLineText
                      texts={[
                        "ファイル形式 - PNG, JPG, GIF",
                        "ファイルサイズ - 200KB以下",
                        "推奨アスペクト比 - 1:1",
                      ]}
                    />
                  }
                />
              </div>,
            ]}
          />
        }
        elements={[
          <ImageUploaderButton
            image={parameters.image.file}
            onChange={callbacks.image.updateFile}
          />,
          parameters.image.file ? (
            <MonitoringSlider
              value={parameters.image.magnification}
              onChange={callbacks.image.updateMagnification}
              minValue={0.5}
              maxValue={1.5}
              stepValue={0.05}
              formatCallback={(e) => `x${e.toFixed(2)}`}
            />
          ) : (
            <></>
          ),
        ]}
      />,
      <UnderBarTitleForm
        title="見出し"
        elements={[
          <TextArea
            value={parameters.title.text}
            onChange={(e) => callbacks.title.updateText(e)}
          />,
          <RangeSliderForm
            name={<div>文字サイズ</div>}
            value={parameters.title.fontSize}
            min={10}
            max={30}
            step={2}
            onChange={(e) => callbacks.title.updateFontSize(e)}
            valueTextEditCallback={(e) => `${e}px`}
          />,
          <ColorPickForm
            name="文字色"
            value={parameters.title.fontColor}
            onChange={(e) => callbacks.title.updateFontColor(e)}
          />,
        ]}
      />,
      <UnderBarTitleForm
        title="本文"
        elements={[
          <TextArea
            value={parameters.description.text}
            onChange={(e) => callbacks.description.updateText(e)}
          />,
          <RangeSliderForm
            name={<div>文字サイズ</div>}
            value={parameters.description.fontSize}
            min={10}
            max={30}
            step={2}
            onChange={(e) => callbacks.description.updateFontSize(e)}
            valueTextEditCallback={(e) => `${e}px`}
          />,
          <ColorPickForm
            name="文字色"
            value={parameters.description.fontColor}
            onChange={(e) => callbacks.description.updateFontColor(e)}
          />,
        ]}
      />,
      <UnderBarTitleForm
        title="許可ボタン"
        elements={[
          <TextInput
            value={parameters.button.text}
            onChange={(e) => callbacks.button.updateText(e.target.value)}
            placeholder="通知を受け取る"
          />,
          <RangeSliderForm
            name={<div>文字サイズ</div>}
            value={parameters.button.fontSize}
            min={10}
            max={30}
            step={2}
            onChange={(e) => callbacks.button.updateFontSize(e)}
            valueTextEditCallback={(e) => `${e}px`}
          />,
          <ColorPickForm
            name="文字色"
            value={parameters.button.fontColor}
            onChange={(e) => callbacks.button.updateFontColor(e)}
          />,
          <GradationColorPickForm
            name="背景色"
            values={parameters.button.backgroundColor}
            colorType={parameters.button.colorType}
            onChange={(e) => callbacks.button.updateBackgroundColor(e)}
            onChangeColorType={(e) => callbacks.button.updateColorType(e)}
          />,
          <RangeSliderForm
            name={<div>角丸</div>}
            value={parameters.button.borderRadius}
            min={0}
            max={50}
            step={5}
            onChange={(e) => callbacks.button.updateBorderRadius(e)}
            valueTextEditCallback={(e) => `${e}px`}
          />,
          <MarginForm
            name={<div>余白</div>}
            topBottomOptions={{ min: 0, max: 15, step: 1 }}
            rightLeftOptions={{ min: 0, max: 100, step: 10 }}
            topBottomMarginValue={parameters.button.heightMargin}
            rightLeftMarginValue={parameters.button.widthMargin}
            onChangeTopBottomMargin={(e) =>
              callbacks.button.updateHeightMargin(Number(e))
            }
            onChangeRightLeftMargin={(e) =>
              callbacks.button.updateWidthMargin(Number(e))
            }
          />,
        ]}
      />,
      <UnderBarTitleForm
        title="閉じるボタン"
        elements={[
          <ColorPickForm
            name="文字色"
            value={parameters.closeButton.crossColor}
            onChange={(e) => callbacks.closeButton.updateCrossColor(e)}
          />,
          <ColorPickForm
            name="背景色"
            value={parameters.closeButton.backgroundColor}
            onChange={(e) => callbacks.closeButton.updateBackgroundColor(e)}
          />,
        ]}
      />,
    ]}
  />
);

export default GrantMessageEditDesignForm;
