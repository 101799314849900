import CheckIcon from "images/icons/icon_white_check.svg";

import styles from "./ActiveCheckBox.module.scss";

const ActiveCheckBox = () => {
  return (
    <div className={styles.activeCheckBox}>
      <div className={styles.box} />
      <img className={styles.checkIcon} src={CheckIcon} alt="" />
    </div>
  );
};

export default ActiveCheckBox;
