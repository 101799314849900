import ReactPaginate from "react-paginate";
import PreviousIcon from "images/icons/icon_arrow_left.svg";
import NextIcon from "images/icons/icon_arrow_right.svg";
import styles from "./ReportPageNation.module.scss";

interface ReportPageNationProps {
  selectedPageNationNumber: number;
  setSelectedPageNationNumber: React.Dispatch<React.SetStateAction<number>>;
  lowCountPerPage: number;
  wholeLowCount: number;
}

const ReportPageNation = (props: ReportPageNationProps) => {
  const pageCount = Math.ceil(props.wholeLowCount / props.lowCountPerPage);
  const previousIcon = <img src={PreviousIcon} alt="previous" />;
  const nextIcon = <img src={NextIcon} alt="next" />;
  const displayRange = 9;
  return (
    <div className={styles.paginateArea}>
      <ReactPaginate
        initialPage={0}
        pageCount={pageCount}
        pageRangeDisplayed={displayRange}
        marginPagesDisplayed={0}
        previousLabel={previousIcon}
        nextLabel={nextIcon}
        previousLinkClassName={styles.arrow}
        nextLinkClassName={styles.arrow}
        containerClassName={styles.paginate}
        disabledClassName={styles.disabled}
        activeClassName={styles.activePage}
        pageClassName={styles.page}
        breakLabel={""}
        forcePage={props.selectedPageNationNumber}
        onPageChange={(e) => props.setSelectedPageNationNumber(e.selected)}
      />
    </div>
  );
};

export default ReportPageNation;
