type SegmentationRuleCreationViewProps = {
  headerElement: JSX.Element;
  formElement: JSX.Element;
};

const SegmentationRuleCreationView = ({
  headerElement,
  formElement,
}: SegmentationRuleCreationViewProps) => (
  <div>
    <div>{headerElement}</div>
    <div>{formElement}</div>
  </div>
);

export default SegmentationRuleCreationView;
