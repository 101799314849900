import styles from "./OrangeText.module.scss";
import React from "react";

const OrangeText: React.VFC<{
  text: string;
  onChange: () => void;
  clickable?: boolean;
}> = ({ text, onChange, clickable = true }) => {
  return (
    <span
      className={clickable ? styles.orangeText : styles.orangeText__disable}
      onClick={clickable ? onChange : undefined}
    >
      {text}
    </span>
  );
};

export default OrangeText;
