import NoticeText from "atoms/accountPagesShared/NoticeText";
import {
  SegmentationRuleDescriptionV2,
  UrlDescription,
} from "utils/SegmentationRuleSentence";
import styles from "./SegmentationRuleDescriptionBox.module.scss";

//////////////////
// commons
//////////////////

const createUrlDescription = (urlDescription: UrlDescription) => (
  <div className={styles.urlStyle}>
    {urlDescription.location.length > 0 ? (
      <div className={styles.locations}>
        <div>指定ページ：</div>
        {urlDescription.location.map((e, i) => (
          <div key={i} className={styles.location}>
            {e}
          </div>
        ))}
      </div>
    ) : undefined}
    {urlDescription.parameters.length > 0 ? (
      <div className={styles.parameters}>
        <div>パラメータ：</div>
        {urlDescription?.parameters.map((e, i) => (
          <div key={i} className={styles.parameter}>
            {e}
          </div>
        ))}
      </div>
    ) : undefined}
  </div>
);

const TitleElement = ({ title }: { title: string }) => (
  <div className={styles.title}>{title}</div>
);

const andElement = (
  <div className={styles.separator}>
    <NoticeText text="and" />
  </div>
);

////////////////////////
// current page behavior
////////////////////////

const createCurrentPageBehavior = (
  urlDescription: UrlDescription | null,
  elements: string[]
): JSX.Element | undefined =>
  !urlDescription && elements.length === 0 ? undefined : (
    <div className={styles.currentPageBehavior}>
      {urlDescription ? (
        <div className={styles.url}>{createUrlDescription(urlDescription)}</div>
      ) : undefined}
      {elements.length > 0 ? (
        <div>
          {elements.map((e, i) => (
            <div key={i}>
              {i > 0 ? andElement : undefined}
              {<div className={styles.condition}>{e}</div>}
            </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );

///////////////////
// session behavior
///////////////////

const createSessionBehaviorDescriptions = (
  elements: {
    text: string;
    urlDescription: UrlDescription | null;
  }[]
): JSX.Element | undefined =>
  elements.length > 0 ? (
    <div className={styles.sessionBehaviors}>
      {elements.map((e, i) => (
        <div key={i}>
          {i > 0 ? andElement : undefined}
          <div className={styles.behavior}>
            <div>{e.text}</div>
            {e.urlDescription ? (
              <div className={styles.url}>
                {createUrlDescription(e.urlDescription)}
              </div>
            ) : undefined}
          </div>
        </div>
      ))}
    </div>
  ) : undefined;

///////////////////
// past behavior
///////////////////

const createPastBehaviorDescriptions = (
  elements: {
    text: string;
    urlDescription: UrlDescription | null;
  }[]
): JSX.Element | undefined =>
  elements.length > 0 ? (
    <div className={styles.pastBehavior}>
      {elements.map((e, i) => (
        <div key={i}>
          {i > 0 ? andElement : undefined}
          <div className={styles.behavior}>
            {<div className={styles.condition}>{e.text}</div>}
            {e.urlDescription ? (
              <div className={styles.url}>
                {createUrlDescription(e.urlDescription)}
              </div>
            ) : undefined}
          </div>
        </div>
      ))}
    </div>
  ) : undefined;

//////////////////////////////
// customer attribute
//////////////////////////////

const createCustomerAttributeInfo = (
  typeName: string,
  elements: string[]
): JSX.Element | undefined =>
  elements.length > 0 ? (
    <div className={styles.customerAttributesInfo}>
      <div className={styles.type}>{typeName}</div>
      <div className={styles.attributes}>
        {elements.map((e, i) => (
          <div key={i}>
            {i > 0 ? andElement : undefined}
            {<div className={styles.element}>{e}</div>}
          </div>
        ))}
      </div>
    </div>
  ) : undefined;

//////////////////////////////
// main component
//////////////////////////////

type SegmentationRuleConditionViewProps = {
  titleElement: JSX.Element;
  detailElement: JSX.Element | undefined;
};

const SegmentationRuleConditionView = ({
  titleElement,
  detailElement,
}: SegmentationRuleConditionViewProps) => {
  return (
    <div className={styles.segmentationRuleConditionView}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.description}>{detailElement}</div>
    </div>
  );
};

const createSegmentationRuleDescriptionBox = (
  descriptions: SegmentationRuleDescriptionV2
): JSX.Element => {
  const elements: { title: JSX.Element; detail: JSX.Element | undefined }[] = [
    {
      title: <TitleElement title="現在閲覧しているページでの行動" />,
      detail: createCurrentPageBehavior(
        descriptions.currentPageBehaviorDescription.urlDescription,
        descriptions.currentPageBehaviorDescription.elements
      ),
    },
    {
      title: <TitleElement title="来訪～現在までのセッション内行動" />,
      detail: createSessionBehaviorDescriptions(
        descriptions.sessionBehaviorDescription.elements
      ),
    },
    {
      title: <TitleElement title="過去来訪を含む行動" />,
      detail: createPastBehaviorDescriptions(
        descriptions.pastBehaviorDescription.elements
      ),
    },
    {
      title: <TitleElement title="ユーザー属性情報" />,
      detail: createCustomerAttributeInfo(
        descriptions.customerTypeDescription.typeName,
        descriptions.customerAttributeDescription.elements
      ),
    },
  ];

  const isExistElement =
    elements.filter(
      (e): e is Exclude<typeof e, undefined> => e.detail !== undefined
    ).length > 0;

  return isExistElement ? (
    <div className={styles.segmentationRuleDescriptionBox}>
      {elements.map((e, i) =>
        e.detail ? (
          <SegmentationRuleConditionView
            key={i}
            titleElement={e.title}
            detailElement={e.detail}
          />
        ) : undefined
      )}
    </div>
  ) : (
    <div>全ユーザー</div>
  );
};

export default createSegmentationRuleDescriptionBox;
