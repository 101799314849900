import styles from "./ActionDetailView.module.scss";

const ActionDetailView = ({
  titleElement,
  actionDetailTableElement,
  closeButtonElement,
}: {
  titleElement: JSX.Element;
  actionDetailTableElement: JSX.Element;
  closeButtonElement: JSX.Element;
}) => {
  return (
    <div className={styles.actionDetailView}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.table}>{actionDetailTableElement}</div>
      <div className={styles.closeButton}>{closeButtonElement}</div>
    </div>
  );
};

export default ActionDetailView;
