import styles from "./PositiveButton.module.scss";

interface PositiveButtonProps {
  text: string;
  onClick?: () => void;
}

const PositiveButton = (props: PositiveButtonProps) => {
  return (
    <button
      type="button"
      className={styles.PositiveButton}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default PositiveButton;
