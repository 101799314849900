import React, { useCallback, useRef } from "react";
import { useState } from "react";
import Frame from "react-frame-component";
import parse from "html-react-parser";

import styles from "./PreviewIFrame.module.scss";

interface FrameRef extends Frame {
  node: HTMLIFrameElement;
}

interface PreviewIFrameProps {
  contents: string;
}

const PreviewIFrame = ({ contents }: PreviewIFrameProps) => {
  const [height, setHeight] = useState<number>(677);
  const iframeRef = useRef(null);

  const HEIGHT_MARGIN = 30;

  // FIXME : Cannot be resized for modals with z index
  const handleResize = useCallback((iframe: React.RefObject<FrameRef>) => {
    const html = iframe.current?.node.contentDocument;

    if (html) {
      const body = html.body;

      if (body && body.clientHeight > 0) {
        setHeight(body.clientHeight + HEIGHT_MARGIN);
      }
    }
  }, []);

  return (
    <Frame
      ref={iframeRef}
      height={height}
      className={styles.iframe}
      onLoad={() => handleResize(iframeRef)}
    >
      <div>{parse(contents)}</div>
    </Frame>
  );
};

export default PreviewIFrame;
