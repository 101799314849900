import { useCallback, useEffect, useState } from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import { GrantMessageType, GrantMessageV2 } from "types/grant_message";
import { useGrantMessageV2Callbacks } from "app/hooks/v2/useGrantMessageV2";
import GrantMessageV2Repository from "utils/repositories/GrantMessageV2Repository";

import AccountPageBase from "./AccountPageBase";
import GrantMessageList from "organisms/ezPush/grantMessage/GrantMessageList";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import { GRANT_MESSAGE_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";
import { useHistory } from "react-router-dom";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";

const AskChangeModal: React.VFC<{
  selectedGrantMessageType: GrantMessageType | null;
  closeModal: () => void;
  clickPublishStatus: () => void;
}> = ({ selectedGrantMessageType, closeModal, clickPublishStatus }) => {
  const status = selectedGrantMessageType
    ? GRANT_MESSAGE_TYPE_DISPLAY_WORD[selectedGrantMessageType]
    : "";
  return (
    <ModalBackground>
      <NotificationModal
        title={`${status}に設定します。よろしいですか？`}
        buttons={[
          <TransparentButton onClick={closeModal} text="いいえ" />,
          <OrangeButton onClick={clickPublishStatus} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

const GrantMessageListPage: React.VFC<{
  accountContext: AccountPageContextV2;
  grantMessages: GrantMessageV2[] | undefined;
  grantMessageCallbacks: useGrantMessageV2Callbacks;
}> = ({ accountContext, grantMessages, grantMessageCallbacks }) => {
  const history = useHistory();
  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  const [selectedGrantMessageType, setSelectedGrantMessageType] =
    useState<GrantMessageType | null>(null);

  useEffect(() => {
    if (grantMessages) {
      setSelectedGrantMessageType(
        grantMessages[0].grantMessageSetting.selected_message_type
      );
    }
  }, [grantMessages, setSelectedGrantMessageType]);

  const clickPublishStatus = useCallback(
    (selectedGrantMessageType) => {
      const repo = new GrantMessageV2Repository(accountContext.managementApi);
      repo
        .updateType({
          grant_message_type: selectedGrantMessageType,
        })
        .then(() => {
          grantMessageCallbacks.load();
          history.push(V2_ROUTING_TABLE.ACCOUNT.HOME);
          setModalContents(undefined);
        });
    },
    [accountContext.managementApi, grantMessageCallbacks, history]
  );

  const submitGrantMessageType = useCallback(
    (selectedType: GrantMessageType | null) => {
      if (selectedType) {
        setModalContents(
          <AskChangeModal
            selectedGrantMessageType={selectedType}
            closeModal={() => setModalContents(undefined)}
            clickPublishStatus={() => clickPublishStatus(selectedType)}
          />
        );
      }
    },
    [clickPublishStatus, setModalContents]
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <GrantMessageList
          grantMessages={grantMessages}
          selectedGrantMessageType={selectedGrantMessageType}
          changeGrantMessageType={(grantMessageType) =>
            setSelectedGrantMessageType(grantMessageType)
          }
          submitGrantMessageType={() =>
            submitGrantMessageType(selectedGrantMessageType)
          }
          backToHome={() => history.push(V2_ROUTING_TABLE.ACCOUNT.HOME)}
        />
      }
      modalContents={modalContents}
    />
  );
};

export default GrantMessageListPage;
