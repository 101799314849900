// TODO: デザイン情報も含めてここのファイルに文言系はまとめちゃっていいかもしれない。
// 多言語対応するなら、このへんはテキストだけ固めたファイルにして、factoryパターンで読ませることになるだろうし。

export const SUBSCRIBE_DOMAIN_DESCRIPTION = {
  introduction: [
    "まずはじめに、プッシュ通知の許諾ユーザーを募集する",
    "ウェブサイトのドメインを登録しましょう",
  ],
  note: [
    "※ドメイン名のみを入力してください（例: example.com、sub.example.com）",
    "※先頭の「http://」「https://」、末尾の「/」は入力不要です。",
    "※ドメイン、サブドメインは別のドメインとして扱われます。別のドメインでCVなどの計測を行いたい場合は、登録後にドメインの追加設定を行ってください。",
    "※登録後、変更するにはお問い合わせが必要になりますので、お間違えのないようご記入ください。",
  ],
};

export const SUBSCRIBE_DOMAIN_CONFIRM_DESCRIPTION = {
  title: "",
  introduction: [
    "登録後、変更するにはお問い合わせが必要になりますので、お間違えのないようご記入ください。",
  ],
};

export const DOMAIN_CREATE_MODAL_DESCRIPTION = {
  note: [
    "※許諾ドメイン以外で利用するドメインを入力してください。",
    "※ドメイン名のみを入力してください（例: example.com、sub.example.com）",
    "※先頭の「http://」「https://」、末尾の「/」は入力不要です。",
  ],
};

export const MEASUREMENT_URL_SETTING_DESCRIPTION = {
  subscription: ["許諾メッセージを表示するページを登録します。"],
  conversion: [
    "セグメント設定で指定したり、レポートでのCV計測で使用するページを登録します。",
    "セグメント設定ではCVページを閲覧したかどうかを条件に配信するユーザーを絞り込みたい場合に使います。",
  ],
  segmentation: [
    "セグメント設定で指定するページを登録します。CVページ以外のページで、閲覧したかどうかを条件に配信するユーザーを絞り込みたい場合に使います。",
  ],
};

export const SERVICE_WORKER_DESCRIPTIONS = [
  "※設置場所はルートディレクトリでなくても構いません。",
  "WordPressなどのプラットフォームを使用する場合でも、ドメイン配下であれば動作可能です。",
  "※ファイル名は変更しないでください。正常に動作しなくなります。",
];

export const MEASUREMENT_TAG_DESCRIPTIONS = [
  "※計測対象ページとは、通知許諾メッセージ表示対象ページ、CVページ、セグメント条件に使用するページと、",
  "プッシュ通知クリック時の遷移先ページを全て含みます。",
  "※設置個所は<head>タグ内、<body>タグ内のどちらでも動作します。タグマネジメントツールをお使いいただけると便利です。",
];
