import styles from "./DecorationFormView.module.scss";

type DecorationFormViewProps = {
  elements: JSX.Element[];
  headElement?: JSX.Element;
};

const DecorationFormView = ({
  elements,
  headElement,
}: DecorationFormViewProps) => {
  return (
    <div className={styles.decorationFormView}>
      {headElement ? (
        <div className={styles.header}>{headElement}</div>
      ) : undefined}
      {elements.map((e, i) => (
        <div key={i} className={styles.elem}>
          {e}
        </div>
      ))}
      {/* NOTE: Blank for additional scroll.  */}
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "150px",
        }}
      />
    </div>
  );
};

export default DecorationFormView;
