import TagModalDescription from "atoms/accountPagesShared/TagModalDescription";
import TagModalCopyArea from "atoms/accountPagesShared/TagModalCopyArea";
import ErrorMessage from "atoms/accountPagesShared/ErrorMessage";

import styles from "./TagCopyBody.module.scss";

const TagCopyBody = ({ tagData }: { tagData: string }) => {
  return (
    <div className={styles.tagCopyBody}>
      <div className={styles.description}>
        <TagModalDescription />
      </div>
      <div>
        {tagData === "" ? (
          <ErrorMessage message="タグの読み込みに失敗しました" />
        ) : (
          <TagModalCopyArea tag={tagData} />
        )}
      </div>
    </div>
  );
};

export default TagCopyBody;
