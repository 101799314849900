import { useMemo } from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import useSegmentationRuleListPage from "app/hooks/v2/segmentationRule/home/useSegmentationRuleListPage";

import fontStyles from "fontStyles.module.scss";

import AccountPageBase from "./AccountPageBase";
import SegmentationRuleList from "organisms/ezPush/segmentationRule/list/SegmentationRuleList";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TitleMainLayout from "templates/ezPush/TitleMainLayout";
import GrayOutButton from "atoms/ezPushShared/accountPageShared/GrayOutButton";
import TooltipIcon from "atoms/ezPushShared/accountPageShared/TooltipIcon";

const SegmentationRuleListPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = useSegmentationRuleListPage(
    accountContext.managementApi
  );

  const titleElement = useMemo(
    () => (
      <HorizontalLayout
        elements={[
          <span className={fontStyles.title}>セグメント一覧</span>,
          parameters.urls &&
          parameters.urls.filter((url) => url.type !== "subscription").length >
            0 ? (
            <OrangeButton
              text="新規作成"
              onClick={callbacks.events.createRule}
            />
          ) : (
            <TooltipIcon
              iconElement={<GrayOutButton text={"新規作成"} isActive={false} />}
              comment={
                "コンバージョンページかセグメント指定ページを登録してください"
              }
            />
          ),
        ]}
      />
    ),
    [callbacks.events, parameters.urls]
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <TitleMainLayout
          titleElement={titleElement}
          mainElement={
            <SegmentationRuleList
              rules={parameters.data}
              urls={parameters.urls}
              deleteRule={callbacks.events.deleteRule}
              copyRule={callbacks.events.copyRule}
              editRule={callbacks.events.editRule}
              getConnectedScheduledCampaigns={
                callbacks.events.getConnectedScheduledCampaigns
              }
              getConnectedDraftCampaigns={
                callbacks.events.getConnectedDraftCampaigns
              }
              updateModal={callbacks.updateModalContents}
              clearModal={callbacks.closeModalContents}
            />
          }
        />
      }
      modalContents={parameters.modalContents}
    />
  );
};

export default SegmentationRuleListPage;
