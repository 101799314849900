import { DomainFormValidator } from "app/process/basicConfiguration/domainValidator";
import { DOMAIN_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import { useCallback, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import ErrorMessage from "utils/ErrorMessage";
import RestApi from "utils/RestApi";
import DomainRepository from "utils/repositories/DomainRepository";

type SubscribeDomainRegisterPageParameters = {
  domain: string;
  errors: ErrorMessage[];
};

type SubscribeDomainRegisterPageCallbacks = {
  updateDomain: (domain: string) => void;
  canRequestCreateDomain: () => boolean;
  requestCreateDomain: () => void;
};

const useSubscribeDomainRegisterPage = (
  managementApi: RestApi
): [
  SubscribeDomainRegisterPageParameters,
  SubscribeDomainRegisterPageCallbacks
] => {
  const history = useHistory();

  const isAlreadyRegistered = useCallback(async () => {
    const repository = new DomainRepository(managementApi);
    const domains = await repository.get();
    return domains.items.length > 0;
  }, [managementApi]);

  const [domain, setDomain] = useState<string>("");
  const [errors, setErrors] = useState<ErrorMessage[]>([]);

  const canRequestCreateDomain = useCallback(() => {
    const domainValidator = new DomainFormValidator();
    const errors = domainValidator.run(domain);
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors([]);
      return true;
    }
  }, [domain]);

  const requestCreateDomain = useCallback(async () => {
    const domainValidator = new DomainFormValidator();
    const errors = domainValidator.run(domain);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    const repository = new DomainRepository(managementApi);
    return repository
      .create({
        domain,
        is_subscribe: true,
      })
      .then(() => {
        history.push(V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.HOME);
      })
      .catch((e) => {
        setErrors([
          new ErrorMessage(DOMAIN_ERROR_MESSAGE_LIST.UNEXPECTED_ERROR),
        ]);
      });
  }, [managementApi, domain, history]);

  useLayoutEffect(() => {
    isAlreadyRegistered();
  }, [isAlreadyRegistered]);

  return [
    {
      domain,
      errors,
    },
    {
      updateDomain: setDomain,
      requestCreateDomain: requestCreateDomain,
      canRequestCreateDomain: canRequestCreateDomain,
    },
  ];
};

export default useSubscribeDomainRegisterPage;
