import RestApi from "./RestApi";
import { Accesses } from "interfaces/models";

class AccessesRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(): Promise<Accesses> {
    const monthly = await this.managementApi.get("accesses/monthly");
    const daily = await this.managementApi.get("accesses/daily");
    return {
      monthly: monthly,
      daily: daily,
    };
  }
}

export default AccessesRepository;
