import RestApi from "utils/RestApi";

import {
  DomainConfigurationParameters,
  DomainConfigurationCallbacks,
  useDomainConfigurationParameters,
} from "./useDomainParameters";

import {
  MeasurementUrlSettingCallbacks,
  MeasurementUrlSettingParameters,
  useMeasurementUrlSettingParameters,
} from "./useMeasurementUrlSetting";
import * as useUrlParameters from "./useUrlParameters";
import {
  BasicConfigurationPageEvents,
  useBasicConfigurationPageEvents,
} from "./useBasicConfigurationPageEvents";
import {
  FileConfigurationCallbacks,
  useFileConfiguration,
} from "./useFileConfiguration";

type BasicConfigurationPageParameters = {
  domain: DomainConfigurationParameters;
  urlSetting: MeasurementUrlSettingParameters;
  urlParameters: useUrlParameters.UrlParameters;
};

type BasicConfigurationPageCallbacks = {
  domain: DomainConfigurationCallbacks;
  file: FileConfigurationCallbacks;
  urlSettings: MeasurementUrlSettingCallbacks;
  events: BasicConfigurationPageEvents;
};

export const useBasicConfigrationPageParameters = (
  managementApi: RestApi
): [BasicConfigurationPageParameters, BasicConfigurationPageCallbacks] => {
  const urlParameters = useUrlParameters.useUrlParameters();

  const [domainParameters, domainCallbacks] =
    useDomainConfigurationParameters(managementApi);

  const [fileCallbacks] = useFileConfiguration(managementApi);

  const [urlSettingParameters, urlSettingCallbacks] =
    useMeasurementUrlSettingParameters(managementApi);

  const events = useBasicConfigurationPageEvents(urlParameters);

  return [
    {
      domain: domainParameters,
      urlSetting: urlSettingParameters,
      urlParameters: urlParameters,
    },
    {
      domain: domainCallbacks,
      file: fileCallbacks,
      urlSettings: urlSettingCallbacks,
      events: events,
    },
  ];
};
