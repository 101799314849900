type SystemSettings = {
  adminPath: string;
  apiEndpointUrl: string;
  authInfo: {
    UserPoolId: string;
    ClientId: string;
  };
};

const LoadSystemSettings = ({
  render,
}: {
  render: (props: { systemSettings: SystemSettings }) => JSX.Element;
}) => {
  if (
    !process.env.REACT_APP_COGNITO_USER_POOL_ID ||
    !process.env.REACT_APP_COGNITO_CLIENT_ID
  ) {
    throw new Error("Failed to load environment variable");
  }

  const systemSettings = {
    adminPath: process.env.ADMIN_PAGE_PATH
      ? process.env.ADMIN_PAGE_PATH
      : "/RVXAFGK",
    apiEndpointUrl: process.env.REACT_APP_ENDPOINT_URL as string,
    authInfo: {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
  };

  return render({ systemSettings });
};

export default LoadSystemSettings;
