import PresetFormatSelectButton from "atoms/accountPagesShared/PresetFormatSelectButton";
import PresetFormatSelectPhaseFormMain from "organisms/account/action_creation/format_select/PresetFormatSelectPhaseFormMain";

import { ActionCreationPresetServiceCallbacks } from "utils/action_creation/ActionCreationPresetServiceCallbacks";
import LoginFailure from "images/icons/action_creation/format_select/preset/login_failure.svg";
import { PRESET_CONTENTS_FORMAT } from "interfaces/model/actionContentsParameters";

const CreatePresetFormatSelectButtonText = ({
  texts,
}: {
  texts: string[];
}): JSX.Element => {
  return (
    <>
      {texts.map((e, i) => (
        <div key={i}>{e}</div>
      ))}
    </>
  );
};

type CreatePresetFormatSelectPhaseFormMainProps = {
  services: ActionCreationPresetServiceCallbacks;
};

const CreatePresetFormatSelectPhaseFormMain = ({
  services,
}: CreatePresetFormatSelectPhaseFormMainProps) => {
  const presetPatterns = [
    {
      text: ["購入中ログインに失敗した", "ユーザーへのアクション"],
      type: PRESET_CONTENTS_FORMAT.LOGIN_FOLLOW,
      img: LoginFailure,
    },
    // {text: "購入直前で迷っているユーザーへのアクション", type: PRESET_CONTENTS_FORMAT.PURCHASE_FOLLOW},
    // {text: "気になっている商品があるユーザーへのアクション", type: PRESET_CONTENTS_FORMAT.PRODUCT_INTEREST},
    // {text: "再度来訪したユーザーへのアクション", type: PRESET_CONTENTS_FORMAT.REVISITOR},
    // {text: "初回来訪したユーザーへのアクション", type: PRESET_CONTENTS_FORMAT.FIRST_VISIT},
    // {text: "未購入ユーザーへのアクション", type: PRESET_CONTENTS_FORMAT.CART_NOTIFICATE},
  ];

  return (
    <PresetFormatSelectPhaseFormMain
      buttons={presetPatterns.map((e) => (
        <PresetFormatSelectButton
          onClick={() => services.onSelectPresetFormat(e.type)}
          text={<CreatePresetFormatSelectButtonText texts={e.text} />}
          img={<img src={e.img} alt="" height="47px" />}
        />
      ))}
    />
  );
};

export default CreatePresetFormatSelectPhaseFormMain;
