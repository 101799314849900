import { useEffect, useMemo, useState } from "react";
import { Permission, PermissionType } from "interfaces/models";
import RestApi from "utils/RestApi";
import SessionRepository from "utils/SessionRepository";

type LoadPermissionProps = {
  managementApi: RestApi;
  render: (permission: PermissionType) => JSX.Element;
};

const LoadPermission = ({ managementApi, render }: LoadPermissionProps) => {
  const sessionRepository = useMemo(
    () => new SessionRepository(managementApi),
    [managementApi]
  );
  const [permission, setPermission] = useState<PermissionType>();

  const load = useMemo(
    () => async () => {
      try {
        setPermission(await sessionRepository.get());
      } catch (error) {
        console.error(error);
      }
    },
    [sessionRepository]
  );

  useEffect(() => {
    load();
  }, [load]);

  return render(permission || Permission.GENERAL);
};

export default LoadPermission;
