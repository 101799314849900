import React, { useMemo } from "react";
import {
  DeliveryReportCondition,
  DeliveryReportRequestStatus,
  DeliveryReportResultStatus,
  DeliveryReportStatus,
} from "types/delivery_report";

import styles from "./DeliveryReportStatusTable.module.scss";
import fontStyles from "fontStyles.module.scss";
import reloadIcon from "images/icons/icon_reload.svg";

import OrangeBorderedButton from "atoms/ezPushShared/accountPageShared/OrangeBorderedButton";
import OrangeHeaderTable, {
  OrangeHeaderTableRecord,
} from "atoms/ezPushShared/accountPageShared/OrangeHeaderTable";
import OrangeText from "atoms/ezPushShared/accountPageShared/OrangeText";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import SpreadLayout from "templates/ezPush/SpreadLayout";
import CautionMessage from "atoms/ezPushShared/accountPageShared/CautionMessage";
import Label from "molecules/v2/Label";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import { getDateString, unixTimeToDateTimeSecond } from "utils/format/unixtime";

const generateStatusElement = (
  requestStatus: DeliveryReportRequestStatus,
  resultStatus: DeliveryReportResultStatus | null,
  retry: () => void,
  download: () => void
): JSX.Element => {
  if (requestStatus !== "COMPLETE") {
    return <div>集計中</div>;
  }

  if (resultStatus === "ERROR") {
    return (
      <CautionMessage
        message={<OrangeText text={"エラー"} onChange={retry} />}
      />
    );
  } else if (resultStatus === "SUCCESS") {
    return <OrangeButton text={"CSVダウンロード"} onClick={download} />;
  }

  return <div>-</div>;
};

const generateRecords = (
  statusList: DeliveryReportStatus[],
  download: (requestUuid: string) => void,
  retry: (requestUuid: string, condition: DeliveryReportCondition) => void
): OrangeHeaderTableRecord[] | null => {
  return statusList
    ? statusList.map((status) => {
        return {
          cells: [
            {
              item: unixTimeToDateTimeSecond(status.created_at),
            },
            {
              item: `${getDateString(
                status.conditions.from_timestamp_at
              )} ～ ${getDateString(status.conditions.to_timestamp_at)}`,
            },
            { item: `${status.conditions.range_hours / 24}日` },
            {
              item: generateStatusElement(
                status.request_status,
                status.result_status,
                () => retry(status.request_uuid, status.conditions),
                () => download(status.request_uuid)
              ),
            },
          ],
        };
      })
    : [];
};

const DeliveryReportStatusTable: React.VFC<{
  statusList: DeliveryReportStatus[] | null;
  update: () => void;
  download: (requestUuid: string) => void;
  retry: (requestUuid: string, condition: DeliveryReportCondition) => void;
}> = ({ statusList, update, download, retry }) => {
  const listElement = useMemo(
    () => (
      <OrangeHeaderTable
        headers={[
          "作成日時",
          "レポート対象期間",
          "トラッキング期間",
          <Label
            label="ステータス"
            toolTip={
              <div className={fontStyles.text}>
                <MultipleLineText
                  texts={[
                    "レポートデータの集計状況です。",
                    "集計が完了したらダウンロードボタンが表示されます。",
                    "ステータスが集計中の場合は少し時間をおいて画面を更新してください。",
                  ]}
                />
              </div>
            }
          />,
        ]}
        records={
          statusList !== null
            ? generateRecords(statusList, download, retry)
            : null
        }
        blankMessage="集計したレポートがありません"
      />
    ),
    [statusList, download, retry]
  );

  // NOTE: 外でも使い回すときatom化する
  const updateElement = useMemo(
    () => (
      <OrangeBorderedButton
        text={
          <div className={styles.updateButton}>
            <img src={reloadIcon} alt="" className={styles.icon} />
            <span className={fontStyles.text}>ステータスを更新</span>
          </div>
        }
        onClick={update}
      />
    ),
    [update]
  );

  return (
    <div className={styles.deliveryReportStatusTable}>
      <div className={styles.header}>
        <SpreadLayout
          items={[
            <div>※レポートは最新10件まで表示されます</div>,
            updateElement,
          ]}
        />
      </div>
      <div>{listElement}</div>
    </div>
  );
};

export default DeliveryReportStatusTable;
