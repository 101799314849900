import { UrlPattern } from "interfaces/models";

export class UrlSanitizer {
  static removeAllSpace(word: string) {
    return word.trim().replace(" ", "");
  }
  static run(data: UrlPattern): UrlPattern {
    return {
      location: data.location.map((e) => {
        return {
          pattern: e.pattern,
          value: this.removeAllSpace(e.value),
        };
      }),
      parameters: data.parameters.map((e) => {
        return {
          pattern: e.pattern,
          key: this.removeAllSpace(e.key),
          value: e.value ? this.removeAllSpace(e.value) : "",
        };
      }),
    };
  }
}
