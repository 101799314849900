import { DEFAULT_CONTENTS_STYLE } from "app/system_defaults/action_creation_defaults";
import {
  PresetContentsFormat,
  PRESET_CONTENTS_FORMAT,
} from "interfaces/model/actionContentsParameters";
import { ActionCreationContentsStyleCallbacks } from "interfaces/view/actionCreation";

export const initPresetActionContents = (
  format: PresetContentsFormat,
  stylesCallbacks: ActionCreationContentsStyleCallbacks
) => {
  switch (format) {
    case PRESET_CONTENTS_FORMAT.LOGIN_FOLLOW:
      stylesCallbacks.setHeadlineText("パスワードをお忘れでしょうか？");
      stylesCallbacks.setText(
        "お忘れの場合、ログインに必要な仮パスワードが簡単に発行できます。"
      );
      stylesCallbacks.setButtonText("仮パスワードを発行する");
  }
};

export const initCustomActionContents = (
  stylesCallbacks: ActionCreationContentsStyleCallbacks
) => {
  stylesCallbacks.setHeadlineText(DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT);
  stylesCallbacks.setSubHeadlineText(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.TEXT.TEXT
  );
  stylesCallbacks.setText(DEFAULT_CONTENTS_STYLE.TEXT.TEXT);
  stylesCallbacks.setButtonText(DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.TEXT);
  stylesCallbacks.setButtonLinkText(DEFAULT_CONTENTS_STYLE.BUTTON.LINK_TEXT);
  stylesCallbacks.setCouponCodeText(
    DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.TEXT
  );
  stylesCallbacks.setCouponButtonText(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.TEXT
  );
};
