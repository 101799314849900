import {
  ACTION_STATUS,
  ExecuteTiming,
  PROCESS_HOLIDAY_PATTERN,
} from "interfaces/models";
import {
  ActionCreationPresetParameters,
  ActionCreationSettingsParameters,
  ActionOperationMode,
  ACTION_OPERATION_MODE,
} from "interfaces/view/actionCreation";

export type ActionCreationSettingsError = {
  name?: string;
  segmentationRules?: string;
  locations?: string;
  excludeLocations?: string;
  expirationPeriod?: string;
  frequency?: string;
  terminateReaction?: string;
  cvUrl?: string;
  abRate?: string;
  timeComparison?: string;
  timeProper?: string;
  dayOfWeek?: string;
};

export type ActionCreationPresetParametersError = {
  loginErrorCount?: string;
  loginUrl?: string;
};

export type ErrorMessage = {
  key: string;
  message: string;
};

export class ActionCreationValidations {
  static checkSetting(
    setting: ActionCreationSettingsParameters,
    actionNameList: string[],
    operation: ActionOperationMode,
    selfActionName?: string
  ) {
    let settingsError: ActionCreationSettingsError = {};

    if (!setting.name) {
      settingsError.name = "条件設定－アクション名を入力してください";
    }

    // NOTE: when mode is edit, can save edit target name.
    if (
      actionNameList
        .filter((n) =>
          operation === ACTION_OPERATION_MODE.EDIT ? n !== selfActionName : true
        )
        .includes(setting.name)
    ) {
      settingsError.name =
        "条件設定－すでに登録されているアクション名です。別名でご登録ください。";
    }

    if (setting.cvUrl.length === 0) {
      settingsError.cvUrl = "条件設定－CVページURLを入力してください";
    }

    if (
      setting.isExpirationPeriodEnabled &&
      setting.expirationPeriod &&
      setting.expirationPeriod.from_date > setting.expirationPeriod.to_date
    ) {
      settingsError.expirationPeriod =
        "条件設定－配信スケジュールの終了日時は開始日時以降に設定してください。";
    }

    if (setting.executeTimings) {
      const timeComparison: ExecuteTiming[] = setting.executeTimings.filter(
        (e) => {
          return (
            e.period.from_time.hour * 60 + e.period.from_time.minute >=
            e.period.to_time.hour * 60 + e.period.to_time.minute
          );
        }
      );
      if (timeComparison.length > 0) {
        settingsError.timeComparison =
          "条件設定－配信時間帯の終了時刻は開始時刻以降に設定してください。";
      }
    }

    if (setting.executeTimings) {
      const timeProper: ExecuteTiming[] = setting.executeTimings.filter((e) => {
        return e.period.to_time.hour >= 24 && e.period.to_time.minute > 0;
      });
      if (timeProper.length > 0) {
        settingsError.timeProper =
          "条件設定－配信時間帯の終了時刻は24時00分以前の時刻を設定してください。";
      }
    }

    if (setting.executeTimings) {
      const timeProper: ExecuteTiming[] = setting.executeTimings.filter((e) => {
        return (
          e.process_holiday === PROCESS_HOLIDAY_PATTERN.EXCLUDE &&
          e.days.monday.is_active === false &&
          e.days.tuesday.is_active === false &&
          e.days.wednesday.is_active === false &&
          e.days.thursday.is_active === false &&
          e.days.friday.is_active === false &&
          e.days.saturday.is_active === false &&
          e.days.sunday.is_active === false
        );
      });
      if (timeProper.length > 0) {
        settingsError.dayOfWeek =
          "条件設定－配信時間帯の曜日・祝日を１つ以上選択してください。";
      }
    }

    if (
      setting.segmentationRuleIds.length === 0 &&
      setting.status === ACTION_STATUS.ACTIVE
    ) {
      settingsError.segmentationRules =
        "条件設定－セグメント条件が指定されていません。一時停止で保存するか、セグメント条件を指定してください。";
    }

    return settingsError;
  }

  static check(
    settings: ActionCreationSettingsParameters,
    actionNameList: string[],
    operation: ActionOperationMode,
    selfActionName?: string
  ) {
    return this.checkSetting(
      settings,
      actionNameList,
      operation,
      selfActionName
    );
  }

  static checkPresetSetting(setting: ActionCreationSettingsParameters) {
    let settingsError: ActionCreationSettingsError = {};

    if (!setting.name) {
      settingsError.name = "条件設定－アクション名を入力してください";
    }

    return settingsError;
  }

  static checkPresetParams(params: ActionCreationPresetParameters) {
    let presetError: ActionCreationPresetParametersError = {};

    if (!params.loginErrorCount) {
      presetError.loginErrorCount =
        "条件設定－「ログインに失敗した回数」を入力してください";
    }

    if (!params.loginUrl.location[0].value) {
      presetError.loginUrl =
        "条件設定－「ログインエラーURL」を入力してください";
    }

    return presetError;
  }

  static checkPreset(
    settings: ActionCreationSettingsParameters,
    presets: ActionCreationPresetParameters
  ) {
    const presetSettings = this.checkPresetSetting(settings);
    const presetErrors = this.checkPresetParams(presets);

    return { ...presetSettings, ...presetErrors };
  }
}
