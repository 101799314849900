import styles from "./SessionForm.module.scss";

type SessionFormProps = {
  selectorElement: JSX.Element;
  inputElement?: JSX.Element;
  unitElement?: JSX.Element;
  compareTypeElement?: JSX.Element;
  urlElement?: JSX.Element;
};

const SessionForm = ({
  selectorElement,
  inputElement,
  unitElement,
  compareTypeElement,
  urlElement,
}: SessionFormProps) => (
  <div className={styles.sessionForm}>
    <div className={styles.behavior}>
      <div className={styles.select}>{selectorElement}</div>
      {inputElement ? (
        <div className={styles.input}>{inputElement}</div>
      ) : undefined}
      {unitElement ? (
        <div className={styles.unit}>{unitElement}</div>
      ) : undefined}
      {compareTypeElement ? (
        <div className={styles.compareType}>{compareTypeElement}</div>
      ) : undefined}
    </div>
    {urlElement ? <div className={styles.url}>{urlElement}</div> : undefined}
  </div>
);

export default SessionForm;
