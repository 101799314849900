import React from "react";

import fontStyles from "fontStyles.module.scss";

import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";

import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import PushNotificationTestSettingDescription from "./PushNotificationTestSettingDescription";
import { GrantMessageType } from "types/grant_message";

export const PushNotificationTestSettingModal: React.VFC<{
  subscribeUrl: string | null;
  testRegisterUrl: string | null;
  grantMessageType: GrantMessageType | undefined;
  closeModal: () => void;
}> = ({ subscribeUrl, testRegisterUrl, grantMessageType, closeModal }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="テスト配信の設定"
        description={
          <PushNotificationTestSettingDescription
            subscribeUrl={subscribeUrl}
            testRegisterUrl={testRegisterUrl}
            grantMessageType={grantMessageType}
          />
        }
        buttons={[<TransparentButton text="閉じる" onClick={closeModal} />]}
      />
    </ModalBackground>
  );
};

export const PushNotificationSendingConfirmModal: React.VFC<{
  closeModal: () => void;
  execute: () => void;
}> = ({ closeModal, execute }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="下書き保存してからテスト配信します。よろしいですか？"
        description={
          <MultipleLineText
            texts={[
              "テスト配信設定を行なったデバイスに配信が届くまで数分程度かかる場合があります。",
              " 「はい」を選択後、少々お待ちください。",
              <span className={fontStyles.note}>
                ※テスト配信を行うには、あらかじめ配信したいデバイスやブラウザで配信設定を行う必要があります。設定を行うには「テスト配信の設定」をご確認ください。
              </span>,
            ]}
          />
        }
        buttons={[
          <TransparentButton text="いいえ" onClick={closeModal} />,
          <OrangeButton text="はい" onClick={execute} />,
        ]}
      />
    </ModalBackground>
  );
};
