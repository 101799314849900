import SelectWrap, { OptionType } from "atoms/accountPagesShared/SelectWrap";

type PullDownProps = {
  options: OptionType[];
  value: any;
  onChange: (value: any) => void;
};

const PullDown = ({ options, value, onChange }: PullDownProps) => {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      boxSizing: "border-box",
      fontSize: "10px",
      textAlign: "left",
      backgroundColor: "transparent",
      width: "100%",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    indicatorsContainer: (provided: any) => ({
      margin: 0,
      padding: 0,
    }),
    control: (provided: any) => ({
      ...provided,
      display: "flex",
      justifyContact: "center",
      padding: "0px 5px",
      minHeight: "0",
      height: "20px",
      minWidth: "50px",
      lineHeight: "18px",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#FFF",
      margin: "5px 0px",
      padding: "5px 0px",
      whiteSpace: "nowrap",
    }),
    menuList: (provided: any) => ({
      padding: 0,
      border: 0,
    }),
    option: () => ({
      "&:hover": {
        backgroundColor: "#FFF7F1",
        cursor: "pointer",
      },
      border: "none",
      "text-align": "center",
    }),
    indicatorSeparator: (provided: any) => ({
      width: "5px",
      backgroundColor: "transparent",
    }),
    dropdownIndicator: (provided: any) => ({
      backgroundColor: "transparent",
      margin: 0,
      padding: 0,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      margin: 0,
      paddingLeft: 0,
      paddingRight: 1,
      textOverflow: "clip",
    }),
  };

  return (
    <SelectWrap
      options={options}
      value={options.filter((e) => e.value === value)}
      onChange={(e) => onChange(e.value)}
      customStyles={customStyles}
    />
  );
};

export default PullDown;
