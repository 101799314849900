import SegmentationRuleCreationForm from "organisms/account/SegmentationRuleCreationForm";
import SegmentationRuleCreationFormSection from "organisms/account/segmentation_rule_creation/SegmentationRuleCreationFormSection";

import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";

import FormTitleView from "organisms/account/FormTitleView";
import FormTitle from "atoms/accountPagesShared/FormTitle";
import RequireIcon from "atoms/accountPagesShared/RequireIcon";
import TextInput from "atoms/TextInput";
import HeadlineText from "atoms/HeadlineText";
import SegmentationRuleNameForm from "organisms/account/segmentation_rule_creation/SegmentationRuleNameForm";
import SegmentationRuleSettingBoard from "organisms/account/segmentation_rule_creation/SegmentationRuleSettingBoard";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import EventText from "atoms/accountPagesShared/EventText";
import {
  SegmentationRuleCreationRenderingState,
  SegmentationRuleCreationServiceCallbacks,
} from "app/hooks/useSegmentationRuleCreationService";
import TransitionForm from "organisms/account/segmentation_rule_creation/TransitionForm";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import CreateCurrentPageForm from "app/creators/segmentation_rule_creation/CreateCurrentPageForm";
import createSessionForm from "./CreateSessionPageForm";
import createPastForm from "./CreatePastForm";
import CreateCustomerAttributeForm from "./CreateCustomerAttributeForm";
import AndSeparator from "atoms/accountPagesShared/AndSeparator";

const createSegmentationRuleCreationForms = (
  params: SegmentationRuleCreationRenderingState,
  callbacks: SegmentationRuleCreationServiceCallbacks
): JSX.Element[] => {
  const andSeparator = <AndSeparator />;
  const currentPageForm = (
    <SegmentationRuleSettingBoard
      titleElement={<HeadlineText text="現在閲覧しているページ上での行動" />}
      formElements={[
        <CreateCurrentPageForm
          state={params.app.behavior.currentPageBehaviors}
          initState={params.initialState?.behavior.currentPageBehaviors}
          formCount={params.form.currentPageBehaviorCount}
          callbacks={callbacks.app.behavior.currentPage}
        />,
      ]}
      separatorElement={<NoticeText text="and" />}
      addElementButton={
        <EventText
          text="条件追加"
          onClick={callbacks.app.behavior.currentPage.add}
        />
      }
    />
  );

  const sessionFormElements = createSessionForm(
    params.app.behavior.sessionBehaviors,
    params.initialState?.behavior.sessionBehaviors,
    params.form.sessionBehaviorCount,
    callbacks.app.behavior.session
  );

  const sessionForm = (
    <SegmentationRuleSettingBoard
      titleElement={<HeadlineText text="来訪～現在までのセッション内行動" />}
      formElements={sessionFormElements.map((e, i) => (
        <TransitionForm
          key={i}
          formElement={e}
          deleteButtonElement={
            sessionFormElements.length > 1 ? (
              <DeleteButton
                onClick={() => callbacks.app.behavior.session.delete(i)}
              />
            ) : undefined
          }
        />
      ))}
      separatorElement={<NoticeText text="and" />}
      addElementButton={
        <EventText
          text="条件追加"
          onClick={callbacks.app.behavior.session.add}
        />
      }
    />
  );

  const pastFormElements = createPastForm(
    params.app.behavior.pastBehaviors,
    params.initialState?.behavior.pastBehaviors,
    params.form.pastBehaviorCount,
    callbacks.app.behavior.past
  );

  const pastForm = (
    <SegmentationRuleSettingBoard
      titleElement={<HeadlineText text="過去来訪を含む行動" />}
      formElements={pastFormElements.map((e, i) => (
        <TransitionForm
          key={i}
          formElement={e}
          deleteButtonElement={
            pastFormElements.length > 1 ? (
              <DeleteButton
                onClick={() => callbacks.app.behavior.past.delete(i)}
              />
            ) : undefined
          }
        />
      ))}
      separatorElement={<NoticeText text="and" />}
      addElementButton={
        <EventText text="条件追加" onClick={callbacks.app.behavior.past.add} />
      }
    />
  );

  const forms = [
    currentPageForm,
    andSeparator,
    sessionForm,
    andSeparator,
    pastForm,
  ];

  // NOTE: ユーザー情報を連携しているときだけ表示される
  if (params.customerAttributeHeaders.length > 0) {
    const customerAttributeForm = (
      <SegmentationRuleSettingBoard
        titleElement={<HeadlineText text="ユーザー属性情報" />}
        formElements={[
          <CreateCustomerAttributeForm
            formCount={params.form.customerAttributeCount}
            customerAttributeKeys={params.customerAttributeHeaders}
            state={params.app.customer}
            callbacks={callbacks.app.customerAttribute}
          />,
        ]}
        separatorElement={<NoticeText text="and" />}
        addElementButton={
          <EventText
            text="条件追加"
            onClick={callbacks.app.customerAttribute.customerAttributes.add}
          />
        }
      />
    );

    forms.push(andSeparator);
    forms.push(customerAttributeForm);
  }

  return forms;
};

const CreateSegmentationRuleCreationContentsInput = ({
  params,
  callbacks,
}: {
  params: SegmentationRuleCreationRenderingState;
  callbacks: SegmentationRuleCreationServiceCallbacks;
}) => {
  const sections: JSX.Element[] = [
    <SegmentationRuleNameForm
      titleElement={
        <FormTitleView
          titleElement={<FormTitle name="セグメント名" />}
          subElement={<RequireIcon />}
        />
      }
      formElement={
        <TextInput
          value={params.app.name}
          onChange={(e) => callbacks.app.common.onChangeName(e.target.value)}
        />
      }
    />,
    <SegmentationRuleCreationFormSection
      contents={createSegmentationRuleCreationForms(params, callbacks)}
    />,
  ];

  return (
    <SegmentationRuleCreationForm
      mainElements={sections}
      footerElements={[
        <NegativeButton
          text="キャンセル"
          onClick={() => callbacks.event.onCancel()}
        />,
        <PositiveButton
          text="保存"
          onClick={() => callbacks.event.onSubmit()}
        />,
      ]}
    />
  );
};

export default CreateSegmentationRuleCreationContentsInput;
