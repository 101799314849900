import { CognitoUserPool } from "amazon-cognito-identity-js";
import Modal from "react-modal";
import { BrowserRouter } from "react-router-dom";

import CognitoAuth from "./loaders/CognitoAuth";
import LoadCurrentAuthName from "./loaders/LoadCurrentAuthName";
import LoadSystemSettings from "./loaders/LoadSystemSettings";
import RootRoute from "./routers/RootRoute";
import styles from "./App.module.scss";

const App = () => {
  Modal.setAppElement("#root");

  return (
    <LoadSystemSettings
      render={({ systemSettings }) => (
        <BrowserRouter>
          <LoadCurrentAuthName
            render={(authName, saveAuthName) => (
              <CognitoAuth
                userPool={new CognitoUserPool(systemSettings.authInfo)}
                authName={authName}
                saveAuthName={saveAuthName}
                render={({
                  session,
                  operations,
                  adminTargetAccountId,
                  loginAccountId,
                }) => (
                  <div className={styles.App}>
                    <RootRoute
                      apiEndpointUrl={systemSettings.apiEndpointUrl}
                      session={session}
                      operations={operations}
                      adminTargetAccountId={adminTargetAccountId}
                      loginAccountId={loginAccountId}
                    />
                  </div>
                )}
              />
            )}
          />
        </BrowserRouter>
      )}
    />
  );
};

export default App;
