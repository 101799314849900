import HorizontalSlider from "atoms/accountPagesShared/HorizontalSlider";
import styles from "./MonitoringSlider.module.scss";

type MonitoringSliderProps = {
  value: number;
  minValue: number;
  maxValue: number;
  stepValue: number;
  onChange: (value: number) => void;
  formatCallback?: (value: number) => string;
};

const MonitoringSlider = ({
  value,
  minValue,
  maxValue,
  stepValue,
  onChange,
  formatCallback,
}: MonitoringSliderProps) => {
  return (
    <div className={styles.horizontalSlider}>
      <div className={styles.slider}>
        <HorizontalSlider
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          stepValue={stepValue}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className={styles.monitor}>
        {formatCallback ? formatCallback(value) : value}
      </div>
    </div>
  );
};

export default MonitoringSlider;
