import OrangeButton from "atoms/ezPushShared/OrangeButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";

const MeasurementUrlSettingExcludeLimitModal: React.VFC<{
  gobackListPage: () => void;
}> = ({ gobackListPage }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="登録数が上限に達しました。"
        buttons={[
          <OrangeButton text="戻る" onClick={() => gobackListPage()} />,
        ]}
      />
    </ModalBackground>
  );
};

export default MeasurementUrlSettingExcludeLimitModal;
