import styles from "./AuthFormLayoutV2.module.scss";

type AuthFormLayoutV2Props = {
  headline: JSX.Element;
  sentence: JSX.Element;
  button: JSX.Element;
};

const AuthFormLayoutV2 = (props: AuthFormLayoutV2Props) => {
  return (
    <div className={styles.authFormLayoutV2}>
      <div className={styles.headline}>{props.headline}</div>
      <div className={styles.sentence}>{props.sentence}</div>
      <div className={styles.button}>{props.button}</div>
    </div>
  );
};

export default AuthFormLayoutV2;
