import NoticeText from "atoms/accountPagesShared/NoticeText";
import styles from "./SegmentationRulesFormView.module.scss";

type SegmentationRulesFormViewProps = {
  forms: JSX.Element[];
  addButton: JSX.Element;
  deleteButton?: JSX.Element;
};

const SegmentationRulesFormView = ({
  forms,
  addButton,
  deleteButton,
}: SegmentationRulesFormViewProps) => {
  return (
    <div className={styles.segmentationRulesFormView}>
      <div className={styles.main}>
        <div className={styles.forms}>
          {forms.map((e, i) => {
            return (
              <div key={i} className={styles.elem}>
                {i !== 0 ? (
                  <div className={styles.andText}>
                    <NoticeText text="or" />
                  </div>
                ) : null}
                <div className={styles.form}>{e}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.delete}>{deleteButton}</div>
      </div>
      <div>{addButton}</div>
    </div>
  );
};

export default SegmentationRulesFormView;
