import React, { useState } from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import useSubscribeDomainRegisterPage from "app/hooks/v2/subscribeDomainRegister/useSubscribeDomainRegisterPage";

import AccountPageBase from "./AccountPageBase";
import SubscribeDomainPanel from "organisms/ezPush/initialize/SubscribeDomainPanel";

const SubscribeDomainRegisterPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [modalContents, setModalContents] = useState<JSX.Element>();
  const [parameters, callbacks] = useSubscribeDomainRegisterPage(
    accountContext.managementApi
  );
  return (
    <AccountPageBase
      context={accountContext}
      modalContents={modalContents}
      areaContents={
        <SubscribeDomainPanel
          domain={parameters.domain}
          errors={parameters.errors}
          updateDomain={callbacks.updateDomain}
          canRequestCreateDomain={callbacks.canRequestCreateDomain}
          requestCreateDomain={callbacks.requestCreateDomain}
          setModalContents={(modalContents) => setModalContents(modalContents)}
        />
      }
      isSideBar={false}
    />
  );
};

export default SubscribeDomainRegisterPage;
