import UrlDownloader from "./download/downloadUrl";

export const ASYNC_PROCESS_STATUS = {
  QUEUED: "QUEUED",
  RUNNING: "RUNNING",
  SUCCEEDED: "SUCCEEDED",
  CANCELLED: "CANCELLED",
} as const;

export type AsyncProcessStatus =
  typeof ASYNC_PROCESS_STATUS[keyof typeof ASYNC_PROCESS_STATUS];

const getUrl = (path: string, params?: { [key: string]: string }) => {
  if (params) {
    const payload = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    return `${path}?${payload}`;
  } else {
    return path;
  }
};

type RestApiResponse = { [key: string]: any };

export default class RestApi {
  endpointUrl: string;
  jwtToken: string;
  adminTargetAccountId: string | undefined;
  abortController: AbortController;
  onUnauthorized: () => void;
  onSystemError: () => void;

  constructor(
    endpointUrl: string,
    jwtToken: string,
    adminTargetAccountId: string | undefined,
    onUnauthorized: () => void,
    onSystemError: () => void
  ) {
    this.endpointUrl = endpointUrl;
    this.jwtToken = jwtToken;
    this.adminTargetAccountId = adminTargetAccountId;
    this.abortController = new AbortController();
    this.onUnauthorized = onUnauthorized;
    this.onSystemError = onSystemError;
  }

  abort() {
    this.abortController.abort();
  }

  private get_header(): { [key: string]: string } {
    const header: { [key: string]: string } = {
      Authorization: this.jwtToken,
    };

    if (this.adminTargetAccountId) {
      header["X-EZCX-ADMIN-TARGET-ACCOUNT-ID"] = this.adminTargetAccountId;
    }

    return header;
  }

  private async parseResponse(
    response: Response
  ): Promise<RestApiResponse | null> {
    if (response.ok) {
      const body = await response.text();

      if (!body.length) {
        return null;
      }

      try {
        return JSON.parse(body);
      } catch (e) {
        throw new Error(`response '${body}' is not json format`);
      }
    } else {
      if (response.status === 401) {
        this.onUnauthorized();
      }

      const body = await response.text();
      try {
        const error = JSON.parse(body);
        console.error(
          `Failed to load with state ${response.status}: ${error.message}`
        );
        this.onSystemError();
      } catch (e) {
        console.error(`Failed to load with state ${response.status}: ${body}`);
        this.onSystemError();
      } finally {
        return null;
      }
    }
  }

  private async request(
    url: string,
    methodType: string,
    params?: { [key: string]: any }
  ) {
    try {
      const response = await fetch(url, {
        method: methodType,
        headers: this.get_header(),
        body: params ? JSON.stringify(params) : null,
        signal: this.abortController.signal,
      });

      return this.parseResponse(response);
    } catch (error) {
      console.error(error);
      this.onSystemError();
      this.abortController.abort();
    }
  }

  private async nonBodyRequest(url: string, methodType: string) {
    try {
      const response = await fetch(url, {
        method: methodType,
        headers: this.get_header(),
        signal: this.abortController.signal,
      });

      return this.parseResponse(response);
    } catch (error) {
      console.error(error);
      this.abortController.abort();
      this.onSystemError();
    }
  }

  async get(path: string, params?: { [key: string]: string }): Promise<any> {
    const resource = new URL(path, this.endpointUrl).toString();

    return this.nonBodyRequest(getUrl(resource, params), "GET");
  }

  async post(path: string, params?: { [key: string]: any }): Promise<any> {
    const resource = new URL(path, this.endpointUrl).toString();

    return this.request(resource, "POST", params);
  }

  async delete(path: string): Promise<any> {
    const resource = new URL(path, this.endpointUrl).toString();

    return this.nonBodyRequest(resource, "delete");
  }

  async put(path: string, params: { [key: string]: any }): Promise<any> {
    const resource = new URL(path, this.endpointUrl).toString();

    return this.request(resource, "put", params);
  }

  async patch(path: string, params: { [key: string]: any }): Promise<any> {
    const resource = new URL(path, this.endpointUrl).toString();

    return this.request(resource, "PATCH", params);
  }

  async download(path: string, params?: { [key: string]: any }) {
    const resource = new URL(path, this.endpointUrl).toString();
    const response = await fetch(resource, {
      method: "GET",
      headers: this.get_header(),
      body: params ? JSON.stringify(params) : null,
      signal: this.abortController.signal,
    });

    if (response.ok) {
      const disposition = response.headers.get("content-disposition");
      if (disposition && disposition.indexOf("attachment") !== -1) {
        const getFileName = (text: string) => {
          return text.split(" ")[1].split("=")[1].replaceAll('"', "");
        };

        const getDownloadUrl = (responseBlob: Blob) => {
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const blob = new Blob([bom, responseBlob], { type: "text/csv" });
          return URL.createObjectURL(blob);
        };

        new UrlDownloader().contentsDisposition(
          getFileName(disposition),
          getDownloadUrl(await response.blob())
        );
      }
    }
  }
}
