import styles from "./FormTitle.module.scss";

const FormTitle = ({
  name,
  additional,
}: {
  name: string;
  additional?: JSX.Element;
}) => {
  return (
    <div className={styles.formTitle}>
      <div className={styles.title}>{name}</div>
      {additional ? (
        <div className={styles.additional}>{additional}</div>
      ) : undefined}
    </div>
  );
};

export default FormTitle;
