import { useEffect, useState, useCallback } from "react";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";

import { SegmentationRule } from "interfaces/models";

const LoadSegmentationRules = ({
  segmentationRuleRepository,
  onError,
  render,
}: {
  segmentationRuleRepository: SegmentationRuleRepository;
  onError: (message: string) => void;
  render: (param: {
    segmentationRules: SegmentationRule[] | null;
    reload: () => void;
  }) => JSX.Element;
}) => {
  const [segmentationRules, setSegmentationRules] = useState<
    SegmentationRule[] | null
  >(null);

  const load = useCallback<() => Promise<void>>(async () => {
    try {
      setSegmentationRules(await segmentationRuleRepository.load());
    } catch (error) {
      onError(error.message);
    }
  }, [segmentationRuleRepository, onError]);

  useEffect(() => {
    load();
  }, [load]);

  return render({
    segmentationRules: segmentationRules,
    reload: () => load(),
  });
};

export default LoadSegmentationRules;
