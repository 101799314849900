import { Route, Switch } from "react-router-dom";

// Components
import ActionPage from "pages/account/ActionPage";
import ActionCreationPage from "pages/account/ActionCreationPage";

// Utils
import { ActionRepository } from "utils/ActionsRepository";
import { AccountPageContext } from "interfaces/view/accout";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";

const ActionPagesRouting = ({ context }: { context: AccountPageContext }) => {
  const actionRepository = new ActionRepository(context.managementApi);
  const segmentationRuleRepository = new SegmentationRuleRepository(
    context.managementApi
  );

  return (
    <Switch>
      <Route path="/actions" exact>
        <ActionPage context={context} actionRepository={actionRepository} />
      </Route>
      <Route path="/actions/creation">
        <ActionCreationPage
          context={context}
          actionRepository={actionRepository}
          segmentationRuleRepository={segmentationRuleRepository}
        />
      </Route>
    </Switch>
  );
};

export default ActionPagesRouting;
