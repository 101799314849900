const DatetimeView = (props: { value: number }) => {
  const date = new Date(props.value * 1000);

  return (
    <div>
      {date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2)}
    </div>
  );
};

export default DatetimeView;
