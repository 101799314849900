import styles from "./ActionTableHeaderColumn.module.scss";

export interface ActionTableSettings {
  key: string;
  header: string;
  align: string;
}

const ActionTableHeaderColumn = (props: ActionTableSettings) => {
  const clazz = props.align === "right" ? styles.th_right : styles.th;

  return <th className={clazz}>{props.header}</th>;
};

export default ActionTableHeaderColumn;
