import Modal from "react-modal";
import styles from "./NotificateModal.module.scss";

const NotificateModal = ({
  Contents,
  isOpen,
  onRequestClose,
}: {
  Contents?: JSX.Element;
  isOpen: boolean;
  onRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void;
}) => {
  const modalStyle: Modal.Styles = {
    overlay: {
      position: "fixed",
      backgroundColor: "rgba(102, 102, 102, 0.3)",
    },
  };

  return (
    <Modal
      style={modalStyle}
      className={styles.modalContainer}
      overlayClassName={styles.modalOverlay}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {Contents || null}
    </Modal>
  );
};

export default NotificateModal;
