import RangeSlider from "molecules/RangeSlider/RangeSlider";
import RightLeftIcon from "images/icons/action_creation/contents_edit/icon_space_w.svg";
import TopBottomIcon from "images/icons/action_creation/contents_edit/icon_space_h.svg";

import styles from "./MarginForm.module.scss";

type MarginFormOption = {
  min: number;
  max: number;
  step: number;
};

type MarginFormProps = {
  name: JSX.Element;
  topBottomOptions: MarginFormOption;
  rightLeftOptions: MarginFormOption;
  topBottomMarginValue: any;
  rightLeftMarginValue: any;
  onChangeTopBottomMargin: (value: any) => void;
  onChangeRightLeftMargin: (value: any) => void;
};

const MarginForm = ({
  name,
  topBottomOptions,
  rightLeftOptions,
  topBottomMarginValue,
  rightLeftMarginValue,
  onChangeTopBottomMargin,
  onChangeRightLeftMargin,
}: MarginFormProps) => {
  return (
    <div className={styles.marginForm}>
      <div className={styles.title}>{name}</div>
      <div className={styles.forms}>
        <div className={styles.form}>
          <div className={styles.icon}>
            <img src={RightLeftIcon} alt="right_left" />
          </div>
          <div>
            <RangeSlider
              value={rightLeftMarginValue}
              maxValue={rightLeftOptions.max}
              minValue={rightLeftOptions.min}
              stepValue={rightLeftOptions.step}
              onChange={(e) => onChangeRightLeftMargin(e)}
              valueTextEditCallback={(e) => `${e}px`}
            />
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.icon}>
            <img src={TopBottomIcon} alt="top_bottom" />
          </div>
          <div>
            <RangeSlider
              value={topBottomMarginValue}
              maxValue={topBottomOptions.max}
              minValue={topBottomOptions.min}
              stepValue={topBottomOptions.step}
              onChange={(e) => onChangeTopBottomMargin(e)}
              valueTextEditCallback={(e) => `${e}px`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarginForm;
