import TimePickerSelectBox from "atoms/accountPagesShared/TimePickerSelectBox";
import {
  ExecuteTiming,
  LIST_FROM_HOURS,
  LIST_MINUTES,
  LIST_TO_HOURS,
} from "interfaces/models";
import styles from "./PeriodForm.module.scss";

const HOUR_LIST_HAS_NODATA_MESSAGE: string = "期間指定開始時間が存在しません";
const MINUTE_LIST_HAS_NODATA_MESSAGE: string = "期間指定開始時刻が存在しません";

type PeriodFormType = {
  index: number;
  executeTiming: ExecuteTiming;
  onChangeSetExecuteTimingFromHourValue: (index: number, value: number) => void;
  onChangeSetExecuteTimingFromTimeValue: (index: number, value: number) => void;
  onChangeSetExecuteTimingToHourValue: (index: number, value: number) => void;
  onChangeSetExecuteTimingToTimeValue: (index: number, value: number) => void;
};

const timePickerSelectBoxElement = (
  index: number,
  hourTimeList: {
    label: string;
    value: number;
  }[],
  hourTimeValue: number,
  noDataMessage: string,
  onChange: (index: number, selectedValue: number) => void
) => {
  return (
    <div className={styles.picker}>
      <TimePickerSelectBox
        options={hourTimeList}
        value={hourTimeValue}
        onChange={(e) => {
          onChange(index, e);
        }}
        noOptionsMessage={noDataMessage}
      />
    </div>
  );
};

const PeriodForm = ({
  index,
  executeTiming,
  onChangeSetExecuteTimingFromHourValue,
  onChangeSetExecuteTimingFromTimeValue,
  onChangeSetExecuteTimingToHourValue,
  onChangeSetExecuteTimingToTimeValue,
}: PeriodFormType) => {
  return (
    <div key={index} className={styles.period}>
      {timePickerSelectBoxElement(
        index,
        LIST_FROM_HOURS,
        executeTiming.period.from_time.hour,
        HOUR_LIST_HAS_NODATA_MESSAGE,
        onChangeSetExecuteTimingFromHourValue
      )}
      <div className={styles.text}>:</div>
      {timePickerSelectBoxElement(
        index,
        LIST_MINUTES,
        executeTiming.period.from_time.minute,
        MINUTE_LIST_HAS_NODATA_MESSAGE,
        onChangeSetExecuteTimingFromTimeValue
      )}
      <div className={styles.text}>～</div>
      {timePickerSelectBoxElement(
        index,
        LIST_TO_HOURS,
        executeTiming.period.to_time.hour,
        HOUR_LIST_HAS_NODATA_MESSAGE,
        onChangeSetExecuteTimingToHourValue
      )}
      <div className={styles.text}>:</div>
      <div className={styles.picker}>
        {timePickerSelectBoxElement(
          index,
          LIST_MINUTES,
          executeTiming.period.to_time.minute,
          MINUTE_LIST_HAS_NODATA_MESSAGE,
          onChangeSetExecuteTimingToTimeValue
        )}
      </div>
    </div>
  );
};

export default PeriodForm;
