import TextInput from "atoms/TextInput";
import CheckBox from "molecules/CheckBox/CheckBox";

import styles from "./LoginErrorCountForm.module.scss";

type LoginErrorCountFormProps = {
  loginErrorCount: number;
  loginErrorCondition: boolean;
  onChange: (value: string) => void;
  onChangeLoginErrorCondition: () => void;
};

const LoginErrorCountForm = ({
  loginErrorCount,
  loginErrorCondition,
  onChange,
  onChangeLoginErrorCondition,
}: LoginErrorCountFormProps) => {
  return (
    <div className={styles.loginErrorCountForm}>
      <div className={styles.count}>
        <div className={styles.form}>
          <TextInput
            value={loginErrorCount}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
        回ログインに失敗したら表示する
      </div>
      <div className={styles.isErrorPage}>
        <CheckBox
          text="エラーページがなく、ログインページが連続で表示される"
          value={loginErrorCondition}
          onChange={() => onChangeLoginErrorCondition()}
        />
      </div>
    </div>
  );
};

export default LoginErrorCountForm;
