import styles from "./FormTitleView.module.scss";

type FormTitleViewProps = {
  titleElement: JSX.Element;
  subElement?: JSX.Element;
};

const FormTitleView = ({ titleElement, subElement }: FormTitleViewProps) => {
  return (
    <div className={styles.formTitle}>
      <div className={styles.title}>{titleElement}</div>
      <div>{subElement}</div>
    </div>
  );
};

export default FormTitleView;
