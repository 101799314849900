import { SegmentationRule } from "interfaces/models";
import DetailModal from "organisms/account/DetailModal";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import HeadlineText from "atoms/HeadlineText";
import LoadingIcon from "atoms/LoadingIcon";
import LoadingView from "organisms/account/LoadingView";
import SegmentationRuleDetailView from "organisms/account/segmentation_rules/SegmentationRuleDetailView";
import CreateSegmentationRuleDetailTable from "./CreateSegmentationRuleDetailTable";
import AssertNever from "atoms/AssertNever";
import { SegmentationRuleDescription } from "interfaces/view/segmentationCreation";

export const SegmentationRulePageModalParameters = {
  detail: "detail",
} as const;

export type SegmentationRulePageModalParametersType =
  typeof SegmentationRulePageModalParameters[keyof typeof SegmentationRulePageModalParameters];

const CreateSegmentationRuleModalElement = ({
  segmentationRule,
  modalMode,
  segmentationRuleDescription,
  onRequestCloseModal,
}: {
  segmentationRule: SegmentationRule;
  modalMode: SegmentationRulePageModalParametersType;
  segmentationRuleDescription: SegmentationRuleDescription;
  onRequestCloseModal: () => void;
}) => {
  switch (modalMode) {
    case SegmentationRulePageModalParameters.detail:
      return segmentationRule ? (
        <DetailModal
          modalContentsElement={
            <SegmentationRuleDetailView
              titleElement={<HeadlineText text="カスタマーセグメント詳細" />}
              SegmentationRuleDetailTableElement={
                <CreateSegmentationRuleDetailTable
                  segmentationRule={segmentationRule}
                />
              }
              closeButtonElement={
                <NegativeButton text="閉じる" onClick={onRequestCloseModal} />
              }
            />
          }
          onRequestCloseModal={onRequestCloseModal}
        />
      ) : (
        <LoadingView
          iconElement={<LoadingIcon />}
          textElement={<div>データ読み込み中．．．</div>}
        />
      );
    default:
      return <AssertNever never={modalMode} />;
  }
};

export default CreateSegmentationRuleModalElement;
