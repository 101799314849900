import { PLEASE_CHOICE } from "app/system_defaults/WordDefaults";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import EventText from "atoms/accountPagesShared/EventText";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";
import { SegmentationRule } from "interfaces/models";
import SegmentationRulesFormView from "organisms/account/action_creation/settings_edit/SegmentationRulesFormView";
import { StringFormatter } from "utils/StringFormatter";

type SegmentationRulesFormProps = {
  selects: string[];
  rules: SegmentationRule[];
  onClickAddButton: () => void;
  onClickDeleteButton: () => void;
  onChangeSegmentationRule: (index: number, segmentationRuleId: string) => void;
};

const SegmentationRulesForm = ({
  selects,
  rules,
  onClickAddButton,
  onClickDeleteButton,
  onChangeSegmentationRule,
}: SegmentationRulesFormProps) => {
  const noLengthList: number[] = rules.map(
    (e) => String(e.segmentation_rule_number).length
  );
  const maxNumberLength: number = Math.max(...noLengthList);

  const options: OptionType[] = rules
    ? rules.map((e) => {
        return {
          value: e.segmentation_rule_id,
          label: `${StringFormatter.fillLeft(
            String(e.segmentation_rule_number),
            maxNumberLength,
            "0"
          )}. ${e.name}`,
        };
      })
    : [];
  const createSelectBox = (value: string, index: number) => (
    <BorderedSelectBox
      options={options}
      value={value}
      onChange={(e) => onChangeSegmentationRule(index, e)}
      noOptionsMessage="セグメント条件が存在しません"
      placeholder={`No. ${PLEASE_CHOICE}`}
    />
  );

  const forms =
    selects.length > 0
      ? selects.map((value, index) => createSelectBox(value, index))
      : [createSelectBox("", 0)];

  return (
    <SegmentationRulesFormView
      forms={forms}
      addButton={<EventText text="追加" onClick={onClickAddButton} />}
      deleteButton={
        selects.length > 1 ? (
          <DeleteButton onClick={onClickDeleteButton} />
        ) : undefined
      }
    />
  );
};

export default SegmentationRulesForm;
