import EventText from "atoms/accountPagesShared/EventText";
import { ColorResult, CustomPicker, InjectedColorProps } from "react-color";
import Hue from "react-color/lib/components/common/Hue";
import Saturation from "react-color/lib/components/common/Saturation";
import styles from "./GradationSliderColorPicker.module.scss";
import EZCXEditableInput from "./EditableInput";
import ColorCircle from "./ColorCircle";

type GradationSliderColorPickerProps = {
  colors: ColorResult[];
  currentColorNumber: number;
  onChangePicker: () => void;
  onClickColorCircle: (index: number) => void;
};

const GradationSliderColorPicker = (
  props: InjectedColorProps & GradationSliderColorPickerProps
) => {
  return (
    <div className={styles.gradientSliderColorPicker}>
      <div className={styles.main}>
        <div className={styles.colorCircles}>
          {props.colors.map((e, i) => (
            <div className={styles.circle}>
              <ColorCircle
                color={e}
                isActive={i === props.currentColorNumber}
                onChange={() => props.onClickColorCircle(i)}
              />
            </div>
          ))}
        </div>
        <div className={styles.sliders}>
          <div className={styles.hue}>
            <Hue
              {...props}
              onChange={(c, e) => (props.onChange ? props.onChange(c) : null)}
              pointer={() => {
                return <div className={styles.slider} />;
              }}
            />
          </div>
          <div className={styles.saturation}>
            <Saturation
              {...props}
              onChange={(c, e) => (props.onChange ? props.onChange(c) : null)}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.editableInput}>
          <EZCXEditableInput
            hex={props.hex}
            onChange={(c) => (props.onChange ? props.onChange(c) : null)}
          />
        </div>
        <div>
          <EventText text="標準色から選ぶ" onClick={props.onChangePicker} />
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(GradationSliderColorPicker);
