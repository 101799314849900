import styles from "./NegativeButton.module.scss";

interface NegativeButtonProps {
  text: string;
  onClick: () => void;
}

const NegativeButton = (props: NegativeButtonProps) => {
  return (
    <button
      type="button"
      className={styles.negativeButton}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default NegativeButton;
