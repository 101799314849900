import { PAGE_CONDITION_COMPARE_TYPE } from "interfaces/v2/segmentationRuleForm";
import { MEASUREMENT_URL_SETTING_TYPE } from "./v2_routing";

export const PLEASE_CHOICE: string = "―選択してください―";

export const LOCATION_COMPARE_TYPE_DISPLAY_WORD = {
  include: "を含む",
  exclude: "を除く",
  prefix: "から始まる",
  complete: "と一致する",
} as const;

export const QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD = {
  include: "を含む",
  exclude: "を除く",
} as const;

export const PAGE_SETTING_TYPE_DISPLAY_WORD = {
  segmentation: "セグメント指定ページ",
  conversion: "コンバージョンページ",
  subscription: "許諾取得ページ",
} as const;

export const COMPARE_CONDITION_OPTIONS_DISPLAY_WORD = {
  [PAGE_CONDITION_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO]: "以下",
  [PAGE_CONDITION_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO]: "以上",
  [PAGE_CONDITION_COMPARE_TYPE.EQUAL_TO]: "に等しい",
} as const;

export const GRANT_MESSAGE_TYPE_DISPLAY_WORD = {
  custom: "カスタムデザイン＋ブラウザ標準デザイン",
  browser_default: "ブラウザ標準デザイン",
};

export const PUSH_NOTIFICATION_SCHEDULE_TYPE_DISPLAY_WORD = {
  ONCE: "一回のみ配信",
  REPEAT: "くりかえし配信",
};

export const MEASUREMENT_URL_TYPE_DISPLAY_WORD = {
  [MEASUREMENT_URL_SETTING_TYPE.SUBSCRIPTION]: "許諾メッセージの表示ページ",
  [MEASUREMENT_URL_SETTING_TYPE.CONVERSION]: "コンバージョンページ",
  [MEASUREMENT_URL_SETTING_TYPE.SEGMENTATION]: "セグメント指定ページ",
};
