import styles from "./ActionDetailReportTable.module.scss";

const ActionDetailReportTable: React.VFC<{
  fixedTable: JSX.Element;
}> = ({ fixedTable }) => {
  return (
    <div className={styles.actionDetailReportTable}>
      <div className={styles.fixed}>{fixedTable}</div>
    </div>
  );
};

export default ActionDetailReportTable;
