import CompletionView from "organisms/account/segmentation_rule_creation/CompletionView";

import NegativeButton from "atoms/accountPagesShared/NegativeButton";

import { SegmentationRule } from "interfaces/models";
import CreateSegmentationRuleDetailTable from "../segmentation_rules/CreateSegmentationRuleDetailTable";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";

const CreateSegmentationRuleCreationContentsComplete = ({
  segmentationRule,
  onComplete,
}: {
  segmentationRule: SegmentationRule | undefined;
  onComplete: () => void;
}) => {
  return segmentationRule ? (
    <CompletionView
      massage="登録完了しました"
      table={
        <CreateSegmentationRuleDetailTable
          segmentationRule={segmentationRule}
        />
      }
      goBackButton={<NegativeButton text="一覧へ戻る" onClick={onComplete} />}
    />
  ) : (
    <LoadingView
      iconElement={<LoadingIcon />}
      textElement={<div>カスタマーセグメントを保存しています。</div>}
    />
  );
};

export default CreateSegmentationRuleCreationContentsComplete;
