import styles from "./SettingsEditPhaseFormMainSideArea.module.scss";

type SettingsEditPhaseFormMainSideAreaProps = {
  buttons: JSX.Element[];
};

const SettingsEditPhaseFormMainSideArea = ({
  buttons,
}: SettingsEditPhaseFormMainSideAreaProps) => {
  return (
    <div className={styles.settingsEditPhaseFormMainSideArea}>
      {buttons.map((e) => e)}
    </div>
  );
};

export default SettingsEditPhaseFormMainSideArea;
