import SelectBoxArrowIcon from "images/icons/icon_select_box_arrow.svg";
import SelectBoxActiveArrowIcon from "images/icons/icon_select_box_active_arrow.svg";
import styles from "./TimePickerOpener.module.scss";

const formatTime = (date: Date) => {
  const _hours = ("0" + date.getHours()).slice(-2);
  const _minutes = ("0" + date.getMinutes()).slice(-2);
  return `${_hours}:${_minutes}`;
};

type TimePickerOpenerProps = {
  time: Date;
  isOpen: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const TimePickerOpener = ({ time, isOpen, onClick }: TimePickerOpenerProps) => {
  return (
    <button className={styles.timePickerOpener} onClick={onClick}>
      <div className={styles.time}>{formatTime(time)}</div>
      <div className={styles.buttonRight}>
        <img
          className={styles.arrow}
          src={isOpen ? SelectBoxActiveArrowIcon : SelectBoxArrowIcon}
          alt=""
        />
      </div>
    </button>
  );
};

export default TimePickerOpener;
