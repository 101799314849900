import styles from "./DownloadButton.module.scss";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";
import downloadIcon from "images/icons/icon_download.svg";

const DownloadButton: React.VFC<{
  text: string;
  fileName: string;
  data: Data;
}> = ({ text, fileName, data }) => {
  return (
    <CSVLink className={styles.downloadButton} data={data} filename={fileName}>
      <img className={styles.icon} src={downloadIcon} alt="" />
      <div className={styles.text}>{text}</div>
    </CSVLink>
  );
};

export default DownloadButton;
