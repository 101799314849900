import errorIcon from "images/icons/icon_error.svg";
import styles from "./ErrorMessage.module.scss";

const ErrorMessage = ({ message }: { message?: string }) => {
  if (message) {
    return (
      <span className={styles.errorMessage}>
        <img className={styles.icon} src={errorIcon} alt="ERROR : " />
        <span className={styles.text}>{message}</span>
      </span>
    );
  }
  return <></>;
};

export default ErrorMessage;
