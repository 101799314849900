import CreateActionDetailTableElement from "./CreateActionDetailTable";

// view components
import ActionDetailView from "organisms/account/action_detail/ActionDetailView";
import LoadingView from "organisms/account/LoadingView";
import ErrorMessage from "atoms/accountPagesShared/ErrorMessage";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import HeadlineText from "atoms/HeadlineText";
import LoadingIcon from "atoms/LoadingIcon";

import { ActionDetail2 } from "utils/ActionsRepository";

type CreateActionDetailElementProps = {
  actionDetail?: ActionDetail2;
  errorMessage?: string;
  onCloseModal: () => void;
};

const CreateActionDetailElement = ({
  actionDetail,
  errorMessage,
  onCloseModal,
}: CreateActionDetailElementProps) => {
  if (errorMessage) {
    return <ErrorMessage message={errorMessage} />;
  }

  if (!actionDetail) {
    return (
      <LoadingView
        iconElement={<LoadingIcon />}
        textElement={<div>データ読み込み中．．．</div>}
      />
    );
  }

  return (
    <CreateActionDetailTableElement
      actionDetail2={actionDetail}
      isDisplayPopUp={true}
      render={(actionDetailTableElement) => (
        <ActionDetailView
          titleElement={<HeadlineText text="おもてなしアクション詳細" />}
          actionDetailTableElement={actionDetailTableElement}
          closeButtonElement={
            <NegativeButton text="閉じる" onClick={onCloseModal} />
          }
        />
      )}
    />
  );
};

export default CreateActionDetailElement;
