import styles from "./ContentsEditPhaseFormMainEditAreaView.module.scss";

type ContentsEditPhaseFormMainEditAreaViewProps = {
  categoryHeaderElements: JSX.Element[];
  htmlInputElement: JSX.Element;
};

const ContentsEditPhaseFormMainEditAreaView = ({
  categoryHeaderElements,
  htmlInputElement,
}: ContentsEditPhaseFormMainEditAreaViewProps) => (
  <div className={styles.contentsEditPhaseFormMainEditArea}>
    <div className={styles.header}>
      {categoryHeaderElements.map((e, i) => (
        <div key={i} className={styles.elem}>
          {e}
        </div>
      ))}
    </div>
    <div className={styles.form}>{htmlInputElement}</div>
  </div>
);

export default ContentsEditPhaseFormMainEditAreaView;
