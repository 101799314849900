import { V2_PARAMETERS } from "app/system_defaults/v2_service";

class TestSettingUrlGenerator {
  getSubscribeUrl(origin: URL, action_uuid: string) {
    const tmp = new URL(origin.toString());
    tmp.searchParams.append(
      V2_PARAMETERS.SUBSCRIBE.FORCE_DISPLAY.QUERY_PARAMETER.KEY,
      action_uuid
    );
    return tmp.toString();
  }
  generateTestRegisterUrl(origin: URL) {
    const tmp = new URL(origin.toString());
    tmp.searchParams.append(
      V2_PARAMETERS.SUBSCRIBE.TEST.QUERY_PARAMETER.KEY,
      V2_PARAMETERS.SUBSCRIBE.TEST.QUERY_PARAMETER.VALUE
    );
    return tmp.toString();
  }
}

export default TestSettingUrlGenerator;
