import { Action } from "interfaces/models";

// FIXME : this func has sanitize and sort, please divide
export const sortActionsWithActionNumber = (actions: Action[]): Action[] => {
  const compare = (a: Action, b: Action) => {
    if (a.details.number < b.details.number) return -1;
    if (a.details.number > b.details.number) return 1;
    return 0;
  };

  return actions
    .filter((action) => {
      return action.details;
    })
    .sort(compare);
};
