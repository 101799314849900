import styles from "./RadioButtonBase.module.scss";

type RadioButtonBaseProps = {
  isActive: boolean;
  onClick: () => void;
  size?: number;
};

const RadioButtonBase = ({
  isActive,
  onClick,
  size = 16,
}: RadioButtonBaseProps) => {
  const innerSize = (size * 3) / 8;
  return (
    <button
      style={{ width: size, height: size }}
      className={isActive ? styles.button__active : styles.button}
      onClick={() => onClick()}
    >
      <div
        style={{ width: innerSize, height: innerSize }}
        className={isActive ? styles.inner__active : styles.inner}
      />
    </button>
  );
};

export default RadioButtonBase;
