import styles from "./ReportItem.module.scss";
import arrowPlusIcon from "images/icons/icon_arrow_plus.svg";
import arrowMinusIcon from "images/icons/icon_arrow_minus.svg";

export const FORMAT_TYPE = {
  NUMBER: "number",
  RATE: "rate",
  IMPROVED_RATE: "improved_rate",
  TEXT: "text",
  INDEX: "index",
} as const;

export type FormatType = typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE];

export type Item = {
  columnId?: string;
  formatType?: FormatType;
  value?: number | string;
  textAlign?: string;
  nestedValues?: Item[];
};

export const reportItems = (cells: Item[]): JSX.Element[] => {
  const createRateItemElement = (value: number | string) => {
    type ImproveRateStyle = {
      icon: JSX.Element;
      style: string;
    };

    const improveRateStyle: ImproveRateStyle =
      value > 1
        ? {
            icon: (
              <img className={styles.arrowIcon} src={arrowPlusIcon} alt="" />
            ),
            style: styles.cvrImproveRateUp,
          }
        : value < 1
        ? {
            icon: (
              <img className={styles.arrowIcon} src={arrowMinusIcon} alt="" />
            ),
            style: styles.cvrImproveRateDown,
          }
        : {
            icon: <div className={styles.arrowIcon}></div>,
            style: styles.cvrImproveRateEven,
          };

    const tmp = parseFloat(value.toString());

    return (
      <div className={improveRateStyle.style}>
        {value !== "-" && !isNaN(tmp) ? (
          <>
            {`${(tmp * 100).toFixed(2)}%`}
            {improveRateStyle.icon}
          </>
        ) : (
          value
        )}
      </div>
    );
  };

  const createFormattedItem = (
    value: string | number,
    formatType: FormatType
  ) => {
    switch (formatType) {
      case FORMAT_TYPE.NUMBER:
        return (
          <div className={styles.itemAlignRight}>
            {Number(value).toLocaleString()}
          </div>
        );
      case FORMAT_TYPE.RATE:
        return (
          <div className={styles.itemAlignRight}>
            {(Number(value) * 100).toFixed(2)}%
          </div>
        );
      case FORMAT_TYPE.TEXT:
        return <div className={styles.itemAlignLeft}>{value}</div>;
      case FORMAT_TYPE.IMPROVED_RATE:
        return createRateItemElement(value);
      case FORMAT_TYPE.INDEX:
        return <div className={styles.itemAlignCenter}>{value}</div>;
      default:
        break;
    }
  };

  const createItemElement = (e: Item) => {
    return e.value !== undefined && e.formatType ? (
      createFormattedItem(e.value, e.formatType)
    ) : (
      <div>no data</div>
    );
  };

  return cells.map((e, i) => {
    return (
      <td key={i} className={styles.cell}>
        {e.nestedValues ? (
          <div className={styles.nest}>
            {e.nestedValues.map((f, j) => (
              <div className={styles.nestedItem} key={j}>
                {createItemElement(f)}
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.item}>{createItemElement(e)}</div>
        )}
      </td>
    );
  });
};
