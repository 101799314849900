import SelectWrap, { OptionType } from "./SelectWrap";

type BorderedSelectBoxProps = {
  options: OptionType[];
  value: any;
  onChange: (e: any) => void;
  noOptionsMessage?: string;
  isDisabled?: boolean;
  placeholder?: string;
};

const BorderedSelectBox = ({
  options,
  value,
  onChange,
  noOptionsMessage,
  isDisabled,
  placeholder,
}: BorderedSelectBoxProps) => {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      fontSize: "13px",
      textAlign: "left",
      backgroundColor: "transparent",
      width: "100%",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    control: (provided: any) => ({
      ...provided,
      display: "flex",
      justifyContact: "center",
      padding: "0px 10px",
      minHeight: "0",
      height: "30px",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#FFF",
      margin: "5px 0px",
      padding: "5px 0px",
      whiteSpace: "nowrap",
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      border: 0,
    }),
    option: () => ({
      padding: "5px 15px",
      "&:hover": {
        backgroundColor: "#FFF7F1",
        cursor: "pointer",
      },
      border: "none",
    }),
    indicatorSeparator: (provided: any) => ({
      width: "5px",
      backgroundColor: "transparent",
    }),
    dropdownIndicator: (provided: any) => ({
      backgroundColor: "transparent",
      margin: 0,
      padding: 0,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      margin: 0,
      paddingLeft: 0,
      paddingRight: 1,
      textOverflow: "clip",
    }),
  };

  return (
    <SelectWrap
      options={options}
      value={options.filter((o) => o.value === value)}
      onChange={(e: OptionType) => onChange(e.value)}
      customStyles={customStyles}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled ? isDisabled : false}
      placeholder={placeholder}
    />
  );
};

export default BorderedSelectBox;
