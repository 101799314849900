import styles from "./ClientPageTemplate.module.scss";

export interface ClientPageTemplateProps {
  headerElement: JSX.Element;
  sideBarElement: JSX.Element;
  areaContents: JSX.Element;
  footerElement: JSX.Element;
  modalElement?: JSX.Element;
}

const ClientPageTemplate = ({
  headerElement,
  sideBarElement,
  areaContents,
  footerElement,
  modalElement,
}: ClientPageTemplateProps) => {
  return (
    <>
      <div className={styles.ClientPageTemplate}>
        <div className={styles.header}>{headerElement}</div>
        <div className={styles.main}>
          <div className={styles.sideBar}>{sideBarElement}</div>
          <div className={styles.contents}>{areaContents}</div>
        </div>
        <div className={styles.footer}>{footerElement}</div>
        {modalElement}
      </div>
    </>
  );
};

export { ClientPageTemplate };
