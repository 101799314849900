import { ImageLoader } from "app/process/imageLoader";
import { PUSH_NOTIFICATION_FORM_VALIDATE_RULE } from "app/system_defaults/validateRule";
import { useEffect, useMemo, useState } from "react";

export type PushNotificationContentsParameters = {
  contentsUuid: string;
  title: string;
  body: string;
  isNeedImage: boolean;
  image: File | null;
  imageUrl: string | null;
  redirectUrl: string;
  isNewImage: boolean;
};

export type PushNotificationContentsCallbacks = {
  updateContentsUuid: (value: string) => void;
  updateTitle: (value: string) => void;
  updateBody: (value: string) => void;
  isNeedImage: (value: boolean) => void;
  updateImage: (value: File | null) => void;
  updateRedirectUrl: (value: string) => void;
};

const usePushNotificationContents = (
  contents: PushNotificationContentsParameters | null
): [PushNotificationContentsParameters, PushNotificationContentsCallbacks] => {
  const [contentsUuid, setContentsUuid] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [isNeedImage, setIsNeedImage] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [isNewImage, setIsNewImage] = useState<boolean>(false);

  // NOTE: Initialize
  useEffect(() => {
    const loadImage = async () => {
      if (contents && contents.imageUrl) {
        const imageLoader = new ImageLoader();
        const currentFile = await imageLoader.get(contents.imageUrl);
        setImage(currentFile);
      }
    };
    loadImage();
  }, [contents]);

  useEffect(() => {
    if (contents) {
      if (contents.contentsUuid) {
        setContentsUuid(contents.contentsUuid);
      }
      setTitle(contents.title);
      setBody(contents.body);
      setRedirectUrl(contents.redirectUrl);
      setIsNeedImage(contents.isNeedImage);
      setImageUrl(contents.imageUrl);
      setIsNewImage(false);
    }
  }, [contents]);

  const params: PushNotificationContentsParameters = useMemo(() => {
    return {
      contentsUuid,
      title,
      body,
      isNeedImage,
      image,
      imageUrl,
      redirectUrl,
      isNewImage,
    };
  }, [
    contentsUuid,
    title,
    body,
    isNeedImage,
    image,
    imageUrl,
    redirectUrl,
    isNewImage,
  ]);

  const callbacks: PushNotificationContentsCallbacks = useMemo(() => {
    return {
      updateContentsUuid: (value: string) => setContentsUuid(value),
      updateTitle: (value: string) => {
        if (
          value.length <=
          PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.TITLE.MAX_LENGTH
        ) {
          setTitle(value);
        }
      },
      updateBody: (value: string) => {
        if (
          value.length <=
          PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.BODY.MAX_LENGTH
        ) {
          setBody(value);
        }
      },
      isNeedImage: (value: boolean) => {
        setIsNeedImage(value);
        if (value === false) {
          setImage(null);
          setImageUrl(null);
          setIsNewImage(false);
        }
      },
      updateImage: (value: File | null) => {
        setImage(value);
        setImageUrl(value ? URL.createObjectURL(value) : null);
        setIsNewImage(true);
      },
      updateRedirectUrl: (value: string) => {
        if (
          value.length <=
          PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.REDIRECT_URL.MAX_LENGTH
        ) {
          setRedirectUrl(value);
        }
      },
    };
  }, [setContentsUuid, setTitle, setBody, setImage, setRedirectUrl]);

  return [params, callbacks];
};

export default usePushNotificationContents;
