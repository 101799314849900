import React from "react";
import styles from "./BlankMessage.module.scss";
import fontStyles from "fontStyles.module.scss";

const BlankMessage: React.VFC<{ text: string }> = ({ text }) => {
  return (
    <div className={styles.blankMessage}>
      <span className={fontStyles.text}>{text}</span>
    </div>
  );
};

export default BlankMessage;
