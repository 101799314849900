import React from "react";

import styles from "./TitleForm.module.scss";
import fontStyles from "fontStyles.module.scss";

import Label from "./Label";

const TitleForm: React.VFC<{
  title: string;
  mainElement: JSX.Element;
  isRequired?: boolean;
  notification?: string | JSX.Element;
  toolTip?: string | JSX.Element;
}> = ({ title, mainElement, isRequired = false, notification, toolTip }) => {
  return (
    <div className={styles.titleForm}>
      <div className={styles.title}>
        <span className={fontStyles.boldText}>
          <Label label={title} isRequired={isRequired} toolTip={toolTip} />
        </span>
      </div>
      <div className={styles.main}>{mainElement}</div>
      <div className={styles.notification}>
        <span className={fontStyles.note}>{notification}</span>
      </div>
    </div>
  );
};

export default TitleForm;
