import styles from "./FixedReportHeader.module.scss";
import sortIcon from "images/icons/icon_arrow_bottom_small.svg";
import {
  sortColumnType,
  sortStateType,
  SORT_DIRECTION,
} from "interfaces/models";

export type FixedReportLabel = {
  label: string;
  isSortable: boolean;
  columnId?: string;
  nest?: FixedReportLabel[];
};

export const fixedReportHeaderItems = (
  labels: FixedReportLabel[],
  sortColumnId: sortColumnType,
  setSortColumnId: React.Dispatch<React.SetStateAction<sortColumnType>>,
  sortStatus: sortStateType,
  setSortStatus: React.Dispatch<React.SetStateAction<sortStateType>>,
  selectedPageNationNumber: number,
  setSelectedPageNationNumber: React.Dispatch<React.SetStateAction<number>>
): JSX.Element[] => {
  const onClickHandler = (id: string) => {
    let columnStatus: sortStateType = sortStatus;
    let columnIndex = 0;
    columnStatus.forEach((c, index) => {
      if (c.columnId === id) {
        columnIndex = index;
        return;
      }
    });
    columnStatus[columnIndex].columnId = id;
    const direction =
      columnStatus[columnIndex].sortDirection === SORT_DIRECTION.ASC
        ? SORT_DIRECTION.DESC
        : SORT_DIRECTION.ASC;
    columnStatus[columnIndex].sortDirection = direction;
    setSortStatus(columnStatus);
    setSortColumnId({ columnId: id, sortDirection: direction });
    setSelectedPageNationNumber(0);
  };

  const createSortIcon = (elem: FixedReportLabel) => (
    <img
      className={
        sortColumnId.columnId === elem.columnId &&
        sortColumnId.sortDirection === SORT_DIRECTION.DESC
          ? styles.sortIcon
          : styles.sortIconRotated
      }
      src={sortIcon}
      alt=""
      onClick={() => onClickHandler(elem.columnId ? elem.columnId : "")}
    />
  );
  return labels.map((e, i) => (
    <th
      key={i}
      className={
        i === 0 ? styles.fixedNo : i === 1 ? styles.fixedName : styles.cell
      }
    >
      {e.nest ? (
        <div className={styles.nestedItem}>
          <div className={styles.head}>
            <div className={styles.reportHeaderItem}>
              <div className={styles.label}>{e.label}</div>
              {e.isSortable ? <div>{createSortIcon(e)}</div> : undefined}
            </div>
          </div>
          <div className={styles.nest}>
            {e.nest.map((n, j) => (
              <div key={j} className={styles.item}>
                <div className={styles.label}>{n.label}</div>
                {n.isSortable ? <div>{createSortIcon(n)}</div> : undefined}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.item}>
          <div className={styles.label}>{e.label}</div>
          <div>{e.isSortable ? createSortIcon(e) : undefined}</div>
        </div>
      )}
    </th>
  ));
};
