import LinkText from "atoms/LinkText";
import { cloneElement } from "react";
import styles from "./TopicPath.module.scss";

const TopicPath = ({ items }: { items: { text: string; link?: string }[] }) => {
  const separator = <span>＞</span>;

  const elements = items.map((i) =>
    i.link ? <LinkText text={i.text} link={i.link} /> : <span>{i.text}</span>
  );

  return (
    <div className={styles.topicPath}>
      {elements
        .map((e) => [e, separator])
        .flat()
        .slice(0, -1)
        .map((e, i) => cloneElement(e, { key: i }))}
    </div>
  );
};

export default TopicPath;
