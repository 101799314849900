import styles from "./CompletionView.module.scss";

const CompletionView = ({
  massage,
  table,
  goBackButton,
}: {
  massage: string;
  table: JSX.Element;
  goBackButton: JSX.Element;
}) => (
  <div>
    <div className={styles.message}> {massage} </div>
    <div className={styles.table}>{table}</div>
    <div className={styles.goBackButton}>{goBackButton}</div>
  </div>
);

export default CompletionView;
