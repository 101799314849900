import React from "react";
import { DraftCampaign } from "types/campaign";

import styles from "./DraftCampaignList.module.scss";

import OrangeHeaderTable, {
  OrangeHeaderTableRecord,
} from "atoms/ezPushShared/accountPageShared/OrangeHeaderTable";
import { unixTimeToDateTime } from "utils/format/unixtime";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import EditButton from "atoms/accountPagesShared/EditButton";
import CopyButton from "atoms/accountPagesShared/CopyButton";
import PreviewButton from "atoms/ezPushShared/accountPageShared/PreviewerButton";
import { PreviewModal } from "./PushNotificationListModals";
import PushNotificationPreviewer from "./PushNotificationPreviewer";
import SpreadLayout from "templates/ezPush/SpreadLayout";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

import TooltipIcon from "atoms/ezPushShared/accountPageShared/TooltipIcon";
import LinkText from "atoms/LinkText";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import VerticalTable from "atoms/ezPushShared/accountPageShared/VerticalTable";

const HEADER_NAMES = ["最終更新日時", "管理名", "セグメント指定", "操作"];

const DraftCampaignList: React.VFC<{
  campaigns: DraftCampaign[] | null;
  deleteCampaign: (campaignUuid: string) => void;
  editCampaign: (campaignUuid: string) => void;
  copyCampaign: (campaignUuid: string) => void;
  showContents: (contents: JSX.Element) => void;
  hideContents: () => void;
}> = ({
  campaigns,
  deleteCampaign,
  editCampaign,
  copyCampaign,
  showContents,
  hideContents,
}) => {
  const formatter = new DescriptionFormatter();

  const draftCampaignList: OrangeHeaderTableRecord[] | null =
    campaigns === null
      ? null
      : campaigns.map((elem) => {
          const previewButton = (
            <PreviewButton
              onClick={() =>
                showContents(
                  <PreviewModal
                    previewer={
                      <VerticalSpreadLayout
                        align="center"
                        items={[
                          <div style={{ margin: "30px" }}>
                            <PushNotificationPreviewer
                              title={elem.contents.title}
                              body={elem.contents.body}
                              imageUrl={elem.contents.image_url}
                            />
                          </div>,
                          <VerticalTable
                            items={[
                              {
                                label: "遷移先URL",
                                value: (
                                  <LinkText
                                    text={elem.contents.redirect_url}
                                    link={elem.contents.redirect_url}
                                    external={true}
                                  />
                                ),
                              },
                            ]}
                          />,
                        ]}
                      />
                    }
                    close={hideContents}
                  />
                )
              }
            />
          );

          const buttons = [
            <TooltipIcon
              iconElement={
                <EditButton onClick={() => editCampaign(elem.campaign_uuid)} />
              }
              comment="編集"
            />,
            <TooltipIcon
              iconElement={
                <DeleteButton
                  onClick={() => deleteCampaign(elem.campaign_uuid)}
                />
              }
              comment="削除"
            />,
            <TooltipIcon
              iconElement={
                <CopyButton onClick={() => copyCampaign(elem.campaign_uuid)} />
              }
              comment="複製"
            />,
          ];

          const controllers = (
            <div className={styles.controllers}>
              {buttons.map((elem, i) => (
                <div key={i} className={styles.item}>
                  {elem}
                </div>
              ))}
            </div>
          );

          return {
            cells: [
              { item: unixTimeToDateTime(elem.updated_at), width: 108 },
              {
                item: (
                  <SpreadLayout
                    items={[
                      <div>{formatter.substr(elem.name, 100)}</div>,
                      <div>{previewButton}</div>,
                    ]}
                  />
                ),
              },
              {
                item: formatter.substr(elem.destination_collect_rule.name),
              },
              {
                item: controllers,
                width: 98,
              },
            ],
          };
        });

  return (
    <OrangeHeaderTable
      headers={HEADER_NAMES}
      records={draftCampaignList}
      blankMessage="下書きのプッシュ通知設定はありません"
    />
  );
};

export default DraftCampaignList;
