import { useCallback, useState } from "react";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

import styles from "./CopyableText.module.scss";

import CopyNoticeButton from "atoms/ezPushShared/accountPageShared/CopyNoticeButton";
import { generateRandomId } from "utils/randomId/RandomId";

const CopyableText = ({ text }: { text: string }) => {
  const [id] = useState<string>(generateRandomId());
  const onClick = useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  const formatter = new DescriptionFormatter();

  return (
    <div className={styles.copyableText}>
      <div className={styles.text}>{formatter.substr(text)}</div>
      <div>
        <CopyNoticeButton id={id} onClick={onClick} />
      </div>
    </div>
  );
};

export default CopyableText;
