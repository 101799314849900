import { PRESET_CONTENTS_FORMAT } from "interfaces/model/actionContentsParameters";
import {
  ActionCreationPresetFormCallbacks,
  ActionCreationPresetFormParameters,
} from "interfaces/view/actionCreation";
import SettingsEditPhaseFormMainBodyAreaView from "organisms/account/action_creation/settings_edit/SettingsEditPhaseFormMainBodyAreaView";
import { ActionCreationPresetServiceCallbacks } from "utils/action_creation/ActionCreationPresetServiceCallbacks";
import {
  CreateActionNameForm,
  CreateLoginErrorURLForm,
  CreateLoginErrorCountForm,
  CreateABTestForm,
} from "../../CreateDefinedFormParts";

type CreatePresetSettingsEditPhaseFormProps = {
  params: ActionCreationPresetFormParameters;
  callbacks: ActionCreationPresetFormCallbacks;
  services: ActionCreationPresetServiceCallbacks;
};

const CreatePresetSettingsEditPhaseForm = ({
  params,
  callbacks,
  services,
}: CreatePresetSettingsEditPhaseFormProps) => {
  switch (params.contents.presetFormat) {
    case PRESET_CONTENTS_FORMAT.LOGIN_FOLLOW:
      return (
        <SettingsEditPhaseFormMainBodyAreaView
          elements={[
            <CreateActionNameForm
              name={params.settings.name}
              setName={callbacks.settings.setName}
            />,
            <CreateLoginErrorURLForm
              location={params.presets.loginUrl.location}
              parameters={params.presets.loginUrl.parameters}
              numberOfLoginUrlParameter={
                params.presets.numberOfLoginUrlParameter
              }
              setLocation={(value) => {
                services.onChangeLoginUrlLocation(value);
              }}
              setParameters={(index, value) => {
                services.onChangeLoginUrlParameter(index, value);
              }}
              onClickParameterAddButton={() =>
                services.onClickLoginUrlParameterAddButton()
              }
              onClickParameterDeleteButton={() =>
                services.onClickLoginUrlParameterDeleteButton()
              }
            />,
            <CreateLoginErrorCountForm
              loginErrorCount={params.presets.loginErrorCount}
              loginErrorCondition={params.presets.continuousLoginErrorCondition}
              onChange={(e) => services.onChangeLoginErrorCount(e)}
              onChangeLoginErrorPageCondition={() =>
                services.onChangeLoginErrorPageCondition()
              }
            />,
            <CreateABTestForm
              abRate={params.settings.abRate}
              setABRate={callbacks.settings.setABRate}
            />,
          ]}
        />
      );
    case PRESET_CONTENTS_FORMAT.PURCHASE_FOLLOW:
      return <>purchase follow</>;
    case PRESET_CONTENTS_FORMAT.PRODUCT_INTEREST:
      return <>product interest</>;
    case PRESET_CONTENTS_FORMAT.REVISITOR:
      return <>revisitor</>;
    case PRESET_CONTENTS_FORMAT.FIRST_VISIT:
      return <>first visit</>;

    case PRESET_CONTENTS_FORMAT.CART_NOTIFICATION:
      return <>cart notificate</>;
    default:
      return <></>;
  }
};

export default CreatePresetSettingsEditPhaseForm;
