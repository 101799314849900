import styles from "./CategoryHeader.module.scss";
import PrecautionaryIcon from "./PrecautionaryIcon";

type CategoryHeaderTabProps = {
  label: string;
  additionalLabel?: string;
  isActive: boolean;
  onClick: () => void;
};

const CategoryHeaderTab: React.VFC<CategoryHeaderTabProps> = ({
  label,
  additionalLabel,
  isActive,
  onClick,
}) => (
  <div
    className={isActive ? styles.categoryHeader__active : styles.categoryHeader}
    onClick={onClick}
  >
    <div>{label}</div>
    {additionalLabel ? (
      <div className={styles.additionalLabel}>
        <PrecautionaryIcon text={additionalLabel} />
      </div>
    ) : null}
  </div>
);

export default CategoryHeaderTab;
