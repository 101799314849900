import styles from "./GrantMessageEditPageLayout.module.scss";

const GrantMessageEditPageLayout = ({
  titleElement,
  errorElement,
  previewElement,
  editElement,
  buttonElement,
}: {
  titleElement: JSX.Element;
  errorElement: JSX.Element;
  previewElement: JSX.Element;
  editElement: JSX.Element;
  buttonElement: JSX.Element;
}) => {
  return (
    <div className={styles.grantMessageEditPageLayout}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.main}>
        <div className={styles.errorMessages}>{errorElement}</div>
        <div className={styles.editor}>
          <div className={styles.preview}>{previewElement}</div>
          <div className={styles.edit}>{editElement}</div>
        </div>
        <div className={styles.button}>{buttonElement}</div>
      </div>
    </div>
  );
};

export default GrantMessageEditPageLayout;
