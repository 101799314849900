import {
  DEFAULT_SEGMENTATION_RULE_CREATION,
  SegmentationRuleCreationFormState,
  SegmentationRuleCreationFormStateCallbacks,
  SegmentationRuleCreationSetters,
} from "app/hooks/segmentationRuleCreationPage";
import {
  CURRENT_PAGE_BEHAVIOR_TYPE,
  DATA_TYPE,
  DATE_COMPARE_TARGET,
  DATE_COMPARE_TYPE,
  INTEGER_COMPARE_TYPE,
  PAST_BEHAVIOR_TYPE,
  SegmentationRuleCreation,
  SESSION_BEHAVIOR_TYPE,
  WORD_COMPARE_TYPE,
} from "interfaces/model/segmentationRuleCreation";
import { SegmentationRule, UrlPattern } from "interfaces/models";
import {
  Phase,
  SegmentationRuleCreationMode,
  SegmentationRuleCreationModeType,
} from "interfaces/view/segmentationCreation";
import SegmentationRuleCreationCodec from "./segmentationRuleCreationCodec";
import { RouteComponentProps } from "react-router-dom";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import SearchParameters from "utils/SearchParameters";
import { useCallback, useMemo } from "react";
import { SegmentationRuleCreationValidator } from "./SegmentationRuleCreationValidator";
import { UrlSanitizer } from "utils/UrlSanitizer";

export declare namespace SegmentationRuleCreationCallbacks {
  export type CommonSettingCallbacks = {
    onChangeName: (value: string) => void;
  };

  export type CurrentPageBehaviorSettingCallbacks = {
    onChangeType: (
      index: number,
      behaviorType: SegmentationRuleCreation.CurrentPageBehaviorType
    ) => void;
    onChangeValue: (index: number, value: number) => void;
    onChangeUrl: (value: UrlPattern) => void;
    add: () => void;
    delete: (index: number) => void;
  };

  export type SessionBehaviorSettingCallbacks = {
    onChangeType: (
      index: number,
      behaviorType: SegmentationRuleCreation.SessionBehaviorType
    ) => void;
    onChangeValue: (index: number, value: number) => void;
    onChangeUrl: (index: number, value: UrlPattern) => void;
    onChangeCompareType: (
      index: number,
      value: SegmentationRuleCreation.IntegerCompareType
    ) => void;
    add: () => void;
    delete: (index: number) => void;
  };

  export type PastBehaviorSettingCallbacks = {
    onChangeType: (
      index: number,
      behaviorType: SegmentationRuleCreation.PastBehaviorType
    ) => void;
    onChangeValue: (index: number, value: number) => void;
    onChangeUrl: (index: number, value: UrlPattern) => void;
    onChangeCompareType: (
      index: number,
      value: SegmentationRuleCreation.IntegerCompareType
    ) => void;
    onChangePeriods: (index: number, value: number) => void;
    add: () => void;
    delete: (index: number) => void;
  };

  export type BehaviorSettingCallbacks = {
    currentPage: CurrentPageBehaviorSettingCallbacks;
    session: SessionBehaviorSettingCallbacks;
    past: PastBehaviorSettingCallbacks;
  };

  export type CustomerTypeSettingCallbacks = {
    onChangeType: (value: SegmentationRuleCreation.CustomerType) => void;
  };

  export type CustomerAttributeSettingCallbacks = {
    onChangeKey: (index: number, value: string) => void;
    onChangeDataType: (
      index: number,
      value: SegmentationRuleCreation.DataType
    ) => void;
    onChangeValue: (index: number, value: string) => void;
    onChangeCompareType: (
      index: number,
      compareType: SegmentationRuleCreation.CompareType
    ) => void;
    onChangeCompareTarget: (
      index: number,
      compareTarget: SegmentationRuleCreation.DateCompareTarget
    ) => void;
    add: () => void;
    delete: (index: number) => void;
  };

  export type CustomerAttributeInfoCallbacks = {
    customerType: CustomerTypeSettingCallbacks;
    customerAttributes: CustomerAttributeSettingCallbacks;
  };

  export type AppCallbacks = {
    common: CommonSettingCallbacks;
    behavior: BehaviorSettingCallbacks;
    customerAttribute: CustomerAttributeInfoCallbacks;
  };

  export type EventCallbacks = {
    onCancel: () => void;
    onSubmit: (
      appState: SegmentationRuleCreation.SegmentationRuleCreation
    ) => void;
    onComplete: () => void;
  };
}

export const useSegmentationRuleCreationAppCallbacks = (
  state: SegmentationRuleCreation.SegmentationRuleCreation,
  setters: SegmentationRuleCreationSetters,
  formState: SegmentationRuleCreationFormState,
  formCallbacks: SegmentationRuleCreationFormStateCallbacks
): SegmentationRuleCreationCallbacks.AppCallbacks => {
  const getNewCustomerAttribute = useCallback(
    (
      ca: SegmentationRuleCreation.CustomerAttribute,
      value: string | number
    ): SegmentationRuleCreation.CustomerAttribute => {
      if (ca.operation.type === DATA_TYPE.INTEGER) {
        const op: SegmentationRuleCreation.IntegerCompareOperation =
          ca.operation as SegmentationRuleCreation.IntegerCompareOperation;
        const operation: SegmentationRuleCreation.IntegerCompareOperation = {
          type: DATA_TYPE.INTEGER,
          compareType: op.compareType,
          value: Number(value),
        };

        return {
          key: ca.key,
          operation: operation,
        };
      } else if (ca.operation.type === DATA_TYPE.WORD) {
        const op: SegmentationRuleCreation.WordCompareOperation =
          ca.operation as SegmentationRuleCreation.WordCompareOperation;
        const operation: SegmentationRuleCreation.WordCompareOperation = {
          type: DATA_TYPE.WORD,
          compareType: op.compareType,
          value: String(value),
        };

        return {
          key: ca.key,
          operation: operation,
        };
      }
      return ca;
    },
    []
  );

  const onChangeCurrentPageUrl = useCallback(
    (value) => {
      if (state.behavior.currentPageBehaviors.length > 0) {
        const tmp: SegmentationRuleCreation.CurrentPageBehavior[] =
          state.behavior.currentPageBehaviors.map((e) => {
            return {
              behaviorType: e.behaviorType,
              compareType: e.compareType,
              url: value,
              value: e.value,
            };
          });
        setters.setCurrentPageBehaviors([...tmp]);
      }
    },
    [setters, state.behavior.currentPageBehaviors]
  );

  const onChangeSessionUrl = useCallback(
    (index, value) => {
      if (state.behavior.sessionBehaviors.length > 0) {
        const tmp: SegmentationRuleCreation.SessionBehavior[] =
          state.behavior.sessionBehaviors.map((elem, i) => {
            if (elem.url && i === index) {
              return {
                behaviorType: elem.behaviorType,
                compareType: elem.compareType,
                value: elem.value,
                url: value,
              };
            } else {
              return elem;
            }
          });

        setters.setSessionBehaviors([...tmp]);
      }
    },
    [setters, state.behavior.sessionBehaviors]
  );

  const onChangePastUrl = useCallback(
    (index, value) => {
      if (state.behavior.pastBehaviors.length > 0) {
        const tmp: SegmentationRuleCreation.PastBehavior[] =
          state.behavior.pastBehaviors.map((elem, i) => {
            if (elem.url && i === index) {
              return {
                behaviorType: elem.behaviorType,
                compareType: elem.compareType,
                value: elem.value,
                url: value,
                rangeDays: elem.rangeDays,
              };
            } else {
              return elem;
            }
          });

        setters.setPastBehaviors([...tmp]);
      }
    },
    [setters, state.behavior.pastBehaviors]
  );

  const currentPageCallbacks: SegmentationRuleCreationCallbacks.CurrentPageBehaviorSettingCallbacks =
    useMemo(() => {
      return {
        onChangeType: (index, behaviorType) => {
          const getDefault = (
            behaviorType: SegmentationRuleCreation.CurrentPageBehaviorType
          ) => {
            const defaults = {
              [CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE]:
                DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior
                  .scrollRate,
              [CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME]:
                DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior
                  .stayingTime,
              [CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK]:
                DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior
                  .accessBlank,
              [CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED]:
                DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior
                  .notSelected,
            };

            return defaults[behaviorType];
          };

          const tmp = state.behavior.currentPageBehaviors[index];

          if (tmp) {
            const urlExistBehaviors: SegmentationRuleCreation.CurrentPageBehavior[] =
              state.behavior.currentPageBehaviors.filter(
                (e) => e.url !== undefined
              );
            const behaviors: SegmentationRuleCreation.CurrentPageBehavior[] =
              state.behavior.currentPageBehaviors.map((e, i) => {
                if (i === index) {
                  const defaultValues = getDefault(behaviorType);
                  return {
                    behaviorType: behaviorType,
                    compareType: defaultValues.compareType,
                    url:
                      urlExistBehaviors.length > 0
                        ? urlExistBehaviors[0].url
                        : tmp.url,
                    value: defaultValues.value,
                  };
                } else {
                  return e;
                }
              });

            setters.setCurrentPageBehaviors([...behaviors]);
          }
        },
        onChangeUrl: (value) => onChangeCurrentPageUrl(value),
        onChangeValue: (index, value) => {
          const tmp = state.behavior.currentPageBehaviors[index];

          const behaviors: SegmentationRuleCreation.CurrentPageBehavior[] =
            state.behavior.currentPageBehaviors.map((e, i) =>
              i === index
                ? {
                    behaviorType: tmp.behaviorType,
                    compareType: tmp.compareType,
                    url: tmp.url,
                    value: value,
                  }
                : e
            );

          setters.setCurrentPageBehaviors([...behaviors]);
        },
        add: () => {
          formCallbacks.setCurrentPageBehaviorCount(
            formState.currentPageBehaviorCount + 1
          );
          const tmp = state.behavior.currentPageBehaviors;
          tmp.push(
            DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior.notSelected
          );
          setters.setCurrentPageBehaviors([...tmp]);
        },
        delete: (index) => {
          if (formState.currentPageBehaviorCount > 1) {
            formCallbacks.setCurrentPageBehaviorCount(
              formState.currentPageBehaviorCount - 1
            );

            const tmp = state.behavior.currentPageBehaviors.filter(
              (_, i) => i !== index
            );
            setters.setCurrentPageBehaviors([...tmp]);
          }
        },
      };
    }, [
      formCallbacks,
      formState.currentPageBehaviorCount,
      onChangeCurrentPageUrl,
      setters,
      state.behavior.currentPageBehaviors,
    ]);

  const sessionBehaviorCallbacks: SegmentationRuleCreationCallbacks.SessionBehaviorSettingCallbacks =
    useMemo(() => {
      return {
        onChangeType: (index, behaviorType) => {
          const getDefault = (
            behaviorType: SegmentationRuleCreation.SessionBehaviorType
          ) => {
            const defaults = {
              [SESSION_BEHAVIOR_TYPE.PV_COUNT]:
                DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.pvCount,
              [SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV]:
                DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.continuousPv,
              [SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT]:
                DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.allPvCount,
              [SESSION_BEHAVIOR_TYPE.STAYING_TIME]:
                DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.stayingTime,
              [SESSION_BEHAVIOR_TYPE.NOT_SELECTED]:
                DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.notSelected,
            };

            return defaults[behaviorType];
          };

          const tmp = state.behavior.sessionBehaviors[index];

          const behaviors: SegmentationRuleCreation.SessionBehavior[] =
            state.behavior.sessionBehaviors.map((e, i) => {
              if (i === index) {
                const defaults = getDefault(behaviorType);
                return {
                  behaviorType: behaviorType,
                  compareType: defaults.compareType,
                  url: tmp.url,
                  value: defaults.value,
                };
              } else {
                return e;
              }
            });

          setters.setSessionBehaviors(behaviors);
        },
        onChangeValue: (index, value) => {
          const tmp = state.behavior.sessionBehaviors[index];

          const behaviors: SegmentationRuleCreation.SessionBehavior[] =
            state.behavior.sessionBehaviors.map((e, i) =>
              i === index
                ? {
                    behaviorType: tmp.behaviorType,
                    compareType: tmp.compareType,
                    url: tmp.url,
                    value: value,
                  }
                : e
            );

          setters.setSessionBehaviors([...behaviors]);
        },
        onChangeUrl: (index, value) => onChangeSessionUrl(index, value),
        onChangeCompareType: (index, value) => {
          setters.setSessionBehaviors(
            state.behavior.sessionBehaviors.map((e, i) => {
              return i === index
                ? {
                    behaviorType: e.behaviorType,
                    compareType: value,
                    url: e.url,
                    value: e.value,
                  }
                : e;
            })
          );
        },
        add: () => {
          formCallbacks.setSessionBehaviorCount(
            formState.sessionBehaviorCount + 1
          );
          const tmp = state.behavior.sessionBehaviors;
          tmp.push(
            DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.notSelected
          );
          setters.setSessionBehaviors(tmp);
        },
        delete: (index) => {
          if (formState.sessionBehaviorCount) {
            formCallbacks.setSessionBehaviorCount(
              formState.sessionBehaviorCount - 1
            );
            const tmp = state.behavior.sessionBehaviors.filter(
              (_, i) => i !== index
            );
            setters.setSessionBehaviors([...tmp]);
          }
        },
      };
    }, [
      formCallbacks,
      formState.sessionBehaviorCount,
      onChangeSessionUrl,
      setters,
      state.behavior.sessionBehaviors,
    ]);

  const pastBehaviorCallbacks: SegmentationRuleCreationCallbacks.PastBehaviorSettingCallbacks =
    useMemo(() => {
      return {
        onChangeType: (index, behaviorType) => {
          const getDefault = (
            bt: SegmentationRuleCreation.PastBehaviorType
          ) => {
            const defaults = {
              [PAST_BEHAVIOR_TYPE.PV_COUNT]:
                DEFAULT_SEGMENTATION_RULE_CREATION.pastBehavior.pvCount,
              [PAST_BEHAVIOR_TYPE.ALL_PV_COUNT]:
                DEFAULT_SEGMENTATION_RULE_CREATION.pastBehavior.allPvCount,
              [PAST_BEHAVIOR_TYPE.NOT_SELECTED]:
                DEFAULT_SEGMENTATION_RULE_CREATION.pastBehavior.notSelected,
            };

            return defaults[bt];
          };

          setters.setPastBehaviors(
            state.behavior.pastBehaviors.map((e, i) => {
              if (i === index) {
                const defaults = getDefault(behaviorType);
                return {
                  behaviorType: behaviorType,
                  compareType: defaults.compareType,
                  url: e.url,
                  value: defaults.value,
                  rangeDays: defaults.rangeDays,
                };
              } else {
                return e;
              }
            })
          );
        },
        onChangeValue: (index, value) => {
          setters.setPastBehaviors(
            state.behavior.pastBehaviors.map((e, i) =>
              i === index
                ? {
                    behaviorType: e.behaviorType,
                    compareType: e.compareType,
                    url: e.url,
                    value: value,
                    rangeDays: e.rangeDays,
                  }
                : e
            )
          );
        },
        onChangeUrl: (index, value) => onChangePastUrl(index, value),
        onChangeCompareType: (index, value) => {
          setters.setPastBehaviors(
            state.behavior.pastBehaviors.map((e, i) =>
              i === index
                ? {
                    behaviorType: e.behaviorType,
                    compareType: value,
                    url: e.url,
                    value: e.value,
                    rangeDays: e.rangeDays,
                  }
                : e
            )
          );
        },
        onChangePeriods: (index, value) => {
          setters.setPastBehaviors(
            state.behavior.pastBehaviors.map((e, i) =>
              index === i
                ? {
                    behaviorType: e.behaviorType,
                    compareType: e.compareType,
                    url: e.url,
                    value: e.value,
                    rangeDays: value,
                  }
                : e
            )
          );
        },
        add: () => {
          formCallbacks.setPastBehaviorCount(formState.pastBehaviorCount + 1);
          const tmp = state.behavior.pastBehaviors;
          tmp.push(DEFAULT_SEGMENTATION_RULE_CREATION.pastBehavior.notSelected);
          setters.setPastBehaviors(tmp);
        },
        delete: (index) => {
          if (formState.pastBehaviorCount > 1) {
            formCallbacks.setPastBehaviorCount(formState.pastBehaviorCount - 1);

            const tmp = state.behavior.pastBehaviors.filter(
              (_, i) => i !== index
            );
            setters.setPastBehaviors([...tmp]);
          }
        },
      };
    }, [
      formCallbacks,
      formState.pastBehaviorCount,
      onChangePastUrl,
      setters,
      state.behavior.pastBehaviors,
    ]);

  const customerAttributeCallbacks: SegmentationRuleCreationCallbacks.CustomerAttributeInfoCallbacks =
    useMemo(() => {
      return {
        customerType: {
          onChangeType: (value) => {
            setters.setCustomerType(value);
          },
        },
        customerAttributes: {
          onChangeKey: (index: number, key: string) => {
            const tmp = state.customer.customerAttributes[index];
            const newKey: SegmentationRuleCreation.CustomerAttribute = {
              key: key,
              operation: tmp.operation,
            };
            setters.setCustomerAttributes(
              state.customer.customerAttributes.map((e, i) =>
                i === index ? newKey : e
              )
            );
          },
          onChangeValue: (index: number, value: string) => {
            const tmp = state.customer.customerAttributes[index];

            const newValue = getNewCustomerAttribute(tmp, value);

            setters.setCustomerAttributes(
              state.customer.customerAttributes.map((e, i) =>
                i === index ? newValue : e
              )
            );
          },
          onChangeDataType: (index, dataType) => {
            setters.setCustomerAttributes(
              state.customer.customerAttributes.map((e, i) => {
                if (index === i) {
                  if (dataType === DATA_TYPE.INTEGER) {
                    return {
                      key: e.key,
                      operation: {
                        type: DATA_TYPE.INTEGER,
                        compareType:
                          INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
                        value: 0,
                      },
                    };
                  } else if (dataType === DATA_TYPE.WORD) {
                    return {
                      key: e.key,
                      operation: {
                        type: DATA_TYPE.WORD,
                        compareType: WORD_COMPARE_TYPE.INCLUDE,
                        value: "",
                      },
                    };
                  } else if (dataType === DATA_TYPE.DATE) {
                    return {
                      key: e.key,
                      operation: {
                        type: DATA_TYPE.DATE,
                        compareTarget: DATE_COMPARE_TARGET.MONTH,
                        compareType: DATE_COMPARE_TYPE.RELATIVE,
                        value: 0,
                      },
                    };
                  }
                }
                return e;
              })
            );
          },
          onChangeCompareType: (index, compareType) => {
            setters.setCustomerAttributes(
              state.customer.customerAttributes.map((e, i) => {
                if (index === i) {
                  if (e.operation.type === DATA_TYPE.INTEGER) {
                    const tmpType: SegmentationRuleCreation.IntegerCompareType =
                      compareType as SegmentationRuleCreation.IntegerCompareType;
                    return {
                      key: e.key,
                      operation: {
                        type: DATA_TYPE.INTEGER,
                        compareType: tmpType,
                        value: Number(e.operation.value),
                      },
                    };
                  } else if (e.operation.type === DATA_TYPE.WORD) {
                    const tmpType: SegmentationRuleCreation.WordCompareType =
                      compareType as SegmentationRuleCreation.WordCompareType;
                    return {
                      key: e.key,
                      operation: {
                        type: DATA_TYPE.WORD,
                        compareType: tmpType,
                        value: "",
                      },
                    };
                  }
                }
                return e;
              })
            );
          },
          onChangeCompareTarget: (index, compareTarget) => {
            setters.setCustomerAttributes(
              state.customer.customerAttributes.map((e, i) => {
                if (index === i && e.operation.type === DATA_TYPE.DATE) {
                  const tmpTarget: SegmentationRuleCreation.DateCompareTarget =
                    compareTarget as SegmentationRuleCreation.DateCompareTarget;

                  return {
                    key: e.key,
                    operation: {
                      type: DATA_TYPE.DATE,
                      compareTarget: tmpTarget,
                      compareType: DATE_COMPARE_TYPE.RELATIVE,
                      value: 0,
                    },
                  };
                }
                return e;
              })
            );
          },
          add: () => {
            formCallbacks.setCustomerAttributeCount(
              formState.customerAttributeCount + 1
            );
            const tmp = state.customer.customerAttributes;
            tmp.push(
              DEFAULT_SEGMENTATION_RULE_CREATION.customerAttribute.notSelected
            );
            setters.setCustomerAttributes(tmp);
          },
          delete: (index) => {
            if (formState.customerAttributeCount > 1) {
              formCallbacks.setCustomerAttributeCount(
                formState.customerAttributeCount - 1
              );
              const tmp = state.customer.customerAttributes.filter(
                (_, i) => i !== index
              );
              setters.setCustomerAttributes([...tmp]);
            }
          },
        },
      };
    }, [
      formCallbacks,
      formState.customerAttributeCount,
      getNewCustomerAttribute,
      state.customer.customerAttributes,
      setters,
    ]);

  return useMemo(() => {
    return {
      common: {
        onChangeName: (value: string) => setters.setName(value),
      },
      behavior: {
        currentPage: currentPageCallbacks,
        session: sessionBehaviorCallbacks,
        past: pastBehaviorCallbacks,
      },
      customerAttribute: customerAttributeCallbacks,
    };
  }, [
    currentPageCallbacks,
    sessionBehaviorCallbacks,
    pastBehaviorCallbacks,
    customerAttributeCallbacks,
    setters,
  ]);
};

export const sanitizeAppState = (
  appState: SegmentationRuleCreation.SegmentationRuleCreation
): SegmentationRuleCreation.SegmentationRuleCreation => {
  return {
    name: appState.name.trim(),
    behavior: {
      currentPageBehaviors: appState.behavior.currentPageBehaviors.map((e) => {
        return {
          behaviorType: e.behaviorType,
          url: e.url ? UrlSanitizer.run(e.url) : undefined,
          compareType: e.compareType,
          value: e.value,
        };
      }),
      sessionBehaviors: appState.behavior.sessionBehaviors.map((e) => {
        return {
          behaviorType: e.behaviorType,
          url: UrlSanitizer.run(e.url),
          compareType: e.compareType,
          value: e.value,
        };
      }),
      pastBehaviors: appState.behavior.pastBehaviors.map((e) => {
        return {
          behaviorType: e.behaviorType,
          url: UrlSanitizer.run(e.url),
          compareType: e.compareType,
          value: e.value,
          rangeDays: e.rangeDays,
        };
      }),
    },
    customer: appState.customer,
  };
};

export const useSegmentationRuleCreationEventCallbacks = (
  history: RouteComponentProps["history"],
  segmentationRuleRepository: SegmentationRuleRepository,
  mode: SegmentationRuleCreationModeType | undefined,
  segmentationRuleId: string | undefined,
  existingSegmentationRuleNames: string[] | undefined,
  initialSegmentationRule:
    | SegmentationRuleCreation.SegmentationRuleCreation
    | undefined,
  codec: SegmentationRuleCreationCodec,
  updateSearchParameters: (newParameter: SearchParameters) => void,
  setMessage: (message: string[]) => void,
  setCreatedSegmentation: (segmentationRule: SegmentationRule) => void
): SegmentationRuleCreationCallbacks.EventCallbacks => {
  return useMemo(() => {
    return {
      onCancel: () => {
        history.push("/segmentation_rules");
      },
      onSubmit: (
        appState: SegmentationRuleCreation.SegmentationRuleCreation
      ) => {
        if (existingSegmentationRuleNames === undefined) {
          setMessage([
            "データが初期化されていません、数秒待ってから保存ボタンを押してください。",
          ]);
          return;
        }
        setMessage([]);

        const sanitizedAppState = sanitizeAppState(appState);

        const errors = SegmentationRuleCreationValidator.getErrors(
          existingSegmentationRuleNames,
          sanitizedAppState,
          initialSegmentationRule?.name
        );

        if (errors.length > 0) {
          setMessage(errors);
        } else {
          const apiRequest = codec.encode(sanitizedAppState);

          if (
            mode === SegmentationRuleCreationMode.EDIT &&
            segmentationRuleId
          ) {
            segmentationRuleRepository
              .update(segmentationRuleId, apiRequest)
              .then((e) => setCreatedSegmentation(e));
            updateSearchParameters({
              phase: Phase.COMPLETE,
            });
          } else if (mode === SegmentationRuleCreationMode.CREATE) {
            segmentationRuleRepository
              .create(apiRequest)
              .then((e) => setCreatedSegmentation(e));
            updateSearchParameters({
              phase: Phase.COMPLETE,
            });
          } else {
            // FIXME: 想定していないパターンのアクセスにはエラーハンドリング入れる。多分routingの時点で入れるべきなんだが。
            setMessage(["不正な操作です。"]);
          }
        }
      },
      onComplete: () => {
        history.push("/segmentation_rules");
      },
    };
  }, [
    codec,
    existingSegmentationRuleNames,
    history,
    mode,
    segmentationRuleId,
    segmentationRuleRepository,
    initialSegmentationRule?.name,
    setMessage,
    updateSearchParameters,
    setCreatedSegmentation,
  ]);
};
