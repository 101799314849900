import { useHistory } from "react-router-dom";
import styles from "./LinkText.module.scss";

interface LinkTextProps {
  text: string;
  link: string;
  external?: boolean;
}

const LinkText = ({ text, link, external }: LinkTextProps) => {
  const history = useHistory();
  return external ? (
    <a className={styles.linkText} href={link} target="_blank" rel="noreferrer">
      {text}
    </a>
  ) : (
    <span className={styles.linkText} onClick={() => history.push(link)}>
      {text}
    </span>
  );
};

export default LinkText;
