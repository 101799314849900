import { useCallback, useState } from "react";
import RestApi from "utils/RestApi";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import MeasurementUrlRepository from "utils/repositories/MeasurementUrlRepository";

const useMeasurementUrls = (
  managementApi: RestApi
): [MeasurementUrlSettingWithType[], () => void] => {
  const [urls, setUrls] = useState<MeasurementUrlSettingWithType[]>([]);

  const load = useCallback(async () => {
    const data = await new MeasurementUrlRepository(managementApi).load();

    if (data) {
      setUrls(data.items);
    }
  }, [managementApi]);

  return [urls, load];
};

export default useMeasurementUrls;
