import { ActionSummary } from "interfaces/models";
import { DateRange } from "interfaces/view/accout";
import { useCallback, useEffect, useState } from "react";
import { ActionRepository } from "utils/ActionsRepository";

const useActionSummaries = (
  dateRange: DateRange,
  actionRepository: ActionRepository
): [
  {
    [actionId: string]: ActionSummary;
  },
  string
] => {
  const [actionSummaries, setActionSummaries] = useState<{
    [actionId: string]: ActionSummary;
  }>({});

  const [error, setError] = useState<string>("");

  const loadActionSummaries = useCallback<
    (dateRange: DateRange) => Promise<void>
  >(
    async (dateRange): Promise<void> => {
      try {
        if (dateRange.start && dateRange.end) {
          setActionSummaries({});
          setActionSummaries(
            await actionRepository.loadSummaries(dateRange.start, dateRange.end)
          );
          setError("");
        }
      } catch (e) {
        if (e instanceof Error) {
          setError("アクションのリアルタイム集計に失敗しました");
          console.error(e);
        } else {
          console.error(e);
        }
      }
    },
    [actionRepository, setError]
  );

  useEffect(() => {
    loadActionSummaries(dateRange);
  }, [loadActionSummaries, dateRange]);

  return [actionSummaries, error];
};

export default useActionSummaries;
