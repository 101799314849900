import TextInput from "atoms/TextInput";
import { useState } from "react";

type ContentsPreviewUrlProps = {
  url: string;
  onChange: (url: string) => void;
};

const ContentsPreviewUrl = ({ url, onChange }: ContentsPreviewUrlProps) => {
  const [previewUrl, setPreviewUrl] = useState<string>("");
  return (
    <TextInput
      value={previewUrl}
      placeholder="プレビューするURLを入力"
      onChange={(e) => setPreviewUrl(e.target.value)}
      onBlur={() => {
        onChange(previewUrl);
      }}
    />
  );
};

export default ContentsPreviewUrl;
