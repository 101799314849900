export const GRANT_MESSAGE_LAYOUT_TYPE = {
  POP_UP: "POP_UP",
  MODAL: "MODAL",
} as const;

export type GrantMessageLayoutType =
  typeof GRANT_MESSAGE_LAYOUT_TYPE[keyof typeof GRANT_MESSAGE_LAYOUT_TYPE];

export const GRANT_MESSAGE_MODAL_DISPLAY_POSITION = {
  CENTER: "CENTER",
} as const;

export type GrantMessageModalDisplayPosition =
  typeof GRANT_MESSAGE_MODAL_DISPLAY_POSITION[keyof typeof GRANT_MESSAGE_MODAL_DISPLAY_POSITION];

export const GRANT_MESSAGE_POP_UP_DISPLAY_POSITION = {
  CENTER_BOTTOM: "CENTER_BOTTOM",
  LEFT_BOTTOM: "LEFT_BOTTOM",
  RIGHT_BOTTOM: "RIGHT_BOTTOM",
  CENTER_TOP: "CENTER_TOP",
  LEFT_TOP: "LEFT_TOP",
  RIGHT_TOP: "RIGHT_TOP",
} as const;

export type GrantMessagePopUpDisplayPosition =
  typeof GRANT_MESSAGE_POP_UP_DISPLAY_POSITION[keyof typeof GRANT_MESSAGE_POP_UP_DISPLAY_POSITION];

export type GrantMessageDisplayPosition =
  | GrantMessageModalDisplayPosition
  | GrantMessagePopUpDisplayPosition;
