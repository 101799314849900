import { ColorResult } from "react-color";
import EditableInput, {
  EditableInputStyles,
} from "react-color/lib/components/common/EditableInput";
import { DEFAULT_BLACK, hexToColor } from "utils/color";

type EZCXEditableInputProps = {
  hex?: string;
  onChange: (c: ColorResult) => void;
};

const EZCXEditableInput = ({ hex, onChange }: EZCXEditableInputProps) => {
  const inputStyle: EditableInputStyles = {
    input: {
      width: 125,
      height: 30,
      boxSizing: "border-box",
      outline: "none",
    },
  };

  return (
    <EditableInput
      style={inputStyle}
      value={hex}
      onChange={(c, e) => {
        // NOTE: Definition says, onChange of Editable Input returns first argument as ColorResult type,
        // but returns string color code actually (ex. "#AABBCC")
        // so forcely translate string to ColorResult.
        const color = hexToColor(`${c}`);
        color ? onChange(color) : onChange(DEFAULT_BLACK);
      }}
    />
  );
};

export default EZCXEditableInput;
