import {
  PresetContentsFormat,
  PRESET_CONTENTS_FORMAT,
} from "interfaces/model/actionContentsParameters";
import {
  ConditionTypes,
  IntegerCompareTypes,
  PathParameterPattern,
  SessionPvCountCondition,
  SessionScenarioPvCondition,
  UrlPattern,
} from "interfaces/models";
import { SegmentationRuleCreationRequest } from "interfaces/requests";
import { ActionCreationPresetParameters } from "interfaces/view/actionCreation";

const filterUrlPattern = (url: UrlPattern): UrlPattern => {
  let urlParam: PathParameterPattern[] = [];
  if (url.parameters.length > 0) {
    url.parameters.forEach((p) => {
      if (p.key) {
        urlParam.push(p);
      }
    });
  }

  return {
    location: url.location,
    parameters: urlParam,
  };
};

export class PresetActionSegmentationRuleGenerator {
  private createSessionPvCountCondition(
    presets: ActionCreationPresetParameters
  ): SessionPvCountCondition {
    return {
      condition_type: ConditionTypes.SESSION_PV_COUNT,
      parameters: {
        url: filterUrlPattern(presets.loginUrl),
        count: presets.loginErrorCount,
        operation: {
          compare_type: IntegerCompareTypes.GreaterThanOrEqualTo,
          value: presets.loginErrorCount,
        },
      },
    };
  }

  private createSessionScenarioPvCondition(
    presets: ActionCreationPresetParameters
  ): SessionScenarioPvCondition {
    return {
      condition_type: ConditionTypes.SESSION_SCENARIO_PV,
      parameters: {
        pattern: [...Array(presets.loginErrorCount)].map((_, i) =>
          filterUrlPattern(presets.loginUrl)
        ),
      },
    };
  }

  public execute(
    format: PresetContentsFormat,
    presets: ActionCreationPresetParameters
  ): SegmentationRuleCreationRequest {
    switch (format) {
      case PRESET_CONTENTS_FORMAT.LOGIN_FOLLOW:
        if (
          !presets.loginErrorCount ||
          presets.loginUrl.location[0].value === ""
        ) {
          throw new Error(
            `Generate segmentation rule failed: Login follow segmentation`
          );
        }

        return {
          name: `ログインに失敗して購入を諦めてしまうユーザー(${Date.now()})`,
          conditions: [
            presets.continuousLoginErrorCondition
              ? this.createSessionScenarioPvCondition(presets)
              : this.createSessionPvCountCondition(presets),
          ],
        };

      case PRESET_CONTENTS_FORMAT.PURCHASE_FOLLOW:
      case PRESET_CONTENTS_FORMAT.PRODUCT_INTEREST:
      case PRESET_CONTENTS_FORMAT.REVISITOR:
      case PRESET_CONTENTS_FORMAT.FIRST_VISIT:
      case PRESET_CONTENTS_FORMAT.CART_NOTIFICATION:
        throw new Error("Generate segmentation rule failed: TODO: implement");
      default:
        throw new Error(
          "Generate segmentation rule failed: undefined preset type"
        );
    }
  }
}
