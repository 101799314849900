import styles from "./ToolTipDeleteButton.module.scss";
import ReactTooltip from "react-tooltip";
import tooltip_delete_icon from "images/icons/icon_none_delete.svg";

type ToolTipDeleteButtonType = {
  toolTipText: string;
  onClick: () => void;
};

const ToolTipDeleteButton = ({
  toolTipText,
  onClick,
}: ToolTipDeleteButtonType) => {
  return (
    <>
      <button
        type="button"
        data-tip
        data-for="tooltip"
        className={styles.iconButton}
        onClick={onClick}
      >
        <img src={tooltip_delete_icon} alt="not-delete" />
      </button>
      <ReactTooltip id="tooltip" place="right" effect="float">
        <p>{toolTipText}</p>
      </ReactTooltip>
    </>
  );
};

export default ToolTipDeleteButton;
