import { useMemo } from "react";
import { MeasurementUrlType } from "types/measurement_urls";
import { useLocation } from "react-router-dom";

import {
  MEASUREMENT_URL_SETTING_EDITOR_MODE,
  MeasurementUrlSettingEditorMode,
  V2_ROUTING_PATH_PARAMETERS,
} from "app/system_defaults/v2_routing";

export type EditModeParameters = {
  mode: MeasurementUrlSettingEditorMode;
  type: MeasurementUrlType;
  urlUuid?: string | null;
};

export const useEditModeParameters = (): EditModeParameters => {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  return {
    mode:
      (searchParams.get(
        V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION
          .MEASUREMENT_URL_SETTINGS.EDIT.MODE
      ) as MeasurementUrlSettingEditorMode) ??
      MEASUREMENT_URL_SETTING_EDITOR_MODE.CREATE,
    type: searchParams.get(
      V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION
        .MEASUREMENT_URL_SETTINGS.EDIT.TYPE
    ) as MeasurementUrlType,
    urlUuid: searchParams.get(
      V2_ROUTING_PATH_PARAMETERS.ACCOUNT.BASIC_CONFIGURATION
        .MEASUREMENT_URL_SETTINGS.EDIT.URL_UUID
    ),
  };
};
