import { PLEASE_CHOICE } from "app/system_defaults/WordDefaults";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import NumberInput from "atoms/NumberInput";
import {
  INTEGER_COMPARE_TYPE,
  SegmentationRuleCreation,
  SESSION_BEHAVIOR_TYPE,
} from "interfaces/model/segmentationRuleCreation";
import { UrlPattern } from "interfaces/models";
import SessionForm from "molecules/SegmentationRuleCreation/SessionForm";
import UrlMatchPatternForm from "molecules/SegmentationRuleCreation/UrlMatchPatternForm";
import { SegmentationRuleCreationCallbacks } from "utils/segmentation_rule_creation/SegmentationRuleCreationCallbacks";
import { UrlPatternCodec } from "utils/segmentation_rule_creation/UrlPatternCodec";
import { useMemo } from "react";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";

const getUnit = (
  type: SegmentationRuleCreation.SessionBehaviorType
): string => {
  const countUnitTargets: SegmentationRuleCreation.SessionBehaviorType[] = [
    SESSION_BEHAVIOR_TYPE.PV_COUNT,
    SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV,
    SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT,
  ];

  const secondUnitTargets: SegmentationRuleCreation.SessionBehaviorType[] = [
    SESSION_BEHAVIOR_TYPE.STAYING_TIME,
  ];
  if (countUnitTargets.includes(type)) {
    return "回";
  } else if (secondUnitTargets.includes(type)) {
    return "秒 以上";
  } else {
    return "";
  }
};

type CreateSessionBehaviorFormProps = {
  state: SegmentationRuleCreation.SessionBehavior;
  initState: SegmentationRuleCreation.SessionBehavior | undefined;
  onSelectBehaviorType: (
    value: SegmentationRuleCreation.SessionBehaviorType
  ) => void;
  onChangeInputValue: (value: number) => void;
  onChangeCompareType: (
    value: SegmentationRuleCreation.IntegerCompareType
  ) => void;
  onChangeUrl: (value: UrlPattern) => void;
};

const CreateSessionBehaviorForm = ({
  state,
  initState,
  onSelectBehaviorType,
  onChangeInputValue,
  onChangeCompareType,
  onChangeUrl,
}: CreateSessionBehaviorFormProps) => {
  const behaviorTypeOptions: OptionType[] = [
    {
      label: PLEASE_CHOICE,
      value: SESSION_BEHAVIOR_TYPE.NOT_SELECTED,
    },
    { label: "指定ページの閲覧数", value: SESSION_BEHAVIOR_TYPE.PV_COUNT },
    {
      label: "指定ページの連続閲覧",
      value: SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV,
    },
    {
      label: "総閲覧数",
      value: SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT,
    },
    {
      label: "総滞在時間",
      value: SESSION_BEHAVIOR_TYPE.STAYING_TIME,
    },
  ];

  const behaviorSelectorElement = (
    <BorderedSelectBox
      options={behaviorTypeOptions}
      value={state.behaviorType}
      onChange={(e) => onSelectBehaviorType(e)}
    />
  );

  const inputElement = (
    <NumberInput value={state.value} onChange={(e) => onChangeInputValue(e)} />
  );

  const compareTypeOptions: OptionType[] = [
    { label: "以上", value: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO },
    {
      label: "以下",
      value: INTEGER_COMPARE_TYPE.LESS_THAN_OR_EQUAL_TO,
    },
    {
      label: "と等しい",
      value: INTEGER_COMPARE_TYPE.EQUAL_TO,
    },
  ];

  const compareTypeUsingBehaviors: SegmentationRuleCreation.SessionBehaviorType[] =
    [SESSION_BEHAVIOR_TYPE.PV_COUNT, SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT];

  const compareTypeElement = compareTypeUsingBehaviors.includes(
    state.behaviorType
  ) ? (
    <BorderedSelectBox
      options={compareTypeOptions}
      value={state.compareType}
      onChange={(e) => onChangeCompareType(e)}
    />
  ) : undefined;

  const urlUsingBehaviors: SegmentationRuleCreation.SessionBehaviorType[] = [
    SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV,
    SESSION_BEHAVIOR_TYPE.PV_COUNT,
  ];

  const initStateUrl: ManagementApiData.UrlMatchPattern | undefined =
    useMemo(() => {
      const initUrl = initState ? initState.url : undefined;
      return initUrl ? UrlPatternCodec.decode(initUrl) : undefined;
    }, [initState]);

  const urlFormElement = urlUsingBehaviors.includes(state.behaviorType) ? (
    <UrlMatchPatternForm
      initValue={initStateUrl}
      onChange={(e) => onChangeUrl(UrlPatternCodec.encode(e))}
    />
  ) : undefined;

  return state.behaviorType === SESSION_BEHAVIOR_TYPE.NOT_SELECTED ? (
    <SessionForm selectorElement={behaviorSelectorElement} />
  ) : (
    <SessionForm
      selectorElement={behaviorSelectorElement}
      inputElement={inputElement}
      unitElement={<>{getUnit(state.behaviorType)}</>}
      compareTypeElement={compareTypeElement}
      urlElement={urlFormElement}
    />
  );
};

const createSessionForm = (
  state: SegmentationRuleCreation.SessionBehavior[],
  initState: SegmentationRuleCreation.SessionBehavior[] | undefined,
  formCount: number,
  callbacks: SegmentationRuleCreationCallbacks.SessionBehaviorSettingCallbacks
): JSX.Element[] => {
  return Array.from(Array(formCount))
    .map((_, i) =>
      state[i] ? (
        <CreateSessionBehaviorForm
          key={i}
          state={state[i]}
          initState={initState ? initState[i] : undefined}
          onSelectBehaviorType={(v) => callbacks.onChangeType(i, v)}
          onChangeInputValue={(v) => callbacks.onChangeValue(i, v)}
          onChangeCompareType={(v) => callbacks.onChangeCompareType(i, v)}
          onChangeUrl={(v) => callbacks.onChangeUrl(i, v)}
        />
      ) : undefined
    )
    .filter((e): e is Exclude<typeof e, undefined> => e !== undefined);
};

export default createSessionForm;
