import { useEffect, useState } from "react";
import RestApi from "utils/RestApi";
import { DestinationCollectRule } from "types/destination_collect_rule";
import useDestinationCollectRules from "../../pushNotification/useDestinationCollectRules";
import useSegmentationRulePageEvents, {
  SegmentationRulePageEvents,
} from "./useSegmentationRuleListPageEvents";
import useMeasurementUrls from "../editor/useMeasurementUrls";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";

export type SegmentationRulePageParameters = {
  data: DestinationCollectRule[] | null;
  urls: MeasurementUrlSettingWithType[] | null;
  modalContents: JSX.Element | undefined;
};

export type SegmentationRulePageCallbacks = {
  events: SegmentationRulePageEvents;
  updateModalContents: (value: JSX.Element | undefined) => void;
  closeModalContents: () => void;
};

const useSegmentationRuleListPage = (
  managementApi: RestApi
): [SegmentationRulePageParameters, SegmentationRulePageCallbacks] => {
  const [destinationCollectRules, loadDestinationCollectRules] =
    useDestinationCollectRules(managementApi);

  const [urls, loadUrls] = useMeasurementUrls(managementApi);

  const events = useSegmentationRulePageEvents(
    managementApi,
    loadDestinationCollectRules
  );

  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  useEffect(() => {
    loadDestinationCollectRules();
  }, [loadDestinationCollectRules]);

  useEffect(() => {
    loadUrls();
  }, [loadUrls]);

  return [
    {
      data: destinationCollectRules,
      urls: urls,
      modalContents: modalContents,
    },
    {
      events,
      updateModalContents: (value: JSX.Element | undefined) =>
        setModalContents(value),
      closeModalContents: () => setModalContents(undefined),
    },
  ];
};

export default useSegmentationRuleListPage;
