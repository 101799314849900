import styles from "./ContentsPreviewView.module.scss";

const ContentsPreviewView: React.VFC<{
  urlInputElement: JSX.Element;
  modeSelectElement?: JSX.Element;
  previewAreaElement: JSX.Element;
  style?: React.CSSProperties;
}> = ({ urlInputElement, modeSelectElement, previewAreaElement, style }) => {
  return (
    <div className={styles.contentsPreviewView} style={style}>
      <div className={styles.previewHeader}>
        <div className={styles.modeSelect}>{modeSelectElement}</div>
        <div className={styles.urlInput}>{urlInputElement}</div>
      </div>
      <div className={styles.previewArea}>{previewAreaElement}</div>
    </div>
  );
};

export default ContentsPreviewView;
