import { ExpirationPeriod } from "interfaces/models";

export const FormatDateTime = (dateObj: Date) => {
  const year = dateObj.getFullYear();
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const date = ("0" + dateObj.getDate()).slice(-2);
  const hours = ("0" + dateObj.getHours()).slice(-2);
  const minutes = ("0" + dateObj.getMinutes()).slice(-2);

  return `${year}/${month}/${date} ${hours}:${minutes}`;
};

export const getActionExpirationPeriod = (
  expirationPeriod: ExpirationPeriod | null
) => {
  if (!expirationPeriod) {
    return "無期限";
  }
  const fromDate = new Date(expirationPeriod.from_date * 1000);
  const toDate = new Date(expirationPeriod.to_date * 1000);

  return `${FormatDateTime(fromDate)} ～ ${FormatDateTime(toDate)}`;
};
