import NotificateDescription from "atoms/accountPagesShared/NotificateDescription";
import NotificateTitle from "atoms/accountPagesShared/NotificateTitle";
import ButtonsView from "organisms/account/ButtonsView";
import NotificateModalView from "organisms/account/NotificateModalView";

type CreateNotificateModalContentsProps = {
  title: string;
  description: string;
  buttons: JSX.Element[];
};

const CreateNotificateModalContents = ({
  title,
  description,
  buttons,
}: CreateNotificateModalContentsProps) => {
  return (
    <NotificateModalView
      title={<NotificateTitle title={title} />}
      description={<NotificateDescription description={description} />}
      buttons={<ButtonsView buttons={buttons} />}
    />
  );
};

export default CreateNotificateModalContents;
