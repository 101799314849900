import {
  ActionCreationContentsPCStyleCallbacks,
  ActionCreationContentsStyleCallbacks,
} from "interfaces/view/actionCreation";
import DecorationFormView from "organisms/account/action_creation/contents_edit/DecorationFormView";
import { getCustomFormatPattern } from "utils/action_creation/CustomContentsPatterns";
import UnavailableBoard from "molecules/formParts/UnavailableBoard";
import createEditFormItems from "../../CreateEditFormItems";
import UnavailableContentsView from "organisms/account/action_creation/contents_edit/UnavailableContentsView";
import {
  ActionCreationMode,
  ActionCreationContentsStyleParameters,
  CustomContentsFormat,
  ActionCreationContentsPCStyleParameters,
} from "interfaces/model/actionContentsParameters";
import {
  DecorationEditDeviceType,
  DECORATION_EDIT_DEVICE_TYPE,
} from "interfaces/models";
import createPCEditFormItems from "../../CreatePCEditFormItems";
import ToggleForm from "molecules/formParts/ToggleForm";
import MessageBox from "atoms/accountPagesShared/MessageBox";

type CreateContentsDecorationFormProps = {
  mode?: ActionCreationMode;
  params: {
    isEditRawHTML: boolean;
    format: CustomContentsFormat;
    pattern: number;
    image: File | undefined;
    decorationEditDeviceType: DecorationEditDeviceType;
    isEnablePCStyle: boolean;
  };
  onChangeContentsImage(image: File | undefined): void;
  onChangeIsEnablePCStyle(): void;
  onError: (messages: string[]) => void;
  styles: {
    params: ActionCreationContentsStyleParameters;
    callbacks: ActionCreationContentsStyleCallbacks;
  };
  pcStyles: {
    params: ActionCreationContentsPCStyleParameters;
    callbacks: ActionCreationContentsPCStyleCallbacks;
  };
};

const CreateContentsDecorationForm = ({
  mode,
  params: {
    isEditRawHTML,
    format,
    pattern,
    image,
    decorationEditDeviceType,
    isEnablePCStyle,
  },
  onChangeContentsImage,
  onChangeIsEnablePCStyle,
  onError,
  styles,
  pcStyles,
}: CreateContentsDecorationFormProps) => {
  if (mode !== undefined && format !== undefined) {
    try {
      if (isEditRawHTML) {
        return (
          <UnavailableContentsView
            boardElement={
              <UnavailableBoard
                text={
                  <>
                    HTMLソースが変更されているため
                    <br />
                    「色・文言」は利用できません。
                  </>
                }
              />
            }
          />
        );
      }
      const formParams = getCustomFormatPattern(
        format as CustomContentsFormat,
        pattern
      );
      if (formParams) {
        if (decorationEditDeviceType === DECORATION_EDIT_DEVICE_TYPE.PC) {
          return (
            <DecorationFormView
              elements={[
                <MessageBox
                  messages={[
                    "PC表示時のサイズを変更できます。",
                    "※サイズ以外は「SP表示設定」の設定内容に準じます。",
                  ]}
                />,
                <ToggleForm
                  name={<div>PC表示時のサイズを変更する</div>}
                  isChecked={isEnablePCStyle}
                  onChange={() => onChangeIsEnablePCStyle()}
                />,
              ].concat(
                isEnablePCStyle
                  ? createPCEditFormItems(
                      formParams.parts,
                      pcStyles.params,
                      pcStyles.callbacks
                    )
                  : []
              )}
            />
          );
        }

        return (
          <DecorationFormView
            elements={createEditFormItems(
              formParams.parts,
              image,
              styles.params,
              styles.callbacks,
              onChangeContentsImage
            )}
          />
        );
      }
    } catch (err) {
      onError(["デザインフォームの読み込みに失敗しました。"]);
    }
  } else {
    onError(["アクション作成方法またはフォーマットが指定されていません。"]);
  }

  return <>ERROR</>;
};

export default CreateContentsDecorationForm;
