import { Route, Switch } from "react-router-dom";
import { EZCX_NAME } from "app/system_defaults/ezcx";
import { useEffect } from "react";
import NotFoundPage from "pages/auth/NotFoundPage";

const SignInRoute = () => {
  useEffect(() => {
    document.title = EZCX_NAME;
  }, []);

  return (
    <Switch>
      <Route exact path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default SignInRoute;
