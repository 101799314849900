import { UrlPattern } from "interfaces/models";

export const INTEGER_COMPARE_TYPE = {
  GREATER_THAN_OR_EQUAL_TO: "GREATER_THAN_OR_EQUAL_TO",
  LESS_THAN_OR_EQUAL_TO: "LESS_THAN_OR_EQUAL_TO",
  EQUAL_TO: "EQUAL_TO",
} as const;

export const CURRENT_PAGE_BEHAVIOR_TYPE = {
  SCROLL_RATE: "SCROLL_RATE",
  STAYING_TIME: "STAYING_TIME",
  ACCESS_BLANK: "ACCESS_BLANK",
  NOT_SELECTED: "NOT_SELECTED",
} as const;

export const SESSION_BEHAVIOR_TYPE = {
  PV_COUNT: "PV_COUNT",
  CONTINUOUS_PV: "CONTINUOUS_PV",
  ALL_PV_COUNT: "ALL_PV_COUNT",
  STAYING_TIME: "STAYING_TIME",
  NOT_SELECTED: "NOT_SELECTED",
} as const;

export const PAST_BEHAVIOR_TYPE = {
  PV_COUNT: "PV_COUNT",
  ALL_PV_COUNT: "ALL_PV_COUNT",
  NOT_SELECTED: "NOT_SELECTED",
} as const;

export const CUSTOMER_TYPE = {
  ALL: "ALL",
  EXISTING_USER_ONLY: "EXISTING_USER_ONLY",
  NO_MEMBER_USER_ONLY: "NO_MEMBER_USER_ONLY",
} as const;

export const DATA_TYPE = {
  INTEGER: "INTEGER",
  WORD: "WORD",
  DATE: "DATE",
} as const;

export const WORD_COMPARE_TYPE = {
  INCLUDE: "INCLUDE",
  EQUAL: "EQUAL",
  DEEP_EQUAL: "DEEP_EQUAL",
} as const;

export const DATE_COMPARE_TARGET = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
} as const;

export const DATE_COMPARE_TYPE = {
  ABSOLUTE: "ABSOLUTE",
  RELATIVE: "RELATIVE",
};

export const URL_ORGANIZATION = {
  URL: "URL",
  URL_PARAMETER: "URL_PARAMETER",
};

export declare namespace SegmentationRuleCreation {
  export type IntegerCompareType =
    typeof INTEGER_COMPARE_TYPE[keyof typeof INTEGER_COMPARE_TYPE];

  export type IntegerCompareOperation = {
    type: "INTEGER";
    compareType: IntegerCompareType;
    value: number;
  };

  export type CurrentPageBehaviorType =
    typeof CURRENT_PAGE_BEHAVIOR_TYPE[keyof typeof CURRENT_PAGE_BEHAVIOR_TYPE];

  export type CurrentPageBehavior = {
    behaviorType: CurrentPageBehaviorType;
    url?: UrlPattern;
    compareType: IntegerCompareType | null;
    value: number;
  };

  export type SessionBehaviorType =
    typeof SESSION_BEHAVIOR_TYPE[keyof typeof SESSION_BEHAVIOR_TYPE];

  export type SessionBehavior = {
    behaviorType: SessionBehaviorType;
    url: UrlPattern;
    compareType: IntegerCompareType;
    value: number;
  };

  export type PastBehaviorType =
    typeof PAST_BEHAVIOR_TYPE[keyof typeof PAST_BEHAVIOR_TYPE];

  export type PastBehavior = {
    behaviorType: PastBehaviorType;
    url: UrlPattern;
    compareType: IntegerCompareType;
    value: number;
    rangeDays: number;
  };

  export type Behavior = {
    currentPageBehaviors: CurrentPageBehavior[];
    sessionBehaviors: SessionBehavior[];
    pastBehaviors: PastBehavior[];
  };

  export type CustomerType = typeof CUSTOMER_TYPE[keyof typeof CUSTOMER_TYPE];

  export type DataType = "INTEGER" | "WORD" | "DATE";

  export type WordCompareType =
    typeof WORD_COMPARE_TYPE[keyof typeof WORD_COMPARE_TYPE];

  export type WordCompareOperation = {
    type: "WORD";
    compareType: WordCompareType;
    value: string;
  };

  export type DateCompareTarget =
    typeof DATE_COMPARE_TARGET[keyof typeof DATE_COMPARE_TARGET];

  export type DateCompareType =
    typeof DATE_COMPARE_TYPE[keyof typeof DATE_COMPARE_TYPE];

  export type DateCompareOperation = {
    type: "DATE";
    compareType: DateCompareType;
    compareTarget: DateCompareTarget;
    value: number;
  };

  export type CompareType =
    | IntegerCompareType
    | WordCompareType
    | DateCompareType;

  export type CompareOperation =
    | IntegerCompareOperation
    | WordCompareOperation
    | DateCompareOperation;

  export type CustomerAttribute = {
    key: string;
    operation: CompareOperation;
  };

  export type CustomerAttributeInfo = {
    customerType: CustomerType;
    customerAttributes: CustomerAttribute[];
  };

  export type SegmentationRuleCreation = {
    name: string;
    behavior: Behavior;
    customer: CustomerAttributeInfo;
  };
}

//各項目を一意に示すラベル
export const SEGMENTATION_NAME_LABEL = "セグメント名";
export const CURRENT_PAGE_BEHAVIOR_URL_LABEL =
  "[現在閲覧しているページ上での行動 > URL]";
export const CURRENT_PAGE_BEHAVIOR_PARAMETER_LABEL =
  "[現在閲覧しているページ上での行動 > パラメータ]";
export const CURRENT_PAGE_BEHAVIOR_SCROLL_RATE_LABEL =
  "[現在閲覧しているページ上での行動 > スクロール量]";
export const CURRENT_PAGE_BEHAVIOR_STAYING_TIME_LABEL =
  "[現在閲覧しているページ上での行動 > 滞在時間]";
export const CURRENT_PAGE_BEHAVIOR_ACCESS_BLANK_LABEL =
  "[現在閲覧しているページ上での行動 > 前回のページビューから]";
export const SESSION_BEHAVIOR_PV_COUNT_LABEL =
  "[来訪～現在までのセッション内行動 > 指定ページの閲覧]";
export const SESSION_BEHAVIOR_PV_COUNT_URL_LABEL =
  "[来訪～現在までのセッション内行動 > 指定ページの閲覧 > URL]";
export const SESSION_BEHAVIOR_PV_COUNT_PARAMETER_LABEL =
  "[来訪～現在までのセッション内行動 > 指定ページの閲覧 > パラメータ]";
export const SESSION_BEHAVIOR_CONTINUOUS_PV_LABEL =
  "[来訪～現在までのセッション内行動 > 指定ページの連続閲覧]";
export const SESSION_BEHAVIOR_CONTINUOUS_PV_URL_LABEL =
  "[来訪～現在までのセッション内行動 > 指定ページの連続閲覧 > URL]";
export const SESSION_BEHAVIOR_CONTINUOUS_PV_PARAMETER_LABEL =
  "[来訪～現在までのセッション内行動 > 指定ページの連続閲覧 > パラメータ]";
export const SESSION_BEHAVIOR_ALL_PV_COUNT_LABEL =
  "[来訪～現在までのセッション内行動 > 総閲覧数]";
export const SESSION_BEHAVIOR_STAYING_TIME_LABEL =
  "[来訪～現在までのセッション内行動 > 総滞在時間]";
export const PAST_BEHAVIOR_PV_COUNT_LABEL =
  "[過去来訪を含む行動 > 指定ページの閲覧数]";
export const PAST_BEHAVIOR_PV_COUNT_URL_LABEL =
  "[過去来訪を含む行動 > 指定ページの閲覧 > URL]";
export const PAST_BEHAVIOR_PV_COUNT_PARAMETER_LABEL =
  "[過去来訪を含む行動 > 指定ページの閲覧 > パラメータ]";
export const PAST_BEHAVIOR_ALL_PV_COUNT_LABEL =
  "[過去来訪を含む行動 > 総閲覧数]";
export const CUSTOM_INTEGER_ITEM_LABEL = "[ユーザー属性情報 > 数字]";
export const CUSTOM_WORD_ITEM_LABEL = "[ユーザー属性情報 > 文字]";
export const CUSTOM_DATE_LABEL = "[ユーザー属性情報 > 日時]";

//範囲チェックパラメータ
export const VALUE_RANGE_CHECK_PARAMETERS = [
  {
    label: CURRENT_PAGE_BEHAVIOR_SCROLL_RATE_LABEL,
    range: { minimumValue: 1, maximumValue: 100 },
  },
  {
    label: CURRENT_PAGE_BEHAVIOR_STAYING_TIME_LABEL,
    range: { minimumValue: 1, maximumValue: 1800 },
  },
  {
    label: CURRENT_PAGE_BEHAVIOR_ACCESS_BLANK_LABEL,
    range: { minimumValue: 1, maximumValue: 730 },
  },
  {
    label: SESSION_BEHAVIOR_PV_COUNT_LABEL,
    range: { minimumValue: 0, maximumValue: 10000000 },
  },
  {
    label: SESSION_BEHAVIOR_CONTINUOUS_PV_LABEL,
    range: { minimumValue: 0, maximumValue: 10000000 },
  },
  {
    label: SESSION_BEHAVIOR_ALL_PV_COUNT_LABEL,
    range: { minimumValue: 0, maximumValue: 10000000 },
  },
  {
    label: SESSION_BEHAVIOR_STAYING_TIME_LABEL,
    range: { minimumValue: 1, maximumValue: 1800 },
  },
  {
    label: PAST_BEHAVIOR_PV_COUNT_LABEL,
    range: { minimumValue: 0, maximumValue: 10000000 },
  },
  {
    label: PAST_BEHAVIOR_ALL_PV_COUNT_LABEL,
    range: { minimumValue: 0, maximumValue: 10000000 },
  },
  {
    label: CUSTOM_INTEGER_ITEM_LABEL,
    range: { minimumValue: -10000000, maximumValue: 10000000 },
  },
];

//桁数チェックパラメータ
export const DIGITS_LENGTH_CHECK_PARAMETERS = [
  {
    label: SEGMENTATION_NAME_LABEL,
    digit: { maxLength: 3000 },
  },
  {
    label: URL_ORGANIZATION.URL,
    digit: { maxLength: 3000 },
  },
  {
    label: URL_ORGANIZATION.URL_PARAMETER,
    digit: { maxLength: 3000 },
  },
  {
    label: DATA_TYPE.WORD,
    digit: { maxLength: 3000 },
  },
];

export const NOT_SELECTED = "NOT_SELECTED";
