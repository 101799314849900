import fontStyles from "fontStyles.module.scss";
import { useMemo } from "react";

import TitleMainLayout from "templates/ezPush/TitleMainLayout";
import PushNotificationEditorPanel from "./PushNotificationEditorPanel";
import {
  PushNotificationEditPageCallbacks,
  PushNotificationEditPageParameters,
} from "app/hooks/v2/pushNotification/usePushNotificationEditPage";
import {
  PUSH_NOTIFICATION_EDITOR_MODE,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";
import Panel from "templates/ezPush/Panel";
import BreadCrumbs from "atoms/ezPushShared/accountPageShared/BreadCrumbs";

const PushNotificationEditor: React.VFC<{
  parameters: PushNotificationEditPageParameters;
  callbacks: PushNotificationEditPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const titleElement = useMemo(
    () => <span className={fontStyles.title}>プッシュ通知作成・編集</span>,
    []
  );

  const mainElement = useMemo(() => {
    if (parameters.events.editMode === PUSH_NOTIFICATION_EDITOR_MODE.CREATE) {
      return (
        <PushNotificationEditorPanel
          parameters={parameters}
          callbacks={callbacks}
        />
      );
    }

    if (parameters.destinationCollectRules) {
      return (
        <PushNotificationEditorPanel
          parameters={parameters}
          callbacks={callbacks}
        />
      );
    }
    return (
      <Panel
        itemElements={[
          <LoadingView
            iconElement={<LoadingIcon />}
            textElement={<div>データを取得しています・・・</div>}
          />,
        ]}
      />
    );
  }, [parameters, callbacks]);

  const breadCrumbElement = (
    <BreadCrumbs
      paths={[
        {
          name: "プッシュ通知",
          url: V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME,
        },
        {
          name: "プッシュ通知作成・編集",
          url: V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME,
        },
      ]}
    />
  );

  return (
    <TitleMainLayout
      headerElement={breadCrumbElement}
      titleElement={titleElement}
      mainElement={mainElement}
    />
  );
};

export default PushNotificationEditor;
