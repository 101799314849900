import NotificateModalView from "organisms/account/NotificateModalView";

import NotificateModal from "atoms/accountPagesShared/NotificateModal";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import ButtonsView from "organisms/account/ButtonsView";
import NotificateTitle from "atoms/accountPagesShared/NotificateTitle";
import NotificateDescription from "atoms/accountPagesShared/NotificateDescription";

const CreateDeleteConfirmModal = ({
  message,
  onDelete,
  onClose,
}: {
  message: string;
  onDelete: () => void;
  onClose: () => void;
}) => {
  return (
    <NotificateModal
      Contents={
        <NotificateModalView
          title={<NotificateTitle title={message} />}
          description={
            <NotificateDescription description=">削除すると元には戻せません。よろしいですか？" />
          }
          buttons={
            <ButtonsView
              buttons={[
                <NegativeButton text="キャンセル" onClick={onClose} />,
                <PositiveButton text="削除" onClick={onDelete} />,
              ]}
            />
          }
        />
      }
      isOpen={true}
      onRequestClose={onClose}
    />
  );
};

export default CreateDeleteConfirmModal;
