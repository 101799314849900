import LoadingIcon from "atoms/LoadingIcon";
import { Accesses, MonthlyAccess } from "interfaces/models";
import styles from "./AccessStatisticsCurrentPv.module.scss";

const formatTitleText = (accesses: Accesses) => {
  const keysMonthAll = Object.keys(accesses.monthly.reports);
  const keysLatestMonth = keysMonthAll.reverse().slice(0, 1);
  const date: Date = new Date(keysLatestMonth + "-01");
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return year + "年" + month + "月度PV数";
};

type timeRange = {
  [from: string]: number;
  [to: number]: number;
};

const getTermText = (reports: MonthlyAccess) => {
  const keysMonthAll: string[] = Object.keys(reports);
  const keysLatestMonth: string = keysMonthAll.sort().reverse()[0];
  const timeRange: timeRange = reports[keysLatestMonth].meta.time_range;

  const timeRangeTo = new Date(timeRange["to_timestamp_ms"]);
  timeRangeTo.setDate(timeRangeTo.getDate() - 1);
  return (
    timeRangeTo.getFullYear() +
    "/" +
    (timeRangeTo.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    timeRangeTo.getDate().toString().padStart(2, "0") +
    "までのデータを反映"
  );
};

const getTitleValue = (accesses: Accesses) => {
  const keysMonthAll = Object.keys(accesses.monthly.reports);
  const keysLatestMonth = keysMonthAll.reverse().slice(0, 1)[0];
  const latestPV: string =
    accesses.monthly.reports[keysLatestMonth].value.pv.toLocaleString();
  return latestPV;
};

type AccessStatisticsCurrentPvProps = {
  accesses: Accesses | undefined;
};

const AccessStatisticsCurrentPv = ({
  accesses,
}: AccessStatisticsCurrentPvProps) => {
  return accesses ? (
    <div className={styles.currentPV}>
      <div className={styles.titleText}>{formatTitleText(accesses)}</div>
      <div className={styles.titleValue}>{getTitleValue(accesses)}</div>
      <div className={styles.term}>{getTermText(accesses.monthly.reports)}</div>
    </div>
  ) : (
    <div>
      <LoadingIcon />
    </div>
  );
};
export default AccessStatisticsCurrentPv;
