import { useState } from "react";
import { ColorResult } from "react-color";
import { hexToColor } from "utils/color";
import styles from "./ColorPicker.module.scss";
import PresetColorPicker from "./PresetColorPicker";
import SliderColorPicker from "./SliderColorPicker";

export const PICKER_TYPE = {
  PRESET: "PRESET",
  SLIDER: "SLIDER",
};

export type PickerType = typeof PICKER_TYPE[keyof typeof PICKER_TYPE];

type CreatePickerElementProps = {
  type: PickerType;
  color: ColorResult;
  onChange: (color: ColorResult | string) => void;
  onChangePicker: (p: PickerType) => void;
};

const CreatePickerElement = ({
  type,
  color,
  onChange,
  onChangePicker,
}: CreatePickerElementProps): JSX.Element => {
  switch (type) {
    case PICKER_TYPE.PRESET:
      return (
        <PresetColorPicker
          color={color}
          onChange={(c) => onChange(c)}
          onChangePicker={() => onChangePicker(PICKER_TYPE.SLIDER)}
        />
      );
    case PICKER_TYPE.SLIDER:
      return (
        <SliderColorPicker
          color={color.hsl}
          onChange={(c) => onChange(c)}
          onChangePicker={() => onChangePicker(PICKER_TYPE.PRESET)}
        />
      );
    default:
      return <></>;
  }
};

const DEFAULT_COLOR_RESULT: ColorResult = {
  hex: "#FFFFFF",
  hsl: { h: 0, s: 0, l: 1 },
  rgb: { r: 255, g: 255, b: 255 },
};

type EZCXColorPickerProps = {
  value: string;
  onChange: (value: string) => void;
};

const EZCXColorPicker = ({ value, onChange }: EZCXColorPickerProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [pickerType, setPickerType] = useState<PickerType>(PICKER_TYPE.PRESET);
  const [color, setColor] = useState<ColorResult>(
    hexToColor(value) ?? DEFAULT_COLOR_RESULT
  );

  const openerStyle: React.CSSProperties = {
    background: color ? color.hex : value,
  };

  const onChangeColor = (c: string | ColorResult) => {
    if (typeof c === "string") {
      const color_ = hexToColor(c);
      if (color_) {
        setColor(color_);
        onChange(color_.hex);
      }
    } else {
      setColor(c);
      onChange(c.hex);
    }
  };

  return (
    <div className={styles.colorPicker}>
      <div
        style={openerStyle}
        className={styles.opener}
        onClick={() => setIsActive(!isActive)}
      ></div>
      {isActive ? (
        <>
          <div className={styles.cover} onClick={() => setIsActive(false)} />
          <div className={styles.popover}>
            <CreatePickerElement
              type={pickerType}
              color={color}
              onChange={onChangeColor}
              onChangePicker={(p) => setPickerType(p)}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EZCXColorPicker;
