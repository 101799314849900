import RestApi from "utils/RestApi";
import { useHistory } from "react-router-dom";
import {
  SEGMENTATION_RULE_EDITOR_MODE,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";
import {
  CONDITION_TYPE,
  SegmentationRuleTree,
} from "app/process/segmentationRule/segmentationRuleTree";
import DestinationCollectRuleRepository from "utils/repositories/DestinationCollectRuleRepository";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import { useState } from "react";
import ErrorMessage from "utils/ErrorMessage";
import {
  SegmentationRuleConditionInterface,
  SegmentationRuleFormParameters,
  SegmentationRuleTreeParameter,
} from "interfaces/v2/segmentationRuleForm";
import SegmentationRuleEditFormValidator from "app/process/segmentationRule/segmentationRuleEditFormValidator";
import { SegmentationRuleEditPageModeParameters } from "./useDestinationCollectRuleEditModeParameters";
import { DestinationCollectRule } from "types/destination_collect_rule";

export type SegmentationRulePageEditEvents = {
  canSave: (formParameters: SegmentationRuleFormParameters) => boolean;
  save: (formParameters: SegmentationRuleFormParameters) => void;
  cancel: () => void;
};

export type SegmentationRulePageEditEventParameters = {
  errorMessages: ErrorMessage[];
};

// NOTE: DB保存用のデータは、URL設定が空のものは除外する
// FIXME: 処理内容がモデル側の責務のもの。ここに書くのは適切ではないから移したい
const sanitizeSegmentationRuleTreeParameter = (
  tree: SegmentationRuleTreeParameter
): SegmentationRuleTreeParameter => {
  return tree
    .map((cond) => {
      if (cond.type === CONDITION_TYPE.TOTAL_PV_COUNT) {
        const pages: SegmentationRuleConditionInterface = {
          type: CONDITION_TYPE.TOTAL_PV_COUNT,
          pages: cond.pages.filter((page) => page.urlSetting.value !== ""),
        };
        return {
          ...cond,
          ...pages,
        };
      } else {
        return cond;
      }
    })
    .filter((cond) => {
      if (cond.type === CONDITION_TYPE.TOTAL_PV_COUNT) {
        return cond.pages.length > 0;
      } else {
        return true;
      }
    });
};

const useSegmentationRuleEditEvents = (
  managementApi: RestApi,
  modeParameters: SegmentationRuleEditPageModeParameters,
  urlSettings: MeasurementUrlSettingWithType[],
  existRules: DestinationCollectRule[] | null
): [
  SegmentationRulePageEditEventParameters,
  SegmentationRulePageEditEvents
] => {
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);

  return [
    { errorMessages },
    {
      canSave: (formParameters: SegmentationRuleFormParameters) => {
        setErrorMessages([]);

        const errors = new SegmentationRuleEditFormValidator(
          formParameters,
          existRules ? existRules.map((e) => e.name) : [],
          modeParameters.mode
        ).validate();

        if (errors.length > 0) {
          setErrorMessages(errors);
          return false;
        } else {
          return true;
        }
      },
      save: (formParameters: SegmentationRuleFormParameters) => {
        try {
          const filteredTree: SegmentationRuleTreeParameter =
            sanitizeSegmentationRuleTreeParameter(formParameters.tree);

          const segmentationRuleTree = SegmentationRuleTree.generateNewTree(
            formParameters.duration,
            filteredTree,
            urlSettings
          );

          const request = {
            name: formParameters.name,
            segmentation_rule_tree: segmentationRuleTree.toJsonString(),
            form: JSON.stringify({
              name: formParameters.name,
              duration: formParameters.duration,
              tree: filteredTree,
              isNeedDuration: formParameters.isNeedDuration,
            }),
          };

          if (
            modeParameters.mode === SEGMENTATION_RULE_EDITOR_MODE.EDIT &&
            modeParameters.destinationCollectRuleUuid
          ) {
            new DestinationCollectRuleRepository(managementApi)
              .update({
                ...request,
                destination_collect_rule_uuid:
                  modeParameters.destinationCollectRuleUuid,
              })
              .then(() => {
                history.push(V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.HOME);
              });
          } else {
            new DestinationCollectRuleRepository(managementApi)
              .create(request)
              .then(() => {
                history.push(V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.HOME);
              });
          }
        } catch (e) {
          setErrorMessages([new ErrorMessage("不正な入力です")]);
        }
      },
      cancel: () => {
        history.push(V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.HOME);
      },
    },
  ];
};

export default useSegmentationRuleEditEvents;
