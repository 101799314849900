import {
  CreativeParts,
  CREATIVE_PARTS,
} from "interfaces/model/actionContentsParameters";

import {
  CREATIVE_PARTS_CLASS_NAME,
  CREATIVE_ACTION_CLASS_NAME,
} from "./creative";

export const createImageParts = (imageUrl: string): string => {
  return `<div class="${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME}">
  <img class="${CREATIVE_PARTS_CLASS_NAME.IMAGE}" src="${imageUrl}" alt="" />
</div>`;
};

export const createLinkImageParts = (
  imageUrl: string,
  imageLinkText: string,
  isOpenOtherWindow: boolean
): string => {
  return `<div class="${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME}">
  <a class="${CREATIVE_ACTION_CLASS_NAME.REACTION}" href="${imageLinkText}"
  ${isOpenOtherWindow ? 'target="_blank" rel="noopener noreferrer"' : ""}>
  <img class="${CREATIVE_PARTS_CLASS_NAME.IMAGE}" src="${imageUrl}" alt="" />
  </a>
</div>`;
};

export const createHeadlineParts = (headlineText: string) => {
  return `<div class="${CREATIVE_PARTS_CLASS_NAME.HEADLINE}">${headlineText}</div>`;
};

export const createSubHeadlineParts = (text: string): string => {
  return `<div class="${CREATIVE_PARTS_CLASS_NAME.SUB_HEADLINE}">${text}</div>`;
};

export const createArticleParts = (article: string) => {
  return `<div class="${CREATIVE_PARTS_CLASS_NAME.ARTICLE}">${article}</div>`;
};

export const createCouponCodeParts = (
  couponCodeText: string,
  couponButtonText: string
) => {
  return `<div class="${CREATIVE_PARTS_CLASS_NAME.COUPON_AREA}">
  <input id="copy" class="${CREATIVE_PARTS_CLASS_NAME.COUPON_CODE}" type="text" value="${couponCodeText}" readonly>
  <button type="button" class="${CREATIVE_PARTS_CLASS_NAME.COUPON_COPY_BUTTON} ${CREATIVE_ACTION_CLASS_NAME.REACTION}"
      onclick="document.getElementById('copy').select();document.execCommand('Copy');alert('コピーしました')">
      ${couponButtonText}
  </button>
</div>`;
};

export const createButton = (
  buttonLinkText: string,
  buttonText: string,
  isOpenOtherWindow: boolean
) => {
  return `<div>
  <a class="${CREATIVE_PARTS_CLASS_NAME.BUTTON} ${
    CREATIVE_ACTION_CLASS_NAME.REACTION
  }" href="${buttonLinkText}"
    ${isOpenOtherWindow ? 'target="_blank" rel="noopener noreferrer"' : ""}>
    ${buttonText}
  </a>
</div>`;
};

export const createCloseButton = () => {
  return `<a class="${CREATIVE_PARTS_CLASS_NAME.CLOSE} ${CREATIVE_ACTION_CLASS_NAME.CLOSE}">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
    <path id="path_7848" data-name="path_7848"
      d="M473.114,104.639l-4.339-4.349,4.368-4.413-.029-.192-.918-.88-.194.028-4.338,4.344-4.407-4.373-.193.028-.849.851a.187.187,0,0,0,0,.262l4.338,4.344-4.342,4.352a.184.184,0,0,0,0,.258l.918.879.194-.028,4.337-4.343L472,105.75l.087.055h.063l.1-.04.862-.864A.208.208,0,0,0,473.114,104.639Z"
      transform="translate(-462.16 -94.805)" fill="#444" />
  </svg>
</a>`;
};

export const createContentsParts = (
  part: CreativeParts,
  styles: {
    subHeadlineText: string;
    headlineText: string;
    text: string;
    buttonText: string;
    buttonLinkText: string;
    isOpenOtherWindow: boolean;
    imageUrl: string;
    imageLinkText: string;
    couponCodeText: string;
    couponButtonText: string;
  },
  imageUrl: string
) => {
  switch (part) {
    case CREATIVE_PARTS.SUB_HEADLINE:
      return createSubHeadlineParts(styles.subHeadlineText);

    case CREATIVE_PARTS.IMAGE:
      return createImageParts(imageUrl ? imageUrl : styles.imageUrl);

    case CREATIVE_PARTS.LINK_IMAGE:
      return createLinkImageParts(
        imageUrl ? imageUrl : styles.imageUrl,
        styles.imageLinkText,
        styles.isOpenOtherWindow
      );

    case CREATIVE_PARTS.HEADLINE:
      return createHeadlineParts(styles.headlineText);

    case CREATIVE_PARTS.ARTICLE:
      return createArticleParts(styles.text);

    case CREATIVE_PARTS.COUPON_CODE:
      return createCouponCodeParts(
        styles.couponCodeText,
        styles.couponButtonText
      );

    case CREATIVE_PARTS.BUTTON:
      return createButton(
        styles.buttonLinkText,
        styles.buttonText,
        styles.isOpenOtherWindow
      );

    default:
      return "";
  }
};
