import React, { ButtonHTMLAttributes, useMemo, useState } from "react";
// DatePicker
import DatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";

// self
import "./DateRangePickerWrapper.scss";
import containerStyles from "./DateRangePickerContainer.module.scss";

import DateRangePickerOpener from "./DateRangePickerOpener";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";

import { DateRange } from "interfaces/view/accout";

const locale = { ...ja, options: { ...ja.options, weekStartsOn: 1 } } as Locale;

interface DateRangePickerWrapperProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
}

const DateRangePickerWrapper = ({
  dateRange,
  setDateRange,
}: DateRangePickerWrapperProps) => {
  const [startDate, setStartDate] = useState<Date>(dateRange.start);
  const [endDate, setEndDate] = useState<Date | null>(dateRange.end);
  const todayDate = useMemo(() => {
    return new Date();
  }, []);
  const OldestDate = useMemo(() => {
    return new Date(todayDate.getFullYear() - 2, todayDate.getMonth());
  }, [todayDate]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const Opener = React.forwardRef(
    (
      props: React.DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      ref: React.Ref<HTMLDivElement>
    ) => {
      return (
        <div ref={ref}>
          <DateRangePickerOpener
            dateRange={dateRange}
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      );
    }
  );

  const DateRangePickerContainer = ({
    className,
    children,
  }: {
    className: string;
    children: React.ReactNode[];
  }): React.ReactNode => {
    return (
      <div className={containerStyles.dateRangePickerContainer}>
        <div className={className}>
          <div className={containerStyles.main}>
            {children[0]}
            {children[1]}
            {children[2]}
          </div>
        </div>
        <div>{children[7]}</div>
      </div>
    );
  };

  return (
    <DatePicker
      // dates
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      // designs
      calendarContainer={DateRangePickerContainer}
      customInput={<Opener />}
      showPopperArrow={false}
      focusSelectedMonth
      popperPlacement="bottom-end"
      // events
      onChange={(dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      }}
      shouldCloseOnSelect={false}
      disabledKeyboardNavigation
      open={isOpen}
      onClickOutside={() => setIsOpen(false)}
      // range
      selectsRange
      filterDate={(d) => todayDate > d && OldestDate <= d}
      monthsShown={2}
      maxDate={todayDate}
      minDate={OldestDate}
      showDisabledMonthNavigation
      // locale
      locale={locale}
      dateFormatCalendar={"yyyy/MM"}
    >
      <div className={containerStyles.buttons}>
        <div className={containerStyles.cancel}>
          <NegativeButton text="キャンセル" onClick={() => setIsOpen(false)} />
        </div>
        <div>
          <PositiveButton
            text="適用"
            onClick={() => {
              if (startDate !== null && endDate !== null) {
                setDateRange({
                  start: startDate,
                  end: new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate() + 1,
                    endDate.getHours(),
                    endDate.getMinutes(),
                    endDate.getSeconds() - 1
                  ),
                });
              }
              setIsOpen(false);
            }}
          />
        </div>
      </div>
    </DatePicker>
  );
};

export default DateRangePickerWrapper;
