import React from "react";
import Select, { components } from "react-select";

import fontStyles from "fontStyles.module.scss";

import dropdownIcon from "images/icons/icon_select_box_arrow.svg";
import activeDropdownIcon from "images/icons/icon_select_box_active_arrow.svg";

export interface Option {
  readonly value: string;
  readonly label: string;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly Option[];
}

const GroupLabel = (data: GroupedOption): JSX.Element => (
  <span className={fontStyles.text}>{data.label}</span>
);

const DropdownIndicator = (props: any) => {
  const icon = props.selectProps.menuIsOpen ? activeDropdownIcon : dropdownIcon;

  return (
    <components.DropdownIndicator {...props}>
      <img
        src={icon}
        alt=""
        style={{
          display: "block",
          width: "11px",
          height: "5px",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    boxSizing: "border-box",
    border: "1px solid #AAAAAA",
    height: 30,
    minHeight: 30,
    "&:hover": {
      cursor: "pointer",
    },
    boxShadow: "0 0 0 0",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: 30,
    padding: "5px 10px",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
    height: 30,
    fontSize: 13,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: 30,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  option: () => ({
    padding: "5px 35px",
    "&:hover": {
      backgroundColor: "#FFF7F1",
      cursor: "pointer",
    },
    border: "none",
  }),
  menu: (provided: any) => ({
    ...provided,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: "5px 0",
  }),
  group: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: "5px 10px",
  }),
};

const GroupedPullDown: React.VFC<{
  selected: Option | undefined;
  groups: GroupedOption[];
  onChange: (option: Option) => void;
  noOptionsMessage?: string;
}> = ({ selected, groups, onChange, noOptionsMessage = null }): JSX.Element => {
  return (
    <Select
      components={{ DropdownIndicator }}
      value={selected}
      options={groups}
      formatGroupLabel={GroupLabel}
      onChange={(value) => {
        if (value) onChange(value);
      }}
      styles={customStyles}
      isSearchable={false}
      noOptionsMessage={(_) => noOptionsMessage}
    />
  );
};

export default GroupedPullDown;
