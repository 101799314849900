import Header from "organisms/account/Header";
import SideBar from "organisms/account/SideBar";
import TagModalView from "organisms/account/TagModalView";

import ServiceLogo from "atoms/ServiceLogo";
import LogOutButton from "atoms/accountPagesShared/LogOutButton";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import NotificateModal from "atoms/accountPagesShared/NotificateModal";
import TagModalTitle from "atoms/accountPagesShared/TagModalTitle";
import TagCopyBody from "atoms/accountPagesShared/TagCopyBody";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";

import { AccountPageContext } from "interfaces/view/accout";
import AccessStatisticsButton from "atoms/accountPagesShared/AccessStatisticsButton";
import AccessStatisticsView from "organisms/account/access_statistics/AccessStatisticsView";
import AccessStatisticsTables from "organisms/account/access_statistics/AccessStatisticsTables";
import AccessStatisticsCurrentPv from "organisms/account/access_statistics/AccessStatisticsCurrentPv";

type CreateAccountPageSharedElementsProps = {
  context: AccountPageContext;
  setModalElement: (e: JSX.Element | undefined) => void;
  render: (
    headerElement: JSX.Element,
    sideBarElement: JSX.Element
  ) => JSX.Element;
};

const CreateAccountPageSharedElements = ({
  context,
  setModalElement,
  render,
}: CreateAccountPageSharedElementsProps) => {
  const openDisplayTagModal = () => {
    setModalElement(
      <NotificateModal
        Contents={
          <TagModalView
            titleElement={<TagModalTitle />}
            bodyElement={
              <TagCopyBody
                tagData={
                  context.account !== undefined
                    ? context.account.tag_data
                    : "Loading..."
                }
              />
            }
            closeButton={
              <NegativeButton
                text="閉じる"
                onClick={() => setModalElement(undefined)}
              />
            }
          />
        }
        isOpen={true}
        onRequestClose={() => setModalElement(undefined)}
      />
    );
  };

  const openAccessStatisticsModal = () => {
    setModalElement(
      <NotificateModal
        Contents={
          <AccessStatisticsView
            titleElement={
              <AccessStatisticsCurrentPv accesses={context.accesses} />
            }
            accessStatisticsTableElement={
              <AccessStatisticsTables accesses={context.accesses} />
            }
            closeButtonElement={
              <NegativeButton
                text="閉じる"
                onClick={() => setModalElement(undefined)}
              />
            }
          />
        }
        isOpen={true}
        onRequestClose={() => setModalElement(undefined)}
      />
    );
  };

  const headerElement = (
    <Header
      logo={<ServiceLogo />}
      accountNameElement={
        context.account !== undefined ? (
          <p>{context.account.account_name} 様</p>
        ) : (
          <p>Loading...</p>
        )
      }
      signOutButtonElement={<LogOutButton onSignOut={context.onSignOut} />}
      accessStatisticsButton={
        <AccessStatisticsButton
          accesses={context.accesses}
          onClick={openAccessStatisticsModal}
        />
      }
      displayTagButton={
        <PositiveButton text="計測タグを表示" onClick={openDisplayTagModal} />
      }
    />
  );

  type LinkParameters = {
    label: string;
    link: string;
  };

  const links: LinkParameters[] = [
    { label: "成果レポート", link: "/reports" },
    { label: "おもてなしアクション", link: "/actions" },
    { label: "カスタマーセグメント", link: "/segmentation_rules" },
  ];

  const sideBarElement = <SideBar params={links} />;

  return render(headerElement, sideBarElement);
};

export default CreateAccountPageSharedElements;
