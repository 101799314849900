import {
  PushNotificationEditPageParameters,
  PushNotificationEditPageCallbacks,
} from "app/hooks/v2/pushNotification/usePushNotificationEditPage";

import styles from "./PushNotificationEditorPanel.module.scss";
import fontStyles from "fontStyles.module.scss";

import LoadingIcon from "atoms/LoadingIcon";
import TextInput from "atoms/TextInput";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";

import TitleForm from "molecules/v2/TitleForm";

import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import Panel from "templates/ezPush/Panel";
import PushNotificationContentsEditor from "./PushNotificationContentsEditor";
import { useMemo } from "react";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import GrayButton from "atoms/ezPushShared/GrayButton";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import ErrorMessages from "molecules/v2/ErrorMessages";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import PushNotificationDeliveryScheduleForm from "./PushNotificationDeliveryScheduleForm";
import VerticalTable from "atoms/ezPushShared/accountPageShared/VerticalTable";
import DeliveryScheduleRuleDescription from "molecules/v2/DeliveryScheduleRuleDescription";
import { DeliveryScheduleRule } from "types/delivery_schedule";
import { PUSH_NOTIFICATION_SCHEDULE_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";
import LoadingView from "organisms/account/LoadingView";
import { unixTimeToDateTimeWeekday } from "utils/format/unixtime";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";

const DraftSaveModal: React.VFC<{
  clearModalElement: () => void;
  save: () => void;
}> = ({ clearModalElement, save }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="プッシュ通知設定を下書きで保存します。よろしいですか？"
        description="配信日時は保持されませんので、ご注意ください。"
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={save} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

const ScheduledCampaignSaveModal: React.VFC<{
  deliveryScheduleRule: DeliveryScheduleRule | null;
  nextAt: number | null;
  clearModalElement: () => void;
  save: () => void;
}> = ({ deliveryScheduleRule, nextAt, clearModalElement, save }) => {
  const details: { table: JSX.Element; note: JSX.Element } = useMemo(() => {
    return {
      table:
        deliveryScheduleRule && nextAt ? (
          deliveryScheduleRule.schedule_type === "ONCE" ? (
            <VerticalTable
              items={[
                {
                  label: "配信設定",
                  value:
                    PUSH_NOTIFICATION_SCHEDULE_TYPE_DISPLAY_WORD[
                      deliveryScheduleRule.schedule_type
                    ],
                },
                {
                  label: "配信日時",
                  value: (
                    <div className={fontStyles.boldText}>
                      {unixTimeToDateTimeWeekday(nextAt)}
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <VerticalTable
              items={[
                {
                  label: "配信設定",
                  value:
                    PUSH_NOTIFICATION_SCHEDULE_TYPE_DISPLAY_WORD[
                      deliveryScheduleRule.schedule_type
                    ],
                },
                {
                  label: "配信サイクル",
                  value: deliveryScheduleRule ? (
                    <DeliveryScheduleRuleDescription
                      rule={deliveryScheduleRule}
                    />
                  ) : (
                    "-"
                  ),
                },
                {
                  label: "次回配信日時",
                  value: (
                    <div className={fontStyles.boldText}>
                      {unixTimeToDateTimeWeekday(nextAt)}
                    </div>
                  ),
                },
              ]}
            />
          )
        ) : (
          <LoadingView iconElement={<LoadingIcon />} />
        ),
      note:
        deliveryScheduleRule && nextAt ? (
          deliveryScheduleRule.schedule_type === "ONCE" ? (
            <MultipleLineText
              texts={[
                <span className={fontStyles.note}>
                  ※予約後に配信日時を変更する場合は、予約をキャンセルする必要があります。
                </span>,
              ]}
            />
          ) : (
            <MultipleLineText
              texts={[
                <span className={fontStyles.note}>
                  ※予約後に配信日時を変更する場合は、予約をキャンセルする必要があります。
                </span>,
                <span className={fontStyles.note}>
                  ※くりかえし設定の配信スケジュールは保存から1時間後以降に有効になるため、本日中に初回配信を行いたい場合はご注意ください。
                </span>,
              ]}
            />
          )
        ) : (
          <></>
        ),
    };
  }, [deliveryScheduleRule, nextAt]);

  return (
    <ModalBackground>
      <ElementModal
        title={"以下の配信日時でプッシュ通知設定を保存します。よろしいですか？"}
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={save} text="はい" />,
        ]}
        element={details.table}
        note={details.note}
      />
    </ModalBackground>
  );
};

const LoadingModal: React.VFC<{}> = () => {
  return (
    <ModalBackground>
      <NotificationModal
        title={""}
        description={<LoadingView iconElement={<LoadingIcon />} />}
        buttons={[]}
      />
    </ModalBackground>
  );
};

const CancelModal: React.VFC<{
  clearModalElement: () => void;
  backToHome: () => void;
}> = ({ clearModalElement, backToHome }) => (
  <ModalBackground>
    <NotificationModal
      title="プッシュ通知設定の作成をキャンセルします。よろしいですか？"
      description="入力内容は保持されませんので、ご注意ください。"
      buttons={[
        <TransparentButton text="いいえ" onClick={clearModalElement} />,
        <OrangeButton onClick={backToHome} text="はい" />,
      ]}
    />
  </ModalBackground>
);

const PushNotificationEditorPanel: React.VFC<{
  parameters: PushNotificationEditPageParameters;
  callbacks: PushNotificationEditPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const notificationTitleForm = useMemo(
    () => (
      <TitleForm
        title="管理名"
        mainElement={
          <TextInput
            value={parameters.data.name}
            onChange={(e) => callbacks.data.updateName(e.target.value)}
          />
        }
        notification="文字数上限：50文字"
        isRequired
      />
    ),
    [parameters.data.name, callbacks.data]
  );

  const destinationCollectRuleForm = useMemo(
    () => (
      <TitleForm
        title="セグメント指定"
        mainElement={
          parameters.destinationCollectRules ? (
            <BorderedSelectBox
              options={parameters.destinationCollectRules.map((rule) => {
                return {
                  label: rule.name,
                  value: rule.destination_collect_rule_uuid,
                };
              })}
              value={parameters.data.destinationCollectRuleUuid}
              onChange={(e) =>
                callbacks.data.updateDestinationCollectRuleUuid(e)
              }
            />
          ) : (
            <LoadingIcon />
          )
        }
        isRequired
      />
    ),
    [
      parameters.destinationCollectRules,
      parameters.data.destinationCollectRuleUuid,
      callbacks.data,
    ]
  );

  const contentsEditorForm = useMemo(
    () => (
      <PushNotificationContentsEditor
        contents={parameters.data.contents}
        contentsCallbacks={callbacks.data.contents}
        testNotificationParameters={
          parameters.events.testNotificationParameters
        }
        executeTestNotification={callbacks.events.testNotification}
        updateModalContents={callbacks.updateModalContents}
      />
    ),
    [
      parameters.data.contents,
      parameters.events.testNotificationParameters,
      callbacks.data.contents,
      callbacks.events.testNotification,
      callbacks.updateModalContents,
    ]
  );

  const deliveryScheduleForm = useMemo(
    () => (
      <TitleForm
        title="配信日時"
        mainElement={
          <PushNotificationDeliveryScheduleForm
            parameters={parameters.data.deliverySchedule}
            callbacks={callbacks.data.deliverySchedule}
          />
        }
      />
    ),
    [parameters.data.deliverySchedule, callbacks.data.deliverySchedule]
  );

  const buttonElements = useMemo(
    () => (
      <HorizontalLayout
        elements={[
          <TransparentButton
            text="キャンセル"
            onClick={() =>
              callbacks.updateModalContents(
                <CancelModal
                  clearModalElement={() => {
                    callbacks.updateModalContents(undefined);
                  }}
                  backToHome={() => {
                    callbacks.updateModalContents(undefined);
                    callbacks.events.cancel();
                  }}
                />
              )
            }
          />,
          <GrayButton
            text="下書き保存"
            onClick={() => {
              callbacks.events.canSave(false, null).then((isCanSave) => {
                if (isCanSave) {
                  callbacks.updateModalContents(
                    <DraftSaveModal
                      clearModalElement={() =>
                        callbacks.updateModalContents(undefined)
                      }
                      save={() => {
                        callbacks.events.save(false);
                        callbacks.updateModalContents(undefined);
                      }}
                    />
                  );
                } else {
                  callbacks.updateModalContents(undefined);
                }
              });
            }}
          />,
          <OrangeButton
            text="配信予約"
            onClick={() => {
              callbacks.updateModalContents(<LoadingModal />);
              callbacks.events
                .getCampaignNextAt()
                .then((nextAt) => {
                  callbacks.events.canSave(true, nextAt).then((isCanSave) => {
                    if (isCanSave) {
                      callbacks.updateModalContents(<LoadingModal />);
                      const rule =
                        callbacks.events.getRequestDeliveryScheduleRule();

                      if (rule) {
                        callbacks.updateModalContents(
                          <ScheduledCampaignSaveModal
                            nextAt={nextAt}
                            deliveryScheduleRule={rule}
                            clearModalElement={() =>
                              callbacks.updateModalContents(undefined)
                            }
                            save={() => {
                              callbacks.events.save(true);
                              callbacks.updateModalContents(undefined);
                            }}
                          />
                        );
                      }
                    } else {
                      callbacks.updateModalContents(undefined);
                    }
                  });
                })
                .catch(() => {
                  callbacks.updateModalContents(undefined);
                });
            }}
          />,
        ]}
      />
    ),
    [callbacks]
  );

  return (
    <div className={styles.pushNotificationEditorPanel}>
      {parameters.events.errorMessages.length > 0 ? (
        <div className={styles.errorMessages}>
          <ErrorMessages errors={parameters.events.errorMessages} />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.form}>
        <Panel
          itemElements={[
            <div className={styles.titleForm}>{notificationTitleForm}</div>,
            <div className={styles.ruleForm}>{destinationCollectRuleForm}</div>,
            contentsEditorForm,
            deliveryScheduleForm,
          ]}
        />
      </div>
      <div className={styles.buttons}>{buttonElements}</div>
    </div>
  );
};

export default PushNotificationEditorPanel;
