import {
  ActionContentsSetters,
  ActionCreationSettingsSegmentationRules,
} from "app/hooks/actionCreationPage";
import {
  ContentsType,
  PositionType,
  PresetContentsFormat,
  CustomContentsFormat,
  CustomContentsFormParameters,
  ActionCreationContentsStyleParameters,
  ActionCreationMode,
  CreativeParts,
} from "interfaces/model/actionContentsParameters";

import {
  ActionFrequency,
  ActionStatus,
  ActionTerminateReaction,
  ActionTypes,
  DecorationEditDeviceType,
  ExecuteTiming,
  ExpirationPeriod,
  LocationPattern,
  PathParameterPattern,
  SegmentationRule,
  UrlPattern,
} from "interfaces/models";
import { ColorType } from "molecules/GradationColorPicker/ColorTypeSelector";
import { ActionDetail2 } from "utils/ActionsRepository";

export const ACTION_OPERATION_MODE = {
  CREATE: "create",
  EDIT: "edit",
};

export type ActionOperationMode =
  typeof ACTION_OPERATION_MODE[keyof typeof ACTION_OPERATION_MODE];

export const ACTION_CREATION_PHASE = {
  MODE_SELECT: "mode_select",
  FORMAT_SELECT: "format_select",
  SETTINGS_EDIT: "settings_edit",
  COMPLETE: "complete",
} as const;

export type ActionCreationPhase =
  typeof ACTION_CREATION_PHASE[keyof typeof ACTION_CREATION_PHASE];

export interface ActionCreationSettingsParameters {
  name: string;
  status: ActionStatus;
  expirationPeriod: ExpirationPeriod | null;
  segmentationRuleIds: string[];
  frequency: ActionFrequency;
  terminateReaction: ActionTerminateReaction;
  cvUrl: string;
  abRate: number;
  html: string;
  previewHtml: string;
  actionType: ActionTypes;
  isExpirationPeriodEnabled: boolean;
  executeTimings: ExecuteTiming[];
}

export type ActionCreationSettingsContentsSetters = {
  setAbTestARate: (value: number) => void;
  setContentsType: (value: ContentsType) => void;
  setHtmlTemplate: (value: string) => void;
  setImage: (file: File | undefined) => void;
  details: ActionContentsSetters;
};

export type ActionCreationSettingsExecuteConditionsSetters = {
  setExpirationPeriod: (value: ExpirationPeriod | null) => void;
  setFrequency: (value: ActionFrequency) => void;
  setTerminateReaction: (value: ActionTerminateReaction) => void;
  setSegmentationRules: (
    value: ActionCreationSettingsSegmentationRules
  ) => void;
  urlConditionSetters: {
    setIncludes: (v: ManagementApiData.UrlMatchPattern[]) => void;
    setExcludes: (v: ManagementApiData.UrlMatchPattern[]) => void;
  };
  setExecuteTimings: (value: ExecuteTiming[]) => void;
};

export type ActionCreationSettingsSetters = {
  setName: (value: string) => void;
  setCvUrl: (value: string) => void;
  executeConditions: ActionCreationSettingsExecuteConditionsSetters;
  contents: ActionCreationSettingsContentsSetters;
};

export interface ActionCreationSettingsCallbacks {
  setName: (value: string) => void;
  setStatus: (value: ActionStatus) => void;
  setLocations: (value: string[]) => void;
  setExcludeLocations: (value: LocationPattern[]) => void;
  setExpirationPeriod: (value: ExpirationPeriod | null) => void;
  setSegmentationRuleIds: (value: string[]) => void;
  setFrequency: (value: ActionFrequency) => void;
  setTerminateReaction: (value: ActionTerminateReaction) => void;
  setCvUrl: (value: string) => void;
  setABRate: (value: number) => void;
  setHtml: (value: string) => void;
  setActionType: (value: ActionTypes) => void;
  setExpirationPeriodEnabled: (e: boolean) => void;
}

export interface ActionCreationContentsParameters {
  contentsType: ContentsType;
  positionType: PositionType;
  presetFormat: PresetContentsFormat;
  format: CustomContentsFormat;
  pattern: number;
  formatPatterns: CustomContentsFormParameters[];
  url?: string;
  template: string;
  image: File | undefined;
}

export interface ActionCreationContentsCallbacks {
  setEditingRawHtml: (value: boolean) => void;
  setPositionType: (value: PositionType) => void;
  setPresetFormat: (value: PresetContentsFormat) => void;
  setFormat: (value: CustomContentsFormat) => void;
  setPattern: (value: number) => void;
  setUrl: (value: string) => void;
  setTemplate: (value: string) => void;
  setIsEnablePCStyle: (value: boolean) => void;
}

export const SETTING_PHASE_CATEGORY = {
  VIEW: "view",
  CONDITION: "condition",
} as const;

export type SettingPhaseCategory =
  typeof SETTING_PHASE_CATEGORY[keyof typeof SETTING_PHASE_CATEGORY];

export const CONTENTS_SETTING_PHASE_CATEGORY = {
  CONDITION: "CONDITION",
} as const;

export type ContentsSettingPhaseCategory =
  typeof CONTENTS_SETTING_PHASE_CATEGORY[keyof typeof CONTENTS_SETTING_PHASE_CATEGORY];

export const DESIGN_SETTING_PHASE_CATEGORY = {
  LAYOUT: "LAYOUT",
  DECORATION: "DECORATION",
  SOURCE: "SOURCE",
} as const;

export type DesignSettingPhaseCategory =
  typeof DESIGN_SETTING_PHASE_CATEGORY[keyof typeof DESIGN_SETTING_PHASE_CATEGORY];

export interface ActionCreationSystemParameters {
  errorMessage?: Object;
  status: ActionStatus;
  modalType?: ActionCreationModalType;
  settingPhaseCategory: SettingPhaseCategory;
}

export type ActionCreationSystemCallbacks = {
  setErrorMessage: (value: Object) => void;
  setStatus: (value: ActionStatus) => void;
  setModalType: (value: ActionCreationModalType) => void;
  setSettingPhaseCategory: (value: SettingPhaseCategory) => void;
};

export type ActionCreationCustomParameters = {
  contentsSettingPhaseCategory: ContentsSettingPhaseCategory;
  designPhaseCategory: DesignSettingPhaseCategory;
  isEditRawHTML: boolean;
  isCompleteSettings: boolean;
};

export type ActionCreationCustomCallbacks = {
  setContentsSettingPhaseCategory: (
    value: ContentsSettingPhaseCategory
  ) => void;
  setDesignPhaseCategory: (value: DesignSettingPhaseCategory) => void;
  setIsEditRawHTML: (value: boolean) => void;
  setIsCompleteSettings: (value: boolean) => void;
};

export type ActionCreationPresetParameters = {
  numberOfExcludeLocation: number;
  isCompleteSettings: boolean;
  loginErrorCount: number;
  loginUrl: UrlPattern;
  numberOfLoginUrlParameter: number;
  continuousLoginErrorCondition: boolean;
};

export type ActionCreationPresetCallbacks = {
  setNumberOfExcludeLocation: (value: number) => void;
  setIsCompleteSettings: (value: boolean) => void;
  setLoginErrorCount: (value: number) => void;
  setLoginUrl: (value: UrlPattern) => void;
  setNumberOfLoginUrlParameter: (value: number) => void;
  setContinuousLoginErrorCondition: (value: boolean) => void;
};

export interface ActionCreationContentsStyleCallbacks {
  setBackgroundColor: (value: string) => void;
  setBorderColor: (value: string) => void;
  setSubHeadlineBackgroundColor: (value: string) => void;
  setSubHeadlineTextColor: (value: string) => void;
  setSubHeadlineText: (value: string) => void;
  setSubHeadlineTextSize: (value: number) => void;
  setSubHeadlineBackgroundRadius: (value: number) => void;
  setSubHeadlineBackgroundTopPadding: (value: number) => void;
  setSubHeadlineBackgroundBottomPadding: (value: number) => void;
  setSubHeadlineBackgroundRightPadding: (value: number) => void;
  setSubHeadlineBackgroundLeftPadding: (value: number) => void;
  setHeadlineTextColor: (value: string) => void;
  setHeadlineText: (value: string) => void;
  setHeadlineTextSize: (value: number) => void;
  setTextColor: (value: string) => void;
  setText: (value: string) => void;
  setTextSize: (value: number) => void;
  setButtonTextColor: (value: string) => void;
  setButtonText: (value: string) => void;
  setButtonLinkText: (value: string) => void;
  setButtonBorderRadius: (value: number) => void;
  setIsOpenOtherWindow: (value: boolean) => void;
  setButtonTopPadding: (value: number) => void;
  setButtonBottomPadding: (value: number) => void;
  setButtonRightPadding: (value: number) => void;
  setButtonLeftPadding: (value: number) => void;
  setIsButtonShadow: (value: boolean) => void;
  setButtonBackgroundGradationColor: (value: string[]) => void;
  setButtonColorType: (value: ColorType) => void;
  setCouponButtonColor: (value: string[]) => void;
  setCouponButtonRadius: (value: number) => void;
  setImageLinkText: (value: string) => void;
  setCouponCodeText: (value: string) => void;
  setCouponCodeTextSize: (value: number) => void;
  setCouponCodeBackgroundColor: (value: string) => void;
  setCouponCodeTextColor: (value: string) => void;
  setCouponButtonTextColor: (value: string) => void;
  setCouponButtonText: (value: string) => void;
  setCouponButtonTextSize: (value: number) => void;
  setCouponButtonTopPadding: (value: number) => void;
  setCouponButtonBottomPadding: (value: number) => void;
  setCouponButtonRightPadding: (value: number) => void;
  setCouponButtonLeftPadding: (value: number) => void;
  setCouponButtonShadow: (value: boolean) => void;
  setCouponButtonColorType: (value: ColorType) => void;
  setContentsBaseTopPadding: (value: number) => void;
  setContentsBaseBottomPadding: (value: number) => void;
  setContentsBaseRightPadding: (value: number) => void;
  setContentsBaseLeftPadding: (value: number) => void;
  setContentsBaseRadius: (value: number) => void;
  setContentsBaseWidth: (value: number) => void;
  setContentsBaseMaxHeight: (value: string) => void;
  setContentsBaseHeight: (value: number) => void;
  setContentsBaseBorderWidth: (value: number) => void;
  setContentsBaseHeightAdjust: (value: boolean) => void;
  setCloseButtonBackgroundColor: (value: string) => void;
  setCloseButtonCrossColor: (value: string) => void;
  setCloseButtonSize: (value: string) => void;
  setButtonTextSize: (value: number) => void;
  setImageMagnification: (value: number) => void;
}

export type ActionCreationContentsPCStyleCallbacks = {
  contentsBase: {
    background: {
      setWidth: (value: number) => void;
      setHeight: (value: number) => void;
      setMaxHeight: (value: string) => void;
      setHeightAdjust: (value: boolean) => void;
      padding: {
        setTop: (value: number) => void;
        setBottom: (value: number) => void;
        setRight: (value: number) => void;
        setLeft: (value: number) => void;
      };
    };
    border: {
      setRadius: (value: number) => void;
    };
  };
  headline: {
    text: {
      setSize: (value: number) => void;
    };
  };
  subHeadline: {
    background: {
      setRadius: (value: number) => void;
      padding: {
        setTop: (value: number) => void;
        setBottom: (value: number) => void;
        setRight: (value: number) => void;
        setLeft: (value: number) => void;
      };
    };
    text: {
      setSize: (value: number) => void;
    };
  };
  article: {
    setSize: (value: number) => void;
  };
  button: {
    background: {
      setRadius: (value: number) => void;
      padding: {
        setTop: (value: number) => void;
        setBottom: (value: number) => void;
        setRight: (value: number) => void;
        setLeft: (value: number) => void;
      };
    };
    text: {
      setSize: (value: number) => void;
    };
  };
  image: {
    setMagnification: (value: number) => void;
  };
  couponCode: {
    text: {
      setSize: (value: number) => void;
    };
  };
  couponCopyButton: {
    background: {
      setRadius: (value: number) => void;
      padding: {
        setTop: (value: number) => void;
        setBottom: (value: number) => void;
        setRight: (value: number) => void;
        setLeft: (value: number) => void;
      };
    };
    text: {
      setSize: (value: number) => void;
    };
  };
  closeButton: {
    setSize: (value: string) => void;
  };
};

export interface ActionCreationParameters {
  actionCreationSettings: ActionCreationSettingsParameters;
  actionCreationContents: ActionCreationContentsParameters;
  actionCreationContentsStyles: ActionCreationContentsStyleParameters;
}

export interface ActionCreationCallbacks {
  actionCreationSettings: ActionCreationSettingsCallbacks;
  actionCreationContents: ActionCreationContentsCallbacks;
  actionCreationContentsStyles: ActionCreationContentsStyleCallbacks;
}

export interface ActionCreationCustomFormCallbacks {
  commons: ActionCreationCommonCallbacks;
  systems: ActionCreationSystemCallbacks;
  mode: ActionCreationCustomCallbacks;
  settings: ActionCreationSettingsCallbacks;
  contents: ActionCreationContentsCallbacks;
  styles: ActionCreationContentsStyleCallbacks;
}

export type ActionCreationCustomFormParameters = {
  phase: ActionCreationPhase;
  mode: ActionCreationMode;
  modalType: ActionCreationModalType;
  segmentationRuleList: SegmentationRule[];
  createdAction: ActionDetail2 | undefined;
  systems: ActionCreationSystemParameters;
  customs: ActionCreationCustomParameters;
  settings: ActionCreationSettingsParameters;
  contents: ActionCreationContentsParameters;
  styles: ActionCreationContentsStyleParameters;
};

export interface ActionCreationPresetFormCallbacks {
  commons: ActionCreationCommonCallbacks;
  systems: ActionCreationSystemCallbacks;
  mode: ActionCreationPresetCallbacks;
  settings: ActionCreationSettingsCallbacks;
  contents: ActionCreationContentsCallbacks;
  styles: ActionCreationContentsStyleCallbacks;
}

export type ActionCreationPresetFormParameters = {
  phase: ActionCreationPhase;
  mode: ActionCreationMode;
  modalType: ActionCreationModalType | undefined;
  createdAction: ActionDetail2 | undefined;
  systems: ActionCreationSystemParameters;
  presets: ActionCreationPresetParameters;
  settings: ActionCreationSettingsParameters;
  contents: ActionCreationContentsParameters;
  styles: ActionCreationContentsStyleParameters;
};

export type ActionCreationCommonParameters = {
  phase: ActionCreationPhase;
  mode: ActionCreationMode | undefined;
};

export type ActionCreationCommonCallbacks = {
  moveToActionIndexPage: () => void;
  closeModal: () => void;
  cancel: () => void;
  submit: (active: boolean) => void;
  selectMode: (mode: ActionCreationMode) => void;
  resetMode: () => void;
  changePhase: (phase: ActionCreationPhase) => void;
  changeDecorationEditDeviceType: (type: DecorationEditDeviceType) => void;
  onSubmit: () => Promise<void>;
  onResetHTML: () => void;
  selectPresetFormat: (format: PresetContentsFormat) => void;
  selectCustomFormat: (format: CustomContentsFormat) => void;
  addSegumentationRule: () => void;
  deleteSegumentationRule: () => void;
  onChangeContentsAttribute: (
    contentsType: ContentsType,
    positionType: PositionType
  ) => void;
  requestResettingHtml: () => void;
  changeContentsImage: (image: File | undefined) => void;
  updateRawHtml: (source: string) => void;
  changeCategory: (category: DesignSettingPhaseCategory) => void;
  changeContentsSettingPhaseCategory: (
    category: ContentsSettingPhaseCategory
  ) => void;
  // Change form state
  addLoginUrlFrom: () => void;
  removeLoginUrlFrom: () => void;
  // actionSettings (preset only)
  updateLoginUrlLocation: (location: LocationPattern) => void;
  updateLoginUrlParameter: (
    index: number,
    queryParameter: PathParameterPattern
  ) => void;
  updateLoginErrorCountSettings: (count: number | undefined) => void;
  // actionSettings (custom only)
  setContentsType: (contentsType: ContentsType) => void;
  setPositionType: (positionType: PositionType) => void;
  setContentsPattern: (pattern: number) => void;
  setSegmentationRules: (ruleIds: string[]) => void;
  // actionSettings (both)
  updateIncludeLocation: (index: number, location: LocationPattern) => void;
  updateExcludeLocation: (index: number, location: LocationPattern) => void;
  setExpirationPeriod: (value?: { fromDate?: number; toDate?: number }) => void;
  removeUrlConditionIncludes: (index: number, maxLength: number) => void;
  removeUrlConditionExcludes: (index: number, maxLength: number) => void;
  setIncludes: (p: ManagementApiData.UrlMatchPattern[]) => void;
  setExcludes: (p: ManagementApiData.UrlMatchPattern[]) => void;
  resetUrlConditionIncludes: () => void;
  resetUrlConditionExcludes: () => void;
  setExecuteTiming: (
    isExecuteTimingsActive: boolean,
    executeTimings: ExecuteTiming[]
  ) => void;
  deleteExecuteTimings: (index: number) => void;
  insertExecuteTimings: () => void;
  setExecuteTimingFromHourValue: (index: number, value: number) => void;
  setExecuteTimingFromTimeValue: (index: number, value: number) => void;
  setExecuteTimingToHourValue: (index: number, value: number) => void;
  setExecuteTimingToTimeValue: (index: number, value: number) => void;
  setExecuteTimingDayOfWeek: (index: number, label: string) => void;
  setExecuteTimingHoliday: (index: number) => void;
  getExecuteTimingHolidayState: (index: number) => boolean;
  getExecuteTimingDayOfWeekState: (index: number, value: string) => boolean;
  onActivePCStyle: () => void;
};

export type PresetContentsFormParameters = {
  parts: CreativeParts[];
};

export const ACTION_CREATION_MODAL_TYPE = {
  IS_SUBMIT: "is_submit",
  IS_RESET: "is_reset",
  IS_CANCEL: "is_cancel",
} as const;

export type ActionCreationModalType =
  typeof ACTION_CREATION_MODAL_TYPE[keyof typeof ACTION_CREATION_MODAL_TYPE];
