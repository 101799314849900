import NotificateModalView from "organisms/account/NotificateModalView";

interface TagModalViewProps {
  titleElement: JSX.Element;
  bodyElement: JSX.Element;
  closeButton: JSX.Element;
}

const TagModalView = ({
  titleElement,
  bodyElement,
  closeButton,
}: TagModalViewProps) => {
  return (
    <NotificateModalView
      title={titleElement}
      description={bodyElement}
      buttons={closeButton}
    />
  );
};

export default TagModalView;
