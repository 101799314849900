import {
  CURRENT_PAGE_BEHAVIOR_ACCESS_BLANK_LABEL,
  CURRENT_PAGE_BEHAVIOR_PARAMETER_LABEL,
  CURRENT_PAGE_BEHAVIOR_SCROLL_RATE_LABEL,
  CURRENT_PAGE_BEHAVIOR_STAYING_TIME_LABEL,
  CURRENT_PAGE_BEHAVIOR_TYPE,
  CURRENT_PAGE_BEHAVIOR_URL_LABEL,
  CUSTOM_DATE_LABEL,
  CUSTOM_INTEGER_ITEM_LABEL,
  CUSTOM_WORD_ITEM_LABEL,
  DATA_TYPE,
  PAST_BEHAVIOR_ALL_PV_COUNT_LABEL,
  PAST_BEHAVIOR_PV_COUNT_LABEL,
  PAST_BEHAVIOR_PV_COUNT_PARAMETER_LABEL,
  PAST_BEHAVIOR_PV_COUNT_URL_LABEL,
  PAST_BEHAVIOR_TYPE,
  SegmentationRuleCreation,
  SEGMENTATION_NAME_LABEL,
  SESSION_BEHAVIOR_ALL_PV_COUNT_LABEL,
  SESSION_BEHAVIOR_CONTINUOUS_PV_LABEL,
  SESSION_BEHAVIOR_CONTINUOUS_PV_PARAMETER_LABEL,
  SESSION_BEHAVIOR_CONTINUOUS_PV_URL_LABEL,
  SESSION_BEHAVIOR_PV_COUNT_LABEL,
  SESSION_BEHAVIOR_PV_COUNT_PARAMETER_LABEL,
  SESSION_BEHAVIOR_PV_COUNT_URL_LABEL,
  SESSION_BEHAVIOR_STAYING_TIME_LABEL,
  SESSION_BEHAVIOR_TYPE,
} from "interfaces/model/segmentationRuleCreation";

const DESCRIPTION_LABELS = {
  CURRENT_PAGE: {
    [`${CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE}`]:
      CURRENT_PAGE_BEHAVIOR_SCROLL_RATE_LABEL,
    [`${CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME}`]:
      CURRENT_PAGE_BEHAVIOR_STAYING_TIME_LABEL,
    [`${CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK}`]:
      CURRENT_PAGE_BEHAVIOR_ACCESS_BLANK_LABEL,
  },
  SESSION: {
    [`${SESSION_BEHAVIOR_TYPE.PV_COUNT}`]: SESSION_BEHAVIOR_PV_COUNT_LABEL,
    [`${SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV}`]:
      SESSION_BEHAVIOR_CONTINUOUS_PV_LABEL,
    [`${SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT}`]:
      SESSION_BEHAVIOR_ALL_PV_COUNT_LABEL,
    [`${SESSION_BEHAVIOR_TYPE.STAYING_TIME}`]:
      SESSION_BEHAVIOR_STAYING_TIME_LABEL,
  },
  PAST: {
    [`${PAST_BEHAVIOR_TYPE.PV_COUNT}`]: PAST_BEHAVIOR_PV_COUNT_LABEL,
    [`${PAST_BEHAVIOR_TYPE.ALL_PV_COUNT}`]: PAST_BEHAVIOR_ALL_PV_COUNT_LABEL,
  },
  CUSTOMER: {
    [`${DATA_TYPE.INTEGER}`]: CUSTOM_INTEGER_ITEM_LABEL,
    [`${DATA_TYPE.WORD}`]: CUSTOM_WORD_ITEM_LABEL,
    [`${DATA_TYPE.DATE}`]: CUSTOM_DATE_LABEL,
  },
};

const DESCRIPTION_URL_LABELS = {
  CURRENT_PAGE: {
    LOCATION: CURRENT_PAGE_BEHAVIOR_URL_LABEL,
    PARAMETERS: CURRENT_PAGE_BEHAVIOR_PARAMETER_LABEL,
  },
  SESSION: {
    [`${SESSION_BEHAVIOR_TYPE.PV_COUNT}`]: {
      LOCATION: SESSION_BEHAVIOR_PV_COUNT_URL_LABEL,
      PARAMETERS: SESSION_BEHAVIOR_PV_COUNT_PARAMETER_LABEL,
    },
    [`${SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV}`]: {
      LOCATION: SESSION_BEHAVIOR_CONTINUOUS_PV_URL_LABEL,
      PARAMETERS: SESSION_BEHAVIOR_CONTINUOUS_PV_PARAMETER_LABEL,
    },
  },
  PAST: {
    [`${PAST_BEHAVIOR_TYPE.PV_COUNT}`]: {
      LOCATION: PAST_BEHAVIOR_PV_COUNT_URL_LABEL,
      PARAMETERS: PAST_BEHAVIOR_PV_COUNT_PARAMETER_LABEL,
    },
  },
};

type Limitation = {
  MIN: number | undefined;
  MAX: number | undefined;
};

const NO_NUMBER_LIMITATION: Limitation = {
  MIN: undefined,
  MAX: undefined,
};

const DAYS_NUMBER_LIMITATION: Limitation = {
  MIN: 1,
  MAX: 730,
};

const RATE_NUMBER_LIMITATION: Limitation = {
  MIN: 1,
  MAX: 100,
};

const SECOND_NUMBER_LIMITATION: Limitation = {
  MIN: 1,
  MAX: 1800,
};

const WORD_NUMBER_LIMITATION: Limitation = {
  MIN: undefined,
  MAX: 3000,
};

const INTEGER_NUMBER_LIMITATION: Limitation = {
  MIN: -10000000,
  MAX: 10000000,
};

const PV_NUMBER_LIMITATION: Limitation = {
  MIN: 0,
  MAX: 10000000,
};

const STAYING_TIME_NUMBER_LIMITATION: Limitation = {
  MIN: 0,
  MAX: 10000000,
};

const NUMBER_LIMITATIONS = {
  CURRENT_PAGE: {
    [CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE]: RATE_NUMBER_LIMITATION,
    [CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME]: SECOND_NUMBER_LIMITATION,
    [CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK]: DAYS_NUMBER_LIMITATION,
    [CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED]: NO_NUMBER_LIMITATION,
  },
  SESSION: {
    [SESSION_BEHAVIOR_TYPE.PV_COUNT]: PV_NUMBER_LIMITATION,
    [SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV]: PV_NUMBER_LIMITATION,
    [SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT]: PV_NUMBER_LIMITATION,
    [SESSION_BEHAVIOR_TYPE.STAYING_TIME]: STAYING_TIME_NUMBER_LIMITATION,
    [SESSION_BEHAVIOR_TYPE.NOT_SELECTED]: NO_NUMBER_LIMITATION,
  },
  PAST: {
    [PAST_BEHAVIOR_TYPE.PV_COUNT]: PV_NUMBER_LIMITATION,
    [PAST_BEHAVIOR_TYPE.ALL_PV_COUNT]: PV_NUMBER_LIMITATION,
    [PAST_BEHAVIOR_TYPE.NOT_SELECTED]: NO_NUMBER_LIMITATION,
  },
  CUSTOMER: {
    [DATA_TYPE.INTEGER]: INTEGER_NUMBER_LIMITATION,
    [DATA_TYPE.WORD]: WORD_NUMBER_LIMITATION,
    [DATA_TYPE.DATE]: NO_NUMBER_LIMITATION,
  },
};

const WORD_MAX_LENGTH = 3000;

export class NumberValidator {
  static isInValueRange(
    value: number,
    minimum?: number,
    maximum?: number
  ): boolean {
    const isOverMinimum = minimum !== undefined ? value >= minimum : true;
    const isUnderMaximum = maximum !== undefined ? value <= maximum : true;

    return isOverMinimum && isUnderMaximum;
  }
}

export class WordValidator {
  static isInLengthRange(
    word: string,
    minimum?: number,
    maximum?: number
  ): boolean {
    const isOverMinimum = minimum !== undefined ? word.length >= minimum : true;
    const isUnderMaximum =
      maximum !== undefined ? word.length <= maximum : true;
    return isOverMinimum && isUnderMaximum;
  }

  static isEmpty(word: string | undefined): boolean {
    return word ? word.length === 0 : true;
  }
}

export class UrlValidator {
  static isValidLocationFormat(location?: string): boolean {
    const re = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/i;
    return location ? re.test(location) : true;
  }

  static isValidParameterFormat(parameter?: string): boolean {
    const re = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/i;
    return parameter ? re.test(parameter) : true;
  }

  static isEmptyParameter(key: string, value: string | undefined): boolean {
    const isEmptyKey = key.length === 0;
    const isEmptyValue = value !== undefined ? value.length === 0 : false;

    if (isEmptyKey && isEmptyValue) {
      // NOTE: "="が入っているパターン
      return true;
    } else if (!isEmptyKey && isEmptyValue) {
      // NOTE: "test_key="が入ってるパターン
      return false;
    } else if (isEmptyKey && !isEmptyValue) {
      // NOTE: "=test_value"が入ってるパターン
      return true;
    } else {
      // NOTE: "test_key=test_value"が入ってるパターン
      return false;
    }
  }

  static isEmpty(location: string[], parameters: string[]): boolean {
    const isExistLocation: boolean =
      location.length > 0 ? location.every((e) => e.length > 0) : false;
    const isExistParameters: boolean =
      parameters.length > 0 ? parameters.every((e) => e.length > 0) : false;

    return !(isExistLocation || isExistParameters);
  }
}

class CurrentPageBehaviorValidator {
  urlUsingCurrentPageBehaviorTypes: SegmentationRuleCreation.CurrentPageBehaviorType[] =
    [
      CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE,
      CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME,
    ];

  getDuplicateErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const duplicateCheckTargets = [
      CURRENT_PAGE_BEHAVIOR_TYPE.SCROLL_RATE,
      CURRENT_PAGE_BEHAVIOR_TYPE.STAYING_TIME,
      CURRENT_PAGE_BEHAVIOR_TYPE.ACCESS_BLANK,
    ];

    duplicateCheckTargets.forEach((type) => {
      if (
        currentPageBehaviors.filter((e) => e.behaviorType === type).length > 1
      ) {
        errorMessages.push(
          `${DESCRIPTION_LABELS.CURRENT_PAGE[type]}の条件を2つ以上指定することはできません。`
        );
      }
    });

    return errorMessages;
  }

  getNumberLimitationErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    currentPageBehaviors.forEach((behavior) => {
      const limitation = NUMBER_LIMITATIONS.CURRENT_PAGE[behavior.behaviorType];
      if (
        !NumberValidator.isInValueRange(
          behavior.value,
          limitation.MIN,
          limitation.MAX
        )
      ) {
        const tmp = `${
          DESCRIPTION_LABELS.CURRENT_PAGE[behavior.behaviorType]
        }は${limitation.MIN}から${
          limitation.MAX
        }までの整数で入力してください。`;
        if (!errorMessages.includes(tmp)) {
          errorMessages.push(tmp);
        }
      }
    });

    return errorMessages;
  }

  getUrlEmptyErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const urlUsingBehaviors = currentPageBehaviors.filter((e) =>
      this.urlUsingCurrentPageBehaviorTypes.includes(e.behaviorType)
    );

    const isEmptyUrl: boolean = urlUsingBehaviors.some((behavior) => {
      if (behavior.url) {
        return UrlValidator.isEmpty(
          behavior.url.location.map((e) => e.value),
          behavior.url.parameters.map((e) => e.key)
        );
      } else {
        return false;
      }
    });
    if (isEmptyUrl) {
      errorMessages.push(
        `${CURRENT_PAGE_BEHAVIOR_URL_LABEL}を入力してください。`
      );
    }
    return errorMessages;
  }

  getUrlParameterEmptyErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const urlUsingBehaviors = currentPageBehaviors.filter((e) =>
      this.urlUsingCurrentPageBehaviorTypes.includes(e.behaviorType)
    );

    const isEmptyUrlParameter: boolean = urlUsingBehaviors.some((behavior) => {
      if (behavior.url) {
        return behavior.url.parameters.some((param) =>
          UrlValidator.isEmptyParameter(param.key, param.value)
        );
      } else {
        return false;
      }
    });
    if (isEmptyUrlParameter) {
      errorMessages.push(
        `${CURRENT_PAGE_BEHAVIOR_PARAMETER_LABEL}のパラメータは [パラメータ名]=[値] の形式で入力してください。`
      );
    }
    return errorMessages;
  }

  getUrlLocationLengthRangeErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const urlUsingBehaviors = currentPageBehaviors.filter((e) =>
      this.urlUsingCurrentPageBehaviorTypes.includes(e.behaviorType)
    );
    const isValidLocationLengthRange = urlUsingBehaviors.every((behavior) => {
      return behavior.url
        ? behavior.url.location.every((e) =>
            WordValidator.isInLengthRange(e.value, undefined, WORD_MAX_LENGTH)
          )
        : true;
    });

    if (!isValidLocationLengthRange) {
      errorMessages.push(
        `${CURRENT_PAGE_BEHAVIOR_URL_LABEL}は${WORD_MAX_LENGTH}文字以内で入力してください。`
      );
    }

    return errorMessages;
  }

  getUrlParameterLengthRangeErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const urlUsingBehaviors = currentPageBehaviors.filter((e) =>
      this.urlUsingCurrentPageBehaviorTypes.includes(e.behaviorType)
    );

    const isValidParameterLengthRange = urlUsingBehaviors.every((behavior) => {
      return behavior.url
        ? behavior.url.parameters.every((e) =>
            WordValidator.isInLengthRange(
              `${e.key}=${e.value}`,
              undefined,
              WORD_MAX_LENGTH
            )
          )
        : true;
    });

    if (!isValidParameterLengthRange) {
      errorMessages.push(
        `${CURRENT_PAGE_BEHAVIOR_PARAMETER_LABEL}は${WORD_MAX_LENGTH}文字以内で入力してください。`
      );
    }

    return errorMessages;
  }
  getUrlLocationFormatErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const urlUsingBehaviors = currentPageBehaviors.filter((e) =>
      this.urlUsingCurrentPageBehaviorTypes.includes(e.behaviorType)
    );

    const isValidLocationFormat = urlUsingBehaviors.every((behavior) => {
      return behavior.url
        ? behavior.url.location.every((e) =>
            UrlValidator.isValidLocationFormat(e.value)
          )
        : true;
    });

    if (!isValidLocationFormat) {
      errorMessages.push(
        `${CURRENT_PAGE_BEHAVIOR_URL_LABEL}は半角英数文字と半角記号のみを使用して入力してください。`
      );
    }

    return errorMessages;
  }

  getUrlParameterFormatErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    const urlUsingBehaviors = currentPageBehaviors.filter((e) =>
      this.urlUsingCurrentPageBehaviorTypes.includes(e.behaviorType)
    );

    const isValidParameterFormat = urlUsingBehaviors.every((behavior) => {
      return behavior.url
        ? behavior.url.parameters.every((e) =>
            UrlValidator.isValidParameterFormat(`${e.key}=${e.value}`)
          )
        : true;
    });

    if (!isValidParameterFormat) {
      errorMessages.push(
        `${CURRENT_PAGE_BEHAVIOR_PARAMETER_LABEL}は半角英数文字と半角記号のみを使用して入力してください。`
      );
    }

    return errorMessages;
  }
}

class SessionBehaviorValidator {
  urlUsingSessionBehaviorTypes: SegmentationRuleCreation.SessionBehaviorType[] =
    [SESSION_BEHAVIOR_TYPE.PV_COUNT, SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV];

  getDuplicateErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: 項目重複チェック
    const duplicateCheckTargets = [
      SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV,
      SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT,
      SESSION_BEHAVIOR_TYPE.STAYING_TIME,
    ];

    duplicateCheckTargets.forEach((type) => {
      if (sessionBehaviors.filter((e) => e.behaviorType === type).length > 1) {
        errorMessages.push(
          `${DESCRIPTION_LABELS.SESSION[type]}の条件を2つ以上指定することはできません。`
        );
      }
    });

    return errorMessages;
  }

  getNumberLimitationErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: 数値桁数チェック
    sessionBehaviors.forEach((behavior) => {
      const limitation = NUMBER_LIMITATIONS.SESSION[behavior.behaviorType];
      if (
        !NumberValidator.isInValueRange(
          behavior.value,
          limitation.MIN,
          limitation.MAX
        )
      ) {
        const tmp = `${DESCRIPTION_LABELS.SESSION[behavior.behaviorType]}は${
          limitation.MIN
        }から${limitation.MAX}までの整数で入力してください。`;
        if (!errorMessages.includes(tmp)) {
          errorMessages.push(tmp);
        }
      }
    });

    return errorMessages;
  }

  getUrlEmptyErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    this.urlUsingSessionBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          if (
            UrlValidator.isEmpty(
              behavior.url.location.map((e) => e.value),
              behavior.url.parameters.map((e) => e.key)
            )
          ) {
            const tmp = `${DESCRIPTION_URL_LABELS.SESSION[type].LOCATION}を入力してください。`;
            if (!errorMessages.includes(tmp)) {
              errorMessages.push(tmp);
            }
          }
        });
    });
    return errorMessages;
  }

  getUrlParameterEmptyErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    this.urlUsingSessionBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.parameters.forEach((param) => {
            if (UrlValidator.isEmptyParameter(param.key, param.value)) {
              const tmp = `${DESCRIPTION_URL_LABELS.SESSION[type].PARAMETERS}のパラメータは [パラメータ名]=[値] の形式で入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }

  getUrlLocationLengthRangeErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLロケーション文字数桁数チェック
    this.urlUsingSessionBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.location.forEach((l) => {
            if (
              !WordValidator.isInLengthRange(
                l.value,
                undefined,
                WORD_MAX_LENGTH
              )
            ) {
              const tmp = `${DESCRIPTION_URL_LABELS.SESSION[type].LOCATION}は${WORD_MAX_LENGTH}文字以内で入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }

  getUrlParameterLengthRangeErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLパラメータ文字数桁数チェック
    this.urlUsingSessionBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.parameters.forEach((param) => {
            if (
              !WordValidator.isInLengthRange(
                `${param.key}=${param.value}`,
                undefined,
                WORD_MAX_LENGTH
              )
            ) {
              const tmp = `${DESCRIPTION_URL_LABELS.SESSION[type].PARAMETERS}は${WORD_MAX_LENGTH}文字以内で入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }
  getUrlLocationFormatErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLロケーションフォーマットチェック
    this.urlUsingSessionBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.location.forEach((l) => {
            if (!UrlValidator.isValidLocationFormat(l.value)) {
              const tmp = `${DESCRIPTION_URL_LABELS.SESSION[type].LOCATION}は半角英数文字と半角記号のみを使用して入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }

  getUrlParameterFormatErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLパラメータフォーマットチェック
    this.urlUsingSessionBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.parameters.forEach((param) => {
            if (
              !UrlValidator.isValidParameterFormat(
                `${param.key}=${param.value}`
              )
            ) {
              const tmp = `${DESCRIPTION_URL_LABELS.SESSION[type].PARAMETERS}は半角英数文字と半角記号のみを使用して入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }
}

class PastBehaviorValidator {
  urlUsingPastBehaviorTypes: SegmentationRuleCreation.PastBehaviorType[] = [
    PAST_BEHAVIOR_TYPE.PV_COUNT,
  ];

  getDuplicateErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: 項目重複チェック
    const duplicateCheckTargets = [PAST_BEHAVIOR_TYPE.ALL_PV_COUNT];

    duplicateCheckTargets.forEach((type) => {
      if (pastBehaviors.filter((e) => e.behaviorType === type).length > 1) {
        errorMessages.push(
          `${DESCRIPTION_LABELS.PAST[type]}の条件を2つ以上指定することはできません。`
        );
      }
    });

    return errorMessages;
  }

  getNumberLimitationErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: 数値桁数チェック
    pastBehaviors.forEach((behavior) => {
      const limitation = NUMBER_LIMITATIONS.PAST[behavior.behaviorType];
      if (
        !NumberValidator.isInValueRange(
          behavior.value,
          limitation.MIN,
          limitation.MAX
        )
      ) {
        const tmp = `${DESCRIPTION_LABELS.PAST[behavior.behaviorType]}は${
          limitation.MIN
        }から${limitation.MAX}までの整数で入力してください。`;
        if (!errorMessages.includes(tmp)) {
          errorMessages.push(tmp);
        }
      }
    });

    return errorMessages;
  }

  getDaysRangeNumberLimitationErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    pastBehaviors.forEach((behavior) => {
      const limitation = DAYS_NUMBER_LIMITATION;
      if (
        !NumberValidator.isInValueRange(
          behavior.rangeDays,
          limitation.MIN,
          limitation.MAX
        )
      ) {
        const tmp = `${
          DESCRIPTION_LABELS.PAST[behavior.behaviorType]
        }の過去日数は${limitation.MIN}から${
          limitation.MAX
        }までの整数で入力してください。`;
        if (!errorMessages.includes(tmp)) {
          errorMessages.push(tmp);
        }
      }
    });

    return errorMessages;
  }

  getUrlEmptyErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URL存在チェック。URLが必要な行動にURLがあるかチェックする。
    this.urlUsingPastBehaviorTypes.forEach((type) => {
      pastBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          if (
            UrlValidator.isEmpty(
              behavior.url.location.map((e) => e.value),
              behavior.url.parameters.map((e) => e.key)
            )
          ) {
            const tmp = `${DESCRIPTION_URL_LABELS.PAST[type].LOCATION}を入力してください。`;
            if (!errorMessages.includes(tmp)) {
              errorMessages.push(tmp);
            }
          }
        });
    });
    return errorMessages;
  }

  getUrlParameterEmptyErrors(
    sessionBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    this.urlUsingPastBehaviorTypes.forEach((type) => {
      sessionBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.parameters.forEach((param) => {
            if (UrlValidator.isEmptyParameter(param.key, param.value)) {
              const tmp = `${DESCRIPTION_URL_LABELS.PAST[type].PARAMETERS}のパラメータは [パラメータ名]=[値] の形式で入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }

  getUrlLocationLengthRangeErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLロケーション文字数桁数チェック
    this.urlUsingPastBehaviorTypes.forEach((type) => {
      pastBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.location.forEach((l) => {
            if (
              !WordValidator.isInLengthRange(
                l.value,
                undefined,
                WORD_MAX_LENGTH
              )
            ) {
              const tmp = `${DESCRIPTION_URL_LABELS.PAST[type].LOCATION}は${WORD_MAX_LENGTH}文字以内で入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }

  getUrlParameterLengthRangeErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLパラメータ文字数桁数チェック
    this.urlUsingPastBehaviorTypes.forEach((type) => {
      pastBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.parameters.forEach((param) => {
            if (
              !WordValidator.isInLengthRange(
                `${param.key}=${param.value}`,
                undefined,
                WORD_MAX_LENGTH
              )
            ) {
              const tmp = `${DESCRIPTION_URL_LABELS.PAST[type].PARAMETERS}は${WORD_MAX_LENGTH}文字以内で入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }
  getUrlLocationFormatErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLロケーションフォーマットチェック
    this.urlUsingPastBehaviorTypes.forEach((type) => {
      pastBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.location.forEach((l) => {
            if (!UrlValidator.isValidLocationFormat(l.value)) {
              const tmp = `${DESCRIPTION_URL_LABELS.PAST[type].LOCATION}は半角英数文字と半角記号のみを使用して入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }

  getUrlParameterFormatErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const errorMessages: string[] = [];

    // NOTE: URLパラメータフォーマットチェック
    this.urlUsingPastBehaviorTypes.forEach((type) => {
      pastBehaviors
        .filter((e) => e.behaviorType === type)
        .forEach((behavior) => {
          behavior.url.parameters.forEach((param) => {
            if (
              !UrlValidator.isValidParameterFormat(
                `${param.key}=${param.value}`
              )
            ) {
              const tmp = `${DESCRIPTION_URL_LABELS.PAST[type].PARAMETERS}は半角英数文字と半角記号のみを使用して入力してください。`;
              if (!errorMessages.includes(tmp)) {
                errorMessages.push(tmp);
              }
            }
          });
        });
    });

    return errorMessages;
  }
}

class CustomerAttributeValidator {
  getNumberLimitationErrors(
    customers: SegmentationRuleCreation.CustomerAttribute[]
  ): string[] {
    const errorMessages: string[] = [];

    customers
      .filter((attribute) => attribute.operation.type === DATA_TYPE.INTEGER)
      .forEach((data) => {
        const operation =
          data.operation as SegmentationRuleCreation.IntegerCompareOperation;

        const limitation = NUMBER_LIMITATIONS.CUSTOMER[operation.type];
        if (
          !NumberValidator.isInValueRange(
            operation.value,
            limitation.MIN,
            limitation.MAX
          )
        ) {
          const tmp = `${DESCRIPTION_LABELS.CUSTOMER[operation.type]}は${
            limitation.MIN
          }から${limitation.MAX}までの整数で入力してください。`;
          if (!errorMessages.includes(tmp)) {
            errorMessages.push(tmp);
          }
        }
      });

    return errorMessages;
  }

  getWordEmptyErrors(
    customers: SegmentationRuleCreation.CustomerAttribute[]
  ): string[] {
    const errorMessages: string[] = [];

    customers
      .filter((attribute) => attribute.operation.type === DATA_TYPE.WORD)
      .forEach((data) => {
        const operation =
          data.operation as SegmentationRuleCreation.WordCompareOperation;
        if (WordValidator.isEmpty(operation.value)) {
          const tmp = `${
            DESCRIPTION_LABELS.CUSTOMER[operation.type]
          }を入力してください。`;
          if (!errorMessages.includes(tmp)) {
            errorMessages.push(tmp);
          }
        }
      });

    return errorMessages;
  }

  getWordLengthRangeErrors(
    customers: SegmentationRuleCreation.CustomerAttribute[]
  ): string[] {
    const errorMessages: string[] = [];

    customers
      .filter((attribute) => attribute.operation.type === DATA_TYPE.WORD)
      .forEach((data) => {
        const operation =
          data.operation as SegmentationRuleCreation.WordCompareOperation;
        if (
          !WordValidator.isInLengthRange(
            operation.value,
            undefined,
            WORD_MAX_LENGTH
          )
        ) {
          const tmp = `${
            DESCRIPTION_LABELS.CUSTOMER[operation.type]
          }は${WORD_MAX_LENGTH}文字以内で入力してください。`;
          if (!errorMessages.includes(tmp)) {
            errorMessages.push(tmp);
          }
        }
      });

    return errorMessages;
  }
}

export class SegmentationRuleCreationValidator {
  static getNameErrors(
    segmentationRuleNames: string[],
    currentName: string | undefined,
    initName: string | undefined
  ): string[] {
    const errorMessages: string[] = [];
    if (currentName) {
      if (
        segmentationRuleNames
          .filter((name) => name !== initName)
          .includes(currentName)
      ) {
        errorMessages.push(
          `すでに登録されている${SEGMENTATION_NAME_LABEL}です。別の${SEGMENTATION_NAME_LABEL}を入力してください。`
        );
      }

      if (
        !NumberValidator.isInValueRange(
          currentName.length,
          undefined,
          WORD_MAX_LENGTH
        )
      ) {
        errorMessages.push(
          `${SEGMENTATION_NAME_LABEL}は${WORD_MAX_LENGTH}文字以内で入力してください。`
        );
      }
    } else {
      errorMessages.push(`${SEGMENTATION_NAME_LABEL}を入力してください。`);
    }

    return errorMessages;
  }

  static getCurrentPageErrors(
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ): string[] {
    const validator = new CurrentPageBehaviorValidator();

    return [
      ...validator.getDuplicateErrors(currentPageBehaviors),
      ...validator.getNumberLimitationErrors(currentPageBehaviors),
      ...validator.getUrlEmptyErrors(currentPageBehaviors),
      ...validator.getUrlParameterEmptyErrors(currentPageBehaviors),
      ...validator.getUrlLocationLengthRangeErrors(currentPageBehaviors),
      ...validator.getUrlParameterLengthRangeErrors(currentPageBehaviors),
      ...validator.getUrlLocationFormatErrors(currentPageBehaviors),
      ...validator.getUrlParameterFormatErrors(currentPageBehaviors),
    ];
  }

  static getSessionErrors(
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ): string[] {
    const validator = new SessionBehaviorValidator();

    return [
      ...validator.getDuplicateErrors(sessionBehaviors),
      ...validator.getNumberLimitationErrors(sessionBehaviors),
      ...validator.getUrlEmptyErrors(sessionBehaviors),
      ...validator.getUrlParameterEmptyErrors(sessionBehaviors),
      ...validator.getUrlLocationLengthRangeErrors(sessionBehaviors),
      ...validator.getUrlParameterLengthRangeErrors(sessionBehaviors),
      ...validator.getUrlLocationFormatErrors(sessionBehaviors),
      ...validator.getUrlParameterFormatErrors(sessionBehaviors),
    ];
  }

  static getPastErrors(
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ): string[] {
    const validator = new PastBehaviorValidator();

    return [
      ...validator.getDuplicateErrors(pastBehaviors),
      ...validator.getNumberLimitationErrors(pastBehaviors),
      ...validator.getDaysRangeNumberLimitationErrors(pastBehaviors),
      ...validator.getUrlEmptyErrors(pastBehaviors),
      ...validator.getUrlParameterEmptyErrors(pastBehaviors),
      ...validator.getUrlLocationLengthRangeErrors(pastBehaviors),
      ...validator.getUrlParameterLengthRangeErrors(pastBehaviors),
      ...validator.getUrlLocationFormatErrors(pastBehaviors),
      ...validator.getUrlParameterFormatErrors(pastBehaviors),
    ];
  }

  static getCustomerAttributesErrors(
    customers: SegmentationRuleCreation.CustomerAttribute[]
  ): string[] {
    const validator = new CustomerAttributeValidator();

    return [
      ...validator.getNumberLimitationErrors(customers),
      ...validator.getWordEmptyErrors(customers),
      ...validator.getWordLengthRangeErrors(customers),
    ];
  }

  static getErrors(
    segmentationRuleNames: string[],
    appState: SegmentationRuleCreation.SegmentationRuleCreation,
    initName: string | undefined
  ): string[] {
    const nameErrors = this.getNameErrors(
      segmentationRuleNames,
      appState.name,
      initName
    );
    const currentPageErrors = this.getCurrentPageErrors(
      appState.behavior.currentPageBehaviors
    );
    const sessionErrors = this.getSessionErrors(
      appState.behavior.sessionBehaviors
    );
    const pastErrors = this.getPastErrors(appState.behavior.pastBehaviors);
    const customerAttributesErrors = this.getCustomerAttributesErrors(
      appState.customer.customerAttributes
    );
    return [
      ...nameErrors,
      ...currentPageErrors,
      ...sessionErrors,
      ...pastErrors,
      ...customerAttributesErrors,
    ];
  }
}
