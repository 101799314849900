import { useEffect, useState } from "react";
import SearchParameters from "utils/SearchParameters";

type LoginInput = {
  clientId: string;
  username: string;
  password: string;
};

type LoginInputCallback = {
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
};

const useLoginInput = (
  searchParameters: SearchParameters
): [LoginInput, LoginInputCallback] => {
  const [clientId, setClientId] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (searchParameters.account_uuid) {
      setClientId(searchParameters.account_uuid);
    }
  }, [searchParameters.account_uuid]);

  useEffect(() => {
    if (searchParameters.user_id) {
      setUsername(searchParameters.user_id);
    }
  }, [searchParameters.user_id]);

  return [
    {
      clientId,
      username,
      password,
    },
    {
      setClientId,
      setUsername,
      setPassword,
    },
  ];
};

export default useLoginInput;
