import { CustomerAttributeHeaderResponse } from "interfaces/models";
import RestApi from "./RestApi";

class CustomerAttributeHeaderRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async load(): Promise<CustomerAttributeHeaderResponse> {
    return await this.managementApi.get("customer_attribute_headers");
  }
}

export default CustomerAttributeHeaderRepository;
