import styles from "./MessageBox.module.scss";

type MessageBoxProps = {
  messages: string[];
};

const MessageBox = ({ messages }: MessageBoxProps) => {
  return (
    <div className={styles.messageBox}>
      {messages.map((e) => (
        <div className={styles.message}>{e}</div>
      ))}
    </div>
  );
};

export default MessageBox;
