import copyIcon from "images/icons/icon_copy.svg";

import styles from "./CopyNoticeButton.module.scss";
import ReactTooltip from "react-tooltip";
import React, { useCallback, useState } from "react";

const VISIBLE_TIME_MS = 500;
const UPDATE_OFFSET = 1.5;
const CLICKED_COMMENT = "コピーしました！";
const HOVER_COMMENT = "クリックでコピー";

const CopyNoticeButton: React.VFC<{ id: string; onClick: () => void }> = ({
  id,
  onClick,
}) => {
  const [comment, setComment] = useState<string>(HOVER_COMMENT);

  const clickEvent = useCallback(() => {
    setComment(CLICKED_COMMENT);
    setTimeout(
      () => setComment(HOVER_COMMENT),
      VISIBLE_TIME_MS * UPDATE_OFFSET
    );
  }, [setComment]);

  return (
    <div className={styles.copyNoticeButton}>
      <ReactTooltip
        id={id}
        place="top"
        effect="solid"
        delayHide={VISIBLE_TIME_MS}
      >
        {comment}
      </ReactTooltip>
      <img
        data-tip
        data-for={id}
        src={copyIcon}
        alt=""
        className={styles.button}
        onClick={() => {
          clickEvent();
          onClick();
        }}
      />
    </div>
  );
};

export default CopyNoticeButton;
