import { PushNotificationDeliveryScheduleParameters } from "app/hooks/v2/pushNotification/usePushNotificationDeliverySchedule";
import { PushNotificationEditFormParameters } from "app/hooks/v2/pushNotification/usePushNotificationEditForm";
import { CreateCampaignRequestV2, UpdateCampaignRequest } from "types/campaign";
import {
  DeliveryScheduleRule,
  DeliveryScheduleRulePeriod,
} from "types/delivery_schedule";

export const MIN_PERIOD_START = 0;
export const MAX_PERIOD_END = 9999999999999;

export class ApiRequestBuilder {
  getImageBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject();
    });
  };

  parseDeliverySchedulePeriod(
    start_at: number,
    end_at: number,
    is_selected: boolean
  ): DeliveryScheduleRulePeriod {
    if (is_selected) {
      let nextDate = new Date(end_at);
      nextDate.setDate(nextDate.getDate() + 1);
      return {
        start_at: start_at,
        end_at: nextDate.getTime() - 1,
        is_selected: is_selected,
      };
    } else {
      return {
        start_at: MIN_PERIOD_START,
        end_at: MAX_PERIOD_END,
        is_selected: false,
      };
    }
  }

  parseDeliverySchedule(
    params: PushNotificationDeliveryScheduleParameters
  ): DeliveryScheduleRule {
    if (params.frequencyType === "ONCE") {
      return {
        schedule_type: params.scheduleType,
        frequency: {
          frequency_type: params.frequencyType,
          minutes: [params.deliveryDate.getMinutes()],
          hours: [params.deliveryDate.getHours()],
          days: [params.deliveryDate.getDate()],
          weekdays: [],
          months: [params.deliveryDate.getMonth() + 1],
          years: [params.deliveryDate.getFullYear()],
        },
        period: {
          start_at: params.deliveryDate.getTime(),
          end_at: params.deliveryDate.getTime(),
          is_selected: params.isActivePeriod,
        },
      };
    } else if (params.frequencyType === "WEEKLY") {
      return {
        schedule_type: params.scheduleType,
        frequency: {
          frequency_type: params.frequencyType,
          minutes: [params.repeatDate.getMinutes()],
          hours: [params.repeatDate.getHours()],
          days: [],
          weekdays: params.weekdays.map((weekday) => weekday),
          months: [],
          years: [],
        },
        period: this.parseDeliverySchedulePeriod(
          params.periodStart.getTime(),
          params.periodEnd.getTime(),
          params.isActivePeriod
        ),
      };
    } else if (params.frequencyType === "MONTHLY") {
      return {
        schedule_type: params.scheduleType,
        frequency: {
          frequency_type: params.frequencyType,
          minutes: [params.repeatDate.getMinutes()],
          hours: [params.repeatDate.getHours()],
          days: params.days,
          weekdays: [],
          months: [],
          years: [],
        },
        period: this.parseDeliverySchedulePeriod(
          params.periodStart.getTime(),
          params.periodEnd.getTime(),
          params.isActivePeriod
        ),
      };
    }
    throw new Error("Invalid frequency type");
  }

  parseCreateCampaignRequestWithImage = (
    parameters: PushNotificationEditFormParameters,
    isActive: boolean,
    base64Image?: string | ArrayBuffer | null
  ): CreateCampaignRequestV2 => {
    return {
      name: parameters.name,
      destination_collect_rule_uuid: parameters.destinationCollectRuleUuid,
      contents: {
        title: parameters.contents.title,
        body: parameters.contents.body,
        image_url: parameters.contents.isNewImage
          ? null
          : parameters.contents.imageUrl,
        redirect_url: parameters.contents.redirectUrl,
      },
      delivery_schedule: isActive
        ? this.parseDeliverySchedule(parameters.deliverySchedule)
        : null,
      image:
        parameters.contents.image && base64Image
          ? {
              base64: base64Image,
              name: parameters.contents.image.name,
              content_type: parameters.contents.image.type,
            }
          : null,
      is_active: isActive,
    };
  };

  getCreateCampaignRequest = async (
    parameters: PushNotificationEditFormParameters,
    isActive: boolean
  ): Promise<CreateCampaignRequestV2> => {
    if (parameters.contents.isNewImage && parameters.contents.image) {
      const base64Image = await this.getImageBase64(parameters.contents.image);
      return this.parseCreateCampaignRequestWithImage(
        parameters,
        isActive,
        base64Image
      );
    }

    return this.parseCreateCampaignRequestWithImage(parameters, isActive);
  };

  parseUpdateCampaignRequestWithImage = (
    campaignUuid: string,
    parameters: PushNotificationEditFormParameters,
    isActive: boolean,
    base64Image?: string | ArrayBuffer | null
  ): UpdateCampaignRequest => {
    return {
      campaign_uuid: campaignUuid,
      name: parameters.name,
      destination_collect_rule_uuid: parameters.destinationCollectRuleUuid,
      contents: {
        contents_uuid: parameters.contents.contentsUuid,
        title: parameters.contents.title,
        body: parameters.contents.body,
        image_url: parameters.contents.imageUrl,
        redirect_url: parameters.contents.redirectUrl,
      },
      delivery_schedule: isActive
        ? this.parseDeliverySchedule(parameters.deliverySchedule)
        : null,
      image:
        parameters.contents.image && base64Image
          ? {
              base64: base64Image,
              name: parameters.contents.image.name,
              content_type: parameters.contents.image.type,
            }
          : null,
      is_active: isActive,
    };
  };

  getUpdateCampaignRequest = async (
    campaignUuid: string,
    parameters: PushNotificationEditFormParameters,
    isActive: boolean
  ): Promise<UpdateCampaignRequest> => {
    if (parameters.contents.isNewImage && parameters.contents.image) {
      const base64Image = await this.getImageBase64(parameters.contents.image);

      return this.parseUpdateCampaignRequestWithImage(
        campaignUuid,
        parameters,
        isActive,
        base64Image
      );
    }

    return this.parseUpdateCampaignRequestWithImage(
      campaignUuid,
      parameters,
      isActive
    );
  };
}
