import React from "react";
import styles from "./TableTab.module.scss";
import fontStyles from "fontStyles.module.scss";

export type TableTabProps = {
  label: string | JSX.Element;
  onClick: () => void;
  is_active: boolean;
};

const TableTab: React.VFC<TableTabProps> = ({ label, onClick, is_active }) => {
  return (
    <div
      onClick={onClick}
      className={is_active ? styles.tableTab__active : styles.tableTab}
    >
      <div className={styles.section}>
        <span className={fontStyles.section}>{label}</span>
      </div>
    </div>
  );
};

export default TableTab;
