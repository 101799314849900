import React from "react";

import styles from "./NumberStats.module.scss";
import fontStyles from "fontStyles.module.scss";

const NumberStats: React.VFC<{
  number: string;
  unit?: string;
}> = ({ number, unit }) => {
  return (
    <div className={styles.numberStats}>
      <div className={fontStyles.stats}>{number}</div>
      <div className={fontStyles.title}>{unit}</div>
    </div>
  );
};

export default NumberStats;
