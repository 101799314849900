import ReactPaginate from "react-paginate";
import PreviousIcon from "images/icons/icon_arrow_left.svg";
import NextIcon from "images/icons/icon_arrow_right.svg";
import styles from "./Pagination.module.scss";

interface PaginationProps {
  length: number;
  pageRowMax: number;
  onChange: (num: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const pageCount = Math.ceil(props.length / props.pageRowMax);

  const previousIcon = <img src={PreviousIcon} alt="previous" />;
  const nextIcon = <img src={NextIcon} alt="next" />;

  const displayRange = 9;

  return (
    <div className={styles.paginateArea}>
      <ReactPaginate
        initialPage={0}
        pageCount={pageCount}
        pageRangeDisplayed={displayRange}
        marginPagesDisplayed={0}
        previousLabel={previousIcon}
        nextLabel={nextIcon}
        previousLinkClassName={styles.arrow}
        nextLinkClassName={styles.arrow}
        containerClassName={styles.paginate}
        disabledClassName={styles.disabled}
        activeClassName={styles.activePage}
        pageClassName={styles.page}
        breakLabel={""}
        onPageChange={(e) => props.onChange(e.selected)}
      />
    </div>
  );
};

export default Pagination;
