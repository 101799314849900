import React from "react";

import fontStyles from "fontStyles.module.scss";

import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";

import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import GrayBackPanel from "atoms/ezPushShared/accountPageShared/GrayBackPanel";
import { DeliveryReportFormParameters } from "app/hooks/v2/deliveryReport/useDeliveryReportForm";
import { getDateString } from "utils/format/unixtime";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import { DeliveryReportCondition } from "types/delivery_report";

export const DeliveryReportRequestConfirmModal: React.VFC<{
  formParameters: DeliveryReportFormParameters;
  submit: () => void;
  closeModal: () => void;
}> = ({ formParameters, submit, closeModal }) => {
  return (
    <ModalBackground>
      <ElementModal
        title="レポート集計を開始します。よろしいですか？"
        introduction="集計条件は以下の通りです。"
        element={
          <GrayBackPanel
            text={
              <div className={fontStyles.text}>
                <MultipleLineText
                  texts={[
                    `レポート対象期間：${getDateString(
                      formParameters.fromDate.getTime()
                    )} ~ ${getDateString(formParameters.toDate.getTime())}`,
                    `トラッキング期間：${formParameters.trackingDateRange}日`,
                  ]}
                />
              </div>
            }
          />
        }
        buttons={[
          <TransparentButton text="いいえ" onClick={closeModal} />,
          <OrangeButton text="はい" onClick={submit} />,
        ]}
      />
    </ModalBackground>
  );
};

export const DeliveryReportRequestRetryConfirmModal: React.VFC<{
  condition: DeliveryReportCondition;
  submit: () => void;
  closeModal: () => void;
}> = ({ condition, submit, closeModal }) => {
  const hoursToDay = (value: number) => {
    return value / 24;
  };
  return (
    <ModalBackground>
      <ElementModal
        title="レポート集計を再開始します。よろしいですか？"
        introduction="集計条件は以下の通りです。"
        element={
          <GrayBackPanel
            text={
              <MultipleLineText
                texts={[
                  `レポート対象期間：${getDateString(
                    condition.from_timestamp_at
                  )} ~ ${getDateString(condition.to_timestamp_at)}`,
                  `トラッキング期間：${hoursToDay(condition.range_hours)}日`,
                ]}
              />
            }
          />
        }
        buttons={[
          <TransparentButton text="いいえ" onClick={closeModal} />,
          <OrangeButton text="はい" onClick={submit} />,
        ]}
      />
    </ModalBackground>
  );
};

export const DeliveryReportRequestErrorModal: React.VFC<{
  retry: () => void;
  closeModal: () => void;
}> = ({ retry, closeModal }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="レポートの集計エラー"
        description={
          <MultipleLineText
            texts={[
              "システムエラーによりレポート集計に失敗しました。",
              "レポート集計を再実行し、それでも正常に動作しない場合はお問い合わせください。",
            ]}
          />
        }
        buttons={[
          <TransparentButton text="閉じる" onClick={closeModal} />,
          <OrangeButton text="レポート集計を再実行する" onClick={retry} />,
        ]}
      />
    </ModalBackground>
  );
};
