import { useState } from "react";
import LoadingIcon from "atoms/LoadingIcon";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import GrayBackPanel from "atoms/ezPushShared/accountPageShared/GrayBackPanel";

export const DomainDeleteModal: React.VFC<{
  domain: string;
  deleteDomain: () => void;
  clear: () => void;
}> = ({ domain, deleteDomain, clear }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  return (
    <ModalBackground>
      <NotificationModal
        title="このドメイン設定を削除します。よろしいですか？"
        description={
          <GrayBackPanel
            text={domain}
            notice={
              <div>
                <div>
                  このドメインが紐づいているURLをページ設定で登録している場合、そのページ設定を適用している「許諾メッセージの表示」や「セグメント設定」が機能しなくなります。
                </div>
                <div>
                  ※誤って削除した場合は、同じドメインを登録してください。
                </div>
              </div>
            }
          />
        }
        buttons={
          isProcessing
            ? [<LoadingIcon />]
            : [
                <TransparentButton text="いいえ" onClick={() => clear()} />,
                <OrangeButton
                  text={"はい"}
                  onClick={() => {
                    setIsProcessing(true);
                    deleteDomain();
                  }}
                />,
              ]
        }
      />
    </ModalBackground>
  );
};

export default DomainDeleteModal;
