import downloadIcon_orange from "images/icons/icon_download.svg";
import downloadIcon_white from "images/icons/icon_dl.svg";
import React from "react";

import fontStyles from "fontStyles.module.scss";

import { Domain } from "types/domain";

import Label from "molecules/v2/Label";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import LinkText from "atoms/LinkText";
import OrangeBorderedButton from "atoms/ezPushShared/accountPageShared/OrangeBorderedButton";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import {
  MEASUREMENT_TAG_DESCRIPTIONS,
  SERVICE_WORKER_DESCRIPTIONS,
} from "app/system_defaults/descriptionTexts";

const ServiceWorkerFile: React.VFC<{
  domains: Domain[] | null;
  onDownload: () => void;
}> = ({ domains, onDownload }) => (
  <VerticalSpreadLayout
    margin={10}
    items={[
      <Label
        label={
          <span className={fontStyles.section}>
            1.プッシュ通知用JSファイル設置
          </span>
        }
        isRequired
      />,
      <VerticalSpreadLayout
        margin={5}
        items={[
          <div>
            以下からダウンロードしたファイルを、許諾ドメイン
            <span className={fontStyles.boldText}>
              {domains
                ?.filter((domain) => domain.is_subscribe)
                .map((d, i) => `[${d.domain}]`)
                .join(",")}
              の配下
            </span>
            に設置してください。
          </div>,
          <MultipleLineText
            texts={SERVICE_WORKER_DESCRIPTIONS.map((d) => (
              <span className={fontStyles.note} style={{ color: "#666" }}>
                {d}
              </span>
            ))}
          />,
        ]}
      />,
      <OrangeButton
        text={
          <HorizontalIconLayout
            margin={5}
            elements={[
              <img
                src={downloadIcon_white}
                style={{ verticalAlign: "middle" }}
                alt=""
              />,
              <span>プッシュ通知用JSファイル</span>,
            ]}
          />
        }
        onClick={onDownload}
      />,
    ]}
  />
);

const MeasurementTagFile: React.VFC<{
  onDownload: () => void;
}> = ({ onDownload }) => {
  return (
    <VerticalSpreadLayout
      margin={10}
      items={[
        <Label
          label={
            <span className={fontStyles.section}>2.サービス専用タグ設置</span>
          }
          isRequired
        />,
        <VerticalSpreadLayout
          margin={5}
          items={[
            <span>
              以下からダウンロードしたファイル内のHTMLタグを、
              <span className={fontStyles.boldText}>
                全ての計測対象ページ内
              </span>
              に設置してください。
            </span>,
            <MultipleLineText
              texts={MEASUREMENT_TAG_DESCRIPTIONS.map((d) => (
                <span className={fontStyles.note} style={{ color: "#666" }}>
                  {d}
                </span>
              ))}
            />,
          ]}
        />,
        <OrangeButton
          text={
            <HorizontalIconLayout
              margin={5}
              elements={[
                <img
                  src={downloadIcon_white}
                  style={{ verticalAlign: "middle" }}
                  alt=""
                />,
                <span>サービス専用タグ</span>,
              ]}
            />
          }
          onClick={onDownload}
        />,
      ]}
    />
  );
};

const CallSupport: React.VFC = () => (
  <VerticalSpreadLayout
    margin={10}
    items={[
      <Label
        label={
          <span className={fontStyles.section}>
            3.許諾処理の動作確認をサポートに依頼
          </span>
        }
        isRequired
      />,
      <MultipleLineText
        texts={[
          <div>
            JSファイルの疎通確認と、許諾処理が正常に行えているかの動作を確認します。
          </div>,
          <div>
            お手数ですがサポート（
            {
              <LinkText
                text={"support@target-push.com"}
                link={"mailto:support@target-push.com"}
                external
              />
            }
            ）までご連絡ください。
          </div>,
        ]}
      />,
      <div>
        確認完了までに「プッシュ通知」から事前に配信準備を進めることができます。
      </div>,
    ]}
  />
);

const RequestToSiteOwner: React.VFC<{
  downloadAll: () => void;
}> = ({ downloadAll }) => (
  <VerticalSpreadLayout
    margin={10}
    items={[
      <Label
        label={
          <span className={fontStyles.section}>
            設置作業をサイト管理者や担当者にご依頼する方へ
          </span>
        }
      />,
      <div>
        以下のJSファイル、専用タグ、手順書を同梱したファイルをお渡しいただくことでやり取りがスムーズになります。
      </div>,
      <OrangeBorderedButton
        text={
          <HorizontalIconLayout
            margin={5}
            elements={[
              <img
                src={downloadIcon_orange}
                style={{ verticalAlign: "middle", width: 14, height: 14 }}
                alt=""
              />,
              <span>一括ダウンロード（手順書つき）</span>,
            ]}
          />
        }
        onClick={downloadAll}
      />,
    ]}
  />
);

const FileConfiguration: React.VFC<{
  domains: Domain[] | null;
  downloadSW: () => void;
  downloadTag: () => void;
  downloadAll: () => void;
}> = ({ domains, downloadSW, downloadTag, downloadAll }) => (
  <VerticalSpreadLayout
    margin={40}
    items={[
      <VerticalSpreadLayout
        margin={30}
        items={[
          <div>
            JSファイルや専用タグを設置していきます。「STEP3
            ページ設定」と並行して設定いただけます。
          </div>,
          <VerticalSpreadLayout
            margin={60}
            items={[
              <ServiceWorkerFile domains={domains} onDownload={downloadSW} />,
              <MeasurementTagFile onDownload={downloadTag} />,
              <CallSupport />,
            ]}
          />,
        ]}
      />,
      <div
        style={{
          width: "100%",
          borderTop: "1px solid #ccc",
        }}
      />,
      <RequestToSiteOwner downloadAll={downloadAll} />,
    ]}
  />
);

export default FileConfiguration;
