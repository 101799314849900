import { useState } from "react";

import LoadActionDetail from "app/loaders/LoadActionDetail";
import BuildAccountPage from "app/builders/BuildAccountPage";
import CreateAreaContents from "app/creators/actions/CreateAreaContents";
import CreateActionDetailElement from "app/creators/actions/CreateActionDetailElement";
import { sortActionsWithActionNumber } from "organisms/account/actions/ActionSorter";

import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import NotificateModal from "atoms/accountPagesShared/NotificateModal";
import NegativeButton from "atoms/accountPagesShared/NegativeButton";

import { ActionRepository } from "utils/ActionsRepository";
import { DateRange, AccountPageContext } from "interfaces/view/accout";

import { ActionStatus } from "interfaces/models";
import { useHistory } from "react-router-dom";
import CreateNotificateModalContents from "app/creators/CreateNotificateModalContents";
import DetailModal from "organisms/account/DetailModal";
import { ACTION_OPERATION_MODE } from "interfaces/view/actionCreation";
import useActionSummaries from "app/hooks/useActionSummaries";
import useActions from "app/hooks/useActions";

interface ActionPageProps {
  context: AccountPageContext;
  actionRepository: ActionRepository;
}

const ActionPage = ({ context, actionRepository }: ActionPageProps) => {
  const history = useHistory();
  const nowDate = new Date();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate()
    ),
    end: nowDate,
  });
  const [error, setError] = useState<string>("");

  const [actions, reloadActions, actionsLoadError] =
    useActions(actionRepository);

  const [actionSummaries, actionSummariesLoadError] = useActionSummaries(
    dateRange,
    actionRepository
  );

  const onMoveActionCreationPage = (
    params: { key: string; value?: string }[]
  ) => {
    const payload = params
      .filter((kv): kv is { key: string; value: string } => !!kv.value)
      .map((kv) => `${kv.key}=${kv.value}`)
      .join("&");

    history.push(`/actions/creation?${payload}`);
  };

  return (
    <BuildAccountPage
      context={context}
      renderAreaContentsElement={({ setModalElement }) => (
        <CreateAreaContents
          context={context}
          errorMessages={[
            error,
            actionsLoadError,
            actionSummariesLoadError,
          ].filter((e) => e !== "")}
          actions={
            actions === undefined
              ? undefined
              : sortActionsWithActionNumber(actions)
          }
          actionSummaries={actionSummaries}
          dateRange={dateRange}
          setDateRange={setDateRange}
          actionCallbacks={{
            onDetail: (actionId: string) => {
              setModalElement(
                <DetailModal
                  modalContentsElement={
                    <LoadActionDetail
                      actionRepository={actionRepository}
                      actionId={actionId}
                      render={({ actionDetail2, errorMessage }) => (
                        <CreateActionDetailElement
                          actionDetail={actionDetail2}
                          errorMessage={errorMessage}
                          onCloseModal={() => setModalElement(undefined)}
                        />
                      )}
                    />
                  }
                  onRequestCloseModal={() => setModalElement(undefined)}
                />
              );
            },
            onCreate: (actionId) => {
              onMoveActionCreationPage([
                { key: "operation", value: ACTION_OPERATION_MODE.CREATE },
                { key: "source_id", value: actionId },
              ]);
            },
            onDelete: (actionId: string) => {
              setModalElement(
                <NotificateModal
                  Contents={
                    <CreateNotificateModalContents
                      title="このおもてなしアクションを削除しますか？"
                      description="削除すると元には戻せません。よろしいですか？"
                      buttons={[
                        <NegativeButton
                          text="キャンセル"
                          onClick={() => setModalElement(undefined)}
                        />,
                        <PositiveButton
                          text="削除"
                          onClick={async () => {
                            try {
                              await actionRepository.delete(actionId);
                              reloadActions();
                            } catch (e) {
                              if (e instanceof Error) {
                                setError(e.message);
                              }
                            } finally {
                              setModalElement(undefined);
                            }
                          }}
                        />,
                      ]}
                    />
                  }
                  isOpen={true}
                  onRequestClose={() => setModalElement(undefined)}
                />
              );
            },
            onUpdateStatus: async (actionId: string, value: ActionStatus) => {
              try {
                await actionRepository.updateStatus(actionId, value);
                reloadActions();
              } catch (e) {
                if (e instanceof Error) {
                  setError(e.message);
                }
              }
            },
            onEdit: (actionId: string) => {
              onMoveActionCreationPage([
                { key: "operation", value: ACTION_OPERATION_MODE.EDIT },
                { key: "edit_id", value: actionId },
              ]);
            },
          }}
          render={(areaContentsElement) => areaContentsElement}
        />
      )}
    />
  );
};

export default ActionPage;
