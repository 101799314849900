import styles from "./AccessStatisticsButton.module.scss";
import modalIcon from "images/icons/icon_modal.svg";
import { Accesses, MonthlyAccessDetail } from "interfaces/models";
import LoadingIcon from "atoms/LoadingIcon";

const getButtonText = (
  detail: MonthlyAccessDetail,
  latest_month_date: Date
) => {
  const year = latest_month_date.getFullYear();
  const month = latest_month_date.getMonth() + 1;

  const latestPV: string = detail.value.pv.toLocaleString();
  const latestUU: string = detail.value.uu.toLocaleString();
  const latestCVUU: string = detail.value.cvuu.toLocaleString();
  return (
    <div>
      <div className={styles.pvHeader}>{year + "年" + month + "月度"}</div>
      <div className={styles.data}>{"PV " + latestPV}</div>
      <div className={styles.separator}>{"/"}</div>
      <div className={styles.data}>{"UU " + latestUU}</div>
      <div className={styles.separator}>{"/"}</div>
      <div className={styles.data}>{"CVUU " + latestCVUU}</div>
      <div className={styles.icon}>
        <img src={modalIcon} alt="modal" />
      </div>
    </div>
  );
};

const getButton = (accesses: Accesses, onClick?: () => void) => {
  const keysMonthAll = Object.keys(accesses.monthly.reports);
  const keysLatestMonth = keysMonthAll.reverse().slice(0, 1)[0];

  if (accesses.monthly.reports[keysLatestMonth]) {
    const date: Date = new Date(keysLatestMonth + "-01");
    return (
      <button
        type="button"
        className={styles.AccessStatisticsButton}
        onClick={onClick}
      >
        {getButtonText(accesses.monthly.reports[keysLatestMonth], date)}
      </button>
    );
  } else {
    return (
      <button type="button" className={styles.AccessStatisticsButton}>
        <div>サイト統計データの集計中です...</div>
      </button>
    );
  }
};

type AccessStatisticsButtonProps = {
  accesses?: Accesses | undefined;
  onClick?: () => void;
};

const AccessStatisticsButton = ({
  accesses,
  onClick,
}: AccessStatisticsButtonProps) => {
  if (accesses) {
    return getButton(accesses, onClick);
  } else {
    return <LoadingIcon />;
  }
};

export default AccessStatisticsButton;
