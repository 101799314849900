import { assertNever } from "utils/assertions";

/**
 * This component always throws exception
 */
const AssertNever = (props: { never: never }) => {
  assertNever(props.never);

  return null;
};

export default AssertNever;
