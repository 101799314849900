import CategoryHeaderTab from "atoms/accountPagesShared/CategoryHeader";
import { useState } from "react";
import AccessStatisticsTable from "./AccessStatisticsTable";
import { Accesses, TERM_HEADER } from "interfaces/models";
import styles from "./AccessStatisticsTables.module.scss";

interface AccessStatisticsTablesProps {
  accesses: Accesses | undefined;
}

const AccessStatisticsTables = ({ accesses }: AccessStatisticsTablesProps) => {
  const [selectedTerm, setSelectedTerm] = useState(TERM_HEADER[0].value);
  return (
    <>
      <div className={styles.contentsEditPhaseFormMainEditArea}>
        <div className={styles.header}>
          {TERM_HEADER.map((e) => (
            <div className={styles.tab}>
              <CategoryHeaderTab
                label={e.label}
                isActive={e.value === selectedTerm ? true : false}
                onClick={() => setSelectedTerm(e.value)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.accessStatisticsBackGround}>
        <AccessStatisticsTable
          selectedTerm={selectedTerm}
          accesses={accesses}
        />
      </div>
    </>
  );
};

export default AccessStatisticsTables;
