import styles from "./ModeSelectButton.module.scss";

const ModeSelectButton = ({
  text,
  onClick,
  img,
}: {
  text: string;
  onClick: () => void;
  img?: JSX.Element;
}) => (
  <div className={styles.modeSelectButton} onClick={onClick}>
    <div>
      <div className={styles.image}>{img}</div>
      <div>{text}</div>
    </div>
  </div>
);

export default ModeSelectButton;
