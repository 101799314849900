import SearchParameters, { stringToEnumValue } from "utils/SearchParameters";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import {
  SegmentationRuleCreationFormState,
  useCustomerAttributeHeader,
  useExistingSegmentationRuleNames,
  useSegmentationRuleCreation,
  useSegmentationRuleCreationFormState,
} from "./segmentationRuleCreationPage";
import {
  Phase,
  PhaseType,
  SegmentationRuleCreationModeType,
  SegmentationRuleCreationMode,
} from "interfaces/view/segmentationCreation";
import useSegmentationRule from "./useSegmentationRule";
import { SegmentationRule } from "interfaces/models";
import { useState, useEffect, useMemo } from "react";
import CustomerAttributeHeaderRepository from "utils/CustomerAttributeHeaderRepository";
import SegmentationRuleCreationCodec from "utils/segmentation_rule_creation/segmentationRuleCreationCodec";
import { SegmentationRuleCreation } from "interfaces/model/segmentationRuleCreation";
import {
  useSegmentationRuleCreationAppCallbacks,
  useSegmentationRuleCreationEventCallbacks,
  SegmentationRuleCreationCallbacks,
} from "utils/segmentation_rule_creation/SegmentationRuleCreationCallbacks";
import { useHistory } from "react-router-dom";

type SegmentationRuleCreationSearchParameters = {
  phase: PhaseType;
  mode?: SegmentationRuleCreationModeType;
  segmentationRuleId?: string;
};

const decodeSearchParameters = (
  searchParameters: SearchParameters
): SegmentationRuleCreationSearchParameters => {
  const mode = stringToEnumValue(
    searchParameters.mode,
    Object.values(SegmentationRuleCreationMode),
    SegmentationRuleCreationMode.CREATE
  );

  return {
    phase: stringToEnumValue(
      searchParameters.phase,
      Object.values(Phase),
      Phase.INPUT
    ),
    mode: mode,
    segmentationRuleId:
      mode === SegmentationRuleCreationMode.CREATE
        ? searchParameters.source_id
        : searchParameters.edit_id,
  };
};

/** NOTE: 以下の状態を判別し、画面の描画状態を決定するフラグを生成する。
|mode   |SegmentationRuleId|SegmentationRuleCreation |return|note
|CREATE |EXIST             |xxx                      |TRUE  |Loaded
|CREATE |EXIST             |-                        |FALSE |Loading
|EDIT   |EXIST             |xxx                      |TRUE  |Loaded
|EDIT   |EXIST             |-                        |FALSE |Loading
|CREATE |NONE              |xxx                      |-     |Invalid
|CREATE |NONE              |-                        |TRUE  |Pure Create
|EDIT   |NONE              |xxx                      |-     |Invalid
|EDIT   |NONE              |-                        |-     |Invalid
 */
export const getIsLoaded = (
  mode: SegmentationRuleCreationModeType | undefined,
  segmentationRuleId: string | undefined,
  isLoadSegmentationRuleCreation: boolean
): boolean => {
  if (!mode) {
    console.error("invalid search params");
    return false;
  }

  if (segmentationRuleId) {
    return isLoadSegmentationRuleCreation;
  } else {
    if (
      mode === SegmentationRuleCreationMode.CREATE &&
      !isLoadSegmentationRuleCreation
    ) {
      return true;
    } else {
      console.error("invalid search params");
      return false;
    }
  }
};

export type SegmentationRuleCreationRenderingState = {
  pageState: SegmentationRuleCreationSearchParameters;
  initialState: SegmentationRuleCreation.SegmentationRuleCreation | undefined;
  isLoaded: boolean;
  message: string[];
  app: SegmentationRuleCreation.SegmentationRuleCreation;
  form: SegmentationRuleCreationFormState;
  customerAttributeHeaders: string[];
  createdSegmentationRuleCreation: SegmentationRule | undefined;
};

export type SegmentationRuleCreationServiceCallbacks = {
  app: SegmentationRuleCreationCallbacks.AppCallbacks;
  event: {
    onCancel: () => void;
    onSubmit: () => void;
    onComplete: () => void;
  };
};

const useSegmentationRuleCreationService = (
  segmentationRuleRepository: SegmentationRuleRepository,
  customerAttributeHeaderRepository: CustomerAttributeHeaderRepository,
  searchParameters: SearchParameters,
  updateSearchParameters: (newParameter: SearchParameters) => void
): [
  SegmentationRuleCreationRenderingState,
  SegmentationRuleCreationServiceCallbacks
] => {
  // 1. 初期化処理
  const history = useHistory();
  const [appState, setAppState, initializeCallback] =
    useSegmentationRuleCreation();

  const [formState, formCallbacks, initFormState] =
    useSegmentationRuleCreationFormState();

  const codec: SegmentationRuleCreationCodec = useMemo(() => {
    return new SegmentationRuleCreationCodec();
  }, []);

  // TODO: 構造化する。
  const [message, setMessage] = useState<string[]>([]);

  // existing
  const customerAttributeHeaders = useCustomerAttributeHeader(
    customerAttributeHeaderRepository
  );

  const [existingSegmentationRuleNames, existingSegmentationRuleNamesGetError] =
    useExistingSegmentationRuleNames(segmentationRuleRepository);

  const pageState: SegmentationRuleCreationSearchParameters = useMemo(
    () => decodeSearchParameters(searchParameters),
    [searchParameters]
  );

  const segmentationRule: SegmentationRule | null = useSegmentationRule(
    segmentationRuleRepository,
    pageState.segmentationRuleId
  );

  const segmentationRuleCreation:
    | SegmentationRuleCreation.SegmentationRuleCreation
    | undefined = useMemo(() => {
    return segmentationRule ? codec.decode(segmentationRule) : undefined;
  }, [segmentationRule, codec]);

  const [createdSegmentationRuleCreation, setCreatedSegmentationRuleCreation] =
    useState<SegmentationRule>();

  //***********************
  // 3. 副作用系
  // Spec: When error message updated, the message is focused.
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [message]);

  useEffect(() => {
    if (segmentationRuleCreation) {
      initFormState(segmentationRuleCreation);
    }
  }, [segmentationRuleCreation, initFormState]);

  useEffect(() => {
    if (segmentationRuleCreation) {
      initializeCallback(
        segmentationRuleCreation,
        pageState.mode === SegmentationRuleCreationMode.CREATE
      );
    }
  }, [pageState.mode, segmentationRuleCreation, initializeCallback]);

  // 読み込み処理の失敗のエラーハンドリング
  useEffect(() => {
    if (existingSegmentationRuleNamesGetError !== undefined) {
      setMessage([
        "既存のセグメントルール名の取得に失敗しました、再度ページを読み込んでください。",
      ]);
    }
  }, [existingSegmentationRuleNamesGetError, setMessage]);

  //***********************
  // 4. getter / setter生成

  const isLoaded: boolean = useMemo(
    () =>
      getIsLoaded(
        pageState.mode,
        pageState.segmentationRuleId,
        !!segmentationRuleCreation
      ),
    [pageState, segmentationRuleCreation]
  );

  const state: SegmentationRuleCreationRenderingState = useMemo(() => {
    return {
      pageState: pageState,
      initialState: segmentationRuleCreation,
      isLoaded: isLoaded,
      message: message,
      app: appState,
      form: formState,
      customerAttributeHeaders: customerAttributeHeaders,
      createdSegmentationRuleCreation: createdSegmentationRuleCreation,
    };
  }, [
    appState,
    formState,
    message,
    segmentationRuleCreation,
    pageState,
    customerAttributeHeaders,
    createdSegmentationRuleCreation,
    isLoaded,
  ]);

  const appCallbacks = useSegmentationRuleCreationAppCallbacks(
    appState,
    setAppState,
    formState,
    formCallbacks
  );

  const eventCallbacks: SegmentationRuleCreationCallbacks.EventCallbacks =
    useSegmentationRuleCreationEventCallbacks(
      history,
      segmentationRuleRepository,
      pageState.mode,
      pageState.segmentationRuleId,
      existingSegmentationRuleNames,
      segmentationRuleCreation,
      codec,
      updateSearchParameters,
      setMessage,
      setCreatedSegmentationRuleCreation
    );

  const callbacks: SegmentationRuleCreationServiceCallbacks = {
    app: appCallbacks,
    event: {
      onCancel: () => {
        eventCallbacks.onCancel();
      },
      onSubmit: () => {
        eventCallbacks.onSubmit(state.app);
      },
      onComplete: () => {
        eventCallbacks.onComplete();
      },
    },
  };

  return [state, callbacks];
};

export default useSegmentationRuleCreationService;
