export const STEP_FUNCTIONS_STATUS_RESPONSE = {
  RUNNING: "running",
  SUCCEEDED: "succeeded",
  ABORTED: "aborted",
  FAILED: "failed",
  TIMED_OUT: "timed_out",
} as const;

export type StepFunctionsStatusResponse =
  typeof STEP_FUNCTIONS_STATUS_RESPONSE[keyof typeof STEP_FUNCTIONS_STATUS_RESPONSE];
