import { CONDITION_TYPE } from "app/process/segmentationRule/segmentationRuleTree";

export type SegmentationRulePageConditionUrlSetting = {
  label: string;
  value: string;
};

export type SegmentationRulePageConditionParameters = {
  urlSetting: SegmentationRulePageConditionUrlSetting;
  compareType: string;
  value: number;
  conditionUuid: string;
};

export type SegmentationRuleSubscribeTimingConditionParameters = {
  days_ago: number;
  conditionUuid: string;
};

export type SegmentationRuleConditionInterface =
  | {
      type: CONDITION_TYPE.TOTAL_PV_COUNT;
      pages: SegmentationRulePageConditionParameters[];
    }
  | {
      type: CONDITION_TYPE.SUBSCRIBE_TIMING;
      parameters: SegmentationRuleSubscribeTimingConditionParameters;
    };

export type SegmentationRuleTreeParameter =
  SegmentationRuleConditionInterface[];

export enum TreeDispatchType {
  UPDATE = "UPDATE",
}

export enum ConditionDispatchType {
  ADD_CONDITION = "ADD_CONDITION",
  REDUCE_CONDITION = "REDUCE_CONDITION",
}

export enum PageConditionDispatchType {
  ADD_PAGE = "ADD_PAGE",
  REDUCE_PAGE = "REDUCE_PAGE",
  UPDATE_PAGE_UUID = "UPDATE_PAGE_UUID",
  UPDATE_COMPARE_TYPE = "UPDATE_COMPARE_TYPE",
  UPDATE_VALUE = "UPDATE_VALUE",
}

export enum PAGE_CONDITION_COMPARE_TYPE {
  LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
  GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
  EQUAL_TO = "EQUAL_TO",
}

type PageConditionCompareType =
  typeof PAGE_CONDITION_COMPARE_TYPE[keyof typeof PAGE_CONDITION_COMPARE_TYPE];

type TreeUpdateAction = {
  type: TreeDispatchType.UPDATE;
  tree: SegmentationRuleTreeParameter;
};

type PageConditionAddAction = {
  type: PageConditionDispatchType.ADD_PAGE;
  conditionNumber: number;
};

type PageConditionReduceAction = {
  type: PageConditionDispatchType.REDUCE_PAGE;
  conditionNumber: number;
  pageNumber: number;
};

type UpdatePageUuidAction = {
  type: PageConditionDispatchType.UPDATE_PAGE_UUID;
  conditionNumber: number;
  pageNumber: number;
  value: SegmentationRulePageConditionUrlSetting;
};

type UpdateCompareTypeAction = {
  type: PageConditionDispatchType.UPDATE_COMPARE_TYPE;
  conditionNumber: number;
  pageNumber: number;
  value: PageConditionCompareType;
};

type UpdateValueAction = {
  type: PageConditionDispatchType.UPDATE_VALUE;
  conditionNumber: number;
  pageNumber: number;
  value: number;
};

type ConditionAddAction = {
  type: ConditionDispatchType.ADD_CONDITION;
};

type ConditionReduceAction = {
  type: ConditionDispatchType.REDUCE_CONDITION;
  conditionNumber: number;
};

export type SegmentationRuleTreeDispatchAction =
  | PageConditionAddAction
  | PageConditionReduceAction
  | UpdatePageUuidAction
  | UpdateCompareTypeAction
  | UpdateValueAction
  | ConditionAddAction
  | ConditionReduceAction
  | TreeUpdateAction;

export type SegmentationRuleFormParameters = {
  name: string;
  duration: number;
  tree: SegmentationRuleTreeParameter;
  canEdit: boolean;
  isNeedDuration: boolean;
};

export type SegmentationRuleFormCallbacks = {
  updateName: (value: string) => void;
  updateDuration: (value: number) => void;
  updateTree: (action: SegmentationRuleTreeDispatchAction) => void;
  flipIsNeedDuration: () => void;
};

export enum SegmentationRuleEditorStatus {
  LOADING = "LOADING",
  EDITABLE = "EDITABLE",
  ERROR = "ERROR",
}
