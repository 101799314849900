import { useEffect, useState } from "react";
import { DELIVERY_REPORT_DEFAULTS } from "app/system_defaults/delivery_report_defaults";

const getFirstDayOfCurrentMonth = (): Date => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

const getTodayOfCurrentMonth = (): Date => {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
};

export type DeliveryReportFormParameters = {
  fromDate: Date;
  toDate: Date;
  trackingDateRange: number;
};

export type DeliveryReportFormCallbacks = {
  updateFromDate: (value: Date) => void;
  updateToDate: (value: Date) => void;
  updateTrackingDateRange: (value: number) => void;
};

const useDeliveryReportForm = (): [
  DeliveryReportFormParameters,
  DeliveryReportFormCallbacks
] => {
  const [fromDate, setFromDate] = useState<Date>(getFirstDayOfCurrentMonth());
  const [toDate, setToDate] = useState<Date>(getTodayOfCurrentMonth());
  const [trackingDateRange, setTrackingDateRange] = useState<number>(
    DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.DEFAULT
  );

  // NOTE: 最大最小値の制限
  useEffect(() => {
    if (trackingDateRange < DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MIN) {
      setTrackingDateRange(DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MIN);
    } else if (
      trackingDateRange > DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MAX
    ) {
      setTrackingDateRange(DELIVERY_REPORT_DEFAULTS.CONFIG.TRACKING_DATE.MAX);
    }
  }, [trackingDateRange]);

  return [
    { fromDate, toDate, trackingDateRange },
    {
      updateFromDate: (value: Date) => setFromDate(value),
      updateToDate: (value: Date) => setToDate(value),
      updateTrackingDateRange: (value: number) => setTrackingDateRange(value),
    },
  ];
};

export default useDeliveryReportForm;
