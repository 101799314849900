import styles from "./ActionCreationHeader.module.scss";

type ActionCreationHeaderProps = {
  topicPathElement: JSX.Element;
  headerTextElement: JSX.Element;
  phaseNaviElement?: JSX.Element;
  errorMessageElement?: JSX.Element;
  settingEditElement?: JSX.Element;
};

const ActionCreationHeader = ({
  topicPathElement,
  headerTextElement,
  phaseNaviElement,
  errorMessageElement,
  settingEditElement,
}: ActionCreationHeaderProps) => (
  <div className={styles.layout}>
    <div className={styles.topic}>{topicPathElement}</div>
    <div className={styles.headline}>
      <div className={styles.title}>{headerTextElement}</div>
      {phaseNaviElement ? <>{phaseNaviElement}</> : null}
    </div>
    {errorMessageElement ? (
      <div className={styles.errorMessage}>{errorMessageElement}</div>
    ) : null}
    {settingEditElement ? (
      <div className={styles.stepBar}>{settingEditElement}</div>
    ) : null}
  </div>
);

export default ActionCreationHeader;
