import React from "react";
import {
  PAGE_CONDITION_COMPARE_TYPE,
  SegmentationRuleConditionInterface,
  SegmentationRuleFormParameters,
  SegmentationRulePageConditionParameters,
  SegmentationRuleSubscribeTimingConditionParameters,
} from "interfaces/v2/segmentationRuleForm";
import styles from "./SegmentationRuleTreeTable.module.scss";
import fontStyles from "fontStyles.module.scss";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import LoadingIcon from "atoms/LoadingIcon";
import {
  COMPARE_CONDITION_OPTIONS_DISPLAY_WORD,
  LOCATION_COMPARE_TYPE_DISPLAY_WORD,
  PAGE_SETTING_TYPE_DISPLAY_WORD,
  QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD,
} from "app/system_defaults/WordDefaults";
import { CONDITION_TYPE } from "app/process/segmentationRule/segmentationRuleTree";

const SegmentationRuleTreeNodeUrlSettingBox: React.FC<{
  urlUuid: string;
  urlSettings: MeasurementUrlSettingWithType[] | null;
}> = ({ urlUuid, urlSettings }) => {
  if (!urlSettings) {
    return <LoadingIcon />;
  }
  const target = urlSettings.find((e) => e.url.url_uuid === urlUuid);
  if (!target) {
    return (
      <div className={styles.treeNodeUrlSettingBox}>
        このページ設定は削除されました
      </div>
    );
  }
  return (
    <div className={styles.treeNodeUrlSettingBox}>
      <div className={styles.locationArea}>
        <div>ページ（{PAGE_SETTING_TYPE_DISPLAY_WORD[target.type]}）：</div>
        <div className={styles.value}>
          {target.url.url.locations.map((loc, i) => (
            <div key={i}>
              {i !== 0 ? (
                <div className={styles.andSeparator}>かつ（and）</div>
              ) : undefined}
              <div>
                「{loc.value}」{LOCATION_COMPARE_TYPE_DISPLAY_WORD[loc.pattern]}
              </div>
            </div>
          ))}
        </div>
      </div>
      {target.url.url.parameters.length > 0 ? (
        <div className={styles.parameterArea}>
          <div>パラメータ：</div>
          <div>
            {target.url.url.parameters.map((p, i) => (
              <div key={i}>
                {i !== 0 ? (
                  <div className={styles.andSeparator}>かつ（and）</div>
                ) : undefined}
                <div>
                  「{p.key}={p.value}」
                  {QUERY_PARAMETER_COMPARE_TYPE_DISPLAY_WORD[p.pattern]}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

const SegmentationRuleTreeNodeBox: React.FC<{
  pages: SegmentationRulePageConditionParameters[];
  urlSettings: MeasurementUrlSettingWithType[] | null;
}> = ({ pages, urlSettings }) => {
  return (
    <div className={styles.treeNodeBox}>
      {pages.map((page, i) => (
        <div key={i}>
          {i !== 0 ? (
            <div className={styles.andSeparator}>かつ（and）</div>
          ) : undefined}
          <div>
            <div className={styles.description}>
              「{page.urlSetting.label}」でPV「{page.value}回」
              {
                COMPARE_CONDITION_OPTIONS_DISPLAY_WORD[
                  page.compareType as PAGE_CONDITION_COMPARE_TYPE
                ]
              }
            </div>
            <div className={styles.urlSettingBox}>
              <SegmentationRuleTreeNodeUrlSettingBox
                urlUuid={page.urlSetting.value}
                urlSettings={urlSettings}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const DurationSetting: React.FC<{
  duration: number;
  isNeedDuration: boolean;
}> = ({ duration, isNeedDuration }) => {
  return (
    <div className={styles.durationSetting}>
      <div className={styles.head}>
        <span className={fontStyles.boldText}>期間</span>
      </div>
      <div>
        <div>指定{isNeedDuration ? "する" : "しない"}</div>
        {isNeedDuration ? <div>過去 {duration} 日間</div> : undefined}
      </div>
    </div>
  );
};

const PagesConditionSetting: React.FC<{
  urlSettings: MeasurementUrlSettingWithType[] | null;
  condition: SegmentationRulePageConditionParameters[];
  index: number;
}> = ({ urlSettings, condition, index }) => {
  return (
    <div className={styles.conditionSetting}>
      <div className={styles.head}>
        <span className={fontStyles.boldText}>条件{index}</span>
      </div>
      <div className={styles.body}>
        <SegmentationRuleTreeNodeBox
          pages={condition}
          urlSettings={urlSettings}
        />
      </div>
    </div>
  );
};

const SubscribeTimingConditionSetting: React.FC<{
  condition: SegmentationRuleSubscribeTimingConditionParameters;
  index: number;
}> = ({ condition, index }) => {
  return (
    <div className={styles.conditionSetting}>
      <div className={styles.head}>
        <span className={fontStyles.boldText}>条件{index}</span>
      </div>
      <div className={styles.body}>
        <div>配信日の「{condition.days_ago}日」前に許諾</div>
      </div>
    </div>
  );
};

const ConditionSetting: React.FC<{
  index: number;
  condition: SegmentationRuleConditionInterface;
  urlSettings: MeasurementUrlSettingWithType[] | null;
}> = ({ index, condition, urlSettings }): JSX.Element => {
  if (condition.type === CONDITION_TYPE.TOTAL_PV_COUNT) {
    return (
      <PagesConditionSetting
        urlSettings={urlSettings}
        condition={condition.pages}
        index={index + 1}
      />
    );
  } else if (condition.type === CONDITION_TYPE.SUBSCRIBE_TIMING) {
    return (
      <SubscribeTimingConditionSetting
        condition={condition.parameters}
        index={index + 1}
      />
    );
  } else {
    return <></>;
  }
};

const SegmentationRuleTreeTable: React.FC<{
  form: SegmentationRuleFormParameters;
  urlSettings: MeasurementUrlSettingWithType[] | null;
}> = ({ form, urlSettings }) => (
  <div className={styles.segmentationRuleTreeTable}>
    <div className={styles.durationArea}>
      <DurationSetting
        duration={form.duration}
        isNeedDuration={form.isNeedDuration}
      />
    </div>
    {form.tree.map((condition, i) => (
      <div key={i}>
        {i !== 0 ? (
          <div className={styles.separator}>または（or）</div>
        ) : undefined}
        {
          <ConditionSetting
            index={i}
            condition={condition}
            urlSettings={urlSettings}
          />
        }
      </div>
    ))}
  </div>
);

export default SegmentationRuleTreeTable;
