import styles from "./EventText.module.scss";

type EventTextProps = {
  text: string;
  onClick: () => void;
  isActive?: boolean;
};

const EventText = ({ text, onClick, isActive = true }: EventTextProps) => {
  return (
    <span
      className={isActive ? styles.eventText : styles.eventText__unavailable}
      onClick={isActive ? () => onClick() : () => {}}
    >
      {text}
    </span>
  );
};

export default EventText;
