import { Redirect, Route, Switch } from "react-router-dom";

import SignInRoute from "./SignInRoute";
import AccountPageGroupRouting from "./AccountPageGroupRouting";
import { CognitoAuthOperations } from "../loaders/CognitoAuth";

// FIXME: Move cognito dependency code to CognitoAuth
import { CognitoUserSession } from "amazon-cognito-identity-js";
import V2Route from "./V2Route";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";

type RootRouteProps = {
  apiEndpointUrl: string;
  session: CognitoUserSession | null;
  operations: CognitoAuthOperations;
  adminTargetAccountId?: string;
  loginAccountId: string;
};

const RootRoute = ({
  apiEndpointUrl,
  session,
  operations,
  adminTargetAccountId,
  loginAccountId,
}: RootRouteProps) => {
  return (
    <Switch>
      <Route path="/login" render={() => <SignInRoute />} />
      <Route path={V2_ROUTING_TABLE.ROOT}>
        <V2Route
          apiEndpointUrl={apiEndpointUrl}
          session={session}
          operations={operations}
          adminTargetAccountId={adminTargetAccountId}
          loginAccountId={loginAccountId}
        />
      </Route>
      <Route>
        <AccountPageGroupRouting
          apiEndpointUrl={apiEndpointUrl}
          session={session}
          operations={operations}
          adminTargetAccountId={adminTargetAccountId}
          loginAccountId={loginAccountId}
          onSessionExpired={() => <Redirect to="/login/session_expired" />}
        />
      </Route>
    </Switch>
  );
};

export default RootRoute;
