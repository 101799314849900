// Global Components
import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import HeadlineText from "atoms/HeadlineText";
import ErrorMessage from "molecules/ErrorMessage";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import EditButton from "atoms/accountPagesShared/EditButton";

// Local Components
import DateRangePickerWrapper from "molecules/DateRangePicker/DateRangePickerWrapper";
import ActionTableHeaderColumn, {
  ActionTableSettings,
} from "organisms/account/actions/atoms/ActionTableHeaderColumn";
import ActionHeader from "organisms/account/actions/molecules/ActionHeader";
import ActionTableItem from "organisms/account/actions/ActionTableItem";
import ActionTable from "organisms/account/actions/ActionTable";
import ActionView from "organisms/account/actions/ActionView";
import ActionName from "organisms/account/actions/ActionName";
import ActionOperation from "organisms/account/actions/molecules/ActionOperation";

import ActionStatusSelect from "molecules/ActionStatusSelect";
import ToolTipActionStatusText from "molecules/ToolTipActionStatusText";
import { Action, ActionStatus, ActionSummary } from "interfaces/models";
import { DateRange, AccountPageContext } from "interfaces/view/accout";
import CopyButton from "atoms/accountPagesShared/CopyButton";
import LoadingView from "organisms/account/LoadingView";
import LoadingIcon from "atoms/LoadingIcon";

export interface ActionCallbacks {
  onDetail: (actionId: string) => void;
  onCreate: (actionId?: string) => void;
  onDelete: (actionId: string) => void;
  onUpdateStatus: (actionId: string, value: ActionStatus) => Promise<void>;
  onEdit: (actionId: string) => void;
}

const CreateActionTable = ({
  actions,
  actionSummaries,
  actionCallbacks,
}: {
  actions?: Action[];
  actionSummaries: { [actionId: string]: ActionSummary };
  actionCallbacks: ActionCallbacks;
}) => {
  if (actions === undefined) {
    return (
      <LoadingView
        iconElement={<LoadingIcon />}
        textElement={
          <div>おもてなしアクション一覧データを読み込み中です。</div>
        }
      />
    );
  }

  const items: JSX.Element[] = actions.map((action) => (
    <ActionTableItem
      key={action.action_id}
      actionNumber={action.details.number}
      actionSummary={actionSummaries[action.action_id]}
      nameElement={
        <ActionName
          name={action.details.action_detail.name}
          operationElement={
            <ActionOperation
              elements={[
                <EditButton
                  onClick={() => actionCallbacks.onEdit(action.action_id)}
                />,
                <CopyButton
                  onClick={() => actionCallbacks.onCreate(action.action_id)}
                />,
                <DeleteButton
                  onClick={() => actionCallbacks.onDelete(action.action_id)}
                />,
              ]}
            />
          }
          onClick={() => actionCallbacks.onDetail(action.action_id)}
        />
      }
      statusSelectElement={
        action.execution_conditions.segmentation_rule_ids.length > 0 ? (
          <ActionStatusSelect
            value={action.status}
            onChange={(nextStatus) => {
              actionCallbacks.onUpdateStatus(action.action_id, nextStatus);
            }}
          />
        ) : (
          <ToolTipActionStatusText
            value={action.status}
            toolTipText="セグメント条件が設定されていないため、有効にできません。"
          />
        )
      }
    />
  ));

  const columns: ActionTableSettings[] = [
    { key: "actionId", header: "No.", align: "left" },
    { key: "actionName", header: "施策名", align: "left" },
    { key: "status", header: "ステータス", align: "left" },
    { key: "displayedCount", header: "表示回数", align: "left" },
    { key: "reactionCount", header: "リアクション数", align: "left" },
    { key: "reactionRate", header: "リアクション率", align: "left" },
    {
      key: "commandExecutedCvCount",
      header: "アクション表示後のCV数",
      align: "left",
    },
  ];

  return items.length > 0 ? (
    <ActionTable
      headerColumnElements={columns.map((props) => (
        <ActionTableHeaderColumn {...props} />
      ))}
      items={items}
    />
  ) : (
    <p>No Actions</p>
  );
};

interface CreateAreaContentsProps {
  context: AccountPageContext;
  errorMessages: string[];
  actions: Action[] | undefined;
  actionSummaries: { [actionId: string]: ActionSummary };
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  actionCallbacks: ActionCallbacks;
  render: (areaContentsElement: JSX.Element) => JSX.Element;
}

const CreateAreaContents = ({
  context,
  errorMessages,
  actions,
  actionSummaries,
  dateRange,
  setDateRange,
  actionCallbacks,
  render,
}: CreateAreaContentsProps): JSX.Element => {
  const contents = (
    <ActionView
      errorMessageElements={errorMessages.map((e) => (
        <ErrorMessage message={e} />
      ))}
      headerElement={
        <ActionHeader
          headlineElement={<HeadlineText text="おもてなしアクション" />}
          createButtonElement={
            <PositiveButton
              text="作成"
              onClick={() => actionCallbacks.onCreate()}
            />
          }
          dataLoadingElement={
            Object.keys(actionSummaries).length > 0 ? undefined : (
              <LoadingIcon />
            )
          }
          dateRangeElement={
            <DateRangePickerWrapper
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          }
        />
      }
      actionTableElement={
        <CreateActionTable
          actions={actions}
          actionSummaries={actionSummaries}
          actionCallbacks={actionCallbacks}
        />
      }
    />
  );

  return render(contents);
};

export default CreateAreaContents;
