import ContentsEditPhaseFormMain from "organisms/account/action_creation/contents_edit/ContentsEditPhaseFormMain";
import ContentsEditPhaseFormMainEditAreaView from "organisms/account/action_creation/contents_edit/ContentsEditPhaseFormMainEditAreaView";
import CreateContentsPreviewArea, {
  ActionContentsPreviewParameters,
} from "../../CreateContentsPreviewArea";
import {
  ActionCreationContentsPCStyleCallbacks,
  ActionCreationContentsStyleCallbacks,
  ContentsSettingPhaseCategory,
  DesignSettingPhaseCategory,
  DESIGN_SETTING_PHASE_CATEGORY,
} from "interfaces/view/actionCreation";
import { assertNever } from "utils/assertions";
import CreateContentsDecorationForm from "./CreateContentsDecorationForm";
import CreateContentsLayoutForm from "./CreateContentsLayoutForm";
import CreateContentsSourceForm from "./CreateContentsSourceForm";
import {
  ActionCreationContentsPCStyleParameters,
  ActionCreationContentsStyleParameters,
  ActionCreationMode,
  ContentsType,
  CustomContentsFormat,
  PositionType,
} from "interfaces/model/actionContentsParameters";
import CategoryHeaderTab from "atoms/accountPagesShared/CategoryHeader";
import { DecorationEditDeviceType } from "interfaces/models";

type CreateContentsEditPhaseFormMainProps = {
  params: {
    mode: ActionCreationMode;
    isEditRawHTML: boolean;
    format: CustomContentsFormat;
    pattern: number;
    designPhaseCategory: DesignSettingPhaseCategory;
    html: string;
    contentsType: ContentsType;
    positionType: PositionType;
    image: File | undefined;
    version: number;
    decorationEditDeviceType: DecorationEditDeviceType;
    isEnablePCStyle: boolean;
  };
  callbacks: {
    setErrorMessage: (value: Object) => void;
    onSelectContentsSettingPhaseCategory(
      category: ContentsSettingPhaseCategory
    ): void;
    onChangeContentsAttribute(
      contentsType: ContentsType,
      positionType: PositionType
    ): void;
    onSelectContentsType(contentsType: ContentsType): void;
    onSelectPositionType(positionType: PositionType): void;
    onSelectContentsPattern(pattern: number): void;
    onClickFormatReselect(): void;
    onSelectDesignPhaseCategory(category: DesignSettingPhaseCategory): void;
    onChangeHtmlSource(source: string): void;
    onClickResetHtml(): void;
    onChangeContentsImage(image: File | undefined): void;
    onChangeDecorationEditDeviceType(type: DecorationEditDeviceType): void;
    onChangeIsEnablePCStyle(): void;
  };
  styles: {
    params: ActionCreationContentsStyleParameters;
    callbacks: ActionCreationContentsStyleCallbacks;
  };
  pcStyles: {
    params: ActionCreationContentsPCStyleParameters;
    callbacks: ActionCreationContentsPCStyleCallbacks;
  };
  preview: ActionContentsPreviewParameters;
};

const CreateContentsEditPhaseFormMain = ({
  params,
  callbacks,
  styles,
  pcStyles,
  preview,
}: CreateContentsEditPhaseFormMainProps) => {
  const categoryHeaders = [
    {
      label: "レイアウト",
      value: DESIGN_SETTING_PHASE_CATEGORY.LAYOUT,
    },
    {
      label: "色・文言",
      value: DESIGN_SETTING_PHASE_CATEGORY.DECORATION,
    },
    {
      label: "HTMLソース",
      additionalLabel: "上級者向け",
      value: DESIGN_SETTING_PHASE_CATEGORY.SOURCE,
    },
  ];
  const designPhaseCategory = params.designPhaseCategory;

  return (
    <ContentsEditPhaseFormMain
      previewAreaElement={<CreateContentsPreviewArea {...preview} />}
      editAreaElement={
        <ContentsEditPhaseFormMainEditAreaView
          categoryHeaderElements={categoryHeaders.map((e) => (
            <CategoryHeaderTab
              label={e.label}
              additionalLabel={e.additionalLabel}
              isActive={e.value === params.designPhaseCategory}
              onClick={() => callbacks.onSelectDesignPhaseCategory(e.value)}
            />
          ))}
          htmlInputElement={
            designPhaseCategory === DESIGN_SETTING_PHASE_CATEGORY.SOURCE ? (
              <CreateContentsSourceForm
                html={params.html}
                isAbleReset={params.version >= 1}
                onChangeHtml={callbacks.onChangeHtmlSource}
                onResetHtml={callbacks.onClickResetHtml}
              />
            ) : designPhaseCategory === DESIGN_SETTING_PHASE_CATEGORY.LAYOUT ? (
              <CreateContentsLayoutForm
                params={{
                  isEditRawHTML: params.isEditRawHTML,
                  format: params.format,
                  pattern: params.pattern,
                  contentsType: params.contentsType,
                  positionType: params.positionType,
                }}
                services={{
                  onSelectContentsSettingPhaseCategory:
                    callbacks.onSelectContentsSettingPhaseCategory,
                  onChangeContentsAttribute:
                    callbacks.onChangeContentsAttribute,
                  onSelectContentsType: callbacks.onSelectContentsType,
                  onSelectPositionType: callbacks.onSelectPositionType,
                  onSelectContentsPattern: callbacks.onSelectContentsPattern,
                  onClickFormatReselect: callbacks.onClickFormatReselect,
                }}
              />
            ) : designPhaseCategory ===
              DESIGN_SETTING_PHASE_CATEGORY.DECORATION ? (
              <CreateContentsDecorationForm
                mode={params.mode}
                params={{
                  isEditRawHTML: params.isEditRawHTML,
                  format: params.format,
                  pattern: params.pattern,
                  image: params.image,
                  decorationEditDeviceType: params.decorationEditDeviceType,
                  isEnablePCStyle: params.isEnablePCStyle,
                }}
                styles={styles}
                pcStyles={pcStyles}
                onChangeContentsImage={callbacks.onChangeContentsImage}
                onChangeIsEnablePCStyle={callbacks.onChangeIsEnablePCStyle}
                onError={callbacks.setErrorMessage}
              />
            ) : (
              assertNever(designPhaseCategory)
            )
          }
        />
      }
    />
  );
};

export default CreateContentsEditPhaseFormMain;
