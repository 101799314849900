import BuildAccountPage from "app/builders/BuildAccountPage";
import { createReportContents } from "app/creators/reports/CreateReportContents";
import useReportDetail from "app/hooks/useReportDetail";
import useReportMetaDataList from "app/hooks/useReportMetaDataList";
import {
  MonthlyReportMetaData,
  RawReport,
  Report,
  ReportMetaData,
  ReportMetaDataList,
  ReportPeriodType,
  ReportType,
  REPORT_PERIOD_TYPE,
  REPORT_TYPE,
  RESPONSE_REPORT_PERIOD_TYPE,
  sortColumnType,
  sortStateType,
  SORT_DIRECTION,
  SORT_STATUS,
  SORT_TARGET_KEY_ID,
} from "interfaces/models";
import { AccountPageContext } from "interfaces/view/accout";
import ReportView from "organisms/account/reports/ReportView";
import { useCallback, useEffect, useState } from "react";
import { Data } from "react-csv/components/CommonPropTypes";
import {
  formatResponseActionDetailCsvData,
  formatResponseWholeCsvData,
} from "utils/report/csvFormatter";
import ReportMetaDataRepository from "utils/ReportMetaDataRepository";
import ReportRepository from "utils/ReportRepository";
import { useFilterReportRowNumber } from "app/hooks/useReportFilter";
import { formatDate, subtractDays } from "utils/FormatDate";

const filterReportMetaDataList = (
  reportMetaDataList: ReportMetaDataList | undefined,
  reportPeriodType: ReportPeriodType,
  reportYearMonth: string | undefined,
  reportType: ReportType
): ReportMetaData | null => {
  let reportMetaData: ReportMetaData[] = [];
  if (reportMetaDataList) {
    if (reportPeriodType === RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH) {
      reportMetaData = reportMetaDataList.current_month;
    } else if (reportPeriodType === RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH) {
      reportMetaData = reportMetaDataList.last_month;
    } else if (reportPeriodType === RESPONSE_REPORT_PERIOD_TYPE.ONE_WEEK) {
      reportMetaData = reportMetaDataList.one_week;
    } else if (reportYearMonth) {
      reportMetaData = reportMetaDataList.monthly[reportYearMonth];
    }
    return reportMetaData
      ? reportMetaData.filter((e) => e.report_type === reportType)[0]
      : null;
  } else {
    return null;
  }
};

const generateCsvFileName = (
  metadata: ReportMetaData | null,
  reportType: ReportType
): string | undefined => {
  if (metadata) {
    const from_datetime = new Date(metadata.from_timestamp_ms);
    const to_datetime = subtractDays(new Date(metadata.to_timestamp_ms), 1);

    const reportTypeLabels = {
      [REPORT_TYPE.WHOLE]: "全体",
      [REPORT_TYPE.ACTION_DETAIL]: "個別",
    };

    return `ezcx_${
      reportTypeLabels[reportType]
    }_アクションレポート_${formatDate(from_datetime)}_${formatDate(
      to_datetime
    )}.csv`;
  }
  return undefined;
};

const ReportPage: React.VFC<{
  context: AccountPageContext;
  reportMetaDataRepository: ReportMetaDataRepository;
  reportRepository: ReportRepository;
}> = ({ context, reportMetaDataRepository, reportRepository }): JSX.Element => {
  const reportMetaDataList:
    | ReportMetaDataList
    | MonthlyReportMetaData
    | undefined = useReportMetaDataList(reportMetaDataRepository);
  const [reportPeriodType, setReportPeriodType] = useState<ReportPeriodType>(
    REPORT_PERIOD_TYPE.CURRENT_MONTH
  );
  const [reportYearMonth, setReportYearMonth] = useState<string>();
  const reportDetail: Report | undefined = useReportDetail(
    reportPeriodType,
    reportMetaDataList,
    reportRepository,
    reportYearMonth,
    filterReportMetaDataList
  );
  const [selectedPageNationNumber, setSelectedPageNationNumber] =
    useState<number>(0);
  const filterReportDetailRowNumber: {
    sliceFromIndex: number;
    sliceToRowIndex: number;
  } = useFilterReportRowNumber(selectedPageNationNumber);

  const [wholeReportCsv, setWholeReportCsv] = useState<Data>();
  const [actionDetailReportCsv, setActionDetailReportCsv] = useState<Data>();
  const [sortStatus, setSortStatus] = useState<sortStateType>(SORT_STATUS);
  const [sortColumnId, setSortColumnId] = useState<sortColumnType>({
    columnId: SORT_TARGET_KEY_ID.no,
    sortDirection: SORT_DIRECTION.ASC,
  });
  const downloadRawCsv = useCallback(
    async (
      type: ReportType,
      period: ReportPeriodType,
      yearMonth: string | undefined,
      setCsv: React.Dispatch<React.SetStateAction<Data | undefined>>,
      formatter: (data: RawReport) => Data
    ) => {
      try {
        const report_path = filterReportMetaDataList(
          reportMetaDataList,
          period,
          yearMonth,
          type
        )?.report_path;

        report_path &&
          setCsv(formatter(await reportRepository.get_raw_csv(report_path)));
      } catch (e) {
        // FIXME: エラーメッセージを表示する。
        console.error(e);
        setCsv(undefined);
      }
    },
    [reportMetaDataList, reportRepository]
  );

  useEffect(() => {
    downloadRawCsv(
      REPORT_TYPE.WHOLE,
      reportPeriodType,
      reportYearMonth,
      setWholeReportCsv,
      formatResponseWholeCsvData
    );
    downloadRawCsv(
      REPORT_TYPE.ACTION_DETAIL,
      reportPeriodType,
      reportYearMonth,
      setActionDetailReportCsv,
      formatResponseActionDetailCsvData
    );
  }, [reportPeriodType, reportYearMonth, downloadRawCsv]);

  const parameters = {
    reportMetaDataList,
    reportPeriodType,
    reportDetail,
    wholeReportCsv: wholeReportCsv,
    actionDetailReportCsv: actionDetailReportCsv,
    wholeCsvFileName: generateCsvFileName(
      filterReportMetaDataList(
        reportMetaDataList,
        reportPeriodType,
        reportYearMonth,
        REPORT_TYPE.WHOLE
      ),
      REPORT_TYPE.WHOLE
    ),
    actionDetailCsvFileName: generateCsvFileName(
      filterReportMetaDataList(
        reportMetaDataList,
        reportPeriodType,
        reportYearMonth,
        REPORT_TYPE.ACTION_DETAIL
      ),
      REPORT_TYPE.ACTION_DETAIL
    ),
    filterReportDetailRowNumber,
    sortColumnId,
    sortStatus,
    reportYearMonth,
    selectedPageNationNumber,
  };

  const callbacks = {
    changeReportPeriodType: (reportPeriodType: ReportPeriodType) => {
      setReportPeriodType(reportPeriodType);
    },
    changeReportYearMonth: (reportYearMonth: string) => {
      setReportYearMonth(reportYearMonth);
    },
    setSortColumnId,
    setSortStatus,
    setReportPeriodType,
    filterReportMetaDataList,
    setSelectedPageNationNumber,
  };
  return (
    <BuildAccountPage
      context={context}
      renderAreaContentsElement={() => {
        const [
          headerElement,
          wholeReportElement,
          actionDetailElement,
          pageNationElements,
        ] = createReportContents(parameters, callbacks);
        return (
          <ReportView
            headerElement={headerElement}
            reportElements={[wholeReportElement, actionDetailElement]}
            pageNationElements={pageNationElements}
          />
        );
      }}
    />
  );
};

export default ReportPage;
