import { useLocation } from "react-router-dom";
import {
  PUSH_NOTIFICATION_GENRE_TYPE,
  PushNotificationGenreListType,
  V2_ROUTING_PATH_PARAMETERS,
} from "app/system_defaults/v2_routing";
import { useMemo } from "react";

export type PushNotificationEditSearchParameters = {
  genre: PushNotificationGenreListType;
};

const usePushNotificationSearchParameters =
  (): PushNotificationEditSearchParameters => {
    const location = useLocation();

    const searchParams = useMemo(
      () => new URLSearchParams(location.search),
      [location.search]
    );

    return {
      genre:
        (searchParams.get(
          V2_ROUTING_PATH_PARAMETERS.ACCOUNT.PUSH_NOTIFICATION.HOME.GENRE
        ) as PushNotificationGenreListType) ??
        PUSH_NOTIFICATION_GENRE_TYPE.SCHEDULED,
    };
  };

export default usePushNotificationSearchParameters;
