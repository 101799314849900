import React from "react";
import fontStyles from "fontStyles.module.scss";

import LoadingIcon from "atoms/LoadingIcon";
import Label from "molecules/v2/Label";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import LinkText from "atoms/LinkText";

const IOSSettingTitleElement: React.VFC = () => (
  <Label
    label={
      <>
        <span className={fontStyles.section}>iOS対応手順のご紹介</span>
        <span className={fontStyles.boldText}> ※必須設定ではありません。</span>
      </>
    }
  />
);

const OtherOptionListElement: React.VFC<{
  link: string;
}> = ({ link }) => {
  if (!link) {
    return <LoadingIcon />;
  }

  return (
    <VerticalSpreadLayout
      margin={5}
      items={[
        <MultipleLineText
          texts={[
            "iOS対応を行いたい場合は、以下のマニュアルを参考に設定を行ってください。",
            <LinkText text={"iOS対応マニュアル"} link={link} external />,
          ]}
        />,
      ]}
    />
  );
};

const OptionConfiguration: React.VFC = () => {
  return (
    <VerticalSpreadLayout
      margin={60}
      items={[
        <VerticalSpreadLayout
          margin={5}
          items={[
            <IOSSettingTitleElement />,
            <OtherOptionListElement link="https://doc.target-push.com/ios.pdf" />,
          ]}
        />,
      ]}
    />
  );
};

export default OptionConfiguration;
