const CSS_TEXT = `
.ezcx-full-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: rgba(102,102,102, .5);
  z-index: 99999;
  animation-name: show;
  animation-duration: 0.5s;
}
.ezcx-bottom-modal {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 5%;
  right: 5%;
  overflow: auto;
  z-index: 99999;
  animation-name: show;
  animation-duration: 0.5s;
}
@media screen and (min-width: 360px) {
  .ezcx-bottom-modal {
    right: auto;
    left:calc(50% - 330px/2);
  }
}
@media screen and (min-width: 768px) {
  .ezcx-bottom-modal {
    left:calc(50% - 500px/2);
  }
}
.ezcx-modal-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
`;

export { CSS_TEXT };
