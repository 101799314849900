import UnderBarTitle from "atoms/ezPushShared/accountPageShared/UnderBarTitle";
import styles from "./UnderBarTitleForm.module.scss";
import React from "react";

const UnderBarTitleForm: React.VFC<{
  title: string | JSX.Element;
  elements: JSX.Element[];
}> = ({ title, elements }) => {
  return (
    <div className={styles.underBarTitleForm}>
      <div className={styles.title}>
        <UnderBarTitle title={title} />
      </div>
      <div className={styles.elements}>
        {elements.map((elem, i) => (
          <div key={i} className={styles.element}>
            {elem}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnderBarTitleForm;
