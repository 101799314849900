import errorIcon from "images/icons/icon_error.svg";
import styles from "./ErrorMessages.module.scss";

const ErrorMessages = ({ messages }: { messages: string[] }) => {
  const errors = messages.map((message, i) => (
    <span key={i} className={styles.errorMessage}>
      <img className={styles.icon} src={errorIcon} alt="ERROR : " />
      <span className={styles.text}>{message}</span>
    </span>
  ));

  return <div className={styles.errorMessages}>{errors}</div>;
};

export default ErrorMessages;
