import { useMemo } from "react";

import { GrantMessageType, GrantMessageV2 } from "types/grant_message";

import fontStyles from "fontStyles.module.scss";

import { GRANT_MESSAGE_TYPE_DISPLAY_WORD } from "app/system_defaults/WordDefaults";
import MiniPanel from "templates/ezPush/MiniPanel";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import LoadingIcon from "atoms/LoadingIcon";
import RadioButtonBase from "atoms/RadioButtonBase";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import LoadingView from "organisms/account/LoadingView";
import GrantMessagePanel from "./GrantMessagePanel";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import GrantMessageListLayout from "templates/ezPush/grantMessage/GrantMessageListLayout";
import TitleMainLayout from "templates/ezPush/TitleMainLayout";

type GrantMessageItemType = {
  messageType: GrantMessageType;
  label: JSX.Element;
};

const grantMessageListOrders: GrantMessageItemType[] = [
  {
    messageType: "browser_default",
    label: (
      <div>
        {GRANT_MESSAGE_TYPE_DISPLAY_WORD["browser_default"]}
        <span style={{ color: "#ff5c38" }}>{"(推奨)"}</span>
      </div>
    ),
  },
  {
    messageType: "custom",
    label: <div>{GRANT_MESSAGE_TYPE_DISPLAY_WORD["custom"]}</div>,
  },
];

const GrantMessageList: React.VFC<{
  grantMessages: GrantMessageV2[] | undefined;
  selectedGrantMessageType: GrantMessageType | null;
  changeGrantMessageType: (grantMessgeType: GrantMessageType) => void;
  submitGrantMessageType: () => void;
  backToHome: () => void;
}> = ({
  grantMessages,
  selectedGrantMessageType,
  changeGrantMessageType,
  submitGrantMessageType,
  backToHome,
}) => {
  // NOTE: elements
  const panelElements = useMemo(() => {
    return grantMessages
      ? grantMessageListOrders.map((item) => {
          const tmp = grantMessages.find(
            (gm) => gm.grantMessage.grant_message_type === item.messageType
          );
          return tmp ? (
            <div onClick={() => changeGrantMessageType(item.messageType)}>
              <MiniPanel
                titleElement={
                  <div style={{ margin: "10px 0px" }}>
                    <HorizontalLayout
                      elements={[
                        <RadioButtonBase
                          isActive={
                            !!selectedGrantMessageType &&
                            selectedGrantMessageType === item.messageType
                          }
                          onClick={() => {}}
                        />,
                        <div className={fontStyles.title}>{item.label}</div>,
                      ]}
                    />
                  </div>
                }
                mainElement={<GrantMessagePanel grantMessage={tmp} />}
              />
            </div>
          ) : (
            <></>
          );
        })
      : [];
  }, [selectedGrantMessageType, changeGrantMessageType, grantMessages]);

  const DesignChangeButton = (
    <HorizontalLayout
      elements={[
        <TransparentButton text={"キャンセル"} onClick={() => backToHome()} />,
        <OrangeButton
          text={"選択したデザインを適用"}
          onClick={() => submitGrantMessageType()}
        />,
      ]}
      alignment="right"
    />
  );

  return (
    <TitleMainLayout
      titleElement={
        <span className={fontStyles.title}>
          許諾メッセージ設定＞デザイン切り替え
        </span>
      }
      mainElement={
        <GrantMessageListLayout
          panelElements={
            grantMessages
              ? panelElements
              : [
                  <LoadingView
                    iconElement={<LoadingIcon />}
                    textElement={<div>データ読み込み中．．．</div>}
                  />,
                ]
          }
          buttonElement={grantMessages ? DesignChangeButton : <></>}
        />
      }
    />
  );
};

export default GrantMessageList;
