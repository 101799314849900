import styles from "./SegmentationRuleCustomerAttributeForm.module.scss";

type SegmentationRuleCustomerAttributeFormProps = {
  typeElement: JSX.Element;
  customerAttributeElements: JSX.Element[];
  separatorElement: JSX.Element;
};

const SegmentationRuleCustomerAttributeForm = ({
  typeElement,
  customerAttributeElements,
  separatorElement,
}: SegmentationRuleCustomerAttributeFormProps) => {
  return (
    <div className={styles.segmentationRuleCustomerAttributeForm}>
      <div className={styles.type}>{typeElement}</div>
      <div className={styles.attributes}>
        {customerAttributeElements.map((e, i) => (
          <div key={i}>
            {i === 0 ? undefined : (
              <div className={styles.separator}>{separatorElement}</div>
            )}
            <div className={styles.attribute}>{e}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentationRuleCustomerAttributeForm;
