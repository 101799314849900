import { sliceByNumber } from "utils/Slice";
import styles from "./PresetFormatSelectPhaseFormMain.module.scss";

const PresetFormatSelectPhaseFormMain = ({
  buttons,
}: {
  buttons: JSX.Element[];
}) => (
  <div className={styles.presetFormatSelectPhaseFormMain}>
    <div className={styles.headline}>
      どのおもてなしアクションを作成しますか？
    </div>
    <div className={styles.buttons}>
      {sliceByNumber(buttons, 4).map((row, i) => {
        return (
          <div key={i} className={styles.row}>
            {row.map((col, j) => (
              <div key={j} className={styles.button}>
                {col}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  </div>
);

export default PresetFormatSelectPhaseFormMain;
