import React from "react";

import styles from "./BreadCrumbs.module.scss";
import fontStyles from "fontStyles.module.scss";

import { Link } from "react-router-dom";

export type BreadCrumbPath = {
  name: string;
  url: string;
};

const BreadCrumbs: React.VFC<{ paths: BreadCrumbPath[] }> = ({ paths }) => {
  return (
    <nav className={fontStyles.note}>
      <ul className={styles.breadCrumbs}>
        {paths.map((path, index) => (
          <li key={index} className={styles.item}>
            {index === paths.length - 1 ? (
              <span>{path.name}</span>
            ) : (
              <>
                <Link className={styles.link} to={path.url}>
                  {path.name}
                </Link>
                <span>{" ＞ "}</span>
              </>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default BreadCrumbs;
