import {
  CreateDestinationCollectRuleRequest,
  CreateDestinationCollectRuleResponse,
  DeleteDestinationCollectRuleRequest,
  DeleteDestinationCollectRuleResponse,
  GetDestinationCollectRuleRequest,
  GetDestinationCollectRuleResponse,
  LoadDestinationCollectRuleResponse,
  UpdateDestinationCollectRuleRequest,
  UpdateDestinationCollectRuleResponse,
} from "types/destination_collect_rule";
import RestApi from "../RestApi";

class DestinationCollectRuleRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async load(): Promise<LoadDestinationCollectRuleResponse> {
    const data = await this.managementApi.get("destination_collect_rules");
    return data;
  }

  async get(
    request: GetDestinationCollectRuleRequest
  ): Promise<GetDestinationCollectRuleResponse> {
    const data = await this.managementApi.get(
      `destination_collect_rules/${request.destination_collect_rule_uuid}`
    );
    return data;
  }

  async create(
    request: CreateDestinationCollectRuleRequest
  ): Promise<CreateDestinationCollectRuleResponse> {
    const data = await this.managementApi.post(
      "destination_collect_rules",
      request
    );
    return data;
  }

  async delete(
    request: DeleteDestinationCollectRuleRequest
  ): Promise<DeleteDestinationCollectRuleResponse> {
    const data = await this.managementApi.delete(
      `destination_collect_rules/${request.destination_collect_rule_uuid}`
    );
    return data;
  }

  async update(
    request: UpdateDestinationCollectRuleRequest
  ): Promise<UpdateDestinationCollectRuleResponse> {
    const data = await this.managementApi.put(
      "destination_collect_rules",
      request
    );
    return data;
  }
}

export default DestinationCollectRuleRepository;
