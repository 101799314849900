import styles from "./NamedFormView.module.scss";

const NamedFormView: React.VFC<{
  titleElement: JSX.Element;
  formElement?: JSX.Element;
  toggleElement?: JSX.Element;
}> = ({ titleElement, formElement, toggleElement }) => {
  return (
    <div className={styles.formElement}>
      <div className={styles.title}>{titleElement}</div>
      <div className={styles.form}>
        {toggleElement ? <div>{toggleElement}</div> : null}
        {formElement ? <div>{formElement}</div> : null}
      </div>
    </div>
  );
};

export default NamedFormView;
