import styles from "./ContentsEditPhaseFormMain.module.scss";

const ContentsEditPhaseFormMain = ({
  previewAreaElement,
  editAreaElement,
}: {
  previewAreaElement: JSX.Element;
  editAreaElement: JSX.Element;
}) => (
  <div className={styles.ContentsEditPhaseFormMain}>
    <div className={styles.preview}>{previewAreaElement}</div>
    <div className={styles.form}>{editAreaElement}</div>
  </div>
);

export default ContentsEditPhaseFormMain;
