import EventText from "atoms/accountPagesShared/EventText";
import styles from "./ImageUploader.module.scss";
import PositiveButton from "atoms/accountPagesShared/PositiveButton";
import React, { useRef } from "react";

const ImageUploaderInputForm: React.VFC<{
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  onChange: (value: File | null) => void;
}> = ({ inputRef, onChange }) => {
  return (
    <input
      type="file"
      ref={inputRef}
      accept="image/png,image/jpeg,image/gif,.jpg,.gif,.png"
      onChange={(e) => onChange(e.target.files ? e.target.files[0] : null)}
      style={{ display: "none" }}
    />
  );
};

const ImageUploaderConfirmButtons = ({
  image,
  onChange,
}: {
  image: File;
  onChange: (value: File | null) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const elements: JSX.Element[] = [
    <div className={styles.fileName}>{image.name}</div>,
    <EventText
      text="変更"
      onClick={() => {
        inputRef.current?.click();
      }}
    />,
    <EventText text="削除" onClick={() => onChange(null)} />,
  ];
  return (
    <div className={styles.imageUploaderConfirmButtons}>
      <ImageUploaderInputForm inputRef={inputRef} onChange={onChange} />
      {elements.map((e, i) => (
        <div key={i} className={styles.element}>
          {e}
        </div>
      ))}
    </div>
  );
};

const ImageUploaderChoiceButton = ({
  onChange,
}: {
  onChange: (value: File | null) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <>
      <ImageUploaderInputForm inputRef={inputRef} onChange={onChange} />
      <PositiveButton
        text="アップロード"
        onClick={() => {
          inputRef.current?.click();
        }}
      />
    </>
  );
};

const ImageUploaderButton = ({
  image,
  onChange,
}: {
  image: File | null;
  onChange: (value: File | null) => void;
}) => {
  return image ? (
    <ImageUploaderConfirmButtons image={image} onChange={onChange} />
  ) : (
    <ImageUploaderChoiceButton onChange={onChange} />
  );
};

export default ImageUploaderButton;
