import { useCallback, useEffect, useMemo, useState } from "react";
import AccountRepository from "utils/AccountRepository";
import { Accesses, Account } from "interfaces/models";
import RestApi from "utils/RestApi";
import AccessesRepository from "utils/AccessesRepository";
import { RecorderRepository } from "utils/RecorderRepositoy";

type LoadAccountProps = {
  managementApi: RestApi;
  onSystemError: () => void;
  render: (
    account: Account | undefined,
    accesses: Accesses | undefined,
    errorMessage: string
  ) => JSX.Element;
};

const LoadAccount = ({
  managementApi,
  onSystemError,
  render,
}: LoadAccountProps) => {
  const accountRepository = useMemo(
    () => new AccountRepository(managementApi),
    [managementApi]
  );

  const accessesRepository = useMemo(
    () => new AccessesRepository(managementApi),
    [managementApi]
  );

  const recorderRepository = useMemo(
    () => new RecorderRepository(managementApi),
    [managementApi]
  );

  const [account, setAccount] = useState<Account>();
  const [accesses, setAccesses] = useState<Accesses>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loadAccount = useCallback(async () => {
    try {
      setAccount(await accountRepository.get());
    } catch (error) {
      setErrorMessage("アカウント設定読み込みに失敗しました");
      recorderRepository.record_error(error);

      onSystemError();
    }
  }, [accountRepository, recorderRepository, onSystemError]);

  const loadAccesses = useCallback(async () => {
    try {
      setAccesses(await accessesRepository.get());
    } catch (error) {
      setErrorMessage("サイト統計データ読み込みに失敗しました");
    }
  }, [accessesRepository]);

  useEffect(() => {
    loadAccount();
  }, [loadAccount]);

  useEffect(() => {
    loadAccesses();
  }, [loadAccesses]);

  return render(account, accesses, errorMessage);
};

export default LoadAccount;
