import BuildAccountPage from "app/builders/BuildAccountPage";

import { AccountPageContext } from "interfaces/view/accout";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import CreateSegmentationRuleCreationView from "app/creators/segmentation_rule_creation/CreateSegmentationRuleCreationView";
import useSearchParameters from "app/hooks/useSearchParameters";
import useSegmentationRuleCreationService from "app/hooks/useSegmentationRuleCreationService";
import CustomerAttributeHeaderRepository from "utils/CustomerAttributeHeaderRepository";

type SegmentationRuleCreationPageProps = {
  context: AccountPageContext;
  segmentationRuleRepository: SegmentationRuleRepository;
  customerAttributeHeaderRepository: CustomerAttributeHeaderRepository;
};

const SegmentationRuleCreationPage = ({
  context,
  segmentationRuleRepository,
  customerAttributeHeaderRepository,
}: SegmentationRuleCreationPageProps) => {
  const [searchParameters, updateSearchParameters] = useSearchParameters();
  const [status, callbacks] = useSegmentationRuleCreationService(
    segmentationRuleRepository,
    customerAttributeHeaderRepository,
    searchParameters,
    updateSearchParameters
  );

  return (
    <BuildAccountPage
      context={context}
      renderAreaContentsElement={() => (
        <CreateSegmentationRuleCreationView
          params={status}
          callbacks={callbacks}
        />
      )}
    />
  );
};

export default SegmentationRuleCreationPage;
