import { useEffect, useMemo, useState } from "react";
import {
  ActionCreationCallbacks,
  ActionCreationContentsCallbacks,
  ActionCreationContentsPCStyleCallbacks,
  ActionCreationContentsStyleCallbacks,
  ActionCreationCustomCallbacks,
  ActionCreationPresetCallbacks,
  ActionCreationPresetParameters,
  ActionCreationSettingsContentsSetters,
  ActionCreationSettingsExecuteConditionsSetters,
  ActionCreationSettingsSetters,
  ActionCreationSystemCallbacks,
  ActionOperationMode,
  ACTION_OPERATION_MODE,
} from "interfaces/view/actionCreation";
import {
  ActionExecutionConditions,
  ActionFrequency,
  ActionStatus,
  ActionTerminateReaction,
  ActionTypes,
  ACTION_TYPES,
  ExecuteTiming,
  ExpirationPeriod,
  UrlPattern,
} from "interfaces/models";

import {
  CONTENTS_VERSION,
  DEFAULT_CONTENTS_STYLE,
  DEFAULT_LOGIN_FOLLOW_SEGMENTATION_RULE,
} from "app/system_defaults/action_creation_defaults";
import { loadCustomFormatPatterns } from "utils/action_creation/CustomContentsPatterns";
import { SegmentationRuleCreationRequest } from "interfaces/requests";
import {
  ContentsType,
  ActionContentsParameters,
  ACTION_CREATION_MODE,
  ActionCreationMode,
  CONTENTS_TYPE,
  CustomContentsFormat,
  ActionCreationContentsStyleParameters,
  PositionType,
  POSITION_TYPE,
  PresetContentsFormat,
  PRESET_CONTENTS_FORMAT,
  CUSTOM_CONTENTS_FORMAT,
  CustomContentsFormParameters,
  ActionCreationContentsPCStyleParameters,
} from "interfaces/model/actionContentsParameters";
import {
  ColorType,
  COLOR_TYPE,
} from "molecules/GradationColorPicker/ColorTypeSelector";
import largeImageIcon from "images/preview/265x265.png";

export type ActionContents = {
  abTestARate: number;
  actionType: ActionTypes;
  html: string;
  details?: ActionContentsParameters;
};

export type Action = {
  uuid: string;
  status: ActionStatus;
  name: string;
  number: number | undefined;
  contents: ActionContents;
  executionConditions: ActionExecutionConditions;
  cvUrl: string;
};

export type ActionCreationSettingsSegmentationRules =
  | {
      create: true;
      segmentationRuleCreationSettings: SegmentationRuleCreationRequest;
    }
  | {
      create: false;
      segmentationRuleIds: string[];
    };

export type ActionCreationSettingsExecutionConditions = {
  segmentationRules: ActionCreationSettingsSegmentationRules;
  expirationPeriod: ExpirationPeriod | null;
  frequency: ActionFrequency;
  terminateReaction: ActionTerminateReaction;
  urlCondition: ActionCreation.UrlCondition;
  executeTimings: ExecuteTiming[];
};

export type ActionCreationSettingsContents = {
  contentsType: ContentsType;
  abTestARate: number;
  image?: File;
  htmlTemplate: string;
  details: ActionContentsParameters;
};

export type ActionCreationSettings = {
  name: string;
  cvUrl: string;
  executionConditions: ActionCreationSettingsExecutionConditions;
  contents: ActionCreationSettingsContents;
};

export type ActionContentsSetters = {
  actionCreationContentsCallbacks: ActionCreationContentsCallbacks;
  actionCreationContentsPCStyleCallbacks: ActionCreationContentsPCStyleCallbacks;
  actionCreationContentsStyleCallbacks: ActionCreationContentsStyleCallbacks;
};

export const useActionCreationPagePresetState = (): [
  ActionCreationPresetParameters,
  ActionCreationPresetCallbacks
] => {
  const [numberOfExcludeLocation, setNumberOfExcludeLocation] =
    useState<number>(1);
  const [isCompleteSettings, setIsCompleteSettings] = useState<boolean>(false);
  const [loginErrorCount, setLoginErrorCount] = useState<number>(
    DEFAULT_LOGIN_FOLLOW_SEGMENTATION_RULE.errorCount
  );
  const [loginUrl, setLoginUrl] = useState<UrlPattern>(
    DEFAULT_LOGIN_FOLLOW_SEGMENTATION_RULE.loginUrl
  );
  const [numberOfLoginUrlParameter, setNumberOfLoginUrlParameter] =
    useState<number>(1);
  const [continuousLoginErrorCondition, setContinuousLoginErrorCondition] =
    useState<boolean>(false);

  const presetParams: ActionCreationPresetParameters = useMemo(
    () => ({
      numberOfExcludeLocation,
      isCompleteSettings,
      loginErrorCount,
      loginUrl,
      numberOfLoginUrlParameter,
      continuousLoginErrorCondition,
    }),
    [
      numberOfExcludeLocation,
      isCompleteSettings,
      loginErrorCount,
      loginUrl,
      numberOfLoginUrlParameter,
      continuousLoginErrorCondition,
    ]
  );

  const presetCallbacks: ActionCreationPresetCallbacks = useMemo(
    () => ({
      setNumberOfExcludeLocation: setNumberOfExcludeLocation,
      setIsCompleteSettings: setIsCompleteSettings,
      setLoginErrorCount: setLoginErrorCount,
      setLoginUrl: setLoginUrl,
      setNumberOfLoginUrlParameter: setNumberOfLoginUrlParameter,
      setContinuousLoginErrorCondition: setContinuousLoginErrorCondition,
    }),
    []
  );

  return [presetParams, presetCallbacks];
};

export type PageCallbacks = {
  system: ActionCreationSystemCallbacks;
  allocated: ActionCreationCallbacks;
  mode: {
    [ACTION_CREATION_MODE.CUSTOM]: ActionCreationCustomCallbacks;
    [ACTION_CREATION_MODE.PRESET]: ActionCreationPresetCallbacks;
  };
};

const useActionCreationSettingsContentsState = (
  mode: ActionCreationMode | undefined,
  defaultValue?:
    | {
        abTestARate: number;
        actionType: ActionTypes;
        html: string;
        details?: ActionContentsParameters;
      }
    | undefined
): [ActionCreationSettingsContents, ActionCreationSettingsContentsSetters] => {
  const [abTestARate, setAbTestARate] = useState<number>(1.0);
  const [htmlTemplate, setHtmlTemplate] = useState<string>("");
  const [image, setImage] = useState<File | undefined>();
  const [contentsType, setContentsType] = useState<ContentsType>(
    CONTENTS_TYPE.FULL
  );
  const [actionContents, actionContentsSetters] = useActionContentsParameters(
    mode,
    contentsType,
    defaultValue?.details
  );

  const contents: ActionCreationSettingsContents = useMemo(
    () => ({
      abTestARate,
      contentsType,
      htmlTemplate,
      image,
      details: actionContents,
    }),
    [abTestARate, contentsType, htmlTemplate, image, actionContents]
  );

  useEffect(() => {
    if (defaultValue) {
      setHtmlTemplate(defaultValue.html);
      setAbTestARate(defaultValue.abTestARate);
      if (defaultValue.details) {
        setContentsType(defaultValue.details.contentsType);
      } else {
        setContentsType(
          defaultValue.actionType === ACTION_TYPES.FULL_MODAL
            ? CONTENTS_TYPE.FULL
            : CONTENTS_TYPE.PARTIAL
        );
      }
    }
  }, [defaultValue]);

  const contentsSetters: ActionCreationSettingsContentsSetters = useMemo(
    () => ({
      setAbTestARate,
      setContentsType,
      setHtmlTemplate,
      setImage,
      details: actionContentsSetters,
    }),
    [actionContentsSetters]
  );

  return [contents, contentsSetters];
};

const useUrlCondition = (): [
  ActionCreation.UrlCondition,
  {
    setIncludes: (v: ManagementApiData.UrlMatchPattern[]) => void;
    setExcludes: (v: ManagementApiData.UrlMatchPattern[]) => void;
  }
] => {
  const [includes, setIncludes] = useState<ManagementApiData.UrlMatchPattern[]>(
    []
  );
  const [excludes, setExcludes] = useState<ManagementApiData.UrlMatchPattern[]>(
    []
  );

  const urlCondition = useMemo(
    () => ({
      includes: includes,
      excludes: excludes,
    }),
    [includes, excludes]
  );

  const setters = useMemo(
    () => ({
      setIncludes,
      setExcludes,
    }),
    []
  );

  return [urlCondition, setters];
};

export const useActionCreationSettingsState = (
  operation: ActionOperationMode,
  mode: ActionCreationMode | undefined,
  sourceAction?: Action,
  defaultCvUrl?: string
): [ActionCreationSettings, ActionCreationSettingsSetters] => {
  const [name, setName] = useState<string>("");
  const [cvUrl, setCvUrl] = useState<string>(defaultCvUrl ?? "");

  const [contents, contentsSetters] = useActionCreationSettingsContentsState(
    mode,
    sourceAction?.contents
  );

  const [expirationPeriod, setExpirationPeriod] =
    useState<ExpirationPeriod | null>(null);

  const [frequency, setFrequency] = useState<ActionFrequency>("SESSION_ONCE");
  const [terminateReaction, setTerminateReaction] =
    useState<ActionTerminateReaction>("NONE");
  const [segmentationRules, setSegmentationRules] =
    useState<ActionCreationSettingsSegmentationRules>({
      create: false,
      segmentationRuleIds: [],
    });

  const [urlCondition, urlConditionSetters] = useUrlCondition();
  const [executeTimings, setExecuteTimings] = useState<ExecuteTiming[]>([]);

  const executionConditions: ActionCreationSettingsExecutionConditions =
    useMemo(
      () => ({
        expirationPeriod,
        frequency,
        terminateReaction,
        segmentationRules,
        urlCondition,
        executeTimings,
      }),
      [
        expirationPeriod,
        frequency,
        terminateReaction,
        segmentationRules,
        urlCondition,
        executeTimings,
      ]
    );
  const executionConditionSetters: ActionCreationSettingsExecuteConditionsSetters =
    useMemo(
      () => ({
        setSegmentationRules,
        setExpirationPeriod,
        setFrequency,
        setTerminateReaction,
        urlConditionSetters,
        setExecuteTimings,
      }),
      [urlConditionSetters]
    );

  useEffect(() => {
    if (sourceAction) {
      setName(
        operation === ACTION_OPERATION_MODE.EDIT
          ? sourceAction.name
          : `${sourceAction.name}_copy`
      );
      setCvUrl(sourceAction.cvUrl ?? defaultCvUrl);
      executionConditionSetters.setExpirationPeriod(
        sourceAction.executionConditions.expiration_period
      );
      executionConditionSetters.setFrequency(
        sourceAction.executionConditions.frequency
      );
      executionConditionSetters.setTerminateReaction(
        sourceAction.executionConditions.terminate_reaction
      );
      executionConditionSetters.setSegmentationRules({
        create: false,
        segmentationRuleIds:
          sourceAction.executionConditions.segmentation_rule_ids,
      });
      executionConditionSetters.setExecuteTimings(
        JSON.parse(
          JSON.stringify(sourceAction.executionConditions.execute_timing)
        )
      );
      // XXX: urlCondition is initialized by callback process by molecules
    }
  }, [operation, executionConditionSetters, sourceAction, defaultCvUrl]);

  const actionCreationSettingsSetters: ActionCreationSettingsSetters = useMemo(
    () => ({
      setName,
      setCvUrl,
      executeConditions: executionConditionSetters,
      contents: contentsSetters,
    }),
    [contentsSetters, executionConditionSetters]
  );

  const actionCreationSettings = useMemo(
    () => ({
      name,
      cvUrl,
      executionConditions,
      contents,
    }),
    [name, cvUrl, executionConditions, contents]
  );

  return [actionCreationSettings, actionCreationSettingsSetters];
};

const useActionCreationContentsPCStyleState = (
  defaultSPValue?: ActionCreationContentsStyleParameters | undefined,
  defaultPCValue?: ActionCreationContentsPCStyleParameters | undefined
): [
  ActionCreationContentsPCStyleParameters,
  ActionCreationContentsPCStyleCallbacks
] => {
  // contents base
  const [contentsBaseTopPadding, setContentsBaseTopPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.PADDING.TOP
  );
  const [contentsBaseBottomPadding, setContentsBaseBottomPadding] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.PADDING.BOTTOM);
  const [contentsBaseRightPadding, setContentsBaseRightPadding] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.PADDING.RIGHT);
  const [contentsBaseLeftPadding, setContentsBaseLeftPadding] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.PADDING.LEFT);
  const [contentsBaseRadius, setContentsBaseRadius] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.BORDER.RADIUS
  );
  const [contentsBaseWidth, setContentsBaseWidth] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.SIZE.WIDTH
  );
  const [contentsBaseMaxHeight, setContentsBaseMaxHeight] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BASE.SIZE.MAX_HEIGHT
  );
  const [contentsBaseHeight, setContentsBaseHeight] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.SIZE.HEIGHT
  );
  const [contentsBaseHeightAdjust, setContentsBaseHeightAdjust] =
    useState<boolean>(DEFAULT_CONTENTS_STYLE.BASE.SIZE.IS_HEIGHT_ADJUST);

  // headline
  const [headlineTextSize, setHeadlineTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT_SIZE
  );

  // sub headline
  const [subHeadlineTextSize, setSubHeadlineTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.TEXT.SIZE
  );
  const [subHeadlineBackgroundRadius, setSubHeadlineBackgroundRadius] =
    useState<number>(DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.RADIUS);
  const [subHeadlineBackgroundTopPadding, setSubHeadlineBackgroundTopPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.TOP
    );
  const [
    subHeadlineBackgroundBottomPadding,
    setSubHeadlineBackgroundBottomPadding,
  ] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.BOTTOM
  );
  const [
    subHeadlineBackgroundRightPadding,
    setSubHeadlineBackgroundRightPadding,
  ] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.RIGHT
  );
  const [
    subHeadlineBackgroundLeftPadding,
    setSubHeadlineBackgroundLeftPadding,
  ] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.LEFT
  );

  // article
  const [articleTextSize, setArticleTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.TEXT.TEXT_SIZE
  );

  // button
  const [buttonTextSize, setButtonTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.SIZE
  );
  const [buttonBorderRadius, setButtonBorderRadius] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.BORDER.RADIUS
  );
  const [buttonTopPadding, setButtonTopPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.TOP
  );
  const [buttonBottomPadding, setButtonBottomPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.BOTTOM
  );
  const [buttonRightPadding, setButtonRightPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.RIGHT
  );
  const [buttonLeftPadding, setButtonLeftPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.LEFT
  );

  // image
  const [imageMagnification, setImageMagnification] = useState<number>(
    DEFAULT_CONTENTS_STYLE.IMAGE.MAGNIFICATION
  );

  // coupon code
  const [couponCodeTextSize, setCouponCodeTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.SIZE
  );

  // coupon copy button
  const [couponButtonRadius, setCouponButtonRadius] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.RADIUS
  );
  const [couponButtonTextSize, setCouponButtonTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.SIZE
  );
  const [couponButtonTopPadding, setCouponButtonTopPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.TOP
  );
  const [couponButtonBottomPadding, setCouponButtonBottomPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.BOTTOM
    );
  const [couponButtonRightPadding, setCouponButtonRightPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.RIGHT
    );
  const [couponButtonLeftPadding, setCouponButtonLeftPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.LEFT
    );

  // close button
  const [closeButtonSize, setCloseButtonSize] = useState<string>(
    DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.SIZE
  );

  useEffect(() => {
    if (defaultSPValue && defaultPCValue) {
      setContentsBaseWidth(
        defaultPCValue.contentsBase.background.width ??
          defaultSPValue.contentsBaseWidth ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.WIDTH
      );
      setContentsBaseHeight(
        defaultPCValue.contentsBase.background.height ??
          defaultSPValue.contentsBaseHeight ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.HEIGHT
      );
      setContentsBaseMaxHeight(
        defaultPCValue.contentsBase.background.maxHeight ??
          defaultSPValue.contentsBaseMaxHeight ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.MAX_HEIGHT
      );
      setContentsBaseHeightAdjust(
        defaultPCValue.contentsBase.background.heightAdjust ??
          defaultSPValue.contentsBaseHeightAdjust ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.IS_HEIGHT_ADJUST
      );
      setContentsBaseTopPadding(
        defaultPCValue.contentsBase.background.padding.top ??
          defaultSPValue.contentsBaseTopPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.TOP
      );
      setContentsBaseBottomPadding(
        defaultPCValue.contentsBase.background.padding.bottom ??
          defaultSPValue.contentsBaseBottomPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.BOTTOM
      );
      setContentsBaseRightPadding(
        defaultPCValue.contentsBase.background.padding.right ??
          defaultSPValue.contentsBaseRightPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.RIGHT
      );
      setContentsBaseLeftPadding(
        defaultPCValue.contentsBase.background.padding.left ??
          defaultSPValue.contentsBaseLeftPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.LEFT
      );
      setContentsBaseRadius(
        defaultPCValue.contentsBase.border.radius ??
          defaultSPValue.contentsBaseRadius ??
          DEFAULT_CONTENTS_STYLE.BASE.BORDER.RADIUS
      );
      setHeadlineTextSize(
        defaultPCValue.headline.text.size ??
          defaultSPValue.headlineTextSize ??
          DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT_SIZE
      );
      setSubHeadlineBackgroundTopPadding(
        defaultPCValue.subHeadline.background.padding.top ??
          defaultSPValue.subHeadlineBackgroundTopPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.TOP
      );
      setSubHeadlineBackgroundBottomPadding(
        defaultPCValue.subHeadline.background.padding.bottom ??
          defaultSPValue.subHeadlineBackgroundBottomPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.BOTTOM
      );
      setSubHeadlineBackgroundRightPadding(
        defaultPCValue.subHeadline.background.padding.right ??
          defaultSPValue.subHeadlineBackgroundRightPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.RIGHT
      );
      setSubHeadlineBackgroundLeftPadding(
        defaultPCValue.subHeadline.background.padding.left ??
          defaultSPValue.subHeadlineBackgroundLeftPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.LEFT
      );
      setSubHeadlineBackgroundRadius(
        defaultPCValue.subHeadline.background.radius ??
          defaultSPValue.subHeadlineBackgroundRadius ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.RADIUS
      );
      setSubHeadlineTextSize(
        defaultPCValue.subHeadline.text.size ??
          defaultSPValue.subHeadlineTextSize ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.TEXT.SIZE
      );
      setArticleTextSize(
        defaultPCValue.article.size ??
          defaultSPValue.textSize ??
          DEFAULT_CONTENTS_STYLE.TEXT.TEXT_SIZE
      );
      setButtonBorderRadius(
        defaultPCValue.button.background.radius ??
          defaultSPValue.buttonBorderRadius ??
          DEFAULT_CONTENTS_STYLE.BUTTON.BORDER.RADIUS
      );
      setButtonTopPadding(
        defaultPCValue.button.background.padding.top ??
          defaultSPValue.buttonTopPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.TOP
      );
      setButtonBottomPadding(
        defaultPCValue.button.background.padding.bottom ??
          defaultSPValue.buttonBottomPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.BOTTOM
      );
      setButtonRightPadding(
        defaultPCValue.button.background.padding.right ??
          defaultSPValue.buttonRightPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.RIGHT
      );
      setButtonLeftPadding(
        defaultPCValue.button.background.padding.left ??
          defaultSPValue.buttonLeftPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.LEFT
      );
      setButtonTextSize(
        defaultPCValue.button.text.size ??
          defaultSPValue.buttonTextSize ??
          DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.SIZE
      );
      setImageMagnification(
        defaultPCValue.image.magnification ??
          defaultSPValue.imageMagnification ??
          DEFAULT_CONTENTS_STYLE.IMAGE.MAGNIFICATION
      );
      setCouponCodeTextSize(
        defaultPCValue.couponCode.text.size ??
          defaultSPValue.couponCodeTextSize ??
          DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.SIZE
      );
      setCouponButtonRadius(
        defaultPCValue.couponCopyButton.background.radius ??
          defaultSPValue.couponButtonRadius ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.RADIUS
      );
      setCouponButtonTopPadding(
        defaultPCValue.couponCopyButton.background.padding.top ??
          defaultSPValue.couponButtonTopPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.TOP
      );
      setCouponButtonBottomPadding(
        defaultPCValue.couponCopyButton.background.padding.bottom ??
          defaultSPValue.couponButtonBottomPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.BOTTOM
      );
      setCouponButtonRightPadding(
        defaultPCValue.couponCopyButton.background.padding.right ??
          defaultSPValue.couponButtonRightPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.RIGHT
      );
      setCouponButtonLeftPadding(
        defaultPCValue.couponCopyButton.background.padding.left ??
          defaultSPValue.couponButtonLeftPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.LEFT
      );
      setCouponButtonTextSize(
        defaultPCValue.couponCopyButton.text.size ??
          defaultSPValue.couponButtonTextSize ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.SIZE
      );
      setCloseButtonSize(
        defaultPCValue.closeButton.size ??
          defaultSPValue.closeButtonSize ??
          DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.SIZE
      );
    }
  }, [defaultSPValue, defaultPCValue]);

  const parameters: ActionCreationContentsPCStyleParameters = {
    contentsBase: {
      background: {
        width: contentsBaseWidth,
        height: contentsBaseHeight,
        maxHeight: contentsBaseMaxHeight,
        heightAdjust: contentsBaseHeightAdjust,
        padding: {
          top: contentsBaseTopPadding,
          bottom: contentsBaseBottomPadding,
          right: contentsBaseRightPadding,
          left: contentsBaseLeftPadding,
        },
      },
      border: {
        radius: contentsBaseRadius,
      },
    },
    headline: {
      text: {
        size: headlineTextSize,
      },
    },
    subHeadline: {
      background: {
        radius: subHeadlineBackgroundRadius,
        padding: {
          top: subHeadlineBackgroundTopPadding,
          bottom: subHeadlineBackgroundBottomPadding,
          right: subHeadlineBackgroundRightPadding,
          left: subHeadlineBackgroundLeftPadding,
        },
      },
      text: {
        size: subHeadlineTextSize,
      },
    },
    article: {
      size: articleTextSize,
    },
    button: {
      background: {
        radius: buttonBorderRadius,
        padding: {
          top: buttonTopPadding,
          bottom: buttonBottomPadding,
          right: buttonRightPadding,
          left: buttonLeftPadding,
        },
      },
      text: {
        size: buttonTextSize,
      },
    },
    couponCode: { text: { size: couponCodeTextSize } },
    couponCopyButton: {
      background: {
        radius: couponButtonRadius,
        padding: {
          top: couponButtonTopPadding,
          bottom: couponButtonBottomPadding,
          right: couponButtonRightPadding,
          left: couponButtonLeftPadding,
        },
      },
      text: {
        size: couponButtonTextSize,
      },
    },
    image: {
      magnification: imageMagnification,
    },
    closeButton: {
      size: closeButtonSize,
    },
  };

  const callbacks: ActionCreationContentsPCStyleCallbacks = {
    contentsBase: {
      background: {
        setWidth: setContentsBaseWidth,
        setHeight: setContentsBaseHeight,
        setMaxHeight: setContentsBaseMaxHeight,
        setHeightAdjust: setContentsBaseHeightAdjust,
        padding: {
          setTop: setContentsBaseTopPadding,
          setBottom: setContentsBaseBottomPadding,
          setRight: setContentsBaseRightPadding,
          setLeft: setContentsBaseLeftPadding,
        },
      },
      border: {
        setRadius: setContentsBaseRadius,
      },
    },
    headline: {
      text: {
        setSize: setHeadlineTextSize,
      },
    },
    subHeadline: {
      background: {
        setRadius: setSubHeadlineBackgroundRadius,
        padding: {
          setTop: setSubHeadlineBackgroundTopPadding,
          setBottom: setSubHeadlineBackgroundBottomPadding,
          setRight: setSubHeadlineBackgroundRightPadding,
          setLeft: setSubHeadlineBackgroundLeftPadding,
        },
      },
      text: {
        setSize: setSubHeadlineTextSize,
      },
    },
    article: {
      setSize: setArticleTextSize,
    },
    button: {
      background: {
        setRadius: setButtonBorderRadius,
        padding: {
          setTop: setButtonTopPadding,
          setBottom: setButtonBottomPadding,
          setRight: setButtonRightPadding,
          setLeft: setButtonLeftPadding,
        },
      },
      text: {
        setSize: setButtonTextSize,
      },
    },
    image: {
      setMagnification: setImageMagnification,
    },
    couponCode: {
      text: {
        setSize: setCouponCodeTextSize,
      },
    },
    couponCopyButton: {
      background: {
        setRadius: setCouponButtonRadius,
        padding: {
          setTop: setCouponButtonTopPadding,
          setBottom: setCouponButtonBottomPadding,
          setRight: setCouponButtonRightPadding,
          setLeft: setCouponButtonLeftPadding,
        },
      },
      text: {
        setSize: setCouponButtonTextSize,
      },
    },
    closeButton: {
      setSize: setCloseButtonSize,
    },
  };

  return [parameters, callbacks];
};

const useActionCreationContentsStyleState = (
  defaultValue?: ActionCreationContentsStyleParameters | undefined
): [
  ActionCreationContentsStyleParameters,
  ActionCreationContentsStyleCallbacks
] => {
  const [backgroundColor, setBackgroundColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BASE.BACKGROUND_COLOR
  );
  const [borderColor, setBorderColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BASE.BORDER.COLOR
  );
  const [subHeadlineBackgroundColor, setSubHeadlineBackgroundColor] =
    useState<string>(DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.COLOR);
  const [subHeadlineTextColor, setSubHeadlineTextColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.TEXT.COLOR
  );
  const [subHeadlineText, setSubHeadlineText] = useState<string>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.TEXT.TEXT
  );
  const [subHeadlineTextSize, setSubHeadlineTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.TEXT.SIZE
  );
  const [subHeadlineBackgroundRadius, setSubHeadlineBackgroundRadius] =
    useState<number>(DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.RADIUS);
  const [subHeadlineBackgroundTopPadding, setSubHeadlineBackgroundTopPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.TOP
    );
  const [
    subHeadlineBackgroundBottomPadding,
    setSubHeadlineBackgroundBottomPadding,
  ] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.BOTTOM
  );
  const [
    subHeadlineBackgroundRightPadding,
    setSubHeadlineBackgroundRightPadding,
  ] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.RIGHT
  );
  const [
    subHeadlineBackgroundLeftPadding,
    setSubHeadlineBackgroundLeftPadding,
  ] = useState<number>(
    DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.LEFT
  );
  const [headlineTextColor, setHeadlineTextColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT_COLOR
  );
  const [headlineText, setHeadlineText] = useState<string>(
    DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT
  );
  const [headlineTextSize, setHeadlineTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT_SIZE
  );
  const [textColor, setTextColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.TEXT.TEXT_COLOR
  );
  const [textSize, setTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.TEXT.TEXT_SIZE
  );
  const [text, setText] = useState<string>(DEFAULT_CONTENTS_STYLE.TEXT.TEXT);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BUTTON.BACKGROUND_COLOR
  );
  const [buttonTextColor, setButtonTextColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.COLOR
  );
  const [buttonText, setButtonText] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.TEXT
  );
  const [buttonLinkText, setButtonLinkText] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BUTTON.LINK_TEXT
  );
  const [isOpenOtherWindow, setIsOpenOtherWindow] = useState<boolean>(
    DEFAULT_CONTENTS_STYLE.BUTTON.IS_OPEN_OTHER_WINDOW
  );
  const [buttonTextSize, setButtonTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.SIZE
  );
  const [buttonBorderRadius, setButtonBorderRadius] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.BORDER.RADIUS
  );
  const [buttonTopPadding, setButtonTopPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.TOP
  );
  const [buttonBottomPadding, setButtonBottomPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.BOTTOM
  );
  const [buttonRightPadding, setButtonRightPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.RIGHT
  );
  const [buttonLeftPadding, setButtonLeftPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.LEFT
  );
  const [isButtonShadow, setIsButtonShadow] = useState<boolean>(
    DEFAULT_CONTENTS_STYLE.BUTTON.IS_BUTTON_SHADOW
  );
  const [buttonBackgroundGradationColor, setButtonBackgroundGradationColor] =
    useState<string[]>(
      DEFAULT_CONTENTS_STYLE.BUTTON.GRADATION_BACKGROUND_COLOR
    );
  const [buttonColorType, setButtonColorType] = useState<ColorType>(
    COLOR_TYPE.SIMPLE
  );
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageMagnification, setImageMagnification] = useState<number>(
    DEFAULT_CONTENTS_STYLE.IMAGE.MAGNIFICATION
  );
  const [imageLinkText, setImageLinkText] = useState<string>();
  const [couponCodeText, setCouponCodeText] = useState<string>(
    DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.TEXT
  );
  const [couponCodeTextSize, setCouponCodeTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.SIZE
  );
  const [couponCodeTextColor, setCouponCodeTextColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.COLOR
  );
  const [couponCodeBackgroundColor, setCouponCodeBackgroundColor] =
    useState<string>(DEFAULT_CONTENTS_STYLE.COUPON.CODE.BACKGROUND.COLOR);
  const [couponButtonColor, setCouponButtonColor] = useState<string[]>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.COLOR
  );
  const [couponButtonColorType, setCouponButtonColorType] = useState<ColorType>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.COLOR_TYPE
  );
  const [couponButtonRadius, setCouponButtonRadius] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.RADIUS
  );
  const [couponButtonTextColor, setCouponButtonTextColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.COLOR
  );
  const [couponButtonText, setCouponButtonText] = useState<string>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.TEXT
  );
  const [couponButtonTextSize, setCouponButtonTextSize] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.SIZE
  );
  const [couponButtonTopPadding, setCouponButtonTopPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.TOP
  );
  const [couponButtonBottomPadding, setCouponButtonBottomPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.BOTTOM
    );
  const [couponButtonRightPadding, setCouponButtonRightPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.RIGHT
    );
  const [couponButtonLeftPadding, setCouponButtonLeftPadding] =
    useState<number>(
      DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.LEFT
    );
  const [couponButtonShadow, setCouponButtonShadow] = useState<boolean>(
    DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.IS_SHADOW
  );
  const [contentsBaseTopPadding, setContentsBaseTopPadding] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.PADDING.TOP
  );
  const [contentsBaseBottomPadding, setContentsBaseBottomPadding] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.PADDING.BOTTOM);
  const [contentsBaseRightPadding, setContentsBaseRightPadding] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.PADDING.RIGHT);
  const [contentsBaseLeftPadding, setContentsBaseLeftPadding] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.PADDING.LEFT);
  const [contentsBaseRadius, setContentsBaseRadius] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.BORDER.RADIUS
  );
  const [contentsBaseWidth, setContentsBaseWidth] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.SIZE.WIDTH
  );

  const [closeButtonBackgroundColor, setCloseButtonBackgroundColor] =
    useState<string>(DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.BACKGROUND_COLOR);
  const [closeButtonCrossColor, setCloseButtonCrossColor] = useState<string>(
    DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.CROSS_COLOR
  );
  const [closeButtonSize, setCloseButtonSize] = useState<string>(
    DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.SIZE
  );
  const [contentsBaseMaxHeight, setContentsBaseMaxHeight] = useState<string>(
    DEFAULT_CONTENTS_STYLE.BASE.SIZE.MAX_HEIGHT
  );
  const [contentsBaseHeight, setContentsBaseHeight] = useState<number>(
    DEFAULT_CONTENTS_STYLE.BASE.SIZE.HEIGHT
  );
  const [contentsBaseBorderWidth, setContentsBaseBorderWidth] =
    useState<number>(DEFAULT_CONTENTS_STYLE.BASE.BORDER.WIDTH);
  const [contentsBaseHeightAdjust, setContentsBaseHeightAdjust] =
    useState<boolean>(DEFAULT_CONTENTS_STYLE.BASE.SIZE.IS_HEIGHT_ADJUST);

  useEffect(() => {
    if (defaultValue) {
      setBackgroundColor(defaultValue.backgroundColor);
      setBorderColor(defaultValue.borderColor);
      setSubHeadlineBackgroundColor(defaultValue.subHeadlineBackgroundColor);
      setSubHeadlineTextColor(defaultValue.subHeadlineTextColor);
      setSubHeadlineText(defaultValue.subHeadlineText);
      setSubHeadlineTextSize(defaultValue.subHeadlineTextSize);
      setSubHeadlineBackgroundRadius(
        defaultValue.subHeadlineBackgroundRadius ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.RADIUS
      );
      setSubHeadlineBackgroundTopPadding(
        defaultValue.subHeadlineBackgroundTopPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.TOP
      );
      setSubHeadlineBackgroundBottomPadding(
        defaultValue.subHeadlineBackgroundBottomPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.BOTTOM
      );
      setSubHeadlineBackgroundRightPadding(
        defaultValue.subHeadlineBackgroundRightPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.RIGHT
      );
      setSubHeadlineBackgroundLeftPadding(
        defaultValue.subHeadlineBackgroundLeftPadding ??
          DEFAULT_CONTENTS_STYLE.SUB_HEADLINE.BACKGROUND.PADDING.LEFT
      );
      setHeadlineTextColor(defaultValue.headlineTextColor);
      setHeadlineText(defaultValue.headlineText);
      setHeadlineTextSize(
        defaultValue.headlineTextSize ??
          DEFAULT_CONTENTS_STYLE.HEADLINE.TEXT_SIZE
      );
      setTextColor(defaultValue.textColor);
      setText(defaultValue.text);
      setTextSize(
        defaultValue.textSize ?? DEFAULT_CONTENTS_STYLE.TEXT.TEXT_SIZE
      );
      setButtonBackgroundColor(defaultValue.buttonBackgroundColor);
      setButtonTextColor(defaultValue.buttonTextColor);
      setButtonText(defaultValue.buttonText);
      setButtonLinkText(defaultValue.buttonLinkText);
      setButtonBorderRadius(
        defaultValue.buttonBorderRadius ??
          DEFAULT_CONTENTS_STYLE.BUTTON.BORDER.RADIUS
      );
      setIsOpenOtherWindow(defaultValue.isOpenOtherWindow);
      setButtonTopPadding(
        defaultValue.buttonTopPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.TOP
      );
      setButtonBottomPadding(
        defaultValue.buttonBottomPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.BOTTOM
      );
      setButtonRightPadding(
        defaultValue.buttonRightPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.RIGHT
      );
      setButtonLeftPadding(
        defaultValue.buttonLeftPadding ??
          DEFAULT_CONTENTS_STYLE.BUTTON.PADDING.LEFT
      );
      setIsButtonShadow(
        defaultValue.isButtonShadow ??
          DEFAULT_CONTENTS_STYLE.BUTTON.IS_BUTTON_SHADOW
      );
      setButtonBackgroundGradationColor(
        defaultValue.buttonBackgroundGradationColor
          ? defaultValue.buttonBackgroundGradationColor
          : defaultValue.buttonBackgroundColor
          ? [
              defaultValue.buttonBackgroundColor,
              DEFAULT_CONTENTS_STYLE.BUTTON.GRADATION_BACKGROUND_COLOR[1],
            ]
          : DEFAULT_CONTENTS_STYLE.BUTTON.GRADATION_BACKGROUND_COLOR
      );
      setButtonColorType(
        defaultValue.buttonColorType ?? DEFAULT_CONTENTS_STYLE.BUTTON.COLOR_TYPE
      );
      setImageUrl(defaultValue.imageUrl);
      setImageMagnification(
        defaultValue.imageMagnification ??
          DEFAULT_CONTENTS_STYLE.IMAGE.MAGNIFICATION
      );
      setCouponCodeText(
        defaultValue.couponCodeText ??
          DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.TEXT
      );
      setCouponCodeTextSize(
        defaultValue.couponCodeTextSize ??
          DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.SIZE
      );
      setCouponCodeTextColor(
        defaultValue.couponCodeTextColor ??
          DEFAULT_CONTENTS_STYLE.COUPON.CODE.TEXT.COLOR
      );
      setCouponCodeBackgroundColor(
        defaultValue.couponCodeBackgroundColor ??
          DEFAULT_CONTENTS_STYLE.COUPON.CODE.BACKGROUND.COLOR
      );
      setCouponButtonColor(
        defaultValue.couponButtonColor ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.COLOR
      );
      setCouponButtonRadius(
        defaultValue.couponButtonRadius ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.RADIUS
      );
      setCouponButtonTextColor(
        defaultValue.couponButtonTextColor ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.COLOR
      );
      setCouponButtonText(
        defaultValue.couponButtonText ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.TEXT
      );
      setCouponButtonTextSize(
        defaultValue.couponButtonTextSize ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.TEXT.SIZE
      );
      setCouponButtonTopPadding(
        defaultValue.couponButtonTopPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.TOP
      );
      setCouponButtonBottomPadding(
        defaultValue.couponButtonBottomPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.BOTTOM
      );
      setCouponButtonRightPadding(
        defaultValue.couponButtonRightPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.RIGHT
      );
      setCouponButtonLeftPadding(
        defaultValue.couponButtonLeftPadding ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.PADDING.LEFT
      );
      setCouponButtonShadow(
        defaultValue.couponButtonShadow ??
          DEFAULT_CONTENTS_STYLE.COUPON.BUTTON.BACKGROUND.IS_SHADOW
      );
      setContentsBaseRightPadding(
        defaultValue.contentsBaseRightPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.RIGHT
      );
      setContentsBaseLeftPadding(
        defaultValue.contentsBaseLeftPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.LEFT
      );
      setImageLinkText(defaultValue.imageLinkText);
      setCouponButtonColor(defaultValue.couponButtonColor);
      setCouponCodeText(defaultValue.couponCodeText);
      setCouponButtonTextColor(defaultValue.couponButtonTextColor);
      setCouponButtonText(defaultValue.couponButtonText);
      setContentsBaseTopPadding(
        defaultValue.contentsBaseTopPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.TOP
      );
      setContentsBaseBottomPadding(
        defaultValue.contentsBaseBottomPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.BOTTOM
      );
      setContentsBaseRightPadding(
        defaultValue.contentsBaseRightPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.RIGHT
      );
      setContentsBaseLeftPadding(
        defaultValue.contentsBaseLeftPadding ??
          DEFAULT_CONTENTS_STYLE.BASE.PADDING.LEFT
      );
      setCloseButtonBackgroundColor(
        defaultValue.closeButtonBackgroundColor ??
          DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.BACKGROUND_COLOR
      );
      setCloseButtonCrossColor(
        defaultValue.closeButtonCrossColor ??
          DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.CROSS_COLOR
      );
      setCloseButtonSize(
        defaultValue.closeButtonSize ?? DEFAULT_CONTENTS_STYLE.CLOSE_BUTTON.SIZE
      );
      setContentsBaseRadius(
        defaultValue.contentsBaseRadius ??
          DEFAULT_CONTENTS_STYLE.BASE.BORDER.RADIUS
      );
      setContentsBaseWidth(
        defaultValue.contentsBaseWidth ?? DEFAULT_CONTENTS_STYLE.BASE.SIZE.WIDTH
      );
      setContentsBaseMaxHeight(
        defaultValue.contentsBaseMaxHeight ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.MAX_HEIGHT
      );
      setContentsBaseHeight(
        defaultValue.contentsBaseHeight ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.HEIGHT
      );
      setContentsBaseBorderWidth(
        defaultValue.contentsBaseBorderWidth ??
          DEFAULT_CONTENTS_STYLE.BASE.BORDER.WIDTH
      );
      setContentsBaseHeightAdjust(
        defaultValue.contentsBaseHeightAdjust ??
          DEFAULT_CONTENTS_STYLE.BASE.SIZE.IS_HEIGHT_ADJUST
      );
      setButtonTextSize(
        defaultValue.buttonTextSize ?? DEFAULT_CONTENTS_STYLE.BUTTON.TEXT.SIZE
      );
    }
  }, [defaultValue]);

  const actionCreationContentsStyleParameters: ActionCreationContentsStyleParameters =
    useMemo(
      () => ({
        backgroundColor,
        borderColor,
        subHeadlineBackgroundColor,
        subHeadlineTextColor,
        subHeadlineText,
        subHeadlineTextSize,
        subHeadlineBackgroundRadius,
        subHeadlineBackgroundTopPadding,
        subHeadlineBackgroundBottomPadding,
        subHeadlineBackgroundRightPadding,
        subHeadlineBackgroundLeftPadding,
        headlineTextColor,
        headlineText,
        headlineTextSize,
        textColor,
        text,
        textSize,
        buttonBackgroundColor,
        buttonTextColor,
        buttonText,
        buttonLinkText,
        buttonBorderRadius,
        isOpenOtherWindow,
        buttonTopPadding,
        buttonBottomPadding,
        buttonRightPadding,
        buttonLeftPadding,
        isButtonShadow,
        buttonBackgroundGradationColor,
        buttonColorType,
        imageUrl: imageUrl || largeImageIcon,
        imageMagnification,
        imageLinkText: imageLinkText || "",
        couponCodeText,
        couponCodeTextSize,
        couponCodeTextColor,
        couponCodeBackgroundColor,
        couponButtonColor,
        couponButtonRadius,
        couponButtonTextColor,
        couponButtonText,
        couponButtonTextSize,
        couponButtonTopPadding,
        couponButtonBottomPadding,
        couponButtonRightPadding,
        couponButtonLeftPadding,
        couponButtonShadow,
        couponButtonColorType,
        contentsBaseTopPadding,
        contentsBaseBottomPadding,
        contentsBaseRightPadding,
        contentsBaseLeftPadding,
        contentsBaseRadius,
        contentsBaseWidth,
        contentsBaseMaxHeight,
        contentsBaseHeight,
        contentsBaseBorderWidth,
        contentsBaseHeightAdjust,
        closeButtonBackgroundColor,
        closeButtonCrossColor,
        closeButtonSize,
        buttonTextSize,
      }),
      [
        backgroundColor,
        borderColor,
        subHeadlineBackgroundColor,
        subHeadlineTextColor,
        subHeadlineText,
        subHeadlineTextSize,
        subHeadlineBackgroundRadius,
        subHeadlineBackgroundTopPadding,
        subHeadlineBackgroundBottomPadding,
        subHeadlineBackgroundRightPadding,
        subHeadlineBackgroundLeftPadding,
        headlineTextColor,
        headlineText,
        headlineTextSize,
        textColor,
        text,
        textSize,
        buttonBackgroundColor,
        buttonTextColor,
        buttonText,
        buttonLinkText,
        buttonBorderRadius,
        isOpenOtherWindow,
        buttonTopPadding,
        buttonBottomPadding,
        buttonRightPadding,
        buttonLeftPadding,
        isButtonShadow,
        buttonBackgroundGradationColor,
        buttonColorType,
        imageUrl,
        imageMagnification,
        imageLinkText,
        couponCodeText,
        couponCodeTextSize,
        couponCodeTextColor,
        couponCodeBackgroundColor,
        couponButtonColor,
        couponButtonRadius,
        couponButtonTextColor,
        couponButtonText,
        couponButtonTextSize,
        couponButtonTopPadding,
        couponButtonBottomPadding,
        couponButtonRightPadding,
        couponButtonLeftPadding,
        couponButtonShadow,
        couponButtonColorType,
        contentsBaseTopPadding,
        contentsBaseBottomPadding,
        contentsBaseRightPadding,
        contentsBaseLeftPadding,
        contentsBaseRadius,
        contentsBaseWidth,
        contentsBaseMaxHeight,
        contentsBaseHeight,
        contentsBaseBorderWidth,
        contentsBaseHeightAdjust,
        closeButtonBackgroundColor,
        closeButtonCrossColor,
        closeButtonSize,
        buttonTextSize,
      ]
    );

  const actionCreationContentsStyleCallbacks: ActionCreationContentsStyleCallbacks =
    useMemo(
      () => ({
        setBackgroundColor,
        setBorderColor,
        setSubHeadlineBackgroundColor,
        setSubHeadlineTextColor,
        setSubHeadlineText,
        setSubHeadlineTextSize,
        setSubHeadlineBackgroundRadius,
        setSubHeadlineBackgroundTopPadding,
        setSubHeadlineBackgroundBottomPadding,
        setSubHeadlineBackgroundRightPadding,
        setSubHeadlineBackgroundLeftPadding,
        setHeadlineTextColor,
        setHeadlineText,
        setHeadlineTextSize,
        setTextColor,
        setText,
        setTextSize,
        setButtonBackgroundColor,
        setButtonTextColor,
        setButtonText,
        setButtonLinkText,
        setButtonBorderRadius,
        setIsOpenOtherWindow,
        setButtonTopPadding,
        setButtonBottomPadding,
        setButtonRightPadding,
        setButtonLeftPadding,
        setIsButtonShadow,
        setButtonBackgroundGradationColor,
        setButtonColorType,
        setImageUrl,
        setImageMagnification,
        setImageLinkText,
        setCouponCodeText,
        setCouponCodeTextSize,
        setCouponCodeTextColor,
        setCouponCodeBackgroundColor,
        setCouponButtonColor,
        setCouponButtonRadius,
        setCouponButtonTextColor,
        setCouponButtonText,
        setCouponButtonTextSize,
        setCouponButtonTopPadding,
        setCouponButtonBottomPadding,
        setCouponButtonRightPadding,
        setCouponButtonLeftPadding,
        setCouponButtonShadow,
        setCouponButtonColorType,
        setContentsBaseTopPadding,
        setContentsBaseBottomPadding,
        setContentsBaseRightPadding,
        setContentsBaseLeftPadding,
        setContentsBaseRadius,
        setContentsBaseWidth,
        setContentsBaseMaxHeight,
        setContentsBaseHeight,
        setContentsBaseBorderWidth,
        setContentsBaseHeightAdjust,
        setCloseButtonBackgroundColor,
        setCloseButtonCrossColor,
        setCloseButtonSize,
        setButtonTextSize,
      }),
      []
    );

  return [
    actionCreationContentsStyleParameters,
    actionCreationContentsStyleCallbacks,
  ];
};

export const useActionContentsParameters = (
  mode: ActionCreationMode | undefined,
  contentsType: ContentsType,
  defaultValue?: ActionContentsParameters
): [ActionContentsParameters, ActionContentsSetters] => {
  const [isEditingRawHtml, setEditingRawHtml] = useState<boolean>(false);
  const [positionType, setPositionType] = useState<PositionType>(
    POSITION_TYPE.CENTER
  );
  const [presetFormat, setPresetFormat] = useState<PresetContentsFormat>(
    PRESET_CONTENTS_FORMAT.LOGIN_FOLLOW
  );
  const [customFormat, setCustomFormat] = useState<CustomContentsFormat>(
    CUSTOM_CONTENTS_FORMAT.TEXT_ONLY
  );
  const [url, setUrl] = useState<string | undefined>("");
  const [template, setTemplate] = useState<string>("");
  const [patternIndex, setPatternIndex] = useState<number>(0);
  const [formatPatterns] = useState<CustomContentsFormParameters[]>(
    loadCustomFormatPatterns(customFormat)
  );
  const [version, setVersion] = useState<number>(CONTENTS_VERSION);
  const [isEnablePCStyle, setIsEnablePCStyle] = useState<boolean>(false);

  const [
    actionCreationContentsStyleParameters,
    actionCreationContentsStyleCallbacks,
  ] = useActionCreationContentsStyleState(defaultValue?.styles);

  const [
    actionCreationContentsPCStyleParameters,
    actionCreationContentsPCStyleCallbacks,
  ] = useActionCreationContentsPCStyleState(
    defaultValue?.styles,
    defaultValue?.pcStyles
  );

  useEffect(() => {
    if (defaultValue) {
      setEditingRawHtml(defaultValue.isEditingRawHtml);
      setPositionType(defaultValue.positionType);
      setPresetFormat(defaultValue.presetFormat);
      setCustomFormat(defaultValue.customFormat);
      setUrl(defaultValue.url);
      setTemplate(defaultValue.template);
      setPatternIndex(defaultValue.patternIndex);
      setVersion(defaultValue.version);
      setIsEnablePCStyle(defaultValue.isEnablePCStyle ?? false);
    }
  }, [defaultValue]);

  useEffect(() => {
    setPatternIndex(0);
  }, [formatPatterns]);

  useEffect(() => {
    if (!defaultValue) {
      setEditingRawHtml(true);
    }
  }, [defaultValue, setEditingRawHtml]);

  const actionContentsParameters: ActionContentsParameters = useMemo(
    () => ({
      version,
      mode,
      isEditingRawHtml,
      contentsType,
      positionType,
      presetFormat,
      customFormat,
      url,
      template,
      patternIndex,
      formatPatterns,
      isEnablePCStyle,
      styles: actionCreationContentsStyleParameters,
      pcStyles: actionCreationContentsPCStyleParameters,
    }),
    [
      version,
      isEditingRawHtml,
      actionCreationContentsStyleParameters,
      contentsType,
      customFormat,
      formatPatterns,
      mode,
      patternIndex,
      positionType,
      presetFormat,
      template,
      url,
      actionCreationContentsPCStyleParameters,
      isEnablePCStyle,
    ]
  );

  const actionContentsSetters: ActionContentsSetters = useMemo(
    () => ({
      actionCreationContentsStyleCallbacks,
      actionCreationContentsPCStyleCallbacks,
      actionCreationContentsCallbacks: {
        setEditingRawHtml,
        setPositionType,
        setPresetFormat,
        setFormat: setCustomFormat,
        setUrl,
        setTemplate,
        setPattern: setPatternIndex,
        setIsEnablePCStyle,
      },
    }),
    [
      actionCreationContentsStyleCallbacks,
      actionCreationContentsPCStyleCallbacks,
    ]
  );

  return [actionContentsParameters, actionContentsSetters];
};
