import styles from "./ReportHeader.module.scss";

const ReportHeader: React.VFC<{
  titleElement: JSX.Element;
  updateTimeElement: JSX.Element;
  reportSelectorElement: JSX.Element;
}> = ({ titleElement, updateTimeElement, reportSelectorElement }) => {
  return (
    <div className={styles.reportHeader}>
      <div className={styles.titleArea}>
        <div className={styles.title}>{titleElement}</div>
        <div className={styles.updateTime}>{updateTimeElement}</div>
      </div>
      <div className={styles.functionArea}>{reportSelectorElement}</div>
    </div>
  );
};

export default ReportHeader;
