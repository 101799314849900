import { useMemo, useState } from "react";

export type GrantMessageContentsCloseButtonDesignParameters = {
  backgroundColor: string;
  crossColor: string;
};

export type GrantMessageContentsCloseButtonDesignCallbacks = {
  updateBackgroundColor: (value: string) => void;
  updateCrossColor: (value: string) => void;
};

export const useGrantMessageContentsCloseButtonDesign = (
  currentParameters: GrantMessageContentsCloseButtonDesignParameters
): [
  GrantMessageContentsCloseButtonDesignParameters,
  GrantMessageContentsCloseButtonDesignCallbacks
] => {
  const [backgroundColor, setBackgroundColor] = useState<string>(
    currentParameters.backgroundColor
  );

  const [crossColor, setCrossColor] = useState<string>(
    currentParameters.crossColor
  );

  const parameters = useMemo(() => {
    return {
      backgroundColor,
      crossColor,
    };
  }, [backgroundColor, crossColor]);

  const callbacks = useMemo(() => {
    return {
      updateBackgroundColor: (value: string) => {
        setBackgroundColor(value);
      },
      updateCrossColor: (value: string) => {
        setCrossColor(value);
      },
    };
  }, [setBackgroundColor, setCrossColor]);

  return [parameters, callbacks];
};
