import DecorationFormView from "organisms/account/action_creation/contents_edit/DecorationFormView";
import { getPresetFormPattern } from "utils/action_creation/PresetContentsPatterns";
import { ActionCreationPresetServiceCallbacks } from "utils/action_creation/ActionCreationPresetServiceCallbacks";
import {
  ActionCreationPresetFormCallbacks,
  ActionCreationPresetFormParameters,
} from "interfaces/view/actionCreation";
import createEditFormItems from "../../CreateEditFormItems";
import ContentsEditPhaseFormMain from "organisms/account/action_creation/contents_edit/ContentsEditPhaseFormMain";
import CreateContentsPreviewArea from "../../CreateContentsPreviewArea";

type CreatePresetContentsEditPhaseFormEditAreaFormProps = {
  params: ActionCreationPresetFormParameters;
  callbacks: ActionCreationPresetFormCallbacks;
  services: ActionCreationPresetServiceCallbacks;
};

const CreatePresetContentsEditPhaseFormEditAreaForm = ({
  params,
  callbacks,
  services,
}: CreatePresetContentsEditPhaseFormEditAreaFormProps) => {
  const formParams = getPresetFormPattern(params.contents.presetFormat);
  if (formParams) {
    return (
      <ContentsEditPhaseFormMain
        previewAreaElement={
          <CreateContentsPreviewArea
            url={params.contents.url ? params.contents.url : ""}
            html={params.settings.html}
            actionType={params.settings.actionType}
            onChangeUrl={callbacks.contents.setUrl}
          />
        }
        editAreaElement={
          <DecorationFormView
            elements={createEditFormItems(
              formParams.parts,
              params.contents.image,
              params.styles,
              callbacks.styles,
              (file) => {
                services.onChangeContentsImage(file);
              }
            )}
          />
        }
      />
    );
  }

  return <>ERROR</>;
};

export default CreatePresetContentsEditPhaseFormEditAreaForm;
