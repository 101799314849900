import EventText from "atoms/accountPagesShared/EventText";
import { CustomPicker, InjectedColorProps } from "react-color";
import Hue from "react-color/lib/components/common/Hue";
import Saturation from "react-color/lib/components/common/Saturation";
import styles from "./SliderColorPicker.module.scss";
import EZCXEditableInput from "./EditableInput";

type SliderColorPickerProps = {
  onChangePicker: () => void;
};

const SliderColorPicker = (
  props: InjectedColorProps & SliderColorPickerProps
) => {
  const circleStyle: React.CSSProperties = {
    backgroundColor: props.hex,
  };

  return (
    <div className={styles.sliderColorPicker}>
      <div className={styles.main}>
        <div style={circleStyle} className={styles.colorCircle} />
        <div className={styles.sliders}>
          <div className={styles.hue}>
            <Hue
              {...props}
              onChange={(c, e) => (props.onChange ? props.onChange(c) : null)}
              pointer={() => {
                return <div className={styles.slider} />;
              }}
            />
          </div>
          <div className={styles.saturation}>
            <Saturation
              {...props}
              onChange={(c, e) => (props.onChange ? props.onChange(c) : null)}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.editableInput}>
          <EZCXEditableInput
            hex={props.hex}
            onChange={(c) => (props.onChange ? props.onChange(c) : null)}
          />
        </div>
        <div>
          <EventText text="標準色から選ぶ" onClick={props.onChangePicker} />
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(SliderColorPicker);
