import styles from "./TagModalDescription.module.scss";

export const TagModalDescription = () => {
  return (
    <div className={styles.description}>
      <p>下記の計測タグをコピーし、対象ページに設置ください。</p>
      <p className={styles.notice}>
        {"※設置個所は<head>タグ内、<body>タグ内のどちらでも動作します。"}
      </p>
    </div>
  );
};

export default TagModalDescription;
