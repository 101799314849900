import React from "react";

import errorIcon from "images/icons/icon_error.svg";

import styles from "./ErrorMessages.module.scss";
import fontStyles from "fontStyles.module.scss";

import ErrorMessage from "utils/ErrorMessage";

const ErrorMessages: React.VFC<{ errors: ErrorMessage[] }> = ({ errors }) => {
  return (
    <div className={styles.errorMessages}>
      {errors.map((e, i) => (
        <div key={i} className={styles.message}>
          <img src={errorIcon} alt="ERROR : " className={styles.icon} />
          <span className={fontStyles.text}>{e.message}</span>
        </div>
      ))}
    </div>
  );
};

export default ErrorMessages;
