import {
  GrantMessageContentsLayoutCallbacks,
  GrantMessageContentsLayoutParameters,
} from "app/hooks/v2/grantMessage/useGrantMessageContentsLayout";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import {
  GRANT_MESSAGE_LAYOUT_TYPE,
  GRANT_MESSAGE_MODAL_DISPLAY_POSITION,
  GRANT_MESSAGE_POP_UP_DISPLAY_POSITION,
  GrantMessageLayoutType,
} from "interfaces/v2/grantMessageContents";
import RadioButton from "molecules/RadioButton/RadioButton";
import GrayBackTitleForm from "molecules/v2/GrayBackTitleForm";
import UnderBarTitleForm from "molecules/v2/UnderBarTitleForm";
import styles from "./GrantMessageLayoutEditForm.module.scss";
import MarginSettingForm from "molecules/v2/MarginSettingForm";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";

const LAYOUT_TYPE_CHOICES = [
  { label: "ポップアップ", type: GRANT_MESSAGE_LAYOUT_TYPE.POP_UP },
  { label: "モーダル", type: GRANT_MESSAGE_LAYOUT_TYPE.MODAL },
] as const;

const DISPLAY_POSITION_MODAL_CHOICES: OptionType[] = [
  { label: "中央", value: GRANT_MESSAGE_MODAL_DISPLAY_POSITION.CENTER },
];

const DISPLAY_POSITION_POP_UP_CHOICES: OptionType[] = [
  {
    label: "中央下",
    value: GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_BOTTOM,
  },
  {
    label: "左下",
    value: GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.LEFT_BOTTOM,
  },
  {
    label: "左上",
    value: GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.LEFT_TOP,
  },
  {
    label: "中央上",
    value: GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_TOP,
  },
  {
    label: "右上",
    value: GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.RIGHT_TOP,
  },
  {
    label: "右下",
    value: GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.RIGHT_BOTTOM,
  },
];

const getDisplayPositionChoices = (
  layoutType: GrantMessageLayoutType
): OptionType[] => {
  if (layoutType === GRANT_MESSAGE_LAYOUT_TYPE.MODAL) {
    return DISPLAY_POSITION_MODAL_CHOICES;
  } else if (layoutType === GRANT_MESSAGE_LAYOUT_TYPE.POP_UP) {
    return DISPLAY_POSITION_POP_UP_CHOICES;
  }
  throw new TypeError(`INVALID_LAYOUT_TYPE_DETECTED: ${layoutType}`);
};

const GrantMessageEditLayoutForm = ({
  parameters,
  callbacks,
}: {
  parameters: GrantMessageContentsLayoutParameters;
  callbacks: GrantMessageContentsLayoutCallbacks;
}): JSX.Element => (
  <GrayBackTitleForm
    title="レイアウト設定"
    elements={[
      <UnderBarTitleForm
        title="タイプ"
        elements={LAYOUT_TYPE_CHOICES.map((elem, i) => (
          <RadioButton
            key={i}
            label={elem.label}
            isActive={parameters.layoutType === elem.type}
            onClick={() => callbacks.updateType(elem.type)}
          />
        ))}
      />,
      <UnderBarTitleForm
        title="配置"
        elements={[
          <div className={styles.displayPositionSelectBox}>
            <BorderedSelectBox
              options={getDisplayPositionChoices(parameters.layoutType)}
              onChange={(value) => callbacks.updateDisplayPosition(value)}
              value={parameters.displayPosition}
            />
          </div>,
        ]}
      />,
      parameters.layoutType === GRANT_MESSAGE_LAYOUT_TYPE.POP_UP ? (
        <MarginSettingForm
          topMargin={parameters.topMargin}
          bottomMargin={parameters.bottomMargin}
          updateTopMargin={callbacks.updateTopMargin}
          updateBottomMargin={callbacks.updateBottomMargin}
        />
      ) : (
        <></>
      ),
    ]}
  />
);

export default GrantMessageEditLayoutForm;
