import NoticeText from "atoms/accountPagesShared/NoticeText";
import styles from "./SegmentationRuleDescriptionView.module.scss";

type SegmentationRuleDescriptionViewProps = {
  title: string;
  descriptionBox: JSX.Element;
};

const SegmentationRuleDescriptionView = ({
  title,
  descriptionBox,
}: SegmentationRuleDescriptionViewProps) => {
  return (
    <div className={styles.segmentationRuleDescriptionView}>
      <div className={styles.name}>{title}</div>
      <div className={styles.description}>{descriptionBox}</div>
    </div>
  );
};

type SegmentationRuleDescriptionListProps = {
  list: SegmentationRuleDescriptionViewProps[];
};

const SegmentationRuleDescriptionList = ({
  list,
}: SegmentationRuleDescriptionListProps) => {
  return (
    <div className={styles.segmentationRuleDescriptionList}>
      {list.map((e, i) => (
        <>
          {i !== 0 ? (
            <div className={styles.separator}>
              <NoticeText text="or" />
            </div>
          ) : undefined}
          <SegmentationRuleDescriptionView
            key={i}
            title={e.title}
            descriptionBox={e.descriptionBox}
          />
        </>
      ))}
    </div>
  );
};

export default SegmentationRuleDescriptionList;
