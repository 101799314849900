import React, { useMemo } from "react";
import styles from "./PushNotificationDeliveryScheduleForm.module.scss";
import fontStyles from "fontStyles.module.scss";

import {
  PushNotificationDeliveryScheduleCallbacks,
  PushNotificationDeliveryScheduleParameters,
} from "app/hooks/v2/pushNotification/usePushNotificationDeliverySchedule";
import RadioButton from "molecules/RadioButton/RadioButton";
import DatePickerWrapper from "molecules/DatePicker/DatePickerWrapper";
import TimePicker from "molecules/TimePicker/TimePicker";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import CheckBox from "molecules/CheckBox/CheckBox";
import { DeliveryScheduleFrequencyType } from "types/delivery_schedule";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";

const RedAsterisk: React.VFC = () => {
  return <span style={{ color: "red" }}>*</span>;
};

function chunkArray<T>(array: T[], size: number): T[][] {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

const DeliveryScheduleLabel: {
  label: string;
  value: DeliveryScheduleFrequencyType;
}[] = [
  {
    label: "毎週",
    value: "WEEKLY",
  },
  {
    label: "毎月",
    value: "MONTHLY",
  },
];

const WeeklyForm: React.VFC<{
  weekdays: number[];
  addWeekdays: (value: number) => void;
  deleteWeekdays: (value: number) => void;
}> = ({ weekdays, addWeekdays, deleteWeekdays }): JSX.Element => {
  const weekdaysLabel = [
    {
      label: "日",
      value: 0,
    },
    {
      label: "月",
      value: 1,
    },
    {
      label: "火",
      value: 2,
    },
    {
      label: "水",
      value: 3,
    },
    {
      label: "木",
      value: 4,
    },
    {
      label: "金",
      value: 5,
    },
    {
      label: "土",
      value: 6,
    },
  ];
  return (
    <HorizontalLayout
      elements={weekdaysLabel.map((elem, index) => (
        <CheckBox
          key={index}
          text={elem.label}
          value={weekdays.includes(elem.value)}
          onChange={() => {
            if (weekdays.includes(elem.value)) {
              deleteWeekdays(elem.value);
            } else {
              addWeekdays(elem.value);
            }
          }}
        />
      ))}
    />
  );
};

const MonthlyForm: React.VFC<{
  days: number[];
  addMonthDay: (value: number) => void;
  deleteMonthDay: (value: number) => void;
}> = ({ days, addMonthDay, deleteMonthDay }) => {
  const chunkedLabels = chunkArray(
    Array.from({ length: 31 }, (_, i) => i + 1),
    10
  );

  const getCheckBox = (value: number) => (
    <div style={{ width: 40 }}>
      <CheckBox
        key={value}
        text={
          value >= 29 ? (
            <span>
              {value.toString()}
              <RedAsterisk />
            </span>
          ) : (
            value.toString()
          )
        }
        value={days.includes(value)}
        onChange={() => {
          if (days.includes(value)) {
            deleteMonthDay(value);
          } else {
            addMonthDay(value);
          }
        }}
      />
    </div>
  );

  return (
    <div>
      {chunkedLabels.map((chunk, index) => {
        return (
          <HorizontalLayout
            key={index}
            elements={chunk.map((elem) => getCheckBox(elem))}
          />
        );
      })}
      <div style={{ textAlign: "right" }}>
        <span className={fontStyles.note}>
          <RedAsterisk />
          該当の日にちがない月は配信されません
        </span>
      </div>
    </div>
  );
};

const RepeatForm: React.VFC<{
  parameters: PushNotificationDeliveryScheduleParameters;
  callbacks: PushNotificationDeliveryScheduleCallbacks;
}> = ({ parameters, callbacks }): JSX.Element => {
  if (parameters.frequencyType === "WEEKLY") {
    return (
      <WeeklyForm
        weekdays={parameters.weekdays}
        addWeekdays={callbacks.addWeekdays}
        deleteWeekdays={callbacks.deleteWeekdays}
      />
    );
  } else if (parameters.frequencyType === "MONTHLY") {
    return (
      <MonthlyForm
        days={parameters.days}
        addMonthDay={callbacks.addDays}
        deleteMonthDay={callbacks.deleteDays}
      />
    );
  } else {
    return <></>;
  }
};

const PeriodForm: React.VFC<{
  periodStart: Date;
  periodEnd: Date;
  updatePeriodStart: (date: Date) => void;
  updatePeriodEnd: (date: Date) => void;
}> = ({
  periodStart,
  periodEnd,
  updatePeriodStart,
  updatePeriodEnd,
}): JSX.Element => {
  return (
    <HorizontalLayout
      elements={[
        <DatePickerWrapper
          date={periodStart}
          onChange={(date) => updatePeriodStart(date)}
        />,
        <span>～</span>,
        <DatePickerWrapper
          date={periodEnd}
          onChange={(date) => updatePeriodEnd(date)}
        />,
      ]}
    />
  );
};

const PushNotificationDeliveryScheduleForm: React.VFC<{
  parameters: PushNotificationDeliveryScheduleParameters;
  callbacks: PushNotificationDeliveryScheduleCallbacks;
}> = ({ parameters, callbacks }): JSX.Element => {
  const onceFormElement = useMemo(() => {
    return (
      <HorizontalLayout
        elements={[
          <DatePickerWrapper
            date={parameters.deliveryDate}
            onChange={(date) => callbacks.updateDeliveryDate(date)}
          />,
          <TimePicker
            date={parameters.deliveryDate}
            onChange={(date) => callbacks.updateDeliveryDate(date)}
          />,
        ]}
      />
    );
  }, [callbacks, parameters.deliveryDate]);

  const periodFormElement = useMemo(() => {
    return (
      <VerticalSpreadLayout
        items={[
          <div style={{ width: "fit-content" }}>
            <CheckBox
              text="配信期間を指定する"
              value={parameters.isActivePeriod}
              onChange={() =>
                callbacks.updateIsActivePeriod(!parameters.isActivePeriod)
              }
            />
          </div>,
          parameters.isActivePeriod ? (
            <PeriodForm
              periodStart={parameters.periodStart}
              periodEnd={parameters.periodEnd}
              updatePeriodStart={callbacks.updatePeriodStart}
              updatePeriodEnd={callbacks.updatePeriodEnd}
            />
          ) : (
            <></>
          ),
        ]}
      />
    );
  }, [
    callbacks,
    parameters.isActivePeriod,
    parameters.periodEnd,
    parameters.periodStart,
  ]);

  const frequencyFormElement = useMemo(() => {
    return (
      <VerticalSpreadLayout
        items={[
          <HorizontalLayout
            elements={[
              <BorderedSelectBox
                options={DeliveryScheduleLabel}
                value={parameters.frequencyType}
                onChange={(value) => {
                  callbacks.updateFrequencyType(value);
                }}
              />,
              <div style={{ marginTop: 5 }}>
                <RepeatForm parameters={parameters} callbacks={callbacks} />
              </div>,
            ]}
            vertical="flex-start"
          />,
          <TimePicker
            date={parameters.repeatDate}
            onChange={(date) => callbacks.updateRepeatDate(date)}
          />,
        ]}
      />
    );
  }, [callbacks, parameters]);

  return (
    <div className={styles.pushNotificationDeliveryScheduleForm}>
      <div className={styles.notification}>
        <span className={fontStyles.note}>
          「下書き保存」の場合は以下の設定は保存されません
        </span>
      </div>
      <div className={styles.frequencySection}>
        <div className={styles.frequencyType}>
          <HorizontalLayout
            itemMargin={40}
            elements={[
              <RadioButton
                isActive={parameters.scheduleType === "ONCE"}
                onClick={() => callbacks.updateScheduleType("ONCE")}
                label={
                  <HorizontalIconLayout
                    elements={[
                      <div>１回だけ配信</div>,
                      <ToolTip
                        text={
                          <MultipleLineText
                            texts={[
                              "現時刻から1時間以上先の時間を設定してください。",
                              "（例：現時刻が9:20の場合は10:30が最短での設定となります）",
                            ]}
                          />
                        }
                        place="top"
                      />,
                    ]}
                  />
                }
              />,
              <RadioButton
                isActive={parameters.scheduleType === "REPEAT"}
                onClick={() => callbacks.updateScheduleType("REPEAT")}
                label={
                  <HorizontalIconLayout
                    elements={[
                      <div>くりかえし配信</div>,
                      <ToolTip
                        text={
                          <MultipleLineText
                            texts={[
                              "くりかえし設定の配信スケジュールは保存から1時間後以降に有効になるため、",
                              "本日中に初回配信を行いたい場合はご注意ください。",
                            ]}
                          />
                        }
                        place="top"
                      />,
                    ]}
                  />
                }
              />,
            ]}
          />
        </div>
        <div className={styles.form}>
          {parameters.scheduleType === "ONCE" ? onceFormElement : undefined}
          {parameters.scheduleType === "REPEAT" ? (
            <>
              <div className={styles.frequency}>{frequencyFormElement}</div>
              {periodFormElement}
            </>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default PushNotificationDeliveryScheduleForm;
