import styles from "./PastForm.module.scss";

type PastFormProps = {
  rangeInputElement: JSX.Element;
  selectorElement: JSX.Element;
  inputElement?: JSX.Element;
  unitElement?: JSX.Element;
  compareTypeElement?: JSX.Element;
  urlElement?: JSX.Element;
};

const PastForm = ({
  rangeInputElement,
  selectorElement,
  inputElement,
  unitElement,
  compareTypeElement,
  urlElement,
}: PastFormProps) => (
  <div className={styles.pastForm}>
    <div className={styles.range}>{rangeInputElement}</div>
    <div className={styles.behavior}>
      <div className={styles.select}>{selectorElement}</div>
      <div className={styles.input}>{inputElement}</div>
      <div className={styles.unit}>{unitElement}</div>
      <div className={styles.compareType}>{compareTypeElement}</div>
    </div>
    {urlElement ? <div className={styles.url}>{urlElement}</div> : undefined}
  </div>
);

export default PastForm;
