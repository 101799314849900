import {
  BASIC_CONFIGURATION_TYPE,
  BasicConfigurationType,
  MeasurementUrlSettingType,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";

// FIXME: react-routerで書けばよかった
export class BasicConfigurationRouter {
  toDomain() {
    return `${V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.DOMAIN}`;
  }
  toFile() {
    return `${V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.FILE}`;
  }
  toMeasurementUrlSettings(type?: MeasurementUrlSettingType) {
    if (type) {
      return `${V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.HOME}#${type}`;
    } else {
      return `${V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.HOME}`;
    }
  }
  toOption() {
    return `${V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.OPTION}`;
  }

  run(
    children: BasicConfigurationType,
    measurementUrlSettingType?: MeasurementUrlSettingType
  ) {
    switch (children) {
      case BASIC_CONFIGURATION_TYPE.DOMAIN:
        return this.toDomain();
      case BASIC_CONFIGURATION_TYPE.FILE:
        return this.toFile();
      case BASIC_CONFIGURATION_TYPE.URL:
        return this.toMeasurementUrlSettings(measurementUrlSettingType);
      case BASIC_CONFIGURATION_TYPE.OPTION:
        return this.toOption();
    }
  }
}
