import styles from "./CompletePhaseFormMainView.module.scss";

type CompletePhaseFormMainViewProps = {
  previewAreaElement: JSX.Element;
  settingDetailsElement: JSX.Element;
};

const CompletePhaseFormMainView = ({
  previewAreaElement,
  settingDetailsElement,
}: CompletePhaseFormMainViewProps) => {
  return (
    <div className={styles.completePhaseFormMainView}>
      <div className={styles.preview}>{previewAreaElement}</div>
      <div className={styles.table}>{settingDetailsElement}</div>
    </div>
  );
};

export default CompletePhaseFormMainView;
