import { Link, useLocation } from "react-router-dom";
import styles from "./SideBar.module.scss";

export type LinkParameters = {
  label: string;
  link: string;
};

const SideBarButton = (props: {
  label: string;
  link: string;
  isActive: boolean;
}) => {
  const className = [styles.tab];

  if (props.isActive) {
    className.push(styles.active);
  }

  return (
    <div className={className.join(" ")}>
      <Link className={styles.link} to={props.link}>
        <span>{props.label}</span>
      </Link>
    </div>
  );
};

const SideBar: React.VFC<{ params: LinkParameters[] }> = ({ params }) => {
  const activePage = useLocation().pathname;

  return (
    <div className={styles.SideBar}>
      {params.map((item, index) => (
        <SideBarButton
          key={index}
          label={item.label}
          link={item.link}
          isActive={activePage.includes(item.link)}
        />
      ))}
    </div>
  );
};

export default SideBar;
