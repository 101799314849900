import styles from "./NumberInputForm.module.scss";

type NumberInputFormProps = {
  leftElement: JSX.Element;
  centerElement: JSX.Element;
  rightElement: JSX.Element;
  isActive?: boolean;
};

const NumberInputForm = ({
  leftElement,
  centerElement,
  rightElement,
  isActive = true,
}: NumberInputFormProps): JSX.Element => {
  return (
    <div
      className={
        isActive ? styles.numberInputForm : styles.numberInputForm__disabled
      }
    >
      {leftElement}
      <div className={styles.input}>{centerElement}</div>
      {rightElement}
    </div>
  );
};

export default NumberInputForm;
