import {
  CURRENT_PAGE_BEHAVIOR_TYPE,
  CUSTOMER_TYPE,
  INTEGER_COMPARE_TYPE,
  PAST_BEHAVIOR_TYPE,
  SegmentationRuleCreation,
  SESSION_BEHAVIOR_TYPE,
} from "interfaces/model/segmentationRuleCreation";
import { SegmentationRule, UrlPattern } from "interfaces/models";
import {
  TargetUserType,
  UserAction,
  ExternalData,
} from "interfaces/view/segmentationCreation";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomerAttributeHeaderRepository from "utils/CustomerAttributeHeaderRepository";
import { SegmentationRuleRepository } from "utils/SegmentationRuleRepository";
import { CustomerAttributeHeaderResponse } from "interfaces/models";

export type SegmentationRuleCreationAppStateCallbacks = {
  setName: (value: string) => void;
  setTargetUser: (value: TargetUserType) => void;
  setTargetPage: (value: UrlPattern) => void;
  setUserActions: (value: UserAction[]) => void;
  setExternalData: (value: ExternalData[]) => void;
};

export type SegmentationRuleCreationFormState = {
  currentPageBehaviorCount: number;
  sessionBehaviorCount: number;
  pastBehaviorCount: number;
  customerAttributeCount: number;
};

export type SegmentationRuleCreationFormStateCallbacks = {
  setCurrentPageBehaviorCount: (value: number) => void;
  setSessionBehaviorCount: (value: number) => void;
  setPastBehaviorCount: (value: number) => void;
  setCustomerAttributeCount: (value: number) => void;
};

const DEFAULT_FORM_STATE: SegmentationRuleCreationFormState = {
  currentPageBehaviorCount: 1,
  sessionBehaviorCount: 1,
  pastBehaviorCount: 1,
  customerAttributeCount: 1,
};

export const useSegmentationRuleCreationFormState = (): [
  SegmentationRuleCreationFormState,
  SegmentationRuleCreationFormStateCallbacks,
  (initial: SegmentationRuleCreation.SegmentationRuleCreation) => void
] => {
  const [currentPageBehaviorCount, setCurrentPageBehaviorCount] =
    useState<number>(DEFAULT_FORM_STATE.currentPageBehaviorCount);
  const [sessionBehaviorCount, setSessionBehaviorCount] = useState<number>(
    DEFAULT_FORM_STATE.sessionBehaviorCount
  );
  const [pastBehaviorCount, setPastBehaviorCount] = useState<number>(
    DEFAULT_FORM_STATE.pastBehaviorCount
  );
  const [customerAttributeCount, setCustomerAttributeCount] = useState<number>(
    DEFAULT_FORM_STATE.customerAttributeCount
  );

  const formParameters: SegmentationRuleCreationFormState = useMemo(() => {
    return {
      currentPageBehaviorCount,
      sessionBehaviorCount,
      pastBehaviorCount,
      customerAttributeCount,
    };
  }, [
    currentPageBehaviorCount,
    sessionBehaviorCount,
    pastBehaviorCount,
    customerAttributeCount,
  ]);

  const formCallbacks: SegmentationRuleCreationFormStateCallbacks =
    useMemo(() => {
      return {
        setCurrentPageBehaviorCount,
        setSessionBehaviorCount,
        setPastBehaviorCount,
        setCustomerAttributeCount,
      };
    }, []);

  const initFormState: (
    initial: SegmentationRuleCreation.SegmentationRuleCreation
  ) => void = useCallback<
    (initial: SegmentationRuleCreation.SegmentationRuleCreation) => void
  >((initial: SegmentationRuleCreation.SegmentationRuleCreation) => {
    setCurrentPageBehaviorCount(
      initial.behavior.currentPageBehaviors.length > 0
        ? initial.behavior.currentPageBehaviors.length
        : DEFAULT_FORM_STATE.currentPageBehaviorCount
    );
    setSessionBehaviorCount(
      initial.behavior.sessionBehaviors.length > 0
        ? initial.behavior.sessionBehaviors.length
        : DEFAULT_FORM_STATE.sessionBehaviorCount
    );
    setPastBehaviorCount(
      initial.behavior.pastBehaviors.length > 0
        ? initial.behavior.pastBehaviors.length
        : DEFAULT_FORM_STATE.pastBehaviorCount
    );
    setCustomerAttributeCount(
      initial.customer.customerAttributes.length > 0
        ? initial.customer.customerAttributes.length
        : DEFAULT_FORM_STATE.customerAttributeCount
    );
  }, []);

  return [formParameters, formCallbacks, initFormState];
};

export type SegmentationRuleCreationSetters = {
  setName: (name: string) => void;
  setCurrentPageBehaviors: (
    currentPageBehaviors: SegmentationRuleCreation.CurrentPageBehavior[]
  ) => void;
  setSessionBehaviors: (
    sessionBehaviors: SegmentationRuleCreation.SessionBehavior[]
  ) => void;
  setPastBehaviors: (
    pastBehaviors: SegmentationRuleCreation.PastBehavior[]
  ) => void;
  setCustomerType: (
    customerType: SegmentationRuleCreation.CustomerType
  ) => void;
  setCustomerAttributes: (
    customerAttributes: SegmentationRuleCreation.CustomerAttribute[]
  ) => void;
};

export const DEFAULT_URL_PATTERN = {
  location: [],
  parameters: [],
};

type DefaultSegmentationRuleCreation = {
  name: string;
  currentPageBehavior: {
    scrollRate: SegmentationRuleCreation.CurrentPageBehavior;
    stayingTime: SegmentationRuleCreation.CurrentPageBehavior;
    accessBlank: SegmentationRuleCreation.CurrentPageBehavior;
    notSelected: SegmentationRuleCreation.CurrentPageBehavior;
  };
  sessionBehavior: {
    pvCount: SegmentationRuleCreation.SessionBehavior;
    continuousPv: SegmentationRuleCreation.SessionBehavior;
    allPvCount: SegmentationRuleCreation.SessionBehavior;
    stayingTime: SegmentationRuleCreation.SessionBehavior;
    notSelected: SegmentationRuleCreation.SessionBehavior;
  };
  pastBehavior: {
    pvCount: SegmentationRuleCreation.PastBehavior;
    allPvCount: SegmentationRuleCreation.PastBehavior;
    notSelected: SegmentationRuleCreation.PastBehavior;
  };
  customerType: SegmentationRuleCreation.CustomerType;
  customerAttribute: {
    notSelected: SegmentationRuleCreation.CustomerAttribute;
  };
};

export const DEFAULT_SEGMENTATION_RULE_CREATION: DefaultSegmentationRuleCreation =
  {
    name: "",
    currentPageBehavior: {
      scrollRate: {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 10,
      },
      stayingTime: {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 10,
      },
      accessBlank: {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
      },
      notSelected: {
        behaviorType: CURRENT_PAGE_BEHAVIOR_TYPE.NOT_SELECTED,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
      },
    },
    sessionBehavior: {
      pvCount: {
        behaviorType: SESSION_BEHAVIOR_TYPE.PV_COUNT,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
      },
      continuousPv: {
        behaviorType: SESSION_BEHAVIOR_TYPE.CONTINUOUS_PV,
        compareType: INTEGER_COMPARE_TYPE.EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
      },
      allPvCount: {
        behaviorType: SESSION_BEHAVIOR_TYPE.ALL_PV_COUNT,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
      },
      stayingTime: {
        behaviorType: SESSION_BEHAVIOR_TYPE.STAYING_TIME,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 10,
      },
      notSelected: {
        behaviorType: SESSION_BEHAVIOR_TYPE.NOT_SELECTED,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
      },
    },
    pastBehavior: {
      pvCount: {
        behaviorType: PAST_BEHAVIOR_TYPE.PV_COUNT,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
        rangeDays: 1,
      },
      allPvCount: {
        behaviorType: PAST_BEHAVIOR_TYPE.ALL_PV_COUNT,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
        rangeDays: 1,
      },
      notSelected: {
        behaviorType: PAST_BEHAVIOR_TYPE.NOT_SELECTED,
        compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
        url: DEFAULT_URL_PATTERN,
        value: 1,
        rangeDays: 1,
      },
    },
    customerType: CUSTOMER_TYPE.ALL,
    customerAttribute: {
      notSelected: {
        key: "",
        operation: {
          type: "INTEGER",
          compareType: INTEGER_COMPARE_TYPE.GREATER_THAN_OR_EQUAL_TO,
          value: 0,
        },
      },
    },
  };

export const useSegmentationRuleCreation = (): [
  SegmentationRuleCreation.SegmentationRuleCreation,
  SegmentationRuleCreationSetters,
  (
    segmentationRuleCreation: SegmentationRuleCreation.SegmentationRuleCreation,
    isCopy: boolean
  ) => void
] => {
  const [name, setName] = useState<string>(
    DEFAULT_SEGMENTATION_RULE_CREATION.name
  );
  const [currentPageBehaviors, setCurrentPageBehaviors] = useState<
    SegmentationRuleCreation.CurrentPageBehavior[]
  >([DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior.notSelected]);
  const [sessionBehaviors, setSessionBehaviors] = useState<
    SegmentationRuleCreation.SessionBehavior[]
  >([DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.notSelected]);
  const [pastBehaviors, setPastBehaviors] = useState<
    SegmentationRuleCreation.PastBehavior[]
  >([DEFAULT_SEGMENTATION_RULE_CREATION.pastBehavior.notSelected]);

  const [customerType, setCustomerType] =
    useState<SegmentationRuleCreation.CustomerType>(CUSTOMER_TYPE.ALL);

  const [customerAttributes, setCustomerAttributes] = useState<
    SegmentationRuleCreation.CustomerAttribute[]
  >([DEFAULT_SEGMENTATION_RULE_CREATION.customerAttribute.notSelected]);

  const initializeCallback = useCallback(
    (
      segmentationRuleCreation: SegmentationRuleCreation.SegmentationRuleCreation,
      isCopy: boolean
    ) => {
      setName(
        isCopy
          ? `${segmentationRuleCreation.name}_copy`
          : segmentationRuleCreation.name
      );
      setCurrentPageBehaviors(
        segmentationRuleCreation.behavior.currentPageBehaviors.length > 0
          ? segmentationRuleCreation.behavior.currentPageBehaviors
          : [DEFAULT_SEGMENTATION_RULE_CREATION.currentPageBehavior.notSelected]
      );
      setSessionBehaviors(
        segmentationRuleCreation.behavior.sessionBehaviors.length > 0
          ? segmentationRuleCreation.behavior.sessionBehaviors
          : [DEFAULT_SEGMENTATION_RULE_CREATION.sessionBehavior.notSelected]
      );
      setPastBehaviors(
        segmentationRuleCreation.behavior.pastBehaviors.length > 0
          ? segmentationRuleCreation.behavior.pastBehaviors
          : [DEFAULT_SEGMENTATION_RULE_CREATION.pastBehavior.notSelected]
      );
      setCustomerType(segmentationRuleCreation.customer.customerType);
      setCustomerAttributes(
        segmentationRuleCreation.customer.customerAttributes.length > 0
          ? segmentationRuleCreation.customer.customerAttributes
          : [DEFAULT_SEGMENTATION_RULE_CREATION.customerAttribute.notSelected]
      );
    },
    []
  );

  const state: SegmentationRuleCreation.SegmentationRuleCreation =
    useMemo(() => {
      return {
        name: name,
        behavior: {
          currentPageBehaviors: currentPageBehaviors,
          sessionBehaviors: sessionBehaviors,
          pastBehaviors: pastBehaviors,
        },
        customer: {
          customerType: customerType,
          customerAttributes: customerAttributes,
        },
      };
    }, [
      name,
      currentPageBehaviors,
      sessionBehaviors,
      pastBehaviors,
      customerType,
      customerAttributes,
    ]);

  const setters: SegmentationRuleCreationSetters = useMemo(() => {
    return {
      setName,
      setCurrentPageBehaviors,
      setSessionBehaviors,
      setPastBehaviors,
      setCustomerType,
      setCustomerAttributes,
    };
  }, []);

  return [state, setters, initializeCallback];
};

export const decodeCustomerAttributeHeaderResponse = (
  customerAttributeHeaderResponse: CustomerAttributeHeaderResponse | undefined
): string[] => {
  if (customerAttributeHeaderResponse === undefined) {
    return [];
  } else {
    return customerAttributeHeaderResponse.customer_attribute_headers.map(
      (cah) => cah.header
    );
  }
};

export const useExistingSegmentationRuleNames = (
  segmentationRuleRepository: SegmentationRuleRepository
): [string[] | undefined, Error | undefined] => {
  const [existingSegmentationRuleNames, setExistingSegmentationRuleNames] =
    useState<string[]>();
  const [error, setError] = useState<Error>();
  const loadSegmentationRule = useCallback<() => Promise<void>>(async () => {
    try {
      const srs: SegmentationRule[] = await segmentationRuleRepository.load();
      setExistingSegmentationRuleNames(srs.map((s) => s.name));
    } catch (e) {
      if (e instanceof Error) {
        // 通信に失敗した時
        setError(e);
      }
    }
  }, [segmentationRuleRepository]);

  useEffect(() => {
    loadSegmentationRule();
  }, [loadSegmentationRule]);

  return [existingSegmentationRuleNames, error];
};

export const useCustomerAttributeHeader = (
  customerAttributeHeaderRepository: CustomerAttributeHeaderRepository
): string[] => {
  const [customerAttributeHeaders, setCustomerAttributeHeaders] = useState<
    string[]
  >([]);
  const loadCustomerAttributeHeaders = useCallback<
    () => Promise<void>
  >(async () => {
    setCustomerAttributeHeaders(
      decodeCustomerAttributeHeaderResponse(
        await customerAttributeHeaderRepository.load()
      )
    );
  }, [customerAttributeHeaderRepository]);

  useEffect(() => {
    loadCustomerAttributeHeaders();
  }, [loadCustomerAttributeHeaders]);

  return customerAttributeHeaders;
};
