import { useState } from "react";

import Pagination from "../actions/molecules/Pagination";
import styles from "./SegmentationRuleTable.module.scss";

interface SegmentationRuleTableProps {
  headerColumnElements: JSX.Element[];
  items: JSX.Element[];
}

const SegmentationRuleTable = ({
  headerColumnElements,
  items,
}: SegmentationRuleTableProps) => {
  const [page, setPage] = useState<number>(0);

  const pageMaxRow = 100;

  const startSegmentationRuleNum = pageMaxRow * page;

  return (
    <div className={styles.segmentationRuleTable}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr key="header" className={styles.tr}>
            {headerColumnElements}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {items.slice(
            startSegmentationRuleNum,
            startSegmentationRuleNum + pageMaxRow
          )}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <Pagination
          length={items.length}
          pageRowMax={pageMaxRow}
          onChange={(pageNum: number) => {
            setPage(pageNum);
          }}
        />
      </div>
    </div>
  );
};

export default SegmentationRuleTable;
