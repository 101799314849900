import { useEffect, useState } from "react";

const LoadCurrentAuthName = ({
  render,
}: {
  render: (
    authName: string | null,
    saveAuthName: (authName: string | null) => void
  ) => JSX.Element;
}) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [authName, setAuthName] = useState<string | null>(null);

  const saveAuthName = (authName: string | null) => {
    if (authName) {
      localStorage.setItem("CURRENT_USER_NAME", authName);
    } else {
      localStorage.removeItem("CURRENT_USER_NAME");
    }
    setAuthName(authName);
  };

  const loadAuthName = (): string | null => {
    return localStorage.getItem("CURRENT_USER_NAME");
  };

  useEffect(() => {
    setAuthName(loadAuthName());
    setLoaded(true);
  }, []);

  return isLoaded ? render(authName, saveAuthName) : null;
};

export default LoadCurrentAuthName;
