import { useState } from "react";
import {
  DeliveryScheduleFrequencyType,
  DeliveryScheduleRuleType,
} from "types/delivery_schedule";
import { getCurrentDate } from "utils/date/currentDate";

export type PushNotificationDeliveryScheduleParameters = {
  deliveryDate: Date;
  scheduleType: "ONCE" | "REPEAT";
  frequencyType: "ONCE" | "WEEKLY" | "MONTHLY";
  repeatDate: Date;
  weekdays: number[];
  days: number[];
  isActivePeriod: boolean;
  periodStart: Date;
  periodEnd: Date;
};

export type PushNotificationDeliveryScheduleCallbacks = {
  updateScheduleType: (value: DeliveryScheduleRuleType) => void;
  updateDeliveryDate: (value: Date) => void;
  updateFrequencyType: (value: DeliveryScheduleFrequencyType) => void;
  updateRepeatDate: (value: Date) => void;
  addWeekdays: (value: number) => void;
  deleteWeekdays: (value: number) => void;
  addDays: (value: number) => void;
  deleteDays: (value: number) => void;
  updateIsActivePeriod: (value: boolean) => void;
  updatePeriodStart: (value: Date) => void;
  updatePeriodEnd: (value: Date) => void;
};

const usePushNotificationDeliverySchedule = (): [
  PushNotificationDeliveryScheduleParameters,
  PushNotificationDeliveryScheduleCallbacks
] => {
  // NOTE: for once
  const [deliveryDate, setDeliveryDate] = useState<Date>(getCurrentDate());

  // NOTE: for repeat
  const [scheduleType, setscheduleType] =
    useState<DeliveryScheduleRuleType>("ONCE");

  const [frequencyType, setFrequencyType] =
    useState<DeliveryScheduleFrequencyType>("ONCE");

  const [repeatDate, setRepeatDate] = useState<Date>(getCurrentDate());

  const [weekdays, setWeekdays] = useState<number[]>([]);

  const [days, setDays] = useState<number[]>([]);

  const [isActivePeriod, setIsActivePeriod] = useState<boolean>(false);

  const [periodStart, setPeriodStart] = useState<Date>(
    new Date(getCurrentDate())
  );

  const [periodEnd, setPeriodEnd] = useState<Date>(new Date(getCurrentDate()));

  return [
    {
      scheduleType: scheduleType,
      deliveryDate: deliveryDate,
      frequencyType: frequencyType,
      repeatDate: repeatDate,
      weekdays: weekdays,
      days: days,
      isActivePeriod: isActivePeriod,
      periodStart: periodStart,
      periodEnd: periodEnd,
    },
    {
      updateScheduleType: (value: DeliveryScheduleRuleType) => {
        setscheduleType(value as DeliveryScheduleRuleType);
        if (value === "ONCE") {
          setFrequencyType("ONCE");
        } else {
          if (scheduleType !== (value as DeliveryScheduleRuleType)) {
            setFrequencyType("WEEKLY");
          }
        }
      },
      updateDeliveryDate: setDeliveryDate,
      updateFrequencyType: setFrequencyType,
      updateRepeatDate: setRepeatDate,
      addWeekdays: (value: number) => {
        setWeekdays([...weekdays, value]);
      },
      deleteWeekdays: (value: number) => {
        setWeekdays(weekdays.filter((elem) => elem !== value));
      },
      addDays: (value: number) => {
        setDays([...days, value]);
      },
      deleteDays: (value: number) => {
        setDays(days.filter((elem) => elem !== value));
      },
      updateIsActivePeriod: () => {
        const nextState = !isActivePeriod;
        setIsActivePeriod(nextState);
        if (nextState) {
          setPeriodStart(getCurrentDate());
          setPeriodEnd(getCurrentDate());
        }
      },
      updatePeriodStart: setPeriodStart,
      updatePeriodEnd: (value: Date) => {
        setPeriodEnd(value);
      },
    },
  ];
};

export default usePushNotificationDeliverySchedule;
