import styles from "./CurrentPageForm.module.scss";

type CurrentPageFormProps = {
  behaviorSelectorElement: JSX.Element;
  inputElement?: JSX.Element;
  suffixString?: string;
};

const CurrentPageForm = ({
  behaviorSelectorElement,
  inputElement,
  suffixString,
}: CurrentPageFormProps): JSX.Element => {
  return (
    <div className={styles.currentPageForm}>
      <div className={styles.behaviorSelector}>{behaviorSelectorElement}</div>
      {inputElement ? (
        <div className={styles.input}>{inputElement}</div>
      ) : undefined}
      {suffixString ? <div>{suffixString}</div> : undefined}
    </div>
  );
};

export default CurrentPageForm;
