import styles from "./AppHeaderV2.module.scss";

export interface AppHeaderProps {
  logo: JSX.Element;
  accountNameElement: JSX.Element;
  signOutButtonElement: JSX.Element;
}

/**
 * Component Category: Organism
 */
const AppHeaderV2 = ({
  logo,
  accountNameElement,
  signOutButtonElement,
}: AppHeaderProps) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.serviceLogo}>{logo}</div>
        <div className={styles.loginUser}>{accountNameElement}</div>
        <div className={styles.logout}>{signOutButtonElement}</div>
      </div>
    </header>
  );
};

export default AppHeaderV2;
