import React, { useMemo } from "react";
import fontStyles from "fontStyles.module.scss";

import { Domain } from "types/domain";

import LoadingIcon from "atoms/LoadingIcon";
import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import Label from "molecules/v2/Label";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import LinkText from "atoms/LinkText";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";

const DomainConfigurationDescription: React.VFC = () => (
  <div className={fontStyles.note} style={{ color: "#666" }}>
    ※変更する場合はお手数ですがサポート （
    <LinkText
      text={"support@target-push.com"}
      link={"mailto:support@target-push.com"}
      external
    />
    ） までご連絡ください。
  </div>
);

const OtherDomainTitleElement: React.VFC<{ onCreate: () => void }> = ({
  onCreate,
}) => (
  <HorizontalLayout
    elements={[
      <Label
        label={<span className={fontStyles.section}>その他のドメイン</span>}
        toolTip={
          <MultipleLineText
            texts={[
              "ページ設定で許諾ドメイン以外に使うドメインがある場合は登録してください（複数登録可）。",
              "ドメイン、サブドメインは別のドメインとして扱われます。",
            ]}
          />
        }
      />,
      <OrangeButton text={"新規追加"} onClick={onCreate} />,
    ]}
  />
);

const OtherDomainListElement: React.VFC<{
  domains: Domain[] | null;
  onDelete: (domain: string, domainUuid: string) => void;
}> = ({ domains, onDelete }) => {
  if (!domains) {
    return <LoadingIcon />;
  }
  const otherDomains = domains
    ? domains.filter((domain) => !domain.is_subscribe)
    : [];

  if (otherDomains.length === 0) {
    return <div>登録ドメインがありません</div>;
  } else {
    return (
      <VerticalSpreadLayout
        items={otherDomains.map((domain, index) => (
          <div key={index}>
            <HorizontalIconLayout
              elements={[
                <span className={fontStyles.largeText}>{domain.domain}</span>,
                <DeleteButton
                  onClick={() => onDelete(domain.domain, domain.domain_uuid)}
                />,
              ]}
            />
          </div>
        ))}
      />
    );
  }
};

const SubscribeDomainTitleElement: React.VFC = () => (
  <Label
    label={<span className={fontStyles.section}>許諾ドメイン</span>}
    toolTip={"プッシュ通知の送り主となるサイトドメインです。"}
  />
);

const SubscribeDomainElement: React.VFC<{
  subscribeDomain: Domain | undefined;
}> = ({ subscribeDomain }) => (
  <VerticalSpreadLayout
    margin={5}
    items={[
      subscribeDomain ? (
        <span className={fontStyles.largeText}>{subscribeDomain.domain}</span>
      ) : (
        <LoadingIcon />
      ),
      <DomainConfigurationDescription />,
    ]}
  />
);

const DomainConfiguration: React.VFC<{
  domains: Domain[] | null;
  onCreate: () => void;
  onDelete: (domain: string, domainUuid: string) => void;
}> = ({ domains, onCreate, onDelete }) => {
  const subscribeDomain = useMemo(
    () =>
      domains
        ? domains
            .sort((a, b) => a.created_at - b.created_at)
            .find((domain) => domain.is_subscribe)
        : undefined,
    [domains]
  );

  return (
    <VerticalSpreadLayout
      margin={60}
      items={[
        <VerticalSpreadLayout
          margin={5}
          items={[
            <SubscribeDomainTitleElement />,
            <SubscribeDomainElement subscribeDomain={subscribeDomain} />,
          ]}
        />,
        <VerticalSpreadLayout
          margin={5}
          items={[
            <OtherDomainTitleElement onCreate={onCreate} />,
            <OtherDomainListElement domains={domains} onDelete={onDelete} />,
          ]}
        />,
      ]}
    />
  );
};

export default DomainConfiguration;
