import { ReportMetaDataList } from "interfaces/models";
import RestApi from "./RestApi";

class ReportMetaDataRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async load(): Promise<ReportMetaDataList> {
    const data = await this.managementApi.get("action_reports");

    return data["data"];
  }
}

export default ReportMetaDataRepository;
