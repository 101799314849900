import React from "react";
import styles from "./GrayButton.module.scss";
import fontStyles from "fontStyles.module.scss";

const GrayButton: React.VFC<{
  text: string | JSX.Element;
  onClick: () => void;
}> = ({ text, onClick }): JSX.Element => {
  return (
    <button className={styles.grayButton} onClick={onClick}>
      <div className={fontStyles.text}>{text}</div>
    </button>
  );
};

export default GrayButton;
