import React from "react";

import styles from "./Label.module.scss";

import RequireIcon from "atoms/accountPagesShared/RequireIcon";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import { generateRandomId } from "utils/randomId/RandomId";

const Label: React.VFC<{
  label: string | JSX.Element;
  isRequired?: boolean;
  toolTip?: string | JSX.Element;
}> = ({ label, isRequired, toolTip }) => {
  let labelElements: JSX.Element[] = [
    <span className={styles.text}>{label}</span>,
  ];

  if (isRequired) {
    labelElements.push(<RequireIcon />);
  }

  if (toolTip) {
    labelElements.push(<ToolTip id={generateRandomId()} text={toolTip} />);
  }

  return (
    <div className={styles.label}>
      <HorizontalIconLayout elements={labelElements} />
    </div>
  );
};

export default Label;
