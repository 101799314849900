import { Direction, getTrackBackground, Range } from "react-range";

type HorizontalSliderProps = {
  value: number;
  minValue: number;
  maxValue: number;
  stepValue: number;
  onChange: (value: number) => void;
};

const HorizontalSlider = ({
  value,
  minValue,
  maxValue,
  stepValue,
  onChange,
}: HorizontalSliderProps) => {
  const values = [value];
  return (
    <Range
      values={values}
      min={minValue}
      max={maxValue}
      step={stepValue}
      direction={Direction.Right}
      onChange={(values) => onChange(values[0])}
      renderTrack={({ props, children }) => (
        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
          <div
            ref={props.ref}
            style={{
              ...props.style,
              width: "100px",
              height: "4px",
              background: getTrackBackground({
                values: values,
                colors: ["#ff7b2c", "#ccc"],
                min: minValue,
                max: maxValue,
                direction: Direction.Right,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            width: "14px",
            height: "14px",
            borderRadius: "14px",
            border: "1px solid #ccc",
            outline: "none",
            backgroundColor: "#fff",
          }}
        />
      )}
    />
  );
};

export default HorizontalSlider;
