import styles from "./LoadingIcon.module.scss";

const LoadingIcon = () => {
  return (
    <div className={styles.loadingIcon}>
      <div className={styles.icon} />
    </div>
  );
};

export default LoadingIcon;
