import { useEffect, useState } from "react";
import RestApi from "utils/RestApi";
import useSegmentationRuleEditEvents, {
  SegmentationRulePageEditEventParameters,
  SegmentationRulePageEditEvents,
} from "./useSegmentationRuleEditEvents";
import useSegmentationRuleForm from "./useSegmentationRuleForm";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import {
  SegmentationRuleEditorStatus,
  SegmentationRuleFormCallbacks,
  SegmentationRuleFormParameters,
} from "interfaces/v2/segmentationRuleForm";
import useSegmentationRuleEditPageModeParameters, {
  SegmentationRuleEditPageModeParameters,
} from "./useDestinationCollectRuleEditModeParameters";
import useSegmentationRuleEditPageRequireData from "./useSegmentationRuleEditorRequirementData";
import { SEGMENTATION_RULE_EDITOR_MODE } from "app/system_defaults/v2_routing";

export type SegmentationRuleEditPageParameters = {
  form: SegmentationRuleFormParameters;
  urls: MeasurementUrlSettingWithType[];
  modalContents: JSX.Element | undefined;
  event: SegmentationRulePageEditEventParameters;
  mode: SegmentationRuleEditPageModeParameters;
  editorStatus: SegmentationRuleEditorStatus;
};

export type SegmentationRuleEditPageCallbacks = {
  form: SegmentationRuleFormCallbacks;
  event: SegmentationRulePageEditEvents;
  updateModalContents: (value: JSX.Element | undefined) => void;
  closeModalContents: () => void;
};

const useSegmentationRuleEditPage = (
  managementApi: RestApi
): [SegmentationRuleEditPageParameters, SegmentationRuleEditPageCallbacks] => {
  const modeParameters = useSegmentationRuleEditPageModeParameters();

  const requireData = useSegmentationRuleEditPageRequireData(
    managementApi,
    modeParameters.destinationCollectRuleUuid
  );

  const [formParameters, formCallbacks] = useSegmentationRuleForm(
    modeParameters.mode,
    requireData.rule
  );

  const [eventParameters, events] = useSegmentationRuleEditEvents(
    managementApi,
    modeParameters,
    requireData.urls,
    requireData.existRules
  );

  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  const [editorStatus, setEditorStatus] =
    useState<SegmentationRuleEditorStatus>(
      SegmentationRuleEditorStatus.LOADING
    );

  useEffect(() => {
    if (requireData.urls.length > 0) {
      if (modeParameters.mode === SEGMENTATION_RULE_EDITOR_MODE.CREATE) {
        setEditorStatus(SegmentationRuleEditorStatus.EDITABLE);
      } else {
        if (requireData.rule) {
          setEditorStatus(SegmentationRuleEditorStatus.EDITABLE);
        }
      }
    }
  }, [modeParameters, requireData]);

  return [
    {
      form: formParameters,
      modalContents: modalContents,
      urls: requireData.urls,
      event: eventParameters,
      mode: modeParameters,
      editorStatus: editorStatus,
    },
    {
      form: formCallbacks,
      event: events,
      updateModalContents: (value: JSX.Element | undefined) =>
        setModalContents(value),
      closeModalContents: () => setModalContents(undefined),
    },
  ];
};

export default useSegmentationRuleEditPage;
