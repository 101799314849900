import {
  SEGMENTATION_RULE_EDITOR_MODE,
  V2_ROUTING_PATH_PARAMETERS,
  V2_ROUTING_TABLE,
} from "app/system_defaults/v2_routing";

export class SegmentationRuleEditRouter {
  create() {
    return `${V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.EDIT}?mode=${SEGMENTATION_RULE_EDITOR_MODE.CREATE}`;
  }

  edit(destinationCollectRuleUuid: string) {
    const modeQuery = `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.SEGMENTATION_RULE.EDIT.MODE}=${SEGMENTATION_RULE_EDITOR_MODE.EDIT}`;
    const uuidQuery = `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.SEGMENTATION_RULE.EDIT.DESTINATION_COLLECT_RULE_UUID}=${destinationCollectRuleUuid}`;
    return `${V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.EDIT}?${modeQuery}&${uuidQuery}`;
  }

  copy(destinationCollectRuleUuid: string) {
    const modeQuery = `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.SEGMENTATION_RULE.EDIT.MODE}=${SEGMENTATION_RULE_EDITOR_MODE.COPY}`;
    const uuidQuery = `${V2_ROUTING_PATH_PARAMETERS.ACCOUNT.SEGMENTATION_RULE.EDIT.DESTINATION_COLLECT_RULE_UUID}=${destinationCollectRuleUuid}`;
    return `${V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.EDIT}?${modeQuery}&${uuidQuery}`;
  }
}
