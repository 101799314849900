import React from "react";
import styles from "./GrayOutButton.module.scss";

const GrayOutButton: React.VFC<{
  text: string;
  isActive?: boolean;
}> = ({ text, isActive = true }) => {
  return (
    <div
      className={styles.grayOutButton}
      style={{
        opacity: isActive ? 1 : 0.5,
        pointerEvents: isActive ? "auto" : "none",
      }}
    >
      {text}
    </div>
  );
};

export default GrayOutButton;
