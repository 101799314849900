import { useState, useCallback, useEffect } from "react";
import { MEASUREMENT_URL_SETTING_TYPE } from "app/system_defaults/v2_routing";

import { MeasurementUrlSettingWithType } from "types/measurement_urls";

import RestApi from "utils/RestApi";
import MeasurementUrlRepository from "utils/repositories/MeasurementUrlRepository";

export type MeasurementUrlSettingParameters = {
  subscriptionUrlSetting: MeasurementUrlSettingWithType[] | null;
  segmentationUrlSetting: MeasurementUrlSettingWithType[] | null;
  conversionUrlSetting: MeasurementUrlSettingWithType[] | null;
  isLoaded: boolean;
};

export type MeasurementUrlSettingCallbacks = {
  load: () => void;
  delete: (urlUuid: string) => Promise<void>;
};

export const useMeasurementUrlSettingParameters = (
  managementApi: RestApi
): [MeasurementUrlSettingParameters, MeasurementUrlSettingCallbacks] => {
  const [subscriptionUrlSetting, setSubscriptionUrlSetting] = useState<
    MeasurementUrlSettingWithType[] | null
  >(null);
  const [segmentationUrlSetting, setSegmentationUrlSetting] = useState<
    MeasurementUrlSettingWithType[] | null
  >(null);
  const [conversionUrlSetting, setConversionUrlSetting] = useState<
    MeasurementUrlSettingWithType[] | null
  >(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const load = useCallback(async () => {
    const repository = new MeasurementUrlRepository(managementApi);
    const response = await repository.load();
    setSubscriptionUrlSetting(
      response.items.filter(
        (item) => item.type === MEASUREMENT_URL_SETTING_TYPE.SUBSCRIPTION
      )
    );
    setSegmentationUrlSetting(
      response.items.filter(
        (item) => item.type === MEASUREMENT_URL_SETTING_TYPE.SEGMENTATION
      )
    );
    setConversionUrlSetting(
      response.items.filter(
        (item) => item.type === MEASUREMENT_URL_SETTING_TYPE.CONVERSION
      )
    );
    setIsLoaded(true);
  }, [managementApi]);

  const deleteUrlSetting = useCallback(
    async (urlUuid: string) => {
      const repository = new MeasurementUrlRepository(managementApi);
      await repository.delete({ url_uuid: urlUuid });
      load();
    },
    [managementApi, load]
  );

  useEffect(() => {
    load();
  }, [load]);

  return [
    {
      subscriptionUrlSetting,
      segmentationUrlSetting,
      conversionUrlSetting,
      isLoaded,
    },
    {
      load: load,
      delete: deleteUrlSetting,
    },
  ];
};
