import EventText from "atoms/accountPagesShared/EventText";
import HTMLEditTextarea from "atoms/accountPagesShared/HTMLEditTextarea";
import SourceEditView from "organisms/account/action_creation/contents_edit/SourceEditView";

const CreateContentsSourceForm = ({
  html,
  isAbleReset,
  onChangeHtml,
  onResetHtml,
}: {
  html: string;
  isAbleReset: boolean;
  onChangeHtml: (html: string) => void;
  onResetHtml: () => void;
}) => {
  return (
    <SourceEditView
      resetElement={
        isAbleReset ? (
          <EventText text="リセット" onClick={onResetHtml} />
        ) : (
          <></>
        )
      }
      editAreaElement={<HTMLEditTextarea html={html} onChange={onChangeHtml} />}
    />
  );
};

export default CreateContentsSourceForm;
