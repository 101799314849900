import {
  CONTENTS_BASE_RADIUS_PATTERN,
  SUB_HEADLINE_RADIUS_PATTERN,
} from "app/system_defaults/action_creation_defaults";
import FormTitle from "atoms/accountPagesShared/FormTitle";
import PrecautionaryIcon from "atoms/accountPagesShared/PrecautionaryIcon";
import {
  CreativeParts,
  CREATIVE_PARTS,
  ActionCreationContentsPCStyleParameters,
} from "interfaces/model/actionContentsParameters";
import { closeButtonSizeOptions } from "interfaces/models";
import { ActionCreationContentsPCStyleCallbacks } from "interfaces/view/actionCreation";
import CheckBoxRangeSliderForm from "molecules/formParts/CheckBoxRangeSliderForm";
import MarginForm from "molecules/formParts/MarginForm";
import PullDownForm from "molecules/formParts/PullDownForm";
import RangeSliderForm from "molecules/formParts/RangeSliderForm";
import MonitoringSlider from "molecules/MonitoringSlider/MonitoringSlider";
import FormElementView from "organisms/account/action_creation/contents_edit/FormElementView";
import FormItemView from "organisms/account/action_creation/contents_edit/FormItemView";

const selectFormParts = (
  parts: CreativeParts,
  styles: ActionCreationContentsPCStyleParameters,
  stylesCallbacks: ActionCreationContentsPCStyleCallbacks
): JSX.Element[] | undefined => {
  const precautionaryIcon = <PrecautionaryIcon text="PC設定" />;
  switch (parts) {
    case CREATIVE_PARTS.BASE:
      return [
        <FormItemView
          key="body"
          titleElement={
            <FormTitle name="コンテンツベース" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="width"
                name={<div>横幅</div>}
                value={styles.contentsBase.background.width}
                min={50}
                max={650}
                step={25}
                onChange={(e) =>
                  stylesCallbacks.contentsBase.background.setWidth(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <CheckBoxRangeSliderForm
                key="height"
                name={<div>縦幅</div>}
                checkBoxText="自動調整"
                isChecked={styles.contentsBase.background.heightAdjust}
                value={styles.contentsBase.background.height}
                min={0}
                max={600}
                step={25}
                onChangeSlider={(e) =>
                  stylesCallbacks.contentsBase.background.setHeight(e)
                }
                onChangeCheckBox={(e) => {
                  stylesCallbacks.contentsBase.background.setHeightAdjust(e);
                  stylesCallbacks.contentsBase.background.setHeight(0);
                }}
                valueTextEditCallback={(e) =>
                  Number(e) === 0 ? "自動" : `${e}px`
                }
              />,
            ],
            [
              <PullDownForm
                key="radius"
                name={<div>角丸</div>}
                value={styles.contentsBase.border.radius}
                options={CONTENTS_BASE_RADIUS_PATTERN}
                onChange={(e) =>
                  stylesCallbacks.contentsBase.border.setRadius(Number(e))
                }
              />,
              <MarginForm
                key="margin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 40, step: 5 }}
                rightLeftOptions={{ min: 0, max: 40, step: 5 }}
                topBottomMarginValue={
                  styles.contentsBase.background.padding.top
                }
                rightLeftMarginValue={
                  styles.contentsBase.background.padding.right
                }
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.contentsBase.background.padding.setTop(
                    Number(e)
                  );
                  stylesCallbacks.contentsBase.background.padding.setBottom(
                    Number(e)
                  );
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.contentsBase.background.padding.setRight(
                    Number(e)
                  );
                  stylesCallbacks.contentsBase.background.padding.setLeft(
                    Number(e)
                  );
                }}
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.IMAGE:
      return [];
    case CREATIVE_PARTS.MAGNIFICATION_IMAGE:
      return [
        <FormItemView
          key="magnification_image"
          titleElement={
            <FormTitle name="画像を挿入する" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <FormElementView
                nameElement={<div>幅</div>}
                formElement={
                  <MonitoringSlider
                    value={styles.image.magnification}
                    minValue={0.5}
                    maxValue={2.0}
                    stepValue={0.05}
                    onChange={(e) => stylesCallbacks.image.setMagnification(e)}
                    formatCallback={(e) => `x${e.toFixed(2)}`}
                  />
                }
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.SUB_HEADLINE:
      return [
        <FormItemView
          key="subHeadline"
          titleElement={
            <FormTitle name="サブ見出し" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="subHeadlineTextSize"
                name={<div>文字サイズ</div>}
                value={styles.subHeadline.text.size}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.subHeadline.text.setSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
            ],
            [
              <PullDownForm
                key="subHeadlineBackgroundRadius"
                name={<div>角丸</div>}
                value={styles.subHeadline.background.radius}
                options={SUB_HEADLINE_RADIUS_PATTERN}
                onChange={(e) =>
                  stylesCallbacks.subHeadline.background.setRadius(Number(e))
                }
              />,
              <MarginForm
                key="subHeadlineBackgroundMargin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 15, step: 1 }}
                rightLeftOptions={{ min: 0, max: 100, step: 10 }}
                topBottomMarginValue={styles.subHeadline.background.padding.top}
                rightLeftMarginValue={
                  styles.subHeadline.background.padding.right
                }
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.subHeadline.background.padding.setTop(
                    Number(e)
                  );
                  stylesCallbacks.subHeadline.background.padding.setBottom(
                    Number(e)
                  );
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.subHeadline.background.padding.setRight(
                    Number(e)
                  );
                  stylesCallbacks.subHeadline.background.padding.setLeft(
                    Number(e)
                  );
                }}
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.HEADLINE:
      return [
        <FormItemView
          key="headline"
          titleElement={
            <FormTitle name="見出し" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="headlineTextSize"
                name={<div>文字サイズ</div>}
                value={styles.headline.text.size}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.headline.text.setSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.ARTICLE:
      return [
        <FormItemView
          key="text"
          titleElement={
            <FormTitle name="本文" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="textSize"
                name={<div>文字サイズ</div>}
                value={styles.article.size}
                min={10}
                max={60}
                step={2}
                onChange={(e) => stylesCallbacks.article.setSize(Number(e))}
                valueTextEditCallback={(e) => `${e}px`}
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.BUTTON:
      return [
        <FormItemView
          key="button"
          titleElement={
            <FormTitle name="ボタン" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="buttonTextSize"
                name={<div>文字サイズ</div>}
                value={styles.button.text.size}
                min={10}
                max={60}
                step={2}
                onChange={(e) => stylesCallbacks.button.text.setSize(Number(e))}
                valueTextEditCallback={(e) => `${e}px`}
              />,
            ],
            [
              <RangeSliderForm
                key="buttonRadius"
                name={<div>角丸</div>}
                value={styles.button.background.radius}
                min={0}
                max={50}
                step={5}
                onChange={(e) =>
                  stylesCallbacks.button.background.setRadius(Number(e))
                }
                valueTextEditCallback={() =>
                  `${styles.button.background.radius}px`
                }
              />,
              <MarginForm
                key="margin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 15, step: 1 }}
                rightLeftOptions={{ min: 0, max: 100, step: 10 }}
                topBottomMarginValue={styles.button.background.padding.top}
                rightLeftMarginValue={styles.button.background.padding.right}
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.button.background.padding.setTop(Number(e));
                  stylesCallbacks.button.background.padding.setBottom(
                    Number(e)
                  );
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.button.background.padding.setRight(Number(e));
                  stylesCallbacks.button.background.padding.setLeft(Number(e));
                }}
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.COUPON_CODE:
      return [
        <FormItemView
          key="couponForm"
          titleElement={
            <FormTitle name="クーポンコード" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="couponCodeTextSize"
                name={<div>文字サイズ</div>}
                value={styles.couponCode.text.size}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.couponCode.text.setSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
            ],
          ]}
        />,
        <FormItemView
          key="copyButton"
          titleElement={
            <FormTitle
              name="クーポンコピーボタン"
              additional={precautionaryIcon}
            />
          }
          mainElements={[
            [
              <RangeSliderForm
                key="couponCopyButtonTextSize"
                name={<div>文字サイズ</div>}
                value={styles.couponCopyButton.text.size}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.couponCopyButton.text.setSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
            ],
            [
              <RangeSliderForm
                key="buttonRadius"
                name={<div>角丸</div>}
                value={styles.couponCopyButton.background.radius}
                min={0}
                max={50}
                step={5}
                onChange={(e) =>
                  stylesCallbacks.couponCopyButton.background.setRadius(
                    Number(e)
                  )
                }
                valueTextEditCallback={() =>
                  `${styles.couponCopyButton.background.radius}px`
                }
              />,
              <MarginForm
                key="margin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 15, step: 1 }}
                rightLeftOptions={{ min: 0, max: 100, step: 10 }}
                topBottomMarginValue={
                  styles.couponCopyButton.background.padding.top
                }
                rightLeftMarginValue={
                  styles.couponCopyButton.background.padding.right
                }
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.couponCopyButton.background.padding.setTop(
                    Number(e)
                  );
                  stylesCallbacks.couponCopyButton.background.padding.setBottom(
                    Number(e)
                  );
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.couponCopyButton.background.padding.setRight(
                    Number(e)
                  );
                  stylesCallbacks.couponCopyButton.background.padding.setLeft(
                    Number(e)
                  );
                }}
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.CLOSE_BUTTON:
      return [
        <FormItemView
          key="closeButton"
          titleElement={
            <FormTitle name="クローズボタン" additional={precautionaryIcon} />
          }
          mainElements={[
            [
              <PullDownForm
                key="closeButtonSize"
                name={<div>サイズ</div>}
                value={styles.closeButton.size}
                options={closeButtonSizeOptions}
                onChange={(e) => stylesCallbacks.closeButton.setSize(e)}
              />,
            ],
          ]}
        />,
      ];

    default:
      return undefined;
  }
};

const createPCEditFormItems = (
  parts: CreativeParts[],
  styles: ActionCreationContentsPCStyleParameters,
  stylesCallbacks: ActionCreationContentsPCStyleCallbacks
): JSX.Element[] => {
  let items: JSX.Element[] = [];

  parts.forEach((p) => {
    const formParts = selectFormParts(p, styles, stylesCallbacks);

    if (formParts && formParts.length > 0) {
      items = items.concat(formParts);
    }
  });

  return items;
};

export default createPCEditFormItems;
