import { useCallback, useState } from "react";
import { ScheduledCampaign } from "types/campaign";

import RestApi from "utils/RestApi";
import CampaignRepository from "utils/repositories/CampaignRepository";

const useScheduledCampaign = (
  managementApi: RestApi
): [ScheduledCampaign[] | null, () => void] => {
  const [scheduledCampaigns, setScheduledCampaigns] = useState<
    ScheduledCampaign[] | null
  >(null);

  const load = useCallback(() => {
    setScheduledCampaigns(null);
    new CampaignRepository(managementApi).loadScheduled().then((response) => {
      if (response) {
        setScheduledCampaigns(response.items);
      }
    });
  }, [managementApi]);

  return [scheduledCampaigns, load];
};

export default useScheduledCampaign;
