import editIcon from "images/icons/icon_edit.svg";
import styles from "./IconButton.module.scss";

const EditButton = (props: { onClick?: () => void }) => {
  const click = props.onClick ? props.onClick : () => {};

  return (
    <button className={styles.iconButton} onClick={click}>
      <img src={editIcon} alt="edit" />
    </button>
  );
};

export default EditButton;
