import React, { useMemo } from "react";
import styles from "./OrangeHeaderTable.module.scss";
import animeStyles from "animation.module.scss";

import BlankMessage from "templates/ezPush/BlankMessage";
import LoadingIcon from "atoms/LoadingIcon";
import LoadingView from "organisms/account/LoadingView";

export type OrangeHeaderTableCell = {
  item: string | number | JSX.Element;
  width?: number | undefined;
  isAlignRight?: boolean;
  isNoPadding?: boolean;
};

export type OrangeHeaderTableRecord = {
  cells: OrangeHeaderTableCell[];
};

/**
 * オレンジヘッダーテーブル
 * 要件
 * - 先頭行は固定の幅
 * - 先頭行以外は、表の全体の幅に収まる中で可変（幅指定できない）
 * - 文字数が多い場合はezcx同様「...」省略処理にする
 * - 日付系は２段指定、管理名とセグメント指定は文字数に応じて１段か２段になり、最大２段。
 *    - 外部からのrecordsで指定する
 * @param param0
 * @returns
 */
const OrangeHeaderTable: React.VFC<{
  headers: (string | JSX.Element)[];
  records: OrangeHeaderTableRecord[] | null;
  blankMessage: string;
}> = ({ headers, records, blankMessage }) => {
  const headerElement: JSX.Element = useMemo(
    () => (
      <tr>
        {headers.map((e, i) => (
          <th key={i}>{e}</th>
        ))}
      </tr>
    ),
    [headers]
  );

  const recordElements: JSX.Element[] = useMemo(() => {
    return records
      ? records.map((record, i) => (
          <tr key={i} className={animeStyles.fadeIn}>
            {record.cells.map((cell, j) => (
              <td
                key={j}
                width={cell.width}
                align={cell.isAlignRight ? "right" : "left"}
                style={{
                  padding: cell.isNoPadding ? 0 : undefined,
                  wordBreak: "break-all",
                }}
              >
                {cell.item}
              </td>
            ))}
          </tr>
        ))
      : [];
  }, [records]);

  if (records === null) {
    return <LoadingView iconElement={<LoadingIcon />} />;
  }

  return (
    <div className={styles.orangeHeaderTableFrame}>
      <table className={styles.orangeHeaderTable}>
        <thead className={styles.header}>{headerElement}</thead>
        {recordElements ? (
          <tbody className={styles.body}>{recordElements}</tbody>
        ) : (
          <></>
        )}
      </table>
      {records.length === 0 ? <BlankMessage text={blankMessage} /> : undefined}
    </div>
  );
};

export default OrangeHeaderTable;
