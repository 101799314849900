import { ExpirationPeriod } from "interfaces/models";
import ExpirationPeriodDatePicker from "./ExpirationPeriodDatePicker";
import styles from "./ExpirationPeriodForm.module.scss";

type ExpirationPeriodFormProps = {
  value: ExpirationPeriod;
  onChange: (value: ExpirationPeriod) => void;
};
const ExpirationPeriodForm = ({
  value,
  onChange,
}: ExpirationPeriodFormProps): JSX.Element | null => {
  return (
    <div className={styles.expirationPeriodForm}>
      <div className={styles.fromDate}>
        <ExpirationPeriodDatePicker
          title="開始日時"
          date={new Date(value.from_date * 1000)}
          onChange={(e) => {
            onChange({
              from_date: e.getTime() / 1000,
              to_date: value.to_date,
            });
          }}
        />
      </div>
      <div className={styles.toDate}>
        <ExpirationPeriodDatePicker
          title="終了日時"
          date={new Date(value.to_date * 1000)}
          onChange={(e) => {
            onChange({
              from_date: value.from_date,
              to_date: e.getTime() / 1000,
            });
          }}
        />
      </div>
    </div>
  );
};

export default ExpirationPeriodForm;
