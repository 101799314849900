import React from "react";
import styles from "./MultipleLineText.module.scss";

const MultipleLineText: React.VFC<{
  texts: (string | JSX.Element)[];
  align?: "left" | "center" | "right" | "justify" | "initial" | "inherit";
}> = ({ texts, align = "left" }) => {
  return (
    <ul className={styles.multipleLineText} style={{ textAlign: align }}>
      {texts.map((e, i) => (
        <li key={i}>{e}</li>
      ))}
    </ul>
  );
};

export default MultipleLineText;
