import React from "react";
import { DestinationCollectRule } from "types/destination_collect_rule";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import { unixTimeToDateTimeSecond } from "utils/format/unixtime";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";

import styles from "./SegmentationRuleDetailTable.module.scss";

import VerticalTable from "atoms/ezPushShared/accountPageShared/VerticalTable";
import SegmentationRuleTreeTable from "./SegmentationRuleTreeTable";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import { TOOL_TIP_TEXT } from "app/system_defaults/tool_tip_texts";

const SegmentationRuleDetailTable: React.FC<{
  destinationCollectRule: DestinationCollectRule;
  urls: MeasurementUrlSettingWithType[] | null;
}> = ({ destinationCollectRule, urls }) => {
  return (
    <div className={styles.segmentationRuleDetailTable}>
      <VerticalTable
        items={[
          {
            label: "セグメント名",
            value: destinationCollectRule.name,
          },
          {
            label: (
              <HorizontalIconLayout
                elements={[
                  <span>想定対象者数</span>,
                  <ToolTip
                    text={
                      TOOL_TIP_TEXT.ACCOUNT.SEGMENTATION_RULE.HOME
                        .DESTINATION_COUNT.LABEL
                    }
                  />,
                ]}
              />
            ),
            value:
              destinationCollectRule.destination_count !== null &&
              destinationCollectRule.destination_count_created_at
                ? `${new DescriptionFormatter().addCommasToNumber(
                    destinationCollectRule.destination_count
                  )}（${unixTimeToDateTimeSecond(
                    destinationCollectRule.destination_count_created_at
                  )} 集計）`
                : "集計中",
          },
          {
            label: "最終更新日時",
            value: unixTimeToDateTimeSecond(destinationCollectRule.updated_at),
          },
          {
            label: "セグメント条件",
            value: destinationCollectRule.form ? (
              <SegmentationRuleTreeTable
                form={JSON.parse(destinationCollectRule.form)}
                urlSettings={urls}
              />
            ) : (
              <div>-</div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SegmentationRuleDetailTable;
