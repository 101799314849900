import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";
import AccountPageBase from "./AccountPageBase";
import useDeliveryReportPage from "app/hooks/v2/deliveryReport/useDeliveryReportPage";
import DeliveryReportPageMain from "organisms/ezPush/deliveryReport/DeliveryReportPageMain";

const DeliveryReportPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = useDeliveryReportPage(
    accountContext.managementApi
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <DeliveryReportPageMain parameters={parameters} callbacks={callbacks} />
      }
      modalContents={parameters.modal}
    />
  );
};

export default DeliveryReportPage;
