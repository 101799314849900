import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import { ActionFrequency, ACTION_FREQUENCY_LABELS } from "interfaces/models";

type FrequencyFormProps = {
  frequency: ActionFrequency;
  setFrequency: (value: ActionFrequency) => void;
};

const FrequencyForm = ({ frequency, setFrequency }: FrequencyFormProps) => {
  const frequencyOptions = (
    Object.keys(
      ACTION_FREQUENCY_LABELS
    ) as (keyof typeof ACTION_FREQUENCY_LABELS)[]
  ).map((k) => ({ value: k, label: ACTION_FREQUENCY_LABELS[k] }));
  return (
    <div style={{ width: 142 }}>
      <BorderedSelectBox
        options={frequencyOptions}
        value={frequency}
        onChange={(e) => {
          if (frequency !== e) {
            setFrequency(e as ActionFrequency);
          }
        }}
      />
    </div>
  );
};

export default FrequencyForm;
