import {
  TestNotificationRequest,
  TestNotificationResponse,
  TestNotificationStatusRequest,
  TestNotificationStatusResponse,
} from "types/test_notification";
import RestApi from "utils/RestApi";

export class TestNotificationRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async request(request: TestNotificationRequest) {
    const data: TestNotificationResponse = await this.managementApi.post(
      `notification/test`,
      request
    );
    return data;
  }

  async getStatus(request: TestNotificationStatusRequest) {
    const data: TestNotificationStatusResponse = await this.managementApi.get(
      `notification/test/${request.request_uuid}`
    );
    return data;
  }
}
