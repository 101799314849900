import React from "react";
import styles from "./Panel.module.scss";

const Panel: React.VFC<{
  titleElement?: JSX.Element;
  itemElements: JSX.Element[];
}> = ({ titleElement, itemElements }) => {
  return (
    <div className={styles.panel}>
      {titleElement ? (
        <div className={styles.title}>{titleElement}</div>
      ) : undefined}
      {itemElements.map((elem, i) => (
        <div key={i} className={styles.item}>
          {elem}
        </div>
      ))}
    </div>
  );
};

export default Panel;
