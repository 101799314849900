import React from "react";
import styles from "./UnderBarTitle.module.scss";
import fontStyles from "fontStyles.module.scss";

const UnderBarTitle: React.VFC<{ title: string | JSX.Element }> = ({
  title,
}) => (
  <div className={styles.underBarTitle}>
    <div className={fontStyles.boldText}>{title}</div>
  </div>
);

export default UnderBarTitle;
