import RestApi from "utils/RestApi";
import useDestinationCollectRule from "./useDestinationCollectRule";
import { useEffect } from "react";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import { DestinationCollectRule } from "types/destination_collect_rule";
import useMeasurementUrls from "./useMeasurementUrls";
import useDestinationCollectRules from "../../pushNotification/useDestinationCollectRules";

export type SegmentationRuleEditPageRequireData = {
  urls: MeasurementUrlSettingWithType[];
  rule: DestinationCollectRule | null;
  existRules: DestinationCollectRule[] | null;
};

const useSegmentationRuleEditPageRequireData = (
  managementApi: RestApi,
  destinationCollectRuleUuid: string | null
): SegmentationRuleEditPageRequireData => {
  const [existRules, loadDestinationCollectRules] =
    useDestinationCollectRules(managementApi);

  const [urls, loadUrls] = useMeasurementUrls(managementApi);

  const [destinationCollectRule, getTargetDestinationCollectRule] =
    useDestinationCollectRule(managementApi);

  useEffect(() => {
    if (destinationCollectRuleUuid) {
      getTargetDestinationCollectRule(destinationCollectRuleUuid);
    }
  }, [getTargetDestinationCollectRule, destinationCollectRuleUuid]);

  useEffect(() => {
    loadUrls();
  }, [loadUrls]);

  useEffect(() => {
    loadDestinationCollectRules();
  }, [loadDestinationCollectRules]);

  return {
    urls: urls,
    rule: destinationCollectRule,
    existRules: existRules,
  };
};

export default useSegmentationRuleEditPageRequireData;
