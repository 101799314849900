import styles from "./InputForm.module.scss";

export interface InputFormProps {
  name: string;
  type: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
}

const InputForm = (props: { item: InputFormProps }) => {
  return (
    <input
      className={styles.input}
      name={props.item.name}
      type={props.item.type}
      value={props.item.value}
      onChange={(event) => props.item.onChange(event.target.value)}
      placeholder={props.item.placeholder}
    />
  );
};

export default InputForm;
