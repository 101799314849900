/**
 * FIXME: Classify and Move under to 'model/*'
 */
import { CSVLink } from "react-csv";
import { ActionContentsParameters } from "./model/actionContentsParameters";

export const ACTION_STATUS = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  TESTING: "TESTING",
} as const;

export type ActionStatus = typeof ACTION_STATUS[keyof typeof ACTION_STATUS];

export const ActionBalancingTypes = {
  NONE: "NONE",
  DEVICE: "DEVICE",
  RANDOM: "RANDOM",
} as const;

export type ActionBalancingTypesType =
  typeof ActionBalancingTypes[keyof typeof ActionBalancingTypes];

export type ExpirationPeriod = {
  from_date: number;
  to_date: number;
};

export type RandomBalancingOption = {
  a_rate: number;
  pattern_a: number;
  pattern_b: number;
};

export const randomBalancingARateOption = [
  { label: "100%表示(効果検証無効)", value: 1.0 },
  { label: "90%表示", value: 0.9 },
  { label: "80%表示", value: 0.8 },
  { label: "70%表示", value: 0.7 },
  { label: "60%表示", value: 0.6 },
  { label: "50%表示", value: 0.5 },
  { label: "40%表示", value: 0.4 },
  { label: "30%表示", value: 0.3 },
  { label: "20%表示", value: 0.2 },
  { label: "10%表示", value: 0.1 },
];

export type DeviceBalancingOption = {
  pc: number;
  mobile: number;
};

export const ACTION_TYPES = {
  FULL_MODAL: "full-modal",
  BOTTOM_MODAL: "bottom-modal",
  TOGGLE_MODAL: "toggle-modal",
} as const;

export type ActionTypes = typeof ACTION_TYPES[keyof typeof ACTION_TYPES];

export type ActionContentsPattern = {
  contents_id: string;
  display_type: ActionTypes;
  version: number;
  html: string;
};

export type ActionContentsNoneBalancing = {
  balancing: "NONE";
  pattern: ActionContentsPattern;
  patterns: ActionContentsPattern[];
  balancing_option: {
    is_ab_test: false;
  };
  details?: ActionContentsParameters;
};

export type ActionContentsDeviceBalancing = {
  balancing: "DEVICE";
  patterns: ActionContentsPattern[];
  balancing_option: {
    details: DeviceBalancingOption;
    is_ab_test: boolean;
  };
  details?: ActionContentsParameters;
};

export type ActionContentsRandomBalancing = {
  balancing: "RANDOM";
  patterns: ActionContentsPattern[];
  balancing_option: {
    details: RandomBalancingOption;
    is_ab_test: boolean;
  };
  details?: ActionContentsParameters;
};

export type ActionContents =
  | ActionContentsNoneBalancing
  | ActionContentsDeviceBalancing
  | ActionContentsRandomBalancing;

export const ACTION_FREQUENCY = {
  SESSION_ONCE: "SESSION_ONCE",
  TOTAL_ONCE: "TOTAL_ONCE",
  ONCE_IN_24H: "ONCE_IN_24H",
  EVERY_PV: "EVERY_PV",
} as const;

export type ActionFrequency =
  typeof ACTION_FREQUENCY[keyof typeof ACTION_FREQUENCY];

export const ACTION_FREQUENCY_LABELS: { [key: string]: string } = {
  [ACTION_FREQUENCY.SESSION_ONCE]: "セッションごと",
  [ACTION_FREQUENCY.TOTAL_ONCE]: "一回のみ",
  [ACTION_FREQUENCY.ONCE_IN_24H]: "24時間ごと",
  [ACTION_FREQUENCY.EVERY_PV]: "PVごと",
} as const;

export const ACTION_TERMINATE_REACTION = {
  NONE: "NONE",
  CLICKED: "CLICKED",
} as const;

export type ActionTerminateReaction =
  typeof ACTION_TERMINATE_REACTION[keyof typeof ACTION_TERMINATE_REACTION];

export const ActionTerminateReactionLabels: { [key: string]: string } = {
  [ACTION_TERMINATE_REACTION.NONE]: "なし",
  [ACTION_TERMINATE_REACTION.CLICKED]: "ボタンクリック時",
};

export const LOCATION_COMPARE_PATTERN = {
  INCLUDE: "include",
  EXCLUDE: "exclude",
  COMPLETE: "complete",
} as const;

export type LocationComparePattern =
  typeof LOCATION_COMPARE_PATTERN[keyof typeof LOCATION_COMPARE_PATTERN];

export type LocationPattern = {
  pattern: LocationComparePattern;
  value: string;
};

export const LocationPatternLabels: { [key: string]: string } = {
  [LOCATION_COMPARE_PATTERN.INCLUDE]: "を含む",
  [LOCATION_COMPARE_PATTERN.EXCLUDE]: "を除く",
  [LOCATION_COMPARE_PATTERN.COMPLETE]: "と一致する",
};

// FIXME: PATH_PARAMETER_COMPARE_PATTERN -> QUERY_PARAMETER_COMPARE_PATTERN
export const PATH_PARAMETER_COMPARE_PATTERN = {
  INCLUDE: "include",
  EXCLUDE: "exclude",
} as const;

// FIXME: PathParameterComparePattern -> QueryParameterComparePattern
export type PathParameterComparePattern =
  typeof PATH_PARAMETER_COMPARE_PATTERN[keyof typeof PATH_PARAMETER_COMPARE_PATTERN];

// FIXME: PathParameterPattern -> QueryParameterPattern
export type PathParameterPattern = {
  pattern: PathParameterComparePattern;
  key: string;
  value?: string;
};

export const QueryParameterPatternLabels: { [key: string]: string } = {
  [PATH_PARAMETER_COMPARE_PATTERN.INCLUDE]: "を含む",
  [PATH_PARAMETER_COMPARE_PATTERN.EXCLUDE]: "を除く",
};

export type ActionExecutionConditions = {
  locations: LocationPattern[];
  exclude_locations?: LocationPattern[];
  segmentation_rule_ids: string[];
  expiration_period: ExpirationPeriod | null;
  frequency: ActionFrequency;
  trigger: {
    display_time_wait_sec: number;
  };
  terminate_reaction: ActionTerminateReaction;
  url_condition: {
    includes: ManagementApiData.UrlMatchPattern[];
    excludes: ManagementApiData.UrlMatchPattern[];
  };
  execute_timing: ExecuteTiming[];
};

export type ExecuteTiming = {
  period: Period;
  days: Days;
  process_holiday: ProcessHolidayPattern;
  timezone: Timezone;
  nation: Nation;
};

export type Period = {
  from_time: PeriodTime;
  to_time: PeriodTime;
};

export type PeriodTime = {
  hour: number;
  minute: number;
};

export type Days = {
  monday: Day;
  tuesday: Day;
  wednesday: Day;
  thursday: Day;
  friday: Day;
  saturday: Day;
  sunday: Day;
};

export type Day = {
  is_active: boolean;
};

export const PROCESS_HOLIDAY_PATTERN = {
  INCLUDE: "INCLUDE",
  EXCLUDE: "EXCLUDE",
} as const;

export type ProcessHolidayPattern =
  typeof PROCESS_HOLIDAY_PATTERN[keyof typeof PROCESS_HOLIDAY_PATTERN];

export const NATION = {
  JP: "JP",
} as const;

export type Nation = typeof NATION[keyof typeof NATION];

export const TIMEZONE = {
  Tokyo: "Asia/Tokyo",
} as const;

export type Timezone = typeof TIMEZONE[keyof typeof TIMEZONE];

export type Action = {
  action_id: string;
  action_type: ActionTypes;
  execution_conditions: ActionExecutionConditions;
  contents: ActionContents;
  status: ActionStatus;
  details: ActionNumber;
};

export type Insight = {
  uuid: string;
  status: any;
  segmentation_rule: any;
  action: any;
};

// Segmentation rule conditions

export type UrlPattern = {
  location: LocationPattern[];
  parameters: PathParameterPattern[];
};

export type SessionPvCountCondition = {
  condition_type: string;
  parameters: {
    url: UrlPattern;
    count: number;
    operation?: {
      compare_type: string;
      value: any;
    };
  };
};

export type SessionScenarioPvCondition = {
  condition_type: string;
  parameters: {
    pattern: UrlPattern[];
  };
};

export type SessionStayingTimeCondition = {
  condition_type: string;
  parameters: {
    url: UrlPattern;
    stay_seconds: number;
  };
};

export type PageStayingTimeCondition = {
  condition_type: string;
  parameters: {
    url: UrlPattern;
    stay_seconds: number;
  };
};

export type PageScrollRateCondition = {
  condition_type: string;
  parameters: {
    url: UrlPattern;
    scroll_rate: number;
  };
};

export type UrlCountType = "location";

export type TotalPvCountCondition = {
  condition_type: string;
  parameters: {
    url: UrlPattern;
    count: number;
    range_hours?: number;
    url_count_type?: UrlCountType;
    operation?: IntegerCompareCondition;
  };
};

export const CustomerTypes = {
  SIGNED_UP: "signed_up",
  SIGNED_OUT: "signed_out",
};

export type CustomerTypesType =
  typeof CustomerTypes[keyof typeof CustomerTypes];

export const ComparePatterns = {
  EXISTENCE: "existance",
  WORD: "word",
  DATE: "date",
  INTEGER: "integer",
} as const;

export type CustomerTypeCondition = {
  condition_type: string;
  parameters: {
    key: string;
    customer_type: CustomerTypesType;
  };
};

export const ExistanceCompareTypes = {
  EXIST: "exist",
  NOT_EXIST: "not_exist",
};

export type ExistanceCompareTypesType =
  typeof ExistanceCompareTypes[keyof typeof ExistanceCompareTypes];

export type ExistanceCompareCondition = {
  operation_type: "existance";
  compare_type: ExistanceCompareTypesType;
};

export const WordCompareTypes = {
  EQUAL: "equal",
  INCLUDE: "include",
  MATCH_ANY: "match_any",
};

export type WordCompareTypesType =
  typeof WordCompareTypes[keyof typeof WordCompareTypes];

export const WordCompareTypesTypeLabels: {
  [key in WordCompareTypesType]: string;
} = {
  [WordCompareTypes.EQUAL]: "に完全一致する",
  [WordCompareTypes.INCLUDE]: "に一致する",
  [WordCompareTypes.MATCH_ANY]: "を含む",
};

export type WordCompareCondition = {
  value: string;
  operation_type: "word";
  compare_type: WordCompareTypesType;
};

export const DateCompareTypes = {
  ABSOLUTE: "absolute",
  RELATIVE: "relative",
};

export type DateCompareTypesType =
  typeof DateCompareTypes[keyof typeof DateCompareTypes];

export const DateCompareTargets = {
  YEAR: "year",
  MONTH: "month",
  DAY: "day",
};

export type DateCompareTargetsType =
  typeof DateCompareTargets[keyof typeof DateCompareTargets];

export type DateCompareCondition = {
  value: number;
  operation_type: "date";
  compare_type: DateCompareTypesType;
  compare_target: DateCompareTargetsType;
};

export const IntegerCompareTypes = {
  GreaterThanOrEqualTo: "greater_than_or_equal_to",
  LessThanOrEqualTo: "less_than_or_equal_to",
  GreaterThan: "greater_than",
  LessThan: "less_than",
  EqualTo: "equal_to",
};

export type IntegerCompareType =
  typeof IntegerCompareTypes[keyof typeof IntegerCompareTypes];

export const IntegerCompareTypeLabels: { [key in IntegerCompareType]: string } =
  {
    [IntegerCompareTypes.GreaterThanOrEqualTo]: "以上",
    [IntegerCompareTypes.LessThanOrEqualTo]: "以下",
    [IntegerCompareTypes.EqualTo]: "と等しい",
  };

export type IntegerCompareCondition = {
  operation_type: "integer";
  value: number;
  compare_type: IntegerCompareType;
};

export type CompareOperation =
  | ExistanceCompareCondition
  | WordCompareCondition
  | DateCompareCondition
  | IntegerCompareCondition;

export type CustomerAttributeCondition = {
  condition_type: string;
  parameters: {
    key: string;
    operation: CompareOperation;
  };
};

export type HourRange = {
  min_range_hours: number;
  max_range_hours: number;
};

export type AccessBlankCondition = {
  condition_type: string;
  parameters: HourRange;
};

export type TrackingAttributeCondition = {
  condition_type: string;
  parameters: {
    key: string;
    operation: {
      value: any;
      compare_type: string;
    };
  };
};

export const ConditionTypes = {
  SESSION_PV_COUNT: "session_pv_count",
  SESSION_SCENARIO_PV: "session_scenario_pv",
  SESSION_STAYING_TIME: "session_staying_time",
  PAGE_STAYING_TIME: "page_staying_time",
  PAGE_SCROLL_RATE: "page_scroll_rate",
  TOTAL_PV_COUNT: "total_pv_count",
  CUSTOMER_TYPE: "customer_type",
  CUSTOMER_ATTRIBUTE: "customer_attribute",
  ACCESS_BLANK: "access_blank",
  TRACKING_ATTRIBUTE: "tracking_attribute",
} as const;

export type Condition =
  | SessionPvCountCondition
  | SessionScenarioPvCondition
  | SessionStayingTimeCondition
  | PageStayingTimeCondition
  | PageScrollRateCondition
  | TotalPvCountCondition
  | CustomerTypeCondition
  | CustomerAttributeCondition
  | AccessBlankCondition
  | TrackingAttributeCondition;

export type SegmentationRule = {
  segmentation_rule_id: string;
  segmentation_rule_number: number;
  name: string;
  conditions: Condition[];
  linked_actions: string[];
  created_at: number;
  updated_at: number;
};

export type Account = {
  account_uuid: string;
  account_name: string;
  tag_data: string;
  cv_url: string;
};

export const Permission = {
  SYSTEM: "SYSTEM",
  GENERAL: "GENERAL",
};

export type PermissionType = typeof Permission[keyof typeof Permission];

export type ActionLabel = {
  id: number;
  name: string;
};

export type ActionDetail = {
  action_uuid: string;
  name: string;
  note: string;
  label: ActionLabel;
  cv_url: string;
};

export type ActionNumber = {
  account_uuid: string;
  action_uuid: string;
  number: string;
  action_detail: ActionDetail;
};

export type ActionNumberResponse = {
  [index: string]: ActionNumber;
};

export type ActionSummary = {
  action_uuid: string;
  command_executed_count: number;
  reaction_count: number;
  reaction_rate: number;
  reaction_cv_count: number;
  reaction_cv_rate: number;
  command_executed_cv_count: number;
};

export type SegmentationRuleDetail = {
  account_uuid: string;
  segmentation_rule_uuid: string;
  name: string;
};

export type SegmentationRuleDetailResponse = {
  [index: string]: SegmentationRuleDetail;
};

export type Action2 = {
  action: Action;
  actionNumber: ActionNumber;
};

export type CustomerAttributeHeader = {
  header: string;
};

export type CustomerAttributeHeaderResponse = {
  customer_attribute_headers: CustomerAttributeHeader[];
};

export const CLOSE_BUTTON_SIZE_PATTERN = {
  SMALL: "SMALL",
  MIDDLE: "MIDDLE",
  LARGE: "LARGE",
};

export type CloseButtonSizePatternType =
  typeof CLOSE_BUTTON_SIZE_PATTERN[keyof typeof CLOSE_BUTTON_SIZE_PATTERN];

export const contentsBasePaddingOptions = [
  { label: "なし", value: 0 },
  { label: "小", value: 5 },
  { label: "大", value: 10 },
];

export const closeButtonSizeOptions = [
  { label: "小", value: CLOSE_BUTTON_SIZE_PATTERN.SMALL },
  { label: "中", value: CLOSE_BUTTON_SIZE_PATTERN.MIDDLE },
  { label: "大", value: CLOSE_BUTTON_SIZE_PATTERN.LARGE },
];

export type CloseButtonSizeParameters = {
  key: string;
  size: number;
  radius: number;
  padding: number;
  positionPadding: number;
};

export const DECORATION_EDIT_DEVICE_TYPE = {
  PC: "PC",
  SP: "SP",
};

export type DecorationEditDeviceType =
  typeof DECORATION_EDIT_DEVICE_TYPE[keyof typeof DECORATION_EDIT_DEVICE_TYPE];

export const DECORATION_EDIT_DEVICE_TYPE_LABELS = [
  { label: "SP表示設定", value: DECORATION_EDIT_DEVICE_TYPE.SP },
  { label: "PC表示設定", value: DECORATION_EDIT_DEVICE_TYPE.PC },
];

export const DEVICE_DETAILS = {
  [DECORATION_EDIT_DEVICE_TYPE.PC]: { WIDTH: 600 },
  [DECORATION_EDIT_DEVICE_TYPE.SP]: { WIDTH: 375 },
};

export const REPORT_PERIOD_TYPE = {
  CURRENT_MONTH: "current_month",
  MONTHLY: "monthly",
  ONE_WEEK: "one_week",
};

export type ReportPeriodType =
  typeof REPORT_PERIOD_TYPE[keyof typeof REPORT_PERIOD_TYPE];

export const RESPONSE_REPORT_PERIOD_TYPE = {
  CURRENT_MONTH: "current_month",
  MONTHLY: "monthly",
  ONE_WEEK: "one_week",
  LAST_MONTH: "last_month",
};

export type ResponseReportPeriodType =
  typeof RESPONSE_REPORT_PERIOD_TYPE[keyof typeof RESPONSE_REPORT_PERIOD_TYPE];

export const REPORT_PERIOD_TYPE_LABELS = [
  { label: "当月", value: RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH },
  { label: "先月", value: RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH },
  { label: "過去一週間", value: RESPONSE_REPORT_PERIOD_TYPE.ONE_WEEK },
  { label: "過去月次", value: RESPONSE_REPORT_PERIOD_TYPE.MONTHLY },
];

export const REPORT_TYPE = {
  WHOLE: "whole",
  ACTION_DETAIL: "action_detail",
};

export type ReportType = typeof REPORT_TYPE[keyof typeof REPORT_TYPE];

export type ReportMetaDataList = {
  current_month: ReportMetaData[];
  last_month: ReportMetaData[];
  one_week: ReportMetaData[];
  monthly: MonthlyReportMetaData;
};

export type ReportMetaData = {
  report_type: ReportType;
  report_period_type: ReportPeriodType;
  from_timestamp_ms: number;
  to_timestamp_ms: number;
  created_at: number;
  report_path: string;
};

export type MonthlyReportMetaData = { [date: string]: ReportMetaData[] };

export type Score = {
  matched_uu: number;
  all_user: UserOrientedScore;
  triggered_uu_rate: number;
  triggered: number;
  reaction: number;
  reaction_rate: number;
  new_user: UserOrientedScore;
};

export type UserOrientedScore = {
  triggered: {
    uu: number;
    cv_uu: number;
    cvr: number;
  };
  not_triggered: {
    uu: number;
    cv_uu: number;
    cvr: number;
  };
  cvr_improve_rate: number | string;
};

export type ActionDetailScoreMeta = {
  no: number;
  name: string;
};

export type ActionDetailScoreItem = {
  action_detail: ActionDetailScoreMeta;
  score: Score;
};

export type ActionDetailScore = {
  [key: string]: ActionDetailScoreItem;
};

export type Report = {
  total: Score;
  each: ActionDetailScore;
};

export type RawReportRow = {
  column: string[];
};

export type RawReport = Array<Array<string>>;
export type MonthlyAccessesType = {
  reports: MonthlyAccess;
  meta: {
    timezone: string;
  };
};

export type MonthlyAccessDetail = {
  value: {
    pv: number;
    uu: number;
    cvuu: number;
    cvr: number;
  };
  meta: {
    time_range: {
      [from: string]: number;
      [to: number]: number;
    };
  };
};

export type MonthlyAccess = {
  [month: string]: MonthlyAccessDetail;
};

export type DailyAccessesType = {
  reports: DailyAccess;
  meta: {
    timezone: string;
  };
};

export type DailyAccess = {
  [date: string]: {
    value: {
      pv: number;
      uu: number;
      cvuu: number;
      cvr: number;
    };
  };
};

export type Accesses = {
  monthly: MonthlyAccessesType;
  daily: DailyAccessesType;
};

export type itemType = {
  date: string;
  pv: string | undefined;
  uu: string | undefined;
  cvuu: string | undefined;
  cvr: string | undefined;
};

export const ACCESSES_PERIOD_TYPE = {
  DAILY: "daily",
  MONTHLY: "monthly",
};

export const TERM_HEADER = [
  {
    label: "日別実績",
    value: ACCESSES_PERIOD_TYPE.DAILY,
  },
  {
    label: "月別実績",
    value: ACCESSES_PERIOD_TYPE.MONTHLY,
  },
];

export const REPORT_DETAIL_ROW_COUNT_PER_PAGE: number = 8;

export const SORT_DIRECTION = {
  ASC: "ASK",
  DESC: "DESC",
};

export const SORT_TARGET_KEY_ID = {
  no: "no",
  action_name: "action_name",
  target_uu: "target_uu",
  triggered: "triggered",
  triggered_uu: "triggered_uu",
  triggered_cvuu: "triggered_cvuu",
  triggered_cvr: "triggered_cvr",
  not_triggered: "not_triggered",
  not_triggered_uu: "not_triggered_uu",
  not_triggered_cvuu: "not_triggered_cvuu",
  not_triggered_cvr: "not_triggered_cvr",
  cvr_improve_rate: "cvr_improve_rate",
  action_done_rate: "action_done_rate",
  action_display_count: "action_display_count",
  reaction_count: "reaction_count",
  reaction_rate: "reaction_rate",
  triggered_new: "triggered_new",
  triggered_new_uu: "triggered_new_uu",
  triggered_new_cvuu: "triggered_new_cvuu",
  triggered_new_cvr: "triggered_new_cvr",
  not_triggered_new: "not_triggered_new",
  not_triggered_new_uu: "not_triggered_new_uu",
  not_triggered_new_cvuu: "not_triggered_new_cvuu",
  not_triggered_new_cvr: "not_triggered_new_cvr",
  cvr_improve_rate_new: "cvr_improve_rate_new",
};
export type sortColumnType = {
  columnId: string;
  sortDirection: string;
};

export type sortStateType = {
  columnId: string;
  sortDirection: string;
  isString: boolean;
}[];

export const SORT_STATUS: sortStateType = [
  { columnId: "no", sortDirection: SORT_DIRECTION.ASC, isString: false },
  {
    columnId: "action_name",
    sortDirection: SORT_DIRECTION.ASC,
    isString: true,
  },
  {
    columnId: "target_uu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_uu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_cvuu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_cvr",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_uu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_cvuu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_cvr",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "cvr_improve_rate",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "action_done_rate",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "action_display_count",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "reaction_count",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "reaction_rate",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_new",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_new_uu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_new_cvuu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "triggered_new_cvr",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_new",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_new_uu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_new_cvuu",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "not_triggered_new_cvr",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
  {
    columnId: "cvr_improve_rate_new",
    sortDirection: SORT_DIRECTION.ASC,
    isString: false,
  },
];

export type CSVDownloadRefObject = React.RefObject<
  CSVLink &
    HTMLAnchorElement & {
      link: HTMLAnchorElement;
    }
>;

//時間（HH）のリスト(00-23)
const LIST_FROM_HOURS_COUNT: number = 24;
export const LIST_FROM_HOURS: {
  label: string;
  value: number;
}[] = [...Array(LIST_FROM_HOURS_COUNT)].map((_, index) => {
  return { label: String(index).padStart(2, "0"), value: index };
});

//時間（HH）のリスト(00-24)
const LIST_TO_HOURS_COUNT: number = 25;
export const LIST_TO_HOURS: {
  label: string;
  value: number;
}[] = [...Array(LIST_TO_HOURS_COUNT)].map((_, index) => {
  return { label: String(index).padStart(2, "0"), value: index };
});

//時刻（MM）のリスト（指定分刻み）
const LIST_MINUTES_STEP: number = 5;
const LIST_MINUTES_COUNT: number = Math.ceil(60 / LIST_MINUTES_STEP);
export const LIST_MINUTES: {
  label: string;
  value: number;
}[] = [...Array(LIST_MINUTES_COUNT)].map((_, index) => {
  return {
    label: String(index * LIST_MINUTES_STEP).padStart(2, "0"),
    value: index * LIST_MINUTES_STEP,
  };
});

//初期値
export const DEFAULT_FROM_HOUR: number = 9;
export const DEFAULT_FROM_MINUTE: number = 0;
export const DEFAULT_TO_HOUR: number = 18;
export const DEFAULT_TO_MINUTE: number = 0;
export const DEFAULT_EXECUTE_TIMING: ExecuteTiming = {
  period: {
    from_time: {
      hour: DEFAULT_FROM_HOUR,
      minute: DEFAULT_FROM_MINUTE,
    },
    to_time: {
      hour: DEFAULT_TO_HOUR,
      minute: DEFAULT_TO_MINUTE,
    },
  },
  days: {
    monday: {
      is_active: true,
    },
    tuesday: {
      is_active: true,
    },
    wednesday: {
      is_active: true,
    },
    thursday: {
      is_active: true,
    },
    friday: {
      is_active: true,
    },
    saturday: {
      is_active: true,
    },
    sunday: {
      is_active: true,
    },
  },
  process_holiday: PROCESS_HOLIDAY_PATTERN.INCLUDE,
  timezone: TIMEZONE.Tokyo,
  nation: NATION.JP,
};

export const DAY_OF_WEEK = {
  MONDAY: "monday",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
  SUNDAY: "sunday",
} as const;

export type DAY_OF_WEEK_TYPE = typeof DAY_OF_WEEK[keyof typeof DAY_OF_WEEK];

export const HOLIDAY = {
  HOLIDAY: "holiday",
} as const;

export type HOLIDAY_TYPE = typeof HOLIDAY[keyof typeof HOLIDAY];
