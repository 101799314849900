import deleteIcon from "images/icons/icon_delete.svg";
import styles from "./IconButton.module.scss";

const DeleteButton = (props: { onClick?: () => void }) => {
  return (
    <button type="button" className={styles.iconButton} onClick={props.onClick}>
      <img src={deleteIcon} alt="delete" />
    </button>
  );
};

export default DeleteButton;
