import { ActionSummary } from "interfaces/models";

import styles from "./ActionTableItem.module.scss";
import { getNumberLocale, getRateLocale } from "utils/Locale";

const CalculatingElement = () => (
  <td className={styles.td_right}>
    <span className={styles.calculating}>計算中...</span>
  </td>
);

const SummaryNumberCell = ({ num }: { num: number }) => (
  <td className={styles.td_right}>{getNumberLocale(num) || "-"}</td>
);

const SummaryRateCell = ({ num }: { num: number }) => (
  <td className={styles.td_right}>{getRateLocale(num) || "-"}</td>
);

interface ActionTableItemProps {
  actionNumber: string;
  actionSummary: ActionSummary | undefined;
  nameElement: JSX.Element;
  statusSelectElement: JSX.Element;
}

/**
 * Component Category: Organism
 */
const ActionTableItem = ({
  actionNumber,
  actionSummary,
  nameElement,
  statusSelectElement,
}: ActionTableItemProps) => {
  const summaryColumnElements =
    actionSummary !== undefined
      ? [
          <SummaryNumberCell
            key="command_executed_count"
            num={actionSummary.command_executed_count}
          />,
          <SummaryNumberCell
            key="reaction_count"
            num={actionSummary.reaction_count}
          />,
          <SummaryRateCell
            key="reaction_rate"
            num={actionSummary.reaction_rate}
          />,
          <SummaryNumberCell
            key="command_executed_cv_count"
            num={actionSummary.command_executed_cv_count}
          />,
        ]
      : [
          <CalculatingElement key="command_executed_count" />,
          <CalculatingElement key="reaction_count" />,
          <CalculatingElement key="reaction_rate" />,
          <CalculatingElement key="command_executed_cv_count" />,
        ];

  return (
    <tr className={styles.tr}>
      <td className={styles.td}>{actionNumber}</td>
      <td className={styles.td__action_name}>{nameElement}</td>
      <td className={styles.td}>{statusSelectElement}</td>
      {summaryColumnElements}
    </tr>
  );
};

export default ActionTableItem;
