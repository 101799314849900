import React, { useState } from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import AccountPageBase from "./AccountPageBase";
import { SubscriptionStatisticsParameters } from "app/hooks/v2/useSubscriptionStatistics";
import HomePanels from "organisms/ezPush/home/HomePanels";
import { GrantMessageV2 } from "types/grant_message";

const HomePage: React.VFC<{
  accountContext: AccountPageContextV2;
  grantMessage: GrantMessageV2 | undefined;
  statsParameters: SubscriptionStatisticsParameters;
  loadGrantMessage: () => void;
}> = ({ accountContext, grantMessage, statsParameters, loadGrantMessage }) => {
  const [modalContents, setModalContents] = useState<JSX.Element | undefined>(
    undefined
  );

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <HomePanels
          accountContext={accountContext}
          grantMessage={grantMessage}
          stats={statsParameters}
          setModalContents={setModalContents}
          loadGrantMessage={loadGrantMessage}
        />
      }
      modalContents={modalContents}
    />
  );
};

export default HomePage;
