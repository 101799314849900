import LoadingIcon from "atoms/LoadingIcon";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";

export const SegmentationRuleMeasurementUrlModal: React.VFC<{
  element: JSX.Element;
  clearModalElement: () => void;
}> = ({ element, clearModalElement }) => (
  <ModalBackground>
    <ElementModal
      title={"ページ詳細"}
      element={element}
      buttons={[
        <TransparentButton text="閉じる" onClick={clearModalElement} />,
      ]}
    />
  </ModalBackground>
);

export const SegmentationRuleSaveModal: React.VFC<{
  save: () => void;
  clearModal: () => void;
}> = ({ save, clearModal }) => (
  <ModalBackground>
    <NotificationModal
      title={"セグメントを保存します。よろしいですか？"}
      buttons={[
        <TransparentButton text="いいえ" onClick={clearModal} />,
        <OrangeButton text="はい" onClick={save} />,
      ]}
    />
  </ModalBackground>
);

export const SegmentationRuleSavingModal = (): JSX.Element => (
  <ModalBackground>
    <NotificationModal
      title={"セグメントを保存します。よろしいですか？"}
      buttons={[<LoadingIcon />]}
    />
  </ModalBackground>
);

export const SegmentationRuleEditCancelModal: React.VFC<{
  backToHome: () => void;
  clearModal: () => void;
}> = ({ backToHome, clearModal }) => (
  <ModalBackground>
    <NotificationModal
      title={"セグメントの作成をキャンセルします。よろしいですか？"}
      buttons={[
        <TransparentButton text="いいえ" onClick={clearModal} />,
        <OrangeButton text="はい" onClick={backToHome} />,
      ]}
    />
  </ModalBackground>
);
