import { PushNotificationContentsParameters } from "app/hooks/v2/pushNotification/usePushNotificationContents";
import { PushNotificationEditFormParameters } from "app/hooks/v2/pushNotification/usePushNotificationEditForm";
import { PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST } from "app/system_defaults/error_message_list";
import { PUSH_NOTIFICATION_FORM_VALIDATE_RULE } from "app/system_defaults/validateRule";
import ErrorMessage from "utils/ErrorMessage";
import UrlTranslator from "./urlTranslator";
import { PushNotificationDeliveryScheduleParameters } from "app/hooks/v2/pushNotification/usePushNotificationDeliverySchedule";
import { ScheduledCampaign } from "types/campaign";
import { DeliveryScheduleRule } from "types/delivery_schedule";
import { ApiRequestBuilder } from "./apiRequestBuilder";

export class PushNotificationFormValidator {
  validateName(name: string): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (name === "") {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.NAME.MUST_BE_FILLED
        )
      );
    }

    if (name.length > PUSH_NOTIFICATION_FORM_VALIDATE_RULE.NAME.MAX_LENGTH) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.NAME.CHARACTER_LIMIT
        )
      );
    }

    if (
      PUSH_NOTIFICATION_FORM_VALIDATE_RULE.NAME.INVALID_FIRST_CHARACTER.test(
        name.charAt(0)
      )
    ) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.NAME.FIRST_CHARACTER_IS_NOT_FIGURE
        )
      );
    }

    const reg = new RegExp(
      PUSH_NOTIFICATION_FORM_VALIDATE_RULE.NAME.EMOJI_LIST.join("|"),
      "g"
    );

    /**
     * @param  {String} value
     * @return {String}
     */
    if (reg.test(name)) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.NAME.EMOJI_LIMIT
        )
      );
    }

    return errors;
  }

  validateContentsTitle(title: string): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (title === "") {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.TITLE.MUST_BE_FILLED
        )
      );
    }

    if (
      title.length >
      PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.TITLE.MAX_LENGTH
    ) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.TITLE.CHARACTER_LIMIT
        )
      );
    }

    return errors;
  }

  validateContentsBody(body: string): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (
      body.length >
      PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.BODY.MAX_LENGTH
    ) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.BODY.CHARACTER_LIMIT
        )
      );
    }

    return errors;
  }

  validateContentsRedirectUrl(redirectUrl: string): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (
      redirectUrl.length >
      PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.REDIRECT_URL.MAX_LENGTH
    ) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.REDIRECT_URL.CHARACTER_LIMIT
        )
      );
    }

    if (!redirectUrl) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.REDIRECT_URL.MUST_BE_FILLED
        )
      );
    } else {
      const urlTranslator = new UrlTranslator();

      if (urlTranslator.encode(redirectUrl) === null) {
        errors.push(
          new ErrorMessage(
            PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.REDIRECT_URL.INVALID_URL_FORMAT
          )
        );
      }
    }

    return errors;
  }

  validateContentsImage(image: File | null, isNewImage: boolean) {
    let errors: ErrorMessage[] = [];

    // NOTE: 新しいimageの場合のみ検査する。
    // すでにアップロードされている場合は検査しない。contentsのheader情報を取得できないため。
    // FIXME: header情報を取得できるように画像のcdnとs3の設定を変える
    if (isNewImage && image) {
      // NOTE: image size
      if (
        image.size >
        PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.IMAGE.MAX_IMAGE_BYTE
      ) {
        errors.push(
          new ErrorMessage(
            PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.IMAGE.SIZE_LIMIT
          )
        );
      }
      const fileType: string = image.type;

      // NOTE: image type
      if (
        !PUSH_NOTIFICATION_FORM_VALIDATE_RULE.CONTENTS.IMAGE.ACCEPT_FILE_TYPE.some(
          (type) => fileType.includes(type)
        )
      ) {
        errors.push(
          new ErrorMessage(
            PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.IMAGE.CONTENTS_TYPE_RESTRICT
          )
        );
      }
    }

    return errors;
  }

  validateContentsImageUrl(imageUrl: string | null, isNeedImage: boolean) {
    let errors: ErrorMessage[] = [];

    // NOTE: 画像toggle onで予約保存時は、意図しない画像未設定と判断してエラーを出す
    if (isNeedImage && !imageUrl) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CONTENTS.IMAGE.MUST_BE_FILLED
        )
      );
    }

    return errors;
  }

  validateContents(
    contents: PushNotificationContentsParameters
  ): ErrorMessage[] {
    const titleErrors: ErrorMessage[] = this.validateContentsTitle(
      contents.title
    );

    const bodyErrors: ErrorMessage[] = this.validateContentsBody(contents.body);

    const imageErrors: ErrorMessage[] = this.validateContentsImage(
      contents.image,
      contents.isNewImage
    );

    const imageUrlErrors: ErrorMessage[] = this.validateContentsImageUrl(
      contents.imageUrl,
      contents.isNeedImage
    );

    const redirectUrlErrors: ErrorMessage[] = this.validateContentsRedirectUrl(
      contents.redirectUrl
    );

    return imageUrlErrors
      .concat(imageErrors)
      .concat(titleErrors)
      .concat(bodyErrors)
      .concat(redirectUrlErrors);
  }

  validateDeliveryDate(deliveryDate: Date, currentDate: Date): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    const diff = deliveryDate.getTime() - currentDate.getTime();

    if (
      diff <
      PUSH_NOTIFICATION_FORM_VALIDATE_RULE.DELIVERY_SCHEDULE
        .MINIMUM_SETTING_TIME_MS
    ) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.SCHEDULE.DELIVERY_TIME_OUT_OF_RANGE
        )
      );
    }

    return errors;
  }

  validateWeekly(
    weekdays: number[],
    nextDeliveryAt: number | null
  ): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (weekdays.length === 0) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.SCHEDULE.WEEKDAYS.MUST_BE_SELECTED
        )
      );
    }
    if (nextDeliveryAt === null) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.SCHEDULE.NO_EXIST_DELIVERY_SCHEDULE
        )
      );
    }

    return errors;
  }

  validateMonthly(
    days: number[],
    nextDeliveryAt: number | null
  ): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (days.length === 0) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.SCHEDULE.WEEKDAYS.MUST_BE_SELECTED
        )
      );
    }
    if (nextDeliveryAt === null) {
      errors.push(
        new ErrorMessage(
          PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.SCHEDULE.NO_EXIST_DELIVERY_SCHEDULE
        )
      );
    }

    return errors;
  }

  validateDeliverySchedule(
    parameters: PushNotificationDeliveryScheduleParameters,
    nextDeliveryAt: number | null
  ): ErrorMessage[] {
    let errors: ErrorMessage[] = [];

    if (parameters.scheduleType === "ONCE") {
      return errors.concat(
        this.validateDeliveryDate(parameters.deliveryDate, new Date())
      );
    } else if (parameters.scheduleType === "REPEAT") {
      if (parameters.frequencyType === "WEEKLY") {
        return errors.concat(
          this.validateWeekly(parameters.weekdays, nextDeliveryAt)
        );
      } else if (parameters.frequencyType === "MONTHLY") {
        // NOTE: TOBE IMPLEMENTED
        return errors.concat(
          this.validateMonthly(parameters.days, nextDeliveryAt)
        );
      }
    }
    return errors;
  }

  // FIXME: このバリデーションは、API側でやるほうが簡単である。がAPIの実装工数がないため、ここでやっている
  validateSameCampaign(
    parameters: PushNotificationEditFormParameters,
    scheduledCampaigns: ScheduledCampaign[]
  ): ErrorMessage[] {
    const isSameArray = (a: number[], b: number[]) => {
      if (a.length !== b.length) {
        return false;
      }

      const sortedA = a.sort();
      const sortedB = b.sort();

      return sortedA.every((value, index) => value === sortedB[index]);
    };
    let errors: ErrorMessage[] = [];
    const apiRequestBuilder = new ApiRequestBuilder();

    const targetDeliverySchedule: DeliveryScheduleRule =
      apiRequestBuilder.parseDeliverySchedule(parameters.deliverySchedule);

    scheduledCampaigns.forEach((campaign) => {
      if (campaign.delivery_schedule_rule.schedule_type === "REPEAT") {
        // NOTE: REPEATの場合、同じキャンペーンの存在有無は考慮しない
        // FIXME: 同じnextAtを持っている場合はエラーにする、でもいいんじゃないか？
        return;
      } else if (campaign.delivery_schedule_rule.schedule_type === "ONCE") {
        const isSame = [
          isSameArray(
            targetDeliverySchedule.frequency.years,
            campaign.delivery_schedule_rule.frequency.years
          ),
          isSameArray(
            targetDeliverySchedule.frequency.months,
            campaign.delivery_schedule_rule.frequency.months
          ),
          isSameArray(
            targetDeliverySchedule.frequency.days,
            campaign.delivery_schedule_rule.frequency.days
          ),
          isSameArray(
            targetDeliverySchedule.frequency.hours,
            campaign.delivery_schedule_rule.frequency.hours
          ),
          isSameArray(
            targetDeliverySchedule.frequency.minutes,
            campaign.delivery_schedule_rule.frequency.minutes
          ),
        ];

        if (
          parameters.destinationCollectRuleUuid ===
            campaign.destination_collect_rule.destination_collect_rule_uuid &&
          isSame.every((value) => value)
        ) {
          errors.push(
            new ErrorMessage(
              PUSH_NOTIFICATION_EDIT_ERROR_MESSAGE_LIST.CAMPAIGN.DUPLICATE
            )
          );
          return;
        }
      }
    });

    return errors;
  }

  validateToBeDelivered(
    parameters: PushNotificationEditFormParameters,
    next_delivery_at: number | null,
    scheduledCampaigns: ScheduledCampaign[]
  ): ErrorMessage[] {
    const nameErrors = this.validateName(parameters.name);
    const contentsErrors = this.validateContents(parameters.contents);

    const deliveryDateErrors = this.validateDeliverySchedule(
      parameters.deliverySchedule,
      next_delivery_at
    );

    const sameCampaignErrors = this.validateSameCampaign(
      parameters,
      scheduledCampaigns
    );

    return nameErrors
      .concat(contentsErrors)
      .concat(deliveryDateErrors)
      .concat(sameCampaignErrors);
  }

  validateToBeDraft(
    parameters: PushNotificationEditFormParameters
  ): ErrorMessage[] {
    const nameErrors = this.validateName(parameters.name);

    return nameErrors;
  }

  validateToBeTest(
    parameters: PushNotificationEditFormParameters
  ): ErrorMessage[] {
    const nameErrors = this.validateName(parameters.name);
    const contentsErrors = this.validateContents(parameters.contents);

    return nameErrors.concat(contentsErrors);
  }
}
