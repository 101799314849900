import { ReportMetaDataList } from "interfaces/models";
import { useCallback, useEffect, useState } from "react";
import ReportMetaDataRepository from "utils/ReportMetaDataRepository";

const useReportMetaDataList = (
  reportMetaDataRepository: ReportMetaDataRepository
): ReportMetaDataList | undefined => {
  const [reportMetaDataList, setReportMetaDataList] =
    useState<ReportMetaDataList>();

  const loadReportMetaDataList = useCallback<() => void>(async () => {
    try {
      setReportMetaDataList(await reportMetaDataRepository.load());
    } catch (err) {
      console.error(err);
    }
  }, [reportMetaDataRepository]);

  useEffect(() => {
    loadReportMetaDataList();
  }, [loadReportMetaDataList]);

  return reportMetaDataList;
};

export default useReportMetaDataList;
