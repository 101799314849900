import styles from "./NotificationModal.module.scss";
import fontStyles from "fontStyles.module.scss";

import React from "react";

const NotificationModal: React.VFC<{
  title: string;
  buttons: JSX.Element[];
  description?: string | JSX.Element;
}> = ({ title, buttons, description }) => {
  return (
    <div className={styles.notificationModal}>
      <div className={styles.title}>
        <div className={fontStyles.section}>{title}</div>
      </div>
      {description ? (
        <div className={styles.description}>{description}</div>
      ) : undefined}
      <div className={styles.buttons}>
        {buttons.map((elem, i) => (
          <div key={i} className={styles.button}>
            {elem}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationModal;
