import React from "react";
import OrangeButton from "atoms/ezPushShared/OrangeButton";
import TransparentButton from "atoms/ezPushShared/TransparentButton";
import ModalBackground from "atoms/ezPushShared/accountPageShared/ModalBackground";
import NotificationModal from "atoms/ezPushShared/accountPageShared/NotificationModal";
import ElementModal from "atoms/ezPushShared/accountPageShared/ElementModal";
import GrayBackDescriptionList from "molecules/v2/GrayBackDescriptionList";
import SegmentationRuleDetailTable from "./SegmentationRuleDetailTable";
import { DestinationCollectRule } from "types/destination_collect_rule";
import { MeasurementUrlSettingWithType } from "types/measurement_urls";
import LoadingIcon from "atoms/LoadingIcon";

export const AskDeleteSegmentationRuleModal: React.VFC<{
  clearModalElement: () => void;
  submit: () => void;
}> = ({ clearModalElement, submit }) => (
  <ModalBackground>
    <NotificationModal
      title="このセグメント設定を削除します。よろしいですか？"
      description="削除すると元には戻せません。"
      buttons={[
        <TransparentButton text="いいえ" onClick={clearModalElement} />,
        <OrangeButton onClick={submit} text="はい" />,
      ]}
    />
  </ModalBackground>
);

export const DeleteSegmentationRuleModal = (): JSX.Element => {
  return (
    <ModalBackground>
      <NotificationModal
        title="このセグメント設定を削除します。よろしいですか？"
        description="削除すると元には戻せません。"
        buttons={[<LoadingIcon />]}
      />
    </ModalBackground>
  );
};

export const CopySegmentationRuleModal: React.VFC<{
  clearModalElement: () => void;
  submit: () => void;
}> = ({ clearModalElement, submit }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title="作成画面に移動します。よろしいですか？"
        description="この設定内容を元に新たなセグメント条件を作成します。"
        buttons={[
          <TransparentButton text="いいえ" onClick={clearModalElement} />,
          <OrangeButton onClick={submit} text="はい" />,
        ]}
      />
    </ModalBackground>
  );
};

export enum SEGMENTATION_RULE_CONTROL_TYPE {
  EDIT = "EDIT",
  DELETE = "DELETE",
}

const RejectDescription = {
  [SEGMENTATION_RULE_CONTROL_TYPE.EDIT]: {
    title:
      "このセグメントは配信予定のプッシュ通知に設定されているため編集できません。",
    innerWindow: {
      title: "対象のプッシュ通知設定",
      description: "配信予約中",
    },
  },
  [SEGMENTATION_RULE_CONTROL_TYPE.DELETE]: {
    title:
      "このセグメントは配信予定のプッシュ通知に設定されているため削除できません。",
    innerWindow: {
      title: "対象のプッシュ通知設定",
      description: "配信予約中",
    },
  },
} as const;

export const RejectSegmentationRuleControlModal: React.VFC<{
  controlType: SEGMENTATION_RULE_CONTROL_TYPE;
  campaignNames: string[];
  clearModalElement: () => void;
}> = ({ controlType, campaignNames, clearModalElement }) => {
  return (
    <ModalBackground>
      <NotificationModal
        title={RejectDescription[controlType].title}
        description={
          <GrayBackDescriptionList
            title={RejectDescription[controlType].innerWindow.title}
            description={RejectDescription[controlType].innerWindow.description}
            items={campaignNames}
          />
        }
        buttons={[
          <TransparentButton text="閉じる" onClick={clearModalElement} />,
        ]}
      />
    </ModalBackground>
  );
};

const AskDescription = {
  [SEGMENTATION_RULE_CONTROL_TYPE.EDIT]: {
    introduction:
      "設定内容を変更すると以下のプッシュ通知全てに適用されます。よろしいですか？",
    innerWindow: {
      title: "対象のプッシュ通知設定",
      description: "下書き保存中",
    },
  },
  [SEGMENTATION_RULE_CONTROL_TYPE.DELETE]: {
    introduction:
      "削除すると以下のプッシュ通知のセグメント設定が自動的に「全ての許諾ユーザー」に変更されます。よろしいですか？",
    innerWindow: {
      title: "対象のプッシュ通知設定",
      description: "下書き保存中",
    },
  },
};

export const AskControlSegmentationRuleModal: React.VFC<{
  controlType: SEGMENTATION_RULE_CONTROL_TYPE;
  campaignNames: string[];
  submit: () => void;
  clear: () => void;
}> = ({ controlType, campaignNames, submit, clear }) => {
  return (
    <ModalBackground>
      <ElementModal
        title="このセグメントが設定されているプッシュ通知があります。"
        introduction={AskDescription[controlType].introduction}
        element={
          <GrayBackDescriptionList
            title={AskDescription[controlType].innerWindow.title}
            description={AskDescription[controlType].innerWindow.description}
            items={campaignNames}
          />
        }
        buttons={[
          <TransparentButton text="いいえ" onClick={clear} />,
          <OrangeButton text={"はい"} onClick={submit} />,
        ]}
      />
    </ModalBackground>
  );
};

export const ControlSegmentationRuleModal: React.VFC<{
  controlType: SEGMENTATION_RULE_CONTROL_TYPE;
  campaignNames: string[];
}> = ({ controlType, campaignNames }) => {
  return (
    <ModalBackground>
      <ElementModal
        title="このセグメントが設定されているプッシュ通知があります。"
        introduction={AskDescription[controlType].introduction}
        element={
          <GrayBackDescriptionList
            title={AskDescription[controlType].innerWindow.title}
            description={AskDescription[controlType].innerWindow.description}
            items={campaignNames}
          />
        }
        buttons={[<LoadingIcon />]}
      />
    </ModalBackground>
  );
};

export const SegmentationRuleDetailModal: React.VFC<{
  destinationCollectRule: DestinationCollectRule;
  urls: MeasurementUrlSettingWithType[] | null;
  clear: () => void;
}> = ({ destinationCollectRule, urls, clear }) => (
  <ModalBackground>
    <ElementModal
      title="セグメント詳細"
      element={
        <SegmentationRuleDetailTable
          destinationCollectRule={destinationCollectRule}
          urls={urls}
        />
      }
      buttons={[<TransparentButton text="閉じる" onClick={clear} />]}
    />
  </ModalBackground>
);
