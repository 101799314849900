import {
  ReportPeriodType,
  RESPONSE_REPORT_PERIOD_TYPE,
} from "interfaces/models";
import { useState } from "react";
import styles from "./NestedLists.module.scss";
import SelectBoxArrowIcon from "images/icons/icon_select_box_arrow.svg";
import SelectBoxActiveArrowIcon from "images/icons/icon_select_box_active_arrow.svg";
import SelectBoxHoverArrowIcon from "images/icons/icon_arrow_left_small_g.svg";

const NestedLists: React.VFC<{
  reportMetaDataList: any;
  changeReportPeriodType: (reportPeriodType: ReportPeriodType) => void;
  changeReportYearMonth: (reportYearMonth: string) => void;
}> = ({
  reportMetaDataList,
  changeReportPeriodType,
  changeReportYearMonth,
}) => {
  const [isShownSecondLayer, setIsShownSecondLayer] = useState<boolean>(false);

  const onClickSetType = (period: string): void => {
    changeReportPeriodType(period);
    const layerTopElement: HTMLElement | null =
      document.getElementById("layerTop");
    if (layerTopElement) {
      layerTopElement.innerHTML = period;
    }
    if (layerTopElement) {
      layerTopElement.innerHTML = makeDropDownText(period);
    }
    setIsShownSecondLayer(!isShownSecondLayer);
  };

  const onClickSetMonthlyType = (period: string): void => {
    changeReportPeriodType(RESPONSE_REPORT_PERIOD_TYPE.MONTHLY);
    changeReportYearMonth(period);
    const layerTopElement: HTMLElement | null =
      document.getElementById("layerTop");
    const timeStamp: Date = new Date(period + "-01");
    if (layerTopElement) {
      layerTopElement.innerHTML =
        timeStamp.getFullYear() + "年" + (timeStamp.getMonth() + 1) + "月分";
    }
    setIsShownSecondLayer(!isShownSecondLayer);
  };

  const LOCALE = "ja-JP";
  const TIMEZONE = { timeZone: "Asia/Tokyo" };
  const formatDate = (date: Date) => date.toLocaleDateString(LOCALE, TIMEZONE);

  const makeDropDownText = (periodType: string) => {
    const fromTimeStamp: string = formatDate(
      new Date(reportMetaDataList[periodType][1].from_timestamp_ms)
    );
    const tmpToTimeStamp = new Date(
      reportMetaDataList[periodType][1].to_timestamp_ms
    );
    tmpToTimeStamp.setDate(tmpToTimeStamp.getDate() - 1);
    const toTimeStamp: string = formatDate(tmpToTimeStamp);

    if (periodType === RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH) {
      return "当月(" + fromTimeStamp + " - " + toTimeStamp + ")";
    } else if (periodType === RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH) {
      return "先月(" + fromTimeStamp + " - " + toTimeStamp + ")";
    } else {
      return "過去一週間(" + fromTimeStamp + " - " + toTimeStamp + ")";
    }
  };

  const makeDropDownTextMonthly = () => {
    const months: Date[] = Object.keys(
      reportMetaDataList[RESPONSE_REPORT_PERIOD_TYPE.MONTHLY]
    ).map((month) => {
      return new Date(month + "-01");
    });

    //monthlyの月リストを降順にソート&直近2年以内のデータにフィルタ
    const MONTHLY_LIST_LIMIT_COUNT: number = 24;
    const monthsSortedFiltered: (Date | undefined)[] = months
      .sort((a, b) => (a < b ? 1 : -1))
      .map((month) => {
        const currentDate: Date = new Date();
        const monthDiff: number = currentDate.getMonth() - month.getMonth();
        return monthDiff <= MONTHLY_LIST_LIMIT_COUNT ? month : undefined;
      });

    const dropDownText: {
      value: string;
      text: string;
    }[] = monthsSortedFiltered.map((month) => {
      return {
        value:
          month !== undefined
            ? month.getFullYear() +
              "-" +
              (month.getMonth() + 1).toString().padStart(2, "0")
            : "no_data",
        text:
          month !== undefined
            ? month.getFullYear() + "年" + (month.getMonth() + 1) + "月分"
            : "no_data",
      };
    });
    return dropDownText;
  };

  const makeDropDownTextNoData = (periodType: string) => {
    if (periodType === RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH) {
      return "当月(データなし)";
    } else if (periodType === RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH) {
      return "先月(データなし)";
    } else if (periodType === RESPONSE_REPORT_PERIOD_TYPE.ONE_WEEK) {
      return "過去一週間(データなし)";
    } else {
      return "データなし";
    }
  };

  const createPeriodElement: (
    periodType: string,
    className: string
  ) => JSX.Element | undefined = (periodType: string, className: string) => {
    const periodElement: JSX.Element | undefined = reportMetaDataList[
      periodType
    ] ? (
      <div className={className} onClick={() => onClickSetType(periodType)}>
        {makeDropDownText(periodType)}
      </div>
    ) : (
      <div className={className}>{makeDropDownTextNoData(periodType)}</div>
    );
    return periodElement;
  };

  return (
    <div className={styles.container}>
      <ul className={styles.gnav}>
        <li>
          <div
            id="layerTop"
            className={styles.layerTop}
            onClick={() => setIsShownSecondLayer(!isShownSecondLayer)}
            onMouseEnter={() => setIsShownSecondLayer(false)}
          >
            {reportMetaDataList[RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH]
              ? makeDropDownText(RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH)
              : makeDropDownTextNoData(
                  reportMetaDataList[RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH]
                )}
          </div>
          <div className={styles.buttonRight}>
            <img
              src={
                isShownSecondLayer
                  ? SelectBoxActiveArrowIcon
                  : SelectBoxArrowIcon
              }
              alt=""
            />
          </div>
          {isShownSecondLayer && (
            <ul>
              <li
                value={RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH}
                key={RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH}
              >
                {createPeriodElement(
                  RESPONSE_REPORT_PERIOD_TYPE.CURRENT_MONTH,
                  styles.layerSecondTop
                )}
              </li>
              <li
                value={RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH}
                key={RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH}
              >
                {createPeriodElement(
                  RESPONSE_REPORT_PERIOD_TYPE.LAST_MONTH,
                  styles.layerSecond
                )}
              </li>
              <li key={RESPONSE_REPORT_PERIOD_TYPE.ONE_WEEK}>
                {createPeriodElement(
                  RESPONSE_REPORT_PERIOD_TYPE.ONE_WEEK,
                  styles.layerSecond
                )}
              </li>
              <li>
                <div
                  className={styles.layerSecondLast}
                  onClick={() => setIsShownSecondLayer(!isShownSecondLayer)}
                >
                  {Object.keys(
                    reportMetaDataList[RESPONSE_REPORT_PERIOD_TYPE.MONTHLY]
                  ).length > 0
                    ? "過去月次"
                    : "過去月次(データなし)"}
                </div>
                <div className={styles.hoverIcon}>
                  <img src={SelectBoxHoverArrowIcon} alt="" />
                </div>
                <ul>
                  {makeDropDownTextMonthly().map((element, index) => {
                    return (
                      <li key={index} className={styles.layerChildren}>
                        <div
                          onClick={() => onClickSetMonthlyType(element.value)}
                        >
                          {element.text}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};
export default NestedLists;
