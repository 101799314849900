import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";

const useSession = (
  session: CognitoUserSession | null,
  onExpired: () => void
): [jwtToken: string | null, onSessionExpired: () => void] => {
  const [jwtToken, setJwtToken] = useState<string | null>(
    session ? session.getIdToken().getJwtToken() : null
  );
  const onSessionExpired = () => {
    console.error("session expired callback is not implemented");
    onExpired();
  };

  useEffect(() => {
    setJwtToken(session ? session.getIdToken().getJwtToken() : null);
  }, [session]);

  return [jwtToken, onSessionExpired];
};

export default useSession;
