import React, { ReactNode } from "react";
import styles from "./ModalBackground.module.scss";

const ModalBackground: React.VFC<{
  children: ReactNode;
  onClick?: () => void;
}> = ({ children, onClick }) => {
  return (
    <div className={styles.modalBackground} onClick={onClick}>
      <div className={styles.modal}>{children}</div>
    </div>
  );
};

export default ModalBackground;
