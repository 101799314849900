import styles from "./ItemName.module.scss";
import RequireIcon from "atoms/accountPagesShared/RequireIcon";
import ToolTip from "atoms/accountPagesShared/ToolTip";

type ContentsEditFormItemNameProps = {
  text: string;
  isRequired?: boolean;
  toolTipId?: string;
  toolTipText?: string;
};

const ContentsEditFormItemName = ({
  text,
  isRequired = false,
  toolTipId,
  toolTipText,
}: ContentsEditFormItemNameProps) => {
  return (
    <div className={styles.itemName}>
      <div className={styles.name}>{text}</div>
      {isRequired ? (
        <div className={styles.requireIcon}>
          <RequireIcon />
        </div>
      ) : (
        ""
      )}
      {toolTipId && toolTipText ? (
        <div className={styles.toolTip}>
          <ToolTip id={toolTipId} text={toolTipText} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentsEditFormItemName;
