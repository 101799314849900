import styles from "./ActionOperation.module.scss";

type ActionOperationProps = {
  elements: JSX.Element[];
};

const ActionOperation = ({ elements }: ActionOperationProps) => (
  <div className={styles.operation}>
    {elements.map((e, i) => (
      <div key={i} className={styles.item}>
        {e}
      </div>
    ))}
  </div>
);

export default ActionOperation;
