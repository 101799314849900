import DeleteButton from "atoms/accountPagesShared/DeleteButton";
import EventText from "atoms/accountPagesShared/EventText";
import NoticeText from "atoms/accountPagesShared/NoticeText";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import { LocationPattern, PathParameterPattern } from "interfaces/models";
import UrlLocationForm from "molecules/UrlForm/UrlLocationForm";
import UrlParameterForm from "molecules/UrlForm/UrlParameterForm";

import styles from "./URLForm.module.scss";

type ParametersViewProps = {
  items: JSX.Element[];
};

const ParametersView = ({ items }: ParametersViewProps) => {
  return (
    <>
      {items.map((e, i) => (
        <div key={i}>
          {i > 0 ? (
            <div className={styles.separator}>
              <NoticeText text="and" />
            </div>
          ) : null}
          <div className={styles.param}>{e}</div>
        </div>
      ))}
    </>
  );
};

type URLFormProps = {
  location: LocationPattern;
  parameters: PathParameterPattern[];
  numberOfLoginUrlParameter: number;
  setLocation: (value: LocationPattern) => void;
  setParameters: (index: number, value: PathParameterPattern) => void;
  onClickParameterAddButton: () => void;
  onClickParameterDeleteButton: () => void;
};

const URLForm = ({
  location,
  parameters,
  numberOfLoginUrlParameter,
  setLocation,
  setParameters,
  onClickParameterAddButton,
  onClickParameterDeleteButton,
}: URLFormProps) => {
  const forms = Array.from(Array(numberOfLoginUrlParameter).keys()).map(
    (e, i) => (
      <UrlParameterForm
        key={i}
        parameter={parameters[i]}
        onChange={(value) => setParameters(i, value)}
      />
    )
  );

  return (
    <div className={styles.URLForm}>
      <div className={styles.location}>
        <UrlLocationForm location={location} onChange={setLocation} />
      </div>
      <div>
        <NoticeText text="2083文字以内" />
      </div>
      <div className={styles.parameters}>
        <div className={styles.header}>
          <div className={styles.title}>パラメータ</div>
          <div className={styles.toolTip}>
            <ToolTip
              id={"url_parameters"}
              text="パラメータでページを分けている場合、入力してください。（?を除く）"
            />
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.form}>
            <div className={styles.parameter}>
              <ParametersView items={forms} />
            </div>
            {numberOfLoginUrlParameter > 1 ? (
              <div className={styles.deleteButton}>
                <DeleteButton onClick={() => onClickParameterDeleteButton()} />
              </div>
            ) : null}
          </div>
          <div>
            <EventText
              text="追加"
              onClick={() => onClickParameterAddButton()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default URLForm;
