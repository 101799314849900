import { SegmentationRule } from "interfaces/models";
import { SegmentationRuleCreationRequest } from "interfaces/requests";
import RestApi from "./RestApi";

export class SegmentationRuleRepository {
  managementApi: RestApi;

  constructor(managementApi: RestApi) {
    this.managementApi = managementApi;
  }

  async get(segmentationRuleId: string) {
    const segmentationRules = (await this.load()).filter(
      (segmentationRule) =>
        segmentationRule.segmentation_rule_id === segmentationRuleId
    );

    return segmentationRules.length ? segmentationRules[0] : null;
  }

  async load(): Promise<SegmentationRule[]> {
    const data: { segmentation_rules: SegmentationRule[] } =
      await this.managementApi.get("segmentation_rules");

    return data.segmentation_rules;
  }

  async create(
    request: SegmentationRuleCreationRequest
  ): Promise<SegmentationRule> {
    const response: SegmentationRule = await this.managementApi.post(
      "segmentation_rules",
      request
    );
    return response;
  }

  async delete(segmentationRuleId: string) {
    await this.managementApi.delete(`segmentation_rules/${segmentationRuleId}`);
  }

  async update(
    segmentationRuleId: string,
    request: SegmentationRuleCreationRequest
  ) {
    const response: SegmentationRule = await this.managementApi.put(
      `segmentation_rules/${segmentationRuleId}`,
      request
    );

    return response;
  }
}
