import BorderedSelectBox from "atoms/accountPagesShared/BorderedSelectBox";
import TextInput from "atoms/TextInput";
import {
  PathParameterPattern,
  PATH_PARAMETER_COMPARE_PATTERN,
} from "interfaces/models";
import { useState } from "react";
import styles from "./UrlParameterForm.module.scss";
import { OptionType } from "atoms/accountPagesShared/SelectWrap";

type UrlParameterFormProps = {
  parameter: PathParameterPattern;
  onChange: (params: PathParameterPattern) => void;
};

const UrlParameterForm = ({ parameter, onChange }: UrlParameterFormProps) => {
  const [param, setParam] = useState<string>("");
  const options: OptionType[] = [
    { value: PATH_PARAMETER_COMPARE_PATTERN.INCLUDE, label: "を含む" },
    { value: PATH_PARAMETER_COMPARE_PATTERN.EXCLUDE, label: "を除く" },
  ];

  return (
    <div className={styles.urlParameterForm}>
      <div className={styles.elem}>
        <TextInput
          value={param}
          onChange={(e) => {
            if (e) {
              setParam(e.target.value);

              const pathParam = e.target.value.split("=");

              onChange({
                key: pathParam.length >= 1 ? pathParam[0] : "",
                value: pathParam.length === 2 ? pathParam[1] : "",
                pattern: parameter.pattern,
              });
            }
          }}
          placeholder="product_no="
        />
      </div>
      <div className={styles.compareType}>
        <BorderedSelectBox
          options={options}
          value={parameter.pattern}
          onChange={(e) => {
            onChange({
              key: parameter.key,
              value: parameter.value,
              pattern: e,
            });
          }}
        />
      </div>
    </div>
  );
};

export default UrlParameterForm;
