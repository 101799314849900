import {
  CONTENTS_BASE_BORDER_PATTERN,
  CONTENTS_BASE_RADIUS_PATTERN,
  SUB_HEADLINE_RADIUS_PATTERN,
} from "app/system_defaults/action_creation_defaults";
import FormTitle from "atoms/accountPagesShared/FormTitle";
import TextArea from "atoms/TextArea";
import TextInput from "atoms/TextInput";
import {
  CreativeParts,
  ActionCreationContentsStyleParameters,
  CREATIVE_PARTS,
} from "interfaces/model/actionContentsParameters";
import { closeButtonSizeOptions } from "interfaces/models";
import { ActionCreationContentsStyleCallbacks } from "interfaces/view/actionCreation";
import CheckBox from "molecules/CheckBox/CheckBox";
import CheckBoxRangeSliderForm from "molecules/formParts/CheckBoxRangeSliderForm";
import ColorPickForm from "molecules/formParts/ColorPickForm";
import GradationColorPickForm from "molecules/formParts/GradationColorPickForm";
import ImageUploader from "molecules/formParts/ImageUploader";
import MarginForm from "molecules/formParts/MarginForm";
import PullDownForm from "molecules/formParts/PullDownForm";
import RangeSliderForm from "molecules/formParts/RangeSliderForm";
import ToggleForm from "molecules/formParts/ToggleForm";
import { COLOR_TYPE } from "molecules/GradationColorPicker/ColorTypeSelector";
import MonitoringSlider from "molecules/MonitoringSlider/MonitoringSlider";
import FormElementView from "organisms/account/action_creation/contents_edit/FormElementView";
import FormItemView from "organisms/account/action_creation/contents_edit/FormItemView";

const selectFormParts = (
  parts: CreativeParts,
  image: File | undefined,
  styles: ActionCreationContentsStyleParameters,
  stylesCallbacks: ActionCreationContentsStyleCallbacks,
  onChangeContentsImage: (file: File | undefined) => void
): JSX.Element[] | undefined => {
  switch (parts) {
    case CREATIVE_PARTS.BASE:
      return [
        <FormItemView
          key="body"
          titleElement={<FormTitle name="コンテンツベース" />}
          mainElements={[
            [
              <RangeSliderForm
                key="width"
                name={<div>横幅</div>}
                value={styles.contentsBaseWidth}
                min={50}
                max={325}
                step={25}
                onChange={(e) =>
                  stylesCallbacks.setContentsBaseWidth(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <CheckBoxRangeSliderForm
                key="height"
                name={<div>縦幅</div>}
                checkBoxText="自動調整"
                isChecked={styles.contentsBaseHeightAdjust}
                value={styles.contentsBaseHeight}
                min={0}
                max={600}
                step={25}
                onChangeSlider={(e) => stylesCallbacks.setContentsBaseHeight(e)}
                onChangeCheckBox={(e) => {
                  stylesCallbacks.setContentsBaseHeightAdjust(e);
                  stylesCallbacks.setContentsBaseHeight(0);
                }}
                valueTextEditCallback={(e) =>
                  Number(e) === 0 ? "自動" : `${e}px`
                }
              />,
            ],
            [
              <PullDownForm
                key="radius"
                name={<div>枠線</div>}
                value={styles.contentsBaseBorderWidth}
                options={CONTENTS_BASE_BORDER_PATTERN}
                onChange={(e) => stylesCallbacks.setContentsBaseBorderWidth(e)}
              />,
              <PullDownForm
                key="radius"
                name={<div>角丸</div>}
                value={styles.contentsBaseRadius}
                options={CONTENTS_BASE_RADIUS_PATTERN}
                onChange={(e) =>
                  stylesCallbacks.setContentsBaseRadius(Number(e))
                }
              />,
              <ColorPickForm
                key="border"
                name="枠線色"
                value={styles.borderColor}
                onChange={(e) => stylesCallbacks.setBorderColor(e)}
              />,
            ],
            [
              <MarginForm
                key="margin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 40, step: 5 }}
                rightLeftOptions={{ min: 0, max: 40, step: 5 }}
                topBottomMarginValue={styles.contentsBaseTopPadding}
                rightLeftMarginValue={styles.contentsBaseRightPadding}
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.setContentsBaseTopPadding(Number(e));
                  stylesCallbacks.setContentsBaseBottomPadding(Number(e));
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.setContentsBaseRightPadding(Number(e));
                  stylesCallbacks.setContentsBaseLeftPadding(Number(e));
                }}
              />,
              <ColorPickForm
                key="background"
                name="背景色"
                value={styles.backgroundColor}
                onChange={(e) => stylesCallbacks.setBackgroundColor(e)}
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.IMAGE:
      return [
        <FormItemView
          key="image"
          titleElement={<FormTitle name="画像を挿入する" />}
          mainElements={[
            [
              <ImageUploader
                image={image}
                onChange={(file: File | undefined) =>
                  onChangeContentsImage(file)
                }
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.MAGNIFICATION_IMAGE:
      return [
        <FormItemView
          key="magnification_image"
          titleElement={<FormTitle name="画像を挿入する" />}
          mainElements={[
            [
              <FormElementView
                nameElement={<div>幅</div>}
                formElement={
                  <MonitoringSlider
                    value={styles.imageMagnification}
                    minValue={0.5}
                    maxValue={2.0}
                    stepValue={0.05}
                    onChange={(e) => stylesCallbacks.setImageMagnification(e)}
                    formatCallback={(e) => `x${e.toFixed(2)}`}
                  />
                }
              />,
            ],
            [
              <ImageUploader
                image={image}
                onChange={(file: File | undefined) =>
                  onChangeContentsImage(file)
                }
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.LINK_IMAGE:
      return [
        <FormItemView
          key="subHeadline"
          titleElement={<FormTitle name="画像を挿入する" />}
          mainElements={[
            [
              <ImageUploader
                image={image}
                onChange={(file: File | undefined) =>
                  onChangeContentsImage(file)
                }
              />,
            ],
          ]}
        />,
        <FormItemView
          key="imageLink"
          titleElement={<FormTitle name="リンク先URL" />}
          titleSideElements={[
            <CheckBox
              text="別のウインドウで開く"
              value={styles.isOpenOtherWindow}
              onChange={() =>
                stylesCallbacks.setIsOpenOtherWindow(!styles.isOpenOtherWindow)
              }
            />,
          ]}
          mainElements={[
            [
              <TextInput
                value={styles.imageLinkText}
                onChange={(e) =>
                  stylesCallbacks.setImageLinkText(e.target.value)
                }
                placeholder="https://example.com"
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.SUB_HEADLINE:
      return [
        <FormItemView
          key="subHeadline"
          titleElement={<FormTitle name="サブ見出し" />}
          mainElements={[
            [
              <RangeSliderForm
                key="subHeadlineTextSize"
                name={<div>文字サイズ</div>}
                value={styles.subHeadlineTextSize}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.setSubHeadlineTextSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <ColorPickForm
                key="subHeadlineBackground"
                name="背景色"
                value={styles.subHeadlineBackgroundColor}
                onChange={(e) =>
                  stylesCallbacks.setSubHeadlineBackgroundColor(e)
                }
              />,
              <ColorPickForm
                key="subHeadlineText"
                name="テキスト色"
                value={styles.subHeadlineTextColor}
                onChange={(e) => stylesCallbacks.setSubHeadlineTextColor(e)}
              />,
            ],
            [
              <PullDownForm
                key="subHeadlineBackgroundRadius"
                name={<div>角丸</div>}
                value={styles.subHeadlineBackgroundRadius}
                options={SUB_HEADLINE_RADIUS_PATTERN}
                onChange={(e) =>
                  stylesCallbacks.setSubHeadlineBackgroundRadius(Number(e))
                }
              />,
              <MarginForm
                key="subHeadlineBackgroundMargin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 15, step: 1 }}
                rightLeftOptions={{ min: 0, max: 100, step: 10 }}
                topBottomMarginValue={styles.subHeadlineBackgroundTopPadding}
                rightLeftMarginValue={styles.subHeadlineBackgroundRightPadding}
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.setSubHeadlineBackgroundTopPadding(Number(e));
                  stylesCallbacks.setSubHeadlineBackgroundBottomPadding(
                    Number(e)
                  );
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.setSubHeadlineBackgroundRightPadding(
                    Number(e)
                  );
                  stylesCallbacks.setSubHeadlineBackgroundLeftPadding(
                    Number(e)
                  );
                }}
              />,
            ],
            [
              <TextInput
                value={styles.subHeadlineText}
                onChange={(e) =>
                  stylesCallbacks.setSubHeadlineText(e.target.value)
                }
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.HEADLINE:
      return [
        <FormItemView
          key="headline"
          titleElement={<FormTitle name="見出し" />}
          mainElements={[
            [
              <RangeSliderForm
                key="headlineTextSize"
                name={<div>文字サイズ</div>}
                value={styles.headlineTextSize}
                min={10}
                max={60}
                step={2}
                onChange={(e) => stylesCallbacks.setHeadlineTextSize(Number(e))}
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <ColorPickForm
                key="headline"
                name="文字色"
                value={styles.headlineTextColor}
                onChange={(e) => stylesCallbacks.setHeadlineTextColor(e)}
              />,
            ],
            [
              <TextArea
                value={styles.headlineText}
                onChange={(e) =>
                  stylesCallbacks.setHeadlineText(e.target.value)
                }
              />,
            ],
          ]}
        />,
      ];

    case CREATIVE_PARTS.ARTICLE:
      return [
        <FormItemView
          key="text"
          titleElement={<FormTitle name="本文" />}
          mainElements={[
            [
              <RangeSliderForm
                key="textSize"
                name={<div>文字サイズ</div>}
                value={styles.textSize}
                min={10}
                max={60}
                step={2}
                onChange={(e) => stylesCallbacks.setTextSize(Number(e))}
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <ColorPickForm
                key="text"
                name="文字色"
                value={styles.textColor}
                onChange={(e) => stylesCallbacks.setTextColor(e)}
              />,
            ],
            [
              <TextArea
                value={styles.text}
                onChange={(e) => stylesCallbacks.setText(e.target.value)}
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.BUTTON:
      return [
        <FormItemView
          key="button"
          titleElement={<FormTitle name="ボタン" />}
          mainElements={[
            [
              <RangeSliderForm
                key="buttonTextSize"
                name={<div>文字サイズ</div>}
                value={styles.buttonTextSize}
                min={10}
                max={60}
                step={2}
                onChange={(e) => stylesCallbacks.setButtonTextSize(Number(e))}
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <GradationColorPickForm
                key="buttonColor"
                name="ボタン色"
                values={styles.buttonBackgroundGradationColor}
                colorType={styles.buttonColorType}
                onChange={(e) => {
                  if (styles.buttonColorType === COLOR_TYPE.SIMPLE) {
                    stylesCallbacks.setButtonBackgroundGradationColor([
                      e[0],
                      styles.buttonBackgroundGradationColor[1],
                    ]);
                  } else {
                    stylesCallbacks.setButtonBackgroundGradationColor(e);
                  }
                }}
                onChangeColorType={(e) => stylesCallbacks.setButtonColorType(e)}
              />,
              <ColorPickForm
                key="buttonText"
                name="テキスト色"
                value={styles.buttonTextColor}
                onChange={(e) => stylesCallbacks.setButtonTextColor(e)}
              />,
            ],
            [
              <RangeSliderForm
                key="buttonRadius"
                name={<div>角丸</div>}
                value={styles.buttonBorderRadius}
                min={0}
                max={50}
                step={5}
                onChange={(e) =>
                  stylesCallbacks.setButtonBorderRadius(Number(e))
                }
                valueTextEditCallback={() => `${styles.buttonBorderRadius}px`}
              />,
              <MarginForm
                key="margin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 15, step: 1 }}
                rightLeftOptions={{ min: 0, max: 100, step: 10 }}
                topBottomMarginValue={styles.buttonTopPadding}
                rightLeftMarginValue={styles.buttonRightPadding}
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.setButtonTopPadding(Number(e));
                  stylesCallbacks.setButtonBottomPadding(Number(e));
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.setButtonRightPadding(Number(e));
                  stylesCallbacks.setButtonLeftPadding(Number(e));
                }}
              />,
              <ToggleForm
                key="shadow"
                name={<div>影</div>}
                isChecked={styles.isButtonShadow}
                onChange={(e) => stylesCallbacks.setIsButtonShadow(e)}
              />,
            ],
            [
              <TextInput
                value={styles.buttonText}
                onChange={(e) => stylesCallbacks.setButtonText(e.target.value)}
              />,
            ],
          ]}
        />,
        <FormItemView
          key="buttonLink"
          titleElement={<FormTitle name="リンク先URL" />}
          titleSideElements={[
            <CheckBox
              text="別のウインドウで開く"
              value={styles.isOpenOtherWindow}
              onChange={() =>
                stylesCallbacks.setIsOpenOtherWindow(!styles.isOpenOtherWindow)
              }
            />,
          ]}
          mainElements={[
            [
              <TextInput
                value={styles.buttonLinkText}
                onChange={(e) =>
                  stylesCallbacks.setButtonLinkText(e.target.value)
                }
                placeholder="https://example.com"
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.COUPON_CODE:
      return [
        <FormItemView
          key="couponForm"
          titleElement={<FormTitle name="クーポンコード" />}
          mainElements={[
            [
              <RangeSliderForm
                key="couponCodeTextSize"
                name={<div>文字サイズ</div>}
                value={styles.couponCodeTextSize}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.setCouponCodeTextSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <ColorPickForm
                key="couponCodeTextColor"
                name="文字色"
                value={styles.couponCodeTextColor}
                onChange={(e) => stylesCallbacks.setCouponCodeTextColor(e)}
              />,
              <ColorPickForm
                key="couponCodeBackgroundColor"
                name="背景色"
                value={styles.couponCodeBackgroundColor}
                onChange={(e) =>
                  stylesCallbacks.setCouponCodeBackgroundColor(e)
                }
              />,
            ],
            [
              <TextInput
                value={styles.couponCodeText}
                onChange={(e) =>
                  stylesCallbacks.setCouponCodeText(e.target.value)
                }
              />,
            ],
          ]}
        />,
        <FormItemView
          key="copyButton"
          titleElement={<FormTitle name="クーポンコピーボタン" />}
          mainElements={[
            [
              <RangeSliderForm
                key="couponCopyButtonTextSize"
                name={<div>文字サイズ</div>}
                value={styles.couponButtonTextSize}
                min={10}
                max={60}
                step={2}
                onChange={(e) =>
                  stylesCallbacks.setCouponButtonTextSize(Number(e))
                }
                valueTextEditCallback={(e) => `${e}px`}
              />,
              <ColorPickForm
                key="couponCopyButtonTextColor"
                name="文字色"
                value={styles.couponButtonTextColor}
                onChange={(e) => stylesCallbacks.setCouponButtonTextColor(e)}
              />,
              <GradationColorPickForm
                key="couponButtonColor"
                name="背景色"
                values={styles.couponButtonColor}
                colorType={styles.couponButtonColorType}
                onChange={(e) => {
                  if (styles.couponButtonColorType === COLOR_TYPE.SIMPLE) {
                    stylesCallbacks.setCouponButtonColor([
                      e[0],
                      styles.buttonBackgroundGradationColor[1],
                    ]);
                  } else {
                    stylesCallbacks.setCouponButtonColor(e);
                  }
                }}
                onChangeColorType={(e) =>
                  stylesCallbacks.setCouponButtonColorType(e)
                }
              />,
            ],
            [
              <RangeSliderForm
                key="buttonRadius"
                name={<div>角丸</div>}
                value={styles.couponButtonRadius}
                min={0}
                max={50}
                step={5}
                onChange={(e) =>
                  stylesCallbacks.setCouponButtonRadius(Number(e))
                }
                valueTextEditCallback={() => `${styles.couponButtonRadius}px`}
              />,
              <MarginForm
                key="margin"
                name={<div>余白</div>}
                topBottomOptions={{ min: 0, max: 15, step: 1 }}
                rightLeftOptions={{ min: 0, max: 100, step: 10 }}
                topBottomMarginValue={styles.couponButtonTopPadding}
                rightLeftMarginValue={styles.couponButtonRightPadding}
                onChangeTopBottomMargin={(e) => {
                  stylesCallbacks.setCouponButtonTopPadding(Number(e));
                  stylesCallbacks.setCouponButtonBottomPadding(Number(e));
                }}
                onChangeRightLeftMargin={(e) => {
                  stylesCallbacks.setCouponButtonRightPadding(Number(e));
                  stylesCallbacks.setCouponButtonLeftPadding(Number(e));
                }}
              />,
              <ToggleForm
                key="shadow"
                name={<div>影</div>}
                isChecked={styles.couponButtonShadow}
                onChange={(e) => stylesCallbacks.setCouponButtonShadow(e)}
              />,
            ],
            [
              <TextInput
                value={styles.couponButtonText}
                onChange={(e) =>
                  stylesCallbacks.setCouponButtonText(e.target.value)
                }
              />,
            ],
          ]}
        />,
      ];
    case CREATIVE_PARTS.CLOSE_BUTTON:
      return [
        <FormItemView
          key="closeButton"
          titleElement={<FormTitle name="クローズボタン" />}
          mainElements={[
            [
              <PullDownForm
                key="closeButtonSize"
                name={<div>サイズ</div>}
                value={styles.closeButtonSize}
                options={closeButtonSizeOptions}
                onChange={(e) => stylesCallbacks.setCloseButtonSize(e)}
              />,
              <ColorPickForm
                key="closeButtonCrossColor"
                name="文字色"
                value={styles.closeButtonCrossColor}
                onChange={(e) => stylesCallbacks.setCloseButtonCrossColor(e)}
              />,
              <ColorPickForm
                key="closeButtonBackground"
                name="背景色"
                value={styles.closeButtonBackgroundColor}
                onChange={(e) =>
                  stylesCallbacks.setCloseButtonBackgroundColor(e)
                }
              />,
            ],
          ]}
        />,
      ];

    default:
      return undefined;
  }
};

const createEditFormItems = (
  parts: CreativeParts[],
  image: File | undefined,
  styles: ActionCreationContentsStyleParameters,
  stylesCallbacks: ActionCreationContentsStyleCallbacks,
  onChangeContentsImage: (file: File | undefined) => void
): JSX.Element[] => {
  let items: JSX.Element[] = [];

  parts.forEach((p) => {
    const formParts = selectFormParts(
      p,
      image,
      styles,
      stylesCallbacks,
      onChangeContentsImage
    );

    if (formParts && formParts.length > 0) {
      items = items.concat(formParts);
    }
  });

  return items;
};

export default createEditFormItems;
