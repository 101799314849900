import { DeliveryScheduleRule } from "types/delivery_schedule";
import { getDateString } from "utils/format/unixtime";

export class DeliveryScheduleRuleInterpleter {
  getType(type: string): string {
    if (type === "ONCE") {
      return "一度";
    } else if (type === "WEEKLY") {
      return "毎週";
    } else if (type === "MONTHLY") {
      return "毎月";
    } else {
      return "";
    }
  }

  getWeekdays(weekdays: number[]): string[] {
    const weekdaysList = ["日", "月", "火", "水", "木", "金", "土"];
    return weekdays
      .sort((a, b) => a - b)
      .map((weekday) => {
        return weekdaysList[weekday];
      });
  }

  getDays(days: number[]): string[] {
    return days.sort((a, b) => a - b).map((day) => day.toString());
  }

  getTime(hours: number[], minutes: number[]): string {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  public interpleteBackendData(rule: DeliveryScheduleRule): string[] {
    if (rule.schedule_type === "REPEAT") {
      const periodDescriptions = rule.period.is_selected
        ? [
            `開始日：${getDateString(rule.period.start_at)}`,
            `終了日：${getDateString(rule.period.end_at)}`,
          ]
        : [];

      if (rule.frequency.frequency_type === "WEEKLY") {
        const weekdays = this.getWeekdays(rule.frequency.weekdays);
        return [
          `${this.getType(rule.frequency.frequency_type)}：${weekdays.join(
            ", "
          )}`,
          `時間：${this.getTime(rule.frequency.hours, rule.frequency.minutes)}`,
        ].concat(periodDescriptions);
      } else if (rule.frequency.frequency_type === "MONTHLY") {
        const days = this.getDays(rule.frequency.days);
        return [
          `${this.getType(rule.frequency.frequency_type)}：${days.join(", ")}`,
          `時間：${this.getTime(rule.frequency.hours, rule.frequency.minutes)}`,
        ].concat(periodDescriptions);
      }
    }

    return ["-"];
  }
}
