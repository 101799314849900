import { CreativeParts } from "interfaces/model/actionContentsParameters";
import { PresetContentsFormParameters } from "interfaces/view/actionCreation";
import { createCloseButton, createContentsParts } from "./Layouts";

const createDefaultLayoutHtml = (
  parts: CreativeParts[],
  styles: {
    subHeadlineText: string;
    headlineText: string;
    text: string;
    buttonText: string;
    buttonLinkText: string;
    isOpenOtherWindow: boolean;
    imageUrl: string;
    imageLinkText: string;
    couponCodeText: string;
    couponButtonText: string;
    closeButtonSize: string;
  },
  imageUrl: string
) => {
  let items: string[] = [];

  parts.forEach((e) => {
    items.push(createContentsParts(e, styles, imageUrl));
  });

  // NOTE: close button must be set
  items.push(createCloseButton());

  return `${items.map((e) => e).join("")}`;
};

export const createPresetLayoutHtml = (
  formatPattern: PresetContentsFormParameters,
  styles: {
    subHeadlineText: string;
    headlineText: string;
    text: string;
    buttonText: string;
    buttonLinkText: string;
    isOpenOtherWindow: boolean;
    imageUrl: string;
    imageLinkText: string;
    couponCodeText: string;
    couponButtonText: string;
    closeButtonSize: string;
  },
  imageUrl: string
): string => {
  return createDefaultLayoutHtml(formatPattern.parts, styles, imageUrl);
};
