import DatetimeView from "atoms/accountPagesShared/DatetimeView";
import DetailTableLabel from "atoms/DetailTableLabel";
import DetailTableValue from "atoms/DetailTableValue";
import { SegmentationRule } from "interfaces/models";
import DetailTable from "organisms/account/DetailTable";
import createSegmentationRuleDescriptionBox from "organisms/account/SegmentationRuleDescriptionBox";
import { SegmentationRuleDescriptionV2Generator } from "utils/SegmentationRuleSentence";
import SegmentationRuleCreationCodec from "utils/segmentation_rule_creation/segmentationRuleCreationCodec";

type CreateSegmentationRuleDetailTableProps = {
  segmentationRule: SegmentationRule;
};

const CreateSegmentationRuleDetailTable = ({
  segmentationRule,
}: CreateSegmentationRuleDetailTableProps) => {
  const codec = new SegmentationRuleCreationCodec();

  const description = createSegmentationRuleDescriptionBox(
    SegmentationRuleDescriptionV2Generator.run(codec.decode(segmentationRule))
  );

  return (
    <DetailTable
      records={[
        {
          label: <DetailTableLabel text={"No."} />,
          value: (
            <DetailTableValue
              text={segmentationRule.segmentation_rule_number.toString()}
            />
          ),
        },
        {
          label: <DetailTableLabel text={"セグメント名"} />,
          value: <DetailTableValue text={segmentationRule.name} />,
        },
        {
          label: <DetailTableLabel text={"セグメント条件"} />,
          value: description,
        },
        {
          label: <DetailTableLabel text={"作成日時"} />,
          value: <DatetimeView value={segmentationRule.created_at} />,
        },
        {
          label: <DetailTableLabel text={"更新日時"} />,
          value: <DatetimeView value={segmentationRule.updated_at} />,
        },
      ]}
    />
  );
};

export default CreateSegmentationRuleDetailTable;
