import { useEffect, useMemo, useState } from "react";
import usePushNotificationContents, {
  PushNotificationContentsParameters,
  PushNotificationContentsCallbacks,
} from "./usePushNotificationContents";
import { DestinationCollectRule } from "types/destination_collect_rule";
import { PushNotificationEditResource } from "./useEditResource";
import { PUSH_NOTIFICATION_FORM_VALIDATE_RULE } from "app/system_defaults/validateRule";
import usePushNotificationDeliverySchedule, {
  PushNotificationDeliveryScheduleCallbacks,
  PushNotificationDeliveryScheduleParameters,
} from "./usePushNotificationDeliverySchedule";

export type PushNotificationEditFormParameters = {
  campaignUuid: string | null;
  name: string;
  destinationCollectRuleUuid: string;
  contents: PushNotificationContentsParameters;
  deliverySchedule: PushNotificationDeliveryScheduleParameters;
};

export type PushNotificationEditFormCallbacks = {
  updateName: (value: string) => void;
  updateDestinationCollectRuleUuid: (value: string) => void;
  contents: PushNotificationContentsCallbacks;
  deliverySchedule: PushNotificationDeliveryScheduleCallbacks;
};

const usePushNotificationEditForm = (
  editResource: PushNotificationEditResource | null,
  destinationCollectRules: DestinationCollectRule[] | null
): [PushNotificationEditFormParameters, PushNotificationEditFormCallbacks] => {
  const [campaignUuid, setCampaignUuid] = useState<string>(
    editResource && editResource.campaign_uuid ? editResource.campaign_uuid : ""
  );

  const [name, setName] = useState<string>(
    editResource ? editResource.name : ""
  );

  const [destinationCollectRuleUuid, setDestinationCollectRuleUuid] =
    useState<string>(
      editResource
        ? editResource.destination_collect_rule.destination_collect_rule_uuid
        : ""
    );

  const [contents, contentsCallbacks] = usePushNotificationContents(
    editResource ? editResource.contents : null
  );

  const [deliverySchedule, deliveryScheduleCallbacks] =
    usePushNotificationDeliverySchedule();

  // NOTE: update process
  useEffect(() => {
    if (editResource) {
      if (editResource.campaign_uuid) {
        setCampaignUuid(editResource.campaign_uuid);
      }
      setName(editResource.name);
      setDestinationCollectRuleUuid(
        editResource.destination_collect_rule.destination_collect_rule_uuid
      );
    }
  }, [editResource]);

  useEffect(() => {
    if (!destinationCollectRuleUuid && destinationCollectRules) {
      setDestinationCollectRuleUuid(
        destinationCollectRules[0].destination_collect_rule_uuid
      );
    }
  }, [destinationCollectRuleUuid, destinationCollectRules]);

  // NOTE: create responses
  const params = useMemo(() => {
    return {
      campaignUuid,
      name,
      destinationCollectRuleUuid,
      contents,
      deliverySchedule,
    };
  }, [
    campaignUuid,
    name,
    destinationCollectRuleUuid,
    contents,
    deliverySchedule,
  ]);

  const callbacks = useMemo(() => {
    return {
      updateName: (value: string) => {
        if (
          value.length <= PUSH_NOTIFICATION_FORM_VALIDATE_RULE.NAME.MAX_LENGTH
        ) {
          setName(value);
        }
      },
      updateDestinationCollectRuleUuid: (value: string) =>
        setDestinationCollectRuleUuid(value),
      contents: contentsCallbacks,
      deliverySchedule: deliveryScheduleCallbacks,
    };
  }, [
    setName,
    setDestinationCollectRuleUuid,
    contentsCallbacks,
    deliveryScheduleCallbacks,
  ]);

  return [params, callbacks];
};

export default usePushNotificationEditForm;
