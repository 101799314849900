import styles from "./SegmentationRuleOperation.module.scss";

const SegmentationRuleOperation: React.VFC<{
  items: JSX.Element[];
}> = ({ items }) => (
  <div className={styles.operation}>
    {items.map((item, i) => (
      <div key={i} className={styles.item}>
        {item}
      </div>
    ))}
  </div>
);

export default SegmentationRuleOperation;
