import styles from "./SegmentationRuleCreationFormSection.module.scss";

type SegmentationRuleCreationFormSectionProps = {
  contents: JSX.Element[];
};

const SegmentationRuleCreationFormSection = ({
  contents,
}: SegmentationRuleCreationFormSectionProps) => (
  <div className={styles.layout}>
    {contents.map((e, i) => (
      <div key={i} className={styles.item}>
        {e}
      </div>
    ))}
  </div>
);

export default SegmentationRuleCreationFormSection;
