import React from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import AccountPageBase from "./AccountPageBase";
import usePushNotificationPage from "app/hooks/v2/pushNotification/usePushNotificationPage";
import PushNotificationPanel from "organisms/ezPush/pushNotification/PushNotificationPanel";

const PushNotificationPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = usePushNotificationPage(
    accountContext.managementApi
  );
  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        <PushNotificationPanel parameters={parameters} callbacks={callbacks} />
      }
      modalContents={parameters.modalContents}
    />
  );
};

export default PushNotificationPage;
