import { useState } from "react";

import NewPasswordFrom from "organisms/auth/NewPasswordForm";

import HeadlineText from "atoms/HeadlineText";
import PasswordForm from "atoms/authorizePagesShared/PasswordForm";
import OrangeWideButton from "atoms/ezPushShared/authPageShared/OrangeWideButton";
import ErrorMessages from "atoms/ezPushShared/authPageShared/ErrorMessages";
import AuthPageBaseV2 from "./AuthPageBase";

const validatePassword = (
  newPassword: string,
  newPasswordConfirm: string
): string[] => {
  let errors: string[] = [];
  if (!newPassword) {
    errors.push("パスワードを入力してください");
  }

  if (!newPasswordConfirm) {
    errors.push("確認用パスワードを入力してください");
  }

  if (newPassword !== newPasswordConfirm) {
    errors.push("パスワードが一致しません");
  }
  return errors;
};

type NewPasswordPageProps = {
  onComplete: (
    newPassword: string,
    onErrorMessage: (err: string) => void
  ) => void;
};

const NewPasswordPageV2 = ({ onComplete }: NewPasswordPageProps) => {
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

  const onChangePassword = () => {
    const errors = validatePassword(newPassword, newPasswordConfirm);

    setErrorMessage(errors);

    if (errors.length === 0) {
      onComplete(newPassword, (err) => setErrorMessage([err]));
    }
  };

  const passwordForm = (
    <PasswordForm
      label="newPassword"
      value={newPassword}
      text="新しいパスワード"
      onChange={setNewPassword}
    />
  );

  const passwordConfirmForm = (
    <PasswordForm
      label="newPasswordConfirm"
      value={newPasswordConfirm}
      text="新しいパスワード（確認）"
      onChange={setNewPasswordConfirm}
    />
  );

  return (
    <AuthPageBaseV2
      formElement={
        <NewPasswordFrom
          headlineElement={
            <HeadlineText text="新しいパスワードをご設定ください" />
          }
          passwordFormElement={passwordForm}
          passwordConfirmFormElement={passwordConfirmForm}
          submitButtomElement={
            <OrangeWideButton text="設定" onClick={onChangePassword} />
          }
          errorMessageElement={<ErrorMessages messages={errorMessage} />}
        />
      }
    />
  );
};

export default NewPasswordPageV2;
